import {
    Component,
    ContentChildren,
    QueryList,
    AfterContentInit,
    ViewChild,
    ComponentFactoryResolver,
    ViewContainerRef,
    ContentChild,
    Input
  } from '@angular/core';
import { TabComponent } from './tab.component';

@Component({
    selector: 'my-tabs',
    template: `
      <ul class="pl-5 border-b-2 mb-5 active-tabs space-x-4 rtl:space-x-reverse">
        <li *ngIf='navigation' class="inline-block px-4 cursor-pointer" (click)='prevTab()'>
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
        </svg>
        </li>
        <li class="inline-block cursor-pointer px-4 Gilroy-Bold hover:border-b-4 hover:border-gray-200" *ngFor="let tab of tabs" (click)="selectTab(tab)" [ngClass]='tab.active ? "": "hidden md:inline-block"' [class.active]="tab.active">
          <a [ngClass]='tab.active ? "Gilroy-Bold text-gray-900" : "text-gray-500"'>{{tab.title}}</a>
        </li>
        <li *ngIf='navigation' class="inline-block px-4 cursor-pointer" (click)='nextTab()'>
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
        </svg>
        </li>
      </ul>
      <ng-content></ng-content>
    `,
    styles: [
      `
      .tab-close {
        color: gray;
        text-align: end;
        cursor: pointer;
      }
      `
    ],
    exportAs: 'tabs'
  })
  export class TabsComponent implements AfterContentInit {

    @ContentChildren(TabComponent) tabs: QueryList<TabComponent>;

    @Input() navigation = false; 

    // contentChildren are set
    ngAfterContentInit() {
      const activeTabs = this.tabs.filter((tab) => tab.active);
      if (activeTabs.length === 0) {
        this.selectTab(this.tabs.first);
      }
    }
    selectTab(tab){
        this.tabs.toArray().forEach(tab => tab.active = false);
        tab.active = true;
    }
    nextTab(): void {
      const activeTab = this.tabs.find((tab) => tab.active);
      const index = this.tabs.toArray().indexOf(activeTab);

      if (this.tabs.get(index) === this.tabs.last) {
        this.selectTab(this.tabs.first);
      } else {
        this.selectTab(this.tabs.get(index + 1));
      }
    }

    prevTab(): void {
      const activeTab = this.tabs.find((tab) => tab.active);
      const index = this.tabs.toArray().indexOf(activeTab);

      if (this.tabs.get(index) === this.tabs.first) {
        this.selectTab(this.tabs.last);
      } else {
        this.selectTab(this.tabs.get(index - 1));
      }
    }
  }
