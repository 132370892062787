import { FormControl, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';


export const valueChanges = (form: FormGroup, formErrors, errorMessages, translateService?: TranslateService): any => {
  if (!form) { return; }
  for (const field in formErrors) {
    if (formErrors.hasOwnProperty(field)) {
      formErrors[field] = '';
      const control = form.get(field);
      if (control && (control.dirty || control.touched) && !control.valid) {
        const messages = errorMessages[field];
        for (const key in control.errors) {
          if (control.errors.hasOwnProperty(key)) {
            if (translateService) {
              formErrors[field] += translateService.instant(messages[key]) + ' ';
            }
            else {
              formErrors[field] += messages[key] + ' ';
            }
          }
        }
      }
    }
  }
  return formErrors;
};

export const matchPassword = (controlName: string, matchingControlName: string): any => {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];
    if (matchingControl.errors && !matchingControl.errors.mustMatch) {
      return;
    }
    // set error on matchingControl if validation fails
    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ mustMatch: true });
    } else {
      matchingControl.setErrors(null);
    }
  };
};

export function dateLessThan(from: string, to: string): any {
  return (group: FormGroup) => {
    const f = group.controls[from];
    const t = group.controls[to];
    const checkF = checkDate(f);
    const checkT = checkDate(t);
    if (checkF && checkT) {
      if (new Date(f.value).getTime() > new Date(t.value).getTime()) {
        t.setErrors({ dates: true });
      }
      else {
        t.setErrors(null)
      }
    }
  };
}

export function checkDate(control): any {
  const format = "YYYY-MM-DD";
  if(control.valid && control.value !== null){
    const val = moment(control.value, format, true);
    if (!val.isValid() || val.isBefore('1000-01-01') || val.isAfter('9999-12-31')) {
      control.setErrors(null);
      control.setErrors({ invalidDate: true });
      console.log(control.value);
      return false
    }
    else {
      return true
    }
  }
}

export function DateValidator(format = "YYYY-MM-DD"): any {
  return (control: FormControl): { [key: string]: any } => {
    const val = moment(control.value, format, true);

    if (!val.isValid() || val.isBefore('1000-01-01') || val.isAfter('9999-12-31')) {
      control.setErrors(null);
      console.log(control.value);
      return { invalidDate: true };
    }
    return null;
  };
}

export function amountCheck(amount: string, due: number): any {
  return (group: FormGroup) => {
    const f = group.controls[amount];
    console.log(f.hasError('required'));
    console.log(due);
    if (f.value > due) {
      f.setErrors({ invalid: true });
    }
    else {
      f.setErrors(null);
    }
  };
}

export function minDateValidator(): any {
  const format = "YYYY-MM-DD";
  return (control: FormControl): { [key: string]: any } => {
    const val = moment(control.value, format, true);
    const minDate = moment(control.value, format, true);
    const today = moment().format('YYYY-MM-DD')

    if (!val.isValid() || val.isBefore(today)) {
      control.setErrors(null);
      console.log(control.value);
      return { minDate: true };
    }
    return null;
  };
}