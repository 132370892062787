
<div class="flex min-h-screen">
  <div class="w-full h-screen overflow-y-auto no-scrollbar">
    <div class="w-full flex flex-col space-y-4">

      <!-- header start  -->
      <div class="flex items-center ps-5 pt-4 pe-7 justify-between w-10/12 bg-white fixed top-0">
        <div class="">
          <h1 class="text-2xl Gilroy-Bold inline-flex items-center">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 me-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z" />
            </svg>
            {{'Sales Dashboard' | translate}}
          </h1>
        </div>

      </div>
      <!-- header End -->

      <!-- Grid options start -->
       <!-- <div class="grid grid-cols-3 gap-4 mt-5 mx-4">

        <div class="bg-gradient rounded-xl px-10 pt-7 pb-7 flex gap-4 justify-start">
          <div class="">
            <p class="Gilroy-bold text-base font-bold text-white">{{'Sales' | translate}}</p>
            <h3 class="text-2xl font-bold text-white">{{totalYearSales}}</h3>
          </div>
          <div class="">
            <p class="text-base font-bold text-white Gilroy-bold">{{'YoY%'}}</p>
            <h3 class="text-2xl font-bold text-white">{{totalYearGrowth ?? 0  + "%"}}</h3>
          </div>
        </div>

        <div class="bg-gradient rounded-xl px-10 pt-7 pb-7 flex justify-start ">
          <div class="">
            <p class="Gilroy-bold text-base font-bold text-white">{{'Target' | translate}}</p>
            <h3 class="text-2xl font-bold text-white">{{totalYearTarget}}</h3>
          </div>
        </div>

        <div class="bg-gradient rounded-xl px-10 pt-7 pb-7 flex gap-4 justify-start">
          <div class="">
            <p class="Gilroy-bold text-base font-bold text-white">{{'Target Achievement' | translate}}</p>
            <h3 class="text-2xl font-bold text-white">{{totalYearSales}}</h3>
          </div>
        </div>

       </div> -->
      <!-- Grid option end  -->


      <!-- Charts starts -->
      <div class="flex">
        <div class="w-11/12 px-5 mt-14">

          <div class="flex gap-3 pl-32">
            <label class="text-xl Gilroy-Bold text-center">{{'Type of Chart'}}</label>
            <div class="">
              <div class=" flex">
                <div class="w-64">
                  <ng-select
                  [items]='typeOfChart'
                  bindLabel='name'
                  [searchable]=false
                  [clearable]="false"
                  placeholder="Chart Type"
                  bindValue="value"
                  [(ngModel)]="selectedType"
                  ></ng-select>
                </div>
              </div>
            </div>
            <div class="">
              <div class=" flex">
                <div class="w-64">
                  <ng-select
                  [items]='years'
                  bindLabel='name'
                  [searchable]=false
                  [clearable]="false"
                  placeholder="Years"
                  [(ngModel)]="selectedYear"
                  bindValue="value"
                  (change)="dataChange()"></ng-select>
                </div>
              </div>
            </div>
          </div>



          <!-- chart for sales Growth -->
          <div class=" flex flex-col mt-5" *ngIf="selectedType === 'sales'">

            <!-- <div class="flex items-center justify-between mb-3 mt-3" >
              <h1 class="text-xl Gilroy-Bold">{{'Sales Growth' |translate}}</h1>
            </div> -->
              <div class="flex justify-center relative">
                <div class="flex justify-center gap-2 absolute">
                  <div class="flex gap-1">
                    <div class="w-7 h-3 pt-2" style="background-color:rgb(12, 156, 213)"></div>
                    <div class="text-xs font-bold text-gray-500">{{'Sales in %'}}</div>
                  </div>
                </div>
              <div class="bg-proile p-2 mb-3 rounded-lg" style="width: 697px; height: 365px">
                <!-- <div class="flex justify-center">
                  <div class="bg-blue-500 w-9 h-3 absolute"></div>
                  <div class="text-xs font-bold text-gray-500">{{'Sales Growth in %'}}</div>
                </div> -->
                <canvas baseChart
                  [datasets]="salesGrowData"
                  [labels]="salesGrowLabels"
                  [options]="salesGrowOptions"
                  [plugins]="salesGrowPlugins"
                  [legend]="salesGrowLegend"
                  [chartType]="salesGrowType">
                </canvas>
              </div>
            </div>
          </div>

          <!-- chart for Sales Growth ends here -->

          <!--chart for actual sales and target -->
          <div class=" flex flex-col mt-5" *ngIf="selectedType === 'salestarget'">
            <!-- <div class="flex items-center justify-between mb-3 mt-3">
              <h1 class="text-xl Gilroy-Bold">{{'Actual Sales And Target' | translate}}</h1>
            </div> -->
            <div class="flex justify-center relative">
              <div class="flex justify-center gap-2 absolute">
                <div class="flex gap-1">
                  <div class="w-7 h-3 pt-2" style="background-color:rgb(12, 156, 213)"></div>
                  <div class="text-xs font-bold text-gray-500">{{'Actural >= 100% of Target'}}</div>
                </div>
                <div class="flex gap-1">
                  <div class="w-7 h-3" style="background-color:rgb(255, 234, 0)"></div>
                  <div class="text-xs font-bold text-gray-500">{{'Actural < 100% and >=90% of Target'}}</div>
                </div>
                <div class="flex gap-1">
                  <div class="w-7 h-3" style="background-color:rgb(206, 19, 19)"></div>
                  <div class="text-xs font-bold text-gray-500">{{'Actual <90% of Target'}}</div>
                </div>
                <div class="flex gap-1">
                  <div class="w-7 h-3" style="background-color:rgb(220,220,220)"></div>
                  <div class="text-xs font-bold text-gray-500">{{'Target'}}</div>
                </div>
              </div>
              <div class="bg-proile p-5 mb-3 rounded-lg" style="display: block; width: 697px; height: 365px;">
                <canvas baseChart
                  [datasets]="salesAndTargetData"
                  [labels]="salesAndTargetLabels"
                  [options]="salesAndTargetOptions"
                  [plugins]="salesAndTargetPlugins"
                  [legend]="salesAndTargetLegend"
                  [chartType]="salesAndTargetType">
                </canvas>
              </div>
            </div>
          </div>
          <!-- chart actual sales and target ends here -->
        </div>
      </div>
      <!-- Charts ends here -->

    </div>
  </div>
</div>
