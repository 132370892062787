<div class="flex justify-center p-4">
  <div class="w-full flex flex-col space-y-4">
    <div>
      <div class="flex items-center justify-between">
        <div class="">

          <h1 *ngIf="!update" class="text-2xl Gilroy-Bold inline-flex items-center">
            <svg _ngcontent-hbd-c18="" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
              stroke="currentColor" class="h-6 w-6 me-2">
              <path _ngcontent-hbd-c18="" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="M16 8v8m-4-5v5m-4-2v2m-2 4h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z">
              </path>
            </svg>
            {{'Add Vendor' | translate}}
          </h1>

          <h1 *ngIf="update" class="text-2xl Gilroy-Bold inline-flex items-center">
            <svg _ngcontent-hbd-c18="" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
              stroke="currentColor" class="h-6 w-6 me-2">
              <path _ngcontent-hbd-c18="" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="M16 8v8m-4-5v5m-4-2v2m-2 4h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z">
              </path>
            </svg>
            {{'Update Vendor' | translate}}
          </h1>
        </div>
        <div appAccessControl moduleType='purchase' accessType='edit' class="space-x-2 rtl:space-x-reverse">
          <app-button *ngIf="!update" buttonText="{{'Save' | translate}}" type="primary" (handleClick)="createVendor()"></app-button>
          <app-button *ngIf="update" buttonText="{{'Update' | translate}}" type="primary" (handleClick)="UpdateVendor()"></app-button>
          <app-button routerLink="../" buttonText='{{"Discard" | translate}}' type='border'></app-button>
        </div>
      </div>
    </div>
    <form [formGroup]='vendorForm'>
      <div class="flex justify-between mt-5">
        <div class="flex mb-2">
          <div class="me-5">
            <label class="block text-md Gilroy-Bold">{{'Vendor Name' | translate}}</label>
            <input formControlName="vendorName" type="text" class="w-44 h-11 ps-3 rounded-xl mt-2 bg-input p-0 border-0 focus:ring-0" placeholder="{{'Vendor Name' | translate}}" />
            <div class="text-sm text-red-500 ms-2 mt-1" *ngIf='formErrors.vendorName'>
                {{formErrors.vendorName}}</div>  
        </div>
        </div>
        <div>
          

        </div>
      </div>
      <div class="flex mt-2">
        <div class="me-5">

          <label class="block text-sm Gilroy-Bold">{{'First Name' | translate}}</label>
          <input id='firstName' formControlName='firstName' type="text"
            class="w-full h-11 ps-3 rounded-xl mt-2 bg-input p-0 border-0 focus:ring-0" placeholder="{{'First Name' | translate}}" />
          <div class="text-sm text-red-500 ms-2 mt-1" *ngIf='formErrors.firstName'>
            {{formErrors.firstName}}</div>
        </div>
        <div class="me-5">

          <label class="block text-sm Gilroy-Bold">{{'Last Name' | translate}}</label>
          <input id='lastName' formControlName='lastName' type="text"
            class="w-full h-11 pl-3 rounded-xl mt-2 bg-input p-0 border-0 focus:ring-0" placeholder="{{'Last Name' | translate}}" />
          <div class="text-sm text-red-500 ms-2 mt-1" *ngIf='formErrors.lastName'>
            {{formErrors.lastName}}</div>
        </div>
        <div class="me-5">

          <label class="block text-sm Gilroy-Bold">{{'Email' | translate}}</label>
          <input id='email' formControlName='email' type="email"
            class="w-full h-11 pl-3 rounded-xl mt-2 bg-input p-0 border-0 focus:ring-0" placeholder="{{'Email' | translate}}" />
          <div class="text-sm text-red-500 ms-2 mt-1" *ngIf='formErrors.email'>
            {{formErrors.email}}</div>
        </div>


      </div>
      <h2 class="text-lg font-bold mt-8 mb-4 Gilroy-Bold">{{'Contact Details' | translate}}</h2>
      <div class="flex mt-2">
        <div class="me-5">

          <label class="block text-sm Gilroy-Bold">{{'Telephone' | translate}}</label>
          <input id='telephone' formControlName='telephone' type="number"
            class="w-full h-11 pl-3 rounded-xl mt-2 bg-input p-0 border-0 focus:ring-0" placeholder="{{'Telephone' | translate}}" />
          <div class="text-sm text-red-500 ms-2 mt-1" *ngIf='formErrors.telephone'>
            {{formErrors.telephone}}</div>
        </div>
        <div class="me-5">

          <label class="block text-sm Gilroy-Bold">{{'Mobile Number' | translate}}</label>
          <input id='mobileNumber' formControlName='mobileNumber' type="number"
            class="w-full h-11 pl-3 rounded-xl mt-2 bg-input p-0 border-0 focus:ring-0" placeholder="{{'Mobile Number' | translate}}" />
          <div class="text-sm text-red-500 ms-2 mt-1" *ngIf='formErrors.mobileNumber'>
            {{formErrors.mobileNumber}}</div>
        </div>
        <div class="me-5">

          <label class="block text-sm Gilroy-Bold">{{'Landline' | translate}}</label>
          <input id='tollFree' formControlName='tollFree' type="number"
            class="w-full h-11 pl-3 rounded-xl mt-2 bg-input p-0 border-0 focus:ring-0" placeholder="Landline" />
          <div class="text-sm text-red-500 ms-2 mt-1" *ngIf='formErrors.tollFree'>
            {{formErrors.tollFree}}</div>
        </div>

        <div class="me-5">
          <label class="block text-sm Gilroy-Bold">{{'Fax' | translate}}</label>
          <input id='fax' formControlName='fax' type="number"
            class="w-full h-11 pl-3 rounded-xl mt-2 bg-input p-0 border-0 focus:ring-0" placeholder="{{'Fax' | translate}}" />
          <div class="text-sm text-red-500 ms-2 mt-1" *ngIf='formErrors.fax'>
            {{formErrors.fax}}</div>
        </div>
      </div>
      <div class="flex mt-2">
        <div class="me-5">
          <label class="block text-sm Gilroy-Bold">{{'Tax ID' | translate}}</label>
          <input id='taxId' formControlName='taxId' type="text"
            class="w-full h-11 pl-3 rounded-xl mt-2 bg-input p-0 border-0 focus:ring-0" placeholder="{{'Tax ID' | translate}}" />
          <div class="text-sm text-red-500 ms-2 mt-1" *ngIf='formErrors.taxId'>
            {{formErrors.taxId}}</div>
        </div>
        <div class="me-5">
          <label class="block text-sm Gilroy-Bold">{{'IBAN' | translate}}</label>
          <input id='iban' formControlName='iban' type="text"
            class="w-full h-11 pl-3 rounded-xl mt-2 bg-input p-0 border-0 focus:ring-0" placeholder="{{'IBAN' | translate}}" />
          <div class="text-sm text-red-500 ms-2 mt-1" *ngIf='formErrors.iban'>
            {{formErrors.iban}}</div>
        </div>
      </div>
      <div class="flex mt-2">
        <div class="me-5">
          <label class="block text-sm Gilroy-Bold">{{'Website' | translate}}</label>
          <input id='webSite' formControlName='webSite' type="text"
            class="w-full h-11 pl-3 rounded-xl mt-2 bg-input p-0 border-0 focus:ring-0" placeholder="{{'Website' | translate}}" />
          <div class="text-sm text-red-500 ms-2 mt-1" *ngIf='formErrors.webSite'>
            {{formErrors.webSite}}</div>
        </div>
      </div>
      <div class="flex mt-4">
        <div class="w-8/12">
          <label class="block text-sm Gilroy-Bold">{{'Notes' | translate}}</label>
          <textarea formControlName="notes" id="notes"
            class="w-full h-28 pl-3 rounded-xl mt-2 bg-input p-4 border-0 focus:ring-0"
            placeholder="{{'Add some notes' | translate}}"></textarea>
          <div class="text-sm text-red-500 ms-2 mt-1" *ngIf='formErrors.notes'>
            {{formErrors.notes}}</div>
        </div>
      </div>

      <h3 class="text-lg mt-6 font-bold Gilroy-Bold">{{'Vendor Address' | translate}}</h3>
      <div class="flex mt-4">
        <div class="w-8/12">
          <label class="block text-sm Gilroy-Bold">{{'Address Line 1' | translate}}</label>
          <input id='addressLine1' formControlName='addressLine1' type="text"
            class="w-full h-11 pl-3 rounded-xl mt-2 bg-input p-0 border-0 focus:ring-0" placeholder="{{'Address Line 1' | translate}}" />
          <div class="text-sm text-red-500 ms-2 mt-1" *ngIf='formErrors.addressLine1'>
            {{formErrors.addressLine1}}</div>
        </div>
      </div>

      <div class="flex mt-4">
        <div class="w-8/12">
          <label class="block text-sm Gilroy-Bold">{{'Address Line 2' | translate}}</label>
          <input id='addressLine2' formControlName='addressLine2' type="text"
            class="w-full h-11 pl-3 rounded-xl mt-2 bg-input p-0 border-0 focus:ring-0" placeholder="{{'Address Line 2'| translate}}" />
          <div class="text-sm text-red-500 ms-2 mt-1" *ngIf='formErrors.addressLine2'>
            {{formErrors.addressLine2}}</div>
        </div>
      </div>

      <div class="flex mt-2">
        <div class="me-5">
          <label class="block text-sm Gilroy-Bold mb-2">{{'Country' | translate}}</label>
          <ng-select  (change)='changeEventContry($event )' id='country-select1' [items]="countries" class="custom-selector border-0 p-0 w-44 h-11" bindLabel="text"
          bindValue="text" formControlName="country" placeholder='{{"Select one" | translate}}'>
          <ng-option *ngFor="let country of countries" [value]="country.id">{{country.name}}</ng-option>
          </ng-select>
          <div class="text-sm text-red-500 ms-2 mt-1" *ngIf='formErrors.country'>
            {{formErrors.country}}</div>
        </div>
        <div class="me-5">
          <label class="block text-sm Gilroy-Bold mb-2">{{'State' | translate}}</label>
          <ng-select id='state-select'
            [items]="states" class="custom-selector border-0 p-0 w-44 h-11"
            bindLabel="text"
            bindValue="text"
            formControlName="state"
            placeholder='{{"Select one" | translate}}'>
          </ng-select>
          <div class="text-sm text-red-500 ms-2 mt-1" *ngIf='formErrors.state'>{{formErrors.state}}</div>
        </div>
        <div class="me-5">
          <label class="block text-sm Gilroy-Bold">{{'City' | translate}}</label>
          <input id='city' formControlName='city' type="text"
            class="w-full h-11 pl-3 rounded-xl mt-2 bg-input p-0 border-0 focus:ring-0" placeholder="{{'City' | translate}}" />
          <div class="text-sm text-red-500 ms-2 mt-1" *ngIf='formErrors.city'>
            {{formErrors.city}}</div>
        </div>

        <div class="me-5">
          <label class="block text-sm Gilroy-Bold">{{'Postal Code' | translate}}</label>
          <input id='postalCode' formControlName='postalCode' type="text"
            class="w-full h-11 pl-3 rounded-xl mt-2 bg-input p-0 border-0 focus:ring-0" placeholder="{{'Postal Code' | translate}}" />
          <div class="text-sm text-red-500 ms-2 mt-1" *ngIf='formErrors.postalCode'>
            {{formErrors.postalCode}}</div>
        </div>
      </div>
    </form>
  </div>
</div>
