<ul class="pl-5 border-b-2 space-x-4 rtl:space-x-reverse">
  <li routerLink='/accounting/charts-of-account/assets' routerLinkActive='active-border' class="inline-block pl-4 pr-4 cursor-pointer text-gray-500 hover:border-b-4">
    <a class="Gilroy-SemiBold">{{'Assets' | translate}}</a>
  </li>
  <li routerLink='/accounting/charts-of-account/liabilities' routerLinkActive='active-border' class="inline-block pl-4 pr-4 cursor-pointer text-gray-500 hover:border-b-4">
    <a class="Gilroy-SemiBold">{{'Liabilities' | translate}}</a>
  </li>
  <li routerLink='/accounting/charts-of-account/income' routerLinkActive='active-border' class="inline-block pl-4 pr-4 cursor-pointer text-gray-500 hover:border-b-4">
    <a class="Gilroy-SemiBold">{{'Income' | translate}}</a>
  </li>
  <li routerLink='/accounting/charts-of-account/expenses' routerLinkActive='active-border' class="inline-block pl-4 pr-4 cursor-pointer text-gray-500 hover:border-b-4">
    <a class="Gilroy-SemiBold">{{'Expenses' | translate}}</a>
  </li>
  <li routerLink='/accounting/charts-of-account/equity' routerLinkActive='active-border' class="inline-block pl-4 pr-4 cursor-pointer text-gray-500 hover:border-b-4">
    <a class="Gilroy-SemiBold">{{'Equity' | translate}}</a>
  </li>
</ul>