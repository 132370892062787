import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { selectedVendors } from 'src/app/modules/purchases/state/selectors/vendors.selector';
import { dateLessThan, DateValidator, valueChanges } from 'src/app/shared/utils/formValidator';
import { RootReducerState } from 'src/app/store/reducers';
import * as cc from 'currency-codes';
import { selectBusiness } from 'src/app/store/selectors/business.selector';

import { SalesReducerState } from 'src/app/modules/sales/state/reducers';
import { BillsService } from '../../bills.services';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { selectSalesTaxes } from 'src/app/modules/purchases/state/selectors/salesTaxes.selector';
import { selectedProducts } from 'src/app/modules/purchases/state/selectors/product.selector';
import { selectProjects } from 'src/app/store/selectors/project.selector';
import { PurchasesService } from 'src/app/modules/purchases/purchases.service';
import { selectUser } from 'src/app/store/selectors/user.selectors';
import { UpdateTransactionLimit } from 'src/app/store/actions/user.actions';
import { selectUsage } from 'src/app/store/selectors/usage.selector';
import { updateCurrentTransactionLimit } from 'src/app/store/actions/usage.action';
import { TranslateService } from '@ngx-translate/core';
import { toFixed } from 'src/app/shared/utils/numberFormatting';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { AssetsService } from "../../../assets/assetsServices";
import { NumberService } from 'src/app/shared/services/number.service';
import { selectUserSubscription } from 'src/app/store/selectors/subscription.selectors';
import { FileUploadService } from 'src/app/shared/services/file-upload.service';

interface StockCheck {
  item: string;
  openingStock: number,
  quantityAdded: number
}

@Component({
  selector: 'app-create-bills',
  templateUrl: './create-bills.component.html',
  styleUrls: ['./create-bills.component.scss']
})
export class CreateBillsComponent implements OnInit {

  constructor(private fb: FormBuilder,
              private store: Store<SalesReducerState>,
              private rootStore: Store<RootReducerState>,
              private route: ActivatedRoute,
              private spinner: NgxSpinnerService,
              private assetsService: AssetsService,
              private router: Router,
              private purchaseService: PurchasesService,
              private translateService: TranslateService,
              private billServices: BillsService,
              private numberService: NumberService,
              private fileUploadService: FileUploadService,
              private toaster: ToastrService) {
    this.vendors$ = this.store.pipe(select(selectedVendors));
    this.usage$ = this.rootStore.pipe(select(selectUsage));
    this.business$ = this.rootStore.pipe(select(selectBusiness));
    this.projects$ = this.rootStore.pipe(select(selectProjects));
    this.products$ = this.store.pipe(select(selectedProducts));
    this.salesTaxes$ = this.store.pipe(select(selectSalesTaxes));
    this.subscription$ = rootStore.pipe(select(selectUserSubscription));
  }

  billForm: FormGroup;
  products$: Observable<any>;
  usage$: Observable<any>;
  business$: Observable<any>;
  projects$: Observable<any>;
  salesTaxes$: Observable<any>;
  subscription$: Observable<any>;
  unsubscribe$ = new Subject();
  transactionLimit;
  salesTaxes = [];
  products = [];
  availableProducts = [];
  assets = [];
  projects= [];
  files: File[];
  addedFiles = [];
  subscription = null;
  update = false;
  selectedVendor: number;
  billToUpdate = null;
  billDetails = null;
  selectedCurrency: number;
  businessId = null;
  currencyDetails = {
    currency:'',
    currencySymbol:''
  };
  number = '1.2-2'
  vendors$: Observable<any>;
  vendors: Observable<any>;
  formErrors = {
    vendor: '',
    date: '',
    dueDate: '',
    purchaseOrder: '',
    billNumber: '',
    notes: '',
  };

  formErrorMessages = {
    vendor: {
      required: 'Vendor is Required'
    },
    date: {
      required: 'Date is Required',
      invalidDate: 'Invalid Date'
    },
    dueDate: {
      required: 'Due Date is Required',
      dates: 'Invalid Date',
      invalidDate: 'Invalid Date'
    },
    purchaseOrder: {
      required: 'Purchase Order is Required'
    },
    billNumber: {
      required: 'Bill Number is Required'
    },
    notes: {
      required: 'Notes is Required'
    },
  };

  outOfStock: boolean = false;
  checkQuantityInStock : Array<StockCheck | null> = []

  subTotal = 0;
  totalTax = 0;
  itemTotal = 0;
  totalAmount = 0;
  vendor = [];

  ngOnInit(): void {
    this.getBusinessId();
    this.getCurrencyDetails();
    this.getTransactionLimit();
    this.loadForm();
    this.loadVendor();
    this.loadProjects();
    // this.loadProducts();
    this.loadSubscription();
    this.loadAssets();
    this.loadSalesTaxes();
    this.loadNumberConfig();

    this.route.queryParams.subscribe(({ id }) => {
      if (id) {
        this.billToUpdate = id;
        this.update = true;
        this.spinner.show();
        this.billServices.getBillId(id).subscribe((resp) => {
          this.spinner.hide();
          console.log(resp);
          this.appendBillData(resp.data);
          this.billDetails = resp?.data;
        }, (error) => {
          this.spinner.hide();
          console.log(error);
        });
      } else {
        this.loadBillNumber();
      }
    });
  }

  loadSubscription(): void {
    this.subscription$.pipe(takeUntil(this.unsubscribe$))
    .subscribe(({planName}) => {
      this.subscription = planName;
      this.products$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(products => {
        if(this.subscription === 'Retail' || this.subscription === 'Retail Plus'){
          products = products?.filter(product => product?.isReviewed)
        }
        this.products = products?.filter(item => {
           if(item?.itemType === 'Service' && item?.isSale){
             return false;
           }
           return true;
        });
        console.log('this.products',this.products)
        this.availableProducts = this.products;
      });
    })
  }

  loadNumberConfig(): void {
    this.numberService.number
    .pipe((takeUntil(this.unsubscribe$)))
    .subscribe((number) => {
      this.number = number
    })
  }

  loadBillNumber(): void {
    this.purchaseService.businessId$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((id) => {
      this.spinner.show();
      const from = moment().startOf('year').format('YYYY-MM-DD');
      const to = moment().endOf('year').format('YYYY-MM-DD');
      const year = moment().format('YYYY');
      const body = {
        from,
        to,
        year,
        businessId: id
      };
      this.billServices.getBillNumber(body).subscribe((resp) => {
        this.billForm.get('billNumber').setValue(resp.data);
        this.spinner.hide();
      }, (error) => {
        this.spinner.hide();
        this.router.navigate(['/purchases/bills']);
        this.toaster.error(this.translateService.instant('Unable to generate bill number'));
      });
    })
  }

  reloadBillNumber(): void {
    const billDateField = this.billForm.get('date');
    if (billDateField.valid && this.billForm.get('billNumber').value) {
      const billDateYear = new Date(billDateField.value).getFullYear();
      const billNumber = this.billForm.get('billNumber').value;
      const billYear = billNumber.replace(/^\D+|\D.*$/g, '');
      console.log(billYear, billDateYear, Number(billYear) === billDateYear);
      if (Number(billYear) !== billDateYear) {
        const from = moment(`${billDateYear}`).startOf('year').format('YYYY-MM-DD');
        const to = moment(`${billDateYear}`).endOf('year').format('YYYY-MM-DD');
        const body = {
          from,
          to,
          year: billDateYear,
          businessId: this.businessId
        };
        this.spinner.show();
        this.billServices.getBillNumber(body).subscribe((resp) => {
          this.billForm.get('billNumber').setValue(resp.data);
          this.spinner.hide();
        }, (error) => {
          this.spinner.hide();
          this.toaster.error(this.translateService.instant('Something went wrong!'));
        });
      }
    }
  }

  getTransactionLimit(): void {
    this.usage$.subscribe(({currentUsage})=>{
      if(currentUsage){
        const { transactionLimit } = currentUsage;
        this.transactionLimit = transactionLimit;
      }
    })
  }

  getBusinessId(): void { 
    this.purchaseService.businessId$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((id) => {
        this.businessId = id;
      });
  }

  getCurrencyDetails(): void {
    this.purchaseService.currencyDetails
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(details=>{
      if(details)
        this.currencyDetails = details;
    })
  }

  loadVendor(): void {
    this.vendors$.subscribe((vendors) => {
      this.vendors = vendors;
    });
  }

  loadProjects(): void {
    this.projects$.subscribe(projects=>{
      this.projects = projects;
    })
  } 

  loadSalesTaxes(): void {
    this.salesTaxes$.subscribe((taxes) => {
      this.salesTaxes = taxes.map((tax) => ({
        ...tax,
        name: tax.taxName,
        tax: tax.taxRate,
      }));
    });
  }

  loadProducts(): void {
    this.products$.subscribe((products) => {
      this.products.push(...products?.filter(item => item?.isReviewed).map(product => {
       return {...product,
        type: 'Product'
        }
      }))
    });
  }

  loadAssets(): void {
    this.assetsService.getAllAssets(this.businessId)
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(resp => {
      if(resp?.success) {
        this.assets = resp?.data?.map(asset => (
          {...asset,type: 'Asset',
            name: asset.assetsName,
            price: asset.amount,
            tax: []
          }));
        this.products.push(...this.assets);  
        console.log(this.products,this.assets);
      }
    });

  }

  loadForm(bill?): void {
    this.billForm = this.fb.group({
      vendor: [bill?.vendor ? bill.vendor : null, [Validators.required]],
      projectId: [bill?.projectDetails.projectDetails?.projectId || null],
      date: [bill?.date ? bill.date : '', [Validators.required, DateValidator()]],
      dueDate: [bill?.dueDate ? bill.dueDate : '', [Validators.required, DateValidator()]],
      purchaseOrder: [bill?.purchaseOrder ? bill.purchaseOrder : '', [Validators.required]],
      billNumber: [bill?.billNumber ? bill.billNumber : '', [Validators.required]],
      notes: [null],
      items: this.fb.array([]),
    }, { validator: dateLessThan('date', 'dueDate') });
    this.billForm.valueChanges.subscribe(({ items }) => {
      this.formErrors = valueChanges(
        this.billForm,
        { ...this.formErrors },
        this.formErrorMessages,
        this.translateService
      );
      this.calculateTotal(items);
    });

    this.formErrors = valueChanges(
      this.billForm,
      { ...this.formErrors },
      this.formErrorMessages,
      this.translateService
    );
  }

  appendBillData(billData): void {
    const {
      vendor,
      projectDetails: {
        projectId = null
      } = {projectId: null},
      date,
      dueDate,
      purchaseOrder,
      billNumber,
      subTotal = 0,
      tax,
      totalAmount,
      items,
      notes = '',
      files = []
    } = billData;
    const mappedItems = items.map(
      ({ itemId, item, unit, tax, price, isSale = false, cogsAccountDetails = null, depreciationAccount = null, inventoryAccountDetails = null, accountDetails, type, sku = '', hsn_sac = '' }) => {
        return { itemId, item, unit, tax, accountDetails, type, sku, hsn_sac, isSale, depreciationAccount, cogsAccountDetails, inventoryAccountDetails, price: this.numberService.toFixed(price), totalCost: this.numberService.toFixed(price * unit) };
      }
    );
    this.addedFiles = files;
    const data = {
      vendor,
      projectId,
      date: date.split('T')[0],
      dueDate: dueDate.split('T')[0],
      billNumber,
      purchaseOrder,
      notes,
      items: mappedItems
    };
    this.subTotal = this.numberService.toFixed(subTotal);
    this.totalTax = this.numberService.toFixed(tax);
    this.totalAmount = this.numberService.toFixed(totalAmount);
    this.itemTotal = this.numberService.toFixed((billData.itemTotal ?? 0));
    mappedItems.forEach(() => this.items.push(this.createItem()));
    this.billForm.setValue(data);
  }

  createItem(): FormGroup {
    const itemForm = this.fb.group({
      item: null,
      itemId: '',
      sku: '',
      hsn_sac: '',
      unit: [1, Validators.min(1)],
      price: 0,
      tax: [],
      totalCost: 0,
      accountDetails: null,
      type: null,
      cogsAccountDetails: null,
      isSale: false,
      inventoryAccountDetails: null,
      depreciationAccount: null
    });
    return itemForm;
  }

  get items(): FormArray {
    return this.billForm.get('items') as FormArray;
  }

  calculateTotal(estimateItems): void {
    let subTotal = 0;
    let totalTax = 0;
    let itemTotal = 0;
    estimateItems.forEach((item) => {
      const { price, unit, tax } = item;
      subTotal += price * unit;
      if (item.type === 'Product') {
        itemTotal += price * unit;
      }
      if (tax) {
        totalTax += this.calculateItemTax(price * unit, tax);
      }
    });
    this.subTotal = this.numberService.toFixed(subTotal);
    this.totalTax = this.numberService.toFixed(totalTax);
    this.totalAmount = this.numberService.toFixed((subTotal + totalTax));
    this.itemTotal = this.numberService.toFixed(itemTotal);
  }
  addNewItem(): void {
    this.items.push(this.createItem());
  }

  removeItem(index): void {
    let item = this.products.find( product => product?._id === this.items.controls[index].value?.itemId)
    if(item){
      this.availableProducts.push(item)
    }
    this.items.removeAt(index);
  }

  changeEvent(event, index): void {
    const { _id, name, price, itemType, tax, accountDetails, depreciationAccount = null,  sku = null, hsn, sac, isSale = false, cogsAccountDetails = null, inventoryAccountDetails = null } = event;
    this.items.controls[index].setValue({
      item: name,
      itemId: _id,
      sku,
      hsn_sac: hsn || sac? hsn || sac : null,
      type:itemType  || 'assets',
      price: this.numberService.toFixed(price),
      tax,
      unit: 1,
      totalCost: this.numberService.toFixed((price * 1)),
      accountDetails,
      isSale,
      cogsAccountDetails,
      inventoryAccountDetails,
      depreciationAccount
    });
    this.availableProducts = this.availableProducts.filter(el => el?._id !== _id);
  }

  changeTaxEvent(event, index): void {
    const { price, unit } = this.items.controls[index].value;
    this.items.controls[index].get('total').setValue(this.numberService.toFixed(price * unit));
  }
  changeEventVendor(event): void {
    const { vendorName, _id } = event;
    this.billForm.get('vendor').setValue({
      vendorName,
      vendorId: _id,
    });
  }
  calculateItemTax(price, taxes): any {
    return taxes.reduce((a, b) => (a += price * (b.tax / 100)), 0);
  }
  estimateDataMapper(): object {
    const body = {
      ...this.billForm.value,
      projectDetails: {projectId:this.billForm.value.projectId},
      businessId: this.businessId,
      totalAmount: this.numberService.toFixed(this.totalAmount),
      dueAmount: this.numberService.toFixed(this.totalAmount),
      subtotal: this.numberService.toFixed(this.subTotal),
      tax: this.numberService.toFixed(this.totalTax),
      itemTotal: this.numberService.toFixed(this.itemTotal)
    };
    return body;
  }
  createBill(): void {
    if (this.billForm.invalid) {
      this.billForm.markAllAsTouched();
      this.formErrors = valueChanges(
        this.billForm,
        { ...this.formErrors },
        this.formErrorMessages,
        this.translateService
      );
      return;
    }
    const body: any = this.estimateDataMapper();

    if (body.items.length === 0) {
      return;
    }

    if (body.items[0].item === null) {
      return;
    }

    if(body?.items?.some(item => !item.itemId)){
      this.toaster.error('Please select item for each line item before proceeding');
      return;
    }

    if(this.subscription === 'Retail' || this.subscription === 'Retail Plus' ){
      let stockCheck: Array<StockCheck | null> = [];
      body.items?.forEach(item => {
        const product = this.products.find(product => product?._id == item?.itemId);
        if(product && product?.stockDetails?.openingStock < item?.quantity){
          stockCheck.push({
            item: item?.item,
            openingStock: product?.stockDetails?.openingStock,
            quantityAdded: item?.quantity
          })
          console.log(stockCheck, this.checkQuantityInStock); 
        }
      })
      this.checkQuantityInStock = stockCheck;

      if(this.checkQuantityInStock.length){
        this.outOfStock = true;
        return
      }
    }

    this.spinner.show();
    this.fileUploadService.emitFiles.next(true);
    this.fileUploadService.emitFiles.next(false);
    const formData = new FormData();
    // this.files.forEach((file,i) => {
    //   formData.append(`file${i}`, file);
    // });
    formData.append('payload', JSON.stringify(body));
    this.billServices.createBill({formData, businessId: body?.businessId}).subscribe(
      (resp) => {
        this.spinner.hide();
        if(resp?.success){
            this.toaster.success(resp?.message);
            this.billForm.reset();
            this.files = [];
            this.rootStore.dispatch(updateCurrentTransactionLimit({transactionLimit: this.transactionLimit + 1}))
            this.router.navigate(['/purchases/bills/view-bill'], {queryParams: {id: resp?.data?._id}});
        }
        else{
          this.toaster.error(resp?.message);
        }
      },
      (error) => {
        this.spinner.hide();
      }
    );
  }
  updatebill(): void {
    if (this.billForm.invalid) {
      this.billForm.markAllAsTouched();
      this.formErrors = valueChanges(
        this.billForm,
        { ...this.formErrors },
        this.formErrorMessages,
         this.translateService
      );
      return;
    }
    const body: any = {...this.estimateDataMapper(), _id: this.billToUpdate, businessId: this.businessId};

    const recordedAmount = this.billDetails?.paymentHistory?.reduce((total, curr) => {
      return total += curr?.amount
    }, 0);
    if(body?.totalAmount < recordedAmount){
      this.toaster.error('Bill value cannot be lesser than payment received for bill');
      return;
    }
    body.dueAmount = body.totalAmount - recordedAmount;

    if(body?.items?.some(item => !item.itemId)){
      this.toaster.error('Please select item for each line item before proceeding');
      return;
    }
    
    if(this.subscription === 'Retail' || this.subscription === 'Retail Plus' ){
      let stockCheck: Array<StockCheck | null> = [];
      body.items?.forEach(item => {
        const product = this.products.find(product => product?._id == item?.itemId);
        if(product && product?.stockDetails?.openingStock < item?.quantity){
          stockCheck.push({
            item: item?.item,
            openingStock: product?.stockDetails?.openingStock,
            quantityAdded: item?.quantity
          })
          console.log(stockCheck, this.checkQuantityInStock); 
        }
      })
      this.checkQuantityInStock = stockCheck;

      if(this.checkQuantityInStock.length){
        this.outOfStock = true;
        return
      }
    }

    this.spinner.show();
    this.fileUploadService.emitFiles.next(true);
    this.fileUploadService.emitFiles.next(false);
    const formData = new FormData();
    // this.files.forEach((file,i) => {
    //   formData.append(`file${i}`, file);
    // });
    formData.append('payload', JSON.stringify(body));
    this.billServices.updateBill({formData, billId: body?._id}).subscribe(resp => {
      this.spinner.hide();
      this.router.navigate(['/purchases/bills']);
    }, error => {
      this.spinner.hide();
    });
  }

  saveFiles(files: File[]): void{
    this.files = files;
  }
}
