import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { RouterModule } from '@angular/router';
import { ButtonComponent } from './components/utils/button/button.component';
import { DropdownComponent } from './components/utils/dropdown/dropdown.component';
import { ClickOutsideModule } from 'ng-click-outside';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ModalContainerComponent } from './components/modals/modal-container/modal-container.component';
import { ImageCropperComponent } from './components/modals/image-cropper/image-cropper.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { TableContainerComponent } from './components/tables/table-container/table-container.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DocumentContainerComponent } from './documents/document-container.component';
import { TemplateOneComponent } from './documents/invoices/template-one/template-one.component';
import { TemplateTwoComponent } from './documents/invoices/template-two/template-two.component';
import { TemplateThreeComponent } from './documents/invoices/template-three/template-three.component';
import {NgxPaginationModule} from 'ngx-pagination';
import { RecordPaymentComponent } from './components/modals/record-payment/record-payment.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { TabsComponent } from './components/tabs/tabs.component';
import { TabComponent } from './components/tabs/tab.component';
import { TooltipComponent } from './components/utils/tooltip/tooltip.component';
import { CustomerStatementComponent } from './documents/customer-statement/customer-statement.component';
import { TranslateModule } from '@ngx-translate/core';
import { AccessControlDirective } from '../directives/access-control.directive';
import { DirectivesModule } from '../directives/directives.module';
import { ReceiptComponent } from './documents/receipt/receipt.component';
import { SendToSubComponent } from './components/send-to-sub/send-to-sub.component';
import { FileUploadComponent } from './components/file-upload/file-upload.component';

@NgModule({
  declarations: [
    SidebarComponent,
    ButtonComponent,
    DropdownComponent,
    ModalContainerComponent,
    ImageCropperComponent,
    TableContainerComponent,
    DocumentContainerComponent,
    TemplateOneComponent,
    TemplateTwoComponent,
    TemplateThreeComponent,
    RecordPaymentComponent,
    TabsComponent,
    TabComponent,
    TooltipComponent,
    CustomerStatementComponent,
    ReceiptComponent,
    SendToSubComponent,
    FileUploadComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ImageCropperModule,
    NgxSpinnerModule,
    NgSelectModule,
    ClickOutsideModule,
    ReactiveFormsModule,
    NgxPaginationModule,
    TranslateModule,
    DirectivesModule,
  ],
  exports: [
    SidebarComponent,
    ButtonComponent,
    DropdownComponent,
    ModalContainerComponent,
    ImageCropperComponent,
    TableContainerComponent,
    TemplateOneComponent,
    TemplateTwoComponent,
    TemplateThreeComponent,
    RecordPaymentComponent,
    TabsComponent,
    TabComponent,
    TooltipComponent,
    CustomerStatementComponent,
    SendToSubComponent,
    FileUploadComponent,
  ]
})
export class SharedModule { }
