<div class="p-4" inventoryCheck>
    <div *ngIf='!update' header class="text-start text-lg border-b pb-2 Gilroy-Bold">{{'Add a Product or Service' | translate}}</div>
    <div *ngIf='update'  header class="txet-start text-lg border-b pb-2 Gilroy-Bold">{{'Update a Product or Service' | translate}}</div>
    <div content>
      <p class="text-start text-sm Gilroy-Bold">{{'Create Product ' | translate}}
      </p>
      <form [formGroup]="createProductForm" class="mt-5 text-start flex space-x-2 relative z-10">
        <div class="w-1/2" >
            <div class="flex mb-3 items-center">
                <div class="w-1/5">
                    <p>{{'Name:' | translate}}</p>
                </div>
                <div class="w-2/5">
                    <input type="text" formControlName="name" class="w-full bg-input ps-2 h-10 border-0 rounded-xl" placeholder="Enter product name" />
                    <div class="text-sm text-red-500 ms-2 mt-1" *ngIf='formErrors.name'>
                    {{formErrors.name}}</div>
                </div>
            </div>

            <div class="flex mb-3 w-full">
                <div class="w-1/5">
                    <p>{{'Description:' | translate}}</p>
                </div>
                <div class="w-full">
                    <textarea formControlName="description" class="bg-input pl-2 h-44 border-0 w-9/12 rounded-xl ms-5 me-5"></textarea>
                    <div class="text-sm text-red-500 ms-2 mt-1" *ngIf='formErrors.description'>
                    {{formErrors.description}}</div>
                </div>
            </div>

            <!-- <div class="flex mb-3 items-center">
                <div class="w-1/5 self-start ">
                    <p>{{'Item Type' | translate }}</p>
                </div>
                <div class="w-2/5 ">
                    <fieldset [disabled]="productToUpdate" class="flex items-center ">
                        <div>
                            <input type="radio" value="Product" formControlName="itemType"> Product
                        </div>
                        <div class="ms-3" >
                            <input type="radio" value="Service" formControlName="itemType"> Service
                        </div>
                    </fieldset>
                    <div class="w-full text-sm text-red-500 ms-2 mt-1" *ngIf='formErrors.itemType'>
                    {{formErrors.itemType}}
                    </div>
                </div>
            </div> -->
            <div class="flex mb-3 items-center">
                <div class="w-1/5">
                    <span>{{'Auto Run' | translate }}</span>
                </div>
                <!-- <div class="2/5" [disabled]="productToUpdate"> -->
                    <fieldset class="flex items-center ">
                        <label class="flex items-center space-x-2 rtl:space-x-reverse">
                            <input type="checkbox" class="cursor-pointer" formControlName='isAutoRun' />
                        </label>
                    </fieldset>
                <!-- </div> -->
            </div>

            <div class="flex mb-3 w-full">
                <div class="w-1/5">
                    <p>{{'SKU:' | translate}}</p>
                </div>
                <div class="w-2/5">
                    <input type="text" formControlName="sku" class="w-full bg-input ps-2 h-10 border-0 rounded-xl" placeholder="Enter product SKU" />
                    <div class="text-sm text-red-500 ms-2 mt-1" *ngIf='formErrors.sku'>
                    {{formErrors.sku}}</div>
                </div>
            </div>

            <div *ngIf="createProductForm?.controls['itemType'].value === 'Product'" class="flex mb-3 items-center">
                <div class="w-1/5">
                    <p>{{'HSN Code' | translate}}</p>
                </div>
                <div class="w-2/5">
                    <input type="number" min="1" maxlength="7" formControlName="hsn" class="w-full bg-input ps-2 h-10 border-0 rounded-xl" placeholder="Enter HSN ID" />
                </div>
            </div>

            <div *ngIf="createProductForm?.controls['itemType'].value === 'Service'" class="flex mb-3 items-center">
                <div class="w-1/5">
                    <p>{{'SAC Code' | translate}}</p>
                </div>
                <div class="w-2/5">
                    <input type="number" min="1" maxlength="7" formControlName="sac" class="w-full bg-input ps-2 h-10 border-0 rounded-xl" placeholder="Enter SAC ID" />
                </div>
            </div>

            <div class="flex mb-3 items-center">
                <div class="w-1/5">
                    <p>{{'Account' | translate }}</p>
                </div>
                <div class="w-2/5">
                    <ng-select class="custom-selector border-0 p-0" placeholder="Select product account" groupBy="accountSection" [items]="incomeAccounts" 
                    bindLabel="accountName"
                    formControlName = 'accountDetails'>
                    </ng-select>
                    <div  class="text-sm text-red-500 ms-2 mt-1" *ngIf='formErrors.accountDetails'>
                    {{formErrors.accountDetails}}
                    </div>
                </div>
            </div>
            <div class="flex mb-3 items-center">
                <div class="w-1/5">
                    <p>{{'Category' | translate }}</p>
                </div>
                <div class="w-2/5">
                    <ng-select 
                        #selectRef
                        class="custom-selector border-0 p-0"
                        [items]="categories" 
                        placeholder="{{'Select category' | translate}}"
                        formControlName="category"
                        addTagText = ''  
                        [addTag]="addNewCategory">
                    </ng-select>
                    <div  class="text-sm text-red-500 ms-2 mt-1" *ngIf='formErrors.category'>
                    {{formErrors.category}}
                    </div>
                </div>
            </div>

            <div  class="flex mb-3 items-center">
                <div class="w-1/5">
                    <p>{{'Units' | translate}}</p>
                </div>
                <div class="w-2/5">
                    <ng-select 
                    class="custom-selector border-0 p-0" 
                    [items]="units"
                    bindLabel="name"
                    bindValue="value"
                    placeholder="Select applicable unit"
                    formControlName = 'units'>
                    </ng-select>
                    <div class="text-sm text-red-500 ms-2 mt-1" *ngIf='formErrors.units'>
                        {{formErrors.units}}</div>
                </div>
            </div>
        </div>
        <div class="w-1/2 p-4 border-l-[1px] border-l-[#F7F9FA]" >
            <div class="border-b-[1px] border-b-[#F7F9FA] mb-2">
                <p class="Gilroy-Bold text-lg">Pricing Details</p>
                <div class="flex mb-3 ms-5 items-center">
                    <div class="w-1/5">
                        <p>{{'Selling Price:' | translate}}</p>
                    </div>
                    <div class="w-2/5">
                        <input type="number" min="0" formControlName="sellingPrice" class="w-full ps-2 border-0 bg-input h-10 rounded-xl" placeholder="Enter Selling price" />
                        <div class="text-sm text-red-500 ms-2 mt-1" *ngIf='formErrors.sellingPrice'>
                        {{formErrors.sellingPrice}}</div>
                    </div>
                </div>
                <div class="flex mb-3 ms-5 items-center">
                    <div class="w-1/5">
                        <p>{{'Sales Tax' | translate}}</p>
                    </div>
                    <div class="w-2/5">
                        <ng-select class="custom-selector border-0 p-0" [items]="taxes" 
                        bindLabel="taxName"
                        multiple='true'
                        formControlName = 'tax'
                        placeholder="Select applicable tax"
                        (change)='taxChange($event)'>
                        </ng-select>
                    </div>
                </div>
            </div>

            <div inventoryCheck *ngIf="(createProductForm?.controls['itemType'].value === 'Product' && createProductForm?.controls['isSale'].value === 'true') || createProductForm?.controls['isManage'].value === true" class="">
                <p class="Gilroy-Bold text-lg" >Stock Details</p>
                <div inventoryCheck *ngIf="(createProductForm?.controls['itemType'].value === 'Product' && createProductForm?.controls['isSale'].value === 'true') || createProductForm?.controls['isManage'].value === true" class="flex mb-3 ms-5 items-center">
                    <div class="w-1/5">
                        <p>{{'Opening Stock:' | translate}}</p>
                    </div>
                    <div class="w-2/5">
                        <input type="number" formControlName="openingStock" class="w-full ps-2 border-0 bg-input h-10 rounded-xl" placeholder="Enter Opening Stock" min="0" />
                        <div class="text-sm text-red-500 ms-2 mt-1" *ngIf='formErrors.openingStock'>
                            {{formErrors.openingStock}}</div>
                    </div>
                </div>
                <div inventoryCheck *ngIf="(createProductForm?.controls['itemType'].value === 'Product' && createProductForm?.controls['isSale'].value === 'true') || createProductForm?.controls['isManage'].value === true" class="flex mb-3 ms-5 items-center">
                    <div class="w-1/5">
                        
                        <p>{{'As of Date:' | translate}}</p>
                    </div>
                    <div class="w-2/5">
                        <input type="date" formControlName="asOfDate" class="w-full ps-2 border-0 bg-input h-10 rounded-xl" placeholder="Select Date" />
                        <div class="text-sm text-red-500 ms-2 mt-1" *ngIf='formErrors.asOfDate'>
                            {{formErrors.asOfDate}}</div>
                    </div>
                </div>
                <div inventoryCheck *ngIf="(createProductForm?.controls['itemType'].value === 'Product' && createProductForm?.controls['isSale'].value === 'true') || createProductForm?.controls['isManage'].value === true" class="flex mb-3 ms-5 items-center">
                    <div class="w-1/5">
                        <p>{{'Low Stock below:' | translate}}</p>
                    </div>
                    <div class="w-2/5">
                        <input type="number" formControlName="lowStock" class="w-full ps-2 border-0 bg-input h-10 rounded-xl" placeholder="Low stock quantity" min="0" />
                        <div class="text-sm text-red-500 ms-2 mt-1" *ngIf='formErrors.lowStock'>
                            {{formErrors.lowStock}}</div>
                    </div>
                </div>
                <div inventoryCheck class="flex mb-3 ms-5 items-center">
                    <div class="w-1/5">
                        <p>{{'Item Code:' | translate}}</p>
                    </div>
                    <div class="w-2/5">
                        <input type="text" formControlName="itemCode" class="w-full ps-2 border-0 bg-input h-10 rounded-xl" placeholder="Enter/Generate Item Code"  />
                        <div class="text-sm text-red-500 ms-2 mt-1" *ngIf='formErrors.itemCode'>
                            {{formErrors.itemCode}}</div>
                    </div >
                    <div class="ms-2" > 
                        <app-button (handleClick)="generateBarcode()" type="third" [buttonText]="createProductForm?.controls['itemCode'].value ? 'View Barcode' : 'Generate Barcode'"  ></app-button>
                    </div>
                </div>
                <div inventoryCheck class="flex mb-3 ms-5 items-center" >
                    <div class="w-1/5">
                        <p>{{'Store' | translate}}</p>
                    </div>
                    <div class="w-2/5">
                        <ng-select class="custom-selector border-0 p-0" [items]="stores" 
                        bindLabel="storeName"
                        bindValue="_id"
                        formControlName = 'store'
                        placeholder="Select Store"
                        >
                        </ng-select>
                        <div class="text-sm text-red-500 ms-2 mt-1" *ngIf='formErrors.store'>
                            {{formErrors.store}}</div>
                    </div>
                </div>
            </div>

            
            <div inventoryCheck *ngIf="createProductForm?.controls['itemType'].value === 'Product' || createProductForm?.controls['isManage'].value === true" class="">
                <div class="border-b-[1px] border-b-[#F7F9FA] mb-2"></div>
                <p class="Gilroy-Bold text-lg" >Bundled Items</p>
                <ng-container formArrayName='items'>
                    <ng-container *ngFor='let control of items.controls; let i = index;'>
                        <tr [formGroup]='control' class="">
                            <td class="w-3/12 p-2">
                                <ng-select [items]="availableProducts"
                                class="custom-selector"
                                bindLabel="name"
                                bindValue="_id"
                                formControlName="itemId"
                                [clearable]="false"
                                placeholder='{{"Select one" | translate}}'
                                (change) = "changeEvent($event, i)">
                            </ng-select>
                            </td>
                            <td class="p-2 w-1/12">
                            <fieldset>
                                <input id='quantity' formControlName='quantity' type="number" min="0" class="w-full p-3 rounded-xl bg-input border-0"/>
                            </fieldset>
                            </td>
                            <td class="p-2 w-1/12">
                                <fieldset>
                                    <input id='unit' formControlName='unit' type="string" class="w-full p-3 rounded-xl bg-input border-0" disabled/>
                                </fieldset>
                            </td>
                            <td class="w-1/12">
                                <svg (click)='removeItem(i)' xmlns="http://www.w3.org/2000/svg" name="delete" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="mx-auto h-5 w-5 text-black-500"><path _ngcontent-hsm-c58="" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"></path></svg>
                            </td>
                        </tr>
                        <tr>
                        <td colspan="1">
                            <p class="text-red-500" *ngIf='control.get("itemId").hasError("required") && control.get("itemId").touched'>{{'Atleast 1 unit is required'}}</p>
                        </td>
                        <td>
                            <p class="text-red-500" *ngIf='control.get("quantity").errors && control.get("quantity").touched'>{{'Atleast 1 unit is required'}}</p>
                        </td>
                        <td colspan="4" ></td>
                        </tr>
        
                    </ng-container>
                    <ng-container *ngIf='items.controls.length === 0'>
                    <tr>
                        <td colspan="8" class="text-center p-2">{{'No items added' | translate}}</td>
                    </tr>
                    </ng-container>
                </ng-container>
                <button (click)='addNewItem()' id="add" class="flex items-center Gilroy-Bold space-x-4">
                    <span  class="rounded-full bg-gradient text-white p-1 me-2">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="h-4 w-4">
                            <path  stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path>
                        </svg>
                    </span> {{'Add a Line' | translate}}
                </button>
            </div>
            <div class="text-sm text-red-500 ms-2 mt-1" *ngIf='formErrors.items'>
                {{formErrors.items}}</div>
            <!-- <div *ngIf="createProductForm?.controls['isSale'].value === 'true'" routeToSubscription fromComponent='inventory' class="hidden absolute bottom-6 z-[9999] w-[400px] left-0 right-0 m-auto">
                <app-send-to-sub from="inventory"></app-send-to-sub>
            </div> -->
        </div>
  
      </form>
      <!-- <div class="mt-4 bundled-product">
        <app-file-upload appAccessControl moduleType="storage" accessType="view" [addedFiles]="addedFiles" (emitter)="saveFiles($event)"></app-file-upload>
      </div> -->
    </div>
    <div footer class="flex w-full mt-4">
      <!-- <div class="w-1/5">
      </div> -->
      <div class="z-[99999]">
        <app-button *ngIf='!update' buttonText="{{'Save' | translate}}" (handleClick)="createProduct()" type="primary" rounded="full"></app-button>
        <app-button *ngIf='update'  buttonText="{{'Update' | translate}}"(handleClick)="updateProduct()" type="primary" rounded="full"></app-button>
        <app-button routerLink="/inventory/bundled-products" buttonText="{{'Cancel' | translate}}" type="third"  class="ml-2" rounded="full"></app-button>
      </div>
    </div>
</div>

  <app-modal-container [isOpen]='viewBarcode'>
    <div header class="border-b pb-2 Gilroy-Bold">{{'Barcode' | translate}}</div>
    <div content>
        <img class="mx-auto" width="300" height="300" [src]="barcode" alt="Barcode">
    </div>
    <div footer class="flex items-center space-x-2 rtl:space-x-reverse">
        <!-- <app-button buttonText='{{"Delete" | translate}}' type='danger' rounded=true (handleClick)='deleteProduct()'></app-button> -->
        <app-button buttonText='{{"Close" | translate}}' type='third' rounded=true (handleClick)='viewBarcode = false'></app-button>
    </div>
  </app-modal-container>