<div class="flex justify-center p-4">
    <div class="w-full flex flex-col space-y-4">
        <div class="flex items-center justify-between">
            <h1 class="text-2xl Gilroy-Bold inline-flex items-center">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 me-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z" />
                  </svg>
                {{'Customers' | translate}}
            </h1>
            <div class="flex space-x-4 rtl:space-x-reverse">
                <!-- <app-button buttonText='{{"Import Customer" | translate}}' type='border' size='large'></app-button> -->
                <app-button appAccessControl moduleType='sales' accessType='edit' routerLink="create-customer" buttonText='{{"Add a Customer" | translate}}' type='primary' size='large'></app-button>
            </div>
        </div>
        <!-- <div class="grid grid-cols-4 gap-4 mt-5">
            <div class="bg-gradient rounded-xl p-5 py-7 text-white">
                <p class="">Total Invoices Overdue</p>
                <h3 class="text-2xl">$0.00 <span class="text-sm">USD</span></h3>
                <p class="">Last Updated 10 minutes ago.</p>
            </div>
            <div class="bg-input rounded-xl p-5">
                <p class="Gilroy-Bold">Total Invoices Paid</p>
                <h3 class="text-2xl">$0.00 <span class="text-sm">USD</span></h3>
            </div>
        </div> -->
        <app-table-container [tableHeadings]='tableHeadings' [tableData]='tableData' [keys]='tableKeys' [actionTemplate]='actionTemplate'>
            <ng-template let-item #actionTemplate>
                <td align="right" class="p-2 last:pe-10 text-center">
                    <app-dropdown #dropdown>
                        <div button (click)='dropdown.open = true' (clickOutside)='dropdown.open = false'>
                            <div class="h-8 w-8 rounded-full flex justify-center items-center bg-gray-50 border border-gray-200">
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 m-auto leading-8 text-center" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                                </svg>
                            </div>
                        </div>
                        <div options>
                            <span (click)='viewCustomer(item._id)' class="cursor-pointer block px-4 py-2 text-sm Gilroy-Bold hover:bg-gray-50 text-center">  
                                {{'View' | translate}}  
                            </span>
                            <span appAccessControl moduleType='sales' accessType='edit' (click)='editCustomer(item._id)' class="cursor-pointer block px-4 py-2 text-sm Gilroy-Bold hover:bg-gray-50 text-center">{{'Edit' | translate}}</span>
                            <!-- <span appAccessControl moduleType='sales' accessType='edit' (click)='deleteConfirmation(item._id)' class="cursor-pointer block px-4 py-2 text-sm Gilroy-Bold hover:bg-gray-50 text-center">{{'Delete' | translate}}</span> -->
                        </div>
                    </app-dropdown>             
                </td>
            </ng-template>

            
        </app-table-container>
    </div>
</div>

<app-modal-container [isOpen]='deleteConfirmationModal'>
    <div header class="border-b pb-2 Gilroy-Bold">{{'Delete Customer' | translate}}</div>
    <div content>{{'Are you sure?' | translate}}</div>
    <div footer class="flex items-center space-x-2 rtl:space-x-reverse">
        <app-button buttonText='{{"Delete" | translate}}' type='danger' rounded=true (handleClick)='deleteCustomer()' ></app-button>
        <app-button buttonText='{{"Cancel" | translate}}' type='third' rounded=true (handleClick)='deleteConfirmationModal = false'></app-button>
    </div>
</app-modal-container>

