import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { DateValidator, dateLessThan, minDateValidator, valueChanges } from 'src/app/shared/utils/formValidator';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { RootReducerState } from 'src/app/store/reducers';
import { selectBusiness } from 'src/app/store/selectors/business.selector';
import { TaskServiceService } from './task-service.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { selectUser } from 'src/app/store/selectors/user.selectors';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';
import { UserService } from 'src/app/shared/services/user.service';
import { ChatsService } from '../chats/chats.service';
import { LanguageService } from 'src/app/shared/services/language.service';

@Component({
  selector: 'app-task-manager',
  templateUrl: './task-manager.component.html',
  styleUrls: ['./task-manager.component.scss']
})
export class TaskManagerComponent implements OnInit {
  businessId$: Observable<any>;
  unsubscribe = new Subject()
  businessId:any;
  taskToDelete: any;
  isDeleteModalOpen = false;
  taskFilters = [];
  isRecurringTaskUpdate = false;

  constructor(private fb : FormBuilder,
    private router : Router,
    private rootStore:Store<RootReducerState>,
    private taskService : TaskServiceService,
    private toast : ToastrService,
    private chatService: ChatsService,
    private spinner : NgxSpinnerService,
    private translateService: TranslateService,
    private userService: UserService,
    public datePipe: DatePipe,
    private languageService: LanguageService,
    private store: Store<RootReducerState>) { 
    this.businessId$ = rootStore.pipe(select(selectBusiness))
    this.user$ = this.store.pipe(select(selectUser))
  }
  user$: Observable<any>;

  // constructor(private fb : FormBuilder, private router : Router, private store: Store<RootReducerState>,) {
  //   this.user$ = this.store.pipe(select(selectUser));
  //  }
  @ViewChild('fileInput') fileInput!: ElementRef;
  createTaskForm:FormGroup;
  isOpen = false;
  allBusinessUsers: any;
  edit = false
  quickFilters = [
    {
      filterBy : 'All Tasks',
      status : 'allTasks',
      active : true,
    },
    {
      filterBy : 'In Progress',
      status : 'inProgress',
      active : false,
    },
    {
      filterBy : 'Upcoming',
      status : 'upcoming',
      active : false,
    },
    {
      filterBy : 'Completed',
      status : 'completed',
      active : false,
    },
    {
      filterBy : 'Overdue',
      status : 'overdue',
      active : false,
    },
    {
      filterBy : 'Assigned to Me',
      status : 'assignedToMe',
      active : false,
    },
  ]
  taskTypes = ['One Time', 'Recurring']
  tableHeadings = [
    'Task Name',
    'Assigned Member',
    'Task Type',
    'Date',
    'Status',
    'Action'
  ]
  tasks = [];
  tableKeys = ['taskTitle','assignToName','taskType']
  // users = ['Rahul Badwaya', 'Jaydeep Deshmukh','Gautam Patil','Puneet Rajwani']
  dragDropConfig = {
    showList:true,
    showProgress:false
}
taskId:any
taskRecurringId: any;
files:any = [];
userData: any;
filteredTask:any = this.tasks;
originalTask:any = this.tasks;
isRecurringTaskModal = false;
recurringDeleteType: string;
minDate = moment('01/01/1000').format('YYYY-MM-DD');
maxDate = moment('31/12/3000').format('YYYY-MM-DD');
today = moment().format('YYYY-MM-DD')
createTaskFormErrors = {
  taskTitle: '',
  description: '',
  startDate: '',
  // startTime: '',
  dueDate: '',
  endDate: '',
  taskType: '',
  assignToUserId: '',
  frequency: '',
  recurringUpdateType: '',
  // reminder: ''
};

createTaskFormErrorMessages = {
  taskTitle: {
    required: 'Field is required',
  },
  description: {
    required: 'Field is required',
  },
  // startTime: {
  //   required: 'Field is required',
  // },
  startDate: {
    required: 'Start Date is Required',
    pattern: 'Invalid Date',
    max: 'Invalid Date',
    // minDate: 'Start Date cannot be less than today',
    invalidDate: 'Invalid Date'
  },
  dueDate: {
    required: 'Due Date is Required',
    pattern: 'Invalid Date',
    max: 'Invalid Date',
    // minDate: 'Due Date cannot be less than today',
    invalidDate: 'Invalid Date',
    dates: 'Due Date cannot be before Start Date'
  },
  endDate: {
    required: 'End Date is Required',
    pattern: 'Invalid Date',
    invalidDate: 'Invalid Date',
    // minDate: 'Due Date cannot be less than today',
    dates: 'End Date cannot be before Due Date'
  },
  taskType: {
    required: 'Field is required',
  },
  assignToUserId: {
    required: 'Field is required',
  },
  frequency: {
    required: 'Field is required',
  },
  recurringUpdateType: {
    required: 'Field is required',
  },
  // reminder: {
  //   required: 'Field is required',
  // },
};

fileIdToBeDelete = new Set();
isEmailSettings = true;
emailSettingsModal = false;
mailFrequency = 'daily';
allNotifications: any;
lang: any;
notificationsCount: any;

  ngOnInit(): void {
    this.spinner.show();
    this.loadUserDetails();
    this.loadCreateTaskForm();
    this.languageService.getLanguage().subscribe((language) => {
      this.lang = language;      
    });

    this.chatService.listen('getAllNotifications').subscribe((data)=>{
      this.allNotifications = data.filter((item)=>((item.toUser?.includes(this.userData?._id) || item.toUser === this.userData?._id) && (item.fromUser !== this.userData?._id || (item.fromUser === this.userData?._id && item.entityType === 'taskReminder')))).slice().reverse();
      this.notificationsCount = this.allNotifications.filter((item)=>!item.seen).length;
    });
  }

  getBusinessId(){
    this.businessId$.pipe(takeUntil(this.unsubscribe)).subscribe(business=>{
      if(business?.businessId?._id){
        this.chatService.emit('getAllNotifications', this.userData._id);
        
        this.businessId = business?.businessId?._id;
        if(this.businessId) {
          this.loadAllBusinessTask();
          this.getAllBusinessUsers();
        }
      }
      console.log("business?", this.businessId);
    })
  }

  loadUserDetails(): void {
    let businessId = localStorage.getItem('selectedBusiness');

    this.userService.getUserDetails(businessId).subscribe((userResp) => {
      this.userData = userResp.data;
      this.spinner.hide();
      console.log("this.userData", this.userData, businessId);
      this.getBusinessId();
      this.isEmailSettings = this.userData?.taskEmailSettings?.isActivated;
      this.mailFrequency = this.userData?.taskEmailSettings?.mailFrequency;
    }, (error) => {
      this.spinner.hide();
    });
  }

  getAllBusinessUsers(): void {
    this.taskService.getAllBusinessUsers(this.businessId).subscribe((resp)=>{
      this.allBusinessUsers = resp.data.map(({ _id, firstName, lastName })=>({_id, name: _id === this.userData?._id ? 'Me' : firstName + ' ' + lastName}));
    })
  }

  loadCreateTaskForm() {
    this.createTaskForm = this.fb.group({
      taskTitle : [null, [Validators.required]],
      description : [null, [Validators.required]],
      dueDate: [null, [DateValidator()]],
      endDate: [null],
      startDate: [null, [DateValidator()]],
      // startTime : [null, [Validators.required]],
      // duration : [null],
      // dueTime : [null],
      taskType : [null, [Validators.required]],
      assignToUserId : [null, [Validators.required]],
      createdByUserId : [null],
      files : [null],
      frequency : [null],
      recurringUpdateType: [null],
      isRecurring: [null]
      // reminder : [null, [Validators.required]]
    }, {validator: [dateLessThan('startDate', 'dueDate'), dateLessThan('dueDate', 'endDate')]});
    this.createTaskForm.valueChanges.subscribe(() => {
      this.createTaskFormErrors = valueChanges(
        this.createTaskForm,
        { ...this.createTaskFormErrors },
        this.createTaskFormErrorMessages,
        this.translateService
      );
    });
    
    this.onValueChanges();
  }

  loadAllBusinessTask() {
    // console.log(this.businessId);
    
    this.spinner.show()
    this.taskService.getAllTask(this.businessId).subscribe({
      next : (response)=>{
        this.tasks = response.data;
        this.tasks = this.tasks.map((task)=>{
          if(task.status !== 'Completed' && !task.isStatusChanged) {
            
            task.dueDate = new Date(task.dueDate).toISOString().replace('Z', '');
            // console.log("timeZoneCheck", new Date().toLocaleString(), task.dueDate, moment(), moment().isAfter(moment(task.dueDate)));
            if(moment().isBefore(moment(task.dueDate))) {
              task.status = 'Upcoming'
            }
            if(moment().isAfter(moment(task.dueDate))) {
              task.status = 'Overdue'
            }
            if(moment(task.dueDate).format('DD/MM/YYYY') === moment().format('DD/MM/YYYY')) {
              task.status = 'In Progress'
            }
          }
          task.assignToName = task?.assignToUserId?._id === this.userData?._id ? 'Me' : task?.assignToUserId?.firstName + ' ' + task?.assignToUserId?.lastName
          // console.log(new Date(task.dueDate).toLocaleDateString(navigator.language));
          // console.log(task.dueDate);          
          return task
        })
        this.filteredTask = JSON.parse(JSON.stringify(this.tasks));
        this.originalTask = [...this.filteredTask];
        this.spinner.hide();
      },
      error : (error)=>{
        this.spinner.hide()
        console.log(error)
      }
    })
  }

  onValueChanges(): void {    
    this.createTaskFormErrors = valueChanges(
      this.createTaskForm,
      { ...this.createTaskFormErrors },
      this.createTaskFormErrorMessages,
      this.translateService
    );
  };

  applyQuickFilter(status:any) {
    this.quickFilters = this.quickFilters.map((filter:any)=>{
      if(filter.filterBy === status) {
        filter.active = true
      } else {
        filter.active = false
      }
      return filter
    })
    // this.filteredTask = this.tasks
    console.log('this.tasks', this.tasks);
    
    this.filteredTask = this.tasks.filter((task:any)=>{
      if(status === 'All Tasks') {
        return this.tasks
      }
      if(task.status === status) {
        return task
      }
      if(status === 'Assigned to Me') {
        return task.assignToUserId._id === this.userData._id;
      }
    })
    this.originalTask = [...this.filteredTask]
    console.log("this.filteredTask", this.filteredTask);
    
  }

  openCreateTask(){
    this.createTaskForm.reset();
    this.isOpen = true;
    this.edit = false;
    this.isRecurringTaskUpdate = false;
    this.createTaskForm.get('recurringUpdateType').clearValidators()
    this.createTaskForm.get('recurringUpdateType').setValue(null);
    this.files = [];
  }

  formData() {
    this.createTaskForm.get('createdByUserId').setValue(this.userData._id);
    if(this.createTaskForm.value.reminder === '10 Minutes') {
      this.createTaskForm.get('reminder').setValue(10)
    }
    if(this.createTaskForm.value.reminder === '1 Hour') {
      this.createTaskForm.get('reminder').setValue(60)
    }

    if(this.createTaskForm.value.reminder === '24 Hours') {
      this.createTaskForm.get('reminder').setValue(1440)
    }
    const payload = this.createTaskForm.value
    console.log(this.businessId);

    payload['businessId'] = this.businessId;
    const formData = new FormData()
    Object.keys(this.createTaskForm.value).forEach((key)=>{
      formData.append(key,this.createTaskForm.get(key)?.value)
    })
    formData.append('payload',JSON.stringify(this.createTaskForm.value))
    // if(this.files.length) {
    //   for(let i=0; i<this.files.length; i++) {
    //     formData.append(`file${i}`,this.files[i])
    //   }
    // }
    return formData
  }

  createTaskOrUpdate(){
    console.log("this.createTaskForm.value", this.createTaskForm);
    
    const payload = this.formData()
    console.log("payload", this.businessId, payload);
    if(!this.edit) {
       console.log(this.createTaskForm.value, payload);
       
    if(this.createTaskForm.invalid){
      this.createTaskForm.markAllAsTouched();
      this.onValueChanges();
      return;
    }
    
    this.spinner.show();
      this.taskService.createTask(this.businessId, payload).subscribe({
        next : (response)=>{
          console.log(response)
          this.spinner.hide();
          this.toast.success('Task created Successfully')
          this.isOpen = false;
          if(response.data.assignToUserId !== this.userData._id) {
            this.chatService.emit('notification', {
              entityId: response.data._id,
              entityType: 'taskAssigned',
              message: `${this.userData.firstName + ' ' + this.userData.lastName} has assigned you a task ${response.data.taskTitle}`,
              fromUser: this.userData._id,
              toUser: response.data.assignToUserId,
            });
          }
          setTimeout(() => {
            this.chatService.emit('getAllNotifications', this.userData._id);
          });
          this.loadAllBusinessTask();
        },
        error : (error)=>{
          this.spinner.hide()
          console.log(error);
          if(error.error.message === 'No Storage Plain available on user'){
            this.toast.error(error.error.message+'. Files cannot be attached to the task');
          } else {
            this.toast.error(error.error.message);
          }
        }
      })
    }
    if(this.edit) {
      if(this.createTaskForm.invalid){
        this.createTaskForm.markAllAsTouched();
        this.onValueChanges();
        return;
      }
      console.log("this.createTaskForm", this.createTaskForm.value.recurringUpdateType, this.isRecurringTaskUpdate);
      if(this.createTaskForm.value.recurringUpdateType === 'all') {
        console.log("all");
        this.spinner.show();
        this.taskService.updateRecurringTask(this.taskRecurringId, payload).subscribe((resp)=>{
          if(resp.success){
            this.updateSingleTask(payload);
          }
        }, (err)=>{
          this.spinner.hide();
          this.toast.error(err.error.message)
        })
      } else if(this.createTaskForm.value.recurringUpdateType === 'single' && this.isRecurringTaskUpdate) {
        console.log("single");
        payload['isSingleUpdated'] = true;
        this.updateSingleTask(payload);
      } else {
      console.log('onlyOne');
        this.updateSingleTask(payload);
      }
    }
  }

  updateSingleTask(payload): void {
    this.spinner.show();
    this.taskService.updateTask(this.taskId, payload).subscribe({
      next : (response)=>{
        console.log(response)
        this.spinner.hide();
        this.toast.success('Task Updated Successfully');
        if(response.data.assignToUserId !== this.userData._id) {
          this.chatService.emit('notification', {
            entityId: response.data._id,
            entityType: 'taskAssigned',
            message: `${this.userData.firstName + ' ' + this.userData.lastName} has assigned you a task ${response.data.taskTitle}`,
            fromUser: this.userData._id,
            toUser: response.data.assignToUserId,
          });
        }
        setTimeout(() => {
          this.chatService.emit('getAllNotifications', this.userData._id);
        });
        if(this.fileIdToBeDelete.size) {
          this.fileIdToBeDelete.forEach((id:any)=>{
            this.taskService.deleteFile(id).subscribe({
              next : (response)=>{
                console.log(response);
              },
              error : (error)=>{
                this.toast.error(error.error.message);
                console.log(error)
              }
            })
          })
        }
        this.isOpen = false
        this.edit = false
        this.loadAllBusinessTask()
      },
      error : (error)=>{
        this.spinner.hide();
        console.log(error);
        if(error.error.message === 'No Storage Plain available on user'){
          this.toast.error(error.error.message + '. Files cannot be attached to the task');
        } else {
          this.toast.error(error.error.message);
        }
      }
    }) 
  }

  openEditModel(task:any){
    this.createTaskForm.reset();
    this.files = [];
    this.edit = true;
    this.isOpen = true;
    this.taskId = task._id;
    this.taskRecurringId = task?.recurringReferenceId;
    console.log(task);
    
    this.isRecurringTaskUpdate = task.taskType === "Recurring" ? true : false;
    if(this.isRecurringTaskUpdate){
      this.createTaskForm.get('recurringUpdateType').setValidators([Validators.required])
    } else {
      this.createTaskForm.get('recurringUpdateType').clearValidators()
      this.createTaskForm.get('recurringUpdateType').setValue(null);
    }

    console.log(task.dueDate, task.endDate?.split('T')[0]);
    
    this.createTaskForm.get('taskTitle').setValue(task.taskTitle ?? null)
    this.createTaskForm.get('description').setValue(task.description ?? null)
    this.createTaskForm.get('dueDate').setValue(task.dueDate?.split('T')[0] ?? null)
    this.createTaskForm.get('endDate').setValue(task.endDate?.split('T')[0] ?? null)
    this.createTaskForm.get('startDate').setValue(task.startDate?.split('T')[0] ?? null)
    this.createTaskForm.get('taskType').setValue(task.taskType ?? null)
    this.createTaskForm.get('assignToUserId').setValue(task.assignToUserId?._id ?? null)
    this.createTaskForm.get('frequency').setValue(task.frequency ?? null)
    if(task.reminder === 10) {
      this.createTaskForm.get('reminder').setValue('10 Minutes')
    }
    if(task.reminder === 60) {
      this.createTaskForm.get('reminder').setValue('1 Hour')
    }

    if(task.reminder === 1440) {
      this.createTaskForm.get('reminder').setValue('24 Hours')
    }
    this.files = task.files;

    
  }

  viewTask(id:any) {
    this.router.navigate([`/task/view-task/${id}`])
  }

  getUploadedFiles(event:any) {
    // this.files.push(...event)
  }

  onFileSelect(event: any) {
    // this.files.push(...event.target.files)
  }

  removeFile(file:any) {
    this.files = this.files?.filter((f)=> (f?.lastModified ? f?.lastModified : f?._id) !== (file?.lastModified ? file?.lastModified : file?._id))
    console.log(this.files)
    if(file?._id) {
      this.fileIdToBeDelete.add(file?._id)
    }
  }

  clearFileInput() {
    this.fileInput.nativeElement.value = '';
  }

  ngOnDestroy() {
    this.unsubscribe.next()
    this.unsubscribe.complete() 
  }

  changeTaskType(event): void {
    console.log('changeTaskType', event );
    if(event === 'Recurring'){
      this.createTaskForm.get('frequency').setValidators([Validators.required])
      this.createTaskForm.get('endDate').setValidators([DateValidator()]);
      this.createTaskForm.get('isRecurring').setValue(true);
    } else {
      this.createTaskForm.get('frequency').clearValidators()
      this.createTaskForm.get('frequency').setValue(null);
      this.createTaskForm.get('endDate').clearValidators();
      this.createTaskForm.get('endDate').setValue(null);
      this.createTaskForm.get('isRecurring').setValue(false);
    }
  }

  openDeleteModal(item): void {
    console.log(item);
    if(item.taskType === "Recurring") {
      this.taskToDelete = item;
      this.isRecurringTaskModal = true;
      this.recurringDeleteType = '';
    } else {
      this.taskToDelete = item;
      this.isDeleteModalOpen = true;
    }
  }

  confirmRecurringDelete(): void {
    console.log("this.recurringDeleteType", this.recurringDeleteType);
    this.spinner.show();
    //single all
    if(this.recurringDeleteType === 'all') {
      this.taskService.deleteRecurringTask(this.taskToDelete.recurringReferenceId).subscribe((resp)=>{
        if(resp.success){
          this.taskService.deleteTask(this.taskToDelete._id).subscribe((resp)=>{
            console.log("resp", resp);
            this.toast.success('Recurring tasks have been deleted');
            this.spinner.hide();
            this.closeDeleteModal();
            this.loadAllBusinessTask();
          }, (err)=>{
            this.spinner.hide();
          })
        }
      }, (err)=>{
        this.spinner.hide();
      })
    } else {
      this.confirmDelete();
    }
    
  }

  closeDeleteModal(): void {
    this.isDeleteModalOpen = false;
    this.isRecurringTaskModal = false;
  }

  confirmDelete(): void {
    this.spinner.show();
    this.taskService.deleteTask(this.taskToDelete._id).subscribe((resp)=>{
      console.log("resp", resp);
      this.toast.success('Task has been deleted');
      this.spinner.hide();
      this.closeDeleteModal();
      this.loadAllBusinessTask();
    }, (err)=>{
      this.spinner.hide();
    })
  }

  isCreatedByMe(item): boolean {
    return item.createdByUserId === this.userData._id;
  }

  filterByStatus(event, type): void {
   const isChecked = event.target.checked;
  //  this.filteredTask = [...this.filteredTask];
   if(isChecked){
      this.taskFilters.push(type);
    } else {
      const index = this.taskFilters.indexOf(type);
      this.taskFilters.splice(index, 1);
      this.filteredTask = [...this.originalTask];
      console.log("this.filteredTask1", this.filteredTask, this.originalTask);
    }
    
    if(this.taskFilters.includes('All') && this.taskFilters.includes('Today(s) task')){
      this.filteredTask = [...this.originalTask];
      console.log("this.filteredTask2", this.filteredTask, this.originalTask);
    } else if(this.taskFilters.includes('Today(s) task')) {
      this.filteredTask = this.originalTask.filter((task:any)=>{
        if(moment(task.dueDate).format('DD/MM/YYYY') === moment().format('DD/MM/YYYY')){
          return task;
        }
      })
    } else if(this.taskFilters.includes('All')) {
      this.filteredTask = [...this.originalTask];
      console.log("this.filteredTask3", this.filteredTask, this.originalTask);
      
    }
  }

  dateChange(event): void {
    var convertedDate = event.target.value.split('-');
    convertedDate[1] = convertedDate[1] - 1
    // console.log(event.target.value, new Date(event.target.value), new Date(Date.UTC(...convertedDate)));
    
  }

  openCloseEmailSettingsModal(): void {
    this.isEmailSettings = this.userData?.taskEmailSettings?.isActivated;
    console.log(this.isEmailSettings, this.userData?.taskEmailSettings?.isActivated);
    this.mailFrequency = this.userData?.taskEmailSettings?.mailFrequency;
  }

  confirmEmailSettings(): void {
    console.log("confirmEmailSettings", this.mailFrequency);
    this.spinner.show();
    const payload = {
      isActivated: this.isEmailSettings,
      mailFrequency: this.mailFrequency
    }
    this.taskService.addEmailSettings(this.userData._id, payload).subscribe((resp)=>{
      if(resp.success) {
        // this.spinner.hide();
        this.emailSettingsModal = false;
        this.toast.success(resp.message);
        this.loadUserDetails();
      }
    }, (err)=>{
      this.spinner.hide();
    })
  }

  logout(): void {
    this.spinner.show();
    this.userService.logoutAPI().subscribe((resp) => {
      this.spinner.hide();
      if (resp.success) {
        this.userService.logout();
      }
    }, (error) => {
      this.spinner.hide();
      console.log(error);
      this.toast.error('Something went wrong');
    })
  };

  goToNotification(item){
    console.log(item);
    switch (item.entityType) {
      case "taskAssigned":
        const payload = {
          seen: true,
          updateId: item._id
        }
        this.chatService.emit('updateNotification', payload);
        this.router.navigate([`/task/view-task/${item.entityId}`]);
        break;
      case "sharedFile":
        const payloadThree = {
          seen: true,
          updateId: item._id
        }
        this.chatService.emit('updateNotification', payloadThree);
        this.router.navigate([`/files/shared`]);
      break;
      case "taskReminder":
        const payloadTwo = {
          seen: true,
          updateId: item._id
        }
        this.chatService.emit('updateNotification', payloadTwo);
        this.router.navigate([`/task/view-task/${item.entityId}`]);
      break;
      case "mention":
        const payloadFour = {
          seen: true,
          updateId: item._id
        }
        this.chatService.emit('updateNotification', payloadFour);
        this.chatService.setredirectionChatId(item.entityId);
        this.router.navigate([`/chats`]);
        break;
    }

  }
}
