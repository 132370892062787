<div [ngClass]='isOpen ? "" : "hidden"' class="fixed z-30 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
    <div class="flex modal-mobile-height items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
      <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
  
      <!-- This element is to trick the browser into centering the modal contents. -->
      <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
      <div class="inline-block modal-mobile-view align-bottom bg-white rounded-lg text-start shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-xl sm:w-full">
        <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4 rounded-t-lg">
            <div class="text-lg mb-4">
                <ng-content select='[header]'></ng-content>
            </div>
            <div class="mb-4">
                <ng-content select='[content]'></ng-content>
            </div>
        </div>
        <div class="bg-gray-50 px-4 py-3 sm:flex space-x-2 rounded-b-lg">
            <ng-content select='[footer]'></ng-content>      
        </div>
      </div>
    </div>
</div>