<div class=" flex space-x-2 p-4">
    <div class="w-3/4 mx-5">
        <div class="flex items-center justify-between">
          <h1 class="text-2xl Gilroy-Bold inline-flex items-center">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 me-2" fill="none" viewBox="0 0 24 24"
              stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z" />
            </svg>
            {{"Recurring Bill" | translate}} #{{billData?.recurringBillNumber ?? ''}}
          </h1>
          <div class="space-x-2 rtl:space-x-reverse" >
              <app-button *ngIf="!billData?.isCanceled && !billData?.isEnded" type='danger' buttonText='{{"Cancel Recurring" | translate}}' size='large' (handleClick)="showStopModal = true"></app-button>
              <app-button *ngIf="!billData?.isCanceled && !billData?.isEnded" type='third' buttonText='{{"Update End Date" | translate}}' size='large' (handleClick)="showExtendModal = true" ></app-button>
              <app-button appAccessControl moduleType='sales' accessType='edit' type='primary' buttonText='{{"Create another Bill" | translate}}' size='large' routerLink='../create-bill'></app-button>
          </div>
        </div>
      <div class="flex justify-between p-6 my-3">
        <div class="flex space-x-8 rtl:space-x-reverse">
          <div>
            <p class="Gilroy-Medium text-lg">{{'Status' | translate}}</p>
            <button [ngClass]="!billData?.isCanceled ? 'bg-green-400' : 'bg-red-800'" class="Gilroy-Bold h-8  rounded-full text-white w-28">{{billData?.isCanceled ? 'Stopped' : billData?.isEnded ? 'Completed' : 'Active' | translate}}</button>
          </div>
          <div>
            <p class="Gilroy-Medium text-lg">{{'Vendor' | translate}}</p>
            <h3 class="Gilroy-Bold text-xl">{{billData?.vendor?.vendorId?.vendorName | titlecase}}</h3>
          </div>
        </div>
        <div class="flex space-x-8 rtl:space-x-reverse">
          <div>
            <p class="Gilroy-Medium text-lg">{{'Payment Terms' | translate}}</p>
            <h3 class="Gilroy-Bold text-xl">{{billData?.paymentTerm + ' (' + billData?.dueDateDuration + ' Days)'}}</h3>
          </div>
        </div>
      </div>
      <div class="flex items-center justify-between rounded-xl border-custom p-6 my-3">
        <div class="flex">
            <img src="/assets/images/icon-1.svg" class="me-4" />
            <div>
              <p class="Gilroy-Medium text-lg">{{'Create' | translate}}</p>
              <h4 class="Gilroy-Medium text-lg">{{'Created:' | translate }} {{billData?.createdAt | date: 'longDate'}}</h4>
            </div>
        </div>
        <div class="justify-self-end">
          <p class="Gilroy-Medium text-lg">{{'Frequency' | translate}}</p>
          <h4 class="Gilroy-Medium text-lg">{{billData?.frequency}}</h4>
        </div>
      </div>
      <div class="rounded-xl border-custom p-6 my-3">
        <div>
            <p class="Gilroy-Medium text-lg">{{ billData?.isCanceled ? 'Canceled Date' : billData?.isEnded ? 'Completion Date' : 'Next Bill Date' | translate}}</p>
            <h4 class="Gilroy-Medium text-lg">{{ (billData?.isCanceled ? billData?.cancelDate : billData?.isEnded ? billData?.endDate : billData?.nextBillDate) | date: 'longDate'}}</h4>
          </div>
      </div>
      <!-- <div class="rounded-xl border-custom my-4 p-3 flex flex-col justify-between">
        <app-button appAccessControl moduleType="storage" accessType="edit" type='primary' class="mb-3 ml-auto" buttonText='{{"Upload Files" | translate}}' size='large' (handleClick)="uploadFiles()" ></app-button>
        <app-file-upload appAccessControl moduleType="storage" accessType="view" [addedFiles]="addedFiles" (emitter)="saveFiles($event)" ></app-file-upload>
      </div> -->
      <div>
        <!-- <app-template-one *ngIf='customizationSettings?.selectedTemplate === "modern"' [billData]='billData2'></app-template-one>
        <app-template-two *ngIf='customizationSettings?.selectedTemplate === "classic"' [invoiceData]='invoiceData2'></app-template-two>
        <app-template-three *ngIf='customizationSettings?.selectedTemplate === "contemporary"' [invoiceData]='invoiceData2'></app-template-three> -->
            <div class="w-full">
                <div class="py-8 px-12">
                    <table class="w-full border-collapse">
                        <tr>
                            <td class="w-1/2">
                                <table>
                                    <tr >
                                        <td valign='top' class="Gilroy-Bold" style="color: #0C4D71;">{{'BILL TO' | translate }}</td>
                                        <td class="ps-4 text-start">
                                            <div>{{billData?.vendor?.vendorId?.vendorName | titlecase}}</div>
                                            <div>{{billData?.vendor?.vendorId?.address?.addressLine1}}</div>
                                            <div>{{billData?.vendor?.vendorId?.address?.addressLine2}}</div>
                                            <div>{{billData?.vendor?.vendorId?.address?.city}}, {{billData?.vendor?.vendorId?.address?.state}}</div>
                                            <div>{{billData?.vendor?.vendorId?.address?.postalCode}}</div>
                                            <div>{{billData?.vendor?.vendorId?.address?.country}}</div>
                                            <div>{{billData?.vendor?.vendorId?.contactDetails?.mobileNumber}}</div>
                                        </td>
                                    </tr>
                                </table>
                            </td>
                            <td >
                                <table class="border-collapse text-end w-full">
                                    <tr>
                                        <td class="Gilroy-Bold" style="color: #0C4D71;">{{'Recurring Bill Number' | translate }}</td>
                                        <td class="ps-4" >{{billData?.recurringBillNumber}}</td>
                                    </tr>
                                    <tr>
                                        <td class="Gilroy-Bold" style="color: #0C4D71;">{{'PO Number' | translate }}</td>
                                        <td class="ps-4" >{{billData?.purchaseOrder}}</td>
                                    </tr>
                                    <tr>
                                        <td class="Gilroy-Bold" style="color: #0C4D71;">{{'Start Date' | translate }}</td>
                                        <td class="ps-4" >{{billData?.startDate | date}}</td>
                                    </tr>
                                    <tr>
                                        <td class="Gilroy-Bold" style="color: #0C4D71;">{{'End Date' | translate }}</td>
                                        <td class="ps-4" >{{billData?.endDate | date}}</td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                    </table>
                    <table class="w-full" style="margin-top: 2em; margin-bottom: 2em; border-bottom: 1px solid black;">
                        <thead>
                            <tr>
                                <td class="Gilroy-Bold p-2 text-white" style="background-color: #0C4D71;">{{'Item Name' | translate}}</td>
                                <td class="Gilroy-Bold p-2 text-white" style="background-color: #0C4D71;">{{'SKU' | translate }}</td>
                                <td class="Gilroy-Bold p-2 text-white" style="background-color: #0C4D71;">{{'HSN/SAC' | translate }}</td>
                                <td class="Gilroy-Bold p-2 text-white" style="background-color: #0C4D71;">{{'Quantity' | translate }}</td>
                                <td class="Gilroy-Bold p-2 text-white" style="background-color: #0C4D71;">{{'Unit Price' | translate }}</td>
                                <td class="Gilroy-Bold p-2 text-white" style="background-color: #0C4D71;">{{'Tax' | translate }}</td>
                                <td class="Gilroy-Bold p-2 text-white" style="background-color: #0C4D71;">{{'Total Amount' | translate }}</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="" *ngFor='let item of billData?.items'>
                                <td class="p-2" >{{item.item}}</td>
                                <td class="p-2" >{{item.sku }}</td>
                                <td class="p-2" >{{item.hsn_sac }}</td>
                                <td class="p-2" >{{item.unit }}</td>
                                <td class="p-2" >{{item.price | currency: billData?.currencyDetails?.currencySymbol : 'symbol-narrow' : number}}</td>
                                <td class="p-2" *ngIf='item?.tax[0]?.tax'>{{item?.tax[0]?.tax}}%</td>
                                <td class="p-2" *ngIf='!item?.tax[0]?.tax'>No Tax</td>
                                <td class="p-2" >{{item.unit * item.price | currency: billData?.currencyDetails?.currencySymbol : 'symbol-narrow' : number}}</td>
                            </tr>
                        </tbody>
                    </table>
                    <table class="w-full mb-8">
                        <tr>
                            <td class="w-4/6"></td>
                            <td class="w-2/6" >
                                <table class="w-full">
                                    <tr>
                                        <td class="Gilroy-Bold" style="color: #0C4D71;">{{'Subtotal' | translate }}</td>
                                        <td class="text-end">{{billData?.subtotal | currency: billData?.currencyDetails?.currencySymbol : 'symbol-narrow' : number}}</td>
                                    </tr>
                                    <tr>
                                        <td class="pb-2 Gilroy-Bold" style="color: #0C4D71;">{{'Tax' | translate }}</td>
                                        <td class="pb-2 text-end" >{{billData?.tax | currency: billData?.currencyDetails?.currencySymbol : 'symbol-narrow' : number}}</td>
                                    </tr>
                                    <tr class="border-t">
                                        <td class="Gilroy-Bold pt-2" style="color: #0C4D71;">{{'Total' | translate }}</td>
                                        <td  class="Gilroy-Bold pt-2 text-end">{{billData?.totalAmount | currency: billData?.currencyDetails?.currencySymbol : 'symbol-narrow' : number}}</td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                    </table>
        
                    <div *ngIf='billData?.notesTerms' class="Gilroy-Bold" style="color: #0C4D71;">{{'Terms and Conditions' | translate }}</div>
                    <pre *ngIf='billData?.notesTerms' id='pre' class="whitespace-pre-wrap Gilroy">{{billData?.notesTerms}}</pre>
                    <div class="w-full justify-end flex space-x-2 rtl:space-x-reverse">
                        <h5 class="Gilroy-Bold">{{"Issued By" | translate }}:</h5>
                        <div>{{billData?.userId?.firstName ? billData?.userId?.firstName+' '+billData?.userId?.lastName : ''}}</div>
                    </div>
                </div>
            </div>
      </div>
    </div>
    <div class="w-1/4 border-s border-gray-200 ps-10 " > 
        <h2 class="Gilroy-Bold text-2xl inline-flex items-center">{{'Bills Created' | translate}} - </h2>
        <div class="space-y-3 mt-5">
            <ul>
                <li (click)="viewBill(bill?._id)" class="list-disc border-custom px-3 py-2 rounded flex justify-between items-center box-shadow" *ngFor="let bill of createdBills; let i = index">
                    <a target="_blank" class=" Gilroy-SemiBold text-blue-600 hover:underline cursor-pointer text-lg" >{{bill?.billNumber}}</a>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 me-2">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3" />
                      </svg>                      
                </li>
            </ul>
        </div>
    </div>
</div>

<app-modal-container [isOpen]='showStopModal'>
    <div header class="Gilroy-Bold">
      {{'Stop Recurring Bill' | translate}}
    </div>
    <div class="space-y-2" content>
      <p class="Gilroy-SemiBold">{{'Are you Sure?' | translate}}</p>
      <p class="Gilroy">{{'Stopping recurring bill will not create any bill from this template in NuMetirc' | translate}}</p>
    </div>
    <div footer class="space-x-2 rtl:space-x-reverse">
      <app-button type='danger' buttonText='{{"Stop" | translate}}' (handleClick)='stopRecurringBill()'></app-button>
      <app-button type='third' buttonText='{{"Cancel" | translate}}' (handleClick)='showStopModal=false'></app-button>
    </div>
  </app-modal-container>

  <app-modal-container [isOpen]='showExtendModal'>
    <div header class="Gilroy-Bold">{{'Select New End Date' | translate}}</div>
    <div content class="flex flex-col" style="overflow-y: auto">
      <label for="">{{'New end date' | translate}}</label>
      <input [formControl]='updatedDate' [min]="minDate" type="date" class="w-1/3 rounded-xl bg-gray-100 border-0 p-3">
      <div *ngIf='updatedDate.getError("required") && updatedDate.dirty' class="text-sm text-red-400 ms-2">{{'End date is required' | translate}}</div>
      <div *ngIf='updatedDate.getError("invalidDate") && updatedDate.dirty && !updatedDate.getError("required")' class="text-sm text-red-400 ms-2">{{'End date must be in correct format' | translate}}</div>
    </div>
    <div footer class='flex space-x-2 rtl:space-x-reverse'>
      <app-button type='primary' buttonText='{{"Save" | translate}}' (handleClick)='updateBillDate()'></app-button>
      <app-button type='third' buttonText='{{"Cancel" | translate}}' (handleClick)='showExtendModal = false'></app-button>
    </div>
  </app-modal-container>