<div>
    <form [formGroup]='form'>
        <div class="flex mb-3">
            <div class=" w-full ">
                <div class="bg-proile rounded-xl bg-white px-3 py-2">
                    <fieldset [disabled]='!formEditable'>
                        <label for="date" class="block text-sm Gilroy-Bold font-color-05">{{'Date' | translate}}</label>
                        <input  id="date" formControlName="date" type="date"  class="w-full p-0 border-0 focus:ring-0" >
                        <span class="text-red-500 text-sm" *ngIf='formErrors?.date'>{{formErrors.date}}</span>
                    </fieldset>
                </div>
            </div>
        </div>
        <div class="flex mb-3">
            <div class="bg-proile rounded-xl bg-white px-3 py-2 w-full">
                <label for="firstName" class="block text-sm Gilroy-Bold font-color-05">{{'Description' | translate}}</label>
                <textarea formControlName="description" placeholder="{{'Enter Description' | translate}}" name="description" class="w-full p-0 focus:ring-0 h-20 border-none bg-proile" ></textarea>
            </div>
        </div>
        <div class="flex mb-3 ">
            <div class="bg-proile rounded-xl bg-white px-3 py-2 w-full">
                <label for="firstName" class="block text-sm Gilroy-Bold font-color-05">{{'Credit Account' | translate}}</label>
                <ng-select
                [items]="accounts$ | async"
                class="w-full p-0 border-0 focus:ring-0 back-chnage-custom back-chnage-custom"
                bindLabel="accountName"
                placeholder="{{'Select Type' | translate}}"
                formControlName="creditFrom"
                [readonly]='!formEditable'
                groupBy='accountType'>
              </ng-select>
              <span class="text-red-500 text-sm" *ngIf='formErrors?.account'>{{formErrors.account}}</span>
            </div>
        </div>
        <div class="flex mb-3 ">
            <div class="bg-proile rounded-xl bg-white px-3 py-2 w-full">
                <label for="firstName" class="block text-sm Gilroy-Bold font-color-05">{{'Debit Account' | translate}}</label>
                <ng-select
                [items]="accounts$ | async"
                class="w-full p-0 border-0 focus:ring-0 back-chnage-custom back-chnage-custom"
                bindLabel="accountName"
                placeholder="{{'Select Type' | translate}}"
                formControlName="debitTo"
                [readonly]='!formEditable'
                groupBy='accountType'>
              </ng-select>
              <span class="text-red-500 text-sm" *ngIf='formErrors?.account'>{{formErrors.account}}</span>
            </div>
        </div>
        <div class="flex mb-3">
            <div class="bg-proile rounded-xl bg-white px-3 py-2 w-full">
                <label for="firstName" class="block text-sm Gilroy-Bold font-color-05">{{'Category' | translate}}</label>
                <fieldset disabled="disabled">
                    <input type="text" formControlName='category' class="w-full p-0 border-0 bg-transparent focus:ring-0">
                </fieldset>
            </div>
        </div>
        <div class="flex mb-3">
            <div class="bg-proile rounded-xl bg-white px-3 py-2 w-full">
                <label for="" class="block text-sm Gilroy-Bold font-color-05">{{'Amount' | translate}}</label>
                <fieldset [disabled]="!formEditable">
                    <input formControlName='amount' type="number" class="w-full p-0 border-0 bg-transparent focus:ring-0" placeholder="{{'Amount' | translate}}" >
                </fieldset>
                <span class="text-red-500 text-sm" *ngIf='formErrors?.amount'>{{formErrors.amount}}</span>
                <span class="text-red-500 text-sm" *ngIf='invalidAmount'>{{'Invalid Amount'}}</span>
            </div>
        </div>
    </form>
</div>