import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { RootReducerState } from 'src/app/store/reducers';
import { RecurringInvoicesService } from '../recurring-invoices.service';
import { NumberService } from 'src/app/shared/services/number.service';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SalesService } from '../../../sales.service';
import { selectCustomers } from '../../../state/selectors/customers.selector';
import { selectProjects } from 'src/app/store/selectors/project.selector';
import { selectBusiness } from 'src/app/store/selectors/business.selector';
import { takeUntil } from 'rxjs/operators';
import * as moment from 'moment';

@Component({
  selector: 'app-list-invoices',
  templateUrl: './list-invoices.component.html',
  styleUrls: ['./list-invoices.component.scss']
})
export class ListInvoicesComponent implements OnInit {

  tableData = [];
  tableKeys = [
    'recurringInvoiceNumber',
    'customerName',
    'createdAt',
    'startDate',
    'endDate',
    'invoiceCount',
    'totalAmount',
  ];
  tableHeadings = [
    'Recurring Invoice Number',
    'Customer Name',
    'Created At',
    'Start Date',
    'End Date',
    'Invoices Created',
    'Total Amount',
    'Status',
    'Actions'
  ];
  customers = [];
  number = '1.2-2';
  currencyDetails = {
    currency: '',
    currencySymbol: ''
  };
  projects = [];

  unsubscribe$ = new Subject();
  business$: Observable<any>;
  customers$: Observable<any>;
  projects$: Observable<any>;


  constructor(
    private router: Router,
    private store: Store<RootReducerState>,
    private invoiceService: RecurringInvoicesService,
    private numberService: NumberService,
    private translateService: TranslateService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private salesService: SalesService
  ) {
    this.customers$ = store.pipe(select(selectCustomers));
    this.projects$ = this.store.pipe(select(selectProjects));
    this.business$ = store.pipe(select(selectBusiness));
   }

  ngOnInit(): void {
    this.getCurrencyDetails();
    this.loadNumberConfig();
    this.loadCustomers();
    this.loadProjects();
    this.loadInvoices();
  }

  getCurrencyDetails():void {
    this.salesService.currencyDetail
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((details: any)=>{
      if(details)
      this.currencyDetails = details;
    })
  }

  loadNumberConfig(): void {
    this.numberService.decimalSize
    .pipe((takeUntil(this.unsubscribe$)))
    .subscribe((number) => {
      this.number = `1.${number}-${number}`
    })
  }

  loadCustomers(): void {
    this.customers$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((customers) => {
      this.customers = customers;
    },(err)=>{
      this.toastr.error(this.translateService.instant(err?.error?.message ??'Something went wrong!'));
    });
  }

  loadProjects(): void {
    this.projects$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((projects) => {
      this.projects = projects;
    },(err)=>{
      this.toastr.error(this.translateService.instant(err?.error?.message ?? 'Something went wrong!'));
    });
  }

  loadInvoices(): void {
    this.spinner.show();
    this.invoiceService.getRecurringInvoicesList()
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(resp => {
      if(resp?.success){
        this.tableData = this.mapDataForTable(resp?.data);
      }
      else{
        this.toastr.error(resp?.message)
      }
      this.spinner.hide();
    }, (err) => {
      this.toastr.error(err?.error?.message);
      this.spinner.hide();
    })
  }

  mapDataForTable(invoices) {
    return invoices?.map(invoice => {
      return {
        _id: invoice?._id,
        recurringInvoiceNumber: invoice?.recurringInvoiceNumber,
        customerName: invoice?.customerDetails?.customerName,
        createdAt: moment(invoice?.createdAt).format('DD-MM-YYYY'),
        startDate: moment(invoice?.startDate).format('DD-MM-YYYY'),
        endDate: moment(invoice?.endDate).format('DD-MM-YYYY'),
        invoiceCount: invoice?.invoiceCount ?? 0,
        isCanceled: invoice?.isCanceled,
        isEnded: invoice?.isEnded,
        totalAmount: invoice?.totalAmount ?? 0
      }
    })
  }

  viewInvoice(id: string): void {
    console.log(id);
    
    this.router.navigate(['/sales/recurring-invoices/view-template'], { queryParams: { id }})
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
