import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { select, State, Store } from '@ngrx/store';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { ResetErrors } from 'src/app/store/actions/reset.actions';
import { UserLogin } from 'src/app/store/actions/user.actions';
import { IUserLogin, User } from 'src/app/store/models/user';
import { RootReducerState } from 'src/app/store/reducers';
import { selectUser, selectUserError, selectUserLoading } from 'src/app/store/selectors/user.selectors';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  user$: Observable<User>;
  userLoading$: Observable<boolean>;
  userLoginError$: Observable<any>;
  loginForm: FormGroup;
  loginRespMessage = '';
  showPassword = false;
  formErrors = {
    email: '',
    password: ''
   };
  loginErrorMessages = {
    email: {
      required: 'Email is required',
      pattern: 'Email must be in correct format'
    },
    password: {
      required: 'Password is required',
      minlength: 'Password must be of least 8 characters',
      maxlength: 'Password cannot be more than of 128 characters'
    }
  };

  constructor(private store: Store<RootReducerState>, private fb: FormBuilder, private spinner: NgxSpinnerService) {
    this.user$ = this.store.pipe(select(selectUser));
    this.userLoading$ = this.store.pipe(select(selectUserLoading));
    this.userLoginError$ = this.store.pipe(select(selectUserError));
  }

  ngOnInit(): void {
    this.loadForm();
    this.store.dispatch(ResetErrors());
    this.userLoading$.subscribe((loading) => {
      loading ? this.spinner.show() : this.spinner.hide();
    });
  }

  loadForm(): void {
    this.loginForm = this.fb.group({
      email: ['', [Validators.required, Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]],
      password: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(128)]]
    });
    this.loginForm.valueChanges.subscribe((data) => {
      this.onValueChanges();
    });
    this.onValueChanges();
  }

  onValueChanges(): void{
    if (!this.loginForm) { return; }
    const form = this.loginForm;
    for (const field in this.formErrors) {
      if (this.formErrors.hasOwnProperty(field)) {
        this.formErrors[field] = '';
        const control = form.get(field);
        if (control && (control.dirty || control.touched) && !control.valid) {
          const messages = this.loginErrorMessages[field];
          for (const key in control.errors) {
            if (control.errors.hasOwnProperty(key)) {
              this.formErrors[field] += messages[key] + ' ';
            }
          }
        }
      }
    }
  }

  login(): void{
    if (this.loginForm.invalid) {
      this.loginForm.markAllAsTouched();
      this.onValueChanges();
      return;
    }
    const creds: IUserLogin = {...this.loginForm.value};
    this.store.dispatch(UserLogin(creds));
  }

}
