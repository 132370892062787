<div  class="flex justify-center p-4">
    <div class="w-full flex flex-col space-y-4">
        <div>
            <div class="flex items-center justify-between">
                <div class="">
                    <h1 class="text-2xl Gilroy-Bold inline-flex items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 me-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z" />
                          </svg>
                        {{'Recurring-Bills' | translate}}
                    </h1>
                </div>
                <div class="">
                    <app-button appAccessControl moduleType='purchase' accessType='edit' size='large' buttonText='{{"Create Recurring Bill" | translate}}' type='primary' routerLink='create-bill'></app-button>
                </div>
            </div>
        </div>
        <app-table-container [tableHeadings]='tableHeadings' [tableData]='tableData' [keys]='tableKeys' [actionTemplate]='actionTemplate'>
            <ng-template let-item #actionTemplate>
                <td>
                    <p class="Gilroy" [ngClass]=" item?.isCanceled ? 'text-red-600' : 'text-green-500' " >{{item?.isCanceled ? 'Stopped' : item?.isEnded ? 'Completed' : 'Active' }}</p>
                </td>
                <td class="p-2 text-center last:pe-10">
                    <app-dropdown #dropdown>
                        <div button (click)='dropdown.open = true' (clickOutside)='dropdown.open = false'>
                            <div class="h-8 w-8 rounded-full flex justify-center items-center bg-gray-50 border border-gray-200">
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                                </svg>
                            </div>
                        </div>
                        <div options>
                            <div (click)='viewBill(item._id)' class="cursor-pointer px-4 py-2 text-sm Gilroy-Bold hover:bg-gray-50 text-center">{{'View' | translate}}</div>
                        </div>
                    </app-dropdown>             
                </td>
            </ng-template>
        </app-table-container>
    </div>
</div>
