import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ForgotPasswordComponent } from './auth-components/forgot-password/forgot-password.component';
import { InvitationComponent } from './auth-components/invitation/invitation.component';
import { LoginComponent } from './auth-components/login/login.component';
import { PersonalDetailsComponent } from './auth-components/personal-details/personal-details.component';
import { ResetPasswordComponent } from './auth-components/reset-password/reset-password.component';
import { SelectBusinessComponent } from './auth-components/select-business/select-business.component';
import { SignupAdminInviteComponent } from './auth-components/signup-admin-invite/signup-admin-invite.component';
import { SignupForInvitedComponent } from './auth-components/signup-for-invited/signup-for-invited.component';
import { SignupComponent } from './auth-components/signup/signup.component';
import { SubscriptionPlansComponent } from './auth-components/subscription-plans/subscription-plans.component';
import { VerifyAlternateEmailComponent } from './auth-components/verify-alternate-email/verify-alternate-email.component';
import { VerifySubcriptionComponent } from './auth-components/verify-subcription/verify-subcription.component';
import { AuthContainerComponent } from './auth-container.component';
import { AuthGuard } from './auth.guard';
import { OtpVerifyComponent } from './auth-components/otp-verify/otp-verify.component';
import { SignupFacultyInviteComponent } from './auth-components/signup-faculty-invite/signup-faculty-invite.component';

const routes: Routes = [
    {
        path: '',
        component: AuthContainerComponent,
        children: [
            {
                path: '',
                component: LoginComponent,
                canActivate: [AuthGuard]
            },
            {
                path: 'login',
                component: LoginComponent,
                canActivate: [AuthGuard]
            },
            {
                path: 'signup',
                component: SignupComponent,
                canActivate: [AuthGuard]
            },
            {
                path: 'forgot-password',
                component: ForgotPasswordComponent
            },
            {
                path: 'resetPassword/:id',
                component: ResetPasswordComponent
            },
            {
                path: 'personal-details',
                component: PersonalDetailsComponent
            },
            {
                path: 'verify-alternate-email/:emailId/:userId',
                component: VerifyAlternateEmailComponent
            },
            {
                path: 'invitation/:inviteId/:businessId',
                component: InvitationComponent
            },
            {
                path: 'signup/:inviteId/:businessId',
                component: SignupForInvitedComponent
            },
            {
                path:'signup/:inviteId',
                component: SignupAdminInviteComponent
            },
            {
                path : 'signup-faculty-invite/:id',
                component : SignupFacultyInviteComponent
            },
            {
                path: 'verify-subscription',
                component: VerifySubcriptionComponent
            },
            {
                path: 'subscription-plans',
                component: SubscriptionPlansComponent
            },
            {
                path: 'select-business',
                component: SelectBusinessComponent
            },
            {
                path: 'otp-verify',
                component: OtpVerifyComponent
            },
            {
                path: '**',
                component: LoginComponent
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class AuthRoutingModule {}
