<div class="flex justify-center p-4">
  <div class="w-full flex flex-col space-y-4">
    <div class="flex items-center justify-between">
      <h1 class="text-2xl Gilroy-Bold inline-flex items-center">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 me-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z" />
        </svg>
        {{'Estimate' | translate}}
      </h1>
    </div>
    <div class="flex space-x-2 rtl:space-x-reverse mb-4">
      <app-button appAccessControl moduleType='sales' accessType='edit' (click)='convertToInvoice()' type='primary' buttonText='{{"Convert to Invoice" | translate}}'></app-button>
      <app-button (handleClick)='downloadPdf()' type='primary' buttonText='{{"Download PDF" | translate}}'></app-button>
      <!-- <app-button (click)='emailModal = true' type='primary' buttonText='{{"Share via Email" | translate}}'></app-button> -->
      <div class="border rounded-xl flex items-center ps-2">
        <a [href]='shareURL' class="max-w-[250px] overflow-hidden whitespace-nowrap overflow-ellipsis">
          {{shareURL}}
        </a>
        <app-button type='primary' buttonText='{{copyButtonText | translate}}' (handleClick)='copyLink()'></app-button>
      </div>
    </div>
    <table *ngIf='viewEstimateDetail' class="w-4/5 m-auto d-block overflow-hidden box-shadow-sales rounded-xl">
      <div class="p-12">
        <table class="w-full" style="border-collapse: collapse;">
          <tr>
            <td valign='top' >
              <div>
                <h1 class="text-2xl font-semibold">{{'ESTIMATE' | translate}}</h1>   
              </div>
              <div class="mb-4">{{viewEstimateDetail.subheading}}</div>
            </td>
            <td  class="text-end" valign='top' style='width: 50%;'>
              <div *ngIf='customizationSettings?.companyLogo' class="w-[200px] h-[112px] mb-2 ms-auto">
                <img [src]="customizationSettings?.companyLogo" alt="">
            </div>
            <div>{{viewEstimateDetail.businessId.companyName}}</div>
              <div>{{viewEstimateDetail?.businessId?.address?.addressLineFirst}}</div>
              <div>{{viewEstimateDetail?.businessId?.address?.addressLine2}}</div>
              <div>{{viewEstimateDetail?.businessId?.address?.city ? viewEstimateDetail?.businessId?.address?.city + ',' : ''}} {{viewEstimateDetail?.businessId?.address?.state}}</div>
              <div>{{viewEstimateDetail.businessId.country}}</div>
            </td>
          </tr>
        </table>
      </div>
      <div class="px-12" >
        <table class="w-full" style="border-collapse: collapse;">
          <tr>
            <td>
              <table>
                <tr>
                  <td>
                    <div class="Gilroy-Bold">{{'BILL TO' | translate}}</div> 
                    <div>{{viewEstimateDetail?.customerDetails?.customerId?.customerName}}</div>
                    <div>{{viewEstimateDetail?.customerDetails?.customerId?.email}}</div> 
                    <div>{{viewEstimateDetail?.customerDetails?.customerId?.billingAddress?.addressLine1}}</div> 
                    <div>{{viewEstimateDetail?.customerDetails?.customerId?.billingAddress?.addressLine2}}</div> 
                    <div>{{viewEstimateDetail?.customerDetails?.customerId?.billingAddress?.city}}, {{viewEstimateDetail?.customerDetails?.customerId?.billingAddress?.state}}</div> 
                    <div>{{viewEstimateDetail?.customerDetails?.customerId?.billingAddress?.postalCode}}</div> 
                    <div>{{viewEstimateDetail?.customerDetails?.customerId?.billingAddress?.country}}</div> 
                  </td>
                </tr>
              </table>
            </td>
            <td class="w-1/2"  >
              <table class="w-full" style="border-collapse: collapse;">
                <tr>
                  <td class="Gilroy-Bold">{{'Estimate Number:' | translate}}</td>
                  <td  class="text-end ps-4" >{{viewEstimateDetail.estimateNumber}}</td>
                </tr>
                <tr>
                  <td class="Gilroy-Bold">{{'Reference Number:' | translate}}</td>
                  <td  class="text-end ps-4">{{viewEstimateDetail.purchaseOrder}}</td>
                </tr>
                <tr>
                  <td class="Gilroy-Bold">{{'Estimate Date:' | translate }}</td>
                  <td  class="text-end ps-4">{{viewEstimateDetail.date | date}}</td>
                </tr>
                <tr>
                  <td class="Gilroy-Bold">{{'Expires On:' | translate}}</td>
                  <td  class="text-end ps-4">{{viewEstimateDetail.expiryDate | date}}</td>
                </tr>
              </table>
            </td>
          </tr>
        </table>
        <table class="w-full my-8" style="margin-top: 2em; margin-bottom: 2em; border-bottom: 1px solid #0C4D71;">
          <thead class="bg-light-gray-1 h-12 ps-2 pe-2">
            <tr>  
              <td class="Gilroy-Bold ps-2 text-start">{{'Item Name' | translate}}</td>
              <td align="center" class="Gilroy-Bold">{{'SKU' | translate}}</td>
              <td align="center" class="Gilroy-Bold">{{'HSN/SAC' | translate}}</td>
              <td align="center" class="Gilroy-Bold">{{'Quantity' | translate}}</td>
              <td align="center" class="Gilroy-Bold">{{'Unit Price' | translate}}</td>
              <td align="center" class="Gilroy-Bold">{{'Tax' | translate}}</td>
              <td class="Gilroy-Bold pe-2 text-end">{{'Total Amount' | translate}}</td>
            </tr>
          </thead>
          <tbody>
            <tr class="row-1" *ngFor="let item of viewEstimateDetail.items">
              <td class="text-start ps-2" style="padding: 0.5em;">
                  <h2 class="Gilroy-Bold">{{item.item}}</h2>
              </td>
              <td align="center" style="padding: 0.5em;">{{item.sku}}</td>
              <td align="center" style="padding: 0.5em;">{{item.hsn_sac}}</td>
              <td align="center" style="padding: 0.5em;">{{item.quantity}}</td>
              <td align="center" style="padding: 0.5em;">{{item.unitPrice | currency: currencyDetails?.currency : 'symbol-narrow' : number}}</td>
              <td align="center" class="p-2" *ngIf='item?.tax[0]?.tax'>{{item?.tax[0]?.tax}}%</td>
              <td align="center" class="p-2" *ngIf='!item?.tax[0]?.tax'>No Tax</td>
              <td class="text-end pe-2" style="padding: 0.5em;">{{item.totalCost | currency: currencyDetails?.currency : 'symbol-narrow' : number}}</td>
            </tr>
          </tbody>
        </table>
        <table class="w-full" style="margin-bottom: 2em;">
          <tr>
            <td style="width: 65%;"></td>
            <td align="right" style="width: 35%;">
              <table class="w-full">
                <tr>
                  <td>{{'Subtotal:' | translate}}</td>
                  <td align="right">{{viewEstimateDetail.subTotal | currency: currencyDetails?.currency : 'symbol-narrow' : number}}</td>
                </tr>
                <tr *ngIf='viewEstimateDetail.tax'>
                  <td style="padding-bottom: 0.5em;">{{'Tax' | translate}}</td>
                  <td align="right" style="padding-bottom: 0.5em;">{{viewEstimateDetail.tax | currency: currencyDetails?.currency : 'symbol-narrow' : number}}</td>
                </tr>
                <tr class="border-top" style="border-top: 1px solid #0C4D71;">
                  <td class="bold" style="font-weight: bold; padding-top: 0.5em;">{{'Total' | translate}}</td>
                  <td align="right" class="bold" style="font-weight: bold; padding-top: 0.5em;">{{viewEstimateDetail.totalAmount | currency: currencyDetails?.currency : 'symbol-narrow' : number}}</td>
                </tr>
              </table>
            </td>
          </tr>
        </table>

        <div class="mx-16" style="padding-top: 1em; padding-bottom: 1em; text-align: center; border-top: 1px solid #000; margin-top: 0.5em;" ></div>
        <!-- <div class="my-4 p-3 flex flex-col justify-between">
          <app-button appAccessControl moduleType="storage" accessType="edit" type='primary' class="mb-3 ml-auto" buttonText='{{"Upload Files" | translate}}' size='large' (handleClick)="uploadFiles()" ></app-button>
          <app-file-upload appAccessControl moduleType="storage" accessType="view" [addedFiles]="addedFiles" (emitter)="saveFiles($event)" ></app-file-upload>
        </div> -->

      </div>
      <div class="px-16" *ngIf='viewEstimateDetail.memo'>
        <div class="Gilroy-Bold">{{'Memo' | translate}}</div>
        <pre class="whitespace-pre-wrap Gilroy">{{viewEstimateDetail.memo}}</pre>
      </div>
      <div *ngIf='viewEstimateDetail.footer' class="mx-16" style="padding-top: 1em; padding-bottom: 1em; text-align: center; border-top: 1px solid #000; margin-top: 0.5em;" >{{viewEstimateDetail.footer}}</div>
      <div class="flex justify-end px-16 py-4 space-x-2 rtl:space-x-reverse" >
        <h4 class="Gilroy-Bold" >{{'Issued By'}}:</h4>
        <span>{{viewEstimateDetail?.userId?.firstName+' '+viewEstimateDetail?.userId?.lastName}}</span>
      </div>
    </table>
  </div>
</div>

<app-modal-container [isOpen]='outOfStock'>
  <div header class="text-start border-b pb-2 Gilroy-Bold">{{'Check Items' | translate}}</div>
  <div class="text-start" content>
      <p>Following items have insufficient qunatity in inventory:</p>
      <ul>
          <li class="Gilroy list-disc" *ngFor="let item of checkQuantityInStock">
              <div>{{item?.item}} added with {{item?.quantityAdded}} units has {{item?.openingStock}} units only</div>
          </li>
      </ul>

  </div>
  <div footer class="flex items-center ms-auto space-x-2 rtl:space-x-reverse">
      <!-- <app-button buttonText='{{"Delete" | translate}}' type='danger' rounded=true (handleClick)='deleteEstimateslist()' ></app-button> -->
      <app-button buttonText='{{"Ok" | translate}}' type='third' rounded=true (handleClick)='outOfStock = false'></app-button>
  </div>
</app-modal-container>

<app-modal-container [isOpen]='emailModal'>
  <div header class="Gilroy-Bold">{{'Share estimate via email' | translate}}</div>
  <div content class="flex flex-col">
    <label for="">{{'Email' | translate}}</label>
    <input [formControl]='email' type="text" class="w-full rounded-xl bg-gray-100 border-0 p-3">
    <div *ngIf='email.getError("required") && email.dirty' class="text-sm text-red-400 ms-2">{{'Email is required' | translate}}</div>
    <div *ngIf='email.getError("pattern") && email.dirty' class="text-sm text-red-400 ms-2">{{'Email must be in correct format' | translate}}</div>
    <div *ngIf='error' class="text-red-500 ms-2">{{error}}</div>
    <div *ngIf='response' class="text-green-500 ms-2">{{response}}</div>
  </div>
  <div footer class='flex space-x-2 rtl:space-x-reverse'>
    <app-button type='primary' buttonText='{{"Send" | translate}}' (handleClick)='sendEmail()'></app-button>
    <app-button type='third' buttonText='{{"Cancel" | translate}}' (handleClick)='emailModal = false'></app-button>
  </div>
</app-modal-container>
