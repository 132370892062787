<div class="flex justify-center p-4">
    <div class="w-3/4">
        <div class="flex mb-4">
            <span *ngIf='!update' class="text-2xl Gilroy-Bold">{{'Business Management' | translate }}</span>
        </div>
        <form [ngClass]='showForm ? "" : "hidden"' [formGroup]='businessForm' class="space-y-6 bg-light-gray-1 py-6 px-8 rounded-3xl mb-8">
            <div *ngIf='!update' class="text-lg Gilroy-Bold">{{'Create Business' | translate }}</div>
            <div *ngIf='update' class="text-lg Gilroy-Bold">{{'Update Business Details' | translate }}</div>
            <div>
                <div class="custom-input-box-shadow rounded-xl bg-white px-3 py-2 w-1/2">
                    <label for='businessName' class="block text-sm Gilroy-Bold font-color-05">{{'Company Name' | translate }}</label>
                    <input id='businessName' formControlName='companyName' placeholder="{{'Enter Company Name' | translate }}" type="text" class="w-full p-0 border-0 focus:ring-0 placeholder-gray-300" />
                </div>
                <div class="text-sm text-red-500 ms-2 mt-1" *ngIf='formErrors.companyName'>{{formErrors.companyName}}</div>
            </div>
            <div>
                <div class="custom-input-box-shadow rounded-xl bg-white px-3 py-2 w-1/2">
                    <label for='businessType' class="block text-sm Gilroy-Bold font-color-05">{{'Business Type' | translate }}</label>
                    <!-- <select class="border-0" id="industry-select" formControlName='businessType'></select> -->
                    <ng-select id='industry-select' [items]="industries" class="settings-select border-0 p-0 w-full focus:ring-0" bindLabel="text"
                    bindValue="text" [addTag]="true" addTagText='Create ' formControlName="businessType" placeholder='{{"Select one" | translate }}'>
                    <ng-option *ngFor="let industry of industries" [value]="industry.id">{{industry.name}}</ng-option>
                    </ng-select>
                </div>
                <div class="text-sm text-red-500 ms-2 mt-1" *ngIf='formErrors.businessType'>{{formErrors.businessType}}</div>
            </div>
            <div>
                <div class="custom-input-box-shadow rounded-xl bg-white px-3 py-2 w-1/2">
                    <label for='addressLine1' class="block text-sm Gilroy-Bold font-color-05">{{'Address Line 1' | translate }}</label>
                    <input id='addressLine1' formControlName='addressLine1' placeholder="{{'Enter Address' | translate }}" type="text" class="w-full p-0 border-0 focus:ring-0 placeholder-gray-300" />
                </div>
            </div>
            <div>
                <div class="custom-input-box-shadow rounded-xl bg-white px-3 py-2 w-1/2">
                    <label for='addressLine2' class="block text-sm Gilroy-Bold font-color-05">{{'Address Line 2' | translate }}</label>
                    <input id='addressLine2' formControlName='addressLine2' type="text" class="w-full p-0 border-0 focus:ring-0 placeholder-gray-300" />
                </div>
            </div>
            <div>
                <div class="custom-input-box-shadow rounded-xl bg-white px-3 py-2 w-1/2">
                    <label for='City' class="block text-sm Gilroy-Bold font-color-05">{{'City' | translate }}</label>
                    <input id='City' formControlName='city' placeholder="{{'Enter City' | translate }}" type="text" class="w-full p-0 border-0 focus:ring-0 placeholder-gray-300" />
                </div>
            </div>
            <div class="flex w-1/2">
                <div class="w-1/2">
                    <div class="custom-input-box-shadow rounded-xl bg-white px-3 py-2 w-full">
                        <label for='country' class="block text-sm Gilroy-Bold font-color-05">{{'Country' | translate}}</label>
                        <!-- <select class="w-full p-0 border-0 focus:ring-0" id="country-select" formControlName='country'></select> -->
                        <ng-select id="country-select" [items]="countrySelect" class="settings-select border-0 p-0 w-full focus:ring-0" bindLabel="text"
                        bindValue="text" (change)='changeEventContry($event)' formControlName="country" placeholder='{{"Select one" | translate }}'>
                        <ng-option *ngFor="let country of countrySelect" [value]="country.id">{{country.name}}</ng-option>
                        </ng-select>
                    </div>
                    <div class="text-sm text-red-500 ms-2 mt-1" *ngIf='formErrors.country'>{{formErrors.country}}</div>
                </div>
                <div class='ms-2 w-1/2'>
                    <div class="custom-input-box-shadow rounded-xl bg-white px-3 py-2 w-full">
                        <label for='state' class="block text-sm Gilroy-Bold font-color-05">{{'State' | translate }}</label>
                        <ng-select id="country-select" [items]="states" class="settings-select border-0 p-0 w-full focus:ring-0" bindLabel="text"
                        bindValue="text" formControlName="state" placeholder='{{"Select one" | translate}}'></ng-select>
                    </div>
                </div>
            </div>
            <div class="flex w-1/2">
                <div class="w-1/2">
                    <div class="custom-input-box-shadow rounded-xl bg-white px-3 py-2 w-full">
                        <label for='Tax Id' class="block text-sm Gilroy-Bold font-color-05">{{'Tax Id' | translate }}</label>
                        <input id='Tax Id' formControlName='taxId' placeholder="{{'Enter Tax Id' | translate }}" type="text" class="w-full p-0 border-0 focus:ring-0 placeholder-gray-300" />
                    </div>
                </div>
                <div class='ms-2 w-1/2'>
                    <div class="custom-input-box-shadow rounded-xl bg-white px-3 py-2 w-full">
                        <label for='Entity Id' class="block text-sm Gilroy-Bold font-color-05">{{'Entity Id' | translate }}</label>
                        <input id='Entity Id' formControlName='entityId' placeholder="{{'Enter Entity Id' | translate }}" type="text" class="w-full p-0 border-0 focus:ring-0 placeholder-gray-300" />
                    </div>
                </div>
            </div>
            <div>
                <label class="flex items-center space-x-2 rtl:space-x-reverse">
                    <input type="checkbox" formControlName='showTax' />
                    <span>{{'Show tax number on invoices' | translate }}</span>
                </label>
            </div>
            <div>
                <div class="custom-input-box-shadow rounded-xl bg-white px-3 py-2 w-1/2">
                    <label for='currency' class="block text-sm Gilroy-Bold font-color-05">{{'Business Currency' | translate }}</label>
                    <!-- <select class="w-full p-0 border-0 focus:ring-0" id="currency-select" formControlName='currency'></select> -->
                    <ng-select id='currency-select'  [items]="currenySelect" class="settings-select border-0 p-0 w-full focus:ring-0" bindLabel="text"
                    bindValue="text" formControlName="currency" placeholder='{{"Select one" | translate }}'>
                    <ng-option *ngFor="let currency of currenySelect" [value]="currency.id">{{currency.name}}</ng-option>
                    </ng-select>
                </div>
                <div class="text-sm text-red-500 ms-2 mt-1" *ngIf='formErrors.currency'>{{formErrors.currency}}</div>
            </div>
            <div>
                <div class="custom-input-box-shadow rounded-xl bg-white px-3 py-2 w-1/2">
                    <label for='organization' class="block text-sm Gilroy-Bold font-color-05">{{'Type of Organization' | translate }}</label>
                    <ng-select id='organization-select' [items]="organisations" class="settings-select border-0 p-0 w-full focus:ring-0" bindLabel="text"
                    bindValue="text" formControlName="organization" placeholder='{{"Select one" | translate }}'>
                    <ng-option *ngFor="let organize of organisations" [value]="organize.id">{{organize.name}}</ng-option>
                    </ng-select>
                </div>
                <div class="text-sm text-red-500 ms-2 mt-1" *ngIf='formErrors.organization'>{{formErrors.organization}}</div>
            </div>
            <div>
                <div class="Gilroy-Bold">Decimal Places</div>
                <div class="flex items-center space-x-2">
                    <label>
                        <input formControlName='decimalSize' type="radio" class="me-2" name='decimalSize' value='2' />
                        <span>2 Decimal</span>
                    </label>
                    <label>
                        <input formControlName='decimalSize' type="radio" class="me-2" name='decimalSize' value='3' />
                        <span>3 Decimal</span>
                    </label>
                </div>
            </div>
            <!-- <div *ngIf='update' class="mt-4">
                <app-file-upload [addedFiles]="addedFiles"  (emitter)="saveFiles($event)" ></app-file-upload>
            </div> -->
            <div class="flex items-center space-x-4 rtl:space-x-reverse ">
                <app-button *ngIf='!update' buttonText='{{"Create" | translate }}' [rounded]='true' type='primary' (handleClick)='createBusiness()'></app-button>
                <app-button *ngIf='update' buttonText='{{"Update" | translate }}' [rounded]='true' type='primary' (handleClick)='updateBusiness()'></app-button>
                <app-button buttonText='{{"Cancel" | translate }}' [rounded]='true' type='third' (handleClick)='hideForm()'></app-button>
                <span *ngIf='error' class="text-red-500 text-sm ms-2">{{error}}</span>
                <span *ngIf='response' class="text-green-500 text-sm ms-2">{{response}}</span>
            </div>
        </form>   
        <form [ngClass]='showStoreForm ? "" : "hidden"' [formGroup]='storeForm' class="space-y-6 bg-light-gray-1 py-6 px-8 rounded-3xl mb-8">
            <div *ngIf='!update' class="text-lg Gilroy-Bold">{{'Create Store' | translate }}</div>
            <div *ngIf='update' class="text-lg Gilroy-Bold">{{'Update Store' | translate }}</div>
            <div>
                <div class="custom-input-box-shadow rounded-xl bg-white px-3 py-2 w-1/2">
                    <label for='storeName' class="block text-sm Gilroy-Bold font-color-05">{{'Store Name' | translate }}</label>
                    <input formControlName='storeName' placeholder="{{'Enter Store Name' | translate }}" type="text" class="w-full p-0 border-0 focus:ring-0 placeholder-gray-300" />
                </div>
                <div class="text-sm text-red-500 ms-2 mt-1" *ngIf='storeFormErrors.storeName'>{{storeFormErrors.storeName}}</div>
            </div>
            <div>
                <div class="custom-input-box-shadow rounded-xl bg-white px-3 py-2 w-1/2">
                    <label for='addressLine1' class="block text-sm Gilroy-Bold font-color-05">{{'Address Line 1' | translate }}</label>
                    <input id='addressLine1' formControlName='addressLineFirst' placeholder="{{'Enter Address' | translate }}" type="text" class="w-full p-0 border-0 focus:ring-0 placeholder-gray-300" />
                </div>
                <div class="text-sm text-red-500 ms-2 mt-1" *ngIf='storeFormErrors.addressLineFirst'>{{storeFormErrors.addressLineFirst}}</div>
            </div>
            <div>
                <div class="custom-input-box-shadow rounded-xl bg-white px-3 py-2 w-1/2">
                    <label for='addressLine2' class="block text-sm Gilroy-Bold font-color-05">{{'Address Line 2' | translate }}</label>
                    <input id='addressLine2' formControlName='addressLineSecond' type="text" class="w-full p-0 border-0 focus:ring-0 placeholder-gray-300" />
                </div>
            </div>
            <div>
                <div class="custom-input-box-shadow rounded-xl bg-white px-3 py-2 w-1/2">
                    <label for='City' class="block text-sm Gilroy-Bold font-color-05">{{'City' | translate }}</label>
                    <input id='City' formControlName='city' placeholder="{{'Enter City' | translate }}" type="text" class="w-full p-0 border-0 focus:ring-0 placeholder-gray-300" />
                </div>
                <div class="text-sm text-red-500 ms-2 mt-1" *ngIf='storeFormErrors.city'>{{storeFormErrors.city}}</div>
            </div>
            <div class="flex w-1/2">
                <div class="w-1/2">
                    <div class="custom-input-box-shadow rounded-xl bg-white px-3 py-2 w-full">
                        <label for='country' class="block text-sm Gilroy-Bold font-color-05">{{'Country' | translate}}</label>
                        <!-- <select class="w-full p-0 border-0 focus:ring-0" id="country-select" formControlName='country'></select> -->
                        <ng-select id="country-select" [items]="countrySelect" class="settings-select border-0 p-0 w-full focus:ring-0" bindLabel="text"
                        bindValue="text" (change)='changeEventContry($event)' formControlName="country" placeholder='{{"Select one" | translate }}'>
                        <ng-option *ngFor="let country of countrySelect" [value]="country.id">{{country.name}}</ng-option>
                        </ng-select>
                    </div>
                    <div class="text-sm text-red-500 ms-2 mt-1" *ngIf='storeFormErrors.country'>{{storeFormErrors.country}}</div>
                </div>
                <div class='ms-2 w-1/2'>
                    <div class="custom-input-box-shadow rounded-xl bg-white px-3 py-2 w-full">
                        <label for='state' class="block text-sm Gilroy-Bold font-color-05">{{'State' | translate }}</label>
                        <ng-select id="country-select" [items]="states" class="settings-select border-0 p-0 w-full focus:ring-0" bindLabel="text"
                        bindValue="text" formControlName="state" placeholder='{{"Select one" | translate}}'></ng-select>
                    </div>
                    <div class="text-sm text-red-500 ms-2 mt-1" *ngIf='storeFormErrors.state'>{{storeFormErrors.state}}</div>
                </div>
            </div>
            <div class="flex items-center space-x-4 rtl:space-x-reverse ">
                <app-button *ngIf='!update' buttonText='{{"Create" | translate }}' [rounded]='true' type='primary' (handleClick)='createStore()'></app-button>
                <app-button *ngIf='update' buttonText='{{"Update" | translate }}' [rounded]='true' type='primary' (handleClick)='updateStore()'></app-button>
                <app-button buttonText='{{"Cancel" | translate }}' [rounded]='true' type='third' (handleClick)='hideForm()'></app-button>
                <span *ngIf='error' class="text-red-500 text-sm ms-2">{{error}}</span>
                <span *ngIf='response' class="text-green-500 text-sm ms-2">{{response}}</span>
            </div>
        </form>   
        <div class="flex flex-col space-y-4">    
            <app-businesses-management></app-businesses-management>
            <app-store-management></app-store-management>
        </div>
    </div>
</div>