<div  class="flex justify-center p-4" inventoryCheck>
    <div class="w-full flex flex-col space-y-4">
        <div>
            <div class="flex items-center justify-between">
                <div class="">
                    <h1 class="text-2xl Gilroy-Bold inline-flex items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 me-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z" />
                          </svg>
                        {{'Production Run' | translate}}
                    </h1>
                </div>
                <div class="">
                    <!-- <app-button appAccessControl moduleType='sales' accessType='edit' size='large' buttonText='{{"Create Production Run" | translate}}' (click)="openProductionRun = true;" type='primary' routerLink='create-invoice'></app-button> -->
                </div>
                <div appAccessControl moduleType='sales' accessType='edit' class="bundled-product">
                    <app-dropdown #dropdown>
                        <app-button button type='primary' buttonText='{{"Production Run" | translate}}' size='large' (click)='dropdown.open=true' (clickOutside)='dropdown.open=false'></app-button>
                        <div options>
                            <span (click)="openProductionRun = true; selectedRunType = 'create';" class="cursor-pointer block px-4 py-2 text-sm Gilroy-Bold hover:bg-gray-50 text-center rounded-t-xl">{{'Create Production Run' | translate}}</span>
                            <span (click)="openProductionRun = true; selectedRunType = 'reverse';" class="cursor-pointer block px-4 py-2 text-sm Gilroy-Bold hover:bg-gray-50 text-center rounded-b-xl">{{'Reverse Production Run' | translate}}</span>
                        </div>
                    </app-dropdown>    
                </div>
            </div>
        </div>
        <app-table-container [tableHeadings]='tableHeadings' [tableData]='tableData' [keys]='tableKeys'>
        </app-table-container>
    </div>
</div>


<!-- <div routeToSubscription fromComponent='inventory' class="hidden absolute bottom-6 z-[9999] w-[400px] left-0 right-0 m-auto h-4/6">
    <app-send-to-sub from="inventory"></app-send-to-sub>
</div> -->


<app-modal-container [isOpen]='openProductionRun'>
    <div header class="Gilroy-Bold">{{ selectedRunType === 'create' ? "Create Production Run" : "Reverse Production Run" | translate}}</div>
    <div content class="flex flex-col">
        <div class="bg-proile rounded-xl bg-white px-3 py-2 w-full mb-3">
            <label for='Paytabs Country' class="items-center space-x-2 block text-sm Gilroy-Bold font-color-05">
                {{'Item to Run' | translate }}
            </label>
            <ng-select class="w-full p-0 border-0 bg-transparent focus:ring-0 production-items" [clearable]="false" bindLabel="name" bindValue="_id" [(ngModel)]="selectedItemForRun" [items]="productsList" placeholder="Select Item"></ng-select> 
        </div>
        <div class="flex mb-3">
            <div class="bg-proile rounded-xl bg-white px-3 py-2 w-full">
                <label for="" class="block text-sm Gilroy-Bold font-color-05">{{'Quantity' | translate}}</label>
                <input [(ngModel)]='quantity' type="number" class="w-full p-[10px] border-0 bg-transparent focus:ring-0 placeholder-gray-400" placeholder="{{'Enter quantity' | translate}}" >
            </div>
        </div>
    </div>
    <div footer class='flex space-x-2 rtl:space-x-reverse'>
      <app-button type='primary' buttonText='{{"Run" | translate}}' (handleClick)='submitRun()'></app-button>
      <app-button type='third' buttonText='{{"Cancel" | translate}}' (click)='openProductionRun = false; quantity = null; selectedItemForRun = null;'></app-button>
    </div>
</app-modal-container>
