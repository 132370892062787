<div class="space-y-4 p-4">
    <div class="flex items-center justify-between">
      <h1 class="text-2xl Gilroy-Bold inline-flex items-center">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 me-2" fill="none" viewBox="0 0 24 24"
          stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
            d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z" />
        </svg>
        {{"Bill #" | translate}} {{billDetails?.billNumber}}
      </h1>
      <app-button appAccessControl moduleType='purchases' accessType='edit' type='primary' buttonText='{{"Create another Bill" | translate}}' size='large' routerLink='../create-bill'></app-button>
    </div>
    <div class="w-4/5 mx-auto">
      <div class="flex justify-between p-6 my-3">
        <div class="flex space-x-8 rtl:space-x-reverse">
          <div>
            <p class="Gilroy-Medium text-lg">{{'Status' | translate}}</p>
            <button [ngClass]="billDetails?.status === 'paid'?'bg-green-400': 'bg-red-800'" class="Gilroy-Bold h-8  rounded-full text-white w-28">{{billDetails?.status | titlecase | translate}}</button>
          </div>
          <div>
            <p class="Gilroy-Medium text-lg">{{'Vendor' | translate}}</p>
            <h3 class="Gilroy-Bold text-xl">{{billDetails?.vendor?.vendorName | titlecase}}</h3>
          </div>
        </div>
        <div class="flex space-x-8 rtl:space-x-reverse">
          <div>
            <p class="Gilroy-Medium text-lg">{{'Amount due' | translate}}</p>
            <h3 class="Gilroy-Bold text-xl">{{amountDue | currency : currencyDetails?.currency : 'symbol-narrow' : number}}</h3>
          </div>
          <div>
            <p class="Gilroy-Medium text-lg">{{'Due on' | translate}}</p>
            <h3 class="Gilroy-Bold text-xl">{{billDetails?.dueDate | date: 'longDate'}}</h3>
          </div>
        </div>
      </div>
      <div class="flex items-center rounded-xl border-custom p-6 my-3">
        <img src="/assets/images/icon-1.svg" class="me-4" />
        <div>
          <p class="Gilroy-Medium text-lg">{{'Create' | translate}}</p>
          <h4 class="Gilroy-Medium text-lg">{{'Created:' | translate }} {{billDetails?.createdAt | date: 'longDate'}}</h4>
        </div>
        <div class="space-x-2 rtl:space-x-reverse ms-auto">
          <app-button (handleClick)='downloadPdf()' type='primary' buttonText='{{"Download PDF" | translate}}'></app-button>
          <!-- <app-button (handleClick)='emailModal = true' type='primary' buttonText='{{"Share Bill" | translate}}'></app-button> -->
          <button (click)='shareURLModal = true' class="rounded-xl px-4 py-1 border-custom">{{'Get share link' | translate}}</button>
          <button appAccessControl moduleType='purchases' accessType='edit' (click)='editBill()' class="rounded-xl px-4 py-1 border-custom">{{"Edit Bill" | translate}}</button>
        </div>
      </div>
      <div class="rounded-xl border-custom p-6 my-3">
        <div class="flex items-center">
          <img src="/assets/images/icon-3.svg" class="me-4" />
          <div>
            <p class="Gilroy-Medium text-lg">{{'Get paid' | translate}}</p>
            <h4 class="Gilroy-Medium text-lg">{{'Amount due:' | translate}} {{amountDue | currency : currencyDetails?.currency : 'symbol-narrow' : number}}</h4>
          </div>
          <button appSubscriptionCheck featureToCheck='transactionLimit' 
            *ngIf='amountDue > 0'
            class="rounded-xl px-4 py-1 bg-gradient text-white ms-auto"
            (click)='openRecordPayment()'>
              {{'Record Payment' | translate}}
          </button>
        </div>
        <div class='mt-2' *ngIf='billDetails?.paymentHistory?.length > 0'>
          <table class="w-full">
            <tr>
              <td class="bg-gray-100 Gilroy-Bold p-2">{{'Date' | translate}}</td>
              <td class="bg-gray-100 Gilroy-Bold">{{'Amount' | translate}}</td>
              <td class="bg-gray-100 Gilroy-Bold">{{'Payment Method' | translate}}</td>
              <td class="bg-gray-100 Gilroy-Bold">{{'Memo' | translate }}</td>
              <td class="bg-gray-100 Gilroy-Bold">{{'Actions' | translate }}</td>
            </tr>
            <tr *ngFor='let payment of billDetails?.paymentHistory | paginate:  { itemsPerPage: 5, currentPage: page }'>
              <td class='p-2'>{{payment?.paymentDate | date : 'dd-MM-YYYY'}}</td>
              <td>{{payment?.amount | currency : currencyDetails?.currency : 'symbol-narrow' : number}}</td>
              <td>{{payment?.paymentMethod}}</td>
              <td>{{payment?.memo}}</td>
              <td >
                <div *ngIf='payment?.paymentMethod !== "paypal"'  class="flex item-center">
                  <app-tooltip *ngIf='payment?.paymentMethod !== "Credit Amount"' customClass='mb-8' class='me-2' tooltipName='{{"Receipt" | translate}}'>
                    <svg [routerLink]="['/purchases/bills/view-receipt', payment.recieptId]" ro xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                    </svg>
                    </app-tooltip>
                    <app-tooltip *ngIf='payment?.paymentMethod !== "Credit Amount"' customClass='mb-8' class="me-2" tooltipName='{{"Edit" | translate}}'>
                        <div (click)="editRecord(payment)" class="h-6 w-6 rounded-full flex justify-center items-center bg-gray-50 border border-gray-200 cursor-pointer">
                          <svg  xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
                            </svg>
                      </div>
                  </app-tooltip>
            </div>
              </td>
            </tr>
          </table>
          <pagination-controls autoHide="true" (pageChange)="page = $event"></pagination-controls>
        </div>
      </div>
      <!-- Refund Bill Start -->

      <div class="rounded-xl border-custom p-6 my-3">
        <div class="flex items-center">
          <img src="/assets/images/icon-3.svg" class="me-4" />
          <div>
            <p class="Gilroy-Medium text-lg">{{'Issue Return' | translate}}</p>
            <h4 class="Gilroy-Medium text-lg">{{'Total Amount Return:' | translate}} {{refundAmount | currency : currencyDetails?.currency : 'symbol-narrow' : number}}</h4>
          </div>
          <button appSubscriptionCheck featureToCheck='transactionLimit' 
            class="rounded-xl px-4 py-1 bg-gradient text-white ms-auto"
            (click)='openRefundPayment()'>
              {{'Record Return' | translate}}
          </button>
        </div>
        <div class='mt-2' *ngIf='billDetails?.refundHistory?.length > 0'>
          <table class="w-full">
            <tr>
              <td class="bg-gray-100 Gilroy-Bold p-2">{{'Date' | translate}}</td>
              <td class="bg-gray-100 Gilroy-Bold">{{'Amount' | translate}}</td>
              <td class="bg-gray-100 Gilroy-Bold">{{'Payment Method' | translate}}</td>
              <td class="bg-gray-100 Gilroy-Bold">{{'Memo' | translate }}</td>
              <td class="bg-gray-100 Gilroy-Bold">{{'Actions' | translate }}</td>
            </tr>
            <tr *ngFor='let payment of billDetails?.refundHistory | paginate:  { itemsPerPage: 5, currentPage: page }'>
              <td class='p-2'>{{payment?.refundDate | date : 'dd-MM-YYYY'}}</td>
              <td>{{payment?.amount | currency : currencyDetails?.currency : 'symbol-narrow' : number}}</td>
              <td>{{payment?.paymentMethod}}</td>
              <td>{{payment?.memo}}</td>
              <td >
                <div *ngIf='payment?.paymentMethod !== "paypal"'  class="flex item-center">
                  <app-tooltip customClass='mb-8' class='me-2' tooltipName='{{"Receipt" | translate}}'>
                    <svg [routerLink]="['/purchases/bills/view-receipt', payment.recieptId]" ro xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                    </svg>
                    </app-tooltip>
                </div>
              </td>
            </tr>
          </table>
          <pagination-controls autoHide="true" (pageChange)="page = $event"></pagination-controls>
        </div>
      </div>
      
      <!-- Refund Bill End  -->
      <div class="rounded-xl border-custom p-6 my-3">
        <span class="text-lg mb-2 Gilroy-Medium">{{'Items' | translate }}</span>
        <table class="w-full">
          <tr>
            <td class="bg-gray-100 Gilroy-Bold p-2">{{'Item Name' | translate}}</td>
            <td class="bg-gray-100 Gilroy-Bold">{{'SKU' | translate}}</td>
            <td class="bg-gray-100 Gilroy-Bold">{{'HSN/SAC' | translate}}</td>
            <td class="bg-gray-100 Gilroy-Bold">{{'Quantity' | translate}}</td>
            <td class="bg-gray-100 Gilroy-Bold">{{'Price' | translate}}</td>
            <td class="bg-gray-100 Gilroy-Bold">{{'Total' | translate }}</td>
            <td class="bg-gray-100 Gilroy-Bold">{{'Tax' | translate }}</td>
          </tr>
          <tr *ngFor='let item of billDetails2?.items'>
            <td class="p-2">{{item.item}}</td>
            <td>{{item.sku}}</td>
            <td>{{item.hsn_sac}}</td>
            <td>{{item.unit}}</td>
            <td>{{item.price | currency : currencyDetails?.currency : 'symbol-narrow' : number}}</td>
            <td>{{item.unit * item.price | currency : currencyDetails?.currency : 'symbol-narrow' : number}}</td>
            <td>{{ item?.tax?.length > 0 && item?.tax[0]?.tax ? item.tax[0].tax + "%" : 'No Tax'}}</td>
          </tr>
        </table>
      </div>

      <div class="rounded-xl border-custom p-6 my-3">
        <div class="">
          <span class="Gilroy-Medium text-lg mb-2">{{'Total Amount' | translate}}</span>
          <table>
            <tr>
              <td class="pe-2">{{'Subtotal' | translate}}</td>
              <td>{{billDetails2?.subtotal || 0 | currency : currencyDetails?.currency : 'symbol-narrow' : number}}</td>
            </tr>
            <tr>
              <td class="pe-2">{{'Tax' | translate}}</td>
              <td>{{billDetails2?.tax || 0 | currency : currencyDetails?.currency : 'symbol-narrow' : number}}</td>
            </tr>
            <tr>
              <td class="pe-2">{{'Total Amount' | translate}}</td>
              <td>{{billDetails2?.totalAmount || 0 | currency : currencyDetails?.currency : 'symbol-narrow' : number}}</td>
            </tr>
          </table>
        </div>
      </div>

      <!-- <div class="rounded-xl border-custom p-6 flex flex-col space-y-4 justify-between my-3">
        <app-button appAccessControl moduleType="storage" accessType="edit" class="ml-auto" type='primary' buttonText='{{"Upload Files" | translate}}' size='large' (handleClick)="uploadFiles()" ></app-button>
        <app-file-upload appAccessControl moduleType="storage" accessType="view" [addedFiles]="addedFiles" (emitter)="saveFiles($event)" ></app-file-upload>
      </div> -->
    </div>
  </div>

  <!-- Refund Billl App Modal Start -->

  <app-modal-container [isOpen]='recordRefundModal'>
    <div class="Gilroy-Bold" header>{{'Return Record' | translate}}</div>
    <my-tabs content >
      <my-tab tabTitle="{{'Refund Details' | translate}}">
        <div [formGroup]="refundForm" class="space-y-2 flex flex-col">
          <div class='mt-2'>
            <table class="w-full">
              <tr>
                <td class="p-2 bg-input Gilroy-Bold rounded-s-xl w-3/14">{{tableFields.items | translate}}</td>
                <td class="p-2 bg-input Gilroy-Bold w-1/12">{{tableFields.units | translate}}</td>
                <td class="p-2 bg-input Gilroy-Bold w-2/12">{{tableFields.price | translate}}</td>
                <td class="p-2 bg-input Gilroy-Bold w-3/12">{{tableFields.tax | translate}}</td>
                <td class="p-2 bg-input Gilroy-Bold">{{tableFields.amount | translate}}</td>
                <td class="p-2 bg-input Gilroy-Bold rounded-e-xl"></td>
              </tr>
              <ng-container formArrayName='items'>
                <ng-container *ngFor='let control of items.controls; let i = index;'>
                    <tr [formGroup]='control' class="">
                        <td class="w-3/12 p-2">
                            <ng-select [items]="itemList"
                            class="custom-selector" 
                            bindLabel="item" 
                            bindValue="item"
                            formControlName="item"
                            [clearable]="false"
                            placeholder='{{"Select one" | translate}}'
                            (change) = "changeEvent($event, i)">
                          </ng-select>
                        </td>
                        <td class="p-2 w-1/12">
                          <fieldset>
                            <input id='quantity' formControlName='unit' type="number" class="w-full p-3 rounded-xl bg-input border-0"/>
                          </fieldset>
                        </td>
                        <td class="p-2 w-2/12">
                          <fieldset disabled='true'>  
                            <input id='price' formControlName='price' type="number" min="0"  class="w-full p-3 rounded-xl bg-input border-0"/>
                          </fieldset>
                        </td>
                        <td class="p-2 w-3/12">
                          <fieldset disabled='true'>
                            <input formControlName='tax' type="text" value="{{items.controls[i].get('tax').value ? items.controls[i].get('tax').value[0]?.taxName : null | json }}" class="w-full p-3 rounded-xl bg-input border-0"/>
                          </fieldset>
                        </td>
                        <td class="p-2 ">
                            <p>{{items.controls[i].get('unit').value * items.controls[i].get('price').value | currency: currencySymbol : 'symbol-narrow' : number}}</p>
                        </td>
                        <td class="w-1/12">
                            <svg (click)='removeItem(i)' xmlns="http://www.w3.org/2000/svg" name="delete" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="mx-auto h-5 w-5 text-black-500"><path _ngcontent-hsm-c58="" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"></path></svg>
                        </td>
                    </tr>
                    <tr>
                      <td colspan="1"></td>
                      <td>
                        <p class="text-red-500" *ngIf='control.get("unit").hasError("min")'>{{'Atleast 1 unit is required'}}</p>
                        <p class="text-red-500" *ngIf='control.get("unit").hasError("max")'>{{'limit Reached'}}</p>
                      </td>
                      <td colspan="4" ></td>
                    </tr>
      
                </ng-container>
                <ng-container *ngIf='items.controls.length === 0'>
                  <tr>
                    <td colspan="8" class="text-center p-2">{{'No items added' | translate}}</td>
                  </tr>
                </ng-container>
              </ng-container>
              <tr>
                <td vAlign='top' colspan="3">
                  <div class="ps-2">
                    <button  (click)='addNewItem()' id="add" class="flex items-center Gilroy-Bold space-x-4">
                        <span  class="rounded-full bg-gradient text-white p-1 me-2">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="h-4 w-4">
                                <path  stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path>
                            </svg>
                        </span> {{'Add a Line' | translate}}
                    </button>
                  </div>
                </td>
              </tr>
            </table>
            <!-- <pagination-controls autoHide="true" (pageChange)="page = $event"></pagination-controls> -->
          </div>
          <div>
            <label for='amount'>{{'Amount' | translate }}</label>
            <input formControlName='amount' id='amount' type="number" [value]="totalAmount" class="w-full bg-input rounded-lg border-0 h-11 me-2" disabled>
            <!-- <div class="text-red-500 text-sm ms-2" *ngIf='invalidRefundAmount'>{{'Invalid Amount' | translate }}</div> -->
        </div>
        <!-- <div>
          <label for='paymentType'>{{'Payment Method' | translate }}</label>
          <ng-select [items]="paymentMethods"
                        [searchable]='false'
                        [clearable]='false'
                        class="custom-selector" 
                        formControlName="paymentMethod"
                        placeholder='{{"Select one" | translate }}'>
              </ng-select>
          <div class="text-red-500 text-sm ms-2" *ngIf='refundFormErrors.paymentMethod'>{{refundFormErrors.paymentMethod}}</div>
          </div> -->
          <div>
            <label for='paymentAccount'>{{'Payment Account' | translate }}</label>
                <input formControlName='paymentAccount' [value]="billDetails?.vendor?.vendorName" id='memo' type="text" class="w-full bg-input rounded-lg border-0 h-11 me-2" disabled>
            <div class="text-red-500 text-sm ms-2" *ngIf='refundFormErrors.paymentAccount'>{{refundFormErrors.paymentAccount}}</div>
          </div>
          <div>
            <label for='memo'>{{'Memo' | translate }}</label>
            <input formControlName='memo' id='memo' type="text" class="w-full bg-input rounded-lg border-0 h-11 me-2">
          </div>
        </div>
      </my-tab>
      <my-tab appAccessControl moduleType="storage" accessType="edit" tabTitle="{{'Files' | translate }}">
        <app-file-upload (emitter)="saveRefundFiles($event)"></app-file-upload>
      </my-tab>
    </my-tabs>
    
    <div footer class="flex space-x-2 rtl:space-x-reverse">
      <app-button buttonText='{{"Record" | translate }}' type='primary' rounded='true' (click)="generateRefundPayment()"></app-button>
      <app-button buttonText='{{"Cancel" | translate }}' type='third' rounded='true' (click)="cancelRefund()"></app-button>
  </div>
  </app-modal-container>

  <!-- Refund Billl App Modal End -->

  <app-modal-container [isOpen]='emailModal'>
    <div header class="Gilroy-Bold">{{'Share bill via email' | translate}}</div>
    <div content class="flex flex-col">
      <label for="">{{'Email' | translate}}</label>
      <input [formControl]='email' type="text" class="w-full rounded-xl bg-gray-100 border-0 p-3">
      <div *ngIf='email.getError("required") && email.dirty' class="text-sm text-red-400 ms-2">{{'Email is required' | translate}}</div>
      <div *ngIf='email.getError("pattern") && email.dirty' class="text-sm text-red-400 ms-2">{{'Email must be in correct format' | translate}}</div>
      <div *ngIf='error' class="text-red-500 ms-2">{{error}}</div>
      <div *ngIf='response' class="text-green-500 ms-2">{{response}}</div>
    </div>
    <div footer class='flex space-x-2 rtl:space-x-reverse'>
      <app-button type='primary' buttonText='{{"Send" | translate}}' (handleClick)='sendEmail()'></app-button>
      <app-button type='third' buttonText='{{"Cancel" | translate}}' (handleClick)='emailModal = false'></app-button>
    </div>
  </app-modal-container>

  <app-modal-container [isOpen]='shareURLModal'>
    <div header class="Gilroy-Bold">{{'Bill Shareable link' | translate}}</div>
    <div content class="flex items-center me-2">
      <div class="border rounded-xl flex items-center ps-2">
        <a [href]='shareURL' class="max-w-[400px] overflow-hidden whitespace-nowrap overflow-ellipsis">
          {{shareURL}}
        </a>
        <app-button type='primary' [buttonText]='copyButtonText' (handleClick)='copyLink()'></app-button>
      </div>
    </div>
    <div footer>
      <app-button type='third' buttonText='{{"Close" | translate}}' (handleClick)='shareURLModal = false'></app-button>
    </div>
  </app-modal-container>

<app-modal-container [isOpen]='confirmDeleteModal'>
    <div header>
      {{'Delete Payment Record' | translate}}
    </div>
    <div content>
      <p>{{'Delete payment confirmation' | translate}}</p>
      <p>{{'Delete payment warning' | translate}}</p>
    </div>
    <div footer class="space-x-2 rtl:space-x-reverse">
      <app-button type='danger' buttonText='{{"Delete" | translate}}' (handleClick)='confirmDelete()'></app-button>
      <app-button type='third' buttonText='{{"Cancel" | translate}}' (handleClick)='confirmDeleteModal=false'></app-button>
    </div>
  </app-modal-container>
  
  <record-payment
    type = 'bill'
    [isOpen]='recordPaymentModal'
    [amountDue]='amountDue'
    [minDate]='minDate'
    (closeModal)='recordPaymentModal=false'
    (paymentData)='recordPayment($event)'></record-payment>
  
  <record-payment
    type = 'bill'
    [isOpen]='editRecordPaymentModal'
    [amountDue]='amountDue'
    [recordData]='recordData'
    [minDate]='minDate'
    (closeModal)='editRecordPaymentModal=false'
    (paymentData)='saveEditedRecord($event)'></record-payment>
  
  <ngx-spinner name='view' bdColor = "rgba(138,170,172,0.49)" size = "medium" color = "#0C4D71" type = "ball-clip-rotate" [fullScreen] = "false"></ngx-spinner>
  