import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { StorageRoutingModule } from './storage-routing.module';
import { FolderListingComponent } from './components/folder-listing/folder-listing.component';
import { FileListingComponent } from './components/file-listing/file-listing.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { ClickOutsideModule } from 'ng-click-outside';
import { FileViewComponent } from './components/file-view/file-view.component';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
// import { PdfViewerModule } from 'ng2-pdf-viewer';


@NgModule({
  declarations: [
    FolderListingComponent,
    FileListingComponent,
    FileViewComponent
  ],
  imports: [
    SharedModule,
    TranslateModule,
    CommonModule,
    StorageRoutingModule,
    ClickOutsideModule
  ]
})
export class StorageModule { }
