<div class="flex justify-center p-4">
    <div class="w-full flex flex-col space- y-4">
      <div class="flex items-center justify-between">
        <h1 class="text-2xl Gilroy-Bold inline-flex items-center">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 me-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z" />
          </svg>
          <span class="text-2xl Gilroy-Bold inline-flex items-center" >{{'New Recurring Bill' | translate}}</span>
        </h1>
        <div class="space-x-2 rtl:space-x-reverse">
          <app-button type='primary' buttonText='{{"Create Recurring Bill" | translate}}' (handleClick)='createBill()'></app-button>
          <app-button buttonText='{{"Cancel" | translate}}' type='third' routerLink='../'></app-button>
        </div>
      </div>
      <div class="w-full m-auto mt-5">
        <form class="space-y-4 mb-4" [formGroup]='billForm'>
          <div class="rounded-3xl px-8 py-5 box-shadow-sales bg-white">
            <div class="w-full space-y-8">
              <div class="flex">
              <div class="w-1/3 pe-1">
                <label class="block text-sm Gilroy-Bold font-color-05 mb-2">{{'Add a Vendor' | translate}}</label>
                <fieldset [disabled]='!isFieldEditable'>
                  <ng-select [items]="vendors"
                  class="custom-selector" 
                  bindLabel="vendorName" 
                  bindValue="_id"
                  formControlName="vendor"
                  [readonly]='!isFieldEditable'
                  placeholder='{{"Type Vendor Name" | translate}}'>
                </ng-select>
                <div class="text-red-500" *ngIf='formErrors.vendor'>{{formErrors.vendor}}</div>
              </fieldset>
              </div>
              <div class="w-1/3 px-1">
                <label class="block text-sm Gilroy-Bold font-color-05 mb-2">{{'Add a Project' | translate}}</label>
                <fieldset [disabled]='!isFieldEditable'>
                  <ng-select [items]="projects"
                  class="custom-selector" 
                  bindLabel="projectName" 
                  bindValue="_id"
                  formControlName="projectId"
                  [readonly]='!isFieldEditable'
                  placeholder='{{"Select Project Name" | translate}}'>
                </ng-select>
              </fieldset>
              </div>
              <div class="w-1/3 pe-1">
                <label class="block text-sm Gilroy-Bold font-color-05 mb-2">{{'Vendor Invoice' | translate}}</label>
                <fieldset [disabled]='!isFieldEditable'>
                  <input formControlName='purchaseOrder' type="text" class="bg-input w-full rounded-lg border-0 h-11 " />
                  <div class="text-red-500" *ngIf='formErrors.purchaseOrder'>{{formErrors.purchaseOrder}}</div>
              </fieldset>
              </div>
              </div>
              <div class="flex space-x-2 rtl:space-x-reverse">
                <!-- <div class="flex flex-col w-1/4">
                  <label class="Gilroy-Bold">{{"Invoice #" | translate}}</label>
                  <fieldset [disabled]='!isFieldEditable' >  
                    <input formControlName='invoiceNumber' type="text" class="bg-input w-full rounded-lg border-0 h-11 " />
                    <div class="text-red-500" *ngIf='formErrors.invoiceNumber'>{{formErrors.invoiceNumber}}</div>
                  </fieldset>
                </div> -->
                <div class="flex flex-col w-1/4">
                  <label class="Gilroy-Bold">{{'Frequency' | translate}}</label>
                  <fieldset [disabled]='!isFieldEditable' >
                    <ng-select [items]="frequency"
                        class="custom-selector"
                        formControlName="frequency"
                        [readonly]='!isFieldEditable'
                        [clearable]="false"
                        placeholder='{{"Select Frequency" | translate}}'>
                    </ng-select>
                    <div class="text-red-500" *ngIf='formErrors.frequency'>{{formErrors.frequency}}</div> 
                    <!-- <input formControlName='purchaseOrder' type="text" class="bg-input w-full rounded-lg border-0 h-11 " /> -->
                  </fieldset>
                </div>
                <div class="flex flex-col w-1/4">
                  <label class="Gilroy-Bold">{{'Payment Terms' | translate}}</label>
                  <fieldset [disabled]='!isFieldEditable' >
                    <ng-select #select [items]="paymentTerms"
                        class="custom-selector"
                        formControlName="paymentTerms"
                        bindLabel="paymentTerm"
                        bindValue="_id"
                        [readonly]='!isFieldEditable'
                        [clearable]="false"
                        placeholder='{{"Select Payment Terms" | translate}}'>
                        <ng-template ng-footer-tmp>
                          <p class="create-new cursor-pointer" (click)="showCustomPaymentTermsModal(select)">Create New </p>
                       </ng-template>
                    </ng-select>
                    <div class="text-red-500" *ngIf='formErrors.paymentTerms'>{{formErrors.paymentTerms}}</div> 
                    <!-- <input formControlName='purchaseOrder' type="text" class="bg-input w-full rounded-lg border-0 h-11 " /> -->
                  </fieldset>
                </div>
                <div class="flex flex-col w-1/4">
                  <label class="Gilroy-Bold">{{'Start Date' | translate}}</label>
                  <fieldset [disabled]='!isFieldEditable' >
                    <input formControlName='startDate' [min]="today" type="date" class="bg-input w-full rounded-lg border-0 h-11 " />
                    <div class="text-red-500" *ngIf='formErrors.startDate'>{{formErrors.startDate}}</div>
                  </fieldset>
                </div>
                <div class="flex flex-col w-1/4">
                  <label class="Gilroy-Bold">{{'End Date' | translate}}</label>
                  <input formControlName='endDate' [min]="billForm.get('startDate').value" type="date" class="bg-input rounded-lg border-0 h-11" />
                  <div class="text-red-500" *ngIf='formErrors.endDate'>{{formErrors.endDate}}</div>
                </div>
              </div>
              <div class="flex mt-4">
                <div class="w-8/12">
                  <label class="block text-sm Gilroy-Bold">{{'Notes' | translate}}</label>
                  <textarea [disabled]='!isFieldEditable' formControlName="notesTerms"
                    class="w-full h-28 ps-3 rounded-xl mt-2 bg-input-sales p-4 border-0 focus:ring-0"
                    placeholder="{{'Add some notes' | translate}}"></textarea>
                </div>
              </div>
              <table class="w-full">
                <tr>
                  <td class="p-2 bg-input Gilroy-Bold rounded-s-xl w-3/14">{{tableFields.items | translate}}</td>
                  <td class="p-2 bg-input Gilroy-Bold w-1/6">{{tableFields.sku | translate}}</td>
                  <td class="p-2 bg-input Gilroy-Bold w-1/6">{{tableFields.hsn_sac | translate}}</td>
                  <td class="p-2 bg-input Gilroy-Bold w-1/12">{{tableFields.units | translate}}</td>
                  <td class="p-2 bg-input Gilroy-Bold w-2/12">{{tableFields.price | translate}}</td>
                  <td class="p-2 bg-input Gilroy-Bold w-1/6">{{'Tax:' | translate}}</td>
                  <td class="p-2 bg-input Gilroy-Bold">{{tableFields.amount | translate}}</td>
                  <td class="p-2 bg-input Gilroy-Bold rounded-e-xl"></td>
                </tr>
                <ng-container formArrayName='items'>
                  <ng-container *ngFor='let control of items?.controls; let i = index;'>
                      <tr [formGroup]='control' class="">
                          <td class="w-1/6 p-2">
                              <ng-select [items]="avaialbleProducts"
                              class="custom-selector" 
                              bindLabel="name" 
                              bindValue="name"
                              formControlName="item"
                              [readonly]='!isFieldEditable'
                              [hideSelected]="true"
                              [clearable]="false"
                              placeholder='{{"Select one" | translate}}'
                              (change) = "changeEvent($event, i)">
                            </ng-select>
                          </td>
                          <td class="p-2 w-1/6" >
                            <fieldset disabled='true'>
                              <input formControlName='sku' type="text" class="w-full p-3 rounded-xl bg-input border-0"/>
                            </fieldset>
                          </td>
                          <td class="p-2 w-1/6" >
                            <fieldset disabled='true'>
                              <input formControlName='hsn_sac' type="text" class="w-full p-3 rounded-xl bg-input border-0"/>
                            </fieldset>
                          </td>
                          <td class="p-2 w-1/12">
                            <fieldset [disabled]='!isFieldEditable'>
                              <input id='quantity' formControlName='unit' type="number" min="0" class="w-full p-3 rounded-xl bg-input border-0"/>
                            </fieldset>
                          </td>
                          <td class="p-2 w-2/12">
                            <fieldset [disabled]='!isFieldEditable'>  
                              <input id='price' formControlName='price' type="number" min="0" class="w-full p-3 rounded-xl bg-input border-0"/>
                              <div class="text-red-500" *ngIf='formErrors.price'>{{formErrors.price}}</div>
                            </fieldset>
                          </td>
                          <td class="p-2 w-1/6">
                              <ng-select class="custom-selector select-tax-option border-0 p-0" 
                              [readonly]='!isFieldEditable'
                              [items]="taxes" 
                              bindLabel="name" 
                              formControlName="tax"
                              multiple="true"
                              (change)= 'changeTax($event, i)'
                              placeholder='{{"Select Tax" | translate}}'>
                              <ng-template ng-label-tmp let-item="item" let-clear="clear">
                                <span class="ng-value-label">{{item.taxName}} {{item.tax | number: number}}%</span>
                                <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                              </ng-template>
                            </ng-select>
                          </td>
                          <td class="p-2 ">
                              <p>{{items.controls[i].get('unit').value * items.controls[i].get('price').value | currency: currencySymbol : 'symbol-narrow' : number}}</p>
                          </td>
                          <td *ngIf='isFieldEditable' class="w-1/12">
                              <svg (click)='removeItem(i)' xmlns="http://www.w3.org/2000/svg" name="delete" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="mx-auto h-5 w-5 text-red-500"><path _ngcontent-hsm-c58="" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"></path></svg>
                          </td>
                      </tr>
                      <tr>
                        <td colspan="3"></td>
                        <td>
                          <p class="text-red-500" *ngIf='control.get("unit").hasError("min")'>{{'Atleast 1 unit is required'}}</p>
                        </td>
                        <td colspan="4" ></td>
                      </tr>
  
                  </ng-container>
                  <ng-container *ngIf='items.controls.length === 0'>
                    <tr>
                      <td colspan="8" class="text-center p-2">{{'No items added' | translate}}</td>
                    </tr>
                  </ng-container>
                </ng-container>
                <tr>
                  <td  vAlign='top' colspan="3"> 
                      <div *ngIf='isFieldEditable' class="ps-2">
                          <button  (click)='addNewItem()' id="add" class="flex items-center Gilroy-Bold space-x-4">
                              <span  class="rounded-full bg-gradient text-white p-1 me-2">
                                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="h-4 w-4">
                                      <path  stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path>
                                  </svg>
                              </span> {{'Add a Line' | translate}}
                          </button>
                      </div>
                  </td>
                  <td colspan="4">
                    <div class="Gilroy-Bold flex justify-between py-2">
                      <p>{{'Subtotal:' | translate}}</p>
                      <p>{{subTotal | currency: currencySymbol : 'symbol-narrow' : number}}</p>
                    </div>
                    <div class="Gilroy-Bold flex justify-between py-2">
                      <p>{{'Tax:' | translate}}</p>
                      <p>{{tax | currency: currencySymbol : 'symbol-narrow' : number}}</p>
                    </div>
                    <div class="Gilroy-Bold flex justify-between py-2 border-t">
                      <p>{{'Total Amount:' | translate}}</p>
                      <p>{{totalAmount | currency: currencySymbol : 'symbol-narrow' : number}}</p>
                    </div>
                  </td>
                </tr> 
              </table>
              <!-- File Upload Component -->
              <!-- <div appAccessControl moduleType="storage" accessType="edit" class="mt-4">
                <app-file-upload (emitter)="saveFiles($event)" ></app-file-upload>
              </div> -->
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>

  <app-modal-container [isOpen]='showCustomPaymentTerms'>
    <div header class="text-start border-b pb-2 Gilroy-Bold">{{'Add New Payment Term' | translate}}</div>
    <div class="text-start" [formGroup]="paymentTermForm" content>
      <div class="flex flex-col w-1/2">
        <label class="Gilroy-Bold">{{'New Payment Term' | translate}}</label>
        <fieldset >
          <input id="paymentTerm" formControlName='paymentTerm' type="text" class="bg-input w-full rounded-lg border-0 h-11 " />
          <div class="text-red-500" *ngIf='paymentTermFormErrors.paymentTerm'>{{paymentTermFormErrors.paymentTerm}}</div>
        </fieldset>
      </div>
      <div class="flex flex-col w-1/2">
        <label class="Gilroy-Bold">{{'New Payment Term Days' | translate}}</label>
        <fieldset >
          <input formControlName='days' type="number" class="bg-input w-full rounded-lg border-0 h-11 " />
          <div class="text-red-500" *ngIf='paymentTermFormErrors.days'>{{paymentTermFormErrors.days}}</div>
        </fieldset>
      </div>
    </div>
    <div footer class="flex items-center ms-auto space-x-2 rtl:space-x-reverse">
        <app-button buttonText='{{"Create" | translate}}' type='primary' rounded=true (handleClick)='createPaymentTerms()' ></app-button>
        <app-button buttonText='{{"Close" | translate}}' type='third' rounded=true (handleClick)='showCustomPaymentTerms = false'></app-button>
    </div>
  </app-modal-container>
  <!-- <app-image-cropper [imageData]='imageDataEsign' (base64Image)='loadEsign($event)'></app-image-cropper> -->
  