import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { selectedVendors } from 'src/app/modules/purchases/state/selectors/vendors.selector';
import { dateLessThan, DateValidator, valueChanges } from 'src/app/shared/utils/formValidator';
import { RootReducerState } from 'src/app/store/reducers';
import { selectBusiness } from 'src/app/store/selectors/business.selector';
import { selectSalesTaxes } from 'src/app/modules/purchases/state/selectors/salesTaxes.selector';
import { selectedProducts } from 'src/app/modules/purchases/state/selectors/product.selector';
import { SalesReducerState } from 'src/app/modules/sales/state/reducers';
import { PurchaseOrderService } from '../../PurchaseOrder.service';
import { NgxSpinner, NgxSpinnerService } from 'ngx-spinner';
import { takeUntil } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { PurchasesService } from 'src/app/modules/purchases/purchases.service';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { AssetsService } from '../../../assets/assetsServices';
import { NumberService } from 'src/app/shared/services/number.service';
import { selectUserSubscription } from 'src/app/store/selectors/subscription.selectors';
import { FileUploadService } from 'src/app/shared/services/file-upload.service';

interface StockCheck {
  item: string;
  openingStock: number,
  quantityAdded: number
}

@Component({
  selector: 'app-create-purchase-order',
  templateUrl: './create-purchase-order.component.html',
  styleUrls: ['./create-purchase-order.component.scss']
})
export class CreatePurchaseOrderComponent implements OnInit, OnDestroy{

  constructor(private fb: FormBuilder,
              private store: Store<SalesReducerState>,
              private rootStore: Store<RootReducerState>,
              private route: ActivatedRoute,
              private router: Router,
              private purchaseOrderService: PurchaseOrderService,
              private purchasesService: PurchasesService,
              private toastr: ToastrService,
              private translateService: TranslateService,
              private assetsService: AssetsService,
              private numberService: NumberService,
              private fileUploadService: FileUploadService,
              private spinner: NgxSpinnerService) {
    this.vendors$ = this.store.pipe(select(selectedVendors));
    this.business$ = this.rootStore.pipe(select(selectBusiness));
    this.products$ = this.store.pipe(select(selectedProducts));
    this.salesTaxes$ = this.store.pipe(select(selectSalesTaxes));
    this.subscription$ = rootStore.pipe(select(selectUserSubscription))
  }

  purchaseOrderForm: FormGroup;
  business$: Observable<any>;
  products$: Observable<any>;
  subscription$: Observable<any>;
  update = false;
  salesTaxes$: Observable<any>;
  salesTaxes = [];
  products = [];
  files: File[] = [];
  addedFiles = [];
  availableProducts = [];
  subscription = null;
  subTotal: number;
  selectedVendor: number;
  vendors$: Observable<any>;
  vendors: Observable<any>;
  unsubscribe$ = new Subject();
  businessId = null;
  formErrors = {
    vendorName: '',
    date: '',
    dueDate: '',
    purchaseOrder: '',
    billNumber: '',
    notes: '',
  };
  formErrorMessages = {
    vendorName: {
      required: 'Vendor Name is Required'
    },
    date: {
      required: 'Date is Required',
      invalidDate: 'Invalid Date'
    },
    dueDate: {
      required: 'Due Date Date is Required',
      invalidDate: 'Invalid Date',
      dates: 'Invalid date'
    },
    purchaseOrder: {
      required: 'Purchase Order is Required'
    },
    billNumber: {
      required: 'Bill Number is Required'
    },
    notes: {
      required: 'Notes is Required'
    },
  };

  outOfStock: boolean = false;
  checkQuantityInStock : Array<StockCheck | null> = []

  currencyDetails = {
    currency:'',
    currencySumbol:''
  }
  number = '1.2-2'
  subtotal = 0;
  totalTax = 0;
  toatalAmount = 0;
  tax = 0;
  vendor = [];
  orderToUpdate = null;

  ngOnInit(): void {
    this.getBusinessId();
    this.getCurrencyDetails();
    this.loadForm();
    this.loadVendor();
    this.loadSubscription();
    this.loadSalesTaxes();
    this.loadAssets();
    this.loadNumberConfig();
    this.route.queryParams.subscribe(({ id }) => {
      if (id) {
        this.orderToUpdate = id;
        this.update = true;
        this.spinner.show();
        this.purchaseOrderService.OrderDetails(id).subscribe((resp) => {
          this.spinner.hide();
          this.appendPurchaseOrderdata(resp?.data);
        }, (error) => {
          this.spinner.hide();
          this.toastr.success(this.translateService.instant('Something went wrong!'))
        });
      }
      else{
        this.createPurchaseOrderNumber();
      }
    });
  }

  loadSubscription(): void {
    this.subscription$.pipe(takeUntil(this.unsubscribe$))
    .subscribe(({planName}) => {
      this.subscription = planName;
      this.products$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(products => {
        if(this.subscription === 'Retail' || this.subscription === 'Retail Plus'){
          products = products?.filter(product => product?.isReviewed)
        }
        this.products = products?.filter(item => {
          if(item?.itemType === 'Service' && item?.isSale){
            return false;
          }
          return true;
       });
       this.availableProducts = this.products;
      });
    })
  }

  loadNumberConfig(): void {
    this.numberService.number
    .pipe((takeUntil(this.unsubscribe$)))
    .subscribe((number) => {
      this.number = number
    })
  }

  createPurchaseOrderNumber() {
    this.spinner.show();
    this.purchasesService.businessId$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((id) => {
      const from = moment().startOf('year').format('YYYY-MM-DD');
      const to = moment().endOf('year').format('YYYY-MM-DD');
      const year = moment().format('YYYY');
      const body = {
        from,
        to,
        year,
        businessId: id
      };
      this.purchaseOrderService.generatePurchaseOrderNumber(body).subscribe((resp) => {
        this.purchaseOrderForm.get('purchaseOrder').setValue(resp.data);
        this.spinner.hide();
      }, (error) => {
        this.spinner.hide();
        this.router.navigate(['/purchases/purchase-order']);
        this.toastr.error(this.translateService.instant('Unable to generate purhcase order number'));
      });
    })
  }

  reloadPurchaseNumber(): void {
    const billDateField = this.purchaseOrderForm.get('date');
    console.log('changes');
    
    if (billDateField.valid && this.purchaseOrderForm.get('purchaseOrder').value) {
      const billDateYear = new Date(billDateField.value).getFullYear();
      const billNumber = this.purchaseOrderForm.get('purchaseOrder').value;
      const billYear = billNumber.replace(/^\D+|\D.*$/g, '');
      console.log(billYear, billDateYear, Number(billYear) === billDateYear);
      if (Number(billYear) !== billDateYear) {
        const from = moment(`${billDateYear}`).startOf('year').format('YYYY-MM-DD');
        const to = moment(`${billDateYear}`).endOf('year').format('YYYY-MM-DD');
        const body = {
          from,
          to,
          year: billDateYear,
          businessId: this.businessId
        };
        this.spinner.show();
        this.purchaseOrderService.generatePurchaseOrderNumber(body).subscribe((resp) => {
          this.purchaseOrderForm.get('purchaseOrder').setValue(resp.data);
          this.spinner.hide();
        }, (error) => {
          this.spinner.hide();
          this.toastr.error(this.translateService.instant('Something went wrong!'));
        });
      }
    }
  }

  getBusinessId(): void {
    this.purchasesService.businessId$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(id => {
      this.businessId = id;
    });
  }

  getCurrencyDetails(): void {
    this.purchasesService.currencyDetails.pipe(takeUntil(this.unsubscribe$))
    .subscribe(details=>{
      if(details)
        this.currencyDetails = details;
    })
  }

  loadVendor(): void {
    this.vendors$.subscribe((vendors) => {
      this.vendors = vendors;
    });
  }

  loadSalesTaxes(): void {
    this.salesTaxes$.subscribe((taxes) => {
      this.salesTaxes = taxes.map((tax) => ({
        ...tax,
        name: tax.taxName,
        tax: tax.taxRate,
      }));
    });
  }

  loadProducts(): void {
    this.products$.subscribe((products) => {
      this.products = products.filter(item => item?.isReviewed).map((product) => ({
        ...product,
        text: product.name,
        type: 'Product'
      }));
    });
  }

  loadAssets(): void {
    this.assetsService.getAllAssets(this.businessId)
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(resp => {
      if(resp?.success) {
        const assets = resp?.data?.map(asset => (
          {
            ...asset,
            type: 'Asset',
            name: asset.assetsName,
            price: asset.amount,
            tax: []
          })
        );
        this.products.push(...assets);
        console.log('prod', this.products)
      }
    });

  }

  loadForm(bill?): void {
    this.purchaseOrderForm = this.fb.group({
      vendor: [bill?.vendor ? bill.vendor : null, [Validators.required]],
      date: [bill?.date ? bill.date : '', [Validators.required, DateValidator()]],
      dueDate: [bill?.dueDate ? bill.dueDate : '', [Validators.required, DateValidator()]],
      purchaseOrder: [{value:bill?.purchaseOrder ? bill.purchaseOrder : '', disabled: true}, [Validators.required]],
      reference: [null],
      notes: [null],
      items: this.fb.array([]),
    }, {validator: dateLessThan('date', 'dueDate')});
    this.purchaseOrderForm.valueChanges.subscribe(({ items }) => {
      this.formErrors = valueChanges(
        this.purchaseOrderForm,
        { ...this.formErrors },
        this.formErrorMessages,
        this.translateService
      );
      this.calculateTotal(items);
    });
    this.formErrors = valueChanges(
      this.purchaseOrderForm,
      { ...this.formErrors },
      this.formErrorMessages,
      this.translateService
    );
  }

  appendPurchaseOrderdata(purchaseOrderdata): void {
    const {
      vendor,
      date,
      dueDate,
      purchaseOrder,
      reference,
      subtotal,
      tax,
      toatalAmount,
      items,
      notes,
      files = []
    } = purchaseOrderdata;
    const mappedItems = items.map(
      ({ itemId,item, unit, sku = '', hsn_sac = '', tax, type, price,accountDetails, depreciationAccount = null, isSale = false, inventoryAccountDetails = null, cogsAccountDetails }) => {
        return { item, itemId, unit, sku, hsn_sac, tax, type, accountDetails, isSale, depreciationAccount, inventoryAccountDetails, cogsAccountDetails, price: this.numberService.toFixed(price), totalCost: this.numberService.toFixed(price * unit) };
      }
    );
    this.addedFiles = files ?? [];
    const data = {
      vendor,
      date: date.split('T')[0],
      dueDate: dueDate.split('T')[0],
      reference: reference || null,
      purchaseOrder: purchaseOrder || null,
      notes: notes || null,
      items: mappedItems
    };
    this.subTotal = this.numberService.toFixed(subtotal);
    this.totalTax = this.numberService.toFixed(tax);
    this.toatalAmount = this.numberService.toFixed(toatalAmount);
    mappedItems.forEach(() => this.items.push(this.createItem()));
    this.purchaseOrderForm.setValue(data);
  }

  createItem(): FormGroup {
    const itemForm = this.fb.group({
      item: null,
      itemId: '',
      sku: '',
      hsn_sac: '',
      price: 0,
      unit: [1, Validators.min(1)],
      tax: [],
      totalCost: 0,
      accountDetails: null,
      type: null,
      cogsAccountDetails: null,
      isSale: false,
      inventoryAccountDetails: null,
      depreciationAccount: null
    });
    return itemForm;
  }

  get items(): FormArray {
    return this.purchaseOrderForm.get('items') as FormArray;
  }

  changeEventVendor(event): void {
    const{vendorName, _id} = event;
    this.purchaseOrderForm.get('vendor').setValue({
      vendorName,
      vendorId: _id,
    });
  }

  calculateTotal(estimateItems): void {
    console.log(estimateItems, 'estimated');
    
    let subtotal = 0;
    let totalTax = 0;
    estimateItems.forEach((item) => {
      const { price, unit, tax } = item;
      subtotal += (price * unit);
      if (tax) {
        totalTax += this.calculateItemTax(price * unit, tax);
      }
    });
    this.subtotal = this.numberService.toFixed(subtotal);
    this.totalTax = this.numberService.toFixed(totalTax);
    this.toatalAmount = this.numberService.toFixed((subtotal + totalTax));
  }

  addNewItem(): void {
    this.items.push(this.createItem());
  }

  removeItem(index): void {
    let item = this.products.find( product => product?._id === this.items.controls[index].value?.itemId)
    if(item){
      this.availableProducts.push(item)
    }
    this.items.removeAt(index);
  }

  changeEvent(event, index): void {
    console.log(event);
    
    const { _id,name, price, itemType, type, tax, accountDetails, depreciationAccount = null, sku = null, sac = null, hsn = null, isSale = false, inventoryAccountDetails = null, cogsAccountDetails = null } = event;
    this.items.controls[index].setValue({
      itemId: _id,
      item: name,
      price: this.numberService.toFixed(price),
      tax,
      type: itemType || type,
      sku,
      hsn_sac: hsn || sac,
      unit: 1,
      totalCost: this.numberService.toFixed((price * 1)),
      accountDetails,
      isSale,
      inventoryAccountDetails,
      cogsAccountDetails,
      depreciationAccount
    });
    this.availableProducts = this.availableProducts.filter(el => el?._id !== _id);

    console.log('item', this.items.controls[index].value);
    
  }

  changeTaxEvent(event, index): void {
    const { price, unit } = this.items.controls[index].value;
    this.items.controls[index].get('total').setValue(this.numberService.toFixed(price * unit));
  }

  calculateItemTax(price, taxes): any {
    return taxes.reduce((a, b) => (a += this.numberService.toFixed(price * this.numberService.toFixed((b.tax / 100)))), 0);
  }

  estimateDataMapper(): object {
    const body = {
      ...this.purchaseOrderForm.value,
      businessId: this.businessId,
      toatalAmount: this.numberService.toFixed(this.toatalAmount),
      subtotal: this.numberService.toFixed(this.subtotal),
      tax: this.numberService.toFixed(this.totalTax),
    };
    return body;
  }

  createPurchaseOrder(): void {
    this.purchaseOrderForm.get('purchaseOrder').enable();
    if (this.purchaseOrderForm.invalid) {
      this.purchaseOrderForm.markAllAsTouched();
      this.formErrors = valueChanges(
        this.purchaseOrderForm,
        { ...this.formErrors },
        this.formErrorMessages,
        this.translateService
      );
      return;
    }
    const body: any = this.estimateDataMapper();
    if (body.items.length === 0) {
      return;
    }
    
    if (body.items[0].item === null) {
      return;
    }

    if(body?.items?.some(item => !item.itemId)){
      this.toastr.error('Please select item for each line item before proceeding');
      return;
    }

    if(this.subscription === 'Retail' || this.subscription === 'Retail Plus' ){
      let stockCheck: Array<StockCheck | null> = [];
      body.items?.forEach(item => {
        const product = this.products.find(product => product?._id == item?.itemId);
        if(product && product?.stockDetails?.openingStock < item?.quantity){
          stockCheck.push({
            item: item?.item,
            openingStock: product?.stockDetails?.openingStock,
            quantityAdded: item?.quantity
          })
          console.log(stockCheck, this.checkQuantityInStock); 
        }
      })
      this.checkQuantityInStock = stockCheck;

      if(this.checkQuantityInStock.length){
        this.outOfStock = true;
        return
      }
    }

    this.spinner.show();
    this.fileUploadService.emitFiles.next(true);
    this.fileUploadService.emitFiles.next(false);
    const formData = new FormData();
    // this.files.forEach((file, i) => {
    //   formData.append(`file${i}`, file);
    // });
    formData.append('payload', JSON.stringify(body));
    this.purchaseOrderService.createPurchaseOrder({formData, businessId:this.businessId}).subscribe(
      (resp) => {
        this.spinner.hide();
        this.purchaseOrderForm.reset();
        if(resp?.success){
          this.files = [];
          this.router.navigate(['/purchases/purchase-order']);
          this.toastr.success(resp?.message ?? 'Purchase order created');
        }
        else
          this.toastr.error(this.translateService.instant('Something went wrong!'));
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(this.translateService.instant('Something went wrong!'));
      }
    );
  }

  updatePurchaseOrder(): void {
    this.purchaseOrderForm.get('purchaseOrder').enable()
    if (this.purchaseOrderForm.invalid) {
      this.purchaseOrderForm.markAllAsTouched();
      this.formErrors = valueChanges(this.purchaseOrderForm, { ...this.formErrors }, this.formErrorMessages, this.translateService);
      return;
    }
    const body: any = { ...this.estimateDataMapper(), _id: this.orderToUpdate, businessId: this.businessId };

    if(body?.items?.some(item => !item.itemId)){
      this.toastr.error('Please select item for each line item before proceeding');
      return;
    }
    
    if(this.subscription === 'Retail' || this.subscription === 'Retail Plus' ){
      let stockCheck: Array<StockCheck | null> = [];
      body.items?.forEach(item => {
        const product = this.products.find(product => product?._id == item?.itemId);
        if(product && product?.stockDetails?.openingStock < item?.quantity){
          stockCheck.push({
            item: item?.item,
            openingStock: product?.stockDetails?.openingStock,
            quantityAdded: item?.quantity
          })
          console.log(stockCheck, this.checkQuantityInStock); 
        }
      })
      this.checkQuantityInStock = stockCheck;

      if(this.checkQuantityInStock.length){
        this.outOfStock = true;
        return
      }
    }
    
    this.spinner.show();
    this.fileUploadService.emitFiles.next(true);
    this.fileUploadService.emitFiles.next(false);
    const formData = new FormData();
    // this.files.forEach((file, i) => {
    //   formData.append(`file${i}`, file);
    // });
    formData.append('payload', JSON.stringify(body));
    this.purchaseOrderService.updateOrderDetails({formData, poId: this.orderToUpdate }).subscribe(resp => {
      this.spinner.hide();
      if(resp?.success){
        this.router.navigate(['/purchases/purchase-order']);
        this.toastr.success(resp?.message);
      }
      else{
      this.toastr.error(this.translateService.instant('Something went wrong!'));
      }
    }, error => {
      this.spinner.hide();
      this.toastr.error(this.translateService.instant('Something went wrong!'));
    });
  }

  saveFiles(files: File[]): void {
    this.files = files;
  }

  ngOnDestroy(): void {
      this.unsubscribe$.next();
      this.unsubscribe$.complete();
  }

}
