<div class="flex justify-center p-4">
  <div class="w-full flex flex-col space-y-4">
    <div>
      <div class="flex items-center justify-between">
        <div class="">
          <h1 class="text-2xl Gilroy-Bold inline-flex items-center">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 me-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z" />
            </svg>
           {{viewCustomerDetail?.customerName}}
          </h1>
        </div>
        <div class="">
            <app-button appAccessControl moduleType='sales' accessType='edit' (click)="editCustomer(viewCustomerDetail._id)" buttonText='{{"Edit Customer" | translate}}' type='primary'>{{'Edit Customer' | translate}}</app-button>
        </div>
      </div>
      <div class="">
        <ul class="ps-5 border-b-2 mt-8 pb-0">
          <li class="inline-block ps-4 active-border">
            <a href="#" class="Gilroy-Bold pb-3 text-lg">{{'Overview' | translate}}</a>
          </li>
          <!-- <li class="inline-block ps-4 ps-4">
            <a href="#" class="Gilroy-Bold pb-3 text-lg">{{'Invoice' | translate}}</a>
          </li>
          <li class="inline-block ps-4 ps-4">
            <a href="#" class="Gilroy-Bold pb-3 text-lg">{{'Activity' | translate}}</a>
          </li> -->
        </ul>
      </div>
      <div class="flex mt-5">
        <div class="flex-col w-1/3">
          <div class="">
            <div class="shadow-2xl rounded-xl p-5 me-3">
              <div class="">
                <img class="m-auto d-block" src="https://next.waveapps.com/e4c8545b5df804b145529ea5213ac8de.svg" />
              </div>
              <p class="Gilroy-Bold py-3 text-lg">{{'Primary Contact' | translate}}</p>
              <div class="">
                <p class="Gilroy-Bold text-lg"> {{viewCustomerDetail?.customerName}}</p>
                <p class="text-lg"> {{viewCustomerDetail?.email}}</p>
                <p class="text-lg"> {{viewCustomerDetail?.mobileNumber}}</p>
              </div>
            </div>
          </div>
          <div class="">
            <div class="shadow-2xl rounded-xl p-5 me-3">
              <div class="">
                <p class="Gilroy-Bold text-lg"> {{"Balance"| translate}}</p>
                <p class="text-2xl font-bold"> {{balance | currency : currencyDetails?.currencySymbol : 'symbol-narrow' : number}}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="w-2/3">
          <div class="grid grid-cols-3 gap-4 mt-5">
            <div class="bg-gradient rounded-xl p-5">
              <p class="text-white">{{'Paid' | translate}}</p>
              <h3 class="text-white text-2xl font-bold">{{customerStats?.paidInvoice?.total | currency : currencyDetails?.currencySymbol : 'symbol-narrow' : number}}</h3>
              <p class="text-white">{{'from' | translate}} {{customerStats?.paidInvoice?.count}} {{'invoices' | translate}}</p>
            </div>
            <div class="bg-input rounded-xl p-5">
              <p class="Gilroy-bold">{{'Total Overdue' | translate }}</p>
              <h3 class="text-2xl font-bold">{{customerStats?.overdueInvoice?.total | currency : currencyDetails?.currencySymbol : 'symbol-narrow' : number}}</h3>
              <p class="">{{'from' | translate }} {{customerStats?.overdueInvoice?.count}} {{'invoices' | translate }}</p>
            </div>
            <div class="bg-input rounded-xl p-5">
              <p class="Gilroy-bold">{{'Total Invoice amount' | translate }}</p>
              <h3 class="text-2xl font-bold">{{customerStats?.totalInvoice?.total | currency : currencyDetails?.currencySymbol : 'symbol-narrow' : number}}</h3>
              <p class="">{{'from' | translate }} {{customerStats?.totalInvoice?.count}} {{'invoices' | translate }}</p>
            </div>
          </div>
          <app-table-container [tableHeadings]='tableHeadings' [tableData]='tableData' [keys]='tableKeys' [actionTemplate]='actions'>
            <ng-template let-item #actions>
              <td class="pe-8 text-end">
                <app-button (click)='viewInvoice(item)' buttonText="{{'View' | translate}}" type="secondary"></app-button>
              </td>
            </ng-template>
          </app-table-container>
        </div>
      </div>
      <!-- <div class="flex mt-5">
        <div class="w-1/3">
          <div class="shadow-2xl rounded-xl p-5 me-3">
            <div class="">
              <img class="m-auto d-block" src="https://next.waveapps.com/e4c8545b5df804b145529ea5213ac8de.svg" />
            </div>
            <p class="Gilroy-Bold py-3 text-lg">{{'Primary Contact' | translate}}</p>
            <div class="">
              <p class="Gilroy-Bold text-lg"> {{viewCustomerDetail?.customerName}}</p>
              <p class="text-lg"> {{viewCustomerDetail?.email}}</p>
              <p class="text-lg"> {{viewCustomerDetail?.mobileNumber}}</p>
            </div>
          </div>
        </div>
        <div class="w-2/3">
          <div class="grid grid-cols-3 gap-4 mt-5">
            <div class="bg-gradient rounded-xl p-5">
              <p class="text-white">{{'Paid' | translate}}</p>
              <h3 class="text-white text-2xl font-bold">{{customerStats?.paidInvoice?.total | currency : currencyDetails?.currencySymbol : 'symbol-narrow' : number}}</h3>
              <p class="text-white">{{'from' | translate}} {{customerStats?.paidInvoice?.count}} {{'invoices' | translate}}</p>
            </div>
            <div class="bg-input rounded-xl p-5">
              <p class="Gilroy-bold">{{'Total Overdue' | translate }}</p>
              <h3 class="text-2xl font-bold">{{customerStats?.overdueInvoice?.total | currency : currencyDetails?.currencySymbol : 'symbol-narrow' : number}}</h3>
              <p class="">{{'from' | translate }} {{customerStats?.overdueInvoice?.count}} {{'invoices' | translate }}</p>
            </div>
            <div class="bg-input rounded-xl p-5">
              <p class="Gilroy-bold">{{'Total Invoice amount' | translate }}</p>
              <h3 class="text-2xl font-bold">{{customerStats?.totalInvoice?.total | currency : currencyDetails?.currencySymbol : 'symbol-narrow' : number}}</h3>
              <p class="">{{'from' | translate }} {{customerStats?.totalInvoice?.count}} {{'invoices' | translate }}</p>
            </div>
          </div>
          <app-table-container [tableHeadings]='tableHeadings' [tableData]='tableData' [keys]='tableKeys' [actionTemplate]='actions'>
            <ng-template let-item #actions>
              <td class="pe-8 text-end">
                <app-button (click)='viewInvoice(item._id)' buttonText="{{'View' | translate}}" type="secondary"></app-button>
              </td>
            </ng-template>
          </app-table-container>
        </div>
      </div> -->
    </div>
  </div>
</div>
