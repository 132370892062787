<div class="space-y-4 p-4">
    <div class="flex items-center justify-between">
        <h1 *ngIf="!update" class="text-2xl Gilroy-Bold inline-flex items-center">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 me-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z" />
              </svg>
            {{'Add a Estimate' | translate}}
        </h1>

        <h1 *ngIf="update" class="text-2xl Gilroy-Bold inline-flex items-center">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z" />
              </svg>
            {{'Update a Estimate' | translate}}
        </h1>
        <div appAccessControl moduleType='sales' accessType='edit' >
            <app-button *ngIf="!update" buttonText="{{'Save' | translate}}" type="primary" (handleClick)="createEstimate()"></app-button>
            <app-button *ngIf="update" buttonText="{{'Update' | translate}}" type="primary" (handleClick)="updateEstimate()"></app-button>
            <app-button class="ms-2" buttonText="{{'Cancel' | translate}}" type="third" routerLink='../' ></app-button>
        </div>
    </div>
    <form [formGroup]='estimateForm'>
        <div class="flex flex-col space-y-4">
            <div class="space-y-1">
               <label  class="block text-md Gilroy-Bold font-color-05">{{'Estimate #' | translate}}</label>
               <fieldset disabled>
                   <input id='estimateNumber' formControlName='estimateNumber' type="text" class="rounded-xl bg-gray-100 border-0 focus:ring-0 p-3" />
               </fieldset>
               <div class="text-sm text-red-500 ms-2 mt-1" *ngIf='formErrors.estimateNumber'>
                {{formErrors.estimateNumber}}</div>
            </div>
            <h3 class="text-lg Gilroy-Bold">{{"Customer Details" | translate}}</h3>
            <div class="flex space-x-4 rtl:space-x-reverse">
                <div class="w-1/4 space-y-1">
                    <label class="block text-sm Gilroy-Bold font-color-05">{{"Select Customer" | translate}}</label>
                    <ng-select [items]="customers"
                                        class="custom-selector" 
                                        bindLabel="customerName" 
                                        bindValue="_id"
                                        formControlName="customerId"
                                        placeholder='{{"Select one" | translate}}'
                                        (change) = "changeCustomer($event)">
                                    </ng-select>
                    <div class="text-sm text-red-500 ms-2 mt-1" *ngIf='formErrors.customerId'>
                        {{formErrors.customerId}}</div>
                </div>
                <div class="w-1/4 space-y-1">
                    <label class="block text-sm Gilroy-Bold font-color-05">{{'Date of Estimate Creation' | translate}}</label>
                    <input id='date' formControlName='date' type="date" class="w-full rounded-xl bg-input border-0 focus:ring-0 p-3" />
                    <div class="text-sm text-red-500 ms-2 mt-1" *ngIf='formErrors.date'>
                        {{formErrors.date}}</div>
                </div>
                <div class="w-1/4 space-y-1">
                    <label  class="block text-sm Gilroy-Bold font-color-05">{{'Date of Expiration' | translate}}</label>
                    <input id='expiryDate' formControlName='expiryDate' type="date" class="w-full rounded-xl bg-input border-0 focus:ring-0 p-3" />
                    <div class="text-sm text-red-500 ms-2 mt-1" *ngIf='formErrors.expiryDate'>
                        {{formErrors.expiryDate}}</div>
                </div>
                <div class="w-1/4 space-y-1">
                    <label  class="block text-sm Gilroy-Bold font-color-05">{{'Purchase Order' | translate}}</label>
                    <input id='purchaseOrder' formControlName='purchaseOrder' type="text" class="w-full rounded-xl bg-gray-100 border-0 focus:ring-0 p-3" />
                    <div class="text-sm text-red-500 ms-2 mt-1" *ngIf='formErrors.purchaseOrder'>
                        {{formErrors.purchaseOrder}}</div>
                </div>
            </div>
            <button routerLink='/sales/customers/create-customer' class="flex w-1/6 items-center Gilroy-Bold">
                <span  class="rounded-full bg-gradient text-white p-1 me-2">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="h-4 w-4">
                        <path  stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path>
                    </svg>
                </span>
                {{'Add Customer' | translate}}
            </button>
            <div class="flex space-x-4 rtl:space-x-reverse">
                <div class="w-1/4 space-y-1">
                    <label  class="block text-sm Gilroy-Bold font-color-05">{{'Subheading' | translate}}</label>
                    <input id='subheading' formControlName='subheading' type="text" class="w-full rounded-xl bg-gray-100 border-0 focus:ring-0 p-3" />
                    <div class="text-sm text-red-500 ms-2 mt-1" *ngIf='formErrors.subheading'>
                        {{formErrors.subheading}}</div>
                </div>
                <div class="w-1/4 space-y-1">
                    <label  class="block text-sm Gilroy-Bold font-color-05">{{'Footer' | translate}}</label>
                    <input id='footer' formControlName='footer' type="text" class="w-full rounded-xl bg-gray-100 border-0 focus:ring-0 p-3"/>
                    <div class="text-sm text-red-500 ms-2 mt-1" *ngIf='formErrors.footer'>
                        {{formErrors.footer}}</div>
                </div>
                <div class="w-1/4 space-y-1">
                    <label  class="block text-sm Gilroy-Bold font-color-05">{{'Memo' | translate}}</label>
                    <input id='memo' formControlName='memo' type="text" class="w-full rounded-xl bg-gray-100 border-0 focus:ring-0 p-3" />
                    <div class="text-sm text-red-500 ms-2 mt-1" *ngIf='formErrors.memo'>
                        {{formErrors.memo}}</div>
                </div>
                <div class="w-1/4"></div>
            </div>
            <h3 class="text-lg Gilroy-Bold">{{'Items' | translate}}</h3>
            <table class="min-w-full divide-y divide-gray-300 border-b"> 
                <thead class="bg-gray-100 h-12">
                        <tr class="text-start">
                            <th class="ps-5 rounded-ts-xl">{{'Item' | translate}}</th>
                            <th class="px-2">{{'SKU' | translate}}</th>
                            <th class="px-2">{{'HSN/SAC' | translate}}</th>
                            <th class="px-2">{{'Quantity' | translate}}</th>
                            <th class="px-2">{{'Unit Price' | translate}}</th>
                            <th class="px-2">{{'Select Tax' | translate}}</th>
                            <th class="px-2">{{'Total' | translate}}</th>
                            <th class="rounded-te-xl"></th>
                        </tr>
                </thead>
                <tbody class="border">
                    <ng-container formArrayName='items'>
                        <ng-container *ngFor='let itemList of items.controls; let i = index;'>
                            <tr [formGroup]='itemList' class="">
                                <td class="w-2/12 p-2">
                                    <ng-select [items]="availableProducts"
                                        class="custom-selector" 
                                        bindLabel="name" 
                                        bindValue="name"
                                        formControlName="item"
                                        [clearable]="false"
                                        placeholder='{{"Select one" | translate}}'
                                        (change) = "changeEvent($event, i)">
                                    </ng-select>
                                </td>
                                <td class="p-2">
                                    <fieldset disabled="disabled">
                                        <input formControlName='sku' type="text" class="w-full p-3 rounded-xl bg-gray-100 border-0 focus:ring-0" />
                                    </fieldset>
                                </td>
                                <td class="p-2">
                                    <fieldset disabled="disabled">
                                        <input formControlName='hsn_sac' type="text" class="w-full p-3 rounded-xl bg-gray-100 border-0 focus:ring-0" />
                                    </fieldset>
                                </td>
                                <td class="p-2">
                                    <input id='quantity' formControlName='quantity' type="number" class="w-full p-3 rounded-xl bg-input border-0 focus:ring-0" placeholder="0" />
                                </td>
                                <td class="p-2">
                                    <input id='unitPrice' formControlName='unitPrice' type="number" class="w-full p-3 rounded-xl bg-input border-0 focus:ring-0" placeholder="0" />
                                </td>
                                <td class="p-2 w-2/12">
                                    <ng-select class="custom-selector border-0 p-0" [items]="salesTaxes" 
                                        bindLabel="name" 
                                        formControlName="tax"
                                        multiple="true"
                                        (change)= 'changeTax($event, i)'
                                        placeholder='{{"Select Tax" | translate}}'>
                                        <ng-template ng-label-tmp let-item="item" let-clear="clear">
                                            <span class="ng-value-label">{{item.taxName}} {{item.tax | number: number}}%</span>
                                            <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                                        </ng-template>
                                    </ng-select>
                                </td>
                                <td class="p-2">
                                    <p>{{items.controls[i].get('quantity').value * items.controls[i].get('unitPrice').value | currency: currencyDetails?.currency : 'symbol-narrow' : number}}</p>
                                </td>
                                <td class="w-1/12">
                                    <svg (click)='removeItem(i)' xmlns="http://www.w3.org/2000/svg" name="delete" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="mx-auto h-5 w-5 text-red-500"><path _ngcontent-hsm-c58="" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"></path></svg>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="3"></td>
                                <td>
                                  <p class="text-red-500" *ngIf='itemList.get("quantity").hasError("min")'>{{'Atleast 1 unit is required'}}</p>
                                </td>
                                <td colspan="4" ></td>
                              </tr>
    
                        </ng-container>
                        <ng-container *ngIf='items.controls.length === 0'>
                            <tr>
                                <td colspan="6" class="text-center">
                                    No items found
                                </td>
                            </tr>
                        </ng-container>
                    </ng-container>
                    <tr>
                        <td vAlign='top' colspan="4"> 
                            <div class="ps-2">
                                <button (click)='addNewItem()' id="add" class="flex items-center Gilroy-Bold space-x-4">
                                    <span  class="rounded-full bg-gradient text-white p-1 me-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="h-4 w-4">
                                            <path  stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path>
                                        </svg>
                                    </span> {{'Add a Line' | translate}}
                                </button>
                            </div>
                        </td>
                        <td colspan="2">
                            <div class="Gilroy-Bold flex justify-between py-2">
                                <p>{{'Subtotal:' | translate}}</p>
                                <p>{{subTotal | currency: currencyDetails?.currency : 'symbol-narrow' : number}}</p>
                            </div>
                            <div class="Gilroy-Bold flex justify-between py-2">
                                <p>{{'Tax:' | translate}}</p>
                                <p>{{totalTax | currency: currencyDetails?.currency : 'symbol-narrow' : number}}</p>
                            </div>
                            <div class="Gilroy-Bold flex justify-between py-2 border-t">
                                <p>{{'Total Amount:' | translate}}</p>
                                <p>{{totalAmount | currency: currencyDetails?.currency : 'symbol-narrow' : number}}</p>
                            </div>
                        </td>
                    </tr> 
                </tbody>
            </table>
            <!-- <div class="mt-4">
                <app-file-upload appAccessControl moduleType="storage" accessType="edit" [addedFiles]="addedFiles"  (emitter)="saveFiles($event)" ></app-file-upload>
            </div> -->
        </div>
    </form>
</div>

<app-modal-container [isOpen]='outOfStock'>
    <div header class="text-start border-b pb-2 Gilroy-Bold">{{'Check Items' | translate}}</div>
    <div class="text-start" content>
        <p>Following items have insufficient qunatity in inventory:</p>
        <ul>
            <li class="Gilroy list-disc" *ngFor="let item of checkQuantityInStock">
                <div>{{item?.item}} added with {{item?.quantityAdded}} units has {{item?.openingStock}} units only</div>
            </li>
        </ul>

    </div>
    <div footer class="flex items-center ms-auto space-x-2 rtl:space-x-reverse">
        <!-- <app-button buttonText='{{"Delete" | translate}}' type='danger' rounded=true (handleClick)='deleteEstimateslist()' ></app-button> -->
        <app-button buttonText='{{"Ok" | translate}}' type='third' rounded=true (handleClick)='outOfStock = false'></app-button>
    </div>
</app-modal-container>
