import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/shared/services/http.service';

@Injectable({
  providedIn: 'root'
})
export class FileManagerService {

  
  constructor(private http : HttpService) { }
   
  uploadFile(businessId:any, payload:any) {
    return this.http.postMultipart(`/files/upload-file/${businessId}`, payload)
  }

  getAllFiles() {
    return this.http.get(`/files/all-files`)
  }

  deleteFile(id) {
    return this.http.get(`/files/delete-file/${id}`)
  }

  addShareInFile(fileId:any, payload:any) {
    return this.http.patch(`/files/add-share-in-file/${fileId}`, payload)
  }

  removeShareInFile(fileId:any, payload:any) {
    return this.http.patch(`/files/remove-share-in-file/${fileId}`, payload)
  }

  getAllSharedFiles() {
    return this.http.get(`/files/get-all-shared-files`)
  }

  // updateTask(taskId:any , payload:any) {
  //   return this.http.putMultipart(`/task/update-task/${taskId}`, payload)
  // }

  // addCommentInTask(taskId:any, payload:any) {
  //   return this.http.patch(`/task/add-comment-in-task/${taskId}`,payload)
  // }
  // getAllBusinessUsers(id) {
  //   return this.http.get(`/users/getBusinessUsers/${id}`)
  // }

  deleteStorageFile(fileId:any) {
    return this.http.delete(`/storage/deleteDocument/${fileId}`)
  }
  // markAsCompleted(taskId:any,payload:any) {
  //   return this.http.patch(`/task/mark-as-completed/${taskId}`,payload)
  // }
}
