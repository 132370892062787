<div class="w-full flex">
    <div class="w-3/4 flex flex-col space-y-4">
        <div class="flex items-center ps-5 pt-4 pr-7 justify-between">
            <div class="">
                <h1 class="text-2xl Gilroy-Bold inline-flex items-center">
                    <svg _ngcontent-hbd-c18="" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                        stroke="currentColor" class="h-6 w-6 me-2">
                        <path _ngcontent-hbd-c18="" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                            d="M16 8v8m-4-5v5m-4-2v2m-2 4h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z">
                        </path>
                    </svg>
                    {{'Recurring JV' | translate}} #{{jvDetails?.recurringJVId}}
                </h1>
            </div>
            <div class="flex space-x-2 rtl:space-x-reverse">
                <app-button type='primary' buttonText='{{"Upload Files" | translate}}' size='large' (handleClick)="uploadFiles()" ></app-button>
                <app-button *ngIf="!jvDetails?.isCanceled && !jvDetails?.isEnded" type='third' buttonText='{{"Update End Date" | translate}}' size='large' (handleClick)="showExtendModal = true" ></app-button>
                <app-button *ngIf="!jvDetails?.isCanceled && !jvDetails?.isEnded" type='danger' buttonText='{{"Cancel Recurring JV" | translate}}' size='large' (handleClick)="showCancelModal = true"></app-button>
                <button [ngClass]="!jvDetails?.isCanceled ? 'bg-green-400' : 'bg-red-800'" class="Gilroy-Bold rounded-lg text-white w-28">{{(jvDetails?.isCanceled ? 'Stopped' : jvDetails?.isEnded ? 'Completed' : 'Active') | translate}}</button>
            </div>
        </div>
    
        <my-tabs class="px-2">
            <my-tab tabTitle='{{"Details" | translate}}'>
                <div class="flex justify-between mb-4">
                    <div class="flex space-x-2 rtl:space-x-reverse ms-6">
                        <div class="bg-proile rounded-xl bg-white px-3 py-2 w-52">
                            <fieldset disabled>
                                <label for="startDate" class="block text-sm Gilroy-Bold bg-transparent font-color-05">{{'Start Date' |
                                    translate}}</label>
                                <!-- <input id='startDate' [value]="jvDetails?.startDate" type="date"
                                    class="w-full p-0 border-0 focus:ring-0"> -->
                                    <p>{{jvDetails?.startDate | date: 'dd-MM-YYYY'}}</p>
                            </fieldset>
                        </div>
                        <div class="bg-proile rounded-xl bg-white px-3 py-2 w-52">
                            <fieldset disabled>
                                <label for="endDate" class="block text-sm Gilroy-Bold bg-transparent font-color-05">{{(jvDetails?.isCanceled ? 'Cancel Date' : jvDetails?.isCompleted ? 'Completed Date' : 'End Date') | translate}}</label>
                                <p>{{(jvDetails?.isCanceled ? jvDetails?.cancelDate : jvDetails?.endDate) | date: 'dd-MM-YYYY'}}</p>
                            </fieldset>
                        </div>
                        <div class="bg-proile px-3 py-2 rounded-xl flex flex-col w-52">
                            <label class="Gilroy-Bold">{{'Frequency' | translate}}</label>
                            <fieldset >
                                <p>{{jvDetails?.frequency}}</p>
                            </fieldset>
                            </div>
                        <div class="bg-proile rounded-xl bg-white px-3 py-2 w-60 ms-5">
                            <label for="description"
                                class="block text-sm Gilroy-Bold bg-transparent font-color-05">{{'Description' |
                                translate}}</label>
                            <input id='description' [value]="jvDetails?.description" disabled
                                placeholder="{{'Enter Description' | translate }}" type="text"
                                class="w-full p-0 border-0 bg-transparent focus:ring-0">
                        </div>
                    </div>
                </div>
                <div class="ps-5 pe-7">
                    <table class="w-full">
                        <tr>
                            <td class="p-3 bg-input Gilroy-Bold rounded-s-xl w-1/12">{{'Type' | translate}}</td>
                            <td class="p-3 bg-input Gilroy-Bold w-3/12">{{'Description' | translate}}</td>
                            <td class="p-3 bg-input Gilroy-Bold">{{'Account' | translate}}</td>
                            <td class="p-3 bg-input Gilroy-Bold w-2/12">{{'Debit' | translate}}</td>
                            <td class="p-3 bg-input Gilroy-Bold w-2/12  last:rounded-e-xl ">{{'Credit' | translate}}
                            </td>
                        </tr>
                        <ng-container >
                            <ng-container *ngFor='let control of jvDetails?.journal; let i = index;'>
                                <tr class="">
                                    <td class="w-2/12 p-2">
                                        <!-- <ng-select [items]="['Credit', 'Debit']" class="custom-selector"
                                            placeholder='{{"Select one" | translate }}'
                                            [selecteed]="control?.type"
                                            readonly>
                                        </ng-select> -->
                                        <p>{{control?.type}}</p>
                                    </td>
                                    <td class="w-3/12 p-2">
                                        <!-- <input disabled [value]="control?.description" type="text"
                                            class="w-full p-3 rounded-xl bg-input border-0" /> -->
                                        <p>{{control?.description}}</p>
                                    </td>
                                    <td class="p-2">
                                        <!-- <ng-select [items]="accounts" class="custom-selector" bindLabel="accountName"
                                            [selected]="control?." placeholder='{{"Select one" | translate }}'
                                            groupBy='accountType' readonly>
                                        </ng-select> -->
                                        <p>{{control?.accountName}}</p>
                                    </td>
                                    <td class="p-2 w-2/12">
                                        <fieldset disabled>
                                            <input [ngClass]='control.type==="Credit" ? "hidden":"block"'
                                                [value]="control?.debit" type="number"
                                                class="w-full p-3 rounded-xl bg-input border-0" />
                                        </fieldset>
                                    </td>
                                    <td class="p-2 w-2/12">
                                        <fieldset disabled>
                                            <input [hidden]='control.type==="Debit"' [value]='control?.credit'
                                                type="number" class="w-full p-3 rounded-xl bg-input border-0" />
                                        </fieldset>
                                    </td>
                                </tr>
    
                            </ng-container>
                            <ng-container *ngIf='jvDetails?.journal.length === 0'>
                                <tr>
                                    <td colspan="7" class="text-center p-2">{{'No items added' | translate}}</td>
                                </tr>
                            </ng-container>
                        </ng-container>
                        <tr>
                            <td vAlign='top' colspan="3">
                            </td>
                            <td colspan="3" class="p-3 bg-input rounded-xl">
                                <div class="flex justify-between py-2">
                                    <p class="">{{'Total Debits' | translate}}</p>
                                    <p> </p>
                                    <p class="">{{'Total Credits' | translate}}</p>
                                </div>
                                <div class="flex justify-between">
                                    <p class="text-lg Gilroy-Bold">{{debitTotal | currency: currencyDetails?.currency :
                                        'symbol-narrow' : number}}</p>
                                    <p></p>
                                    <p class="text-lg Gilroy-Bold">{{creditTotal | currency: currencyDetails?.currency :
                                        'symbol-narrow' : number}}</p>
                                </div>
                            </td>
                        </tr>
                    </table>
                </div>
            </my-tab>
            <my-tab tabTitle='{{"Notes" | translate }}'>
                <div class="px-6">
                    <textarea name="notes" readonly id="notes" [value]="jvDetails?.description"
                        placeholder="{{'Write notes here' | translate }}"
                        class='w-full border-0 focus:ring-0 bg-input rounded-xl' cols="30" rows="11"></textarea>
                </div>
            </my-tab>
            <!-- <my-tab appAccessControl moduleType="storage" accessType="view" tabTitle="{{'Files' | translate}}">
                <app-file-upload [addedFiles]="addedFiles" (emitter)="saveFiles($event)" ></app-file-upload>
            </my-tab> -->
        </my-tabs>
    
        <div class="flex items-center px-8 border-t py-4">
            <div class="ms-auto flex items-center">
                <span *ngIf='issuedBy' class="me-2">
                    <span class="Gilroy-Bold me-2">{{'Issued By' | translate }} -</span>
                    <span>{{issuedBy}}</span>
                </span>
            </div>
        </div>
    </div>
    <div class="w-1/4 border-s border-gray-200 ps-10 " > 
        <h2 class="Gilroy-Bold text-2xl inline-flex items-center">{{'JV Created' | translate }} - </h2>
        <div class="space-y-3 mt-5">
            <ul>
                <li (click)="downloadJV(jv)" class="list-disc border-custom px-3 py-2 rounded flex justify-between items-center box-shadow" *ngFor="let jv of creatdJV; let i = index">
                    <a target="_blank" class=" Gilroy-SemiBold text-blue-600 hover:underline cursor-pointer text-lg" >{{jv?.transactionNumber}}</a>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 me-2">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M20.25 7.5l-.625 10.632a2.25 2.25 0 01-2.247 2.118H6.622a2.25 2.25 0 01-2.247-2.118L3.75 7.5m8.25 3v6.75m0 0l-3-3m3 3l3-3M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125z" />
                    </svg>                   
                </li>
            </ul>
        </div>
    </div>
</div>

<app-modal-container [isOpen]='showCancelModal'>
    <div header class="Gilroy-Bold">
      {{'Cancel Recurring JV' | translate}}
    </div>
    <div class="space-y-2" content>
      <p class="Gilroy-SemiBold">{{'Are you Sure?' | translate}}</p>
      <p class="Gilroy">{{'Cancelling recurring JV will not create any JV from this template in NuMetirc' | translate}}</p>
    </div>
    <div footer class="space-x-2 rtl:space-x-reverse">
      <app-button type='danger' buttonText='{{"Cancel" | translate}}' (handleClick)='stopRecurringJV()'></app-button>
      <app-button type='third' buttonText='{{"Close" | translate}}' (handleClick)='showCancelModal=false'></app-button>
    </div>
  </app-modal-container>

  <app-modal-container [isOpen]='showExtendModal'>
    <div header class="Gilroy-Bold">{{'Select New End Date' | translate}}</div>
    <div content class="flex flex-col" style="overflow-y: auto">
      <label for="">{{'New end date' | translate}}</label>
      <input [formControl]='updatedDate' [min]="minDate" type="date" class="w-1/3 rounded-xl bg-gray-100 border-0 p-3">
      <div *ngIf='updatedDate.getError("required") && updatedDate.dirty' class="text-sm text-red-400 ms-2">{{'End date is required' | translate}}</div>
      <div *ngIf='updatedDate.getError("invalidDate") && updatedDate.dirty && !updatedDate.getError("required")' class="text-sm text-red-400 ms-2">{{'End date must be in correct format' | translate}}</div>
    </div>
    <div footer class='flex space-x-2 rtl:space-x-reverse'>
      <app-button type='primary' buttonText='{{"Save" | translate}}' (handleClick)='updateEndDate()'></app-button>
      <app-button type='third' buttonText='{{"Cancel" | translate}}' (handleClick)='showExtendModal = false'></app-button>
    </div>
  </app-modal-container>