<div class="flex justify-center p-4">
    <div class="w-full flex flex-col space-y-4">
        <div class="flex items-center">
            <h1 class="text-2xl Gilroy-Bold inline-flex items-center">
                <svg  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="h-6 w-6 me-2">
                    <path  stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 8v8m-4-5v5m-4-2v2m-2 4h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"></path>
                </svg>
                {{'Customers Statement' | translate}}
            </h1>
        </div>
        <div class=' ms-8'>
        <div [formGroup]='form' class="flex">
            <div class="w-1/5 me-4">
                <label class="block text-black text-md Gilroy-Bold mb-1">{{'Customer' | translate}}</label>
                <ng-select class="w-full rounded-xl border-none bg-transparent custom-selector"
                    [items]='customers'
                    bindLabel="display"
                    formControlName='customer'
                    placeholder='{{"Type Customer Number" | translate}}'>
                </ng-select>
            </div>
            <div class="w-1/5 me-4">
                <label class="block text-black text-md Gilroy-Bold mb-1">{{'Type' | translate}}</label>
                <ng-select class="w-full rounded-xl border-none bg-transparent custom-selector"
                    [items]='["Outstanding Invoices", "Account Activity"]'
                    bindLabel="customerName"
                    [clearable]='false'
                    [searchable]='false'
                    formControlName='type'
                    placeholder='{{"Select Type" | translate}}'>
                 </ng-select>

            </div>
            <div [ngClass]='form.get("type").value === "Account Activity" ? "": "hidden"' class="w-1/5 me-4">
                <label for="" class="block mb-1 Gilroy-Bold text-md text-black">{{'From Date' | translate}}</label>
                <input type="date" class="p-3 bg-input w-full border-0 rounded-xl" formControlName='fromDate'>
            </div>
            <div [ngClass]='form.get("type").value === "Account Activity" ? "": "hidden"' class="w-1/5 me-4">
                <label for="" class="block mb-1 Gilroy-Bold text-md text-black">{{'End Date' | translate}}</label>
                <input type="date" class="p-3 bg-input w-full border-0 rounded-xl" formControlName='endDate'>
            </div>
            <div class="w-1/5">
                <label for="" class="block mb-2 opacity-0">{{'Create Statement' | translate}}</label>
                <app-button (handleClick)='createStatement()' type='primary' size='large' buttonText='{{"Create Statement" | translate}}'></app-button>
            </div>
        </div>
        <div class="flex space-x-3">
            <div class="w-1/5">
                <div *ngIf='formErrors.customer' class="text-red-500 text-sm">{{formErrors.customer}}</div>
            </div>
            <div class="w-1/5"></div>
            <div class="w-1/5">
                <div *ngIf='formErrors.fromDate' class="text-red-500 text-sm">{{formErrors.fromDate}}</div>
            </div>
            <div class="w-1/5">
                <div *ngIf='formErrors.endDate' class="text-red-500 text-sm">{{formErrors.endDate}}</div>
            </div>
        </div>
        </div>
        <div *ngIf='!showStatement'>
            <img src="assets/images/images.png" class="block m-auto w-80 " /> 
            <p class="text-center Gilroy-Bold text-black text-lg">{{'Keep customers informed' | translate}}</p>
            <p class="text-center m-auto w-1/2">{{'Statement Info' | translate}}</p>
        </div>
        <div class="flex justify-center space-x-2 rtl:space-x-reverse my-4" *ngIf='showStatement'>
            <div class="border rounded-xl flex items-center ps-2 me-2">
                <a [href]='shareURL' target="_blank" class="max-w-[250px] overflow-hidden whitespace-nowrap overflow-ellipsis">
                  {{shareURL}}
                </a>
                <app-button type='primary' [buttonText]='copyButtonText | translate' (handleClick)='copyLink()'></app-button>
            </div>
            <app-button buttonText="{{'Download PDF' | translate}}" type='primary' (handleClick)='downloadPdf()'></app-button>
            <!-- <app-button buttonText='{{"Send Statement" | translate}}' type='primary' (handleClick)='sendStatementModalOpen = true'></app-button> -->
        </div>
        <customer-statement *ngIf='showStatement' [config]='config'></customer-statement>
    </div>
</div>


<app-modal-container [isOpen]='sendStatementModalOpen'>
    <div header>{{'Email Customer Statement' | translate}}</div>
    <div content>
        <form [formGroup]='emailForm'>
            <div class="flex flex-col">
                <label for="" class="block Gilroy-Bold mb-1">{{'Email' | translate}}</label>
                <input formControlName='email' type="email" class="p-3 bg-input w-full border-0 rounded-xl">
                <span *ngIf='emailFormErrors.email'>{{emailFormErrors.email}}</span>
            </div>
        </form>
    </div>
    <div footer>
        <app-button (handleClick)='sendStatementEmail()' type='primary' buttonText='{{"Send" | translate}}' class="me-2"></app-button>
        <app-button (handleClick)='sendStatementModalOpen=false' type='third' buttonText='{{"Cancel" | translate}}'></app-button>
    </div>
</app-modal-container>

