<div class="flex justify-center p-4">
    <div class="w-full flex flex-col space- y-4">
      <div class="flex items-center justify-between">
        <h1 class="text-2xl Gilroy-Bold inline-flex items-center">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 me-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z" />
          </svg>
          <span class="text-2xl Gilroy-Bold inline-flex items-center">{{'Add a Invoice' | translate}}</span>
        </h1>
        <div class="space-x-2 rtl:space-x-reverse">
          <app-button  type='primary' buttonText='{{"Create Invoice" | translate}}' (handleClick)='createInvoice()'></app-button>
          <app-button buttonText='{{"Cancel" | translate}}' type='third' routerLink='../'></app-button>
        </div>
      </div>
      <div class="w-5/6 m-auto mt-5">
        <form class="space-y-4 mb-4" [formGroup]='invoiceForm'>
          <div class="rounded-3xl px-8 py-5 box-shadow-sales bg-white">
            <h3 class="Gilroy-Bold text-lg mb-4">{{'Business & Contact Details' | translate}}</h3>
            <div class="space-y-6 w-full">
              <div class="flex space-x-8 rtl:space-x-reverse w-full">
                <div class="flex-1 mb-4">
                  <label for='mobileNumber' class="block text-sm Gilroy-Bold font-color-05 mb-2">{{'Company Logo' | translate}}</label>
                  <div class="border flex items-center justify-center w-[320px] h-[180px] bg-white">
                    <svg *ngIf='!customizationSettings?.companyLogo' xmlns="http://www.w3.org/2000/svg" class="h-16 w-16" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4" />
                    </svg>
                    <img *ngIf='customizationSettings?.companyLogo' [src]="customizationSettings?.companyLogo" class="w-full" alt="Logo">
                  </div>
                </div>
                <div class="flex-1 mb-4">
                  <div>
                    <label for='title' class="block text-sm Gilroy-Bold font-color-05  mb-2">{{'Invoice Title' | translate }}</label>
                    <fieldset [disabled]='!isFieldEditable'>
                      <input id="title" fieldS formControlName="title" type="text" class="bg-input h-12 mb-3 rounded-xl w-full ps-5 border-0" placeholder="{{'Invoice Title' | translate}}" />
                      <div class="text-red-500" *ngIf='formErrors.title'>{{formErrors.title}}</div>
                    </fieldset>
                  </div>
                </div>
              </div>
              <div class="flex flex-col w-full">
                <label class="block text-sm Gilroy-Bold font-color-05 mb-1">{{'Contact Details' | translate}}</label>
                <ng-container *ngIf='business'>
                  <div class="Gilroy-Bold">{{business?.companyName}}</div>
                  <div>{{business?.address?.addressLineFirst}}</div>
                  <div>{{business?.address?.addressLine2}}</div>
                  <div>{{business?.address?.city ? business?.address?.city + ',' : ''}} {{business?.address?.state}}</div>
                  <div>{{business?.country}}</div>
                </ng-container>
              </div>
            </div>
          </div>
          <div class="rounded-3xl px-8 py-5 box-shadow-sales bg-white">
            <div class="w-full space-y-8">
              <div class="flex">
              <div class="w-1/4 pe-1">
                <label class="block text-sm Gilroy-Bold font-color-05 mb-2">{{'Country Code' | translate}}</label>
                <fieldset >
                  <ng-select [items]="countryWithCode"
                    class="custom-selector" 
                    bindLabel="text" 
                    bindValue="code"
                    formControlName="countryCode"
                    placeholder='{{"Select Country Code" | translate}}'>
                  </ng-select>
                  <div class="text-red-500" *ngIf='formErrors.countryCode'>{{formErrors.countryCode}}</div>
                </fieldset>
              </div>
              <div class="w-1/4 px-1">
                <label class="block text-sm Gilroy-Bold font-color-05 mb-2">{{'Customer Mobile Number' | translate}}</label>
                <fieldset >
                    <input (focusout)="searchCustomerByMobileNumber()" pattern="^[1-9][0-9]*$" oninput="if(!this.value.match('^[1-9][0-9]*$'))this.value='';" formControlName='customerNumber' type="number" class="bg-input w-full rounded-lg border-0 h-11 " />
                    <div class="text-red-500" *ngIf='formErrors.customerNumber'>{{formErrors.customerNumber}}</div>
                </fieldset>
              </div>
              <div class="w-1/4 ps-1">
                <label class="block text-sm Gilroy-Bold font-color-05 mb-2">{{'Add a Project' | translate}}</label>
                <fieldset >
                  <ng-select [items]="projects"
                  class="custom-selector" 
                  bindLabel="projectName" 
                  bindValue="_id"
                  formControlName="projectId"
                  [readonly]='!isFieldEditable'
                  placeholder='{{"Select Project Name" | translate}}'>
                </ng-select>
              </fieldset>
              </div>
            </div>
            <div class="flex space-x-2">
                <div class="w-1/4">
                    <label class="Gilroy-Bold">{{'Customer First Name' | translate}}</label>
                    <fieldset >
                        <input formControlName='customerFirstName' type="text" class="bg-input w-full rounded-lg border-0 h-11 " />
                    </fieldset>
                </div>
                <div class="w-1/4">
                    <label class="Gilroy-Bold">{{'Customer Last Name' | translate}}</label>
                    <fieldset >
                        <input formControlName='customerLastName' type="text" class="bg-input w-full rounded-lg border-0 h-11 " />
                    </fieldset>
                </div>
                <div class="w-1/4">
                    <label class="Gilroy-Bold">{{'Customer Email' | translate}}</label>
                    <fieldset >
                        <input formControlName='customerEmail' type="text" class="bg-input w-full rounded-lg border-0 h-11 " />
                    </fieldset>
                </div>
                <div class="w-1/4">
                    <label class="Gilroy-Bold">{{'Customer Company Name' | translate}}</label>
                    <fieldset >
                        <input formControlName='customerCompanyName' type="text" class="bg-input w-full rounded-lg border-0 h-11 " />
                    </fieldset>
                </div>
            </div>
              <div class="flex space-x-2 rtl:space-x-reverse">
                <div class="flex flex-col w-1/4">
                  <label class="Gilroy-Bold">{{'Purchase Order #' | translate}}</label>
                  <fieldset [disabled]='!isFieldEditable' >
                    <input formControlName='purchaseOrder' type="text" class="bg-input w-full rounded-lg border-0 h-11 " />
                    <div class="text-red-500" *ngIf='formErrors.purchaseOrder'>{{formErrors.purchaseOrder}}</div>
                  </fieldset>
                </div>
                <div class="flex flex-col w-1/4">
                  <label class="Gilroy-Bold">{{'Invoice Date' | translate}}</label>
                  <fieldset [disabled]='!isFieldEditable' >
                    <input [readOnly]="true" formControlName='invoiceDate' type="date" class="bg-input w-full rounded-lg border-0 h-11 " />
                    <div class="text-red-500" *ngIf='formErrors.invoiceDate'>{{formErrors.invoiceDate}}</div>
                  </fieldset>
                </div>
                <div *ngIf="showPaymentMethod()" class="flex flex-col w-1/4">
                  <label class="Gilroy-Bold">{{'Payment Account' | translate}}</label>
                  <ng-select [items]="paymentAccounts$ | async"
                    class="custom-selector" 
                    bindLabel="accountName"
                    formControlName="paymentMethod"
                    [clearable]="false"
                    placeholder='{{"Select payment account" | translate}}'>
                  </ng-select>                  
                  <div class="text-red-500" *ngIf='formErrors.paymentMethod'>{{formErrors.paymentMethod}}</div>
                </div>
                <div *ngIf="invoiceForm.get('customerBalance').value" class="flex flex-col w-1/4">
                  <label class="block text-sm Gilroy-Bold font-color-05 mb-2">{{'Customer Balance' | translate}}({{'As of'}}-{{customerBalanceUpdatedAt | date: 'dd/MM/yyyy HH:mm'}})</label>
                  <div class="flex items-center">
                    <fieldset class="w-4/5" [disabled]='true' >
                      <input [readOnly]="true" formControlName='customerBalance' type="text" class="bg-input w-full rounded-lg border-0 h-11 " />
                      <div class="text-red-500" *ngIf='formErrors.invoiceDate'>{{formErrors.invoiceDate}}</div>
                    </fieldset>
                    <span class="w-1/5" >
                      <svg (click)="searchCustomerByMobileNumber()" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                      </svg>                      
                    </span>
                  </div>
                </div>
              </div>
              <div *ngIf="invoiceForm.get('customerBalance').value && invoiceForm.get('customerBalance').value > 0" class="flex space-x-2 rtl:space-x-reverse">
                <div class="w-1/4" ></div>
                <div class="w-1/4" ></div>
                <div *ngIf="showPaymentMethod()" class="w-1/4" ></div>
                <div class="w-1/4 flex space-x-2" >
                  <input type="checkbox" formControlName="useCustomerBalance" ><p class="Gilroy text-sm">{{'Use customer balance for payment' | translate}}</p>
                </div>
              </div>
              <table class="w-full">
                <tr>
                  <td class="p-2 bg-input Gilroy-Bold rounded-s-xl w-3/14">{{tableFields.items | translate}}</td>
                  <td class="p-2 bg-input Gilroy-Bold w-3/12">{{tableFields.sku | translate}}</td>
                  <td class="p-2 bg-input Gilroy-Bold w-3/12">{{tableFields.hsn_sac | translate}}</td>
                  <td class="p-2 bg-input Gilroy-Bold w-1/12">{{tableFields.units | translate}}</td>
                  <td class="p-2 bg-input Gilroy-Bold w-2/12">{{tableFields.price | translate}}</td>
                  <td class="p-2 bg-input Gilroy-Bold w-3/12">{{'Tax:' | translate}}</td>
                  <td class="p-2 bg-input Gilroy-Bold">{{tableFields.amount | translate}}</td>
                  <td class="p-2 bg-input Gilroy-Bold rounded-e-xl"></td>
                </tr>
                <ng-container formArrayName='items'>
                  <ng-container *ngFor='let control of items.controls; let i = index;'>
                      <tr [formGroup]='control' class="">
                          <td class="w-3/12 p-2">
                              <ng-select [items]="availableProducts"
                              class="custom-selector" 
                              bindLabel="name" 
                              bindValue="name"
                              formControlName="item"
                              [readonly]='!isFieldEditable'
                              [clearable]="false"
                              placeholder='{{"Select one" | translate}}'
                              (change) = "changeEvent($event, i)">
                            </ng-select>
                          </td>
                          <td class="p-2 w-3/12" >
                            <fieldset disabled='true'>
                              <input formControlName='sku' type="text" class="w-full p-3 rounded-xl bg-input border-0"/>
                            </fieldset>
                          </td>
                          <td class="p-2 w-3/12" >
                            <fieldset disabled='true'>
                              <input formControlName='hsn_sac' type="text" class="w-full p-3 rounded-xl bg-input border-0"/>
                            </fieldset>
                          </td>
                          <td class="p-2 w-1/12">
                            <fieldset [disabled]='!isFieldEditable'>
                              <input id='quantity' formControlName='unit' type="number" min="0" class="w-full p-3 rounded-xl bg-input border-0"/>
                            </fieldset>
                          </td>
                          <td class="p-2 w-2/12">
                            <fieldset [disabled]='!isFieldEditable'>  
                              <input id='price' formControlName='price' type="number" min="0" class="w-full p-3 rounded-xl bg-input border-0"/>
                              <div class="text-red-500" *ngIf='formErrors.price'>{{formErrors.price}}</div>
                            </fieldset>
                          </td>
                          <td class="p-2 w-3/12">
                              <ng-select class="custom-selector border-0 p-0" 
                              [readonly]='!isFieldEditable'
                              [items]="taxes" 
                              bindLabel="name" 
                              formControlName="tax"
                              multiple="true"
                              (change)= 'changeTax($event, i)'
                              placeholder='{{"Select Tax" | translate}}'>
                              <ng-template ng-label-tmp let-item="item" let-clear="clear">
                                <span class="ng-value-label">{{item.taxName}} {{item.tax | number: number}}%</span>
                                <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                              </ng-template>
                            </ng-select>
                          </td>
                          <td class="p-2 ">
                              <p>{{items.controls[i].get('unit').value * items.controls[i].get('price').value | currency: currencySymbol : 'symbol-narrow' : number}}</p>
                          </td>
                          <td *ngIf='isFieldEditable' class="w-1/12">
                              <svg (click)='removeItem(i)' xmlns="http://www.w3.org/2000/svg" name="delete" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="mx-auto h-5 w-5 text-red-500"><path _ngcontent-hsm-c58="" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"></path></svg>
                          </td>
                      </tr>
                      <tr>
                        <td colspan="3"></td>
                        <td>
                          <p class="text-red-500" *ngIf='control.get("unit").hasError("min")'>{{'Atleast 1 unit is required'}}</p>
                        </td>
                        <td colspan="4" ></td>
                      </tr>
  
                  </ng-container>
                  <ng-container *ngIf='items.controls.length === 0'>
                    <tr>
                      <td colspan="8" class="text-center p-2">{{'No items added' | translate}}</td>
                    </tr>
                  </ng-container>
                </ng-container>
                <tr>
                  <td  vAlign='top' colspan="3"> 
                      <div *ngIf='isFieldEditable' class="ps-2">
                          <button  (click)='addNewItem()' id="add" class="flex items-center Gilroy-Bold space-x-4">
                              <span  class="rounded-full bg-gradient text-white p-1 me-2">
                                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="h-4 w-4">
                                      <path  stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path>
                                  </svg>
                              </span> {{'Add a Line' | translate}}
                          </button>
                      </div>
                  </td>
                  <td colspan="4">
                    <div class="Gilroy-Bold flex justify-between py-2">
                      <p>{{'Subtotal:' | translate}}</p>
                      <p>{{subTotal | currency: currencySymbol : 'symbol-narrow' : number}}</p>
                    </div>
                    <div class="Gilroy-Bold flex items-center justify-between py-2">
                      <p>{{'Discount (%):' | translate}}</p>
                      <fieldset class="w-1/9" [disabled]='!isFieldEditable'>
                        <input type="number" class="p-2 rounded-xl bg-input border-0" formControlName='discount' min="0">
                        <div class='text-red-400' *ngIf='formErrors.discount'>{{formErrors.discount}}</div>
                      </fieldset>
                      <p>{{discount | currency: currencySymbol : 'symbol-narrow' : number}}</p>
                    </div>
                    <div class="Gilroy-Bold flex justify-between py-2">
                      <p>{{'Discount Subtotal:' | translate}} </p>
                      <p>{{discountValue | currency: currencySymbol : 'symbol-narrow' : number}}</p>
                    </div>
                    <div class="Gilroy-Bold flex justify-between py-2">
                      <p>{{'Tax:' | translate}}</p>
                      <p>{{tax | currency: currencySymbol : 'symbol-narrow' : number}}</p>
                    </div>
                    <div class="Gilroy-Bold flex justify-between py-2 border-t">
                      <p>{{'Total Amount:' | translate}}</p>
                      <p>{{totalAmount | currency: currencySymbol : 'symbol-narrow' : number}}</p>
                    </div>
                  </td>
              </tr> 
              </table>
              <div class="pt-4 border-t border-gray-100 space-y-4">
                  <div class="flex flex-col">
                    <label class="Gilroy-Bold">{{'Footer' | translate}}</label>
                    <fieldset [disabled]='!isFieldEditable'>
                      <textarea formControlName='Footer' class="h-40 w-full rounded-xl bg-input border-0"></textarea>
                    </fieldset>
                  </div>
              </div>
            </div>
            <!-- <div class="mt-4">
              <app-file-upload appAccessControl moduleType="storage" accessType="edit" (emitter)="saveFiles($event)" ></app-file-upload>
            </div> -->
          </div>
        </form>
      </div>
    </div>
  </div>
  
  
  
  <app-modal-container [isOpen]='outOfStock'>
    <div header class="text-start border-b pb-2 Gilroy-Bold">{{'Check Items' | translate}}</div>
    <div class="text-start" content>
        <p>Following items have insufficient qunatity in inventory:</p>
        <ul>
            <li class="Gilroy list-disc" *ngFor="let item of checkQuantityInStock">
                <div>{{item?.item}} added with {{item?.quantityAdded}} units has {{item?.openingStock}} units only</div>
            </li>
        </ul>
  
    </div>
    <div footer class="flex items-center ms-auto space-x-2 rtl:space-x-reverse">
        <!-- <app-button buttonText='{{"Delete" | translate}}' type='danger' rounded=true (handleClick)='deleteEstimateslist()' ></app-button> -->
        <app-button buttonText='{{"Ok" | translate}}' type='third' rounded=true (handleClick)='outOfStock = false'></app-button>
    </div>
  </app-modal-container>
  
  <app-image-cropper [isOpen]='openModalForEsign' [imageCropperConfig]='imageCropperConfig' (base64Image)='loadEsign($event)' (closeImage)='openModalForEsign=false'></app-image-cropper>
  
  <app-image-cropper [isOpen]='openModal' [imageCropperConfig]='imageDataQR' (base64Image)='loadQR($event)' (closeImage)='openModal=false'></app-image-cropper>
  
  <!-- <app-image-cropper [imageData]='imageDataEsign' (base64Image)='loadEsign($event)'></app-image-cropper> -->
  