<div  class="relative">
    <div appAccessControl moduleType="storage" accessType="edit" [ngClass]="haveStorageSubscription ? 'blur-0' : 'blur-lg'" *ngIf="filesArrToDisplay.length < 3" class=" flex justify-center items-center w-100 h-28 border border-dashed active-border-invoice bg-white rounded-3xl py-2 relative">
        <input [disabled]="!haveStorageSubscription" #fileInput (change)="addNewFile($event)" (click)="$event.target.value=null" accept=".jpeg, .jpg, .png, .pdf, .docx, .xlsx, .csv, .xml" type="file" class="w-full h-full opacity-0 absolute cursor-pointer">
        <label for="fileInput" class=" space-y-2 cursor-pointer">
            <p (click)="fileInput.click()" class="text-center text-xl Gilroy-SemiBold text-blue-600 ">{{'Select a file to upload' | translate}}</p>
            <p (click)="fileInput.click()" class="text-center text-sm Gilroy-SemiBold ">{{'Only PDF, Image(JPEG, PNG), CSV, XLSX, DOCX, XML file types are supported' | translate}}</p>
        </label>
    </div>
    
    <app-table-container appAccessControl moduleType="storage" accessType="view" [ngClass]="haveStorageSubscription ? 'blur-0' : 'blur-lg'" [keys]="tableKeys" [tableHeadings]="tableHeadings" [tableData]="filesArrToDisplay" [actionTemplate]='actionTemplate' >
        <ng-template let-item #actionTemplate >
            <td class="text-center pe-10" >
                <span class="flex w-full justify-center items-center">
                    <svg (click)="showRemoveFileConfirmation(item)" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                    </svg>              
                </span>
            </td>
        </ng-template>
    </app-table-container>
    
    <app-modal-container [isOpen]='showDeleteModal'>
        <div header>
          {{'Are you sure?' | translate}}
        </div>
        <div content>
          <p>{{'Confirm to delete file' | translate}}</p>
        </div>
        <div footer class="space-x-2 rtl:space-x-reverse">
          <app-button type='danger' buttonText='{{"Delete" | translate}}' (handleClick)='removeFile()'></app-button>
          <app-button type='third' buttonText='{{"Cancel" | translate}}' (handleClick)='showDeleteModal=false'></app-button>
        </div>
      </app-modal-container>
    
    <div *ngIf="!haveStorageSubscription" class="z-[29] text-center absolute top-[25%] left-[25%]" >You don't have any active storage plan, purchase one to access this feature</div>
</div>