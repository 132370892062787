<div class="p-7 rounded-3xl bg-light-gray-1 mt-5 mb-2">
    <h3 class="Gilroy-bold text-xl Gilroy-Bold">{{'General Settings' | translate }}</h3>
    <div class="">
        <p class="text-lg Gilroy-Medium mt-3 mb-3">{{'Company logo' | translate }}</p>
        <div class="flex items-center space-x-4 rtl:space-x-reverse mb-4">
            <div class="border flex items-center justify-center w-[200px] h-[112px] bg-white">
                <svg *ngIf='companyLogo === "assets/images/business.jpg"' xmlns="http://www.w3.org/2000/svg" class="h-16 w-16" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4" />
                </svg>
                <img *ngIf='companyLogo !== "assets/images/business.jpg"' [src]="companyLogo" width="200" alt="Logo">
            </div>
            <div class="flex flex-col space-y-4">
                <label class="bg-gradient px-4 py-2 text-sm rounded-full text-white text-center cursor-pointer" for="file">
                    <input type="file" id='file' accept="image/png, image/jpeg, image/jpg" (change)='fileEvent($event)' hidden>
                    {{companyLogo === "assets/images/business.jpg" ? 'Add Logo' : 'Update' | translate }}
                </label>
                <app-button *ngIf='companyLogo !== "assets/images/business.jpg"' (handleClick)='openDeleteModal= true' buttonText='{{"Remove" | translate }}' rounded='true' type='danger'></app-button>
            </div>
        </div>
        <p class="text-lg Gilroy-Medium mb-3">{{'Template' | translate }}</p>
        <div class="flex justify-start space-x-4 rtl:space-x-reverse mb-4">
            <div class="w-44 relative box-shadow bg-white rounded-lg p-4">
                <img (click)="openPreview('assets/images/ScreenShot_Invoice_3.PNG')" class="border border-gray-400 cursor-pointer" src="assets/images/ScreenShot_Invoice_3.PNG" alt="invoice-1">
                <label class="inline-flex items-center space-x-2 rtl:space-x-reverse mt-2">
                    <input [formControl]='selectedTemplate' type="radio" name="template" value="contemporary">
                    <span class="Gilroy-SemiBold">{{'Contemporary' | translate }}</span>
                </label>
            </div>
            <div class="w-44 relative box-shadow bg-white rounded-lg p-4">
                <img (click)="openPreview('assets/images/ScreenShot_Invoice_2.PNG')" class="border border-gray-400 cursor-pointer" src="assets/images/ScreenShot_Invoice_2.PNG" alt="invoice-1">
                <label class="inline-flex items-center space-x-2 rtl:space-x-reverse mt-2">
                    <input [formControl]='selectedTemplate' type="radio" name="template" value="classic">
                    <span class="Gilroy-SemiBold">{{'Classic' | translate }}</span>
                </label>
            </div>
            <div class="w-44 relative box-shadow rounded-lg  bg-white p-4">
                <img (click)="openPreview('assets/images/ScreenShot_Invoice_1.PNG')" class="border border-gray-400 cursor-pointer" src="assets/images/ScreenShot_Invoice_1.PNG" alt="invoice-1">
                <label class="inline-flex items-center space-x-2 rtl:space-x-reverse mt-2">
                    <input [formControl]='selectedTemplate' type="radio" name="template" value="modern">
                    <span class="Gilroy-SemiBold">{{'Modern' | translate }}</span>
                </label>
            </div>
        </div>
        <app-button buttonText='{{"Save changes" | translate }}' rounded='true' type='primary' (handleClick)='saveTemplate()'></app-button>
    </div>
</div>

<app-modal-container [isOpen]='openPreviewModal'>
    <div header class="Gilroy-Bold border-b pb-2">{{'Preview of Template' | translate }}</div>
    <div content class="flex justify-center">
        <img width="350" class="border" [src]="selectedPreview" alt="invoicePreview">
    </div>
    <div footer>
        <app-button buttonText='{{"Close" | translate }}' rounded='true' type='third' (handleClick)='openPreviewModal = false'></app-button>
    </div>
</app-modal-container>

<app-image-cropper 
    [isOpen]='openModal'
    [imageCropperConfig]='imageCropperConfig'
    (closeImage)='openModal=false'
    (base64Image)='saveImage($event)'>>
</app-image-cropper>

<app-modal-container [isOpen]='openDeleteModal'>
    <div header class="border-b pb-2 Gilroy-Bold">{{'Remove Profile Image' | translate }} </div>
    <div content>{{'Are you sure?' | translate }}</div>
    <div footer class="flex items-center space-x-2 rtl:space-x-reverse">
        <app-button buttonText='{{"Delete" | translate }}' type='danger' rounded=true (handleClick)='removeLogo()'></app-button>
        <app-button buttonText='{{"Cancel" | translate }}' type='secondary' rounded=true (handleClick)='openDeleteModal = false'></app-button>
    </div>
</app-modal-container>
