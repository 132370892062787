import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UserService } from 'src/app/shared/services/user.service';
import { RootReducerState } from 'src/app/store/reducers';
import { selectUser, selectUserBusinesses } from 'src/app/store/selectors/user.selectors';

@Component({
  selector: 'app-businesses-management',
  templateUrl: './businesses-management.component.html',
  styleUrls: ['./businesses-management.component.scss']
})
export class BusinessesManagementComponent implements OnInit, OnDestroy {

  constructor(private store: Store<RootReducerState>,
              private router: Router,
            private userService : UserService) {
    this.businesses$ = this.store.pipe(select(selectUserBusinesses));
    this.user$ = this.store.pipe(select(selectUser));
  }

  businesses$: Observable <any>;
  user$: Observable <any>;
  unsubscribe$ = new Subject();
  userId = '';
  businessCount;
  remainingUserTransaction:any = localStorage.getItem('remainingUserTransaction') ?? 0;
  ngOnInit(): void {
    this.user$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((user) => {
      this.userId = user._id;
    });
    this.businesses$.pipe(takeUntil(this.unsubscribe$))
    .subscribe((business) => {
      if(business) {
        this.businessCount = business;
        this.loadRemainingTransaction(business[0].businessId._id);
      }
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
  loadRemainingTransaction(businessId:any) {
    this.userService.remainingUserTransactions(businessId).subscribe({
      next : (response)=>{
        console.log(">>>>>>><<<<<<<<<<<<<",response)
        localStorage.setItem('remainingUserTransaction',response.data)
        this.remainingUserTransaction = response.data ?? 0;
      },
      error : (error)=>{
        console.log(error)
      }
    })

  }
  editBusiness(id): void {
    this.router.navigate(['/settings/businesses'], { queryParams: { id } });
  }

  route(): void {
    this.router.navigate(['/settings/businesses'], { queryParams: { showForm: true }});
  }

}
