
<div class="flex justify-center p-4">
    <div class="w-full flex flex-col space-y-4">
        <div>
            <div class="flex items-center justify-between">
                <div class="">
                    <h1 class="text-2xl Gilroy-Bold inline-flex items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 me-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z" />
                          </svg>
                        {{'Bills' | translate}}
                    </h1>
                </div>
                <div class="">
                    <app-button appSubscriptionCheck featureToCheck='transactionLimit' appAccessControl moduleType='purchase' accessType='edit' routerLink='create-bill' type='primary' size='large' buttonText='{{"Create a Bill" | translate}}'></app-button>
                </div>
            </div>

            <div class="flex space-x-2 rtl:space-x-reverse mt-5" [formGroup]='filterForm'>
                <div class="">
                    <label class="Gilroy-Bold">{{'Select Vendor' | translate}}</label>
                    <ng-select [items]="vendors"
                    class="custom-selector" 
                    bindLabel="vendorName" 
                    bindValue="_id"
                    formControlName='vendorId'
                    placeholder='{{"Type Vendor Name" | translate}}'>
                    </ng-select>
                </div>
                <div class="self-end">
                    <label class="Gilroy-Bold">{{'Select Projects' | translate}}</label>
                    <ng-select [items]="projects"
                    class="custom-selector" 
                    bindLabel="projectName" 
                    bindValue="_id"
                    formControlName='projectId'
                    placeholder='{{"Type Project Name" | translate}}'>
                    </ng-select>
                </div>
                <div class="self-end">
                    <label class="Gilroy-Bold" for="startDate">{{'From' | translate}}</label>
                    <input formControlName='fromDate' type="date" class="w-full bg-input rounded-lg border-0 h-11"/>
                    <div *ngIf='formErrors.fromDate' class="text-red-500 ms-4">{{formErrors.fromDate}}</div>
                </div>
                <div class="self-end">
                    <label class="Gilroy-Bold" for="endDate">{{'To' | translate}}</label>
                    <input formControlName='endDate' type="date" class="w-full bg-input rounded-lg border-0 h-11"/>
                    <div *ngIf='formErrors.endDate' class="text-red-500 ms-4">{{formErrors.endDate}}</div>
                </div>
                <div class="self-end">
                    <app-button buttonText='{{"Filter Bills" | translate}}' size='large' type='primary' (handleClick)='filterData()'></app-button>
                </div>
            </div>
            <app-table-container [tableHeadings]='tableHeadings' [tableData]='tableData' [keys]='tableKeys' [actionTemplate]='actionTemplate'>
                <ng-template let-item #actionTemplate>
                    <td align="right" class="p-2 last:pe-10 text-center">
                        <app-dropdown #dropdown>
                            <div button (click)='dropdown.open = true' (clickOutside)='dropdown.open = false'>
                                <div class="h-8 w-8 rounded-full flex justify-center items-center bg-gray-50 border border-gray-200">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 m-auto leading-8 text-center" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                                    </svg>
                                </div>
                            </div>
                            <div appAccessControl moduleType='purchase' accessType='edit' options>
                                <span (click)='viewBill(item._id)' class="cursor-pointer block px-4 py-2 text-sm Gilroy-Bold hover:bg-gray-50 text-center rounded-t-xl">{{'View' | translate}}</span>
                                <span (click)='editBill(item._id)' class="cursor-pointer block px-4 py-2 text-sm Gilroy-Bold hover:bg-gray-50 text-center rounded-t-xl">{{'Edit' | translate}}</span>
                                <!-- <span (click)='deleteBill(item._id)' class="cursor-pointer block px-4 py-2 text-sm Gilroy-Bold hover:bg-gray-50 text-center rounded-b-xl">{{'Delete' | translate}}</span> -->
                                <span appSubscriptionCheck featureToCheck='transactionLimit' (click)='openRecordPayment(item)' class="cursor-pointer block px-4 py-2 text-sm Gilroy-Bold hover:bg-gray-50 text-center rounded-t-xl">{{'Record Payment' | translate}}</span>
                            </div>
                        </app-dropdown>             
                    </td>
                </ng-template>
            </app-table-container>
        </div>
    </div>
</div>

<app-modal-container [isOpen]='deleteOpenModal'>
    <div header class="border-b pb-2 Gilroy-Bold">{{'Delete Bills' | translate}}</div>
    <div content>{{'Are you sure?' | translate}}</div>
    <div footer class="flex items-center space-x-2">
        <app-button buttonText='{{"Delete" | translate}}' type='danger' rounded=true (handleClick)='deleteBillslist()' ></app-button>
        <app-button buttonText='{{"Cancel" | translate}}' type='third' rounded=true (handleClick)='deleteOpenModal = false'></app-button>
    </div>
</app-modal-container>

<record-payment
   type = 'bill'
  [isOpen]='recordPaymentModal'
  [amountDue]='amountDue'
  [minDate]='minDate'
  (closeModal)='recordPaymentModal=false'
  (paymentData)='recordPayment($event)'></record-payment>
