import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { InvoiceCustomizationService } from '../../invoice-customization.service';

@Component({
  selector: 'app-estimate-settings',
  templateUrl: './estimate-settings.component.html',
  styleUrls: ['./estimate-settings.component.scss']
})
export class EstimateSettingsComponent implements OnInit {

  constructor(private invoiceCustomizationService: InvoiceCustomizationService,
              private spinner: NgxSpinnerService,
              private fb: FormBuilder) { }

  estimateSettingsForm: FormGroup;
  error = '';
  response = '';

  ngOnInit(): void {
    this.loadForm();
    this.invoiceCustomizationService.invoiceCustomizationSettings$.subscribe((settings) => {
      if (settings?.estimateSettings) {
        this.loadForm(settings?.estimateSettings);
      } else {
        this.loadForm();
      }
    });
  }

  loadForm(settings?): void {
    this.estimateSettingsForm = this.fb.group({
      paymentTerms: [settings?.paymentTerms ? settings.paymentTerms : ''],
      title: [settings?.title ? settings.title : ''],
      scheduling: [settings?.scheduling ? settings.scheduling : ''],
      footer: [settings?.footer ? settings.footer : ''],
      memo: [settings?.footer ? settings.memo : ''],
    });
  }

  saveChanges(): void {
    this.spinner.show();
    this.invoiceCustomizationService.saveInvoiceCustomizationSettings({estimateSettings: this.estimateSettingsForm.value})
    .subscribe((resp) => {
      this.spinner.hide();
      this.response = 'Changes Saved';
      setTimeout(() => this.response = '', 2000);
      console.log(resp);
    }, (error) => {
      this.spinner.hide();
      this.error = error?.error?.message || 'Internal server error';
    });
  }

}
