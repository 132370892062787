<!-- <div class="min-h-screen">
    <div class="p-4 space-y-6">
        <div class="flex justify-between items-start">
            <div class="1/3 flex flex-col items-start">
                <h1 class="Gilroy-Bold text-2xl">{{'My Files' | translate}}</h1>
                <button (click)="openFileModal()" class="bg-black mt-4 text-white Gilroy-Bold px-6 py-3 rounded-2xl">{{'Upload File' | translate}}</button>
            </div>
            <div class="flex flex-col">
                <svg routerLink="/files/shared" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" data-slot="icon" class="w-36 h-16 cursor-pointer outline-white">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 12.75V12A2.25 2.25 0 0 1 4.5 9.75h15A2.25 2.25 0 0 1 21.75 12v.75m-8.69-6.44-2.12-2.12a1.5 1.5 0 0 0-1.061-.44H4.5A2.25 2.25 0 0 0 2.25 6v12a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18V9a2.25 2.25 0 0 0-2.25-2.25h-5.379a1.5 1.5 0 0 1-1.06-.44Z" />
                </svg>
                <p class="text-sm Gilroy-SemiBold text-center">Shared with me</p>
            </div>
        </div> -->
        <div class="mt-4">
            <button (click)="openFileModal()" class="bg-gradient mb-4 text-white Gilroy-Bold px-6 py-3 rounded-2xl">{{'Upload File' | translate}}</button>
            <app-table-container
                [tableHeadings]='tableHeadings'
                [tableData]='allFiles'
                [keys]='tableKeys'
                [actionTemplate]='actionTemplate'
                class="task-grid-table">
                <ng-template let-item #actionTemplate>
                    <td class="p-2"><p>{{item.createdAt | date}}</p></td>
                    <td class="p-2 ltr:pr-10 rtl:pl-10">
                        <svg (click)='viewOrDownloadFile(item.files[0]?._id)' xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-6 cursor-pointer">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75l3 3m0 0l3-3m-3 3v-7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                    </td>
                    <td class="p-2 ltr:pr-8 rtl:pl-8">
                        <svg (click)="shareFile(item._id)" xmlns="http://www.w3.org/2000/svg" class="cursor-pointer"  viewBox="0 0 24 24" height="24px"><path d="M 15.990234 1.9902344 A 1.0001 1.0001 0 0 0 15.292969 3.7070312 L 17.585938 6 L 17 6 C 10.936593 6 6 10.936593 6 17 A 1.0001 1.0001 0 1 0 8 17 C 8 12.017407 12.017407 8 17 8 L 17.585938 8 L 15.292969 10.292969 A 1.0001 1.0001 0 1 0 16.707031 11.707031 L 20.707031 7.7070312 A 1.0001 1.0001 0 0 0 20.707031 6.2929688 L 16.707031 2.2929688 A 1.0001 1.0001 0 0 0 15.990234 1.9902344 z M 2.984375 7.9863281 A 1.0001 1.0001 0 0 0 2 9 L 2 19 C 2 20.64497 3.3550302 22 5 22 L 19 22 C 20.64497 22 22 20.64497 22 19 L 22 18 A 1.0001 1.0001 0 1 0 20 18 L 20 19 C 20 19.56503 19.56503 20 19 20 L 5 20 C 4.4349698 20 4 19.56503 4 19 L 4 9 A 1.0001 1.0001 0 0 0 2.984375 7.9863281 z"/></svg>                   
                    </td>
                    <td class="p-2 ltr:pr-8 rtl:pl-8">
                        <svg (click)="viewFileInNewTab(item.files[0]?._id)" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 cursor-pointer">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z" />
                            <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                        </svg>                          
                    </td>
                    <td class="p-2 ltr:pl-12 rtl:pr-12">
                        <svg (click)="deleteModal(item)" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" data-slot="icon" class="w-6 h-6 cursor-pointer">
                            <path stroke-linecap="round" stroke-linejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                        </svg>                     
                    </td>
                </ng-template>
            </app-table-container>
        </div>
    <!-- </div>
</div> -->


<app-modal-container [isOpen]="isFileModalOpen">
    <div header class="flex justify-between items-center">
        <h1 class="Gilroy-Bold text-2xl">{{"Add a file" | translate}}</h1>
        <svg (click)="closeFileModal()" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 cursor-pointer">
            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
        </svg> 
    </div>
    <div content >
        <div class="space-y-3 border-b pb-8">
            <div class="flex flex-col space-y-1">
                <input type="file" [(ngModel)]="fileUploaded" (change)="onFileSelect($event)" class="bg-transparent w-full outline-none border rounded-2xl px-8 py-3"/>
                <span class="text-red-500 text-sm" *ngIf="fileError">{{"File is required" | translate}}</span>
            </div>
            <!-- <label for="file">
                <div  class="fileDropZone border-2 border-dashed rounded-2xl cursor-pointer h-[150px] flex flex-col items-center justify-center space-y-1"  ngFileDragDrop (uploadedFiles)="getUploadedFiles($event)" (fileDropped)="getUploadedFiles($event)">
                    <div class="flex space-x-2 items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M18.375 12.739l-7.693 7.693a4.5 4.5 0 01-6.364-6.364l10.94-10.94A3 3 0 1119.5 7.372L8.552 18.32m.009-.01l-.01.01m5.699-9.941l-7.81 7.81a1.5 1.5 0 002.112 2.13" />
                        </svg>
                    </div>
                    <p class="Gilroy-Medium text-gray-500">Click or drag file to this area to upload from local drive.</p>
                    <input #fileInput id="file" class="hidden" (change)="onFileSelect($event)"  type="file"  multiple>
                </div>
            </label> -->
        </div>
    </div>
    <div footer>
        <button (click)="uploadFiles()" class="bg-gradient text-white Gilroy-Bold px-6 py-3 rounded-2xl">{{"Upload" | translate}}</button>
    </div>
</app-modal-container>


<app-modal-container [isOpen]="isShareModalOpen">
    <div header>
        <ng-container *ngIf="isRemoveFileAccess else shareFileAbility">
            <div class="flex justify-start gap-2 items-center">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                  stroke="currentColor" class="w-6 h-6 inline cursor-pointer ltr:rotate-0 rtl:rotate-180"
                  (click)="goBackToList()">
                  <path stroke-linecap="round" stroke-linejoin="round"
                    d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75" />
                </svg>
                <h1 class="Gilroy-Bold text-2xl">{{"Remove access" | translate}}</h1>
            </div>
        </ng-container>
        <ng-template #shareFileAbility>
            <div class="flex justify-between items-center">
                <h1 *ngIf="!isRemoveFileAccess" class="Gilroy-Bold text-2xl">
                    {{"Share a file" | translate}}
                </h1>
                <svg *ngIf="!isRemoveFileAccess" (click)="closeShareModal()" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 cursor-pointer">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                </svg>
            </div>
        </ng-template>
    </div>
    <div content >
        <div *ngIf="!isRemoveFileAccess">
            <div class="space-y-3 border-b pb-8 share-file-modal">
                <div class="flex flex-col space-y-1">
                    <ng-select [items]="allBusinessUsers"
                      class="custom-selector"
                      bindLabel="name"
                      bindValue="_id"
                      [(ngModel)]='selectedUser'
                      (change)="userSelected($event)"
                      placeholder="{{'Select Users to Share' | translate}}">
                    </ng-select>
                    <span class="text-red-500 text-sm" *ngIf="selectUserError">{{"Please select a user" | translate}}</span>
                </div>
            </div>
            <div class="pt-4">
                <h4 class="text-base Gilroy-SemiBold pb-4">{{"File is shared with below users" | translate}}</h4>
                <ng-container *ngIf="fileSharedWithUsers?.length > 0; else fileNotShared">
                    <table class="min-w-full divide-y divide-gray-300">
                        <thead>
                            <tr class="h-12 text-start">
                                <th class="Gilroy-Bold">{{'Name' | translate }}</th>
                                <th class="Gilroy-Bold text-end">{{'Action' | translate }}</th>
                            </tr>
                        </thead>
                        <tbody class="divide-y divide-gray-300">
                            <tr *ngFor="let item of fileSharedWithUsers" class="h-12">
                                <td>{{item.userId.firstName + ' ' + item.userId.lastName}}</td>
                                <td class="text-center">
                                    <span class="flex justify-end" (click)="removeAccess(item)">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                                        stroke="currentColor" data-slot="icon" class="w-12 h-6 cursor-pointer">
                                        <path stroke-linecap="round" stroke-linejoin="round"
                                          d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                                      </svg>
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </ng-container>
                <ng-template #fileNotShared>{{"No data available" | translate}}</ng-template>
            </div>
        </div>
        <div *ngIf="isRemoveFileAccess">
            <div>
                <h4 class="text-lg">{{"Are you sure you want to remove access of this file for" | translate}} <span class="Gilroy-SemiBold">{{removeUserDetails.userId.firstName + ' ' + removeUserDetails.userId.lastName}}</span> ?</h4>
            </div>
        </div>
    </div>
    <div footer>
        <button *ngIf="!isRemoveFileAccess" (click)="confirmShare()" class="bg-gradient text-white Gilroy-Bold px-6 py-3 rounded-2xl">{{"Share" | translate}}</button>
        <button *ngIf="isRemoveFileAccess" (click)="confirmRemoveAccess()" class="bg-gradient text-white Gilroy-Bold px-6 py-3 rounded-2xl">{{"Remove" | translate}}</button>
    </div>
</app-modal-container>


<app-modal-container [isOpen]="isDeleteModalOpen">
    <div header class="flex justify-between">
        <h1 class="Gilroy-Bold text-2xl">
            {{"Delete File" | translate}}
        </h1>
        <svg (click)="closeDeleteModal()" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 cursor-pointer">
            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
        </svg>
    </div>
    <div content>
        <h4 class="text-lg">{{"Are you sure you want to delete this file" | translate}} <span class="Gilroy-SemiBold">{{fileToDelete?.fileName}}</span>?</h4>
    </div>
    <div footer>
        <button (click)="confirmDelete()" class="bg-gradient text-white Gilroy-Bold px-6 py-3 rounded-2xl">{{"Delete" | translate}}</button>
    </div>
</app-modal-container>