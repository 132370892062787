<div class="w-full flex flex-col space-y-4 p-4 h-full">
  <div class="enroll-as-expert relative w-full flex flex-col h-full pb-4">
    <div class="wrapper-content ltr:ml-20 rtl:mr-20">
      <img [src]="logo" class="w-[150px] my-10" alt="Logo" />
      <div class="page-content">
        <h1
          class="text-4xl w-[40%] Gilroy-Bold inline-flex text-white items-center"
        >
          {{"Become an expert with Numetric.work" | translate}}
        </h1>
        <p class="text-white text-sm opacity-[0.6] w-[70%] my-5">
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's standard dummy text ever
          since the 1500s, when an unknown printer took a galley of type and
          scrambled it to make a type specimen book. It has survived not only
          five centuries, but also the leap into electronic typesetting.
        </p>
      </div>
      <div class="steps-content my-8">
        <h3 class="text-white text-2xl Gilroy-Bold">
          {{"Become an expert with Numetric in 3 steps" | translate}}
        </h3>
        <div class="steps flex justify-between w-[50%] relative">
          <hr class="steps-line" />
          <div class="steps-inside-content">
            <span class="icons-circle">
              <img [src]="getVerifiedImg" alt="" />
            </span>
            <p class="text-white">{{"Fills the form & submit the documents" | translate}}</p>
          </div>
          <div class="steps-inside-content">
            <span class="icons-circle">
              <img [src]="verifiedUserImg" alt="" />
            </span>
            <p class="text-white">{{"Get Verified" | translate}}</p>
          </div>
          <div class="steps-inside-content">
            <span class="icons-circle">
              <img [src]="formImg" alt="" />
            </span>
            <p class="text-white">{{"Become Expert" | translate}}</p>
          </div>
        </div>
      </div>
      <div class="benefits-content">
        <h3 class="text-white text-2xl Gilroy-Bold">
          {{"Benefits of an Numetric Expert" | translate}}
        </h3>
        <div class="benefits-points ml-4">
          <ul>
            <li>Lorem Ipsum is simply dummy text of the printing.</li>
            <li>Lorem Ipsum is simply dummy text of the printing.</li>
            <li>Lorem Ipsum is simply dummy text of the printing.</li>
            <li>Lorem Ipsum is simply dummy text of the printing.</li>
          </ul>
        </div>
      </div>
      <!-- <app-button type='third' class="text-black font-medium" buttonText="Enroll as an Expert"></app-button> -->
      <button
        class="text-black bg-white rounded-xl text-xs py-3 px-5 mt-4 font-black"
        (click)="enrollAsExpert()"
      >
        {{"Enroll as an Expert" | translate}}
      </button>
      <img
        [src]="groupImg"
        class="group-image ltr:right-28 rtl:left-28"
        alt=""
      />
    </div>
  </div>
</div>
