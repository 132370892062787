<app-modal-container [isOpen]='isOpen'>
  <div  *ngIf='!update' header class="border-b pb-2 Gilroy-ExtraBold text-xl">{{'Add an account' | translate}}</div>
  <div  *ngIf='update' header class="border-b pb-2 Gilroy-ExtraBold text-xl">{{'Update an account' | translate}}</div>
  <div content>
    <form [formGroup]='accountForm'>
      <div class="mt-3 text-center space-y-4 sm:text-left">
        <div class="space-y-2">
          <div class="rounded-xl bg-proile px-3 py-2 space-y-2">
            <label class="block text-sm Gilroy-Bold border-0 font-color-05">{{'Account Type' | translate}}</label>
            <ng-select tabindex="1" [items]="accountTypes"
              class="w-full rounded-xl border-none bg-transparent back-chnage-custom"
              bindLabel="accountType"
              formControlName="accountType"
              placeholder="{{'Select One' | translate}}"
              groupBy="section">
            </ng-select>
            <ng-template ng-optgroup-tmp let-item="item">
              {{item.section || 'Unnamed group'}}
            </ng-template>
          </div>
          <div *ngIf='formErrors.accountType' class="text-sm text-red-400 ms-2">{{formErrors.accountType | translate}}</div>
        </div>
        <div class="space-y-2">
          <div class="rounded-xl bg-proile px-3 py-2 space-y-2">
            <label for='accountName' class="block text-sm Gilroy-Bold font-color-05">{{'Account Name' | translate}}</label>
            <input tabindex="2" id='accountName' formControlName='accountName' type="text" class="w-full p-0 border-0 bg-transparent focus:ring-0 placeholder-gray-400" placeholder="{{'Enter Account Name' | translate}}" />
          </div>
          <div *ngIf='formErrors.accountName' class="text-sm text-red-400 ms-2">{{formErrors.accountName | translate}}</div>
        </div>
        <div class="space-y-2">
          <div class="rounded-xl bg-proile px-3 py-2 space-y-2">
            <label for='accountID' class="block text-sm Gilroy-Bold font-color-05">{{'Account ID' | translate}}</label>
            <input id='accountID' tabindex="4" type="text"  formControlName="accountId" name="accountId" class="w-full p-0 border-0 bg-transparent focus:ring-0 placeholder-gray-400" placeholder="{{'Enter Account ID' | translate}}" />
          </div>
        </div>
        <div class="space-y-2">
          <div class="rounded-xl bg-proile px-3 py-2 space-y-2">
            <label for='description' class="block text-sm Gilroy-Bold font-color-05">{{'Description' | translate}}</label>
            <textarea tabindex="5" id='description' formControlName="description" name="description" class="w-full p-0 border-0 bg-transparent focus:ring-0 placeholder-gray-400" placeholder="{{'Enter Description' | translate}}"></textarea>
          </div>
        </div>
        <div class="space-y-2" *ngIf='update'>
          <div class="rounded-xl bg-proile px-3 py-2 space-y-2">
            <label for="isArchive" class="block text-sm Gilroy-Bold font-color-05">{{'Archive account' | translate}}</label>
            <div class="flex items-center">
              <input type="checkbox" class="me-2" name="isArchive" id="isArchive" formControlName='isArchive'>
              <span>{{'Archive message' | translate}}</span>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div footer class="space-x-2 rtl:space-x-reverse">
    <app-button *ngIf="!update" tabindex="6" class='me-2' buttonText='{{"Save" | translate}}' type='primary' (handleClick)="addAccount()" rounded=true></app-button>
    <app-button *ngIf="update" tabindex="6" class='me-2' buttonText='{{"Update" | translate}}' type='primary' (handleClick)="updateAccount()" rounded=true></app-button>
    <app-button tabindex="7" buttonText='{{"Cancel" | translate}}' type='third' (handleClick)='closeForm()' rounded=true></app-button>
  </div>
</app-modal-container>