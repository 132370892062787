import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RecurringJvService } from '../../recurring-jv.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { FormControl, Validators } from '@angular/forms';
import * as moment from 'moment';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DateValidator } from 'src/app/shared/utils/formValidator';
import { NumberService } from 'src/app/shared/services/number.service';
import { Store, select } from '@ngrx/store';
import { RootReducerState } from 'src/app/store/reducers';
import { selectUser } from 'src/app/store/selectors/user.selectors';
import { selectBusiness } from 'src/app/store/selectors/business.selector';
import { LanguageService } from 'src/app/shared/services/language.service';
import { CurrencyPipe } from '@angular/common';
import { AccountingService } from 'src/app/modules/accounting/accounting.service';
import { FileUploadService } from 'src/app/shared/services/file-upload.service';

@Component({
  selector: 'app-view-recurring-jv',
  templateUrl: './view-recurring-jv.component.html',
  styleUrls: ['./view-recurring-jv.component.scss']
})
export class ViewRecurringJvComponent implements OnInit {

  unsubscribe$ = new Subject();
  user$: Observable<any>;
  business$: Observable<any>;
  businessId;
  debitTotal = 0;
  creditTotal = 0;
  difference = 0;
  number = '.2-2';
  currencyDetails;
  issuedBy = '';
  jvDetails;
  creatdJV = [];
  files: File[] = [];
  addedFiles = [];
  jvId: string = '';
  minDate = moment().format('YYYY-MM-DD')
  showExtendModal: boolean = false;
  showCancelModal: boolean = false;
  updatedDate = new FormControl(moment().format('YYYY-MM-DD'), [Validators.required, DateValidator()]);
  fieldNames = {
    transaction: 'Transaction',
    category: 'Category',
    transactionNumber: 'Transaction ID',
    date: 'Date',
    serialNumber: 'Serial ID',
    total: 'Total',
    amount: 'Amount',
    type: 'Type',
    description: 'Description',
    account: 'Account',
    debit: 'Debit',
    credit: 'Credit',
    issuer: 'Issued By'
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private jvService: RecurringJvService,
    private numberService: NumberService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private store: Store<RootReducerState>,
    private languageService: LanguageService,
    private currencyPipe: CurrencyPipe,
    private accountingService: AccountingService,
    private fileUploadService: FileUploadService
  ) {
    this.user$ = store.pipe(select(selectUser));
    this.business$ = store.pipe(select(selectBusiness));
   }

  ngOnInit(): void {
    this.route.queryParams.pipe(takeUntil(this.unsubscribe$))
    .subscribe(params => {
      if(params?.id){
        this.jvId = params?.id;
        this.loadJvDetails();
        this.loadCurrencyDetails();
        this.loadUser();
        this.loadNumberConfig();
      }
    })
  }

  loadNumberConfig(): void {
    this.numberService.number
    .pipe((takeUntil(this.unsubscribe$)))
    .subscribe((number) => {
      this.number = number
    })
  }

  loadUser(): void {
    this.user$.subscribe((user) => {
      if (user) {
        this.issuedBy = `${user.firstName} ${user.lastName}`;
      }
    });
  }

  loadCurrencyDetails(): void {
    this.business$
    .pipe(
      takeUntil(this.unsubscribe$)
    )
    .subscribe(business => {
      if(business?.businessId?._id){
        this.businessId = business?.businessId?._id;
        this.currencyDetails = (({ currency, currencySymbol }) => ({ currency, currencySymbol }))(business?.businessId);
      }
    })
  }

  loadJvDetails(): void {
    this.spinner.show();
    this.jvService.getRecurringJV(this.jvId)
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(resp => {
      if(resp?.success){
        this.jvDetails = resp?.data;
        resp?.data?.journal?.forEach(journal => {
          if(journal?.type === 'Debit')
            this.debitTotal += journal?.debit;
          else
            this.creditTotal += journal?.credit;
        })
        this.addedFiles = resp?.data?.files ?? [];
        this.difference = this.debitTotal 
        this.creatdJV = resp?.recurringJournal;
        this.updatedDate.setValue(moment(this.jvDetails?.startDate).isAfter(moment()) ? moment(this.jvDetails.startDate).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'))
        this.minDate = moment(this.jvDetails?.startDate).isAfter(moment()) ? moment(this.jvDetails.startDate).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD');
      }
      else{
        this.toastr.error(resp?.message);
        this.router.navigate(['accounting/recurring-jv']);
      }
      this.spinner.hide();
    }, (err) => {
      this.toastr.error(err?.error?.message ?? 'Something went wrong');
      this.spinner.hide();
    })
  }

  updateEndDate(): void {
    if(this.updatedDate.invalid){
      this.updatedDate.markAsTouched();
      return;
    }
    this.spinner.show();
    this.jvService.updateRecurringJV({endDate:moment(this.updatedDate.value).format('YYYY-MM-DD')}, this.jvId)
    .pipe(
      takeUntil(this.unsubscribe$)
    )
    .subscribe(resp => {
      if(resp?.success){
        this.toastr.success(resp?.message);
        this.jvDetails.endDate = moment(this.updatedDate.value).format('YYYY-MM-DD');
        this.jvDetails.isEnded = resp?.data?.isEnded;
        this.showExtendModal = false;
      }
      else{
        this.toastr.error(resp?.message);
      }
      this.spinner.hide();
    }, (err) => {
      this.toastr.error(err?.error?.message);
      this.spinner.hide();
    })
  }

  stopRecurringJV(): void {
    this.spinner.show();
    this.jvService.cancelRecurringJV(this.jvId)
    .pipe(
      takeUntil(this.unsubscribe$)
    )
    .subscribe(resp => {
      if(resp?.success){
        this.toastr.success(resp?.message);
        this.jvDetails.isCanceled = true;
        this.showCancelModal = false;
      }
      else{
        this.toastr.error(resp?.message);
      }
      this.spinner.hide();
    }, (err) => {
      this.toastr.error(err?.error?.message);
      this.spinner.hide();
    })
  }

  downloadJV(transaction) {
    this.spinner.show();
    const body = {
      transactionId: transaction._id, 
      config: {
        direction: localStorage.getItem('NuMetric|lang') === 'en'? 'ltr': 'rtl',
        fieldNames: this.languageService.translate(this.fieldNames),
        data: {
          journal: transaction.journal.map((el) => {
            return {
              ...el, 
              credit: this.currencier(el.credit ?? 0),
              debit: this.currencier(el.debit ?? 0)
            }
          })
        },
        amount: this.currencier(transaction.amount)
      }
    }

    if (transaction.category === 'Credit Note' || transaction.category === 'Debit Note') {
      body.config.data.journal.push({
        accountName: transaction.creditFrom.accountName,
        type: 'Credit',
        credit: this.currencier(transaction.amount),
        debit: this.currencier(0),
        description: '-'
      })
      body.config.data.journal.push({
        accountName: transaction.debitTo.accountName,
        type: 'Debit',
        debit: this.currencier(transaction.amount),
        credit: this.currencier(0),
        description: '-'
      })
    }
    this.accountingService.downloadPdf(body).subscribe((resp) => {
      this.spinner.hide();
      const a = document.createElement('a');
      const blob = new Blob([resp], {type: 'application/pdf'});
      const url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = `Transaction.pdf`;
      a.click();
      window.URL.revokeObjectURL(url);
      // this.toaster.success('PDF downloaded');
    }, (error) => {
      this.spinner.hide();
    })
  }

  uploadFiles(): void {
    this.fileUploadService.emitFiles.next(true);
    this.fileUploadService.emitFiles.next(false);
    if(!this.files.length){
      this.toastr.error('Select files to upload');
      return;
    }
    this.spinner.show();
    const body = {
      businessId: this.businessId,
      referenceId: this.jvDetails?._id,
      documentType: 'Journal Voucher',
      parentDocType: 'Accounting'
    }
    const formData = new FormData();
    // this.files.forEach((file, i) => {
    //   formData.append(`file${i}`, file);
    // })
    formData.append('payload', JSON.stringify(body));
    this.fileUploadService.uploadFileForDocument(formData)
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(resp => {
      if(resp?.success){
        console.log(resp?.data, 'view recurring jv');
        this.addedFiles = resp?.data?.files ?? [];
        this.toastr.success(resp?.message);
      }
      else{
        this.toastr.error(resp?.message)
      }
      this.spinner.hide();
    }, (err) => {
      this.toastr.error(err?.error?.message);
      this.spinner.hide();
    })
  }

  currencier(amount): string {
    return this.currencyPipe.transform(amount, this.currencyDetails.currency, 'symbol-narrow', this.number);
  }

  saveFiles(files: File[]): void {
    this.files = files;
  }

}
