import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { valueChanges } from 'src/app/shared/utils/formValidator';
import { RootReducerState } from 'src/app/store/reducers';
import { AuthService } from '../../auth.service';
import SlimSelect from 'slim-select';
import * as cc from 'currency-codes';
import { selectUser } from 'src/app/store/selectors/user.selectors';
import { Router } from '@angular/router';
import { UserService } from 'src/app/shared/services/user.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { getAllMonths, getDates } from 'src/app/shared/utils/monthDateData';
import { getAllCountries } from 'src/app/shared/utils/countryStateData';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-personal-details',
  templateUrl: './personal-details.component.html',
  styleUrls: ['./personal-details.component.scss']
})
export class PersonalDetailsComponent implements OnInit {

  constructor(private fb: FormBuilder,
              private authService: AuthService,
              private store: Store<RootReducerState>,
              private userService: UserService,
              private router: Router,
              private spinner: NgxSpinnerService,
              private translateService: TranslateService
              ) {
    this.user$ = this.store.pipe(select(selectUser));
  }

  user$: Observable<any>;

  personalDetailForm: FormGroup;
  error = '';
  show = false;
  formErrors = {
    firstName: '',
    lastName: '',
    businessName: '',
    businessIndustry: '',
    businessCurrency: '',
    businessOrganisationType: '',
    country: '',
    month: '',
    date: ''
   };
  formErrorMessages = {
    firstName: {
      required: 'First Name is Required',
    },
    lastName: {
      required: 'Last Name is Required',
    },
    businessName: {
      required: 'Business Name is Required',
    },
    businessIndustry: {
      required: 'Business Industry is Required',
    },
    businessCurrency: {
      required: 'Business Currency is Required',
    },
    businessOrganisationType: {
      required: 'Organisation Type is Required',
    },
    country: {
      required: 'Business Country is Reqired'
    },
    month: {
      required: 'Month is Required',
    },
    date: {
      required: 'Date is Required',
    }
  };

  industrySelect;
  currenySelect;
  organisationSelect;
  countrySelect;
  dateSelect;
  monthSelect;

  industries = [
    {text: 'Business services'},
    {text: 'Accounting'},
    {text: 'Advisory'},
    {text: 'Taxation'},
    {text: 'Advertising'},
    {text: 'Engineering'},
    {text: 'Legal'},
    {text: 'Research'},
    {text: 'Agencies'},
    {text: 'Computer'},
    {text: 'Programming'},
    {text: 'Technology'},
  ];
  
  organisations = [
    {text: 'Sole'},
    {text: 'Partnership'},
    {text: 'Corporation'},
    {text: 'Other'}
  ];
  currencyCodes = []
  allDaysOfMonth:any;
  allMonths = getAllMonths();
  allCountries = getAllCountries();
  ngOnInit(): void {
    this.loadForm();
    this.loadSelectData();
    this.user$.subscribe((user) => {
      if (!user.token) {
      let businessId = localStorage.getItem('selectedBusiness');
        this.userService.getUserDetails(businessId).subscribe((userResp) => {
          this.userService.onGetUserDetailsSuccess(userResp.data);
        }, (error) => this.userService.logout());
      }
      if (user.token && user.businesses.length > 0) {
        this.router.navigate(['/settings/profile']);
      }
    });
  }

  loadForm(): void {
    this.personalDetailForm = this.fb.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      businessName: ['', [Validators.required]],
      businessIndustry: [null, Validators.required],
      businessCurrency: ['', Validators.required],
      businessOrganisationType: ['', Validators.required],
      country: ['', [Validators.required]],
      month: ['', Validators.required],
      date: ['', Validators.required],
    });
    this.personalDetailForm.valueChanges.subscribe((data) => {
      this.formErrors = valueChanges(this.personalDetailForm, {...this.formErrors}, this.formErrorMessages, this.translateService);
    });
    this.formErrors = valueChanges(this.personalDetailForm, {...this.formErrors}, this.formErrorMessages, this.translateService);
  }
  sendPersonalDetails(): void{
    this.error = '';
    if (this.personalDetailForm.invalid) {
      this.personalDetailForm.markAllAsTouched();
      this.formErrors = valueChanges(this.personalDetailForm, {...this.formErrors}, this.formErrorMessages, this.translateService);
    } else {
      const {
        firstName,
        lastName,
        businessName,
        businessIndustry,
        businessCurrency,
        businessOrganisationType,
        country,
        month,
        date } = this.personalDetailForm.value;
      const body = {
        personalDetails: { firstName, lastName },
        businessDetails: {
          businessName,
          businessType: businessIndustry,
          currency: businessCurrency,
          organization: businessOrganisationType,
          country,
          month,
          date
        }
      };
      this.spinner.show();
      this.authService.addPersonDetails(body).subscribe(
        (resp) => {
          this.spinner.hide();
          this.authService.onAddPersonalDetailsSuccess(resp);
        },
        (error) => {
          this.error = error.error?.message || 'Internal Server Error';
          this.spinner.hide();
        }
      );
    }
  }
  loadSelectData(): void {
    this.currencyCodes = cc.codes().map((code) => ({text : code}));
  }
  onMonthChange(event): void {
    this.personalDetailForm.controls.date.patchValue(getDates(event.target.value)[0].text);
    const data = getDates(event.target.value).map(date => {
      if (date === 1) {
        return { ...date, selected: true };
      }
      return date;
    });
    console.log("event", data);
    this.allDaysOfMonth = data;
        // this.dateSelect.setData([...data]);
  }

}
