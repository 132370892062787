<div class="flex min-h-screen">
  <div class="w-full h-screen">
    <div class="w-full flex flex-col space-y-4">
      <!-- header start  -->
      <div class="flex items-center ps-5 pt-4 w-full mx-auto fixed top-0 z-10 bg-white ml-2">
        <div class="">
          <h1 class="text-2xl Gilroy-Bold inline-flex items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6 me-2"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"
              />
            </svg>
            {{ "P/L Analysis" | translate }}
          </h1>
        </div>
        <div class="flex gap-2 ml-[169px]" [ngClass]="{'yearmargin':selectedType==='monthright'}">
          <div class="w-36 z-20">
            <ng-select
              [items]="typeOfData"
              bindLabel="name"
              [searchable]="false"
              placeholder="Select type Of Data"
              [clearable]="false"
              bindValue="value"
              [(ngModel)]="selectedType"
              (change)="dataChange()"
            ></ng-select>
          </div>
          <ng-template [ngIf]="monthsData || monthrightData">
            <div class="w-36 z-20">
              <ng-select
                [items]="monthsName"
                bindLabel="name"
                [searchable]="false"
                bindValue="value"
                placeholder="Months"
                [clearable]="false"
                [(ngModel)]="selectedMonth"
                (change)="dataChange()"
              ></ng-select>
            </div>
          </ng-template>

          <div class="w-36 z-20" *ngIf="yearlyData || monthsData">
            <ng-select
              [items]="years"
              bindLabel="name"
              [searchable]="false"
              [clearable]="false"
              placeholder="Data type"
              bindValue="value"
              [(ngModel)]="selectedYear"
              (change)="dataChange()"
            ></ng-select>
          </div>
          <div class="w-36 z-20" *ngIf="yearlyData">
            <ng-select
              [items]="preference"
              bindLabel="name"
              [searchable]="false"
              [clearable]="false"
              placeholder="Preference"
              bindValue="value"
              [(ngModel)]="preferenceType"
              (change)="preferenceChange()"
            ></ng-select>
          </div>

          <ng-template [ngIf]="monthsData">
            <div class="">
              <button
                (click)="pdfReport()"
                class="bg-gradient text-white py-2 px-1 Gilroy-Bold rounded-lg w-28"
              >
                {{ "Export PDF" | translate }}
              </button>
            </div>
            <div class="">
              <button
                (click)="excelReport()"
                class="bg-gradient text-white py-2 px-1 Gilroy-Bold rounded-lg w-28"
              >
                {{ "Export Excel" | translate }}
              </button>
            </div>
          </ng-template>
          <ng-template [ngIf]="yearlyData">
            <div class="" *ngIf="preferenceData">
              <button
                (click)="pdfReportYear()"
                class="bg-gradient text-white py-2 px-1 Gilroy-Bold rounded-lg w-28"
              >
                {{ "Export PDF" | translate }}
              </button>
            </div>
            <div class="" *ngIf="!preferenceData">
              <button
                (click)="pdfReportYearMonthly()"
                class="bg-gradient text-white py-2 px-1 Gilroy-Bold rounded-lg w-28"
              >
                {{ "Export PDF" | translate }}
              </button>
            </div>
            <div class="">
              <button
                (click)="excelReportYear()"
                class="bg-gradient text-white py-2 px-1 Gilroy-Bold rounded-lg w-28"
              >
                {{ "Export Excel" | translate }}
              </button>
            </div>
          </ng-template>
          <ng-template [ngIf]="monthrightData">
            <div class="">
              <button
                (click)="pdfReportMonthRight()"
                class="bg-gradient text-white py-2 px-1 Gilroy-Bold rounded-lg w-28"
              >
                {{ "Export PDF" | translate }}
              </button>
            </div>
            <div class="">
              <button
                (click)="excelReportMonthRight()"
                class="bg-gradient text-white py-2 px-1 Gilroy-Bold rounded-lg w-28"
              >
                {{ "Export Excel" | translate }}
              </button>
            </div>
          </ng-template>
        </div>
      </div>
      <!-- header End -->

      <!-- Grid options start -->
      <div
        *ngIf="monthsData || monthrightData"
        class="grid grid-cols-3 mt-5 gap-6 ps-5 pe-7 max-w-5xl sticky left-0 justify-between"
        style="margin-top: 72px"
      >
        <div
          class="bg-gradient rounded-xl px-10 pt-7 pb-7 flex gap-4 justify-start z-10"
        >
          <div class="">
            <p class="Gilroy-bold text-base font-bold text-white">
              {{ "Profit" | translate }}
            </p>
            <h3 class="text-2xl font-bold text-white">{{ profitMonth }}</h3>
          </div>
          <div class="">
            <p class="text-base font-bold text-white Gilroy-bold">
              {{ "YoY%" }}
            </p>
            <h3 class="text-2xl font-bold text-white">
              {{ growthMonth ?? 0 }}%
            </h3>
          </div>
        </div>

        <!-- <div class="bg-gradient rounded-xl px-10 pt-7 pb-7 flex justify-start z-10">
          <div class="">
            <p class="Gilroy-bold text-base font-bold text-white">{{'Target' | translate}}</p>
            <h3 class="text-2xl font-bold text-white">{{targetMonth}}</h3>
          </div>
        </div> -->

        <!-- <div class="bg-gradient rounded-xl px-10 pt-7 pb-7 flex gap-4 justify-start z-10">
          <div class="">
            <p class="Gilroy-bold text-base font-bold text-white">{{'Target Achievement' | translate}}</p>
            <h3 class="text-2xl font-bold text-white">{{totalIncomeMonth}}</h3>
          </div>
        </div> -->
      </div>
      <!-- Grid option end  -->

      <!-- Year Grid -->
      <div
        class="grid grid-cols-3 mt-5 gap-6 ps-5 pe-7 max-w-5xl sticky left-0 justify-between"
        style="margin-top: 72px"
        *ngIf="yearlyData"
      >
        <div
          class="bg-gradient rounded-xl px-10 pt-7 pb-7 flex gap-4 justify-start z-10"
        >
          <div class="">
            <p class="Gilroy-bold text-base font-bold text-white">
              {{ "Profit Year" | translate }}
            </p>
            <h3 class="text-2xl font-bold text-white">{{ profitYear }}</h3>
          </div>
          <div class="">
            <p class="text-base font-bold text-white Gilroy-bold">
              {{ "YoY%" }}
            </p>
            <h3 class="text-2xl font-bold text-white">
              {{ growthYear ?? 0 }}%
            </h3>
          </div>
        </div>

        <!-- <div class="bg-gradient rounded-xl px-10 pt-7 pb-7 flex justify-start z-10">
          <div class="">
            <p class="Gilroy-bold text-base font-bold text-white">{{'Target' | translate}}</p>
            <h3 class="text-2xl font-bold text-white">{{targetYear}}</h3>
          </div>
        </div> -->

        <!-- <div class="bg-gradient rounded-xl px-10 pt-7 pb-7 flex gap-4 justify-start z-10">
          <div class="">
            <p class="Gilroy-bold text-base font-bold text-white">{{'Target Achievement' | translate}}</p>
            <h3 class="text-2xl font-bold text-white">{{salesYear}}</h3>
          </div>
        </div> -->
      </div>

      <!-- Year Grid Ends -->

      <!-- Month Data -->
      <!-- Income Data -->
      <ng-template [ngIf]="monthsData">
        <div class="w-[80vw] mx-5 overflow-x-scroll">
          <div class="flex">
            <!-- Left  -->
            <div class="w-max">
              <table class="border-separate shadow-lg my-4 w-max">
                <thead>
                  <tr class="text-center">
                    <th
                      class="border-blue-100 shadow-sm bg-gradient Gilroy-Bold text-white px-2 w-48"
                      *ngFor="let i of tableColLeftIncome"
                      [ngClass]="{ account: i === 'Income' }"
                    >
                      {{ i }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    class="border-slate-700"
                    *ngFor="
                      let rowData of monthData?.incomeFinalArray;
                      index as tw
                    "
                  >
                    <th
                      class="text-center Gilroy-Medium border-slate-700 shadow-sm sticky left-0 bg-white w-64"
                    >
                      {{ rowData?.account }}
                    </th>
                    <td
                      class="text-center Gilroy-Medium border-slate-700 shadow-sm"
                    >
                      {{ rowData?.income0 }}
                    </td>
                    <td
                      class="text-center Gilroy-Medium border-slate-700 shadow-sm"
                    >
                      {{ rowData?.income1 }}
                    </td>
                    <td
                      class="text-center Gilroy-Medium border-slate-700 shadow-sm"
                    >
                      {{ rowData?.income2 }}
                    </td>
                    <td
                      class="text-center Gilroy-Medium border-slate-700 shadow-sm"
                    >
                      {{ rowData?.budget }}
                    </td>
                    <td
                      class="text-center Gilroy-Medium border-slate-700 shadow-sm"
                    >
                      {{ rowData?.historicIncome }}
                    </td>
                    <td
                      class="text-center Gilroy-Medium border-slate-700 shadow-sm"
                    >
                      {{ rowData?.yoy }}
                    </td>
                    <td
                      class="text-center Gilroy-Medium border-slate-700 shadow-sm"
                    >
                      {{ rowData?.budgetVariance }}%
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <!-- Right -->
            <!-- <div class="w-max ml-2">
            <table class="border-separate shadow-lg my-4 w-max">
              <thead>
                <tr class="text-center">
                  <th class="border-blue-100 shadow-sm bg-gradient Gilroy-Bold text-white px-2 w-40" *ngFor="let i of tableColRight">{{i}}</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let rowData of monthData?.incomeFinancialFinalArray;index as tw">
                  <td class="text-center Gilroy-Medium border-blue-300 shadow-sm px-2">{{rowData?.actualAmount}}</td>
                  <td class="text-center Gilroy-Medium border-blue-300 shadow-sm px-2">{{rowData?.actualBudget}}</td>
                  <td class="text-center Gilroy-Medium border-blue-300 shadow-sm px-2">{{rowData?.historicIncome}}</td>
                  <td class="text-center Gilroy-Medium border-blue-300 shadow-sm px-2">{{rowData?.yoy}}</td>
                  <td class="text-center Gilroy-Medium border-blue-300 shadow-sm px-2">{{rowData?.budgetVariance}}</td>
                </tr>
              </tbody>
            </table>
          </div> -->
          </div>

          <!-- IncomeData Ends -->

          <!-- Expense Data  -->

          <div class="flex">
            <!-- Left -->
            <div class="w-max">
              <table class="border-separate shadow-lg my-4 w-max">
                <thead>
                  <tr class="text-center">
                    <th
                      class="border-blue-100 shadow-sm bg-gradient Gilroy-Bold text-white px-2 w-48"
                      *ngFor="let i of tableColLeftExpenses"
                      [ngClass]="{ account: i === 'Expenses' }"
                    >
                      {{ i }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    class="border-slate-700"
                    *ngFor="
                      let rowData of monthData?.expenseFinalArray;
                      index as tw
                    "
                  >
                    <th
                      class="text-center Gilroy-Medium border-slate-700 shadow-sm sticky left-0 bg-white w-64"
                    >
                      {{ rowData?.account }}
                    </th>
                    <td
                      class="text-center Gilroy-Medium border-slate-700 shadow-sm"
                    >
                      {{ rowData?.expense0 }}
                    </td>
                    <td
                      class="text-center Gilroy-Medium border-slate-700 shadow-sm"
                    >
                      {{ rowData?.expense1 }}
                    </td>
                    <td
                      class="text-center Gilroy-Medium border-slate-700 shadow-sm"
                    >
                      {{ rowData?.expense2 }}
                    </td>
                    <td
                      class="text-center Gilroy-Medium border-slate-700 shadow-sm"
                    >
                      {{ rowData?.budget }}
                    </td>
                    <td
                      class="text-center Gilroy-Medium border-slate-700 shadow-sm"
                    >
                      {{ rowData?.historicExpense }}
                    </td>
                    <td
                      class="text-center Gilroy-Medium border-slate-700 shadow-sm"
                    >
                      {{ rowData?.yoy }}
                    </td>
                    <td
                      class="text-center Gilroy-Medium border-slate-700 shadow-sm"
                    >
                      {{ rowData?.budgetVariance }}%
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <!-- Right -->
            <!-- <div class="w-max ml-2">
            <table class="border-separate shadow-lg my-4 w-max">
              <thead>
                <tr class="text-center">
                  <th class="border-blue-100 shadow-sm bg-gradient Gilroy-Bold text-white px-2 w-40" *ngFor="let i of tableColRight">{{i}}</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let rowData of monthData?.expenseFinancialFinalArray;index as tw">
                  <td class="text-center Gilroy-Medium border-blue-300 shadow-sm">{{rowData?.actualAmount}}</td>
                  <td class="text-center Gilroy-Medium border-blue-300 shadow-sm">{{rowData?.actualBudget}}</td>
                  <td class="text-center Gilroy-Medium border-blue-300 shadow-sm">{{rowData?.historicExpense}}</td>
                  <td class="text-center Gilroy-Medium border-blue-300 shadow-sm">{{rowData?.yoy}}</td>
                  <td class="text-center Gilroy-Medium border-blue-300 shadow-sm">{{rowData?.budgetVariance}}</td>
                </tr>
              </tbody>
            </table>
          </div> -->
          </div>
        </div>
      </ng-template>
      <!-- Expense Data Ends -->
      <!-- Month data End -->

      <!-- Year Data -->
      <ng-template [ngIf]="yearlyData">
        <div class="flex flex-col w-[80vw] mx-5 overflow-x-scroll">
          <!-- Income -->
          <div class="w-max">
            <table class="border-separate shadow-lg my-4 w-max">
              <thead>
                <tr class="text-center">
                  <th
                    class="border-blue-100 shadow-sm bg-white Gilroy-Bold text-white px-2 sticky left-0"
                  ></th>
                  <th
                    colspan="3"
                    class="border-blue-100 shadow-sm bg-gradient Gilroy-Bold text-white px-2"
                    *ngFor="let in of counter(12); let i = index"
                  >
                    {{
                      yearData?.incomeFinalArray[0]["month" + i] +
                        " " +
                        yearData?.incomeFinalArray[0]["year" + i]
                    }}
                  </th>
                </tr>
                <tr class="text-center">
                  <th
                    class="border-blue-100 shadow-sm bg-gradient Gilroy-Bold text-white px-2 sticky left-0"
                  >
                    {{ "Income" }}
                  </th>
                  <ng-container *ngFor="let in of counter(12)">
                    <th
                      class="border-blue-100 shadow-sm bg-gradient Gilroy-Bold text-white px-2"
                      *ngFor="let i of tableColYearIncome"
                    >
                      {{ i }}
                    </th>
                  </ng-container>
                </tr>
              </thead>
              <tbody>
                <tr
                  class="border-slate-700"
                  *ngFor="
                    let rowData of yearData?.incomeFinalArray;
                    index as tw
                  "
                >
                  <th
                    class="text-center Gilroy-Medium border-slate-700 shadow-sm sticky left-0 bg-white w-64"
                  >
                    {{ rowData?.account }}
                  </th>
                  <ng-container *ngFor="let in of counter(12); let j = index">
                    <td
                      class="text-center Gilroy-Medium border-slate-700 shadow-sm"
                    >
                      {{ rowData["income" + j] }}
                    </td>
                    <td
                    class="text-center Gilroy-Medium border-slate-700 shadow-sm"
                    >
                    {{ rowData["budget" + j] }}
                  </td>
                  <!-- <td class="text-center Gilroy-Medium border-slate-700 shadow-sm inc">{{rowData["previousIncome"+ j]}}</td> -->
                    <td
                      class="text-center Gilroy-Medium border-slate-700 shadow-sm"
                    >
                      {{ rowData["budgetVariance" + j] }}%
                    </td>
                    <!-- <td class="text-center Gilroy-Medium border-slate-700 shadow-sm">{{"0"}}</td> -->
                  </ng-container>
                </tr>
              </tbody>
            </table>
          </div>

          <!-- Expense -->
          <div class="w-max">
            <table class="border-separate shadow-lg my-4 w-max">
              <thead>
                <tr class="text-center">
                  <th
                    class="border-blue-100 shadow-sm bg-white Gilroy-Bold text-white px-2 sticky left-0"
                  ></th>
                  <th
                    colspan="3"
                    class="border-blue-100 shadow-sm bg-gradient Gilroy-Bold text-white px-2"
                    *ngFor="let in of counter(12); let i = index"
                  >
                    {{
                      yearData?.expenseFinalArray[0]["month" + i] +
                        " " +
                        yearData?.expenseFinalArray[0]["year" + i]
                    }}
                  </th>
                </tr>
                <tr class="text-center">
                  <th
                    class="border-blue-100 shadow-sm bg-gradient Gilroy-Bold text-white px-2 sticky left-0"
                  >
                    {{ "Expenses" }}
                  </th>
                  <ng-container *ngFor="let in of counter(12)">
                    <th
                      class="border-blue-100 shadow-sm bg-gradient Gilroy-Bold text-white px-2"
                      *ngFor="let i of tableColYearIncome"
                    >
                      {{ i }}
                    </th>
                  </ng-container>
                </tr>
              </thead>
              <tbody>
                <tr
                  class="border-slate-700"
                  *ngFor="
                    let rowData of yearData?.expenseFinalArray;
                    index as tw
                  "
                >
                  <th
                    class="text-center Gilroy-Medium border-slate-700 shadow-sm sticky left-0 bg-white w-64"
                  >
                    {{ rowData?.account }}
                  </th>
                  <ng-container *ngFor="let in of counter(12); let j = index">
                    <td
                      class="text-center Gilroy-Medium border-slate-700 shadow-sm"
                    >
                      {{ rowData["expense" + j] }}
                    </td>
                    <!-- <td class="text-center Gilroy-Medium border-slate-700 shadow-sm">{{rowData["previousExpense"+ j]}}</td> -->
                    <td
                      class="text-center Gilroy-Medium border-slate-700 shadow-sm"
                    >
                      {{ rowData["budget" + j] }}
                    </td>
                    <td
                      class="text-center Gilroy-Medium border-slate-700 shadow-sm"
                    >
                      {{ rowData["budgetVariance" + j] }}%
                    </td>
                    <!-- <td class="text-center Gilroy-Medium border-slate-700 shadow-sm">{{"0"}}</td> -->
                  </ng-container>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </ng-template>
      <!-- Year Data Ends -->

      <ng-template [ngIf]="monthrightData">
        <div class="w-[80vw] mx-5 overflow-x-scroll">
          <div class="flex">
            <div class="w-max">
              <table class="border-separate shadow-lg my-4 w-max">
                <thead>
                  <tr class="text-center">
                    <th
                      class="border-blue-100 shadow-sm bg-gradient Gilroy-Bold text-white px-2 w-40"
                      *ngFor="let i of tableColRightIncome"
                      [ngClass]="{ account: i === 'Income' }"
                    >
                      {{ i }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    *ngFor="
                      let rowData of monthData?.incomeFinancialFinalArray;
                      index as tw
                    "
                  >
                    <th
                      class="text-center Gilroy-Medium border-slate-700 shadow-sm sticky left-0 bg-white w-64"
                    >
                      {{ rowData?.account }}
                    </th>
                    <td
                      class="text-center Gilroy-Medium border-blue-300 shadow-sm px-2"
                    >
                      {{ rowData?.actualAmount }}
                    </td>
                    <td
                      class="text-center Gilroy-Medium border-blue-300 shadow-sm px-2"
                    >
                      {{ rowData?.actualBudget }}
                    </td>
                    <td
                      class="text-center Gilroy-Medium border-blue-300 shadow-sm px-2"
                    >
                      {{ rowData?.historicIncome }}
                    </td>
                    <td
                      class="text-center Gilroy-Medium border-blue-300 shadow-sm px-2"
                    >
                      {{ rowData?.yoy }}
                    </td>
                    <td
                      class="text-center Gilroy-Medium border-blue-300 shadow-sm px-2"
                    >
                      {{ rowData?.budgetVariance }}%
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="flex">
            <div class="w-max">
              <table class="border-separate shadow-lg my-4 w-max">
                <thead>
                  <tr class="text-center">
                    <th
                      class="border-blue-100 shadow-sm bg-gradient Gilroy-Bold text-white px-2 w-40"
                      *ngFor="let i of tableColRightExpense"
                      [ngClass]="{ account: i === 'Expenses' }"
                    >
                      {{ i }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    *ngFor="
                      let rowData of monthData?.expenseFinancialFinalArray;
                      index as tw
                    "
                  >
                    <th
                      class="text-center Gilroy-Medium border-slate-700 shadow-sm sticky left-0 bg-white w-64"
                    >
                      {{ rowData?.account }}
                    </th>
                    <td
                      class="text-center Gilroy-Medium border-blue-300 shadow-sm"
                    >
                      {{ rowData?.actualAmount }}
                    </td>
                    <td
                      class="text-center Gilroy-Medium border-blue-300 shadow-sm"
                    >
                      {{ rowData?.actualBudget }}
                    </td>
                    <td
                      class="text-center Gilroy-Medium border-blue-300 shadow-sm"
                    >
                      {{ rowData?.historicExpense }}
                    </td>
                    <td
                      class="text-center Gilroy-Medium border-blue-300 shadow-sm"
                    >
                      {{ rowData?.yoy }}
                    </td>
                    <td
                      class="text-center Gilroy-Medium border-blue-300 shadow-sm"
                    >
                      {{ rowData?.budgetVariance }}%
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
</div>
