import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Observable, observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AccountingService } from 'src/app/modules/accounting/accounting.service';
import { loadAccountsOf } from 'src/app/shared/utils/accountType';
import { valueChanges } from 'src/app/shared/utils/formValidator';
import { RootReducerState } from 'src/app/store/reducers';
import { selectBusiness } from 'src/app/store/selectors/business.selector';
import { PurchasesService } from '../../purchases.service';
import { AssetsService } from './assetsServices';
import { FileUploadService } from 'src/app/shared/services/file-upload.service';

@Component({
  selector: 'app-assets',
  templateUrl: './assets.component.html',
  styleUrls: ['./assets.component.scss']
})
export class AssetsComponent implements OnInit {
  constructor(private fb: FormBuilder,
              private store: Store<RootReducerState>,
              private assetsServices: AssetsService,
              private PurchaseService: PurchasesService,
              private toastr: ToastrService,
              private translationservice: TranslateService,
              private accountingService: AccountingService,
              private fileUploadService: FileUploadService,
              private spinner: NgxSpinnerService) {
  }

  @ViewChild('fileUploadComponent') fileUploadComponent: ElementRef;
  accounts = null
  depreciationAccounts = [];
  files: File[];
  addedFiles = [];
  createAssetsForm: FormGroup;
  update = false;
  openAddAssetsModal = false;
  deleteConfirmationModal = false;
  response = '';
  assetToUpdate = null;
  businessId = null;
  deleteToAsset = null;
  error = '';
  tableHeadings = [
    'Asset Name',
    'Asset Type',
    'Description',
    'Depreciation %',
    'Actions'
  ];
  assets = [
    {
      _id: '',
      assetsName: '',
      assetsType: '',
      description: '',
      amount: '',
      depreciation: '',
      accountDetails:'',
      depreciationAccount: '',
      calDepreciation: false,
      files: []
    }
  ];
  number = '1.2-2';
  currencyDetails = {
    currency: '',
    currencySymbol: ''
  };
  tableKeys = ['assetsName', 'assetsType', 'description', 'depreciation' ];
  tableData = [];
  formErrors = {
    assetsName: '',
    assetsType: '',
    description: '',
    amount: '',
    depreciation: '',
    accountDetails:'',
    depreciationAccount: ''
  };
  formErrorMessages = {
    assetsName: {
      required: 'Name is Required'
    },
    assetsType: {
      required: 'Assets Type is Required'
    },
    depreciation: {
      required: 'Depreciation is Required',
      min: 'Depreciation cannot be zero'
    },
    description: {
      required: 'Description is Required',
    },
    amount: {
      required: 'Amount is Required'
    },
    accountDetails: {
      required: 'Account is required'
    },
    depreciationAccount: {
      required: 'Depreciation Account is required'
    }
  };
  ngOnInit(): void {
    this.loadAssets();
    this.loadForm();
    this.getCurrencyDetails();
    this.loadAccounts();
  }

  getCurrencyDetails(){
    this.PurchaseService.currencyDetails
    .subscribe(details=>{
      if(details)
        this.currencyDetails = details;
    })
  }

  loadAccounts(): void {
    this.accountingService.getAllAccounts(this.businessId)
    .subscribe(resp => {
      this.accounts = resp?.data?.filter(account => {
        return account?.accountType === "Property, Plant, Equipment"
      })
      this.depreciationAccounts = resp?.data?.filter(account => {
        return account?.accountType === "Depreciation and Amortization"
      })
    })
  }

  loadForm(asset?): void {
    this.createAssetsForm = this.fb.group({
      assetsName: [asset?.assetsName || '', [Validators.required]],
      assetsType: [asset?.assetsType || '', [Validators.required]],
      description: [asset?.description || '', [Validators.required]],
      amount: [0],
      accountDetails:[asset?.accountDetails ? asset?.accountDetails : '', [Validators.required]],
      calDepreciation: [asset?.calDepreciation ? asset?.calDepreciation : false, [Validators.required, Validators.min(0.01)]],
      depreciation: [asset?.depreciation ? Number(asset?.depreciation)?.toFixed(2) : null],
      depreciationAccount: [asset?.depreciationAccount ? asset?.depreciationAccount : null],
    });

    this.createAssetsForm.valueChanges.subscribe((data) => {
      this.formErrors = valueChanges(this.createAssetsForm, { ...this.formErrors }, this.formErrorMessages, this.translationservice);
    });
    
    this.createAssetsForm.get('calDepreciation').valueChanges.subscribe((data) => {
      this.manageValidations(data)
    })

    this.manageValidations(this.createAssetsForm.get('calDepreciation').value);

    this.formErrors = valueChanges(this.createAssetsForm, { ...this.formErrors }, this.formErrorMessages, this.translationservice);
  }

  manageValidations(data): void {
    if (data) {
      this.createAssetsForm.get('depreciationAccount').setValidators([Validators.required])
      this.createAssetsForm.get('depreciationAccount').enable();
    } else {
      this.createAssetsForm.get('depreciationAccount').clearValidators()
      this.createAssetsForm.get('depreciationAccount').disable();
      this.createAssetsForm.get('depreciationAccount').setValue(null);
    }
    this.createAssetsForm.updateValueAndValidity();
  }

  createAsset(): void {
    this.PurchaseService.businessId$.subscribe((id) => {
      if (id) {
        if (this.createAssetsForm.invalid) {
          this.createAssetsForm.markAllAsTouched();
          this.formErrors = valueChanges(this.createAssetsForm, { ...this.formErrors }, this.formErrorMessages, this.translationservice);
          return;
        }
        const body = {
          businessId: id,
          ...this.createAssetsForm.value,
          accountDetails: {
            accountId: this.createAssetsForm.value.accountDetails._id,
            accountName: this.createAssetsForm.value.accountDetails.accountName,
            accountSection: this.createAssetsForm.value.accountDetails.accountSection,
          },
          depreciationAccount: this.createAssetsForm.value.depreciationAccount ? {
            accountId: this.createAssetsForm.value.depreciationAccount._id,
            accountName: this.createAssetsForm.value.depreciationAccount.accountName,
            accountSection: this.createAssetsForm.value.depreciationAccount.accountSection,
          } : null,
          amount: 0,
          depreciation: this.createAssetsForm.value.depreciation ? Number(this.createAssetsForm.value.depreciation?.toFixed(2)) : 0,
        };
        this.spinner.show();
        this.fileUploadService.emitFiles.next(true);
        this.fileUploadService.emitFiles.next(false);
        const formData = new FormData();
        // this.files.forEach((file, i) => {
        //   formData.append(`file${i}`, file);
        // });
        formData.append('payload', JSON.stringify(body));
        this.assetsServices.createAsset({formData, businessId:this.businessId}).subscribe((resp) => {
          const assets = {
            ...resp?.data,
            amount: 0,
            customerName: resp?.data?.customerDetails?.customerId?.customerName,
            depreciation: resp?.data?.depreciation?.toFixed(2) ?? null,
          }
          this.tableData.push(assets);
          this.updateAccountsInStore(id);
          this.spinner.hide();
          this.openAddAssetsModal = false;
          this.toastr.success(resp?.message);
          this.files = [];
          this.fileUploadComponent['files'] = [];
          this.fileUploadComponent['filesArrToDisplay'] = [];
          this.createAssetsForm.reset();
          this.loadAssets();
        }, (error) => {
          this.toastr.error(this.translationservice.instant('Something went wrong!'));
          this.spinner.hide();
        });
      }
    });
  }
  loadAssets(): void {
    this.spinner.show();
    this.PurchaseService.businessId$.subscribe((id) => {
      if (id) {
        this.businessId = id;
        this.assetsServices.getAllAssets(id).subscribe(resp => {
          const data = resp.data.map(assets =>
          ({
            ...assets,
            amount: assets?.amount?.toFixed(2) ,
            depreciation: assets?.depreciation?.toFixed(2),
            customerName: assets?.customerDetails?.customerId?.customerName,
            accountDetails:assets?.accountDetails || '',
            depreciationAccount: assets?.depreciationAccount || ''
          }));
          this.tableData = data.map(item=>({
            ...item,
            amount: `${this.currencyDetails.currencySymbol}${item?.amount}` ,
            depreciation: `${item?.depreciation}%`
          }));
          this.assets = data;
          this.spinner.hide();
        }, (error) => {
          this.spinner.hide();
          this.toastr.error(this.translationservice.instant('Something went wrong!'));
        });
      }
    });
  }
  deleteConfirmation(id): void {
    this.deleteToAsset = id;
    this.deleteConfirmationModal = true;
  }
  modalAssetUpdate(id): void {
    this.openAddAssetsModal = true;
    const assets = this.assets.find(({ _id }) => _id === id);
    this.update = true;
    const {
      assetsName,
      assetsType,
      description,
      amount,
      depreciation,
      accountDetails,
      depreciationAccount,
      calDepreciation = false,
      files = []
    } = assets;

    const data = {
      assetsName,
      assetsType,
      description,
      amount:Number(amount).toFixed(2),
      depreciation: Number(depreciation).toFixed(2),
      accountDetails,
      depreciationAccount,
      calDepreciation
    };
    this.addedFiles = files
    this.createAssetsForm.setValue(data);
    if (data.calDepreciation) {
      this.createAssetsForm.get('depreciationAccount').enable();
      this.createAssetsForm.get('depreciationAccount').setValidators([Validators.required])
    }
    this.assetToUpdate = id;
  }
  cancelModal(): void {
    this.openAddAssetsModal = false;
    this.createAssetsForm.reset();
    this.update = false;
    this.files = [];
    this.addedFiles = [];
    this.fileUploadComponent['files'] = [];
    this.fileUploadComponent['filesArrToDisplay'] = [];
  }
  
  updateAsset(): void {
    this.error = '';
    this.response = '';
    if (this.createAssetsForm.invalid) {
      this.createAssetsForm.markAllAsTouched();
      this.formErrors = valueChanges(this.createAssetsForm, { ...this.formErrors }, this.formErrorMessages, this.translationservice);
      return;
    }
    const body = {
      _id: this.assetToUpdate,
      businessId: this.businessId,
      ...this.createAssetsForm.value,
      amount: (Math.round(this.createAssetsForm?.value?.amount*100)/100).toFixed(2),
      depreciation: Math.round(this.createAssetsForm?.value?.depreciation ?? 0).toFixed(2),
    };
    this.spinner.show();
    this.fileUploadService.emitFiles.next(true);
    this.fileUploadService.emitFiles.next(false);
    const formData = new FormData();
    // this.files.forEach((file, i) => {
    //   formData.append(`file${i}`, file);
    // });
    formData.append('payload', JSON.stringify(body));
    this.assetsServices.updateAsset(formData, this.assetToUpdate).subscribe((resp) => {
      this.spinner.hide();
      this.openAddAssetsModal = false;
      this.createAssetsForm.reset();
      const updatedAsset = resp.data
      const asset = (({
        _id,
        assetsName,
        assetsType,
        description,
        amount,
        depreciation,
        accountDetails,
        depreciationAccount,
        calDepreciation
      }) => ({
        _id,
        assetsName,
        assetsType,
        description,
        amount:amount.toFixed(2),
        depreciation: depreciation?.toFixed(2),
        accountDetails,
        depreciationAccount,
        calDepreciation
      }))(updatedAsset);
      this.toastr.success(resp?.message || this.translationservice.instant('Product updated successfully'))
      this.tableData = this.assets.filter(({_id}) => _id !== this.assetToUpdate);
      this.updateAccountsInStore(this.businessId);
      this.tableData.push(asset);
      this.createAssetsForm.reset();
      this.loadAssets();
      this.files = [];
      this.addedFiles = [];
      this.fileUploadComponent['files'] = [];
      this.fileUploadComponent['filesArrToDisplay'] = [];

    }, (error) => {
      this.toastr.error(this.translationservice.instant(error?.error?.message ?? 'Something went wrong!'));
      this.spinner.hide();
    });
  }
  deleteAsset(): void {
    this.spinner.show();
    this.assetsServices.deleteAsset(this.deleteToAsset).subscribe((resp) => {
      this.spinner.hide();
      this.deleteConfirmationModal = false;
      if(resp?.success){
        this.tableData = this.tableData.filter(({_id}) => _id !== this.deleteToAsset);
        this.updateAccountsInStore(this.businessId);
        this.toastr.success(resp?.message)
      }
    }, (error) => {
      this.spinner.hide();
      this.toastr.error(this.translationservice.instant('Something went wrong!'));
    });
  }

  updateAccountsInStore(id): void {
    this.accountingService.getAllAccounts(id).subscribe((resp) => {
      if (resp.success) {
        this.accountingService.setAccountsInStore(resp.data);
      }
    });
  }

  saveFiles(files: File[]): void {
    this.files = files;
  }
}
