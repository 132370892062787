<div class="w-full flex flex-col items-center space-y-4 bg-white border border-black rounded p-2 ">
    <div class="w-full my-2">
        <p class="Gilroy-Bold text-lg"> {{"Features you'll get" | translate}}: </p>
        <ol>
            <li >{{features[from] | translate}}</li>
        </ol>
    </div>
    <div  class=" w-full rounded-2xl flex justify-between text-sm Gilroy text-white items-center bg-[#006FB8] px-5 py-3">
        <div>
            Subscribe to unlock this feature
        </div>
        <div>
            <button (click)="showConfirmationDialog = true" class="p-3 bg-white Gilroy rounded-xl text-[#006FB8]" >Buy Now</button>
        </div>
    </div>
</div>

<app-modal-container [isOpen]='showConfirmationDialog'>
    <div header class="border-b Gilory-SemiBold pb-2">
        {{'Purchase Subscription Plan' | translate}}
    </div>
    <div content>
        {{'Are you sure you want to purchase this plan?' | translate }}
    </div>
    <div footer class="space-x-2 rtl:space-x-reverse">
        <app-button (handleClick)="getSubscriptionLink()" buttonText='{{"Yes" | translate }}' type='primary' rounded='true'></app-button>
        <app-button buttonText='{{"No" | translate }}' type='third' rounded='true' (handleClick)='showConfirmationDialog = false'>
        </app-button>
    </div>
</app-modal-container>