

<p class="text-md Gilroy-Bold mb-4">{{'Add extra features' | translate }}</p>
<div class="flex w-full space-x-2 justify-evenly  mobilescreen">
    <div *ngFor='let plan of subscriptionPlans; let i = index' class=" w-1/4 mobileWidth">
        <ng-container [ngTemplateOutlet]="this[subscriptionPlans[i].planName]"
            [ngTemplateOutletContext]='{$implicit: plan}'>
        </ng-container>
    </div>
</div>
<div class="my-3" >
    <p class="mt-10 text-2xl Gilroy-Bold" >{{'Pay-As-You-Go' | translate}}</p>
    <div>
        <p class="text-md Gilroy-SemiBold mb-4 mt-10">{{'Storage' | translate }}</p>
        <div class="flex space-x-2  mobilescreen">
            <div *ngFor='let plan of storagePlans; let i = index' class="flex-1 w-1/4 mobileWidth">
                <ng-container [ngTemplateOutlet]="storage"
                [ngTemplateOutletContext]='{$implicit: plan}'>
            </ng-container>
            </div>
        </div>
    </div>
    <div>
        <p class="text-md Gilroy-SemiBold mb-4 mt-10">{{'Select the required transaction limit' | translate }}</p>
        <div class="flex space-x-2  mobilescreen">
            <div *ngFor='let plan of transactionPlans; let i = index' class="flex-1 w-1/4 mobileWidth">
                <ng-container [ngTemplateOutlet]="transaction"
                [ngTemplateOutletContext]='{$implicit: plan}'>
            </ng-container>
            </div>
        </div>
    </div>
</div>

<app-modal-container [isOpen]='cancelSubscriptionPlanModal'>
    <div header class="border-b Gilory-SemiBold pb-2">
        {{'Cancel Subscription Plan' | translate}}
    </div>
    <div content>
        {{'Are you sure you want to cancel your subscription plan.' | translate }}
    </div>
    <div footer class="space-x-2 rtl:space-x-reverse">
        <app-button (handleClick)='cancelPlan()' buttonText='{{"Yes" | translate }}' type='danger' rounded='true'></app-button>
        <app-button buttonText='{{"No" | translate }}' type='third' rounded='true' (handleClick)='cancelSubscriptionPlanModal = false'>
        </app-button>
    </div>
</app-modal-container>
<app-modal-container [isOpen]='confirmSubscriptionPlanModal'>
    <div header class="border-b Gilory-SemiBold pb-2">
        {{'Purchase Subscription Plan' | translate}}
    </div>
    <div content>
        {{'Are you sure you want to purchase this plan?' | translate }}
    </div>
    <div footer class="space-x-2 rtl:space-x-reverse">
        <app-button (handleClick)='getSubscriptionLink()' buttonText='{{"Yes" | translate }}' type='primary' rounded='true'></app-button>
        <app-button buttonText='{{"No" | translate }}' type='third' rounded='true' (handleClick)='confirmSubscriptionPlanModal = false'>
        </app-button>
    </div>
</app-modal-container>
<!-- <app-modal-container [isOpen]='showDowngradeModal'>
    <div header class="border-b Gilory-Bold pb-2">
        {{'Cannot downgrade to this plan' | translate }}
    </div>
    <div content>
        <div class="flex flex-col space-y-2"></div>
        <h4>
            {{'Please make sure you fulfill all the below conditions to downgrade' | translate}}
        </h4>
        <table class="border-0">
            <tr>
                <td>
                    <input type="checkbox" disabled class="rounded-xl"
                        [ngClass]='{"text-green-500": downgradeCheck.transactionLimit}' readonly
                        [checked]='downgradeCheck.transactionLimit'>
                </td>
                <td>
                    <p class="p-2">
                        {{'Transactions should be less than' | translate }} {{newPlan?.features?.transactionLimit | translate}}
                    </p>
                </td>
                <td>
                    <span class="p-2">{{"Current transactions" | translate }} {{currentUsage?.transactionLimit | translate }}</span>
                </td>
            </tr>
            <tr>
                <td>
                    <input type="checkbox" disabled class="rounded-xl"
                        [ngClass]='{"text-green-500": downgradeCheck.businessLimit}'
                        [checked]='downgradeCheck.businessLimit'>
                </td>
                <td>
                    <p class="p-2">
                        {{'Businesses should be less than' | translate }} {{newPlan?.features?.businessLimit | translate }}
                    </p>
                </td>
                <td>
                    <span class="p-2">{{'Current businesses' | translate }} {{currentUsage?.businessLimit | translate }}</span>
                </td>
            </tr>
            <tr>
                <td>
                    <input type="checkbox" disabled class="rounded-xl"
                        [ngClass]='{"text-green-500": downgradeCheck.adminLimit}' [checked]='downgradeCheck.adminLimit'>
                </td>
                <td>
                    <p class="p-2">
                        {{'Account emails should be less than' | translate }} {{newPlan?.features?.adminLimit | translate}}
                    </p>
                </td>
                <td>
                    <span class="p-2">{{'Currently added emails:' | translate }} {{currentUsage?.adminLimit | translate}}</span>
                </td>
            </tr>
            <tr>
                <td>
                    <input type="checkbox" disabled class="rounded-xl"
                        [ngClass]='{"text-green-500": downgradeCheck.supportEmail}'
                        [checked]='downgradeCheck.supportEmail'>
                </td>
                <td>
                    <p class="p-2">
                        {{'Support emails should be less than' | translate }} {{newPlan?.features?.supportEmail | translate}}
                    </p>
                </td>
                <td>
                    <span class="p-2">{{'Current invited users' | translate }} {{currentUsage?.supportEmail | translate}}</span>
                </td>
            </tr>

        </table>

    </div>
    <div footer>
        <app-button buttonText='{{"Ok" | translate }}' type='primary' rounded='true' (handleClick)='showDowngradeModal = false'>
        </app-button>
    </div>
</app-modal-container> -->

<app-modal-container [isOpen]='starterPlanModal'>
    <div header class="border-b Gilory-Bold pb-2">
        {{'Free Subscription Plan' | translate }}
    </div>
    <div content>
        {{'Are you sure you want to subscribe free plan.' | translate }}
    </div>
    <div footer class="space-x-2 rtl:space-x-reverse">
        <app-button (handleClick)='injectPlan()' buttonText='{{"Yes" | translate }}' type='danger' rounded='true'></app-button>
        <app-button buttonText='{{"No" | translate }}' type='third' rounded='true' (handleClick)='starterPlanModal = false'>
        </app-button>
    </div>
</app-modal-container>

<!-- Add on plans -->
<ng-template #Budget let-plan>
    <div class="rounded-xl pb-2 flex flex-col bg-white shadow-xl h-[400px] overflow-hidden">
        <div class="bg-gradient h-1/3 px-3 py-2">
            <div class="">
                <p class="text-white text-xl px-2 py-1 w-fit rounded-md font-bold Gilroy-Bold mt-3">{{plan?.planName | translate}}</p>
            </div>
            <div class="flex space-x-2  py-3">
                <p class="text-xl pt-4 text-white Gilroy-Bold">$</p>
                <p class="text-[50px] text-white Gilroy-Bold">{{plan?.planPrice | translate }}</p>
                <p class="text-sm pt-4 text-gray-400">/ {{plan?.planDuration | translate}}</p>
            </div>
        </div>
        <ol class="space-y-4 flex-1 list-none ms-4 p-3">
            <li class="flex list-item items-center space-x-2 rtl:space-x-reverse ">
                <p class="text-sm Gilroy-Bold">{{"P/L Budget Subscription Description" | translate }}</p>
            </li>
        </ol>
        <div class="mt-4 flex justify-center">
            <ng-container *ngIf="isSubscribed(plan?._id) else buyButtons">
                <div *ngIf="!isCanceled(plan?._id) else lastDate" class="flex w-full justify-center">
                    <app-button
                        buttonText='{{"Cancel" | translate }}' type='danger' rounded='full'
                        (handleClick)='confirmCancelPlan(plan)'>
                    </app-button>
                </div>
                <ng-template #lastDate>
                    <p class="Gilroy" >{{'Valid till:' | translate}} {{getLastDate(plan?._id) | date: 'dd/MM/yyyy'}}</p>
                </ng-template>
            </ng-container>
            <ng-template #buyButtons>
                <app-button buttonText='{{"Buy this" | translate }}' type='primary' rounded='full'
                (handleClick)='showConfiramtionDialoge(plan)'></app-button>
            </ng-template>
        </div>
    </div>
</ng-template>
<ng-template #Payroll let-plan>
    <div class="rounded-xl pb-2 flex flex-col bg-white shadow-xl h-[400px] overflow-hidden">
        <div class="bg-gradient h-1/3 px-3 py-2">
            <div class="">
                <p class="text-white text-xl px-2 py-1 w-fit rounded-md font-bold Gilroy-Bold mt-3">{{plan?.planName | translate}}</p>
            </div>
            <div class="flex space-x-2  py-3">
                <p class="text-xl pt-4 text-white Gilroy-Bold">$</p>
                <p class="text-[50px] text-white Gilroy-Bold">{{plan?.planPrice | translate }}</p>
                <p class="text-sm pt-4 text-gray-400">/ {{plan?.planDuration | translate}}</p>
            </div>
        </div>
        <ol class="space-y-4 flex-1 list-none ms-4 p-3">
            <li class="flex list-item items-center space-x-2 rtl:space-x-reverse ">
                <p class="text-sm Gilroy-Bold">{{"Payroll Subscription Description" | translate }}</p>
            </li>
        </ol>
        <div class="mt-4 flex justify-center">
            <ng-container *ngIf="isSubscribed(plan?._id) else buyButtons">
                <div *ngIf="!isCanceled(plan?._id) else lastDate" class="flex w-full justify-center">
                    <app-button
                        buttonText='{{"Cancel" | translate }}' type='danger' rounded='full'
                        (handleClick)='confirmCancelPlan(plan)'>
                    </app-button>
                </div>
                <ng-template #lastDate>
                    <p class="Gilroy" >{{'Valid till:' | translate}} {{getLastDate(plan?._id) | date: 'dd/MM/yyyy'}}</p>
                </ng-template>
            </ng-container>
            <ng-template #buyButtons>
                <app-button buttonText='{{"Buy this" | translate }}' type='primary' rounded='full'
                (handleClick)='showConfiramtionDialoge(plan)'></app-button>
            </ng-template>
        </div>
    </div>
</ng-template>
<ng-template #Inventory let-plan>
    <div class="rounded-xl pb-2 flex flex-col bg-white shadow-xl h-[400px] overflow-hidden">
        <div class="bg-gradient h-1/3 px-3 py-2">
            <div class="">
                <p class="text-white text-xl px-2 py-1 w-fit rounded-md font-bold Gilroy-Bold mt-3">{{plan?.planName | translate}}</p>
            </div>
            <div class="flex space-x-2  py-3">
                <p class="text-xl pt-4 text-white Gilroy-Bold">$</p>
                <p class="text-[50px] text-white Gilroy-Bold">{{plan?.planPrice | translate }}</p>
                <p class="text-sm pt-4 text-gray-400">/ {{plan?.planDuration | translate}}</p>
            </div>
        </div>
        <ol class="space-y-4 flex-1 list-none ms-4 p-3">
            <li class="flex list-item items-center space-x-2 rtl:space-x-reverse ">
                <p class="text-sm Gilroy-Bold">{{"Inventory Subscription Description" | translate }}</p>
            </li>
        </ol>
        <div class="mt-2 flex justify-center">
            <ng-container *ngIf="isSubscribed(plan?._id) else buyButtons">
                <div *ngIf="!isCanceled(plan?._id) else lastDate" class="flex w-full justify-center">
                    <app-button
                        buttonText='{{"Cancel" | translate }}' type='danger' rounded='full'
                        (handleClick)='confirmCancelPlan(plan)'>
                    </app-button>
                </div>
                <ng-template #lastDate>
                    <p class="Gilroy" >{{'Valid till:' | translate}} {{getLastDate(plan?._id) | date: 'dd/MM/yyyy'}}</p>
                </ng-template>
            </ng-container>
            <ng-template #buyButtons>
                <app-button buttonText='{{"Buy this" | translate }}' type='primary' rounded='full'
                (handleClick)='showConfiramtionDialoge(plan)'></app-button>
            </ng-template>
        </div>
    </div>
</ng-template>
<ng-template #Recurring let-plan>
    <div class="rounded-xl pb-2 flex flex-col bg-white shadow-xl h-[400px] overflow-hidden">
        <div class="bg-gradient h-1/3 px-3 py-2">
            <div class="">
                <p class="text-white text-xl px-2 py-1 w-fit rounded-md font-bold Gilroy-Bold mt-3">{{plan?.planName | translate}}</p>
            </div>
            <div class="flex space-x-2  py-3">
                <p class="text-xl pt-4 text-white Gilroy-Bold">$</p>
                <p class="text-[50px] text-white Gilroy-Bold">{{plan?.planPrice | translate }}</p>
                <p class="text-sm pt-4 text-gray-400">/ {{plan?.planDuration | translate}}</p>
            </div>
        </div>
        <ol class="space-y-4 flex-1 list-none ms-4 p-3">
            <li class="flex list-item items-center space-x-2 rtl:space-x-reverse ">
                <p class="text-sm Gilroy-Bold">{{"Recurring Subscription Description" | translate }}</p>
            </li>
        </ol>
        <div class="mt-4 flex justify-center">
            <ng-container *ngIf="isSubscribed(plan?._id) else buyButtons">
                <div *ngIf="!isCanceled(plan?._id) else lastDate" class="flex w-full justify-center">
                    <app-button
                        buttonText='{{"Cancel" | translate }}' type='danger' rounded='full'
                        (handleClick)='confirmCancelPlan(plan)'>
                    </app-button>
                </div>
                <ng-template #lastDate>
                    <p class="Gilroy" >{{'Valid till:' | translate}} {{getLastDate(plan?._id) | date: 'dd/MM/yyyy'}}</p>
                </ng-template>
            </ng-container>
            <ng-template #buyButtons>
                <app-button buttonText='{{"Buy this" | translate }}' type='primary' rounded='full'
                (handleClick)='showConfiramtionDialoge(plan)'></app-button>
            </ng-template>
        </div>
    </div>
</ng-template>
<ng-template #storage let-plan>
    <div class="rounded-xl flex flex-col bg-gradient shadow-xl h-[250px] overflow-hidden">
        <div class="flex-1 px-3 py-2">
            <div class="">
                <p class="text-white text-xl px-2 py-1 w-fit rounded-md font-bold Gilroy-Bold mt-3">{{plan?.planName | translate}} GB</p>
            </div>
            <div class="flex space-x-2  py-5">
                <p class="text-xl pt-2 text-white Gilroy-Bold">$</p>
                <p class="text-5xl text-white Gilroy-Bold">{{plan?.planPrice | translate }}</p>
                <p class="text-sm pt-2 text-gray-400">/ {{plan?.planDuration | translate}}</p>
            </div>
        </div>
        <div class="h-[30%] flex justify-center items-center space-x-2 bg-white">
            <ng-container *ngIf="currentStorageSubscription && !currentStorageSubscription?.isCanceled else buyButtons">
                <app-button
                    *ngIf='plan?._id === currentStorageSubscription?.subId'
                    buttonText='{{"Cancel" | translate }}' type='danger' rounded='full'
                    (handleClick)='confirmCancelPlan(plan)'>
                </app-button>
                <app-button
                    *ngIf='plan?.planPrice > currentStorageSubscription?.planPrice'
                    buttonText='{{"Upgrade" | translate }}' type='third' rounded='full'
                    (handleClick)='updatePlan(plan, true)'>
                </app-button>
                <app-button
                    *ngIf='plan?.planPrice < currentStorageSubscription?.planPrice'
                    buttonText='{{"Downgrade" | translate }}' type='third' rounded='full'
                    (handleClick)='updatePlan(plan, false)'>
                </app-button>
            </ng-container>
            <ng-template #buyButtons>
                <ng-container *ngIf="!currentStorageSubscription || (currentStorageSubscription?.isCanceled && plan?._id !== currentStorageSubscription?.subId) else lastDate">
                    <app-button  buttonText='{{"Buy this" | translate }}' type='primary' rounded='full'
                    (handleClick)='showConfiramtionDialoge(plan)'></app-button>
                </ng-container>
                <ng-template #lastDate>
                    <p class="Gilroy" >{{'Valid till:' | translate}} {{currentStorageSubscription?.expiryDate  | date: 'dd/MM/yyyy'}}</p>
                </ng-template>
            </ng-template>
        </div>
    </div>
</ng-template>

<!-- Transaction Plan -->
<ng-template #transaction let-plan>
    <div class="rounded-xl flex flex-col bg-gradient shadow-xl h-[250px] overflow-hidden">
        <div class="flex-1 px-3 py-2">
            <div class="">
                <p class="text-white text-xl px-2 py-2 mb-2 w-fit rounded-md font-bold Gilroy-Bold ">{{plan?.planName | translate}}</p>
            </div>
            <div class="flex space-x-2  py-2">
                <p class="text-xl pt-2 text-white Gilroy-Bold">$</p>
                <p class="text-5xl text-white Gilroy-Bold">{{plan?.planPrice | translate }}</p>
                <p class="text-sm pt-2 text-gray-400">/ {{plan?.planDuration | translate}}</p>
            </div>
        </div>
        <div class="h-[30%] flex justify-center items-center space-x-2 bg-white">
            <ng-container *ngIf="currentTransactionSubscription && !currentTransactionSubscription?.isCanceled else buyButtons">
                <app-button
                    *ngIf='plan?._id === currentTransactionSubscription?.subId'
                    buttonText='{{"Cancel" | translate }}' type='danger' rounded='full'
                    (handleClick)='confirmCancelPlan(plan)'>
                </app-button>
                <app-button
                    *ngIf='plan?.planPrice > currentTransactionSubscription?.planPrice'
                    buttonText='{{"Upgrade" | translate }}' type='third' rounded='full'
                    (handleClick)='updatePlan(plan, true)'>
                </app-button>
                <app-button
                    *ngIf='plan?.planPrice < currentTransactionSubscription?.planPrice'
                    buttonText='{{"Downgrade" | translate }}' type='third' rounded='full'
                    (handleClick)='updatePlan(plan, false)'>
                </app-button>
            </ng-container>
            <ng-template #buyButtons>
                <ng-container *ngIf="!currentTransactionSubscription || (currentTransactionSubscription?.isCanceled && plan?._id !== currentTransactionSubscription?.subId) else lastDate">
                    <app-button  buttonText='{{"Buy this" | translate }}' type='primary' rounded='full'
                    (handleClick)='showConfiramtionDialoge(plan)'></app-button>
                </ng-container>
                <ng-template #lastDate>
                    <p class="Gilroy" >{{'Valid till:' | translate}} {{currentTransactionSubscription?.expiryDate  | date: 'dd/MM/yyyy'}}</p>
                </ng-template>
            </ng-template>
        </div>
    </div>
</ng-template>

<app-modal-container [isOpen]="showCardModal" >
        <div content>
          <h3 class="text-lg text-black">{{'Add Card details' | translate }}</h3>
                <ng-container *ngIf="elementsOptions?.clientSecret as clientSecret">
                    <ngx-stripe-payment [clientSecret]="clientSecret"></ngx-stripe-payment>
                </ng-container>
        </div>
        <div footer class="space-x-2">
          <app-button type='primary' (handleClick)="pay()" [buttonText]="'Add card details' | translate" ></app-button>
          <app-button type='danger' (handleClick)="showCardModal = false" [buttonText]="'Cancel' | translate" ></app-button>
        </div>
</app-modal-container>