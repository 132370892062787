import { ReadPropExpr } from '@angular/compiler';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { selectCustomizationSettings } from 'src/app/modules/sales/state/selectors/customizationSettings.selector';
import { copyToClipBoard } from 'src/app/shared/utils/copyToClipBoard';
import { RootReducerState } from 'src/app/store/reducers';
import { selectBusiness } from 'src/app/store/selectors/business.selector';
import { InvoicesService } from '../../../invoices/invoices.servcies';
import { EstimatesService } from '../../estimates.service';
import { environment } from 'src/environments/environment';
import { LanguageService } from 'src/app/shared/services/language.service';
import { NumberService } from 'src/app/shared/services/number.service';
import { FileUploadService } from 'src/app/shared/services/file-upload.service';
import { selectProducts } from 'src/app/modules/sales/state/selectors/products.selector';
import { selectUserSubscription } from 'src/app/store/selectors/user.selectors';

interface StockCheck {
  item: string;
  openingStock: number;
  quantityAdded: number;
}

@Component({
  selector: 'app-view-estimate',
  templateUrl: './view-estimate.component.html',
  styleUrls: ['./view-estimate.component.scss'],
})
export class ViewEstimateComponent implements OnInit, OnDestroy {
  constructor(
    private store: Store<RootReducerState>,
    private router: Router,
    private route: ActivatedRoute,
    private estimateService: EstimatesService,
    private invoiceService: InvoicesService,
    private spinner: NgxSpinnerService,
    private languageService: LanguageService,
    private toaster: ToastrService,
    private numberService: NumberService,
    private fileUploadService: FileUploadService
  ) {
    this.business$ = this.store.pipe(select(selectBusiness));
    this.products$ = this.store.pipe(select(selectProducts));
    this.subscription$ = store.pipe(select(selectUserSubscription));
    this.customizationSettings$ = this.store.pipe(select(selectCustomizationSettings));
  }
  business$: Observable<any>;
  products$: Observable<any>;
  subscription$: Observable<any>;
  customizationSettings$: Observable<any>;
  customizationSettings = null;
  products = [];
  subscription = null;
  businessId = null;
  business = null;
  currencyDetails = {
    currency:'',
    currencySymbol:''
  };
  unsubscribe$ = new Subject();
  addedFiles = [];
  files: File[] = [];
  viewEstimateDetail;
  emailModal = false;
  error = '';
  response = '';
  shareURL = '';
  copyButtonText = 'Copy';
  email = new FormControl(null, [Validators.required, Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]);
  fieldNames = {
    type: 'Invoice',
    estimateNumber: 'Estimate Number',
    date: 'Estimate Date',
    expiryDate: 'Expiry Date',
    purchaseOrder: 'Reference Number',
    billingAddress: 'Billing Address',
    shippingAddress: 'Shipping Address',
    total: 'Total',
    receiptAmount: 'Receipt Amount',
    subtotal: 'Subtotal',
    tax: 'Tax',
    terms: 'Memo',
    itemName: 'Item Name',
    quantity: 'Quantity',
    unitPrice: 'Unit Price',
    totalAmount: 'Total Amount',
    billTo: 'Sent To',
    eSign: 'Digitally signed document',
    discount: 'Discount',
    discountSubtotal: 'Discount Subtotal',
    issuer: 'Issued By'
  };
  outOfStock: boolean = false;
  checkQuantityInStock: Array<StockCheck | null> = [];
  baseURL = environment.frontBaseURL;
  number = '1.2-2';
  decimalSize = 2;

  ngOnInit(): void {
    this.business$.pipe(takeUntil(this.unsubscribe$)).subscribe((business) => {
      this.businessId = business.businessId._id;
      this.business = business.businessId;
      const {currency, currencySymbol} = business?.businessId;
      this.currencyDetails = {
        currency: currency,
        currencySymbol: currencySymbol
      }
    });
    this.loadSubscription();
    this.loadCustomSettings();
    this.route.queryParams.subscribe(({ id }) => {
      if (id) { this.loadEstimate(id); }
      else {
        this.router.navigate(['../']);
      }
    });
    this.loadNumberConfig()
  }

  loadSubscription(): void {
    this.subscription$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((subscription) => {
        this.subscription = subscription;
        this.products$
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe((products) => {
            if (
              this.subscription?.subscriptions?.find(sub => sub.subHost === 'Inventory')
            ) {
              products = products?.filter((product) => product?.isReviewed);
            }
            this.products = products.filter((product) => product?.isSale);
          });
      });
  }

  loadNumberConfig(): void {
    this.numberService.number
    .pipe((takeUntil(this.unsubscribe$)))
    .subscribe((number) => {
      this.number = number;
    })

    this.numberService.decimalSize
    .pipe((takeUntil(this.unsubscribe$)))
    .subscribe((decimalSize) => {
      this.decimalSize = decimalSize
    })
  }

  loadEstimate(id): void {
    this.spinner.show();
    this.estimateService.getEstimate(id).subscribe(
      (resp) => {
        this.spinner.hide();
        this.viewEstimateDetail = resp.data;
        this.addedFiles = resp?.data?.files;
        this.shareURL = `${this.baseURL}/open/estimate/${resp.data.businessId._id}/${resp.data._id}`;
      },
      (error) => {
        this.spinner.hide();
        this.router.navigate(['../']);
        console.log(error);
      }
    );
  }

  loadCustomSettings(): void {
    this.customizationSettings$.pipe(takeUntil(this.unsubscribe$)).subscribe((settings) => {
      this.customizationSettings = settings;
    });
  }

  convertToInvoice(): void {

    if (this.subscription?.subscriptions?.find(sub => sub.subHost === 'Inventory')) {
      let stockCheck: Array<StockCheck | null> = [];
      this.viewEstimateDetail.items?.forEach((item) => {
        const product = this.products.find(
          (product) => product?._id == item?.itemId
        );
        if (product?.itemType === 'Product' && product?.stockDetails?.openingStock < item?.quantity) {
          stockCheck.push({
            item: item?.item,
            openingStock: product?.stockDetails?.openingStock,
            quantityAdded: item?.quantity,
          });
        }
      });
      this.checkQuantityInStock = stockCheck;

      if (this.checkQuantityInStock.length) {
        this.outOfStock = true;
        return;
      }
    }

    this.spinner.show();
    const from = moment(this.viewEstimateDetail.date).startOf('year').format('YYYY-MM-DD');
    const to = moment(this.viewEstimateDetail.expiryDate).endOf('year').format('YYYY-MM-DD');
    const year = moment(this.viewEstimateDetail.date).format('YYYY');
    const body = {
      from,
      to,
      year
    };
    const newInvoice = this.createInvoiceData(this.viewEstimateDetail);
    this.createInvoice(newInvoice);
  }

  createInvoiceData(data): object {
    const items = data.items.map(item => ({
      accountDetails: item.accountDetails,
      item: item.item,
      itemId: item.itemId,
      hsn_sac: item?.hsn_sac,
      sku: item?.sku,
      isSale: item?.isSale ?? false,
      cogsAccountDetails: item?.cogsAccountDetails ?? null,
      inventoryAccountDetails: item?.inventoryAccountDetails ?? null,
      unit: item.quantity,
      price: item.unitPrice,
      tax: item.tax,
      type: item?.type,
      totalCost: item.totalCost,
      averagePrice: (item.averagePrice || item.unitPrice)
    }));
    return {
      invoiceNumber: 'DRAFT',
        customerDetails: {
          customerId: data.customerDetails.customerId._id,
        },
        businessDetails: {
          businessId: '',
        },
        purchaseOrder: data.purchaseOrder,
        invoiceDate: data.date,
        dueDate: data.expiryDate,
        companylogo: this.customizationSettings.companyLogo || '',
        title: this.customizationSettings?.invoiceSettings?.title ?? 'Invoice',
        subHeading: this.customizationSettings?.invoiceSettings?.scheduling ?? 'Summary',
        notesTerms: this.customizationSettings?.invoiceSettings?.terms ?? null,
        Footer: this.customizationSettings?.invoiceSettings?.footer ?? null,
        items: [...items],
        discount: 0,
        discountValue: null,
        qrHeading: null,
        dueAmount: data.totalAmount,
        totalAmount: data.totalAmount,
        tax: data.tax,
        subtotal: data.subTotal,
        averageTotalAmount: this.numberService.toFixed((data.averageTotalAmount || data.totalAmount)),
        currencyDetails: {
          currencySymbol: this.currencyDetails?.currencySymbol,
          currencyValue: null,
          convertedAmount: null
        },
    };
  }

  createInvoice(invoiceData): void {
    this.invoiceService.createInvoice(invoiceData).subscribe((resp) => {
      this.spinner.hide();
      console.log(resp);
      this.router.navigate(['/sales/invoices/view-invoice'], { queryParams: { id: resp.data._id } });
    }, (error) => {
      this.spinner.hide();
      console.log(error);
    });
  }

  copyLink(): void {
    copyToClipBoard(this.shareURL);
    this.copyButtonText = 'Copied!';
    setTimeout(() => {
      this.copyButtonText = 'Copy';
    }, 1500);
  }

  sendEmail(): void {
    if (this.email.invalid) {
      this.email.markAsDirty();
      return;
    }
    const data = {
      email: this.email.value,
      id: this.viewEstimateDetail._id,
      ownerId: this.business.userId
    };
    this.spinner.show();
    this.estimateService.sendEmail(data).subscribe((resp) => {
      this.spinner.hide();
      this.email.reset();
      this.emailModal = false;
      if (resp.status !== 500) {
        this.toaster.success('Email sent successfully');
      } else {
        this.toaster.error(resp.message ?? 'Email not sent');  
      }
    }, (error) => {
      this.spinner.hide();
      this.toaster.error('Email not sent');
      console.log(error);
    });
  }

  downloadPdf(): void {
    const body = {
      config: {
        data: {
          ...this.viewEstimateDetail,
          items: this.viewEstimateDetail.items.map(item => {
            return {...item,
              unitPrice: this.numberService.currencier(item?.unitPrice),
              totalCost: this.numberService.currencier((item.unitPrice * item.quantity))
            }
          }),
          subTotal: this.numberService.currencier(this.viewEstimateDetail.subTotal),
          tax: this.numberService.currencier(this.viewEstimateDetail.tax),
          totalAmount: this.numberService.currencier(this.viewEstimateDetail.totalAmount),
          currencyDetails: { currencySymbol: this.currencyDetails.currencySymbol },
          companylogo: this.customizationSettings.companyLogo,
        expiryDate: moment(this.viewEstimateDetail.expiryDate).format('DD-MM-YYYY'),
        date: moment(this.viewEstimateDetail.date).format('DD-MM-YYYY')
        },
        direction: localStorage.getItem('NuMetric|lang')=== 'en'? 'ltr': 'rtl',
        showTax: this.viewEstimateDetail.tax ? true : false,
        decimalSize: this.decimalSize,
        fieldNames: this.languageService.translate(this.fieldNames)
      }
    };
    this.spinner.show();
    this.estimateService.createEstimatePdf(body).subscribe((resp) => {
      this.spinner.hide();
      const a = document.createElement('a');
      const blob = new Blob([resp], {type: 'application/pdf'});
      const url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = `Estimate ${this.viewEstimateDetail.estimateNumber}.pdf`;
      a.click();
      window.URL.revokeObjectURL(url);
      this.toaster.success('PDF downloaded');
    }, (error) => {
      this.spinner.hide();
      const errorMessage = error?.error?.message || 'Something went wrong';
      this.toaster.error(errorMessage);
    });
  }

  uploadFiles(): void {
    this.fileUploadService.emitFiles.next(true);
    this.fileUploadService.emitFiles.next(false);
    if(!this.files.length){
      this.toaster.error('Select files to upload');
      return;
    }
    this.spinner.show();
    const body = {
      businessId: this.businessId,
      referenceId: this.viewEstimateDetail?._id,
      documentType: 'Estimate',
      parentDocType: 'Sales'
    }
    const formData = new FormData();
    this.files.forEach((file, i) => {
      formData.append(`file${i}`, file);
    })
    formData.append('payload', JSON.stringify(body));
    this.fileUploadService.uploadFileForDocument(formData)
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(resp => {
      if(resp?.success){
        console.log(resp?.data, 'view estimate');
        this.addedFiles = resp?.data?.files ?? [];
        this.toaster.success(resp?.message);
      }
      else{
        this.toaster.error(resp?.message)
      }
      this.spinner.hide();
    }, (err) => {
      this.toaster.error(err?.error?.message);
      this.spinner.hide();
    })
  }

  saveFiles(files: File[]): void {
    if(files?.length){
      this.spinner.show();
      const body = {
        businessId: this.businessId,
        referenceId: this.viewEstimateDetail?._id,
        documentType: 'Estimate',
        parentDocType: 'Sales'
      }
      const formData = new FormData();
      files?.forEach((file, i) => {
        formData.append(`file${i}`, file);
      })
      formData.append('payload', JSON.stringify(body));
      this.estimateService.uploadFileForEstimate(formData)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(resp => {
        if(resp?.success){
          this.toaster.success(resp?.message);

        }
        else{
          this.toaster.error(resp?.message)
        }
        this.spinner.hide();
      }, (err) => {
        this.toaster.error(err?.error?.message);
        this.spinner.hide();
      })
    }
  }

  ngOnDestroy(): void {
      this.unsubscribe$.next();
      this.unsubscribe$.complete();
  }

}
