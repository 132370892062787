<div class="flex justify-center p-4" inventoryCheck>
    <div class="w-full flex flex-col space-y-4">
        <div>
            <div class="flex items-center justify-between">
                <div class="">
                    <h1 class="text-2xl Gilroy-Bold inline-flex items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 me-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z" />
                          </svg>
                        {{'Complex Products Inventory' | translate}}
                    </h1>
                </div>
                <div class="">
                    <app-button appAccessControl moduleType="inventory" accessType="edit" size='large' buttonText='{{"Create Complex Product" | translate}}' type='primary' routerLink='/inventory/add-bundled-item'></app-button>
                </div>
            </div>
            <div *ngIf="showItemSummaryOption" class="grid grid-cols-4 gap-4 mt-5">
                <div class="bg-gradient rounded-xl p-5 pt-7 pb-7">
                    <p class="Gilroy-bold text-white">{{'Total Retail Value' | translate }}</p>
                    <h3 class="text-2xl font-bold text-white">{{inventoryStats?.retailValue | currency: currencyDetails?.currencySymbol }}</h3>
                    <!-- <p class="text-sm text-white">{{'from' | translate }} {{invoiceStats?.totalInvoice?.count }} {{'invoices' | translate }}</p> -->
                </div>
                <div class="bg-input rounded-xl p-5">
                    <p class="Gilroy-bold">{{'Total Inventory Value' | translate }}</p>
                    <h3 class="text-2xl font-bold">{{inventoryStats?.inventoryValue | currency: currencyDetails?.currencySymbol }}</h3>
                    <!-- <p class="text-sm">{{'from' | translate }} {{invoiceStats?.paidInvoice?.count }} {{'invoices' | translate }}</p> -->
                </div>
                <div class="bg-input rounded-xl p-5">
                    <p class="Gilroy-bold">{{'Expected Profit' | translate }}</p>
                    <h3 class="text-2xl font-bold">{{inventoryStats?.profit | currency: currencyDetails?.currencySymbol }}</h3>
                    <!-- <p class="text-sm">{{'from' | translate }} {{invoiceStats?.overdueInvoice?.count }} {{'invoices' | translate }}</p> -->
                </div>
                <div class="bg-input rounded-xl p-5">
                    <p class="Gilroy-bold">{{'Expected margin' | translate }}</p>
                    <h3 class="text-2xl font-bold">{{inventoryStats?.margin}}%</h3>
                    <!-- <p class="text-sm">{{'from' | translate }} {{invoiceStats?.overdueInvoice?.count }} {{'invoices' | translate }}</p> -->
                </div>
            </div>
            <h3 class="text-xl Gilroy-Bold inline-flex items-center mt-6">{{'Active Filters' | translate}}</h3>
            <div class="flex space-x-2 rtl:space-x-reverse" [formGroup]='filterForm'>
                <div class="">
                    <label class="Gilroy-Bold">{{'Select Store' | translate}}</label>
                    <ng-select [items]="stores"
                    class="custom-selector" 
                    bindLabel="storeName" 
                    bindValue="_id"
                    formControlName='storeId'
                    placeholder='{{"Type Store Name" | translate}}'>
                    </ng-select>
                </div>
                <div class="self-end">
                    <app-button (handleClick)="filterProduct()" *ngIf="!filtered" buttonText='{{"Filter Products" | translate}}' size='large' type='primary' ></app-button>
                    <app-button (handleClick)="filterProduct()" *ngIf="filtered" buttonText='{{"Reset" | translate}}' size='large' type='third' ></app-button>
                </div>
            </div>
            <app-table-container 
                [tableHeadings]='tableHeadings'
                [tableData]='products'
                [keys]='tableKeys'
                [actionTemplate]='actionTemplate'>
                <ng-template let-item #actionTemplate>
                    <td *ngIf="showItemSummaryOption">{{item?.stockDetails?.openingStock || '-'}}</td>
                    <td *ngIf="showItemSummaryOption">
                        {{item?.stockDetails?.openingStock * item?.price || '-'}}
                    </td>
                    <td *ngIf="showItemSummaryOption">
                        {{item?.isReviewed ? 'Reviewed' : 'Need Review' }}
                    </td>
                    <td appAccessControl moduleType="inventory" accessType="edit" align="right" class="p-2 last:pe-10 text-center">
                        <app-dropdown #dropdown>
                            <div button (click)='dropdown.open = true' (clickOutside)='dropdown.open = false'>
                                <div class="h-8 w-8 rounded-full flex justify-center items-center bg-gray-50 border border-gray-200">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 m-auto leading-8 text-center" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                                    </svg>
                                </div>
                            </div>
                            <div options>
                                <!-- <span class="cursor-pointer block px-4 py-2 text-sm Gilroy-Bold hover:bg-gray-50 hover:rounded-xl text-center">{{'View' | translate}}</span> -->
                                <span  *ngIf="showItemSummaryOption" (click)='showItemSummary(item?._id)'  class="cursor-pointer block px-4 py-2 text-sm Gilroy-Bold hover:bg-gray-50 hover:rounded-xl text-center">{{'Item Summary' | translate}}</span>
                                <span (click)='editProduct(item?._id)'  class="cursor-pointer block px-4 py-2 text-sm Gilroy-Bold hover:bg-gray-50 hover:rounded-xl text-center">{{'Edit' | translate}}</span>
                                <span (click)='deleteProduct(item?._id)' class="cursor-pointer block px-4 py-2 text-sm Gilroy-Bold hover:bg-gray-50 hover:rounded-xl text-center">{{'Delete' | translate}}</span>
                            </div>
                        </app-dropdown>             
                    </td>
                </ng-template>
            </app-table-container>
            <!-- <div class="px-5 mt-10">
                <div class="ps-5 flex space-x-2 rtl:space-x-reverse">
                    <div>
                        <input checked id='all' type="radio" name='status' class="peer hidden">
                        <label (click)='filterInvoicesByStatus("all")' for='all' class="peer-checked:border-b-4 pb-1 active-border-invoice px-2 Gilroy-Bold">{{'All Invoices' | translate}}</label>
                    </div>
                    <div>
                        <input id='paid' type="radio" name='status' class="peer hidden">
                        <label (click)='filterInvoicesByStatus("paid")' for='paid' class="peer-checked:border-b-4 pb-1 active-border-invoice px-2 Gilroy-Bold">{{'Paid' | translate}}</label>
                    </div>
                    <div>
                        <input id='unpaid' type="radio" name='status' class="peer hidden">
                        <label (click)='filterInvoicesByStatus("unpaid")' for='unpaid' class="peer-checked:border-b-4 pb-1 active-border-invoice px-2 Gilroy-Bold">{{'Unpaid' | translate}}</label>
                    </div>
                    <div>
                        <input id='draft' type="radio" name='status' class="peer hidden">
                        <label (click)='filterInvoicesByStatus("draft")' for='draft' class="peer-checked:border-b-4 pb-1 active-border-invoice px-2 Gilroy-Bold">{{'Draft' | translate }}</label>
                    </div>
                </div>
                <app-table-container [tableHeadings]='tableHeadings' [tableData]='tableData' [keys]='tableKeys' [actionTemplate]='actionTemplate'>
                    <ng-template let-item #actionTemplate>
                        <td class="p-2 text-center last:pe-10">
                            <app-dropdown #dropdown>
                                <div button (click)='dropdown.open = true' (clickOutside)='dropdown.open = false'>
                                    <div class="h-8 w-8 rounded-full flex justify-center items-center bg-gray-50 border border-gray-200">
                                        <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                                        </svg>
                                    </div>
                                </div>
                                <div options>
                                    <div (click)='viewInvoice(item._id)' class="cursor-pointer px-4 py-2 text-sm Gilroy-Bold hover:bg-gray-50 text-center">{{'View' | translate}}</div>
                                    <div appAccessControl moduleType='sales' accessType='edit' *ngIf='item?.status?.primaryStatus !== "draft"' (click)='openRecordPayment(item)' class="cursor-pointer px-4 py-2 text-sm Gilroy-Bold hover:bg-gray-50 text-center">{{'Record Payment' | translate}}</div>
                                    <div appAccessControl moduleType='sales' accessType='edit' (click)='updateInvoice(item._id)' class="cursor-pointer px-4 py-2 text-sm Gilroy-Bold hover:bg-gray-50 text-center">{{'Edit' | translate}}</div>
                                    <div appAccessControl moduleType='sales' accessType='edit' *ngIf='item?.status?.primaryStatus === "draft"' (click)='deleteInvoice(item._id)' class="cursor-pointer px-4 py-2 text-sm Gilroy-Bold hover:bg-gray-50 text-center">{{'Delete' | translate}}</div>
                                </div>
                            </app-dropdown>             
                        </td>
                    </ng-template>
                </app-table-container>
            </div> -->
        </div>
    </div>
</div>

<!-- <div routeToSubscription fromComponent='inventory' class="hidden absolute bottom-6 z-[9999] w-[400px] left-0 right-0 m-auto h-4/6">
    <app-send-to-sub from="inventory"></app-send-to-sub>
</div> -->

<app-modal-container [isOpen]='showDeleteModal'>
    <div header class="border-b pb-2 Gilroy-Bold">{{'Delete Product' | translate}}</div>
    <div content>{{'Are you sure?' | translate}}</div>
    <div footer class="flex items-center space-x-2 rtl:space-x-reverse">
        <app-button buttonText='{{"Delete" | translate}}' type='danger' rounded=true (handleClick)='confirmDeleteProduct()' ></app-button>
        <app-button buttonText='{{"Cancel" | translate}}' type='third' rounded=true (handleClick)='showDeleteModal = false'></app-button>
    </div>
</app-modal-container>