<div class="expert-application-container">
  <accordion [expandAll]="true" [showArrows]="true">
    <form [formGroup]="expertApplicationForm" enctype="multipart/form-data">
      <accordion-group
        [isOpened]="true"
        (onToggle)="openRow($event, 'personal')"
      >
        <accordion-heading
          [ngClass]="{
            'heading-border': !openedAccordion.includes('personal'),
            heading: openedAccordion.includes('personal')
          }"
          class="ltr:pl-6 rtl:pr-6"
        >
          {{"Personal Information" | translate}}
        </accordion-heading>
        <div class="accordion-body">
          <div class="flex space-x-8 rtl:space-x-reverse">
            <div class="w-1/2">
              <div class="flex items-center space-x-4 rtl:space-x-reverse mb-4">
                <div class="border w-[120px] bg-white rounded-full relative">
                  <img
                    [src]="profilePic"
                    width="120"
                    class="rounded-full"
                    alt="Logo"
                  />
                  <div class="flex flex-col space-y-4">
                    <label class="text-center cursor-pointer" for="file">
                      <input
                        type="file"
                        id="file"
                        accept="image/png, image/jpeg, image/jpg"
                        (change)="fileEvent($event, 'personalInfo')"
                        hidden
                      />
                      <img
                        src="/assets/images/profile-edit.svg"
                        class="absolute top-1.5 ltr:right-1.5 rtl:left-1.5"
                        alt=""
                      />
                      <!-- {{
                        profilePic === "assets/images/avatar.jpg"
                          ? "Add Picture"
                          : ("Update" | translate)
                      }} -->
                    </label>
                    <!-- <app-button
                      *ngIf="profilePic !== 'assets/images/avatar.jpg'"
                      (handleClick)="openDeleteModal = true"
                      buttonText="{{ 'Remove' | translate }}"
                      rounded="true"
                      type="danger"
                    ></app-button> -->
                  </div>
                </div>
                <span
                  class="text-red-500 text-sm"
                  *ngIf="applicationFormErrors?.profileImage"
                  >{{ applicationFormErrors.profileImage }}</span
                >
              </div>
            </div>
          </div>
          <div class="flex w-3/5">
            <div class="rounded-xl py-2 w-[48%]">
              <label
                for="firstName"
                class="block text-xs mb-2 opacity-50 font-color-05"
                >{{ "First Name" | translate }}</label
              >
              <input
                id="firstName"
                class="w-full h-12 rounded-lg p-4 border-0 focus:ring-0 placeholder-gray-200"
                formControlName="firstName"
                type="text"
                placeholder="{{ 'Enter First Name' | translate }}"
              />
              <span
                class="text-red-500 text-sm"
                *ngIf="applicationFormErrors?.firstName"
                >{{ applicationFormErrors.firstName }}</span
              >
            </div>
            <div class="rounded-xl ltr:ml-4 rtl:mr-4 py-2 w-[48%]">
              <label
                for="lastName"
                class="block text-xs mb-2 opacity-50 font-color-05"
                >{{ "Last Name" | translate }}</label
              >
              <input
                id="lastName"
                class="w-full h-12 rounded-lg p-4 border-0 focus:ring-0 placeholder-gray-200"
                formControlName="lastName"
                type="text"
                placeholder="{{ 'Enter Last Name' | translate }}"
              />
              <span
                class="text-red-500 text-sm"
                *ngIf="applicationFormErrors?.lastName"
                >{{ applicationFormErrors.lastName }}</span
              >
            </div>
          </div>
          <div class="mt-4">
            <label
              for="description"
              class="block text-xs mb-2 opacity-50 font-color-05"
              >{{ "Description" | translate }}</label
            >
            <textarea
              tabindex="5"
              id="description"
              formControlName="description"
              name="description"
              class="w-full h-36 bg-white rounded-lg p-4 border-0 focus:ring-0 placeholder-gray-200"
              placeholder="{{ 'Enter Description' | translate }}"
            ></textarea>
            <span
              class="text-red-500 text-sm"
              *ngIf="applicationFormErrors?.description"
              >{{ applicationFormErrors.description }}</span
            >
          </div>
        </div>
      </accordion-group>
      <accordion-group
        (onToggle)="openRow($event, 'contact')"
        [isOpened]="true"
      >
        <accordion-heading
          [ngClass]="{
            'heading-border': !openedAccordion.includes('contact'),
            heading: openedAccordion.includes('contact')
          }"
          class="ltr:pl-6 rtl:pr-6"
        >
          {{"Contacts Information" | translate}}
        </accordion-heading>
        <div class="accordion-body">
          <div class="flex w-[80%]">
            <div class="rounded-xl py-2 w-[48%]">
              <label
                for="contact"
                class="block text-xs mb-2 opacity-50 font-color-05"
                >{{ "Contact Number" | translate }}</label
              >
              <span class="relative block">
                <input
                  id="contact"
                  class="w-full number-fill h-12 rounded-lg p-4 border-0 focus:ring-0 placeholder-gray-200"
                  formControlName="contactNumber"
                  type="number"
                  placeholder="{{ 'Enter Contact Number' | translate }}"
                />
                <span
                  class="text-red-500 text-sm"
                  *ngIf="applicationFormErrors?.contactNumber"
                  >{{ applicationFormErrors.contactNumber }}</span
                >
              </span>
            </div>
            <div class="rounded-xl ltr:ml-4 rtl:mr-4 py-2 w-[48%]">
              <label
                for="email"
                class="block text-xs mb-2 opacity-50 font-color-05"
                >{{ "Email Address" | translate }}</label
              >
              <span class="relative block">
                <img
                  src="/assets/images/locked-icon.svg"
                  class="h-4 absolute ltr:right-2 top-[15px] opacity-40"
                  alt=""
                />
                <input
                  id="email"
                  class="w-full h-12 rounded-lg p-4 border-0 focus:ring-0 placeholder-gray-200"
                  formControlName="email"
                  type="text"
                  placeholder="{{ 'Enter Email Address' | translate }}"
                />
                <span
                  class="text-red-500 text-sm"
                  *ngIf="applicationFormErrors?.email"
                  >{{ applicationFormErrors.email }}</span
                >
              </span>
            </div>
          </div>
          <div class="mt-4">
            <label
              for="address"
              class="block text-xs mb-2 opacity-50 font-color-05"
              >{{ "Address" | translate }}</label
            >
            <span class="relative block">
              <input
                id="address"
                class="w-full h-12 rounded-lg p-4 border-0 focus:ring-0 placeholder-gray-200"
                formControlName="address"
                type="text"
                placeholder="{{ 'Enter Address' | translate }}"
              />
              <span
                class="text-red-500 text-sm"
                *ngIf="applicationFormErrors?.address"
                >{{ applicationFormErrors.address }}</span
              >
            </span>
          </div>
        </div>
      </accordion-group>
      <accordion-group
        (onToggle)="openRow($event, 'qualification')"
        [isOpened]="true"
      >
        <accordion-heading
          [ngClass]="{
            'heading-border': !openedAccordion.includes('qualification'),
            heading: openedAccordion.includes('qualification')
          }"
          class="ltr:pl-6 rtl:pr-6"
        >
          {{"Qualification Details" | translate}}
        </accordion-heading>
        <div class="accordion-body">
          <!-- <div
            class="qualification-details bg-white mb-2 p-4 rounded-lg relative"
          >
            <span
              class="details-edit-circle cursor-pointer absolute top-3 ltr:right-3 rtl:left-3"
            >
              <img
                class="absolute top-1.5 ltr:right-1.5 rtl:left-1.5 -scale-x-100"
                src="/assets/images/details-edit.svg"
                alt=""
              />
            </span>
            <h4 class="Gilroy-Bold text-lg">University Name</h4>
            <p class="text-sm leading-6">Course Name</p>
            <p class="text-sm leading-6">Aug 2015 - Jun 2019</p>
          </div> -->
          <ng-container
            *ngIf="
              expertApplicationForm.get('qualification')?.value.length > 0;
              else noQualification
            "
          >
            <ng-container
              *ngFor="
                let work of expertApplicationForm.get('qualification')?.value;
                let i = index
              "
            >
              <div
                class="qualification-details bg-white p-4 mb-2 rounded-lg relative"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  (click)="removeItem(i, 'qualification')"
                  class="w-6 h-6 cursor-pointer rotate-45 absolute ltr:right-[-10px] rtl:left-[-10px] top-[-10px]"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M12 6v12m6-6H6"
                  />
                </svg>
                <span
                  class="details-edit-circle cursor-pointer absolute top-3 ltr:right-3 rtl:left-3"
                  (click)="editInfo('qualification', i)"
                >
                  <img
                    class="absolute top-1.5 ltr:right-1.5 rtl:left-1.5 -scale-x-100"
                    src="/assets/images/details-edit.svg"
                    alt=""
                  />
                </span>
                <h4 class="Gilroy-Bold text-lg">{{ work.fieldName }}</h4>
                <p class="text-sm leading-6">{{ work.fieldSubName }}</p>
                <p class="text-sm leading-6">
                  {{ work.fromDate }} - {{ work.toDate }}
                </p>
              </div>
            </ng-container>
          </ng-container>
          <ng-template #noQualification>
            <div>{{"No data available" | translate}}</div>
          </ng-template>

          <button
            (click)="openAddMore('qualification')"
            class="text-sm flex items-center mt-2.5"
          >
            <svg
              class="h-3 w-3 ltr:mr-2 rtl:ml-2"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M12 4.5v15m7.5-7.5h-15"
              />
            </svg>
            {{"Add More" | translate}}
          </button>
          <span
            class="text-red-500 text-sm"
            *ngIf="applicationFormErrors?.qualification"
            >{{ applicationFormErrors.qualification }}</span
          >
        </div>
      </accordion-group>
      <accordion-group
        (onToggle)="openRow($event, 'certificate')"
        [isOpened]="true"
      >
        <accordion-heading
          [ngClass]="{
            'heading-border': !openedAccordion.includes('certificate'),
            heading: openedAccordion.includes('certificate')
          }"
          class="ltr:pl-6 rtl:pr-6"
        >
          {{"Certificates" | translate}}
        </accordion-heading>
        <div class="accordion-body">
          <ng-container
            *ngIf="
              expertApplicationForm.get('certificate')?.value.length > 0;
              else noCertificate
            "
          >
            <ng-container
              *ngFor="
                let work of expertApplicationForm.get('certificate')?.value;
                let i = index
              "
            >
              <div
                class="qualification-details bg-white mb-2 p-4 rounded-lg relative"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  (click)="removeItem(i, 'certificate')"
                  class="w-6 h-6 cursor-pointer rotate-45 absolute ltr:right-[-10px] rtl:left-[-10px] top-[-10px]"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M12 6v12m6-6H6"
                  />
                </svg>
                <span
                  class="details-edit-circle cursor-pointer absolute top-3 ltr:right-3 rtl:left-3"
                  (click)="editInfo('certificate', i)"
                >
                  <img
                    class="absolute top-1.5 ltr:right-1.5 rtl:left-1.5 -scale-x-100"
                    src="/assets/images/details-edit.svg"
                    alt=""
                  />
                </span>
                <h4 class="Gilroy-Bold text-lg">{{ work.fieldName }}</h4>
                <p class="text-sm leading-6">{{ work.fieldSubName }}</p>
                <p class="text-sm leading-6">
                  {{ work.fromDate | date : "MMM d, y" }} -
                  {{ work.toDate | date : "MMM d, y" }}
                </p>
              </div>
            </ng-container>
          </ng-container>
          <ng-template #noCertificate>
            <div>{{"No data available" | translate}}</div>
          </ng-template>

          <button
            (click)="openAddMore('certificate')"
            class="text-sm flex items-center mt-2.5"
          >
            <svg
              class="h-3 w-3 ltr:mr-2 rtl:ml-2"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M12 4.5v15m7.5-7.5h-15"
              />
            </svg>
            {{"Add More" | translate}}
          </button>
        </div>
      </accordion-group>
      <accordion-group
        (onToggle)="openRow($event, 'experience')"
        [isOpened]="true"
      >
        <accordion-heading
          [ngClass]="{
            'heading-border': !openedAccordion.includes('experience'),
            heading: openedAccordion.includes('experience')
          }"
          class="ltr:pl-6 rtl:pr-6"
        >
          {{"Experience" | translate}}
        </accordion-heading>
        <div class="accordion-body">
          <!-- <div
            class="qualification-details bg-white mb-2 p-4 rounded-lg relative"
          >
            <span
              class="details-edit-circle cursor-pointer absolute top-3 ltr:right-3 rtl:left-3"
            >
              <img
                class="absolute top-1.5 ltr:right-1.5 rtl:left-1.5 -scale-x-100"
                src="/assets/images/details-edit.svg"
                alt=""
              />
            </span>
            <h4 class="Gilroy-Bold text-lg">Experience Name</h4>
            <p class="text-sm leading-6">Organisation Name</p>
            <p class="text-sm leading-6">Aug 2015 - Jun 2019</p>
          </div> -->
          <ng-container
            *ngIf="
              expertApplicationForm.get('experience')?.value.length > 0;
              else noExperience
            "
          >
            <ng-container
              *ngFor="
                let work of expertApplicationForm.get('experience')?.value;
                let i = index
              "
            >
              <div
                class="qualification-details bg-white mb-2 p-4 rounded-lg relative"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  (click)="removeItem(i, 'experience')"
                  class="w-6 h-6 cursor-pointer rotate-45 absolute ltr:right-[-10px] rtl:left-[-10px] top-[-10px]"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M12 6v12m6-6H6"
                  />
                </svg>
                <span
                  class="details-edit-circle cursor-pointer absolute top-3 ltr:right-3 rtl:left-3"
                  (click)="editInfo('experience', i)"
                >
                  <img
                    class="absolute top-1.5 ltr:right-1.5 rtl:left-1.5 -scale-x-100"
                    src="/assets/images/details-edit.svg"
                    alt=""
                  />
                </span>
                <h4 class="Gilroy-Bold text-lg">{{ work.fieldName }}</h4>
                <p class="text-sm leading-6">{{ work.fieldSubName }}</p>
                <p class="text-sm leading-6">
                  {{ work.fromDate }} - {{ work.toDate }}
                </p>
              </div>
            </ng-container>
          </ng-container>
          <ng-template #noExperience>
            <div>{{"No data available" | translate}}</div>
          </ng-template>
          <button
            (click)="openAddMore('experience')"
            class="text-sm flex items-center mt-2.5"
          >
            <svg
              class="h-3 w-3 ltr:mr-2 rtl:ml-2"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M12 4.5v15m7.5-7.5h-15"
              />
            </svg>
            {{"Add More"| translate}}
          </button>
        </div>
      </accordion-group>
      <accordion-group
        (onToggle)="openRow($event, 'corporate')"
        [isOpened]="true"
      >
        <accordion-heading
          [ngClass]="{
            'heading-border': !openedAccordion.includes('corporate'),
            heading: openedAccordion.includes('corporate')
          }"
          class="ltr:pl-6 rtl:pr-6"
        >
          {{"Corporate Information" | translate}}
        </accordion-heading>
        <div class="accordion-body">
          <div class="flex space-x-8 rtl:space-x-reverse">
            <div class="w-1/2">
              <div class="flex items-center space-x-4 rtl:space-x-reverse mb-4">
                <div class="border w-[120px] bg-white rounded-full relative">
                  <img
                    [src]="companyImage"
                    width="120"
                    class="rounded-full"
                    alt="Logo"
                  />
                  <div class="flex flex-col space-y-4">
                    <label class="text-center cursor-pointer" for="companyFile">
                      <input
                        type="file"
                        id="companyFile"
                        accept="image/png, image/jpeg, image/jpg"
                        (change)="fileEvent($event, 'companyInfo')"
                        hidden
                      />
                      <img
                        src="/assets/images/profile-edit.svg"
                        class="absolute top-1.5 ltr:right-1.5 rtl:left-1.5"
                        alt=""
                      />
                    </label>
                  </div>
                </div>
                <span
                  class="text-red-500 text-sm"
                  *ngIf="applicationFormErrors?.companyImage"
                  >{{ applicationFormErrors.companyImage }}</span
                >
              </div>
            </div>
          </div>
          <div class="flex">
            <div class="rounded-xl w-[43%] py-2">
              <label
                for="companyName"
                class="block text-xs mb-2 opacity-50 font-color-05"
                >{{ "Company Name" | translate }}</label
              >
              <input
                id="companyName"
                class="w-full h-12 rounded-lg p-4 border-0 focus:ring-0 placeholder-gray-200"
                formControlName="companyName"
                type="text"
                placeholder="{{ 'Enter Company Name' | translate }}"
              />
              <span
                class="text-red-500 text-sm"
                *ngIf="applicationFormErrors?.companyName"
                >{{ applicationFormErrors.companyName }}</span
              >
            </div>
          </div>
          <div class="mt-4">
            <label
              for="aboutCompany"
              class="block text-xs mb-2 opacity-50 font-color-05"
              >{{ "Description" | translate }}</label
            >
            <textarea
              tabindex="5"
              id="aboutCompany"
              formControlName="aboutCompany"
              name="aboutCompany"
              class="w-full h-36 bg-white rounded-lg p-4 border-0 focus:ring-0 placeholder-gray-200"
              placeholder="{{ 'Enter About' | translate }}"
            ></textarea>
            <span
              class="text-red-500 text-sm"
              *ngIf="applicationFormErrors?.aboutCompany"
              >{{ applicationFormErrors.aboutCompany }}</span
            >
          </div>
        </div>
      </accordion-group>
      <accordion-group
        (onToggle)="openRow($event, 'specialization')"
        [isOpened]="true"
      >
        <accordion-heading
          [ngClass]="{
            'heading-border': !openedAccordion.includes('specialization'),
            heading: openedAccordion.includes('specialization')
          }"
          class="ltr:pl-6 rtl:pr-6"
        >
          {{"Specializations" | translate}}
        </accordion-heading>
        <div class="accordion-body">
          <div class="flex gap-3">
            <!-- <div
              class="w-[120px] h-11 rounded-xl bg-white flex items-center justify-center text-sm Gilroy-Bold"
            >
              Sales
            </div> -->
            <ng-container
              *ngIf="
                expertApplicationForm.get('specialization')?.value.length > 0;
                else noSpecialization
              "
            ></ng-container>
            <ng-template #noSpecialization>
              <div>{{"No data available" | translate}}</div>
            </ng-template>
            <ng-container
              *ngFor="
                let card of expertApplicationForm.get('specialization')?.value;
                let i = index
              "
            >
              <div
                class="w-[120px] relative h-11 rounded-xl bg-white flex items-center justify-center text-sm Gilroy-Bold"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  (click)="removeItem(i, 'specialization')"
                  class="w-6 h-6 cursor-pointer rotate-45 absolute ltr:right-[-10px] rtl:left-[-10px] top-[-10px]"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M12 6v12m6-6H6"
                  />
                </svg>

                {{ card.name }}
              </div>
            </ng-container>
          </div>

          <button
            (click)="addMoreCard('specialization')"
            class="text-sm flex items-center mt-2.5"
          >
            <svg
              class="h-3 w-3 ltr:mr-2 rtl:ml-2"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M12 4.5v15m7.5-7.5h-15"
              />
            </svg>
            {{"Add More" | translate}}
          </button>
          <span
            class="text-red-500 text-sm"
            *ngIf="applicationFormErrors?.specialization"
            >{{ applicationFormErrors.specialization }}</span
          >
        </div>
      </accordion-group>
      <accordion-group
        (onToggle)="openRow($event, 'language')"
        [isOpened]="true"
      >
        <accordion-heading
          [ngClass]="{
            'heading-border': !openedAccordion.includes('language'),
            heading: openedAccordion.includes('language')
          }"
          class="ltr:pl-6 rtl:pr-6"
        >
          {{"Language" | translate}}
        </accordion-heading>
        <div class="accordion-body">
          <div class="flex gap-3">
            <!-- <div
              class="w-[120px] h-11 rounded-xl bg-white flex items-center justify-center text-sm Gilroy-Bold"
            >
              Arabic
            </div> -->
            <ng-container
              *ngIf="
                expertApplicationForm.get('language')?.value.length > 0;
                else noLanguage
              "
            >
              <ng-container
                *ngFor="
                  let card of expertApplicationForm.get('language')?.value;
                  let i = index
                "
              >
                <div
                  class="w-[120px] h-11 relative rounded-xl bg-white flex items-center justify-center text-sm Gilroy-Bold"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    (click)="removeItem(i, 'language')"
                    class="w-6 h-6 cursor-pointer rotate-45 absolute ltr:right-[-10px] rtl:left-[-10px] top-[-10px]"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M12 6v12m6-6H6"
                    />
                  </svg>
                  {{ card.name }}
                </div>
              </ng-container>
            </ng-container>
            <ng-template #noLanguage>
              <div>{{"No data available" | translate}}</div>
            </ng-template>
          </div>
          <button
            (click)="addMoreCard('language')"
            class="text-sm flex items-center mt-2.5"
          >
            <svg
              class="h-3 w-3 ltr:mr-2 rtl:ml-2"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M12 4.5v15m7.5-7.5h-15"
              />
            </svg>
            {{"Add More" | translate}}
          </button>
          <span
            class="text-red-500 text-sm"
            *ngIf="applicationFormErrors?.language"
            >{{ applicationFormErrors.language }}</span
          >
        </div>
      </accordion-group>
      <app-button
        class="w-64 h-12 rounded-lg Gilroy-Medium"
        type="primary"
        buttonText="{{ 'Submit Application' | translate }}"
        (click)="submitApplication()"
      ></app-button>
    </form>
  </accordion>

  <app-image-cropper
    [isOpen]="openModal"
    [imageCropperConfig]="imageCropperConfig"
    (closeImage)="openModal = false"
    (base64Image)="saveImage($event)"
  >
  </app-image-cropper>

  <app-modal-container [isOpen]="openAddMoreModal">
    <div header class="border-b pb-2 Gilroy-ExtraBold text-xl">
      {{ "Add More" | translate }}
    </div>
    <div content>
      <div class="w-full" [formGroup]="addMoreForm">
        <div class="rounded-xl py-2">
          <label
            for="fieldName"
            class="block text-xs mb-2 opacity-50 font-color-05"
            >{{ modalFieldName + " name" | titlecase | translate }}</label
          >
          <input
            id="fieldName"
            formControlName="fieldName"
            class="w-full h-12 rounded-lg p-4 border-[1px] border-[#707070] focus:ring-0 placeholder-gray-600"
            type="text"
            placeholder="{{ 'Enter ' + modalFieldName + ' name' | translate }}"
          />
          <span
            class="text-red-500 text-sm"
            *ngIf="addMoreFormErrors?.fieldName"
            >{{ addMoreFormErrors.fieldName }}</span
          >
        </div>
        <div class="rounded-xl py-2">
          <label
            for="fieldSubName"
            class="block text-xs mb-2 opacity-50 font-color-05"
            >{{ modalSubFieldName + " name" | titlecase | translate }}</label
          >
          <input
            id="fieldSubName"
            formControlName="fieldSubName"
            class="w-full h-12 rounded-lg p-4 border-[1px] border-[#707070] focus:ring-0 placeholder-gray-600"
            type="text"
            placeholder="{{
              'Enter ' + modalSubFieldName + ' name' | translate
            }}"
          />
          <span
            class="text-red-500 text-sm"
            *ngIf="addMoreFormErrors?.fieldSubName"
            >{{ addMoreFormErrors.fieldSubName }}</span
          >
          <div class="py-4">
            <fieldset class="flex gap-20" [disabled]="false">
              <div class="w-2/5">
                <label
                  for="fromDate"
                  class="block text-xs mb-2 opacity-50 font-color-05"
                  >{{ "From" | translate }}</label
                >
                <input
                  id="fromDate"
                  type="date"
                  formControlName="fromDate"
                  (change)="change($event)"
                  class="w-full h-12 rounded-lg p-4 border-[1px] border-[#707070] focus:ring-0 placeholder-gray-600"
                />
                <span
                  class="text-red-500 text-sm"
                  *ngIf="addMoreFormErrors?.fromDate"
                  >{{ addMoreFormErrors.fromDate }}</span
                >
              </div>
              <div class="w-2/5">
                <label
                  for="toDate"
                  class="block text-xs mb-2 opacity-50 font-color-05"
                  >{{ "To" | translate }}</label
                >
                <input
                  id="toDate"
                  type="date"
                  formControlName="toDate"
                  class="w-full h-12 rounded-lg p-4 border-[1px] border-[#707070] focus:ring-0 placeholder-gray-600"
                />
                <span
                  class="text-red-500 text-sm"
                  *ngIf="addMoreFormErrors?.toDate"
                  >{{ addMoreFormErrors.toDate }}</span
                >
              </div>
            </fieldset>
          </div>
        </div>
      </div>
    </div>
    <div footer class="flex items-center space-x-2 rtl:space-x-reverse">
      <app-button
        buttonText="{{ 'Save' | translate }}"
        type="primary"
        rounded="true"
        (handleClick)="saveMoreInfo()"
      ></app-button>
      <app-button
        buttonText="{{ 'Cancel' | translate }}"
        type="third"
        rounded="true"
        (handleClick)="closeModal('detail')"
      ></app-button>
    </div>
  </app-modal-container>

  <app-modal-container class="details-content" [isOpen]="openCardModal">
    <div header class="border-b pb-2 Gilroy-ExtraBold text-xl">
      {{ "Add More" | translate }}
    </div>
    <div content>
      <div class="w-full">
        <div class="rounded-xl py-2">
          <ng-container
          *ngIf="cardModalField === 'specialization'; else addLanguage"
        >
          <app-dropdown #dropdown>
            <span
              button
              (click)="dropdown.open = true"
              (clickOutside)="dropdown.open = false"
              class="flex opacity-40 justify-between w-full px-3 py-4 border border-[#707070] rounded-lg items-center gap-1"
            >
              {{ cardFieldName ? cardFieldName : "Select type" }}
              <img
                src="/assets/images/arrow-up.svg"
                class="rotate-180 w-2 h-2"
                alt=""
              />
            </span>
            <div class="ml-6 py-4" options>
              <ul class="flex flex-col gap-3">
                <ng-container *ngFor="let item of specializationList">
                  <li
                    (click)="selectExpertType(item)"
                    class="text-xs cursor-pointer items-center"
                  >
                    {{item | translate}}
                  </li>
                </ng-container>
              </ul>
            </div>
          </app-dropdown>
        </ng-container>
        <ng-template #addLanguage>
          <input
            id="cardField"
            [(ngModel)]="cardFieldName"
            class="w-full h-12 rounded-lg p-4 border-[1px] border-[#707070] focus:ring-0 placeholder-gray-600"
            type="text"
            placeholder="{{ 'Enter ' + cardModalField | translate }}"
        /></ng-template>
          <span class="text-red-500 text-sm" *ngIf="fieldError"
            >{{"Field is required" | translate}}</span
          >
        </div>
      </div>
    </div>
    <div footer class="flex items-center space-x-2 rtl:space-x-reverse">
      <app-button
        buttonText="{{ 'Save' | translate }}"
        type="primary"
        rounded="true"
        (handleClick)="saveMoreCard()"
      ></app-button>
      <app-button
        buttonText="{{ 'Cancel' | translate }}"
        type="third"
        rounded="true"
        (handleClick)="closeModal('card')"
      ></app-button>
    </div>
  </app-modal-container>
</div>
