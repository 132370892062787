import { CurrencyPipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NumberService {

  constructor(private currenyPipe: CurrencyPipe) { }

  decimalSize = new BehaviorSubject(2);
  currency = new BehaviorSubject('INR');
  number = new BehaviorSubject('1.2-2');

  toFixed(num: (string | Number)):number {
    return Number((Number(num)).toFixed(this.decimalSize.value));
  }

  currencier(amount): string {
    return this.currenyPipe.transform(amount, this.currency.value, 'symbol-narrow', `1.${this.decimalSize.value}-${this.decimalSize.value}`)
  }
}
