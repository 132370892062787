<div class="w-full flex flex-col space-y-4">
    <div class="flex items-center ps-5 pt-4 pr-7 justify-between">
        <div class="">
            <h1 class="text-2xl Gilroy-Bold inline-flex items-center">
                <svg _ngcontent-hbd-c18="" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                    stroke="currentColor" class="h-6 w-6 me-2">
                    <path _ngcontent-hbd-c18="" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="M16 8v8m-4-5v5m-4-2v2m-2 4h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z">
                    </path>
                </svg>
                {{'Recurring JV' | translate}}
            </h1>
        </div>
    </div>

    <form [formGroup]='journalEntryForm'>
        <my-tabs>
            <div class="flex justify-between mb-4">
                <div class="flex space-x-2 ms-6">
                    <div class="bg-proile rounded-xl bg-white px-3 py-2 ">
                        <fieldset [disabled]='transaction?.type !== "journal voucher"'>
                            <label for="startDate" class="block text-sm Gilroy-Bold bg-transparent font-color-05">{{'Start Date' |
                                translate}}</label>
                            <input id='startDate' [min]="minDate" formControlName="startDate" type="date"
                                class="w-full p-0 border-0 focus:ring-0">
                            <div *ngIf='formErrors.startDate' class="text-red-500 ms-4">
                                {{formErrors.startDate}}</div>
                        </fieldset>
                    </div>
                    <div class="bg-proile rounded-xl bg-white px-3 py-2 ">
                        <fieldset [disabled]='transaction?.type !== "journal voucher"'>
                            <label for="endDate" class="block text-sm Gilroy-Bold bg-transparent font-color-05">{{'End Date' |
                                translate}}</label>
                            <input id='endDate' [min]="journalEntryForm.get('startDate').value" formControlName="endDate" type="date"
                                class="w-full p-0 border-0 focus:ring-0">
                            <div *ngIf='formErrors.endDate' class="text-red-500 ms-4">
                                {{formErrors.endDate}}</div>
                        </fieldset>
                    </div>
                    <div class="flex flex-col w-1/4">
                        <label class="Gilroy-Bold">{{'Frequency' | translate}}</label>
                        <fieldset >
                          <ng-select [items]="frequency"
                              class="custom-selector"
                              formControlName="frequency"
                              [clearable]="false"
                              placeholder='{{"Select Frequency" | translate}}'>
                          </ng-select>
                          <div class="text-red-500" *ngIf='formErrors.frequency'>{{formErrors.frequency}}</div> 
                          <!-- <input formControlName='purchaseOrder' type="text" class="bg-input w-full rounded-lg border-0 h-11 " /> -->
                        </fieldset>
                      </div>
                    <div class="bg-proile rounded-xl bg-white px-3 py-2 w-96 ms-5">
                        <label for="description"
                            class="block text-sm Gilroy-Bold bg-transparent font-color-05">{{'Description' |
                            translate}}</label>
                        <input id='description' formControlName='description'
                            placeholder="{{'Enter Description' | translate }}" type="text"
                            class="w-full p-0 border-0 bg-transparent focus:ring-0">
                    </div>
                </div>
            </div>
            <my-tab tabTitle='{{"Details" | translate}}'>
                <div class="ps-5 pe-7">
                    <table class="w-full">
                        <tr>
                            <td class="p-3 bg-input Gilroy-Bold rounded-s-xl w-1/12">{{'Type' | translate}}</td>
                            <td class="p-3 bg-input Gilroy-Bold w-3/12">{{'Description' | translate}}</td>
                            <td class="p-3 bg-input Gilroy-Bold">{{'Account' | translate}}</td>
                            <td class="p-3 bg-input Gilroy-Bold w-2/12">{{'Debit' | translate}}</td>
                            <td class="p-3 bg-input Gilroy-Bold w-2/12  last:rounded-e-xl ">{{'Credit' | translate}}
                            </td>
                            <td *ngIf='transaction?.type !== "journal voucher"'
                                class="p-3 bg-input Gilroy-Bold w-1/12 last:rounded-e-xl "></td>
                        </tr>
                        <ng-container formArrayName='journal'>
                            <ng-container *ngFor='let control of journal.controls; let i = index;'>
                                <tr [formGroup]='control' class="">
                                    <td class="w-2/12 p-2">
                                        <ng-select [items]="['Credit', 'Debit']" class="custom-selector"
                                            formControlName="type" placeholder='{{"Select one" | translate }}'
                                            [searchable]='false' [clearable]='false'
                                            [readonly]='transaction?.type !== "journal voucher"'
                                            (change)='changeType($event, i)'>
                                        </ng-select>
                                    </td>
                                    <td class="w-3/12 p-2">
                                        <input formControlName='description' type="text"
                                            class="w-full p-3 rounded-xl bg-input border-0" />
                                    </td>
                                    <td class="p-2">
                                        <ng-select [items]="accounts" class="custom-selector" bindLabel="accountName"
                                            formControlName="account" placeholder='{{"Select one" | translate }}'
                                            groupBy='accountType' [readonly]='transaction?.type !== "journal voucher"'>
                                        </ng-select>
                                    </td>
                                    <td class="p-2 w-2/12">
                                        <fieldset [disabled]='transaction?.type !== "journal voucher"'>
                                            <input [ngClass]='control.value.type==="Credit" ? "hidden":"block"'
                                                formControlName='debit' type="number"
                                                class="w-full p-3 rounded-xl bg-input border-0" />
                                        </fieldset>
                                    </td>
                                    <td class="p-2 w-2/12">
                                        <fieldset [disabled]='transaction?.type !== "journal voucher"'>
                                            <input [hidden]='control.value.type==="Debit"' formControlName='credit'
                                                type="number" class="w-full p-3 rounded-xl bg-input border-0" />
                                        </fieldset>
                                    </td>
                                    <td *ngIf='transaction?.type === "journal voucher"' class="p-2 w-1/12">
                                        <svg (click)='removeLine(i)' xmlns="http://www.w3.org/2000/svg" name='delete'
                                            class="h-5 w-5 text-red-500" fill="none" viewBox="0 0 24 24"
                                            stroke="currentColor">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                                d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                        </svg>
                                    </td>
                                </tr>

                            </ng-container>
                            <ng-container *ngIf='journal.controls.length === 0'>
                                <tr>
                                    <td colspan="7" class="text-center p-2">{{'No items added' | translate}}</td>
                                </tr>
                            </ng-container>
                        </ng-container>
                        <tr>
                            <td vAlign='top' colspan="3">
                                <div *ngIf='transaction?.type === "journal voucher" && journal.controls.length !== 20 '
                                    class="ps-2">
                                    <button (click)='addLine()' id="add"
                                        class="flex items-center Gilroy-Bold space-x-4">
                                        <span class="rounded-full bg-gradient text-white p-1 me-2">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                                stroke="currentColor" class="h-4 w-4">
                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                                    d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path>
                                            </svg>
                                        </span> {{'Add a Line' | translate}}
                                    </button>
                                </div>
                            </td>
                            <td colspan="3" class="p-3 bg-input rounded-xl">
                                <div class="flex justify-between py-2">
                                    <p class="">{{'Total Debits' | translate}}</p>
                                    <p> </p>
                                    <p class="">{{'Total Credits' | translate}}</p>
                                </div>
                                <div class="flex justify-between">
                                    <p class="text-lg Gilroy-Bold">{{debitTotal | currency: currencyDetails?.currency :
                                        'symbol-narrow' : number}}</p>
                                    <p>{{ creditTotal === debitTotal ? "=" : "≠"}}</p>
                                    <p class="text-lg Gilroy-Bold">{{creditTotal | currency: currencyDetails?.currency :
                                        'symbol-narrow' : number}}</p>
                                </div>
                                <div class="flex justify-center">
                                    <p> <span> {{'Difference' | translate}} </span> = <span
                                            [ngClass]='difference === 0 ? "text-green-600": "text-red-600"'>{{difference
                                            | currency: currencyDetails?.currency : 'symbol-narrow' : number}}</span>
                                    </p>
                                </div>
                            </td>
                        </tr>
                    </table>
                </div>
            </my-tab>
            <my-tab tabTitle='{{"Notes" | translate }}'>
                <div class="px-6">
                    <textarea name="notes" id="notes" formControlName='notes'
                        placeholder="{{'Write notes here' | translate }}"
                        class='w-full border-0 focus:ring-0 bg-input rounded-xl' cols="30" rows="11"></textarea>
                </div>
            </my-tab>
            <!-- <my-tab appAccessControl moduleType="storage" accessType="edit" tabTitle="{{'Files' | translate }}">
                <app-file-upload (emitter)="saveFiles($event)" ></app-file-upload>
            </my-tab> -->
        </my-tabs>


    </form>
    <div class="flex items-center px-8 border-t py-4">
        <app-button buttonText='{{"Cancel" | translate }}' type='third' routerLink='/accounting/transaction'>
        </app-button>
        <div class="ms-auto flex items-center">
            <span *ngIf='issuedBy' class="me-2">
                <span class="Gilroy-Bold me-2">{{'Issued By' | translate }} -</span>
                <span>{{issuedBy}}</span>
            </span>
            <app-tooltip customClass='mb-10' class="me-4" tooltipName='{{"Mark as reviewed" | translate }}'
                *ngIf='update && !transaction?.isReviewed'>
                <svg xmlns="http://www.w3.org/2000/svg" (click)='reviewTransaction()'
                    class="ml-auto h-10 w-10 text-gray-500 cursor-pointer" fill="none" viewBox="0 0 24 24"
                    stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
            </app-tooltip>
            <app-tooltip customClass='mb-10' class="me-4" tooltipName='{{"Reviewed" | translate }}'
                *ngIf='update && transaction?.isReviewed'>
                <svg xmlns="http://www.w3.org/2000/svg" (click)='reviewTransaction()'
                    class="ml-auto h-10 w-10 text-green-500 cursor-pointer" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clip-rule="evenodd" />
                </svg>
            </app-tooltip>
            <app-button buttonText='{{"Save" | translate }}' [disabled]='!enableSave' (handleClick)='saveJournal()'
                type='primary'></app-button>
        </div>
    </div>
</div>