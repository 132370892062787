<div class="flex min-h-screen">
    <div appFeatureCheck feature='reports'>
        <div class=" w-full flex flex-col space-y-4">
            <div class="flex items-center pl-5 pt-4 pr-7 justify-between">
                <div class="">
                    <h1 class="text-2xl Gilroy-Bold inline-flex items-center">
                        <svg _ngcontent-hbd-c18="" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                            stroke="currentColor" class="h-6 w-6 me-2">
                            <path _ngcontent-hbd-c18="" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                d="M16 8v8m-4-5v5m-4-2v2m-2 4h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z">
                            </path>
                        </svg>
                        {{'Reports' | translate }}
                    </h1>
                </div>

            </div>
            <div class="w-full">
                <div *ngFor='let section of sections' class="rounded-2xl bg-input p-5 mb-5 mx-5">
                    <div class="flex">
                        <div class="flex-1 me-4">
                            <h3 class="Gilroy-Bold text-lg">{{section.name | translate}}</h3>
                            <p class="Gilroy-Medium text-black">{{section.summary | translate }}</p>
                        </div>
                        <div class="flex-1">
                            <div *ngFor="let subSection of section.subSection; let last = last"
                                [ngClass]="last ? '' : 'border-b-2 pb-2 mb-2'" class="flex justify-between">
                                <div>
                                    <p class="color-blue Gilroy-Bold text-xl">{{subSection.name | translate }}
                                    </p>
                                    <p class="Gilroy-Medium text-black">{{subSection.summary | translate }}</p>
                                </div>
                                <div class="self-center ps-2">
                                    <div (click)="viewReport(subSection)"
                                        class="h-8 w-8 rounded-full flex justify-center items-center color-blue bg-gray-50 border border-gray-200 cursor-pointer">
                                        <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4"
                                            [ngClass]='{"rotate-180": direction === "ar"}' fill="none"
                                            viewBox="0 0 24 24" stroke="currentColor">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                                d="M9 5l7 7-7 7" />
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- <div routeToSubscription fromComponent='reports' class="absolute bottom-6 z-[9999] w-[400px] left-0 right-0 m-auto">
    <app-send-to-sub></app-send-to-sub>
</div> -->