<div class="min-h-screen p-4 max-w-[1440px] mx-auto">
    <div class="mb-6 flex justify-between">
        <a routerLink="/"><img src="assets/images/logo.png" width="150" alt="logo"></a>
        <app-button buttonText='Logout' (handleClick)='logout()' type='border' rounded='true'></app-button>
    </div>
    <div class="text-3xl mb-6 Gilroy-Bold">{{'Choose the plan which suits you' | translate}}</div>
    <div class="flex mobilescreen">
        <div [ngClass]="{'order-1':plan?.planName === 'Starter', 'order-2': plan?.planName === 'Essential', 'order-3': plan?.planName === 'Essential Plus','order-4': plan?.planName === 'Retail','order-5': plan?.planName === 'Retail Plus'}" *ngFor='let plan of subscriptionPlans   let i=index' class="w-1/3 gap-3 mobileWidth">

            <div *ngIf="plan?.planName === 'Essential Plus'">
                <div class="pb-4 bg-white shadow-xl rounded-xl h-full overflow-hidden w-[233px] mobileWidth">
                    <div class="bg-blue-100 h-1/3 max-h-[33.33%] rounded-t-xl overflow-hidden  pb-2 pt-0 ">
                        <div class="bg-gradient text-center py-1 text-white text-sm font-bold GilroyBold">{{'Everything you need' | translate}}</div>
                        <div class="px-4 flex flex-col justify-center">
                            <!-- <img src="assets/images/Group 4653.svg" class="w-full" > -->
                            <p class="text-white text-sm px-2 py-1 w-fit bg-gradient rounded-md font-bold GilroyBold mt-3">{{plan?.planName}}</p>
                            <!-- </div> -->
                            <!-- <p class="Gilroy-Bold text-lg">{{plan?.planName}}</p> -->
                            <div class="flex space-x-2  py-5">
                                <p class="text-xl pt-2 text-black Gilroy-Bold">$</p>
                                <p class="text-[50px] text-black Gilroy-Bold">{{plan?.planPrice | translate}}</p>
                                <p class="text-sm pt-2 text-gray-400">/ {{plan?.planDuration | translate}}</p>
                            </div>
                        </div>
                    </div>
                    <!-- <hr class="border-2 mt-2 mb-2" /> -->
                    <ol class="space-y-4 list-disc ms-4 p-6">
                        <li class="flex list-item items-center space-x-2 rtl:space-x-reverse ">

                            <p class="text-sm Gilroy-Bold">{{plan?.features?.transactionLimit | translate}} {{'Transaction Limit.' | translate}}</p>
                        </li>
                        <li class="flex list-item items-center space-x-2 rtl:space-x-reverse ">

                            <p class="text-sm Gilroy-Bold">{{plan?.features?.adminLimit | translate}} {{'Admin.' | translate }}</p>
                        </li>
                        <li class="flex list-item items-center space-x-2 rtl:space-x-reverse ">

                            <p class="text-sm Gilroy-Bold">{{plan?.features?.supportEmail | translate}} {{'Support.' | translate}}</p>
                        </li>
                        <li class="flex list-item items-center space-x-2 rtl:space-x-reverse ">

                            <p class="text-sm Gilroy-Bold">{{'Single Business Account.' | translate}}</p>
                        </li>
                        <li class="flex list-item items-center space-x-2 rtl:space-x-reverse ">

                            <p class="text-sm Gilroy-Bold">{{'Powerful reports on Overdue Invoices & Bills, Cash Flow and Profit & Loss.' | translate}}</p>
                        </li>
                    </ol>
                    <div class="mt-4 flex justify-center">
                        <div #buyButtons>
                            <app-button buttonText='{{"Buy this" | translate }}' type='primary' rounded='full'
                                (handleClick)='subscribePlan(plan)'></app-button>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="plan?.planName === 'Retail'">
                <div class="pb-4 bg-white shadow-xl rounded-xl h-full overflow-hidden w-[233px] mobileWidth">
                    <div class="bg-blue-100 h-1/3 max-h-[33.33%] rounded-t-xl overflow-hidden  pb-2 pt-0 ">
                        <div class="bg-gradient text-center py-1 text-white text-sm font-bold GilroyBold">{{'Everything you need' | translate}}</div>
                        <div class="px-4">
                            <!-- <img src="assets/images/Group 4653.svg" class="w-full" > -->
                            <p class="text-white text-sm px-2 py-1 w-fit bg-gradient rounded-md font-bold GilroyBold mt-3">{{plan?.planName }}</p>
                            <!-- </div> -->
                            <!-- <p class="Gilroy-Bold text-lg">{{plan?.planName}}</p> -->
                            <div class="flex space-x-2  py-5">
                                <p class="text-xl pt-2 text-black Gilroy-Bold">$</p>
                                <p class="text-[50px] text-black Gilroy-Bold">{{plan?.planPrice | translate }}</p>
                                <p class="text-sm pt-2 text-gray-400">/ {{plan?.planDuration | translate }}</p>
                            </div>
                        </div>
                    </div>
                    <!-- <hr class="border-2 mt-2 mb-2" /> -->
                    <ol class="space-y-4 list-disc ms-4 p-6">
                        <li class="flex list-item items-center space-x-2 rtl:space-x-reverse ">

                            <p class="text-sm Gilroy-Bold">{{plan?.features?.transactionLimit | translate }} {{'Transaction Limit.' | translate }}</p>
                        </li>
                        <li class="flex list-item items-center space-x-2 rtl:space-x-reverse ">

                            <p class="text-sm Gilroy-Bold">{{plan?.features?.adminLimit | translate }} {{'Admin.' | translate }}</p>
                        </li>
                        <li class="flex list-item items-center space-x-2 rtl:space-x-reverse ">

                            <p class="text-sm Gilroy-Bold">{{plan?.features?.supportEmail | translate }} {{'Support.' | translate }}</p>
                        </li>
                        <li class="flex list-item items-center space-x-2 rtl:space-x-reverse ">

                            <p class="text-sm Gilroy-Bold">{{'Single Business Account.' | translate }}</p>
                        </li>
                        <li class="flex list-item items-center space-x-2 rtl:space-x-reverse ">

                            <p class="text-sm Gilroy-Bold">{{'Powerful reports on Overdue Invoices & Bills, Cash Flow and Profit & Loss.' | translate }}</p>
                        </li>
                    </ol>
                    <div class="mt-4 flex justify-center">
                        <div #buyButtons>
                            <app-button buttonText='{{"Buy this" | translate }}' type='primary' rounded='full'
                                (handleClick)='subscribePlan(plan)'></app-button>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="plan?.planName === 'Retail Plus'">
                <div class="bg-white shadow-xl rounded-xl h-full pb-4 overflow-hidden w-[233px] mobileWidth">
                    <div class="bg-blue-100 h-1/3 max-h-[33.33%] rounded-t-xl overflow-hidden  pb-2 pt-0 ">
                        <div class="bg-gradient text-center py-1 text-white text-sm font-bold GilroyBold">{{'Everything you need' | translate }}</div>
                        <div class="px-4">
                            <!-- <img src="assets/images/Group 4653.svg" class="w-full" > -->
                            <p class="text-white text-sm px-2 py-1 w-fit bg-gradient rounded-md font-bold GilroyBold mt-3">{{plan?.planName }}</p>
                            <!-- </div> -->
                            <!-- <p class="Gilroy-Bold text-lg">{{plan?.planName}}</p> -->
                            <div class="flex space-x-2  py-5">
                                <p class="text-xl pt-2 text-black Gilroy-Bold">$</p>
                                <p class="text-[50px] text-black Gilroy-Bold">{{plan?.planPrice | translate }}</p>
                                <p class="text-sm pt-2 text-gray-400">/ {{plan?.planDuration | translate }}</p>
                            </div>
                        </div>
                    </div>
                    <!-- <hr class="border-2 mt-2 mb-2" /> -->
                    <ol class="space-y-4 list-disc ms-4 p-6">
                        <li class="flex list-item items-center space-x-2 rtl:space-x-reverse ">

                            <p class="text-sm Gilroy-Bold">{{plan?.features?.transactionLimit | translate }} {{'Transaction Limit.' | translate }}</p>
                        </li>
                        <li class="flex list-item items-center space-x-2 rtl:space-x-reverse ">

                            <p class="text-sm Gilroy-Bold">{{plan?.features?.adminLimit | translate }} {{'Admin.' | translate }}</p>
                        </li>
                        <li class="flex list-item items-center space-x-2 rtl:space-x-reverse ">

                            <p class="text-sm Gilroy-Bold">{{plan?.features?.supportEmail | translate }} {{'Support.' | translate }}</p>
                        </li>
                        <li class="flex list-item items-center space-x-2 rtl:space-x-reverse ">

                            <p class="text-sm Gilroy-Bold">{{'Single Business Account.' | translate }}</p>
                        </li>
                        <li class="flex list-item items-center space-x-2 rtl:space-x-reverse ">

                            <p class="text-sm Gilroy-Bold">{{'Powerful reports on Overdue Invoices & Bills, Cash Flow and Profit & Loss.' | translate }}</p>
                        </li>
                    </ol>
                    <div class="mt-4 flex justify-center">
                        <div #buyButtons>
                            <app-button buttonText='{{"Buy this" | translate }}' type='primary' rounded='full'
                                (handleClick)='subscribePlan(plan)'></app-button>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="plan?.planName === 'Starter'">
                <div class="pb-4 rounded-xl bg-white shadow-xl h-full overflow-hidden w-[233px] mobileWidth">
                    <div class="bg-gradient h-1/3 max-h-[33.33%] px-6 py-4 rounded-t-xl overflow-hidden pb-2 pt-0">
                      <div class="bg-gradient text-center py-1 opacity-0 text-sm font-bold GilroyBold">{{'Everything you need' | translate }}</div>
                        <div class="px-4">
                          <p class="text-white text-sm px-2 py-1 w-fit bg-gradient rounded-md font-bold GilroyBold mt-3">{{plan?.planName }}</p>
                          <div class="flex space-x-2  py-5">
                            <p class="text-xl pt-2 text-white Gilroy-Bold">$</p>
                            <div class="text-[50px] flex space-x-2 items-baseline text-white Gilroy-Bold ">
                                <p [ngClass]="{ 'line-through text-[30px]' : plan?.isDiscount }" >{{plan?.planPrice | translate }}</p>
                                <span *ngIf='plan?.isDiscount'>{{plan?.discountedPrice | translate }}</span>
                            </div >
                            <p class="text-sm pt-2 text-gray-400">/ {{plan?.planDuration | translate }}</p>
                        </div>
                      </div>
                    </div>
                    <!-- <hr class="border-2 mt-2 mb-2" /> -->
                    <ol class="space-y-4 list-disc ms-4 p-6">
                        <li class="flex list-item items-center space-x-2 rtl:space-x-reverse ">

                            <p class="text-sm Gilroy-Bold">{{plan?.features?.transactionLimit | translate }} {{'Transaction Limit.' | translate }}</p>
                        </li>
                        <li class="flex list-item items-center space-x-2 rtl:space-x-reverse ">

                            <p class="text-sm Gilroy-Bold">{{plan?.features?.adminLimit | translate }} {{'Admin.' | translate }}</p>
                        </li>
                        <li class="flex list-item items-center space-x-2 rtl:space-x-reverse ">

                            <p class="text-sm Gilroy-Bold">{{plan?.features?.supportEmail | translate }} {{'Support.' | translate }}</p>
                        </li>
                        <li class="flex list-item items-center space-x-2 rtl:space-x-reverse ">

                            <p class="text-sm Gilroy-Bold">{{'Single Business Account.' | translate }}</p>
                        </li>
                        <li class="flex list-item items-center space-x-2 rtl:space-x-reverse ">

                            <p class="text-sm Gilroy-Bold">{{'Free support, and Secure cloud storage.' | translate }}</p>
                        </li>
                    </ol>
                    <div class="flex justify-center mt-14">
                        <div class="">
                          <app-button buttonText='{{"Get This Plan" | translate }}' type='primary' rounded='full'
                              (handleClick)='injectPlan(plan)'></app-button>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="plan?.planName === 'Essential'">
                <div class="pb-4 bg-white shadow-xl rounded-xl h-full overflow-hidden w-[233px] mobileWidth">
                    <div class="bg-blue-100 h-1/3 max-h-[33.33%] rounded-t-xl overflow-hidden  pb-2 pt-0 ">
                        <div class="bg-gradient text-center py-1 text-white text-sm font-bold GilroyBold">{{'Everything you need' | translate }}</div>
                        <div class="px-4">
                            <p class="text-white text-sm px-2 py-1 w-fit bg-gradient rounded-md font-bold GilroyBold mt-3">{{plan?.planName }}</p>
                            <div class="flex space-x-2  py-5">
                                <p class="text-xl pt-2 text-black Gilroy-Bold">$</p>

                                <div class="text-[50px] flex space-x-2 items-baseline text-black Gilroy-Bold ">
                                    <p [ngClass]="{ 'line-through text-[30px]' : plan?.isDiscount }" >{{plan?.planPrice | translate }}</p>
                                    <span *ngIf='plan?.isDiscount'>{{plan?.discountedPrice | translate }}</span>
                                </div >
                                <p class="text-sm pt-2 text-gray-400">/ {{plan?.planDuration | translate }}</p>
                            </div>
                        </div>
                    </div>

                    <ol class="space-y-4 list-disc ms-4 p-6">
                        <li class="flex list-item items-center space-x-2 rtl:space-x-reverse ">

                            <p class="text-sm Gilroy-Bold">{{plan?.features?.transactionLimit | translate }} {{'Transaction Limit.' | translate }}</p>
                        </li>
                        <li class="flex list-item items-center space-x-2 rtl:space-x-reverse ">

                            <p class="text-sm Gilroy-Bold">{{plan?.features?.adminLimit | translate }} {{'Admin.' | translate }}</p>
                        </li>
                        <li class="flex list-item items-center space-x-2 rtl:space-x-reverse ">

                            <p class="text-sm Gilroy-Bold">{{plan?.features?.supportEmail | translate }} {{'Support.' | translate }}</p>
                        </li>
                        <li class="flex list-item items-center space-x-2 rtl:space-x-reverse ">

                            <p class="text-sm Gilroy-Bold">{{'Single Business Account.' | translate }}</p>
                        </li>
                        <li class="flex list-item items-center space-x-2 rtl:space-x-reverse ">

                            <p class="text-sm Gilroy-Bold">{{'Powerful reports on Overdue Invoices & Bills, Cash Flow and Profit & Loss.' | translate }}</p>
                        </li>
                    </ol>
                    <div class="flex justify-center mt-4">
                        <div class="">
                          <app-button buttonText='{{"Buy this" | translate }}' type='primary' rounded='full'
                              (handleClick)='subscribePlan(plan)'></app-button>
                        </div>
                    </div>
                </div>
            </div>
            <ng-container [ngTemplateOutlet]="this[subscriptionPlans[i].planName]"
            [ngTemplateOutletContext]='{$implicit: plan}'></ng-container>
        </div>
        <div class="text-lg" *ngIf='subscriptionPlans.length === 0'>{{'No plans to subscribe.' | translate }}</div>
    </div>
</div>

<!-- <ng-template #Starter let-plan>
    <div class=" ms-5 pb-4 rounded-xl bg-white shadow-xl h-full overflow-hidden">
        <div class="bg-gradient h-1/3 max-h-[33.33%] px-6 py-4 rounded-t-xl overflow-hidden pb-2 pt-0">
            <div class="px-4">
              <p class="text-white text-sm px-2 py-1 w-fit bg-gradient rounded-md font-bold GilroyBold mt-3">{{plan?.planName}}</p>
              <div class="flex space-x-2  py-5">
                <p class="text-xl pt-2 text-white Gilroy-Bold">$</p>
                <div class="text-[50px] flex space-x-2 items-baseline text-white Gilroy-Bold ">
                    <p [ngClass]="{ 'line-through text-[30px]' : plan?.isDiscount }" >{{plan?.planPrice}}</p>
                    <span *ngIf='plan?.isDiscount'>{{plan?.discountedPrice}}</span>
                </div >
                <p class="text-sm pt-2 text-gray-400">/ {{plan?.planDuration}}</p>
            </div>
          </div>
        </div>

        <ol class="space-y-4 list-disc ms-4 p-6">
            <li class="flex list-item items-center space-x-2 rtl:space-x-reverse ">

                <p class="text-sm Gilroy-Bold">{{plan?.features?.transactionLimit}} Transaction Limit.</p>
            </li>
            <li class="flex list-item items-center space-x-2 rtl:space-x-reverse ">

                <p class="text-sm Gilroy-Bold">{{plan?.features?.adminLimit}} Admin.</p>
            </li>
            <li class="flex list-item items-center space-x-2 rtl:space-x-reverse ">

                <p class="text-sm Gilroy-Bold">{{plan?.features?.supportEmail}} Support.</p>
            </li>
            <li class="flex list-item items-center space-x-2 rtl:space-x-reverse ">

                <p class="text-sm Gilroy-Bold">Single Business Account.</p>
            </li>
            <li class="flex list-item items-center space-x-2 rtl:space-x-reverse ">

                <p class="text-sm Gilroy-Bold">Free support, and Secure cloud storage.</p>
            </li>
        </ol>
        <div class="flex justify-center">
            <div class="mt-[71px]">
              <app-button buttonText='Get This' type='primary' rounded='full'
                  (handleClick)='injectPlan(plan)'></app-button>
            </div>
        </div>
    </div>
</ng-template> -->
<!-- <ng-template #Essential let-plan>
    <div class="ms-5 pb-4 bg-white shadow-xl rounded-xl h-full overflow-hidden">
        <div class="bg-blue-100 h-1/3 max-h-[33.33%] rounded-t-xl overflow-hidden  pb-2 pt-0 ">
            <div class="bg-gradient text-center py-1 text-white text-sm font-bold GilroyBold">Everything you need</div>
            <div class="px-4">
                <p class="text-white text-sm px-2 py-1 w-fit bg-gradient rounded-md font-bold GilroyBold mt-3">{{plan?.planName}}</p>
                <div class="flex space-x-2  py-5">
                    <p class="text-xl pt-2 text-black Gilroy-Bold">$</p>

                    <div class="text-[50px] flex space-x-2 items-baseline text-black Gilroy-Bold ">
                        <p [ngClass]="{ 'line-through text-[30px]' : plan?.isDiscount }" >{{plan?.planPrice}}</p>
                        <span *ngIf='plan?.isDiscount'>{{plan?.discountedPrice}}</span>
                    </div >
                    <p class="text-sm pt-2 text-gray-400">/ {{plan?.planDuration}}</p>
                </div>
            </div>
        </div>

        <ol class="space-y-4 list-disc ms-4 p-6">
            <li class="flex list-item items-center space-x-2 rtl:space-x-reverse ">

                <p class="text-sm Gilroy-Bold">{{plan?.features?.transactionLimit}} Transaction Limit.</p>
            </li>
            <li class="flex list-item items-center space-x-2 rtl:space-x-reverse ">

                <p class="text-sm Gilroy-Bold">{{plan?.features?.adminLimit}} Admin.</p>
            </li>
            <li class="flex list-item items-center space-x-2 rtl:space-x-reverse ">

                <p class="text-sm Gilroy-Bold">{{plan?.features?.supportEmail}} Support.</p>
            </li>
            <li class="flex list-item items-center space-x-2 rtl:space-x-reverse ">

                <p class="text-sm Gilroy-Bold">Single Business Account.</p>
            </li>
            <li class="flex list-item items-center space-x-2 rtl:space-x-reverse ">

                <p class="text-sm Gilroy-Bold">Powerful reports on Overdue Invoices & Bills, Cash Flow and Profit &
                    Loss.</p>
            </li>
        </ol>
        <div class="flex justify-center mt-[71px]">
            <div class="">
              <app-button buttonText='{{"Buy this" | translate }}' type='primary' rounded='full'
                  (handleClick)='subscribePlan(plan)'></app-button>
            </div>
        </div>
    </div>
</ng-template> -->
<ng-template #Lite let-plan>
    <div class="ms-5 pb-4 bg-white shadow-xl rounded-xl h-full overflow-hidden">
        <div class="bg-blue-100 h-1/3 max-h-[33.33%] rounded-t-xl overflow-hidden  pb-2 pt-0 ">
            <div class="bg-gradient text-center py-1 text-white text-sm font-bold GilroyBold">{{'Recommended for you' | translate }}</div>
            <div class="px-4">
                <!-- <img src="assets/images/Group 4653.svg" class="w-full" > -->
                <p class="text-white text-sm px-2 py-1 w-fit bg-gradient rounded-md font-bold GilroyBold mt-3">{{plan?.planName}}</p>
                <!-- </div> -->
                <!-- <p class="Gilroy-Bold text-lg">{{plan?.planName}}</p> -->
                <div class="flex space-x-2  py-5">
                    <p class="text-xl pt-2 text-black Gilroy-Bold">$</p>
                    <!-- <p class="text-[50px] text-black Gilroy-Bold">{{plan?.planPrice }}</p> -->
                    <div class="text-[50px] flex space-x-2 items-baseline text-black Gilroy-Bold ">
                        <p [ngClass]="{ 'line-through text-[30px]' : plan?.isDiscount }" >{{plan?.planPrice | translate}}
                        </p>
                        <span *ngIf='plan?.isDiscount'>{{plan?.discountedPrice | translate}}</span>
                    </div >
                    <p class="text-sm pt-2 text-gray-400">/ {{plan?.planDuration | translate}}</p>
                </div>
            </div>
        </div>
        <!-- <hr class="border-2 mt-2 mb-2" /> -->
        <ol class="space-y-4 list-disc ms-4 p-6">
            <li class="flex list-item items-center space-x-2 rtl:space-x-reverse ">

                <p class="text-sm Gilroy-Bold">{{plan?.features?.transactionLimit | translate }} {{'Transaction Limit.' | translate }}</p>
            </li>
            <li class="flex list-item items-center space-x-2 rtl:space-x-reverse ">

                <p class="text-sm Gilroy-Bold">{{plan?.features?.adminLimit | translate }} {{'Admin.' | translate }}</p>
            </li>
            <li class="flex list-item items-center space-x-2 rtl:space-x-reverse ">

                <p class="text-sm Gilroy-Bold">{{plan?.features?.supportEmail | translate }} {{'Support.' | translate }}</p>
            </li>
            <li class="flex list-item items-center space-x-2 rtl:space-x-reverse ">

                <p class="text-sm Gilroy-Bold">{{'Single Business Account.' | translate }}</p>
            </li>
            <li class="flex list-item items-center space-x-2 rtl:space-x-reverse ">

                <p class="text-sm Gilroy-Bold">{{'Powerful reports on Overdue Invoices & Bills, Cash Flow and Profit & Loss.' | translate}}</p>
            </li>
        </ol>
        <div class="mt-4 ml-6">
            <app-button buttonText='{{"Buy this" | translate }}' type='primary' rounded='full'
                (handleClick)='subscribePlan(plan)'></app-button>
        </div>
    </div>
</ng-template>
