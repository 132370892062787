import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './auth-components/login/login.component';
import { SignupComponent } from './auth-components/signup/signup.component';
import { ForgotPasswordComponent } from './auth-components/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './auth-components/reset-password/reset-password.component';
import { AuthRoutingModule } from './auth-routing.module';
import { AuthContainerComponent } from './auth-container.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PersonalDetailsComponent } from './auth-components/personal-details/personal-details.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { NgxSpinnerModule } from 'ngx-spinner';
import { VerifyAlternateEmailComponent } from './auth-components/verify-alternate-email/verify-alternate-email.component';
import { InvitationComponent } from './auth-components/invitation/invitation.component';
import { SignupForInvitedComponent } from './auth-components/signup-for-invited/signup-for-invited.component';
import { VerifySubcriptionComponent } from './auth-components/verify-subcription/verify-subcription.component';
import { SubscriptionPlansComponent } from './auth-components/subscription-plans/subscription-plans.component';
import { SignupAdminInviteComponent } from './auth-components/signup-admin-invite/signup-admin-invite.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { SelectBusinessComponent } from './auth-components/select-business/select-business.component';
import { TranslateModule } from '@ngx-translate/core';
import { OtpVerifyComponent } from './auth-components/otp-verify/otp-verify.component';
import { NgOtpInputModule } from 'ng-otp-input';
import { SignupFacultyInviteComponent } from './auth-components/signup-faculty-invite/signup-faculty-invite.component';


@NgModule({
  declarations: [
    LoginComponent,
    SignupComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    AuthContainerComponent,
    PersonalDetailsComponent,
    VerifyAlternateEmailComponent,
    InvitationComponent,
    SignupForInvitedComponent,
    VerifySubcriptionComponent,
    SubscriptionPlansComponent,
    SignupAdminInviteComponent,
    SelectBusinessComponent,
    OtpVerifyComponent,
    SignupFacultyInviteComponent
  ],
  imports: [
    CommonModule,
    AuthRoutingModule,
    SharedModule,
    NgxSpinnerModule,
    ReactiveFormsModule,
    NgSelectModule,
    TranslateModule,
    NgOtpInputModule,
    FormsModule
  ]
})
export class AuthModule { }
