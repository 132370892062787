<div class="flex min-h-screen">
    <div id="chat-sidebar" class="w-[25%] bg-[#1481c9] flex flex-col rounded-e-3xl ltr:pl-6 rtl:pr-6">    
        <h1 class="text-2xl text-white Gilroy-SemiBold flex gap-2 my-4">
            <span id="back-button" class="w-8 h-8 bg-[#FFCBCB1A] rounded-full flex justify-center items-center">
                <svg routerLink="/dashboard" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#fff" class="w-4 h-4 cursor-pointer">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18" />
                </svg>                
            </span>{{"Chats" | translate}}
            <svg (click)="toggleSidebar()" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 grip-icon-mobile">
                <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
            </svg>
        </h1>
        <button class="2xl:py-2 py-1 cursor-pointer Gilroy-SemiBold text-white inline-flex gap-2 items-center 2xl:text-base text-base" (click)="showDialog = true; createChatStep = 'one'; closeSidebar()">
            <span class="w-8 h-8 bg-white rounded-full flex justify-center items-center">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#000" class="w-4 h-4">
                    <path stroke-linecap="round" stroke-linejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125" />
                </svg>
            </span>{{"Create New" | translate}}</button>
            <span class="bg-[#f6f6f6] flex items-center gap-2 rounded-[10px] p-2 text-xs my-2" (click)="globalSearchModal = true; closeSidebar()"><svg width="14" height="14" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.75 12.75L15.75 15.75" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M2.25 8.25C2.25 11.5637 4.93629 14.25 8.25 14.25C9.90975 14.25 11.4121 13.5761 12.4983 12.487C13.5808 11.4016 14.25 9.90398 14.25 8.25C14.25 4.93629 11.5637 2.25 8.25 2.25C4.93629 2.25 2.25 4.93629 2.25 8.25Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>Search globally...</span>
            <ng-container *ngIf="individualChatList?.length > 0">
            <ul class="py-4">
                <p class="text-white opacity-70 Gilroy-SemiBold text-sm">{{"Individuals" | translate}}</p>
                <ng-container *ngFor="let users of individualChatList">
                    <li [ngClass]="{'bg-white text-black' : selectedChatToShow?._id === users?._id}" class="2xl:py-2 p-2 rounded-[10px] gap-2 cursor-pointer Gilroy-SemiBold text-white items-center 2xl:text-base text-sm flex justify-between" (click)="goToChat(users, userDetail?._id)">
                        <div class="flex w-[95%]" [ngClass]="{'!w-[80%]': users?.unreadCount > 0}">
                            <span [ngClass]="{'single-chat-image': isUserOnline(users.chatTitle._id)}">
                                <img [src]="users.chatTitle.profileImageName ? baseURL+users.chatTitle.profileImageName : 'assets/images/avatar.jpg'" onerror="this.src='assets/images/avatar.jpg'" class="rounded-full min-w-[18px] h-[18px] relative" alt="avatar" srcset="">
                            </span>
                            <p class="mx-2 overflow-hidden whitespace-nowrap overflow-ellipsis" [title]="users?.chatTitle?.name">{{users?.chatTitle?.name}}</p>
                        </div>
                        <span *ngIf="users?.unreadCount > 0" class="w-5 h-5 rounded-full text-xs bg-red-500 text-white flex items-center justify-center">{{users?.unreadCount}}</span>
                    </li>
                </ng-container>
            </ul>
        </ng-container>
        <ng-container *ngIf="groupChatList?.length > 0">
            <ul class="py-4">
                <p class="text-white opacity-70 Gilroy-SemiBold text-sm">{{"Groups" | translate}}</p>
                <ng-container *ngFor="let users of groupChatList">
                    <li [ngClass]="{'bg-white text-black' : selectedChatToShow?._id === users?._id}" class="2xl:py-2 flex rounded-[10px] gap-2 p-2 cursor-pointer Gilroy-SemiBold text-white items-center 2xl:text-base text-sm justify-between" (click)="goToChat(users, userDetail?._id)">
                        <div class="flex w-[95%]" [ngClass]="{'!w-[80%]': users?.unreadCount > 0}">
                            <svg [ngClass]="{'active-link' : selectedChatToShow?._id === users?._id}" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.75 15V14.25C0.75 11.3505 3.10051 9 6 9C8.8995 9 11.25 11.3505 11.25 14.25V15" stroke="white" stroke-width="1.5" stroke-linecap="round"/>
                                <path d="M9.75 10.5C9.75 8.42895 11.429 6.75 13.5 6.75C15.571 6.75 17.25 8.42895 17.25 10.5V10.875" stroke="white" stroke-width="1.5" stroke-linecap="round"/>
                                <path d="M6 9C7.65683 9 9 7.65683 9 6C9 4.34314 7.65683 3 6 3C4.34314 3 3 4.34314 3 6C3 7.65683 4.34314 9 6 9Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M13.5 6.75C14.7427 6.75 15.75 5.74264 15.75 4.5C15.75 3.25736 14.7427 2.25 13.5 2.25C12.2573 2.25 11.25 3.25736 11.25 4.5C11.25 5.74264 12.2573 6.75 13.5 6.75Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                            <p class="mx-2 overflow-hidden whitespace-nowrap overflow-ellipsis" [title]="users?.channelGroupName">{{users?.channelGroupName}}</p>
                        </div>
                        <span *ngIf="users?.unreadCount > 0" class="w-5 h-5 rounded-full bg-red-500 text-white text-xs flex items-center justify-center">{{users?.unreadCount}}</span>
                    </li>
                </ng-container>
            </ul>
        </ng-container>
        <ng-container *ngIf="channelChatList?.length > 0">
            <ul class="py-4">
                <p class="text-white opacity-70 Gilroy-SemiBold text-sm">{{"Channels" | translate}}</p>
                <ng-container *ngFor="let users of channelChatList">
                    <li [ngClass]="{'bg-white text-black' : selectedChatToShow?._id === users?._id}" class="2xl:py-2 flex rounded-[10px] gap-2 p-2 cursor-pointer Gilroy-SemiBold text-white items-center 2xl:text-base text-sm justify-between" (click)="goToChat(users, userDetail?._id)">
                        <div class="flex w-[95%]" [ngClass]="{'!w-[80%]': users?.unreadCount > 0}">
                            <svg [ngClass]="{'active-link' : selectedChatToShow?._id === users?._id}" *ngIf="users.channelType === 'private'" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12 9H13.05C13.2985 9 13.5 9.20145 13.5 9.45V14.55C13.5 14.7985 13.2985 15 13.05 15H4.95C4.70147 15 4.5 14.7985 4.5 14.55V9.45C4.5 9.20145 4.70147 9 4.95 9H6M12 9V6C12 5 11.4 3 9 3C6.6 3 6 5 6 6V9M12 9H6" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                            <svg [ngClass]="{'active-link' : selectedChatToShow?._id === users?._id}" *ngIf="users.channelType === 'public'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2px" stroke="currentColor" class="w-4 h-4">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M5.25 8.25h15m-16.5 7.5h15m-1.8-13.5-3.9 19.5m-2.1-19.5-3.9 19.5" />
                            </svg>
                            <p class="mx-2 overflow-hidden whitespace-nowrap overflow-ellipsis" [title]="users?.channelGroupName">{{users?.channelGroupName}}</p>
                        </div>
                        <span *ngIf="users?.unreadCount > 0" class="w-5 h-5 rounded-full bg-red-500 text-white text-xs flex items-center justify-center">{{users?.unreadCount}}</span>
                    </li>
                </ng-container>
            </ul>
        </ng-container>
    </div>
    <ng-container *ngIf="selectedChatToShow; else noChatSelected">
        <div id="chat-application">
            <div id="chat-top-details" class="flex justify-between items-center mx-4 py-5 border-b border-[#E2E2E2]">
                <div class="flex gap-2 items-start w-full">
                    <svg (click)="toggleSidebar()" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 grip-icon-mobile">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                    </svg>
                    <span *ngIf="selectedChatToShow?.chatType === 'Individual'" [ngClass]="{'single-chat-image': isUserOnline(selectedChatToShow?.chatTitle?._id)}"><img [src]="selectedChatToShow?.chatTitle?.profileImageName ? baseURL+selectedChatToShow?.chatTitle?.profileImageName : 'assets/images/avatar.jpg'" onerror="this.src='assets/images/avatar.jpg'" class="rounded-full w-[28px] h-[28px] relative" alt="avatar" srcset=""></span>
                    <svg *ngIf="selectedChatToShow?.chatType === 'Group'" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.75 15V14.25C0.75 11.3505 3.10051 9 6 9C8.8995 9 11.25 11.3505 11.25 14.25V15" stroke="black" stroke-width="1.5" stroke-linecap="round"/>
                        <path d="M9.75 10.5C9.75 8.42895 11.429 6.75 13.5 6.75C15.571 6.75 17.25 8.42895 17.25 10.5V10.875" stroke="black" stroke-width="1.5" stroke-linecap="round"/>
                        <path d="M6 9C7.65683 9 9 7.65683 9 6C9 4.34314 7.65683 3 6 3C4.34314 3 3 4.34314 3 6C3 7.65683 4.34314 9 6 9Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M13.5 6.75C14.7427 6.75 15.75 5.74264 15.75 4.5C15.75 3.25736 14.7427 2.25 13.5 2.25C12.2573 2.25 11.25 3.25736 11.25 4.5C11.25 5.74264 12.2573 6.75 13.5 6.75Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    <svg *ngIf="selectedChatToShow?.chatType === 'Channel' && selectedChatToShow?.channelType === 'private'" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12 9H13.05C13.2985 9 13.5 9.20145 13.5 9.45V14.55C13.5 14.7985 13.2985 15 13.05 15H4.95C4.70147 15 4.5 14.7985 4.5 14.55V9.45C4.5 9.20145 4.70147 9 4.95 9H6M12 9V6C12 5 11.4 3 9 3C6.6 3 6 5 6 6V9M12 9H6" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    <svg *ngIf="selectedChatToShow?.chatType === 'Channel' && selectedChatToShow?.channelType === 'public'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2px" stroke="currentColor" class="w-4 h-4">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M5.25 8.25h15m-16.5 7.5h15m-1.8-13.5-3.9 19.5m-2.1-19.5-3.9 19.5" />
                    </svg>
                    <h3 (click)="selectedChatToShow?.chatType === 'Channel' || selectedChatToShow?.chatType === 'Group' ? editDialog = true : editDialog = false" class="flex cursor-pointer gap-1 items-center Gilroy-SemiBold">{{selectedChatToShow?.chatTitle?.name || selectedChatToShow?.channelGroupName}} 
                        <span *ngIf="selectedChatToShow?.chatType === 'Channel' || selectedChatToShow?.chatType === 'Group'">
                            <svg width="8" height="5" viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 1L4 4L7 1" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </span>
                    </h3>
                    <app-dropdown class="absolute ltr:right-4 rtl:left-4 right-options" #dropdownOptions>
                        <!-- <app-button button type='primary' buttonText='{{"Add New Transaction" | translate}}' size='large' (click)='dropdownOptions.open=true' (clickOutside)='dropdownOptions.open=false'></app-button> -->
                        <svg button (click)='dropdownOptions.open=true' (clickOutside)='dropdownOptions.open=false' xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 grip-icon-mobile">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M8.625 12a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H8.25m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H12m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0h-.375M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                        </svg>                          
                        <div options>
                            <span routerLink="/chats" class="flex items-center cursor-pointer px-4 py-2 text-sm Gilroy-Bold hover:bg-gray-50 text-center rounded-t-xl"><svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 me-2" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M8.625 12a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H8.25m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H12m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0h-.375M21 12c0 4.556-4.03 8.25-9 8.25a9.764 9.764 0 0 1-2.555-.337A5.972 5.972 0 0 1 5.41 20.97a5.969 5.969 0 0 1-.474-.065 4.48 4.48 0 0 0 .978-2.025c.09-.457-.133-.901-.467-1.226C3.93 16.178 3 14.189 3 12c0-4.556 4.03-8.25 9-8.25s9 3.694 9 8.25Z" />
                            </svg>{{'Chats' | translate}}</span>
                            <span routerLink="/task" class="flex items-center cursor-pointer px-4 py-2 text-sm Gilroy-Bold hover:bg-gray-50 text-center rounded-b-xl">
                                <svg class="h-6 w-6 me-2" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" color="currentColor"><path d="M9 6L20 6" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M3.80002 5.79999L4.60002 6.59998L6.60001 4.59999" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M3.80002 11.8L4.60002 12.6L6.60001 10.6" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M3.80002 17.8L4.60002 18.6L6.60001 16.6" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M9 12L20 12" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M9 18L20 18" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                                {{'Task-Manager' | translate}}</span>
                            <span routerLink="/files" class="flex items-center cursor-pointer px-4 py-2 text-sm Gilroy-Bold hover:bg-gray-50 text-center rounded-b-xl"><svg class="h-6 w-6 me-2" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M10.6776 2.30337C11.5101 1.89888 12.4899 1.89888 13.3223 2.30337L20.6379 5.85815C22.454 6.74064 22.454 9.25936 20.6379 10.1419L13.3223 13.6966C12.4899 14.1011 11.5101 14.1011 10.6776 13.6966L3.36208 10.1419C1.54595 9.25936 1.54595 6.74064 3.36208 5.85815L10.6776 2.30337ZM12.4408 4.01685C12.1633 3.88202 11.8367 3.88202 11.5592 4.01685L4.24364 7.57163C3.88042 7.74813 3.88042 8.25187 4.24364 8.42837L11.5592 11.9831C11.8367 12.118 12.1633 12.118 12.4408 11.9831L19.7563 8.42837C20.1196 8.25187 20.1195 7.74813 19.7563 7.57163L12.4408 4.01685Z" fill="#000"/>
                                <path d="M2.10555 16.8172C2.35254 16.3232 2.95321 16.123 3.44719 16.37L11.5528 20.4227C11.8343 20.5635 12.1657 20.5635 12.4472 20.4227L20.5528 16.37C21.0467 16.123 21.6474 16.3232 21.8944 16.8172C22.1414 17.3111 21.9412 17.9118 21.4472 18.1588L13.3416 22.2116C12.497 22.6339 11.5029 22.6339 10.6583 22.2116L2.55276 18.1588C2.05878 17.9118 1.85856 17.3111 2.10555 16.8172Z" fill="#000"/>
                                <path d="M3.44742 12.1058C2.95344 11.8588 2.35277 12.059 2.10578 12.553C1.85879 13.047 2.05901 13.6477 2.55299 13.8946L10.6586 17.9474C11.5031 18.3697 12.4973 18.3697 13.3418 17.9474L21.4474 13.8946C21.9414 13.6477 22.1416 13.047 21.8946 12.553C21.6476 12.059 21.047 11.8588 20.553 12.1058L12.4474 16.1586C12.1659 16.2993 11.8345 16.2993 11.553 16.1586L3.44742 12.1058Z" fill="#000"/>
                            </svg>{{'Files' | translate}}</span>
                            <span (click)='logout()'  class="flex items-center cursor-pointer px-4 py-2 text-sm Gilroy-Bold hover:bg-gray-50 text-center rounded-b-xl">
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-6 me-2" viewBox="0 0 20 20" fill="currentColor">
                                    <path fill-rule="evenodd" d="M3 3a1 1 0 00-1 1v12a1 1 0 102 0V4a1 1 0 00-1-1zm10.293 9.293a1 1 0 001.414 1.414l3-3a1 1 0 000-1.414l-3-3a1 1 0 10-1.414 1.414L14.586 9H7a1 1 0 100 2h7.586l-1.293 1.293z" clip-rule="evenodd" />
                                </svg>
                                {{'Logout' | translate}}
                            </span>
                        </div>
                    </app-dropdown>
                </div>
                <div class="flex gap-5 w-full items-center search-options">
                    <div *ngIf="selectedChatToShow?.chatType === 'Channel' || selectedChatToShow?.chatType === 'Group'" (click)="editDialog = true" class="flex cursor-pointer">
                        <div [ngClass]="lang === 'ar' ? 'image-container-right' : 'image-container'">
                            <ng-container *ngFor="let user of selectedChatToShow?.chatUsers; let i = index;">
                                <ng-container *ngIf="i < 3">
                                    <div class="flex image-item gap-1">
                                        <span class="relative flex justify-center items-center h-6 w-6 bg-white rounded-full">
                                            <img class="h-5 w-5 rounded-full" [src]="user.profileImageName ? baseURL+user.profileImageName : 'assets/images/avatar.jpg'" onerror="this.src='assets/images/avatar.jpg'" alt="">
                                        </span>
                                    </div>
                                </ng-container>
                            </ng-container>
                        </div>
                        <div>{{selectedChatToShow?.chatUsers.length}}</div>
                    </div>
                    <app-dropdown #dropdown (clickOutside)='dropdown.open = false' class="flex notification-tray">
                        <span button (click)='dropdown.open = true' class="relative inline-block">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 cursor-pointer">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M14.857 17.082a23.848 23.848 0 0 0 5.454-1.31A8.967 8.967 0 0 1 18 9.75V9A6 6 0 0 0 6 9v.75a8.967 8.967 0 0 1-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 0 1-5.714 0m5.714 0a3 3 0 1 1-5.714 0" />
                            </svg>
                            <span class="absolute -top-2 ltr:-right-1 rtl:-left-1 text-[8px] text-white bg-red-500 w-4 h-4 flex items-center justify-center rounded-full">{{notificationsCount}}</span>
                        </span>
                        <div options class="py-4">
                            <h3 class="text-sm px-4 Gilroy-SemiBold">
                                {{ "Notifications" | translate }}
                            </h3>
                            <ng-container *ngIf="allNotifications?.length > 0; else noNotification">
                                <div class="notification-height">
                                    <ng-container *ngFor="let item of allNotifications">
                                        <div (click)="goToNotification(item)" [ngClass]="{'bg-blue-200': !item.seen}" class="px-4 cursor-pointer border-t">
                                            <p id="menu-item-0" class="text-dark-700 py-1 text-sm text-start rounded-xl">{{item.message}}</p>
                                            <p class="text-dark-700 text-xs text-start py-1 rounded-xl">{{item.createdAt | date : 'MMM d, y, h:mm a'}}</p>
                                        </div>
                                    </ng-container>
                                </div>
                            </ng-container>
                            <ng-template #noNotification><span class="p-4">{{"No data available" | translate}}</span></ng-template>
                        </div>
                    </app-dropdown>
                    <div class="flex gap-1">
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12.75 12.75L15.75 15.75" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M2.25 8.25C2.25 11.5637 4.93629 14.25 8.25 14.25C9.90975 14.25 11.4121 13.5761 12.4983 12.487C13.5808 11.4016 14.25 9.90398 14.25 8.25C14.25 4.93629 11.5637 2.25 8.25 2.25C4.93629 2.25 2.25 4.93629 2.25 8.25Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        <input type="text" ([ngModel])="searchText" (input)="getFilteredMessages($event)" class="border-0 chat-input-field max-h-5 chat-search" placeholder="Search in this chat...">
                    </div>                    
                </div>
            </div>
            <div id="chat-window">
                <p *ngIf="selectedChatToShow?.channelDescription" class="Gilroy-Medium padding-without-bottom">{{"Description:" | translate}} <span>{{selectedChatToShow?.channelDescription}}</span></p>
                <div *ngIf="selectedChatToShow?.chatType === 'Channel'" class="flex gap-3 p-4">
                    <button *ngIf="!selectedChatToShow?.channelDescription && isMemberOfChat" (click)="editDialog = true" class="p-2 text-sm border-[#DDDDDD] border rounded-[10px] Gilroy-SemiBold flex items-center gap-2">
                        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.57559 4.26781L10.5622 3.28115C11.0829 2.76045 11.9271 2.76045 12.4479 3.28115L13.3907 4.22396C13.9113 4.74466 13.9113 5.58888 13.3907 6.10958L12.404 7.09624M9.57559 4.26781L3.16511 10.6782C2.94373 10.8996 2.80731 11.1918 2.77977 11.5037L2.61837 13.3307C2.58186 13.744 2.92777 14.09 3.34112 14.0534L5.16806 13.892C5.47993 13.8645 5.77216 13.728 5.99354 13.5067L12.404 7.09624M9.57559 4.26781L12.404 7.09624" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        {{"Add description" | translate}}</button>
                    <button *ngIf="selectedChatToShow?.chatCreatedBy?._id === userDetail._id && isMemberOfChat" (click)="editDialog = true" class="p-2 text-sm border-[#DDDDDD] border rounded-[10px] Gilroy-SemiBold flex items-center gap-2">
                        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_260_3225)">
                            <g clip-path="url(#clip1_260_3225)">
                            <path d="M11.3333 7.6665H13.3333M13.3333 7.6665H15.3333M13.3333 7.6665V5.6665M13.3333 7.6665V9.6665" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M0.666748 14.3333V13.6667C0.666748 11.0893 2.75609 9 5.33341 9C7.91075 9 10.0001 11.0893 10.0001 13.6667V14.3333" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M5.33341 8.99984C6.80615 8.99984 8.00008 7.8059 8.00008 6.33317C8.00008 4.86041 6.80615 3.6665 5.33341 3.6665C3.86065 3.6665 2.66675 4.86041 2.66675 6.33317C2.66675 7.8059 3.86065 8.99984 5.33341 8.99984Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </g>
                            </g>
                            <defs>
                            <clipPath id="clip0_260_3225">
                            <rect width="16" height="16" fill="white" transform="translate(0 0.5)"/>
                            </clipPath>
                            <clipPath id="clip1_260_3225">
                            <rect width="16" height="16" fill="white" transform="translate(0 1)"/>
                            </clipPath>
                            </defs>
                        </svg>
                        {{"Add colleagues" | translate}}
                    </button>
                </div>
                <button (click)="editDialog = true" *ngIf="selectedChatToShow?.chatType === 'Group' && selectedChatToShow?.chatCreatedBy?._id === userDetail._id" class="p-2 m-4 text-sm border-[#DDDDDD] border rounded-[10px] Gilroy-SemiBold flex items-center gap-2">
                    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.57559 4.26781L10.5622 3.28115C11.0829 2.76045 11.9271 2.76045 12.4479 3.28115L13.3907 4.22396C13.9113 4.74466 13.9113 5.58888 13.3907 6.10958L12.404 7.09624M9.57559 4.26781L3.16511 10.6782C2.94373 10.8996 2.80731 11.1918 2.77977 11.5037L2.61837 13.3307C2.58186 13.744 2.92777 14.09 3.34112 14.0534L5.16806 13.892C5.47993 13.8645 5.77216 13.728 5.99354 13.5067L12.404 7.09624M9.57559 4.26781L12.404 7.09624" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    {{"Add members" | translate}}
                </button>
              <div #messageContainer class="pt-3" id="output" *ngFor="let group of output | messageFilter: searchText">
                    <div class="date-header flex justify-center items-center">
                        <span>{{ group.date === today ? 'Today' : (group.date | date: 'EEEE, MMMM d') }}
                            <!-- <svg width="8" height="5" viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 1L4 4L7 1" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg> -->
                        </span>
                    </div>
                    <!-- {{group.messages | json }} -->
                    <div id="output" *ngFor="let data of group.messages">
                        <div>
                            <p *ngIf="data.showNameHeader" class="flex gap-[10px] items-center"><img [src]="data?.profileImageName ? baseURL+data?.profileImageName : data?.messageOwner?.profileImageName ? baseURL+data?.messageOwner?.profileImageName : 'assets/images/avatar.jpg'" onerror="this.src='assets/images/avatar.jpg'" class="rounded-full w-[28px] h-[28px]" alt="avatar" srcset="">
                                <span class="flex gap-1 text-black items-center Gilroy-SemiBold">{{data.messageOwner?.firstName + " " + data.messageOwner?.lastName}}</span>
                                <span class="text-xs opacity-40">{{data?.createdAt | date : 'shortTime'}}</span>
                            </p>
                            <div [id]="data._id" class="px-[52px] py-1 chat-inner-text ql-snow" [innerHTML]="data.message"></div>
                        </div>
                    </div>
              </div>
              <div id="lastMessage" class="w-full h-2"></div>
            </div>
            <div id="feedback" class="text-xs px-4 min-h-[16px]"><p>{{feedback}}</p></div>
            <!-- <button type="button" class="ql-mention" value="#"></button> -->
            <ng-container *ngIf="isMemberOfChat; else notAMemberOfChat">
                <div class="flex quill-editor relative items-center px-3 w-full">
                    <quill-editor #editor class="w-full !p-0" type="text" [(ngModel)]="message" placeholder="{{'Message' | translate}}" autocomplete="off" [ngClass]="{'right-to-left' : lang === 'ar' }" class="chat-input-field w-full border-0 rounded-b-lg"
                    (keypress)="messageTyping(this.selectedChatToShow?._id)" [modules]="modules"></quill-editor>
                    <svg (click)="sendMessage()" width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg" class="cursor-pointer absolute ltr:right-5 rtl:left-5 bottom-4" [ngClass]="{'send-message-icon' : lang === 'ar' }">
                        <circle cx="17" cy="17" r="17" fill="#00BB78"/>
                        <path d="M24 11L15.8308 24L14.5875 17.5961L9 14.2365L24 11Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M14.5493 17.6228L23.9999 11" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </div>
            </ng-container>
            <ng-template #notAMemberOfChat>
                <div class="flex flex-col bg-[#f5f5f5] h-24 border border-[#6B7280] justify-center rounded-lg mx-3 relative items-center">
                    <p class="text-sm">{{"Join the channel to send messages and receive updates related this channel" | translate}}</p>
                    <button (click)="joinChannel()" class="bg-green-400 text-xs px-4 py-2 rounded-lg text-white">{{"Join" | translate}}</button>
                </div>
            </ng-template>
        </div>
    </ng-container>
    <ng-template #noChatSelected>
        <div class="w-full flex items-center justify-center">{{"You can start a chat from create new option" | translate}}</div>
    </ng-template>
</div>

<app-modal-container [isOpen]='showDialog'>
    <div header class="flex justify-between text-start border-b pb-2 Gilroy-Bold">
        <ng-container *ngIf="createChatStep === 'one'">
            {{'Create New' | translate}}
            <svg (click)="showDialog = false; createChatStep = 'one'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 cursor-pointer">
                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
            </svg>
        </ng-container>
        <ng-container *ngIf="createChatStep === 'two' || createChatStep === 'three'">
            {{createChatType === 'Individual' ? 'Select Someone' : createChatType === 'Group' ? 'Create a group' : 'Create a channel'}}
            <svg (click)="createChatStep = 'one'; channelGroupName = ''; initialiseMessage = ''; newChatUser = null; channelType = null;" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 cursor-pointer">
                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
            </svg>
        </ng-container>
        <ng-container *ngIf="createChatStep === 'four'">
            {{"Add new people to" | translate}} {{ channelGroupName }}
            <svg (click)="createChatStep = 'one'; channelGroupName = ''; newChatUser = null; channelType = null;" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 cursor-pointer">
                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
            </svg>
        </ng-container>
    </div>
    <div class="text-start" content>
        <ng-container *ngIf="createChatStep === 'one'">
            <div class="flex gap-2" [ngClass]="{'justify-evenly': !channelPermission}">
                <div (click)="selectedChatType('Individual')" class="w-1/3 h-32 cursor-pointer flex gap-2 flex-col justify-center items-center border rounded-[20px]">
                    <span class="w-14 h-14 bg-[#c2ddef] rounded-full flex justify-center items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#000" class="w-4 h-4">
                        <path stroke-linecap="round" stroke-linejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125" />
                    </svg>
                </span>
                <p>{{"Individual" | translate}}</p>
                </div>
                <div (click)="selectedChatType('Group')" class="w-1/3 h-32 cursor-pointer flex gap-2 flex-col justify-center items-center border rounded-[20px]">
                    <span class="w-14 h-14 bg-[#c2ddef] rounded-full flex justify-center items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M18 18.72a9.094 9.094 0 0 0 3.741-.479 3 3 0 0 0-4.682-2.72m.94 3.198.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0 1 12 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 0 1 6 18.719m12 0a5.971 5.971 0 0 0-.941-3.197m0 0A5.995 5.995 0 0 0 12 12.75a5.995 5.995 0 0 0-5.058 2.772m0 0a3 3 0 0 0-4.681 2.72 8.986 8.986 0 0 0 3.74.477m.94-3.197a5.971 5.971 0 0 0-.94 3.197M15 6.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm6 3a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Zm-13.5 0a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Z" />
                    </svg>                      
                </span>
                <p>{{"Group" | translate}}</p>
                </div>
                <div *ngIf="channelPermission" (click)="selectedChatType('Channel')" class="w-1/3 h-32 cursor-pointer flex gap-2 flex-col justify-center items-center border rounded-[20px]">
                    <span class="w-14 h-14 bg-[#c2ddef] rounded-full flex justify-center items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M5.25 8.25h15m-16.5 7.5h15m-1.8-13.5-3.9 19.5m-2.1-19.5-3.9 19.5" />
                    </svg>                      
                </span>
                <p>{{"Channel" | translate}}</p>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="createChatStep === 'two'">
            <div *ngIf="createChatType === 'Individual'">
                <label class="label-name">{{"Name" | translate}}</label>
                <ng-select class="chat-type-select" [clearable]="false" bindLabel="value" bindValue="id" [(ngModel)]="newChatUser" [items]="usersList" placeholder="Select user"></ng-select>
                <label class="label-name">{{"Message" | translate}}</label>
                <textarea type="text" rows="4" class="chat-input-type" [(ngModel)]="initialiseMessage"></textarea>
            </div>
            <div *ngIf="createChatType === 'Group' || createChatType === 'Channel'">
                <label class="label-name">{{"Name" | translate}}</label>
                <input type="text" class="chat-input-type" [(ngModel)]="channelGroupName" placeholder="#  e.g. subscription-budget">
            </div>
        </ng-container>
        <ng-container *ngIf="createChatStep === 'three'">
            <p class="Gilroy-SemiBold opacity-70 text-sm mb-4">#{{channelGroupName}}</p>
            <p class="Gilroy-SemiBold text-lg">{{"Visibility" | translate}}</p>
            <label class="flex items-center gap-1 py-1 text-sm"><input type="radio" value="public" [(ngModel)]="channelType" name="channelType"/><p class="Gilroy-Medium">{{"Public - Anyone in " | translate}}<strong>{{userBusiness?.companyName}}</strong></p></label>
            <label class="flex items-center gap-1 py-1 text-sm"><input type="radio" value="private" [(ngModel)]="channelType" name="channelType"/><p class="Gilroy-Medium">{{"Private - Only specific people" | translate}}</p></label>
        </ng-container>
        <ng-container *ngIf="createChatStep === 'four'">
            <ng-select class="chat-type-select" [multiple]="true" [clearable]="false" bindLabel="value" bindValue="id" [(ngModel)]="newChatUser" [items]="originalUsersList" placeholder="Select user"></ng-select>
        </ng-container>
    </div>
    <div footer class="flex gap-3 items-center">
        <ng-container *ngIf="createChatType === 'Individual'; else groupChannel">
            <button *ngIf="createChatStep !== 'one'" [ngClass]="{'opacity-50 cursor-not-allowed' : !initialiseMessage || !newChatUser}" [disabled]="!initialiseMessage || !newChatUser" class="bg-gradient text-white rounded-2xl px-6 py-3" (click)="submitCreate('Individual')">{{"Create" | translate}}</button>
        </ng-container>
        <ng-template #groupChannel>
            <button *ngIf="createChatStep === 'two'" [ngClass]="{'opacity-50 cursor-not-allowed' : !channelGroupName }" [disabled]="!channelGroupName" class="bg-gradient text-white rounded-2xl px-6 py-3" (click)="decideNextStep()">{{"Next" | translate}}</button>
            <button *ngIf="createChatStep === 'three'" [ngClass]="{'opacity-50 cursor-not-allowed' : !channelType }" [disabled]="!channelType" class="bg-gradient text-white rounded-2xl px-6 py-3" (click)="createChatStep = 'four'; checkIfPublic()">{{"Next" | translate}}</button>
            <ng-container *ngIf="createChatStep === 'four'">
                <button [ngClass]="{'opacity-50 cursor-not-allowed' : !newChatUser || newChatUser?.length < 1 }" [disabled]="!newChatUser || newChatUser?.length < 1" class="bg-gradient text-white rounded-2xl px-6 py-3" (click)="submitCreate('channelGroup')">{{"Create" | translate}}</button>
                <button class="text-black border border-[#CACACA] rounded-2xl px-6 py-3" (click)="submitCreate('skip')">{{"Skip for now" | translate}}</button>
            </ng-container>
        </ng-template>
    </div>
</app-modal-container>


<app-modal-container [isOpen]='editDialog'>
    <div header class="flex justify-between text-start border-b pb-2 Gilroy-Bold">
        {{selectedChatToShow?.channelGroupName}}
        <svg (click)="editDialog = false; editName = false; editDescription = false" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 cursor-pointer">
            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
        </svg>
    </div>
    <div class="edit-channel-group" content>
        <my-tabs>
            <my-tab tabTitle="{{'About' | translate}}">
                <div class="bg-[#F9F9F9] rounded-xl">
                    <ng-container *ngIf="selectedChatToShow?.chatType === 'Channel'; else groupOptions">
                        <div class="border-b border-[E7E7E7] p-4">
                            <div class="flex justify-between">
                                <div class="flex flex-col w-full">
                                    <div class="flex justify-between">
                                        <p class="text-xs opacity-70">{{"Channel Name" | translate}}</p>
                                        <div *ngIf="isMemberOfChat" class="flex gap-3">
                                            <button class="text-xs opacity-70 cursor-pointer bg-[#E7E7E7] p-3 rounded-xl" (click)="editName = !editName">{{editName ? 'Cancel' : 'Edit'}}</button>
                                            <button *ngIf="editName" class="text-xs opacity-70 cursor-pointer bg-[#E7E7E7] p-3 rounded-xl" [ngClass]="{'opacity-30 cursor-not-allowed' : !chatDetailsToEdit?.channelGroupName}" [disabled]="!chatDetailsToEdit?.channelGroupName" (click)="saveChatDetails()">{{"Save" | translate}}</button>
                                        </div>
                                    </div>
                                    <ng-container *ngIf="editName; else nameViewMode">
                                        <input type="text" [(ngModel)]="chatDetailsToEdit.channelGroupName" class="chat-input-type my-3">
                                    </ng-container>
                                    <ng-template #nameViewMode>
                                        <span class="text-lg Gilroy-SemiBold">{{chatDetailsToEdit?.channelGroupName}}</span>
                                    </ng-template>
                                </div>
                            </div>
                        </div>
                        <div class="border-b border-[E7E7E7] p-4">
                            <div class="flex justify-between">
                                <div class="flex flex-col w-full">
                                    <div class="flex justify-between">
                                        <p class="text-xs opacity-70">{{"Description" | translate}}</p>
                                        <div *ngIf="isMemberOfChat" class="flex gap-3">
                                            <button class="text-xs opacity-70 cursor-pointer bg-[#E7E7E7] p-3 rounded-xl" (click)="editDescription = !editDescription">{{editDescription ? 'Cancel' : 'Edit'}}</button>
                                            <button *ngIf="editDescription" class="text-xs opacity-70 cursor-pointer bg-[#E7E7E7] p-3 rounded-xl" (click)="saveChatDetails()">{{"Save" | translate}}</button>
                                        </div>
                                    </div>
                                    <ng-container *ngIf="editDescription; else descriptionViewMode">
                                        <textarea [(ngModel)]="chatDetailsToEdit.channelDescription" rows="5" class="chat-input-type my-3"></textarea>
                                    </ng-container>
                                    <ng-template #descriptionViewMode>
                                        <span class="text-sm Gilroy-Medium">{{chatDetailsToEdit?.channelDescription || '---'}}</span>
                                    </ng-template>
                                </div>
                            </div>
                        </div>
                        <div class="p-4">
                            <div>
                                <p class="text-xs opacity-70">{{"Created By" | translate}}</p>
                                <span class="text-lg Gilroy-SemiBold">{{chatDetailsToEdit?.chatCreatedBy?.name}}</span><span class="text-xs"> {{"on" | translate}} {{chatDetailsToEdit?.createdAt | date : 'MMM d, y'}}</span>
                            </div>
                        </div>
                    </ng-container>
                    <ng-template #groupOptions>
                        <div class="border-b border-[E7E7E7] p-4">
                            <div class="flex justify-between">
                                <div class="flex flex-col w-full">
                                    <div class="flex justify-between">
                                        <p class="text-xs opacity-70">{{"Group Name" | translate}}</p>
                                        <div class="flex gap-3">
                                            <button class="text-xs opacity-70 cursor-pointer bg-[#E7E7E7] p-3 rounded-xl" (click)="editName = !editName">{{editName ? 'Cancel' : 'Edit'}}</button>
                                            <button *ngIf="editName" class="text-xs opacity-70 cursor-pointer bg-[#E7E7E7] p-3 rounded-xl" [disabled]="!chatDetailsToEdit?.channelGroupName" [ngClass]="{'opacity-30 cursor-not-allowed' : !chatDetailsToEdit?.channelGroupName}" (click)="saveChatDetails()">{{"Save" | translate}}</button>
                                        </div>
                                    </div>
                                    <ng-container *ngIf="editName; else nameViewMode">
                                        <input type="text" [(ngModel)]="chatDetailsToEdit.channelGroupName" class="chat-input-type my-3">
                                    </ng-container>
                                    <ng-template #nameViewMode>
                                        <span class="text-lg Gilroy-SemiBold">{{chatDetailsToEdit?.channelGroupName}}</span>
                                    </ng-template>
                                </div>
                            </div>
                        </div>
                        <div class="p-4">
                            <div>
                                <p class="text-xs opacity-70">{{"Created By" | translate}}</p>
                                <span class="text-lg Gilroy-SemiBold">{{chatDetailsToEdit?.chatCreatedBy?.name}}</span><span class="text-xs"> {{"on" | translate}} {{chatDetailsToEdit?.createdAt | date : 'MMM d, y'}}</span>
                            </div>
                        </div>
                    </ng-template>
                </div>
            </my-tab>
            <my-tab tabTitle="{{'Members' | translate}}">
                <div class="flex gap-3 border border-[#F2F2F2] rounded-xl p-3 mb-3">
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12.75 12.75L15.75 15.75" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M2.25 8.25C2.25 11.5637 4.93629 14.25 8.25 14.25C9.90975 14.25 11.4121 13.5761 12.4983 12.487C13.5808 11.4016 14.25 9.90398 14.25 8.25C14.25 4.93629 11.5637 2.25 8.25 2.25C4.93629 2.25 2.25 4.93629 2.25 8.25Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    <input type="text" ([ngModel])="findMembers" class="border-0 w-full chat-input-field max-h-5 chat-search" placeholder="Find members...">
                </div>
                <button *ngIf="selectedChatToShow?.chatCreatedBy?._id === userDetail?._id" (click)="addMoreDialog = true; filterExistingUsers()" class="py-4 text-sm rounded-[10px] Gilroy-SemiBold flex items-center gap-2">
                    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="36" height="36" rx="10" fill="#F2F2F2"/>
                        <path d="M22.5833 16.167H25.3333M25.3333 16.167H28.0833M25.3333 16.167V13.417M25.3333 16.167V18.917" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M7.91675 25.3333V24.4167C7.91675 20.8728 10.7896 18 14.3334 18C17.8772 18 20.7501 20.8728 20.7501 24.4167V25.3333" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M14.3334 18.0003C16.3584 18.0003 18.0001 16.3587 18.0001 14.3337C18.0001 12.3086 16.3584 10.667 14.3334 10.667C12.3084 10.667 10.6667 12.3086 10.6667 14.3337C10.6667 16.3587 12.3084 18.0003 14.3334 18.0003Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>                        
                    {{"Add people" | translate}}
                </button>
                <div>
                    <ng-container *ngIf="selectedChatToShow?.chatUsers?.length > 0; else noMembers">
                        <div *ngFor="let user of selectedChatToShow?.chatUsers" class="flex px-[10px] py-[6px] gap-3 mb-3 rounded-xl bg-[#F7F7F7] justify-between items-center">
                            <div class="flex gap-3 items-center">
                                <img [src]="user.profileImageName ? baseURL+user.profileImageName : 'assets/images/avatar.jpg'" onerror="this.src='assets/images/avatar.jpg'" class="rounded-full w-[18px] h-[18px]" alt="avatar" srcset="">
                                <span class="text-sm Gilroy-Medium">{{user?.name}}</span>
                                <span class="text-xs Gilroy-Medium" *ngIf="user._id === selectedChatToShow?.chatCreatedBy?._id">(admin)</span>
                            </div>
                            <svg *ngIf="selectedChatToShow?.chatCreatedBy?._id === userDetail._id && user._id !== userDetail._id" (click)="warningDialog = true; memberToRemove = user;" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-4 cursor-pointer">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
                            </svg>
                        </div>
                    </ng-container>
                    <ng-template #noMembers>{{"No Members" | translate}}</ng-template>
                </div>
            </my-tab>
        </my-tabs>
    </div>
    <div footer class="flex gap-3 items-center">
        <button (click)="warningDialog = true; leaveGroup = true; memberToRemove = userDetail; filterAdminMembers()" class="text-red-600 text-base border border-[#CACACA] px-5 py-4 rounded-2xl Gilroy-SemiBold">{{"Leave" | translate}} {{selectedChatToShow?.chatType === 'Channel' ? 'Channel' : 'Group'}}</button>
    </div>
</app-modal-container>

<app-modal-container [isOpen]='addMoreDialog'>
    <div header class="flex justify-between text-start border-b pb-2 Gilroy-Bold">
        {{selectedChatToShow?.channelGroupName}}
        <svg (click)="addMoreDialog = false; addMoreUsers = null; membersToAdd = null;" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 cursor-pointer">
            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
        </svg>
    </div>
    <div class="edit-channel-group" content>
        <ng-select class="chat-type-select" [multiple]="true" [clearable]="false" bindLabel="value" bindValue="id" [(ngModel)]="membersToAdd" [items]="remainingUsersList" placeholder="Select user"></ng-select>
    </div>
    <div footer class="flex gap-3 items-center">
        <button [ngClass]="{'opacity-50 cursor-not-allowed' : !membersToAdd || membersToAdd?.length < 1 }" [disabled]="!membersToAdd || membersToAdd?.length < 1" class="bg-gradient text-white rounded-2xl px-6 py-3" (click)="addMember()">{{"Add" | translate}}</button>
    </div>
</app-modal-container>


<app-modal-container [isOpen]='warningDialog'>
    <div header class="flex justify-between text-start border-b pb-2 Gilroy-Bold">
        {{leaveGroup ? 'Leave Group' : 'Remove this user'}}
        <svg (click)="warningDialog = false; leaveGroup = false; memberToRemove = null; toMakeAdmin = null" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 cursor-pointer">
            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
        </svg>
    </div>
    <div class="edit-channel-group" content>
        <ng-container *ngIf="leaveGroup; else removeTemplate">
            {{"Are you sure you want to leave from this" | translate}} {{selectedChatToShow?.chatType}}<strong class="Gilroy-SemiBold"> {{selectedChatToShow?.channelGroupName}}</strong>?
            <div class="my-3" *ngIf="selectedChatToShow?.chatCreatedBy._id === userDetail?._id">
                <strong class="text-xs">{{"You are the admin of this" | translate}} {{selectedChatToShow?.chatType}}, {{"please select any user to make admin" | translate}}</strong>
                <ng-select class="chat-type-select my-2" [clearable]="false" bindLabel="name" bindValue="_id" [(ngModel)]="toMakeAdmin" [items]="membersForAdmin" placeholder="Select user"></ng-select>
            </div>
        </ng-container>
        <ng-template #removeTemplate>
            {{"Are you sure you want to remove" | translate }} <strong class="Gilroy-SemiBold">{{memberToRemove?.name}}</strong> from this <strong class="Gilroy-SemiBold"> {{selectedChatToShow?.chatType}}</strong>?
        </ng-template>
    </div>
    <div footer class="flex gap-3 items-center">
        <button *ngIf="leaveGroup" [ngClass]="{'opacity-50 cursor-not-allowed' : selectedChatToShow?.chatCreatedBy._id === userDetail?._id && !toMakeAdmin }" [disabled]="selectedChatToShow?.chatCreatedBy._id === userDetail?._id && !toMakeAdmin" class="bg-gradient text-white rounded-2xl px-6 py-3" (click)="removeMember()">{{"Remove" | translate}}</button>
        <button *ngIf="!leaveGroup && memberToRemove" class="bg-gradient text-white rounded-2xl px-6 py-3" (click)="removeMember()">{{"Remove" | translate}}</button>
    </div>
</app-modal-container>


<app-modal-container [isOpen]='globalSearchModal'>
    <div header class="flex flex-col justify-between text-start border-b pb-2 Gilroy-Bold">
        <div class="flex justify-between">
            {{'Search in ' + userBusiness?.companyName}}
            <svg (click)="globalSearchModal = false; globalSearchText = null; globalSearchData = null;" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 cursor-pointer">
                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
            </svg>
        </div>
        <input type="text" (click)="globalSearchModal = true;" (keydown.enter)="searchTextGlobally()" [(ngModel)]="globalSearchText" class="border border-black p-3 my-2 text-xs rounded-lg" placeholder="Search anything...">
    </div>
    <div class="edit-channel-group" content>
        <ng-container *ngIf="globalSearchData?.length > 0; else noDataTemplate">
            <div class="my-3 h-[60vh] overflow-scroll">
                <div *ngFor="let data of globalSearchData" (click)="messageScrollIntoView(data)" class="border rounded-lg mb-4 cursor-pointer">
                    <span class="text-xs opacity-60 Gilroy-Semibold mx-3">{{data.chatId?.chatType === "Individual" ? data.chatId?.chatSentTo?.firstName + ' ' + data.chatId?.chatSentTo?.lastName : data.chatId?.channelGroupName}}</span>
                    <div>
                        <p class="flex gap-[10px] items-center mx-3"><img [src]="data?.profileImageName ? baseURL+data?.profileImageName : data?.messageOwner?.profileImageName ? baseURL+data?.messageOwner?.profileImageName : 'assets/images/avatar.jpg'" onerror="this.src='assets/images/avatar.jpg'" class="rounded-full w-[28px] h-[28px]" alt="avatar" srcset="">
                            <span class="flex gap-1 text-black items-center Gilroy-SemiBold">{{data.messageOwner?.firstName + " " + data.messageOwner?.lastName}}</span>
                            <span class="text-xs opacity-40">{{data?.createdAt | date : 'MMM d'}} at {{data?.createdAt | date : 'shortTime'}}</span>
                        </p>
                        <div class="px-[52px] py-1 chat-inner-text ql-snow" [innerHTML]="data.message"></div>
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-template #noDataTemplate>
            Enter anything in the above search box to find it in all chats
        </ng-template>
    </div>
    <div footer class="flex gap-3 items-center">
        <button *ngIf="leaveGroup" [ngClass]="{'opacity-50 cursor-not-allowed' : selectedChatToShow?.chatCreatedBy._id === userDetail?._id && !toMakeAdmin }" [disabled]="selectedChatToShow?.chatCreatedBy._id === userDetail?._id && !toMakeAdmin" class="bg-gradient text-white rounded-2xl px-6 py-3" (click)="removeMember()">{{"Remove" | translate}}</button>
        <button *ngIf="!leaveGroup && memberToRemove" class="bg-gradient text-white rounded-2xl px-6 py-3" (click)="removeMember()">{{"Remove" | translate}}</button>
    </div>
</app-modal-container>