<div class="flex justify-center p-4">
    <div class="w-full flex flex-col space-y-4">
        <div>
            <div class="flex items-center justify-between">
                <div class="">
                    <h1 class="text-2xl Gilroy-Bold inline-flex items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 me-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z" />
                          </svg>
                        {{'Invoices' | translate}}
                    </h1>
                </div>
                <div class="">
                    <app-button appAccessControl moduleType='sales' accessType='edit' size='large' buttonText='{{"Create Invoice" | translate}}' type='primary' routerLink='create-invoice'></app-button>
                </div>
            </div>
            <div class="grid grid-cols-3 gap-4 mt-5">
                <div class="bg-gradient rounded-xl p-5 pt-7 pb-7">
                    <p class="Gilroy-bold text-white">{{'Total Invoiced amount' | translate }}</p>
                    <h3 class="text-2xl font-bold text-white">{{invoiceStats?.totalInvoice?.total | currency : currencyDetails?.currencySymbol : 'symbol-narrow' : number}}</h3>
                    <p class="text-sm text-white">{{'from' | translate }} {{invoiceStats?.totalInvoice?.count }} {{'invoices' | translate }}</p>
                </div>
                <div class="bg-input rounded-xl p-5">
                    <p class="Gilroy-bold">{{'Total Invoices Paid' | translate }}</p>
                    <h3 class="text-2xl font-bold">{{invoiceStats?.paidInvoice?.total | currency : currencyDetails?.currencySymbol : 'symbol-narrow' : number}}</h3>
                    <p class="text-sm">{{'from' | translate }} {{invoiceStats?.paidInvoice?.count }} {{'invoices' | translate }}</p>
                </div>
                <div class="bg-input rounded-xl p-5">
                    <p class="Gilroy-bold">{{'Total Invoice overdue' | translate }}</p>
                    <h3 class="text-2xl font-bold">{{invoiceStats?.overdueInvoice?.total | currency : currencyDetails?.currencySymbol : 'symbol-narrow' : number}}</h3>
                    <p class="text-sm">{{'from' | translate }} {{invoiceStats?.overdueInvoice?.count }} {{'invoices' | translate }}</p>
                </div>
            </div>
            <h3 class="text-xl Gilroy-Bold inline-flex items-center mt-6">{{'Active Filters' | translate}}</h3>
            <div class="flex space-x-2 rtl:space-x-reverse" [formGroup]='filterForm'>
                <div class="">
                    <label class="Gilroy-Bold">{{'Select Customer' | translate}}</label>
                    <ng-select [items]="customers"
                    class="custom-selector" 
                    bindLabel="customerName" 
                    bindValue="_id"
                    formControlName='customerId'
                    placeholder='{{"Type Customer Name" | translate}}'>
                    </ng-select>
                </div>
                <div class="">
                    <label class="Gilroy-Bold">{{"Select Project" | translate}}</label>
                    <ng-select [items]="projects"
                    class="custom-selector" 
                    bindLabel="projectName" 
                    bindValue="_id"
                    formControlName='projectId'
                    placeholder='{{"Type Project Name" | translate}}'>
                    </ng-select>
                </div>
                <div class="self-end">
                    <label class="Gilroy-Bold" for="startDate">{{'From' | translate}}</label>
                    <input formControlName='fromDate' type="date"  class="w-full bg-input rounded-lg border-0 h-11"/>
                </div>
                <div class="self-end">
                    <label class="Gilroy-Bold" for="endDate">{{'To' | translate}}</label>
                    <input formControlName='endDate' type="date"  class="w-full bg-input rounded-lg border-0 h-11"/>
                </div>
                <div class="self-end">
                    <app-button buttonText='{{"Filter Invoices" | translate}}' size='large' type='primary' (handleClick)='filterData()'></app-button>
                </div>
            </div>
            <div class="flex space-x-3 rtl:space-x-reverse">
                <div class="w-1/4"></div>
                <div class="w-1/4"></div>
                <div class="w-1/4">
                    <div *ngIf='formErrors.endDate' class="text-red-500 ms-4">{{formErrors.endDate}}</div>
                </div>
                <div class="w-1/4"></div>
                <div class="w-1/4"></div>
            </div>
            <div class="px-5 mt-10">
                <div class="ps-5 flex space-x-2 rtl:space-x-reverse">
                    <div>
                        <input checked id='all' type="radio" name='status' class="peer hidden">
                        <label (click)='filterInvoicesByStatus("all")' for='all' class="peer-checked:border-b-4 pb-1 active-border-invoice px-2 Gilroy-Bold">{{'All Invoices' | translate}}</label>
                    </div>
                    <div>
                        <input id='paid' type="radio" name='status' class="peer hidden">
                        <label (click)='filterInvoicesByStatus("paid")' for='paid' class="peer-checked:border-b-4 pb-1 active-border-invoice px-2 Gilroy-Bold">{{'Paid' | translate}}</label>
                    </div>
                    <div>
                        <input id='unpaid' type="radio" name='status' class="peer hidden">
                        <label (click)='filterInvoicesByStatus("unpaid")' for='unpaid' class="peer-checked:border-b-4 pb-1 active-border-invoice px-2 Gilroy-Bold">{{'Unpaid' | translate}}</label>
                    </div>
                    <div>
                        <input id='draft' type="radio" name='status' class="peer hidden">
                        <label (click)='filterInvoicesByStatus("draft")' for='draft' class="peer-checked:border-b-4 pb-1 active-border-invoice px-2 Gilroy-Bold">{{'Draft' | translate }}</label>
                    </div>
                </div>
                <app-table-container [tableHeadings]='tableHeadings' [tableData]='tableData' [keys]='tableKeys' [actionTemplate]='actionTemplate'>
                    <ng-template let-item #actionTemplate>
                        <td class="p-2 text-center last:pe-10">
                            <app-dropdown #dropdown>
                                <div button (click)='dropdown.open = true' (clickOutside)='dropdown.open = false'>
                                    <div class="h-8 w-8 rounded-full flex justify-center items-center bg-gray-50 border border-gray-200">
                                        <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                                        </svg>
                                    </div>
                                </div>
                                <div options>
                                    <div (click)='viewInvoice(item._id)' class="cursor-pointer px-4 py-2 text-sm Gilroy-Bold hover:bg-gray-50 text-center">{{'View' | translate}}</div>
                                    <div appAccessControl moduleType='sales' accessType='edit' *ngIf='item?.status?.primaryStatus !== "draft"' (click)='openRecordPayment(item)' class="cursor-pointer px-4 py-2 text-sm Gilroy-Bold hover:bg-gray-50 text-center">{{'Record Payment' | translate}}</div>
                                    <div appAccessControl moduleType='sales' accessType='edit' (click)='updateInvoice(item._id)' class="cursor-pointer px-4 py-2 text-sm Gilroy-Bold hover:bg-gray-50 text-center">{{'Edit' | translate}}</div>
                                    <div appAccessControl moduleType='sales' accessType='edit' *ngIf='item?.status?.primaryStatus === "draft"' (click)='deleteInvoice(item._id)' class="cursor-pointer px-4 py-2 text-sm Gilroy-Bold hover:bg-gray-50 text-center">{{'Delete' | translate}}</div>
                                </div>
                            </app-dropdown>             
                        </td>
                    </ng-template>
                </app-table-container>
            </div>
        </div>
    </div>
</div>


<record-payment
  [isOpen]='recordPaymentModal'
  [amountDue]='amountDue'
  [minDate]='minDate'
  (closeModal)='recordPaymentModal=false'
  (paymentData)='recordPayment($event)'></record-payment>