import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subject, Observable } from 'rxjs';
import { SalesService } from 'src/app/modules/sales/sales.service';
import { selectCustomers } from 'src/app/modules/sales/state/selectors/customers.selector';
import { NumberService } from 'src/app/shared/services/number.service';
import {
  DateValidator,
  dateLessThan,
  valueChanges,
} from 'src/app/shared/utils/formValidator';
import { updateCurrentTransactionLimit } from 'src/app/store/actions/usage.action';
import { RootReducerState } from 'src/app/store/reducers';
import { selectBusiness } from 'src/app/store/selectors/business.selector';
import { selectProjects } from 'src/app/store/selectors/project.selector';
import { selectUsage } from 'src/app/store/selectors/usage.selector';
import { takeUntil } from 'rxjs/operators';
import { CashInvoiceService } from '../cash-invoice.service';

@Component({
  selector: 'app-list-cash-invoice',
  templateUrl: './list-cash-invoice.component.html',
  styleUrls: ['./list-cash-invoice.component.scss'],
})
export class ListCashInvoiceComponent implements OnInit {
  constructor(
    private cashInvoiceService: CashInvoiceService,
    private store: Store<RootReducerState>,
    private salesService: SalesService,
    private router: Router,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private translateService: TranslateService,
    private numberService: NumberService,
    private fb: FormBuilder
  ) {
    this.customers$ = this.store.pipe(select(selectCustomers));
    this.projects$ = this.store.pipe(select(selectProjects));
    this.usage$ = store.pipe(select(selectUsage));
    this.business$ = store.pipe(select(selectBusiness));
  }
  tableHeadings = [
    'Customer Number',
    'Invoice Number',
    'Created At',
    'Invoice Date',
    'Total Amount',
    'Actions',
  ];

  tableKeys = [
    'customerNumber',
    'invoiceNumber',
    'createdAt',
    'invoiceDate',
    'totalAmount',
  ];

  tableData = [];
  unfilteredList = [];
  filterStatus = 'all';

  number = '1.2-2';
  currencyDetails = {
    currency: '',
    currencySymbol: '',
  };
  transactionLimit;

  unsubscribe$ = new Subject();
  business$: Observable<any>;
  customers$: Observable<any>;
  projects$: Observable<any>;
  usage$: Observable<any>;
  customers = [];
  projects = [];
  invoiceStats;

  filterForm: FormGroup;
  formErrors = {
    fromDate: '',
    endDate: '',
  };

  formErrorMessages = {
    fromDate: {
      required: 'Date is Required',
      invalidDate: 'Invalid Date',
    },
    endDate: {
      required: 'Due Date is Required',
      dates: 'Invalid Date',
      invalidDate: 'Invalid Date',
    },
  };

  recordPaymentModal = false;
  amountDue = null;
  recordPaymentFor = null;
  minDate = null;

  selectOption(option): void {
    console.log(option);
  }

  ngOnInit(): void {
    this.getTransactionLimit();
    this.getCurrencyDetails();
    this.getInvoiceStats();
    this.loadInvoices();
    this.loadCustomers();
    this.loadProjects();
    this.loadFilterForm();
    this.loadNumberConfig();
  }

  loadNumberConfig(): void {
    this.numberService.decimalSize
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((number) => {
        this.number = `1.${number}-${number}`;
      });
  }

  getTransactionLimit(): void {
    this.usage$.subscribe(({ currentUsage }) => {
      if (currentUsage) {
        const { transactionLimit } = currentUsage;
        this.transactionLimit = transactionLimit;
      }
    });
  }

  getInvoiceStats() {
    this.business$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(({ businessId }) => {
        const year = moment().format('YYYY');
        const prevYear = moment().subtract(1, 'year').format('YYYY');
        const currentYear = moment(
          `${businessId?.accounting?.date || '01'} ${
            businessId?.accounting?.month || 'January'
          } ${year}`
        );
        const previousYear = moment(
          `${businessId?.accounting?.date || '01'} ${
            businessId?.accounting?.month || 'January'
          } ${prevYear}`
        );
        const body = {
          fromDate: previousYear,
          endDate: currentYear,
        };
        this.salesService
          .getCashInvoiceStats()
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe((resp) => {
            if (resp?.success) {
              this.invoiceStats = resp?.data;
              console.log(this.invoiceStats);
            }
          });
      });
  }

  getCurrencyDetails(): void {
    this.salesService.currencyDetail
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((details) => {
        if (details) this.currencyDetails = details;
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  loadFilterForm(): void {
    this.filterForm = this.fb.group(
      {
        projectId: [],
        customerId: [],
        fromDate: [null, DateValidator()],
        endDate: [null, DateValidator()],
      },
      { validator: dateLessThan('fromDate', 'endDate') }
    );

    this.filterForm.valueChanges.subscribe(() => {
      this.formErrors = valueChanges(
        this.filterForm,
        { ...this.formErrors },
        this.formErrorMessages,
        this.translateService
      );
    });

    this.formErrors = valueChanges(
      this.filterForm,
      { ...this.formErrors },
      this.formErrorMessages,
      this.translateService
    );
  }

  loadCustomers(): void {
    this.customers$.pipe(takeUntil(this.unsubscribe$)).subscribe(
      (customers) => {
        this.customers = customers?.map(c => {
          return {
            ...c,
            display: `${c?.mobileNumber} ${c?.firstName && c?.lastName ? `- ${c?.firstName} ${c?.lastName}` : ''}`
          }
        });
        console.log('Customer in invoice', this.customers);
      },
      () => {
        this.toastr.error(
          this.translateService.instant('Something went wrong!')
        );
      }
    );
  }

  loadProjects(): void {
    this.projects$.pipe(takeUntil(this.unsubscribe$)).subscribe(
      (projects) => {
        this.projects = projects;
      },
      () => {
        this.toastr.error(
          this.translateService.instant('Something went wrong!')
        );
      }
    );
  }

  loadInvoices(): void {
    this.salesService.businessId$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((id) => {
        if (id) {
          this.spinner.show();
          this.cashInvoiceService.getCashInvoices().subscribe(
            (resp) => {
              this.spinner.hide();
              if (resp.status === 200) {
                this.tableData = this.mapInvoiceListForTable(resp.data);
                this.unfilteredList = [...resp?.data];
              }
            },
            () => {
              this.toastr.error(
                this.translateService.instant('Something went wrong!')
              );
            }
          );
        }
      });
  }

  viewInvoice(id): void {
    this.router.navigate(['/sales/cash-invoice/view-cash-invoice'], {
      queryParams: { id },
    });
  }

  mapInvoiceListForTable(invoiceList): Array<object> {
    return [
      ...invoiceList
        .map((invoice) => ({
          ...invoice,
          totalAmount: this.numberService.currencier(invoice?.totalAmount),
          customerNumber: invoice?.customerDetails?.customerId?.mobileNumber,
          projectName: invoice?.projectDetails?.projectId?.projectName,
          invoiceDate: moment(invoice?.invoiceDate?.split('T')[0]).format(
            'DD-MM-YYYY'
          ),
          createdAt: moment(invoice?.createdAt?.split('T')[0]).format(
            'DD-MM-YYYY'
          ),
        }))
        .reverse(),
    ];
  }

  filterInvoicesByStatus(statusType: string): void {
    if (statusType !== 'all') {
      const filtered = this.unfilteredList.filter((invoice) => {
        if (
          invoice?.status?.primaryStatus.toLowerCase() ===
          statusType.toLowerCase()
        ) {
          return invoice;
        }
      });
      this.tableData = this.mapInvoiceListForTable([...filtered]);
    } else {
      this.tableData = this.mapInvoiceListForTable([...this.unfilteredList]);
    }
  }

  filterData(): void {
    if (this.filterForm.invalid) {
      this.filterForm.markAllAsTouched();
      this.formErrors = valueChanges(
        this.filterForm,
        { ...this.formErrors },
        this.formErrorMessages
      );
      return;
    }
    const body = this.filterForm.value;
    this.spinner.show();
    this.cashInvoiceService.filterCashInvoices(body).subscribe(
      (resp) => {
        this.spinner.hide();
        if (resp?.success) {
          this.unfilteredList = [...resp.data];
          this.tableData = this.mapInvoiceListForTable(resp.data);
        }
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(
          this.translateService.instant('Something went wrong!')
        );
      }
    );
  }

  openRecordPayment(item): void {
    const invoiceData = this.unfilteredList.find(
      (invoice) => invoice._id === item._id
    );
    if (invoiceData.dueAmount > 0) {
      this.recordPaymentModal = true;
      this.amountDue = Number(item.dueAmount);
      this.recordPaymentFor = item;
      this.minDate = item.invoiceDate;
    }
  }
}
