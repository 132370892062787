<div>
  <form [formGroup]="form">
    <div class="flex mb-3 pb-4">
      <div class="w-1/2 ">
        <div class="bg-proile rounded-xl bg-white px-3 py-2">
          <label for="category" class="block text-sm Gilroy-Bold font-color-05">{{'Category' | translate}}</label>
          <ng-select [items]="category" class="w-full p-0 border-0 focus:ring-0 back-chnage-custom"
            placeholder="Select one"
            formControlName="category">
          </ng-select>
        </div>
      </div>
      <div class="bg-proile rounded-xl bg-white px-3 py-2 w-1/2 ms-5">
        <label for="status" class="block text-sm Gilroy-Bold font-color-05">{{'Status' | translate}}</label>
        <ng-select [items]="status" class="w-full p-0 border-0 focus:ring-0 back-chnage-custom"
          placeholder="Select one"
          formControlName="status">
        </ng-select>
      </div>
    </div>
    <div class="flex mb-5 pb-4 border-b-2">
      <div class="bg-proile rounded-xl bg-white px-3 py-2 w-1/2">
        <label for="customDateRangefrom" class="block text-sm Gilroy-Bold font-color-05">{{'Custom range from' | translate}}</label>
        <input id="customDateRangefrom" formControlName="customDateRangefrom" type="date" 
          class="w-full p-0 border-0 focus:ring-0 back-chnage-custom">
          <div *ngIf='formErrors.customDateRangefrom' class="text-red-500 ms-4">{{formErrors.customDateRangefrom}}</div>
      </div>
      <div class="bg-proile rounded-xl bg-white px-3 py-2 w-1/2 ms-5">
        <label for="customDateRangeTo" class="block text-sm Gilroy-Bold font-color-05">{{'Custom range to' | translate}}</label>
        <input type="date" formControlName="customDateRangeTo" class="w-full p-0 border-0 focus:ring-0 back-chnage-custom">
        <div *ngIf='formErrors.customDateRangeTo' class="text-red-500 ms-4">{{formErrors.customDateRangeTo}}</div>
      </div>
    </div>
    <!-- <div class="flex mb-5 pb-4">
      <div class='w-1/2'>
      </div>
      <div class='w-1/2'>
      </div>
    </div> -->


  </form>

 

</div>
