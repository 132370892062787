<button
    class="inline-block disabled:cursor-not-allowed"
    [ngClass] = 'customClass'
    [class.w-full] = 'fullWidth'
    [class.rounded-full] = 'rounded'
    [class.rounded-xl] = '!rounded'
    (click)='handleClick.emit()'
    [disabled]='disabled'
    >
    {{buttonText | translate}}
</button>
