import { Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { NgSelectComponent } from '@ng-select/ng-select';
import { Store, select } from '@ngrx/store';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject } from 'rxjs';
import { takeUntil, debounceTime } from 'rxjs/operators';
import { selectSalesTaxes } from 'src/app/modules/sales/state/selectors/salesTaxes.selector';
import { AccountingService } from 'src/app/modules/settings/settings-modules/accounting/accounting.service';
import { FileUploadService } from 'src/app/shared/services/file-upload.service';
import { NumberService } from 'src/app/shared/services/number.service';
import { valueChanges } from 'src/app/shared/utils/formValidator';
import { HSN, SAC, filterHSNSAC } from 'src/app/shared/utils/hsnSac';
import { RootReducerState } from 'src/app/store/reducers';
import { selectAccounts } from 'src/app/store/selectors/account.selector';
import { selectBusiness } from 'src/app/store/selectors/business.selector';
import { selectStore } from 'src/app/store/selectors/store.selector';
import { selectUserSubscription } from 'src/app/store/selectors/user.selectors';
import { InventoryService } from '../../inventory.service';

@Component({
  selector: 'app-add-bundled-item',
  templateUrl: './add-bundled-item.component.html',
  styleUrls: ['./add-bundled-item.component.scss']
})
export class AddBundledItemComponent implements OnInit {

  @ViewChild('selectRef') selectRef: NgSelectComponent;
  buisness$: Observable<any>;
  subscription$: Observable<any>;
  salesTaxes$: Observable<any>;
  stores$: Observable<any>;
  accounts$: Observable<any>;
  unsubscribe = new Subject;
  businessId = null;
  itemType = ['Product', 'Service'];
  itemCategory = ['Sales', 'Purchases'];
  stores = []
  incomeAccounts = [];
  allAccounts = [];
  taxes = [];
  hsnCode = [];
  sacCode = [];
  units = [
    {
      "name": "LADI",
      "value": "LAD"
    },
    {
      "name": "PETI",
      "value": "PET"
    },
    {
      "name": "JARS",
      "value": "JAR"
    },
    {
      "name": "HANGER",
      "value": "HEGAR"
    },
    {
      "name": "POUCH",
      "value": "POCH"
    },
    {
      "name": "BORA",
      "value": "BOR"
    },
    {
      "name": "COIL",
      "value": "COIL"
    },
    {
      "name": "FEET",
      "value": "FT"
    },
    {
      "name": "INCHES",
      "value": "IN"
    },
    {
      "name": "PORTION",
      "value": "PRT"
    },
    {
      "name": "CASE",
      "value": "CASE"
    },
    {
      "name": "EACH",
      "value": "EACH"
    },
    {
      "name": "CAPSULES",
      "value": "CPS"
    },
    {
      "name": "PADS",
      "value": "PADS"
    },
    {
      "name": "REEL",
      "value": "REEL"
    },
    {
      "name": "BLISTER",
      "value": "BLISTER"
    },
    {
      "name": "PAD",
      "value": "PAD"
    },
    {
      "name": "PIECES",
      "value": "PCS"
    },
    {
      "name": "PAIRS",
      "value": "PRS"
    },
    {
      "name": "QUINTAL",
      "value": "QTL"
    },
    {
      "name": "ROLLS",
      "value": "ROL"
    },
    {
      "name": "SETS",
      "value": "SET"
    },
    {
      "name": "SQUARE FEET",
      "value": "SQF"
    },
    {
      "name": "SQUARE METERS",
      "value": "SQM"
    },
    {
      "name": "SQUARE YARDS",
      "value": "SQY"
    },
    {
      "name": "TABLETS",
      "value": "TBS"
    },
    {
      "name": "TEN GROSS",
      "value": "TGM"
    },
    {
      "name": "THOUSANDS",
      "value": "THD"
    },
    {
      "name": "TONNES",
      "value": "TON"
    },
    {
      "name": "TUBES",
      "value": "TUB"
    },
    {
      "name": "US GALLONS",
      "value": "UGS"
    },
    {
      "name": "UNITS",
      "value": "UNT"
    },
    {
      "name": "YARDS",
      "value": "YDS"
    },
    {
      "name": "OTHERS",
      "value": "OTH"
    },
    {
      "name": "HOURS",
      "value": "HRS"
    },
    {
      "name": "MINUTES",
      "value": "MINS"
    },
    {
      "name": "LITRE",
      "value": "LTR"
    },
    {
      "name": "METRIC TON",
      "value": "MTON"
    },
    {
      "name": "MILLIGRAM",
      "value": "MLG"
    },
    {
      "name": "BUCKETS",
      "value": "BCK"
    },
    {
      "name": "GLASSES",
      "value": "GLS"
    },
    {
      "name": "PLATES",
      "value": "PLT"
    },
    {
      "name": "CARATS",
      "value": "CTS"
    },
    {
      "name": "STRIPS",
      "value": "STRP"
    },
    {
      "name": "CUBIC FOOT",
      "value": "CFT"
    },
    {
      "name": "VIALS",
      "value": "VIAL"
    },
    {
      "name": "BAGS",
      "value": "BAG"
    },
    {
      "name": "BALE",
      "value": "BAL"
    },
    {
      "name": "BUNDLES",
      "value": "BDL"
    },
    {
      "name": "BUCKLES",
      "value": "BKL"
    },
    {
      "name": "BILLIONS OF UNITS",
      "value": "BOU"
    },
    {
      "name": "BOX",
      "value": "BOX"
    },
    {
      "name": "BOTTLES",
      "value": "BTL"
    },
    {
      "name": "BUNCHES",
      "value": "BUN"
    },
    {
      "name": "CANS",
      "value": "CAN"
    },
    {
      "name": "CUBIC METER",
      "value": "CBM"
    },
    {
      "name": "CUBIC CENTIMETER",
      "value": "CCM"
    },
    {
      "name": "CENTIMETER",
      "value": "CMS"
    },
    {
      "name": "CARTONS",
      "value": "CTN"
    },
    {
      "name": "DOZEN",
      "value": "DOZ"
    },
    {
      "name": "DRUM",
      "value": "DRM"
    },
    {
      "name": "GREAT GROSS",
      "value": "GGR"
    },
    {
      "name": "GRAMS",
      "value": "GMS"
    },
    {
      "name": "GROSS",
      "value": "GRS"
    },
    {
      "name": "GROSS YARDS",
      "value": "GYD"
    },
    {
      "name": "KILOGRAMS",
      "value": "KGS"
    },
    {
      "name": "KILOLITER",
      "value": "KLR"
    },
    {
      "name": "KILOMETRE",
      "value": "KME"
    },
    {
      "name": "MILLILITRE",
      "value": "MLT"
    },
    {
      "name": "METERS",
      "value": "MTR"
    },
    {
      "name": "NUMBERS",
      "value": "NOS"
    },
    {
      "name": "PACKS",
      "value": "PAC"
    },
    {
      "name": "CENTS",
      "value": "CNT"
    },
    {
      "name": "RUNNING FOOT",
      "value": "RFT"
    },
    {
      "name": "RIM",
      "value": "RIM"
    },
    {
      "name": "TIN",
      "value": "TIN"
    },
    {
      "name": "CHUDI",
      "value": "CHUDI"
    },
    {
      "name": "PATTA",
      "value": "PATTA"
    },
    {
      "name": "KIT",
      "value": "KIT"
    },
    {
      "name": "CUBIC FEET",
      "value": "CUFT"
    },
    {
      "name": "RUNNING METER",
      "value": "RMT"
    },
    {
      "name": "MILLIMETER ",
      "value": "MM"
    },
    {
      "name": "AMPOULE",
      "value": "AMP"
    },
    {
      "name": "PAIR",
      "value": "PAIR"
    },
    {
      "name": "AANA",
      "value": "ANA"
    },
    {
      "name": "CUBIC FEET PER MINUTE",
      "value": "CFM"
    },
    {
      "name": "YEARS",
      "value": "YRS"
    },
    {
      "name": "MONTH ",
      "value": "UOM"
    },
    {
      "name": "MAN-DAYS",
      "value": "MAN-DAY"
    },
    {
      "name": "NIGHTS",
      "value": "NIGHT"
    },
    {
      "name": "DAYS",
      "value": "DAY"
    },
    {
      "name": "SQUARE INCHES",
      "value": "SQIN"
    },
    {
      "name": "WEEKS",
      "value": "WEEK"
    },
    {
      "name": "PERSONS",
      "value": "PERSON"
    },
    {
      "name": "LOT",
      "value": "LOT"
    },
    {
      "name": "SACHET",
      "value": "SAC"
    },
    {
      "name": "REAM",
      "value": "REAM"
    },
    {
      "name": "BRASS",
      "value": "BRASS"
    },
    {
      "name": "NONE",
      "value": "NONE"
    },
    {
      "name": "COPY",
      "value": "COPY"
    },
    {
      "name": "TICKET",
      "value": "TKT"
    },
    {
      "name": "KILOWATT",
      "value": "KW"
    },
    {
      "name": "WATT",
      "value": "W"
    },
    {
      "name": "MBPS",
      "value": "MBPS"
    },
    {
      "name": "ACRE",
      "value": "AC"
    },
    {
      "name": "HECTARE",
      "value": "HA"
    },
    {
      "name": "BOLUS",
      "value": "BOLUS"
    },
    {
      "name": "AAAAAAAAAAA",
      "value": "G NEX"
    },
    {
      "name": "KILOVOLT-AMP",
      "value": "KVA"
    },
    {
      "name": "POINT",
      "value": "PT"
    },
    {
      "name": "MARK",
      "value": "MRK"
    },
    {
      "name": "STONE",
      "value": "STONE"
    },
    {
      "name": "BRICK",
      "value": "BRICK "
    },
    {
      "name": "PACKETS",
      "value": "PKT"
    },
    {
      "name": "BARNI",
      "value": "BAR"
    }
  ];
  files: File[] = [];
  addedFiles = [];
  barcode = '';
  subscription = '';
  isSaleUpdate:any;

  createProductForm: FormGroup;
  update:boolean = false;
  viewBarcode: boolean = false;
  productToUpdate = null;
  productsList;
  availableProducts;
  formErrors = {
    name: '',
    description: '',
    accountDetails: '',
    sellingPrice: '',
    itemType: '',
    sku: '',
    lowStock: '',
    asOfDate: '',
    openingStock: '',
    store: '',
    itemCode: '',
    units: '',
    category : '',
    items: ''
  };
  checkBoolean = true;

  formErrorMessages = {
    name: {
      required: 'Name is Required'
    },
    description: {
      required: 'Description is Required'
    },
    sellingPrice: {
      required: 'Price is Required'
    },
    sku: {
      required: 'SKU is Required',
      notUnique: 'This code is already in use, please try something else'
    },
    itemType: {
      required: 'Item Type is Required'
    },
    accountDetails: {
      required: 'Account is Required'
    },
    units: {
      required: 'Units are required'
    },
    lowStock: {
      required: "Minimum quantity for low stock can't be empy"
    },
    asOfDate: {
      required: 'As Of Date is required'
    },
    openingStock: {
      required: 'Opening Stock is required'
    },
    store: {
      required: 'Store is required'
    },
    itemCode : {
      required: 'Item Code is required'
    },
    category : {
      required: 'Category is required'
    },
    items : {
      required: 'Atleast 1 item is required'
    }
  };
  categories:any = []
  constructor(private fb: FormBuilder,
    private accountingService: AccountingService,
    private spinner: NgxSpinnerService,
    private inventoryService: InventoryService,
    private store: Store<RootReducerState>,
    private toastr: ToastrService,
    private router: Router,
    private numberService: NumberService,
    private route: ActivatedRoute,
    private fileUploadService: FileUploadService
    ) {
    this.buisness$ = store.pipe(select(selectBusiness));
    this.stores$ = store.pipe(select(selectStore));
    this.accounts$ = this.store.pipe(select(selectAccounts));
    this.salesTaxes$ = store.pipe(select(selectSalesTaxes));
    this.subscription$ = store.pipe(select(selectUserSubscription))
    this.addNewCategory = this.addNewCategory.bind(this);
  }

  ngOnInit(): void {
    this.hsnCode = HSN.slice(0,100);
    this.sacCode = SAC.slice(0,100);
    this.loadBusiness();
    this.loadForm();
    this.loadStores();
    this.loadAccounts();
    this.getSubscription();
    this.route.queryParams
    .pipe(takeUntil(this.unsubscribe))
    .subscribe(({ productId = '' }) => {
      if(productId){
        this.loadProductData(productId);
      }
    })
    this.units.sort((a,b) => a.name.localeCompare(b.name))
  }

  manageValidations(planName): void {
    if((planName === 'Retail' || planName === 'Retail Plus') && this.createProductForm.get('itemType').value === 'Product' && this.createProductForm.get('isSale').value === 'true'){
      this.createProductForm?.get('asOfDate')?.setValidators([Validators.required])
      this.createProductForm?.get('openingStock')?.setValidators([Validators.required])
      this.createProductForm?.get('itemCode')?.setValidators([Validators.required])
      this.createProductForm.updateValueAndValidity();
      this.formErrors = valueChanges(this.createProductForm, {...this.formErrors}, this.formErrorMessages)
    }
    else if((planName === 'Retail' || planName === 'Retail Plus') && this.createProductForm.get('itemType').value === 'Service'){
      this.createProductForm?.get('lowStock')?.clearValidators()
      this.createProductForm?.get('asOfDate')?.clearValidators()
      this.createProductForm?.get('asOfDate')?.setErrors(null)
      this.createProductForm?.get('openingStock')?.clearValidators()
      this.createProductForm?.get('itemCode')?.clearValidators()
      this.createProductForm.updateValueAndValidity();
    }
  }

  getSubscription(): void {
    this.subscription$.pipe(takeUntil(this.unsubscribe))
    .subscribe(sub => {
      this.subscription = sub?.planName;
      this.manageValidations(this.subscription);
    })
  }

  loadTaxes(): void {
    if(this.businessId){
      this.accountingService.listSalesTaxes(this.businessId)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(resp => {
        this.taxes = resp?.data.map(item => ({...item, tax: this.numberService.toFixed(item?.taxRate)}));
      });
    }
  }

  loadBusiness(): void {
    this.buisness$
    .pipe(takeUntil(this.unsubscribe))
    .subscribe(business => {
      this.businessId = business?.businessId?._id;
      this.loadCategories(this.businessId)
      this.loadTaxes();
      if(this.businessId) {
        this.loadProductsList(this.businessId)
      }
    })
  }

  loadForm(): void {
    this.createProductForm = this.fb.group({
      name: ['', [Validators.required]],
      description: ['', [Validators.required]],
      tax: [[]],
      sellingPrice: [0, Validators.required],
      sku: [''],
      itemType: ['Product', Validators.required],
      isSale: ['true'],
      accountDetails: [null, [Validators.required]],
      hsn: [null],
      sac: [null],
      units: [null, Validators.required],
      openingStock: [0],
      asOfDate: [null],
      itemCode: [null],
      barCode: [''],
      store: [null],
      lowStock: [0],
      isManage : [false],
      isAutoRun : [false],
      category : [null, Validators.required],
      items: this.fb.array([], Validators.required)
    });

    this.addNewCategory = this.addNewCategory.bind(this);
    this.createProductForm.valueChanges.subscribe((value) => {
      if(this.update){
        if(this.isSaleUpdate){
          this.incomeAccounts = this.allAccounts.filter(account => (account.accountType === 'Income' && account.accountSection === 'Income' && !account.isArchive))
        }
        else {
          this.incomeAccounts = this.allAccounts.filter(account => (account.accountSection === 'Expenses' && !account.isArchive))
        }
        this.formErrors = valueChanges(this.createProductForm, { ...this.formErrors }, this.formErrorMessages);
      } else {

      value.isSale = value?.isSale == 'true'? true : false;
      if(value?.isSale){
        this.incomeAccounts = this.allAccounts.filter(account => (account.accountType === 'Income' && account.accountSection === 'Income' && !account.isArchive))
      }
      else {
        this.incomeAccounts = this.allAccounts.filter(account => (account.accountSection === 'Expenses' && !account.isArchive))
      }
      this.formErrors = valueChanges(this.createProductForm, { ...this.formErrors }, this.formErrorMessages);
    }
    });

    this.createProductForm.get('sku').valueChanges.pipe(debounceTime(1000),takeUntil(this.unsubscribe)).subscribe(sku => {
      if(sku && (this.productToUpdate?.sku !== sku)) {
        this.spinner.show();
        this.inventoryService.checkSKU(this.businessId, sku)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(resp => {
          if(resp?.success){
            resp.status === 200 ? this.createProductForm.get('sku').setErrors(null) : this.createProductForm.get('sku').setErrors({notUnique: true});
            this.formErrors = valueChanges(this.createProductForm, { ...this.formErrors }, this.formErrorMessages);
          }
        }, (err)=> {
          this.toastr.error(err?.message);
        },()=> {
          this.spinner.hide();
        })
      }
    })

    this.createProductForm.get('itemType').valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(()=> this.manageValidations(this.subscription));

    this.formErrors = valueChanges(this.createProductForm, { ...this.formErrors }, this.formErrorMessages);
    console.log("createProductForm1234", this.createProductForm);
    
  }

  loadStores(): void {
    this.stores$
    .pipe(takeUntil(this.unsubscribe))
    .subscribe((stores: Array<any>) => {
      this.stores = stores;
      const defaultStore = stores.find(store => store?.position === 'Primary');
      if(defaultStore){
        this.createProductForm.get('store').setValue(defaultStore?._id)
      }
    })
  }

  loadAccounts(): void {
    this.accounts$
    .pipe(takeUntil(this.unsubscribe))
    .subscribe(accounts => {
      if (accounts?.length > 0) {
        // this.incomeAccounts = accounts
        // .filter(account =>
        //   ((account.accountType === 'Income' && account.accountSection === 'Income') || account.accountSection === 'Expenses') && !account.isArchive);
        this.incomeAccounts = accounts.filter(account => (account.accountType === 'Income' && account.accountSection === 'Income' && !account.isArchive))
      }        
      this.allAccounts = this.incomeAccounts;
    });
  }

  loadProductData(productId): void {
    this.spinner.show();
    this.inventoryService.getProductById(productId)
    .pipe(takeUntil(this.unsubscribe))
    .subscribe(resp => {
      if(resp?.success){
        
        const productData = resp?.data;
        console.log('productData', productData);
        this.addedFiles = resp?.data?.files;
        this.createProductForm.patchValue({
          name: productData?.name,
          description: productData?.description,
          tax: productData?.tax,
          accountDetails: {...productData?.accountDetails, _id: productData?.accountDetails?.accountId},
          sellingPrice: productData?.sellPrice,
          itemType: productData?.itemType,
          itemCode: productData?.itemCode,
          sku: productData?.sku,
          hsn: productData?.hsn,
          sac: productData?.sac,
          store: this.stores?.find(store => store?._id == productData?.stockDetails?.storeId) ?? null,
          units: productData?.stockDetails?.unit,
          openingStock: productData?.stockDetails?.openingStock ?? 0,
          asOfDate: (productData?.stockDetails?.date)?.split('T')[0],
          lowStock: productData?.stockDetails?.lowStock ?? 0,
          barCode:productData?.barCode,
          isSale: productData?.isSale ? 'true' : 'false',
          category : productData?.category ?? '',
          items: productData?.productRequire ?? null,
          isAutoRun: productData?.isAutoRun ?? null
        })

        this.isSaleUpdate = productData?.isSale

        this.update = true;
        this.productToUpdate = productData;
        for (let i = 0; i < productData?.productRequire.length; i++) {
          console.log('itemRunning');
          (<FormArray>(
            this.createProductForm.get('items')
          )).push(
            this.fb.group({
              itemId: productData?.productRequire[i].itemId,
              quantity: productData?.productRequire[i].quantity,
            })
          );
        }
        this.spinner.hide();
      }
      else {
        this.spinner.hide();
        this.toastr.error(resp?.message);
      }
    },(err) => {
      this.spinner.hide();
      this.toastr.error(err?.message);
    })
  }

  taxChange(taxes): void {
    const mappedTaxes = taxes.map(({taxName, tax, accountDetails}) => ({taxName, tax: this.numberService.toFixed(tax), accountDetails}));
    this.createProductForm.get('tax').setValue(mappedTaxes);
  }

  hsnChange(event, i){
      this.hsnCode = filterHSNSAC('hsn',event.term).slice(0,100);
  }

  sacChange(event, i){
    this.sacCode = filterHSNSAC('sac',event.term).slice(0,100);
  }

  generateBarcode(): void {
    this.spinner.show();
    this.inventoryService.getBarCode(this.businessId, this.createProductForm.get('itemCode').value)
    .pipe(takeUntil(this.unsubscribe))
    .subscribe(resp => {
      if(resp?.success){
        if(!this.createProductForm.get('itemCode').value){
          this.createProductForm.get('itemCode').setValue(resp?.data?.itemCode);
        }
        this.barcode = resp?.data?.barCode;
        if(this.barcode){
          this.createProductForm.get('barCode').setValue(this.barcode);
        }
        this.viewBarcode = true;
        this.spinner.hide();
      }
      else {
        this.spinner.hide();
      }
    }, (err) => {
      this.spinner.hide();
      this.toastr.error(err?.message)
    })
  }

  createProduct(): void {
    console.log("createItem", this.createProductForm);
    
    if(this.createProductForm.invalid){
      this.createProductForm.markAllAsTouched();
      this.createProductForm.markAsDirty();
      this.formErrors = valueChanges(this.createProductForm, {...this.formErrors}, this.formErrorMessages);
      return;
    }
    const formValue = this.createProductForm.value;
    const product = {
      name: formValue?.name,
      description: formValue?.description,
      tax: formValue?.tax,
      accountDetails:{
        accountId: formValue?.accountDetails?._id,
        accountName: formValue?.accountDetails?.accountName,
        accountSection: formValue?.accountDetails?.accountSection
      },
      sellPrice: formValue?.sellingPrice,
      productType: formValue?.productType,
      itemType: formValue?.itemType,
      itemCode: formValue?.itemCode,
      sku: formValue?.sku,
      hsn: formValue?.hsn,
      sac: formValue?.sac,
      isSale: formValue?.isSale,
      category : formValue?.category ?? '',
      productNature: 'complex',
      productRequire: formValue.items,
      isAutoRun: formValue.isAutoRun,
      isManage: formValue.isManage,
      stockDetails: {
        storeId: formValue?.store,
        unit: formValue?.units,
        openingStock: formValue?.openingStock ?? 0,
        date: formValue?.asOfDate,
        lowStock: formValue?.lowStock ?? 0
      },
      barCode:formValue?.barCode
    }
    this.spinner.show();
    this.fileUploadService.emitFiles.next(true);
    this.fileUploadService.emitFiles.next(false);
    const formData = new FormData();
    this.files?.forEach((file,i) => {
      formData.append(`file${i}`, file);
    });
    formData.append('payload', JSON.stringify(product));
    console.log("formValue", formData, formValue, product);
    
    this.inventoryService.createProduct(this.businessId, formData)
    .pipe(takeUntil(this.unsubscribe))
    .subscribe(resp => {
      if(resp?.success){
        this.toastr.success(resp?.message || 'Product Created');
        if(product?.itemType === 'Product' && product?.isSale){
          this.accountingService.getAllAccounts(this.businessId).subscribe((resp) => {
            if (resp.success) {
              this.accountingService.setAccountsInStore(resp.data);
            }
          });
        }
        this.files = [];
        this.createProductForm.reset();
        this.router.navigate(['/inventory/bundled-products'])
      }
      else{
        this.toastr.error(resp?.message);
      }
      this.spinner.hide();
    }, (err) => {
      this.toastr.error(err?.error?.message);
      this.spinner.hide()
    })
  }
  loadCategories(businessId) {
    this.inventoryService.getProdCategoryByBusiness(businessId).subscribe({
      next : (response)=>{
        this.categories = response.data
      },
      error : (error)=>{
        console.log(error)
      }
    })
  }
  loadProductsList(businessId) {
    this.inventoryService.getProductsListByBusiness(businessId).subscribe({
      next : (response)=>{
        console.log("productsList", this.productsList);
        
        this.productsList = JSON.parse(JSON.stringify(response.data));
        this.availableProducts = response.data;
      },
      error : (error)=>{
        console.log(error)
      }
    })
  }
  addNewCategory(category){
    this.createProductForm?.get('category')?.setValue(category)
    if (this.selectRef) {
      this.selectRef.close(); // Close the dropdown
    }
  }
  updateProduct(): void {
    if(this.createProductForm.invalid){
      this.createProductForm.markAllAsTouched();
      this.formErrors = valueChanges(this.createProductForm, {...this.formErrors},this.formErrorMessages);
      return;
    }
    this.spinner.show();
    const formValue = this.createProductForm.value;
    const product = {
    _id: this.productToUpdate?._id,
    businessId: this.businessId,
    name: formValue?.name,
    description: formValue?.description,
    tax: formValue?.tax,
    accountDetails: formValue?.accountDetails,
    sellPrice: formValue?.sellingPrice,
    itemType: formValue?.itemType,
    itemCode: formValue?.itemCode,
    sku: formValue?.sku,
    hsn: formValue?.hsn,
    sac: formValue?.sac,
    isSale: formValue?.isSale,
    category : formValue?.category ?? '',
    productNature: 'complex',
    productRequire: formValue.items,
    isAutoRun: formValue.isAutoRun,
    isManage: formValue.isManage,
    stockDetails: {
      storeId: formValue?.store,
      unit: formValue?.units,
      openingStock: formValue?.openingStock ?? 0,
      date: formValue?.asOfDate,
      lowStock: formValue?.lowStock ?? 0
    },
    barCode:formValue?.barCode
    }
    this.fileUploadService.emitFiles.next(true);
    this.fileUploadService.emitFiles.next(false);
    const formData = new FormData();
    this.files.forEach((file,i) => {
      formData.append(`file${i}`, file);
    });
    formData.append('payload', JSON.stringify(product));
  this.inventoryService.updateProduct(formData, this.productToUpdate?._id)
  .pipe(takeUntil(this.unsubscribe))
  .subscribe(resp => {
    if(resp?.success){
      this.spinner.hide();
      this.toastr.success(resp?.message);
      this.createProductForm.reset();
      this.router.navigate(['/inventory/bundled-products']);
    }
    else {
      this.spinner.hide();
      this.toastr.error(resp?.message);
    }
  },(err) => {
    this.spinner.hide();
    this.toastr.error(err?.message)
  })
  }

  saveFiles(files: File[]): void {
    this.files = files ?? [];
  }

  addNewItem(): void {
    this.items.push(this.createItem());
    this.createProductForm.controls.items.valueChanges.subscribe(items=>{
    })
  }

  get items(): FormArray {
    return this.createProductForm.get('items') as FormArray;
  }

  removeItem(index): void {
    console.log("this.items.controls[index]", this.items.controls[index].value);
    
    this.items.removeAt(index);
    const allIds = this.items.value.map(item=>item.itemId)
    this.availableProducts = this.productsList.filter(el => !allIds.includes(el?._id));
  }

  changeEvent(event, index): void {
    console.log('changeEvent', event);
    
    const { _id, quantity } = event;
    this.items.controls[index].setValue({itemId: _id, quantity: 1, unit: event.stockDetails.unit});
    // this.availableProducts = [...this.productsList];
    const allIds = this.items.value.map(item=>item.itemId)
    this.availableProducts = this.productsList.filter(el => !allIds.includes(el?._id));
    console.log('this.availableProducts', allIds);
  }

  createItem(): FormGroup {
    const itemForm = this.fb.group({
      itemId: [null, [Validators.required]],
      quantity: [1,[Validators.required, Validators.min(1)]],
      unit: [null],
    });
    return itemForm;
  }

}
