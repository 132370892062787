import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { UserService } from 'src/app/shared/services/user.service';
import { ChatsService } from '../chats/chats.service';
import { Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { selectUser } from 'src/app/store/selectors/user.selectors';
import { RootReducerState } from 'src/app/store/reducers';
import { LanguageService } from 'src/app/shared/services/language.service';

@Component({
  selector: 'app-file-manager',
  templateUrl: './file-manager.component.html',
  styleUrls: ['./file-manager.component.scss']
})
export class FileManagerComponent implements OnInit {
  activeRoute = 'files';
  allNotifications: any;
  notificationsCount: any;
  user$: Observable<any>;
  userData: any;
  lang: any;

  constructor(private activatedRoute: ActivatedRoute, private languageService: LanguageService, private store: Store<RootReducerState>, private chatService: ChatsService, private toast : ToastrService, private userService: UserService, private spinner : NgxSpinnerService, private router: Router) {
    console.log(this.activatedRoute);
    console.log(this.router.routerState.snapshot.url);
    this.activeRoute = this.router.routerState.snapshot.url;
    this.user$ = this.store.pipe(select(selectUser));
   }

  ngOnInit(): void {

    this.chatService.listen('getAllNotifications').subscribe((data)=>{
      // console.log('receiving', data);
      
      this.allNotifications = data.filter((item)=>((item.toUser?.includes(this.userData?._id) || item.toUser === this.userData?._id) && (item.fromUser !== this.userData?._id || (item.fromUser === this.userData?._id && item.entityType === 'taskReminder')))).slice().reverse();
      this.notificationsCount = this.allNotifications.filter((item)=>!item.seen).length;
      // console.log('receiving1234', this.allNotifications);
    });
    this.languageService.getLanguage().subscribe((language) => {
      this.lang = language;      
    });
    
    this.user$.subscribe((user) => {
      if (user) {
        this.userData = user;
        this.chatService.emit('getAllNotifications', this.userData._id);
      }
    });
  }


  toggleSidebar(): void {
    document.getElementById("secondary-sidebar").classList.remove("toggle-sidebar");
  }


  logout(): void {
    this.spinner.show();
    this.userService.logoutAPI().subscribe((resp) => {
      this.spinner.hide();
      if (resp.success) {
        this.userService.logout();
      }
    }, (error) => {
      this.spinner.hide();
      console.log(error);
      this.toast.error('Something went wrong');
    })
  }


  goToNotification(item){
    console.log(item);
    switch (item.entityType) {
      case "taskAssigned":
        const payload = {
          seen: true,
          updateId: item._id
        }
        this.chatService.emit('updateNotification', payload);
        this.router.navigate([`/task/view-task/${item.entityId}`]);
        break;
      case "sharedFile":
        const payloadFour = {
          seen: true,
          updateId: item._id
        }
        this.chatService.emit('updateNotification', payloadFour);
        this.router.navigate([`/files/shared`]);
      break;
      case "sharedFile":
        const payloadThree = {
          seen: true,
          updateId: item._id
        }
        this.chatService.emit('updateNotification', payloadThree);
        this.router.navigate([`/files/shared`]);
        break;
      case "taskReminder":
        const payloadTwo = {
          seen: true,
          updateId: item._id
        }
        this.chatService.emit('updateNotification', payloadTwo);
        this.router.navigate([`/task/view-task/${item.entityId}`]);
      break;
      case "mention":
        const payloadOne = {
          seen: true,
          updateId: item._id
        }
        this.chatService.emit('updateNotification', payloadOne);
        this.chatService.setredirectionChatId(item.entityId);
        this.router.navigate([`/chats`]);
        break;
    }

  }
}
