<div class="flex h-screen ">
    <div class="p-8 md:p-14 lg:p-20 w-full lg:w-5/12 max-h-full overflow-y-auto no-scrollbar">
      <div class="mb-12"><a routerLink="/"><img src="assets/images/logo.png" width="150" alt="logo"></a></div>
      <div class="w-full lg:w-5/6">
        <div class="Gilroy-Bold text-3xl mb-1">{{"Verify OTP." | translate }}</div>
        <p class="Gilroy-SemiBold font-color-black-03 py-2">{{"Enter the 6 digit OTP received over email to continue." | translate}}</p>
        <ng-otp-input [formCtrl]="otp" [config]="{length:6, disableAutoFocus:false, containerClass: 'my-5', letterCase: 'Upper'}"></ng-otp-input>
        <app-button  type='primary' size='large' buttonText='Verify OTP'  [disabled]="otp.invalid" (handleClick)='verifyOtp()'></app-button>
        <p class=" Gilroy-SemiBold font-color-black-05 mt-5 text-sm">{{"Didn't received the OTP?" | translate}} <a (click)="resendOtp()" class="text-black cursor-pointer Gilroy-Bold">{{"Resend OTP"| translate}}</a></p>
      </div>
    </div>
    <div class="w-0 lg:w-7/12 flex items-center justify-center bg-light-gray-1 min-h-screen">
      <img src="assets/images/login.png" width="600" alt="" srcset="">
    </div>
</div>
