<app-document-container>
    <div class="w-full">
        <div class="p-8 border-b">
            <div class="text-2xl Gilroy-Bold uppercase text-center">{{invoiceData?.title}}</div>
            <div class="text-xl text-center mb-4">{{invoiceData?.subHeading}}</div>
            <table class="w-full border-collapse">
                <tr>
                    <td vAlign='top' class="w-1/2 ps-8">
                        <table class="border-collapse">
                            <tr>
                                <td class="Gilroy-Bold">{{'Invoice Number' | translate }}</td>
                                <td  class="ps-2">{{invoiceData?.invoiceNumber}}</td>
                            </tr>
                            <tr>
                                <td class="Gilroy-Bold">{{'PO Number' | translate }}</td>
                                <td  class="ps-2">{{invoiceData?.purchaseOrder}}</td>
                            </tr>
                            <tr>
                                <td class="Gilroy-Bold">{{'Invoice Date' | translate }}</td>
                                <td  class="ps-2">{{invoiceData?.invoiceDate | date}}</td>
                            </tr>
                            <tr>
                                <td class="Gilroy-Bold">{{'Due Date' | translate }}</td>
                                <td  class="ps-2">{{invoiceData?.dueDate | date}}</td>
                            </tr>
                        </table>
                    </td>
                    <td class="pe-8 text-end" >
                        <div *ngIf='invoiceData?.companylogo' class="w-[200px] h-[112px] mb-2 ms-auto">
                            <img [src]="invoiceData?.companylogo" alt="">
                        </div>
                        <div class="Gilroy-Bold">{{invoiceData?.businessDetails?.businessId?.companyName}}</div>
                        <div >{{invoiceData?.businessDetails?.businessId?.address?.addressLineFirst}}</div>
                        <div >{{invoiceData?.businessDetails?.businessId?.address?.addressLine2}}</div>
                        <div >{{invoiceData?.businessDetails?.businessId?.address?.city ? invoiceData?.businessDetails?.businessId?.address?.city + ',' : '' }} {{invoiceData?.businessDetails?.businessId?.address?.state || ''}}</div>
                        <div>{{invoiceData?.businessDetails?.businessId?.country}}</div>
                        <div>{{invoiceData?.businessDetails?.businessId?.entityId ?  'Entity ID -' + invoiceData?.businessDetails?.businessId?.entityId : ''}}</div>
                        <div>{{invoiceData?.businessDetails?.businessId?.taxId ? 'Tax ID -' + invoiceData?.businessDetails?.businessId?.taxId : ''}}</div>
                    </td>
                </tr>
            </table>
        </div>
        <div class="py-8 px-12">
            <table class="w-full border-collapse">
                <tr>
                    <td class="w-1/2">
                        <table>
                            <tr>
                                <td class="text-start">
                                    <div class="Gilroy-Bold">{{'BILL TO' | translate }}</div>
                                    <div>{{invoiceData?.customerDetails?.customerId?.customerName | titlecase}}</div>
                                    <div>{{invoiceData?.customerDetails?.customerId?.billingAddress?.addressLine1}}</div>
                                    <div>{{invoiceData?.customerDetails?.customerId?.billingAddress?.addressLine2}}</div>
                                    <div>{{invoiceData?.customerDetails?.customerId?.billingAddress?.city}}, {{invoiceData?.customerDetails?.customerId?.billingAddress?.state}}</div>
                                    <div>{{invoiceData?.customerDetails?.customerId?.billingAddress?.postalCode}}</div>
                                    <div>{{invoiceData?.customerDetails?.customerId?.billingAddress?.country}}</div>
                                    <div>{{invoiceData?.customerDetails?.customerId?.mobileNumber}}</div>
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>
            </table>
            <table class="w-full my-8 border-b">
                <thead>
                    <tr>
                        <td class="p-2" style="background-color: rgb(232, 245, 250);">{{'Item Name' | translate }}</td>
                        <td class="p-2" style="background-color: rgb(232, 245, 250);">{{'SKU' | translate }}</td>
                        <td class="p-2" style="background-color: rgb(232, 245, 250);">{{'HSN/SAC' | translate }}</td>
                        <td class="p-2" style="background-color: rgb(232, 245, 250);">{{'Quantity' | translate }}</td>
                        <td class="p-2" style="background-color: rgb(232, 245, 250);">{{'Unit Price' | translate }}</td>
                        <td class="p-2" style="background-color: rgb(232, 245, 250);">{{'Tax' | translate }}</td>
                        <td class="p-2" style="background-color: rgb(232, 245, 250);">{{'Total Amount' | translate }}</td>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor='let item of invoiceData?.items'>
                        <td class="p-2" >{{item.item}}</td>
                        <td class="p-2" >{{item.sku }}</td>
                        <td class="p-2" >{{item.hsn_sac }}</td>
                        <td class="p-2" >{{item.unit }}</td>
                        <td class="p-2" >{{item.price | currency: invoiceData?.currencyDetails?.currencySymbol : 'symbol-narrow' : number}}</td>
                        <td class="p-2" *ngIf='item?.tax[0]?.tax'>{{item?.tax[0]?.tax}}%</td>
                        <td class="p-2" *ngIf='!item?.tax[0]?.tax'>No Tax</td>
                        <td class="p-2" >{{item.unit * item.price | currency: invoiceData?.currencyDetails?.currencySymbol : 'symbol-narrow' : number}}</td>
                    </tr>
                </tbody>
            </table>
            <table class="w-full mb-8">
                <tr>
                    <td class="w-4/6"></td>
                    <td class="w-2/6" >
                        <table class="w-full">
                            <tr>
                                <td>{{'Subtotal' | translate }}</td>
                                <td class="text-end" >{{invoiceData?.subtotal | currency: invoiceData?.currencyDetails?.currencySymbol : 'symbol-narrow' : number}}</td>
                            </tr>
                            <tr *ngIf='invoiceData?.discount'>
                                <td class="" >{{'Discount:' | translate }} ({{invoiceData?.discount | number : number}}%)</td>
                                <td class="text-end" >-{{invoiceData?.subtotal - invoiceData?.discountValue | currency: invoiceData?.currencyDetails?.currencySymbol : 'symbol-narrow' : number}}</td>
                            </tr>
                            <tr *ngIf='invoiceData?.discount'>
                                <td class="" >{{'Discounted Subtotal' | translate }}</td>
                                <td class="text-end" >{{invoiceData?.discountValue | currency: invoiceData?.currencyDetails?.currencySymbol : 'symbol-narrow' : number}}</td>
                            </tr>
                            <tr>
                                <td class="pb-2">{{'Tax' | translate }}</td>
                                <td class="pb-2 text-end" >{{invoiceData?.tax | currency: invoiceData?.currencyDetails?.currencySymbol : 'symbol-narrow' : number}}</td>
                            </tr>
                            <tr class="border-t">
                                <td class="Gilroy-Bold pt-2">{{'Total' | translate }}</td>
                                <td class="Gilroy-Bold pt-2 text-end">{{invoiceData?.totalAmount | currency: invoiceData?.currencyDetails?.currencySymbol : 'symbol-narrow' : number}}</td>
                            </tr>
                        </table>
                    </td>
                </tr>
            </table>

            <div *ngIf='invoiceData?.notesTerms' class="Gilroy-Bold">{{'Terms and Conditions' | translate }}</div>
            <pre *ngIf='invoiceData?.notesTerms' id='pre' class="whitespace-pre-wrap Gilroy">{{invoiceData?.notesTerms}}</pre>
            <div *ngIf='invoiceData?.eSign' class="flex">
                <div *ngIf='invoiceData?.eSign' class="max-w-sm me-5 mb-5">
                    <h3 class="block text-sm Gilroy-Bold font-color-05 mb-2">{{'Digitally signed document' | translate }}
                    </h3>
                   <div class="px-8 py-5 rounded-xl items-center justify-center">
                        <img class="w-300" src="{{invoiceData?.eSign}}" />
                    </div>
                </div>
            </div>
            <div class="flex my-5 border rounded-xl">
                <div class="w-1/4 p-5">
                    <h3 class="block text-sm Gilroy-Bold font-color-05 mb-2">{{'E-Invoice' | translate }}</h3>
                    <div class="">
                        <img  src="{{invoiceData?.qrCodeWithUuid}}" />
                    </div>
                </div>
                <div *ngIf='invoiceData?.qrCode' class="w-1/4 p-5">
                    <h3 class="block text-sm Gilroy-Bold font-color-05 mb-2">{{invoiceData?.qrHeading}}</h3>
                    <div class="">
                        <img src="{{invoiceData?.qrCode}}" />
                    </div>
                </div>
            </div>
            <div class="w-full justify-end flex space-x-2 rtl:space-x-reverse">
                <h5 class="Gilroy-Bold" >{{"Issued By" | translate }}:</h5>
                <div>{{invoiceData?.userId?.firstName ? invoiceData?.userId?.firstName+' '+invoiceData?.userId?.lastName : ''}}</div>
            </div>
        </div>
    </div>
</app-document-container>
