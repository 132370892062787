
<div class="flex justify-center p-4">
    <div class="w-full flex flex-col space-y-4">
        <div>

            <div class="flex items-center justify-between">
                <div class="">

                    <h1 class="text-2xl Gilroy-Bold inline-flex items-center">
                        <svg _ngcontent-hbd-c18="" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                            stroke="currentColor" class="h-6 w-6 me-2">
                            <path _ngcontent-hbd-c18="" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                d="M16 8v8m-4-5v5m-4-2v2m-2 4h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z">
                            </path>
                        </svg>
                        {{'Vendors' | translate}}
                    </h1>
                </div>
                <div class="">
                    <app-button appAccessControl moduleType='purchase' accessType='edit' routerLink='create-vendor' type='primary' size='large' buttonText='{{"Create a Vendor" | translate}}'></app-button>
                </div>
            </div>

            <div class="flex mt-5">
                    <input type="text" name="search" [(ngModel)]="searchText" autocomplete="off" class="rounded-full mr-3 w-80 text-base h-10 border-black border" placeholder="{{'Search by name' | translate}}" />     
            </div>

            <app-table-container [tableHeadings]='tableHeadings' [tableData]='tableData | filter:searchText' [keys]='tableKeys' [actionTemplate]='actionTemplate'>
                <ng-template let-item #actionTemplate>
                    <td appAccessControl moduleType='purchase' accessType='edit' align="right" class="p-2 last:pr-10 text-center">
                        <app-dropdown #dropdown>
                            <div button (click)='dropdown.open = true' (clickOutside)='dropdown.open = false'>
                                <div class="h-8 w-8 rounded-full flex justify-center items-center bg-gray-50 border border-gray-200">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 m-auto leading-8 text-center" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                                    </svg>
                                </div>
                            </div>
                            <div options>
                                <span (click)='editVendor(item._id)' class="cursor-pointer block px-4 py-2 text-sm Gilroy-Bold hover:bg-gray-50 text-center">{{'Edit' | translate}}</span>
                                <span (click)='deleteConfirmation(item._id)' class="cursor-pointer block px-4 py-2 text-sm Gilroy-Bold hover:bg-gray-50 text-center">{{'Delete' | translate}}</span>
                            </div>
                        </app-dropdown>             
                    </td>
                </ng-template>
            </app-table-container>
            
           
        </div>


    </div>
</div>

<app-modal-container [isOpen]='deleteConfirmationModal'>
    <div header class="border-b pb-2 Gilroy-Bold">Delete Vendor</div>
    <div content>Are you sure?</div>
    <div footer class="flex items-center space-x-2">
        <app-button buttonText='Delete' type='danger' rounded=true (handleClick)='deleteVendor()' ></app-button>
        <app-button buttonText='Cancel' type='third' rounded=true (handleClick)='deleteConfirmationModal = false'></app-button>
    </div>
</app-modal-container>
