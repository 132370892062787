import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { ReferrralService } from 'src/app/shared/services/referrral.service';
import { valueChanges } from 'src/app/shared/utils/formValidator';
import { ResetErrors } from 'src/app/store/actions/reset.actions';
import { UserSignUp } from 'src/app/store/actions/user.actions';
import { IUserLogin } from 'src/app/store/models/user';
import { RootReducerState } from 'src/app/store/reducers';
import {
  selectUserError,
  selectUserLoading,
} from 'src/app/store/selectors/user.selectors';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../auth.service';
@Component({
  selector: 'app-signup-faculty-invite',
  templateUrl: './signup-faculty-invite.component.html',
  styleUrls: ['./signup-faculty-invite.component.scss']
})
export class SignupFacultyInviteComponent implements OnInit {
  signupForm: FormGroup;
  signupRespMessage = '';
  signupError$: Observable<any>;
  signupLoading$: Observable<boolean>;
  showPassword = false;
  showModal: boolean = false;
  country: any = 'Global';
  redirectLink: string = '';
  facultyId:any;
  facultyData:any
  countries = [
    // { countryName: 'Global', _id: 'Global' },
    // { countryName: 'Jordan', _id: 'Jordan' },
    // { countryName: 'Saudi Arabia', _id: 'Saudi Arabia' },
  ];
  universities = []
  formErrors = {
    email: '',
    password: '',
    universityId : '',
  };
  signupErrorMessages = {
    email: {
      required: 'Email is required',
      pattern: 'Email must be in correct format',
    },
    universityId : {
      required : 'Institution/University name is required'
    },
    password: {
      required: 'Password is required',
      minlength: 'Password must be of least 8 characters',
      maxlength: 'Password cannot be more than of 128 characters',
    }
  };

  constructor(
    private fb: FormBuilder,
    private store: Store<RootReducerState>,
    private spinner: NgxSpinnerService,
    private referralService: ReferrralService,
    private toast: ToastrService,
    private router: Router,
    private authService : AuthService,
    private route : ActivatedRoute
  ) {
    this.signupError$ = this.store.pipe(select(selectUserError));
    this.signupLoading$ = this.store.pipe(select(selectUserLoading));
  }

  ngOnInit(): void {
    this.facultyId = this.route.snapshot.paramMap.get('id')
    this.loadForm();
    this.loadAllUniversity()
    this.store.dispatch(ResetErrors());
    this.signupForm.get('country').setValue(this.country);
    this.signupLoading$.subscribe((loading) => {
      loading ? this.spinner.show() : this.spinner.hide();
    });
    this.loadFacultyDetails()
  }
  loadFacultyDetails() {
    this.authService.getInviteFaculty(this.facultyId).subscribe({
      next : (response)=>{
        console.log(response)
        const data = response.data
        this.facultyData = data
        this.signupForm.get('email').patchValue(data.email)
        this.signupForm.get('email').disable()
        this.signupForm.get('universityId').patchValue(data.universityId)
        this.signupForm.get('universityId').disable()
      },
      error : (error)=>{
        console.log(error)
      }
    })
  }
  loadAllUniversity() {
    this.spinner.show()
    this.authService.getAllUniversity().subscribe({
      next : (response)=>{
        this.spinner.hide()
        this.universities = response.data
        this.universities = this.universities.filter((univ:any)=> univ.active)
      },
      error : (error)=>{
        this.spinner.hide()
        console.log(error?.error?.message ?? 'Something went wrong')
      }
    })
  }
  loadForm(): void {
    this.signupForm = this.fb.group({
      email: ['',[Validators.required,Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/),],],
      password: ['',[Validators.required,Validators.minLength(8),Validators.maxLength(128),],],
      country: ['Global'],
      referralCode: [''],
      universityId : [null, [Validators.required]]
    });
    this.signupForm.valueChanges.subscribe((data) => {
      this.formErrors = valueChanges(
        this.signupForm,
        { ...this.formErrors },
        this.signupErrorMessages
      );
    });
    this.formErrors = valueChanges(
      this.signupForm,
      { ...this.formErrors },
      this.signupErrorMessages
    );
  }
  changeUniversity(event:any) {}
  signup() {
    this.spinner.show();
    const payload = {
      firstName : this.facultyData.firstName,
      lastName : this.facultyData.lastName,
      transactionLimit : this.facultyData.transactionLimit,
      email : this.facultyData.email,
      universityId : this.facultyData.universityId,
      password : this.signupForm.value.password,
      country : this.signupForm.value.country,
      referralCode : this.signupForm.value.referralCode,
    }
    console.log(payload)
    this.authService.registerUser(payload).subscribe({
      next : (response)=>{
        this.spinner.hide()
        this.toast.success('Register successfully')
        this.router.navigate(['/auth/login'])
        console.log(response)
      },
      error : (error)=>{
        this.spinner.hide()
        this.toast.error(error?.error?.message ?? 'Something went wrong')
        console.log(error)
      }
    })
  }
  // signup(): void {
  //   this.signupRespMessage = '';
  //   if (this.signupForm.invalid) {
  //     this.signupForm.markAllAsTouched();
  //     this.formErrors = valueChanges(
  //       this.signupForm,
  //       { ...this.formErrors },
  //       this.signupErrorMessages
  //     );
  //     return;
  //   }
  //   if (this.signupForm.value.referralCode) {
  //     this.spinner.show();
  //     this.referralService.getExpertReferralCode().subscribe((resp) => {
  //       if (resp.data.length > 0) {
  //         console.log('check1');

  //         const codeExists = resp.data.filter(
  //           (item) => item.refCode === this.signupForm.value.referralCode
  //         );
  //         if (codeExists.length > 0) {
  //           this.spinner.hide();
  //           console.log('code');

  //           let data = { ...this.signupForm.value };
  //           data.expertReferralCode = this.signupForm.value.referralCode;
  //           const user: IUserLogin = { ...data };
  //           this.store.dispatch(UserSignUp(user));
  //         } else {
  //           this.spinner.hide();
  //           this.toast.error('Invalid Referral Code');
  //         }
  //       } else {
  //         this.spinner.hide();
  //         this.toast.error('Invalid Referral Code');
  //       }
  //     });
  //   } else {
  //     const user: IUserLogin = { ...this.signupForm.value };
  //     this.store.dispatch(UserSignUp(user));
  //   }
  // }

  changeCountry(event): void {
    this.country = event._id;
    this.signupForm.get('country').setValue(event._id);
    if (this.country == 'Jordan' || this.country == 'Saudi Arabia') {
      this.showModal = true;
      this.redirectLink =
        this.country == 'Jordan' ? 'jo.numetric.work' : 'sa.numetric.work';
    }
  }

  toggleModal() {
    this.showModal = !this.showModal;
  }
}
