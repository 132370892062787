<table class="min-w-full border-separate" style="border-spacing: 0 1rem;">
    <thead>
        <tr class="bg-light-gray-1 h-12 mb-4">
            <td class="p-2 Gilroy-Bold first:rounded-s-3xl first:ps-10 last:pe-10 last:rounded-e-3xl last:text-center" *ngFor='let item of tableHeadings'>{{item | translate}}</td>
        </tr>
    </thead>
    <tbody>
        <tr class="box-shadow-sales h-12 rounded-3xl mb-4 py-4 px-6" *ngFor='let item of tableData | paginate:  { itemsPerPage: 10, currentPage: page }'>
            <td [ngClass]='last && !actionTemplate ? "last:text-center pe-10": ""' class="p-2 first:ps-10" *ngFor='let key of keys; let last=last'>
                {{item[key] || '-'}}
            </td>
            <ng-container *ngTemplateOutlet="actionTemplate; context: {$implicit: item}"></ng-container>
        </tr>
        <tr *ngIf='tableData?.length === 0'>
            <td [colSpan]='keys.length + 1' class="text-center">{{ emptyTableMessage || 'No Data Found'}}</td>
        </tr>
    </tbody>
</table>
<pagination-controls autoHide="true" (pageChange)="page = $event"></pagination-controls>