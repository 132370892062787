import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { RootReducerState } from 'src/app/store/reducers';
import { ProjectsService } from '../../projects.service';
import { selectProjects } from "src/app/store/selectors/project.selector";
import { getProjects } from 'src/app/store/reducers/projects.reducer';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { selectBusiness } from 'src/app/store/selectors/business.selector';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { dateLessThan, valueChanges } from 'src/app/shared/utils/formValidator';
import * as moment from 'moment';
import { FileUploadService } from 'src/app/shared/services/file-upload.service';

@Component({
  selector: 'app-list-projects',
  templateUrl: './list-projects.component.html',
  styleUrls: ['./list-projects.component.scss']
})
export class ListProjectsComponent implements OnInit, OnDestroy {

  @ViewChild('fileUploadComponent') fileUploadComponent: ElementRef;
  unsubscribe = new Subject();
  projects$: Observable<any>;
  businessId$: Observable<any>;
  projects = [];
  unfilteredProjects = [];
  files: File[];
  addedFiles = [];
  businessId;
  projectForm: FormGroup;
  filterForm: FormGroup;
  deleteOpenModal = false;
  createUpdateModal = false;
  newProject: boolean = true;
  projectSelected = null;
  tableData;
  formErrors = {
    projectName: '',
  }
  formErrorMessages = { 
    projectName : {
      required: 'Project Name is required'
    },
  }
  
  tableHeadings = [
    'Name',
    'Description',
    'Status',
    'Actions'
  ];

  tableKeys = [
    'projectName',
    'description',
  ];

  constructor(
    private projectService: ProjectsService,
    private fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private rootStore:Store<RootReducerState>,
    private toastr: ToastrService,
    private translateService: TranslateService,
    private router: Router,
    private fileUploadService: FileUploadService
    ) {
      this.projects$ = rootStore.pipe(select(selectProjects));
      this.businessId$ = rootStore.pipe(select(selectBusiness))
     }

  ngOnInit(): void {
    this.getBusinessId();
    this.getProjects();
    this.createForm();
  }

  getBusinessId(){
    this.businessId$.pipe(takeUntil(this.unsubscribe)).subscribe(business=>{
      if(business?.businessId?._id){
        this.businessId = business?.businessId?._id;
      }
    })
  }

  getProjects(){
    this.projects$.subscribe(projects=>{
      this.projects = projects;
      this.unfilteredProjects = projects;
    })
  }

  createForm(){
    this.projectForm = this.fb.group({
      projectName: ['',Validators.required],
      description: ['']
    });
    this.projectForm.valueChanges.subscribe((val) => {
      this.formErrors = valueChanges(this.projectForm, {...this.formErrors}, this.formErrorMessages, this.translateService)
    })
    this.formErrors = valueChanges(this.projectForm, {...this.formErrors}, this.formErrorMessages, this.translateService)
    
  }

  showHideProject(item?){
    this.createUpdateModal = true;
    if(item){
      this.newProject = false;
      this.projectSelected = item;
      // this.addedFiles = item?.files ?? [];
      // this.files = [];
      this.projectForm.patchValue({
        projectName: this.projectSelected.projectName,
        description: this.projectSelected.description
      })
    }
    console.log(this.fileUploadComponent);
    
  }

  closeModal(): void{
    // this.fileUploadComponent['files'] = [];
    // this.fileUploadComponent['filesArrToDisplay'] = [];
    // this.files = [];
    // this.addedFiles = [];
    this.createUpdateModal = false;
  }

  showCloseModal(item){
    this.projectSelected = item;
    this.deleteOpenModal = true;
  }

  handleProject(){
    this.spinner.show();
    if(this.projectForm.invalid){
      this.projectForm.markAllAsTouched();
      this.formErrors = valueChanges(this.projectForm, {...this.formErrors}, this.formErrorMessages, this.translateService);
      this.spinner.hide();
      return
    }
    const formData = new FormData();
    let apiResponse: Observable<any>
    // this.fileUploadService.emitFiles.next(true);
    // this.fileUploadService.emitFiles.next(false);
    // this.files.forEach((file, i) => {
    //   formData.append(`file${i}`, file);
    // });
    if(!this.projectSelected){
      formData.append('payload', JSON.stringify(this.projectForm.value))
      apiResponse = this.projectService.createProject(formData,this.businessId)
    }
    else{
      formData.append('payload', JSON.stringify({...this.projectSelected,...this.projectForm.value}))
      apiResponse = this.projectService.updateProject(formData,this.projectSelected._id)
    }
    apiResponse
    .subscribe(resp=>{
      if(resp.success){
        this.spinner.hide()
        this.toastr.success(resp?.message);
        if(this.projectSelected){
          const newProject = {...this.projectSelected,...this.projectForm.value}
          this.projectService.updateProjectInStore(newProject);
        }
        else
          this.projectService.addProjectInStore(resp.data);
        // this.files = [];
        // this.addedFiles = [];
        // this.fileUploadComponent['files'] = [];
        // this.fileUploadComponent['filesArrToDisplay'] = [];
      }
      else{
        this.toastr.error(this.translateService.instant('Something went wrong!'));
      }
      this.spinner.hide();
      this.createUpdateModal = false;
      this.projectForm.reset()
    },(err)=>{
      this.toastr.success(this.translateService.instant('Something went wrong!'));
      this.spinner.hide()
    })

  }

  changeProjectStatus(){

    this.spinner.show()
    this.projectService.updateProject({...this.projectSelected,isActive:!this.projectSelected?.isActive},this.projectSelected._id).subscribe(resp=>{
      this.deleteOpenModal = false;
      if(resp?.success){
        this.toastr.success(resp?.message)
        const newProject = {...this.projectSelected,isActive:!this.projectSelected?.isActive}
        this.projectService.updateProjectInStore(newProject);
      }
      else
      this.toastr.success(this.translateService.instant('Something went wrong!'))
      this.spinner.hide()
    },(err)=>this.spinner.hide())

  }
  
  showDetails(id){
    this.router.navigate([`/projects/${id}`])
  }

  // saveFiles(files: File[]): void{
  //   this.files = files;
  // }


  ngOnDestroy(){
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
