import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {
  currentLanguage: string = 'en';
  email: FormControl = new FormControl('', [
    Validators.required,
    Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]);

  constructor() { }

  ngOnInit(): void {
    if(window.innerWidth < 640) {
      document.getElementById("nav-content").classList.add("hidden");
      document.getElementById("nav-content1").classList.add("hidden");
    }
    else {
      document.getElementById("nav-content").classList.remove("hidden");
      document.getElementById("nav-content1").classList.remove("hidden");
    }
    this.currentLanguage = localStorage.getItem('NuMetric|lang') || 'en';
    let prevScrollpos = window.pageYOffset;
    const mainNavLinks = document.querySelectorAll('nav a');
    window.onscroll = () => {
      const fromTop = window.scrollY + 200;
      const currentScrollPos = window.pageYOffset;
      if (window.pageYOffset > 50) {
        if (prevScrollpos > currentScrollPos) {
          document.getElementById('navbar').classList.remove('scrollUp');
        } else {
          document.getElementById('navbar').classList.add('scrollUp');
          if(window.innerWidth < 640) {
            document.getElementById("nav-content").classList.add("hidden");
            document.getElementById("nav-content1").classList.add("hidden");
          }
          else {
            document.getElementById("nav-content").classList.remove("hidden");
            document.getElementById("nav-content1").classList.remove("hidden");
          }
        }
        prevScrollpos = currentScrollPos;
      }

      mainNavLinks.forEach((link: HTMLAnchorElement) => {
        if (link?.hash) {
          const section: HTMLElement = document.querySelector(link?.hash);
          if ( section?.offsetTop <= fromTop && section?.offsetTop + section?.offsetHeight > fromTop ) {
            link.classList.add('current');
          } else {
            link.classList.remove('current');
          }
        }
      });
    };
  }
  
  toggle(){
    document.getElementById("nav-content").classList.toggle("hidden");
    document.getElementById("nav-content1").classList.toggle("hidden");
  }

  changeLanguage(language): void{
    localStorage.setItem('NuMetric|lang', language);
    location.reload();
  }

}
