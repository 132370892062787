<div class="flex justify-center p-4">
    <div class="w-full flex flex-col space-y-4">
      <div class="flex items-center justify-between">
        <h1 class="text-2xl Gilroy-Bold inline-flex items-center">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 me-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z" />
          </svg>
          {{'Purchase Order' | translate}}
        </h1>
      </div>
      <div class="flex space-x-2 rtl:space-x-reverse mb-4">
        <app-button appAccessControl moduleType='purchase' accessType='edit' (click)='convertToBill()' type='primary' buttonText='{{"Convert to Bill" | translate}}'></app-button>
        <app-button (handleClick)='downloadPdf()' type='primary' buttonText='{{"Download PDF" | translate}}'></app-button>
        <!-- <app-button (click)='emailModal = true' type='primary' buttonText='{{"Share via Email" | translate}}'></app-button> -->
        <div class="border rounded-xl flex items-center ps-2">
          <a [href]='shareURL' class="max-w-[250px] overflow-hidden whitespace-nowrap overflow-ellipsis">
            {{shareURL}}
          </a>
          <app-button type='primary' buttonText='{{copyButtonText | translate}}' (handleClick)='copyLink()'></app-button>
        </div>
      </div>
      <table *ngIf='purchaseOrderDetail' class="w-4/5 m-auto d-block overflow-hidden box-shadow-sales rounded-xl">
        <div class="p-12">
          <table class="w-full" style="border-collapse: collapse;">
            <tr>
              <td valign='top' >
                <div>
                  <h1 class="text-2xl font-semibold">{{'Purchase Order' | translate}}</h1>   
                </div>
                <div class="mb-4">{{purchaseOrderDetail.subheading}}</div>
              </td> 
              <td class="pe-8 text-end" style='width: 50%;'></td>
            </tr>
            <tr>
              <td valign='top' >
                <div>{{purchaseOrderDetail.businessId.companyName}}</div>
                <div>{{purchaseOrderDetail?.businessId?.address?.addressLineFirst}}</div>
                <div>{{purchaseOrderDetail?.businessId?.address?.addressLine2}}</div>
                <div>{{purchaseOrderDetail?.businessId?.address?.city ? purchaseOrderDetail?.businessId?.address?.city + ',' : ''}} {{purchaseOrderDetail?.businessId?.address?.state}}</div>
                <div>{{purchaseOrderDetail.businessId.country}}</div>
              </td>
              <td  class="text-end pe-8" style='width: 50%;'>
             
              </td>
            </tr>
          </table>
        </div>
        <div class="px-12" >
          <table class="w-full" style="border-collapse: collapse;">
            <tr>
              <td>
                <table>
                  <tr>
                    <td>
                      <div class="Gilroy-Bold">{{'BILL TO' | translate}}</div> 
                      <div>{{purchaseOrderDetail?.vendor?.vendorName}}</div>
                      <div>{{purchaseOrderDetail?.vendor?.vendorId?.email}}</div> 
                      <div>{{purchaseOrderDetail?.vendor?.vendorId?.address?.addressLine1}}</div> 
                      <div>{{purchaseOrderDetail?.vendor?.vendorId?.address?.addressLine2}}</div> 
                      <div>{{purchaseOrderDetail?.vendor?.vendorId?.address?.city}}, {{purchaseOrderDetail?.vendor?.vendorId?.address?.state}}</div> 
                      <div>{{purchaseOrderDetail?.vendor?.vendorId?.address?.postalCode}}</div> 
                      <div>{{purchaseOrderDetail?.vendor?.vendorId?.address?.country}}</div> 
                    </td>
                  </tr>
                </table>
              </td>
              <td class="w-1/2"  >
                <table class="w-full" style="border-collapse: collapse;">
                  <tr>
                    <td class="Gilroy-Bold">{{'P.O. Number:' | translate}}</td>
                    <td  class="text-end ps-4" >{{purchaseOrderDetail.purchaseOrder}}</td>
                  </tr>
                  <tr>
                    <td class="Gilroy-Bold">{{'P.O Date:' | translate }}</td>
                    <td  class="text-end ps-4">{{purchaseOrderDetail.date | date}}</td>
                  </tr>
                  <tr>
                    <td class="Gilroy-Bold">{{'Due Date:' | translate}}</td>
                    <td  class="text-end ps-4">{{purchaseOrderDetail.dueDate | date}}</td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
          <table class="w-full my-8" style="margin-top: 2em; margin-bottom: 2em; border-bottom: 1px solid #0C4D71;">
            <thead class="bg-light-gray-1 h-12 ps-2 pe-2">
              <tr>  
                <td align="left" class="Gilroy-Bold ps-2">{{'Item Name' | translate}}</td>
                <td align="center" class="Gilroy-Bold">{{'SKU' | translate}}</td>
                <td align="center" class="Gilroy-Bold">{{'HSN/SAC' | translate}}</td>
                <td align="center" class="Gilroy-Bold">{{'Quantity' | translate}}</td>
                <td align="center" class="Gilroy-Bold">{{'Unit Price' | translate}}</td>
                <td align="center" class="Gilroy-Bold">{{'Tax' | translate}}</td>
                <td align="right" class="Gilroy-Bold pe-2">{{'Total Amount' | translate}}</td>
              </tr>
            </thead>
            <tbody>
              <tr class="row-1" *ngFor="let item of purchaseOrderDetail.items">
                <td align="left" style="padding: 0.5em;">
                    <h2 class="Gilroy-Bold">{{item.item}}</h2>
                </td>
                <td align="center" style="padding: 0.5em;">{{item.sku}}</td>
                <td align="center" style="padding: 0.5em;">{{item.hsn_sac}}</td>
                <td align="center" style="padding: 0.5em;">{{item.unit}}</td>
                <td align="center" style="padding: 0.5em;">{{item.price | currency: currencyDetails?.currency : 'symbol-narrow' : number}}</td>
                <td align="center" class="p-2" *ngIf='item?.tax && item?.tax[0]?.tax'>{{item?.tax[0]?.tax + '%' }}</td>
                <td align="center" class="p-2" *ngIf='!item?.tax'>No Tax</td>
                <td align="right" style="padding: 0.5em;">{{item.unit * item.price | currency: currencyDetails?.currency : 'symbol-narrow' : number}}</td>
              </tr>
            </tbody>
          </table>
          <table class="w-full" style="margin-bottom: 2em;">
            <tr>
              <td style="width: 65%;"></td>
              <td align="right" style="width: 35%;">
                <table class="w-full">
                  <tr>
                    <td>{{'Subtotal:' | translate}}</td>
                    <td align="right">{{purchaseOrderDetail.subtotal | currency: currencyDetails?.currency : 'symbol-narrow' : number}}</td>
                  </tr>
                  <tr *ngIf='purchaseOrderDetail.tax'>
                    <td style="padding-bottom: 0.5em;">{{'Tax' | translate}}</td>
                    <td align="right" style="padding-bottom: 0.5em;">{{purchaseOrderDetail.tax | currency: currencyDetails?.currency : 'symbol-narrow' : number}}</td>
                  </tr>
                  <tr class="border-top" style="border-top: 1px solid #0C4D71;">
                    <td class="bold" style="font-weight: bold; padding-top: 0.5em;">{{'Total' | translate}}</td>
                    <td align="right" class="bold" style="font-weight: bold; padding-top: 0.5em;">{{purchaseOrderDetail.toatalAmount | currency: currencyDetails?.currency : 'symbol-narrow' : number}}</td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
        </div>
        <div *ngIf='purchaseOrderDetail.notes' class="px-16" >
          <div class="Gilroy-Bold">{{'Notes' | translate}}</div>
          <pre class="whitespace-pre-wrap Gilroy">{{purchaseOrderDetail.notes}}</pre>
        </div>
        <div class="mx-16" style="padding-top: 1em; padding-bottom: 1em; text-align: center; border-top: 1px solid #000; margin-top: 0.5em;" ></div>
        <!-- <div class="my-4 p-3 flex flex-col justify-between">
          <app-button appAccessControl moduleType="storage" accessType="edit" type='primary' class="mb-3 ml-auto" buttonText='{{"Upload Files" | translate}}' size='large' (handleClick)="uploadFiles()" ></app-button>
          <app-file-upload appAccessControl moduleType="storage" accessType="view" [addedFiles]="addedFiles" (emitter)="saveFiles($event)" ></app-file-upload>
        </div> -->
        <div class="mx-16" style="padding-top: 1em; padding-bottom: 1em; text-align: center; border-top: 1px solid #000; margin-top: 0.5em;" >{{purchaseOrderDetail.footer}}</div>
        <div class="flex justify-end space-x-2 mx-16 rtl:space-x-reverse" >
          <h4 class="Gilroy-Bold" >{{'Issued By'}}:</h4>
          <span>{{purchaseOrderDetail?.userId?.firstName+' '+purchaseOrderDetail?.userId?.lastName}}</span>
        </div>
      </table>
    </div>
  </div>
  
  <app-modal-container [isOpen]='emailModal'>
    <div header class="Gilroy-Bold">{{'Share Purchase Order via email' | translate}}</div>
    <div content class="flex flex-col">
      <label for="">{{'Email' | translate}}</label>
      <input [formControl]='email' type="text" class="w-full rounded-xl bg-gray-100 border-0 p-3">
      <div *ngIf='email.getError("required") && email.dirty' class="text-sm text-red-400 ms-2">{{'Email is required' | translate}}</div>
      <div *ngIf='email.getError("pattern") && email.dirty' class="text-sm text-red-400 ms-2">{{'Email must be in correct format' | translate}}</div>
      <div *ngIf='error' class="text-red-500 ms-2">{{error}}</div>
      <div *ngIf='response' class="text-green-500 ms-2">{{response}}</div>
    </div>
    <div footer class='flex space-x-2 rtl:space-x-reverse'>
      <app-button type='primary' buttonText='{{"Send" | translate}}' (handleClick)='sendEmail()'></app-button>
      <app-button type='third' buttonText='{{"Cancel" | translate}}' (handleClick)='emailModal = false'></app-button>
    </div>
  </app-modal-container>
  