<div class="flex justify-center p-4">
  <div class="w-full flex flex-col space-y-4">
    <div class="flex items-center justify-between">
      <div class="">
        <h1 *ngIf='!update' class="text-2xl Gilroy-Bold inline-flex items-center">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 me-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z" />
          </svg>
          {{'Add New Customer' | translate}}
        </h1>
        <h1 *ngIf='update' class="text-2xl Gilroy-Bold inline-flex items-center">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z" />
          </svg>
          {{'Update Customer' | translate}}
        </h1>
      </div>
    </div>
    <div class="w-3/5 m-auto mt-5">
      <form class="formSelect" [formGroup]='createCustomerForm'>
        <div class="rounded-3xl px-8 box-shadow-sales bg-white mt-2 mb-4">
          <div class="bg-profile py-6">
            <h3 class="Gilroy-Bold text-lg mb-4">{{'Update Customer' | translate}}</h3>
          </div>
          <div class="space-y-6 w-full">
            <div class="flex space-x-8 rtl:space-x-reverse w-full">
              <div class="flex-1 mb-4">
                <label for='customerName' class="block text-sm Gilroy-Bold font-color-05">{{"Customer Name" | translate}}</label>
                <input type="" id='customerName' formControlName='customerName'
                  class="bg-input h-12 rounded-xl w-full ps-5 " placeholder="{{'Customer Name' | translate}}" />
                <div class="text-sm text-red-500 ms-2 mt-1" *ngIf='formErrors.customerName'>
                  {{formErrors.customerName}}</div>
              </div>
              <div class="flex-1 mb-4"></div>
            </div>
            <div class="flex space-x-8 rtl:space-x-reverse w-full">
              <div class="flex-1 mb-4">
                <label for='firstName' class="block text-sm Gilroy-Bold font-color-05">{{"First Name" | translate}}</label>
                <input id="firstName" formControlName="firstName" type=""
                  class="bg-input h-12 rounded-xl w-full ps-5 " placeholder="{{'First Name' | translate}}" />
                <div class="text-sm text-red-500 ms-2 mt-1" *ngIf='formErrors.firstName'>
                  {{formErrors.firstName}}</div>
              </div>
              <div class="flex-1 mb-4">
                <label for='lastName' class="block text-sm Gilroy-Bold font-color-05">{{'Last Name' | translate}}</label>
                <input id="lastName" formControlName="lastName" type="" class="bg-input h-12 rounded-xl w-full ps-5"
                  placeholder="{{'Last Name' | translate}}" />
                <div class="text-sm text-red-500 ms-2 mt-1" *ngIf='formErrors.lastName'>
                  {{formErrors.lastName}}</div>
              </div>
            </div>

            <div class="flex space-x-8 rtl:space-x-reverse w-full">
              <div class="flex-1 mb-4">
                <label for='countryCode' class="block text-sm Gilroy-Bold font-color-05">{{'Country Code' | translate}}</label>
                <fieldset >
                  <ng-select [items]="countryWithCode"
                    class="custom-selector" 
                    bindLabel="text" 
                    bindValue="code"
                    formControlName="countryCode"
                    placeholder='{{"Select Country Code" | translate}}'>
                  </ng-select>
                  <div class="text-red-500" *ngIf='formErrors.countryCode'>{{formErrors.countryCode}}</div>
                </fieldset>
              </div>
              <div class="flex-1 mb-4">
                <label for='mobileNumber' class="block text-sm Gilroy-Bold font-color-05">{{'Mobile Number' | translate}}</label>
                <input id="mobileNumber" pattern="^[1-9][0-9]*$" oninput="if(!this.value.match('^[1-9][0-9]*$'))this.value='';" formControlName="mobileNumber" type="number"
                  class="bg-input h-12 rounded-xl w-full ps-5 " placeholder="{{'Mobile Number' | translate}}" />
                <div class="text-sm text-red-500 ms-2 mt-1" *ngIf='formErrors.mobileNumber'>
                  {{formErrors.mobileNumber}}</div>
              </div>
            </div>
            <div class="flex space-x-8 rtl:space-x-reverse w-full">
              <div class="flex-1 mb-4">
                <label for='email' class="block text-sm Gilroy-Bold font-color-05">{{'Email Address' | translate}}</label>
                <input id="email" formControlName="email" type="" class="bg-input h-12 rounded-xl w-full ps-5"
                  placeholder="{{'Email Address' | translate}}" />
                <div class="text-sm text-red-500 ms-2 mt-1" *ngIf='formErrors.email'>
                  {{formErrors.email}}</div>
              </div>
              <div class="flex-1 mb-4">
                <label for='taxId' class="block text-sm Gilroy-Bold font-color-05">{{'Tax ID' | translate}}</label>
                <input id="taxId" formControlName="taxId" type=""
                  class="bg-input h-12 rounded-xl w-full ps-5 " placeholder="{{'Tax Id' | translate}}" />
              </div>
            </div>

            <div class="flex space-x-8 rtl:space-x-reverse w-full">
              <div class="flex-1 mb-4">
                <label for='tollFree' class="block text-sm Gilroy-Bold font-color-05">{{'Landline' | translate}}</label>
                <input id="tollFree" formControlName="tollFree" type="number" class="bg-input border-0 h-12 rounded-xl w-full ps-5 "
                  placeholder="{{'Landline' | translate}}" />
                  <div class="text-sm text-red-500 ms-2 mt-1" *ngIf='formErrors.tollFree'>
                    {{formErrors.tollFree}}
                  </div>
              </div>
              <div class="flex-1 mb-4">
                <label for='fax' class="block text-sm Gilroy-Bold font-color-05">{{'Fax' | translate}}</label>
                <input id="fax" formControlName="fax" type="" class="bg-input h-12 rounded-xl w-full ps-5"
                  placeholder="{{'Fax' | translate}}" />
              </div>
            </div>

            <div class="flex space-x-8 rtl:space-x-reverse w-full">
              <div class="flex-1 mb-4">
                <label for='webSite' class="block text-sm Gilroy-Bold font-color-05">{{'Website' | translate}}</label>
                <input id="webSite" formControlName="webSite" type="text" class="bg-input border-0 h-12 rounded-xl w-full ps-5 "/>
              </div>
            </div>

            <div class="flex space-x-8 rtl:space-x-reverse w-full">
              <div class="flex-1 mb-4">
                <label for='notes' class="block text-sm Gilroy-Bold font-color-05">{{'Notes' | translate}}</label>
                <textarea id="notes" formControlName="notes" class="bg-input max-h-60 border-0 rounded-xl w-full ps-5"
                  placeholder="{{'Add some notes' | translate}}"></textarea>
              </div>
            </div>
          </div>
        </div>
        <div class="rounded-3xl px-8 box-shadow-sales bg-white mt-2 mb-4">
          <div class="bg-profile py-6">
            <h3 class="Gilroy-Bold text-lg mb-4">{{'Billing Details' | translate}}</h3>
          </div>
          <div sclass="space-y-6 w-full">
            <div class="flex space-x-8 rtl:space-x-reverse w-full">
              <div class="flex-1 mb-4">
                  <label for='addressLine1' class="block text-sm Gilroy-Bold font-color-05">{{'Address Line 1' | translate}}</label>
                  <input id="addressLine1" formControlName="addressLine1" type="" class="bg-input h-12 rounded-xl w-full ps-5 " placeholder="{{'Address Line 1' | translate}}" />
                  <div class="text-sm text-red-500 ms-2 mt-1" *ngIf='formErrors.addressLine1'>
                      {{formErrors.addressLine1}}</div>
              </div>
            </div>
            <div class="flex space-x-8 rtl:space-x-reverse w-full">
              <div class="flex-1 mb-4">
                <label for='addressLine2' class="block text-sm Gilroy-Bold font-color-05">{{'Address Line 2' | translate}}</label>
                <input id="addressLine2" formControlName="addressLine2" type="" class="bg-input h-12 rounded-xl w-full ps-5 " placeholder="{{'Address Line 2' | translate}}" />
              </div>
            </div>
            <div class="flex space-x-8 rtl:space-x-reverse w-full">
              <div class="flex-1 mb-4">
                  <label for='country' class="block text-sm Gilroy-Bold font-color-05">{{'Country' | translate}}</label>
                  <ng-select [items]="countries"
                    class="custom-selector" 
                    bindLabel="text" 
                    bindValue="text"
                    formControlName="country"
                    (change)='onBillingCountryChange($event)'>
                  </ng-select>
                  <div class="text-sm text-red-500 ms-2 mt-1" *ngIf='formErrors.country'>{{formErrors.country}}</div>
              </div>
              <div class="flex-1 mb-4">
                  <label for="state" class="text-sm Gilroy-Bold font-color-05">{{'State' | translate}}</label>
                  <ng-select [items]="billingStates"
                    class="custom-selector" 
                    bindLabel="text" 
                    bindValue="text"
                    formControlName="state">
                  </ng-select>
                  <!-- <select formControlName='state' id='state-select' class="bg-input h-12 rounded-xl w-full ps-5 pr-5">
                  </select> -->
                  <div class="text-sm text-red-500 ms-2 mt-1" *ngIf='formErrors.state'>
                      {{formErrors.state}}</div>
              </div>
            </div>
            <div class="flex space-x-8 rtl:space-x-reverse w-full">
              <div class="flex-1 mb-4">
                  <label for='city' class="block text-sm Gilroy-Bold font-color-05">{{'City' | translate}}</label>
                  <input id="city" formControlName="city" type="" class="bg-input h-12 rounded-xl w-full ps-5 " placeholder="{{'City' | translate}}" />
                  <div class="text-sm text-red-500 ms-2 mt-1" *ngIf='formErrors.city'>
                      {{formErrors.city}}</div>
              </div>
              <div class="flex-1 mb-4">
                  <label for="postalCode" class="text-sm Gilroy-Bold font-color-05">{{'Postal Code' | translate}}</label>
                  <input id="postalCode" formControlName="postalCode" type="" class="bg-input h-12 rounded-xl w-full ps-5 " placeholder="{{'Postal Code' | translate}}" />
                  <div class="text-sm text-red-500 ms-2 mt-1" *ngIf='formErrors.postalCode'>
                      {{formErrors.postalCode}}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="rounded-3xl px-8 box-shadow-sales bg-white mt-2 mb-4">
          <div class="bg-profile py-6 flex items-center">
            <span class="Gilroy-Bold text-lg me-4">{{'Shipping Details' | translate}}</span>
            <span class="me-2">{{'Same as Billing' | translate}}</span>
            <input type="checkbox" name="sameAsBilling" (change)='sameAsBilling()' [(ngModel)]='sameAsBillingAddress' [ngModelOptions]="{standalone: true}">
          </div>
          <div class="flex-1 mb-4">
              <label for='shipTo' class="block text-sm Gilroy-Bold font-color-05">{{'Ship To' | translate}}</label>
              <input type="" id='shipTo' formControlName='shipTo'
                class="bg-input h-12 rounded-xl w-full ps-5 " placeholder="{{'Customer Name' | translate}}" />
              <div class="text-sm text-red-500 ms-2 mt-1" *ngIf='formErrors.shipTo'>
                {{formErrors.shipTo}}</div>
            </div>
            <div class="flex-1 mb-4"></div>
          <div class="space-y-6 w-full">
            <div class="flex space-x-8 rtl:space-x-reverse w-full">
              <div class="flex-1 mb-4">
                  <label for='shippingaddressLine1' class="block text-sm Gilroy-Bold font-color-05">{{'Address Line 1' | translate}}</label>
                  <input id="shippingaddressLine1" formControlName="shippingaddressLine1" type="" class="bg-input h-12 rounded-xl w-full ps-5 " placeholder="{{'Address Line 1' | translate}}" />
                  <div class="text-sm text-red-500 ms-2 mt-1" *ngIf='formErrors.shippingaddressLine1'>
                      {{formErrors.shippingaddressLine1}}</div>
              </div>
            </div>
            <div class="flex space-x-8 rtl:space-x-reverse w-full">
              <div class="flex-1 mb-4">
                <label for='shippingLine2' class="block text-sm Gilroy-Bold font-color-05">{{'Address Line 2' | translate}}</label>
                <input id="shippingLine2" formControlName="shippingLine2" type="" class="bg-input h-12 rounded-xl w-full ps-5 " placeholder="{{'Address Line 2' | translate}}" />
              </div>
            </div>
            <div class="flex space-x-8 rtl:space-x-reverse w-full">
              <div class="flex-1 mb-4">
                  <label for='country-select2' class="block text-sm Gilroy-Bold font-color-05">{{'Country' | translate}}</label>
                  <ng-select [items]="countries"
                    class="custom-selector" 
                    bindLabel="text" 
                    bindValue="text"
                    formControlName="shippingcountry"
                    (change)='onShippingCountryChange($event)'>
                  </ng-select>
                  <div class="text-sm text-red-500 ms-2 mt-1" *ngIf='formErrors.country'>{{formErrors.country}}</div>
              </div>
              <div class="flex-1 mb-4">
                  <label for="state-select2" class="text-sm Gilroy-Bold font-color-05">{{'State' | translate}}</label>
                  <ng-select [items]="shippingStates"
                    class="custom-selector" 
                    bindLabel="text" 
                    bindValue="text"
                    formControlName="shippingstate">
                  </ng-select>
                  <div class="text-sm text-red-500 ms-2 mt-1" *ngIf='formErrors.state'>{{formErrors.state}}</div>
              </div>
            </div>
            <div class="flex space-x-8 rtl:space-x-reverse w-full">
              <div class="flex-1 mb-4">
                  <label for='shippingcity' class="block text-sm Gilroy-Bold font-color-05">{{'City' | translate}}</label>
                  <input id="shippingcity" formControlName="shippingcity" type="" class="bg-input h-12 rounded-xl w-full ps-5 " placeholder="{{'City' | translate}}" />
                  <div class="text-sm text-red-500 ms-2 mt-1" *ngIf='formErrors.shippingcity'>
                      {{formErrors.shippingcity}}</div>
              </div>
              <div class="flex-1 mb-4">
                  <label for="shippingpostalCode" class="text-sm Gilroy-Bold font-color-05">{{'Postal Code' | translate}}</label>
                  <input id="shippingpostalCode" formControlName="shippingpostalCode" type="" class="bg-input h-12 rounded-xl w-full ps-5 " placeholder="{{'Postal Code' | translate}}" />
                  <div class="text-sm text-red-500 ms-2 mt-1" *ngIf='formErrors.shippingpostalCode'>
                      {{formErrors.shippingpostalCode}}</div>
              </div>
            </div>

            <div class="flex space-x-8 rtl:space-x-reverse w-full">
              <div class="flex-1 mb-4">
                  <label for='shippingmobileNumber' class="block text-sm Gilroy-Bold font-color-05">{{'Mobile Number' | translate}}</label>
                  <input id="shippingmobileNumber" formControlName="shippingmobileNumber" type="number" class="bg-input border-0 h-12 rounded-xl w-full ps-5 " placeholder="{{'Mobile Number' | translate}}" />
                  <div class="text-sm text-red-500 ms-2 mt-1" *ngIf='formErrors.shippingmobileNumber'>
                      {{formErrors.shippingmobileNumber}}
                    </div>
              </div>
              <div class="flex-1 mb-4">
                  <label for="shippingemailAddress" class="text-sm Gilroy-Bold font-color-05">{{'Email Address' | translate}}</label>
                  <input id="shippingemailAddress" formControlName="shippingemailAddress" type="" class="bg-input h-12 rounded-xl w-full ps-5 " placeholder="{{'Email Address' | translate}}" />
              </div>
            </div>
            <div class="flex space-x-8 rtl:space-x-reverse w-full">
              <div class="flex-1 mb-4">
                  <label for='instruction' class="block text-sm Gilroy-Bold font-color-05">{{'Delivery Instructions' | translate}}</label>
                  <textarea id="instruction" formControlName="instruction" type="" class="bg-input h-44 rounded-xl border-0 w-full ps-5 " placeholder="{{'Write Here...' | translate}}"></textarea>
              </div>
            </div>
          </div>
        </div>
        <div appAccessControl moduleType='sales' accessType='edit' class="mb-4 float-end space-x-2 rtl:space-x-reverse">
          <app-button *ngIf='!update' buttonText='{{"Save" | translate}}' [rounded]='true' size='large' type='primary' (handleClick)="createCustomer()"></app-button>
          <app-button *ngIf='update'  buttonText='{{"Update" | translate}}' [rounded]='true' size='large' type='primary' (handleClick)="updateCustomer()"></app-button>
          <app-button routerLink='..' buttonText='{{"Cancel" | translate}}' [rounded]='true' size='large' type='third'></app-button>
        </div>
      </form>
    </div>
  </div>
</div>
