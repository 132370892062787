<div class="flex justify-center p-4">
    <div class="w-[90%] flex flex-col">
        <div class="flex items-center mb-4">
            <h1 class="text-2xl Gilroy-Bold inline-flex items-center">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-7 w-7 me-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                </svg>
                {{'Subscriptions' | translate }}
            </h1>
        </div>
        <div class="flex items-baseline space-x-4 rtl:space-x-reverse mb-4">
            <a routerLink='subscription-plans' routerLinkActive='underline' class="Gilroy-Bold text-lg">{{'Subscription Plans' | translate }}</a>
            <a routerLink='subscription-history' routerLinkActive='underline' class="Gilroy-Bold text-lg">{{'Subscription History' | translate }}</a>
        </div>
        <div class="bg-gradient rounded-3xl p-8 mb-4">
            <div *ngIf="currentSubscription else noSubscription" class="flex justify-between">
                <div class="flex space-x-8 rtl:space-x-reverse">
                    <div>
                        <p class="Gilroy-SemiBold text-white text-md">{{'Current Plan' | translate }}</p>
                        <p class="Gilroy-SemiBold text-white text-xl">{{currentSubscription.planName}}</p>
                    </div>
                    <!-- <div *ngIf='currentSubscription.nextPlan'>
                        <p class="Gilroy-SemiBold text-white text-md">{{'Next Plan' | translate }}</p>
                        <p class="Gilroy-SemiBold text-white text-xl">{{currentSubscription.nextPlan}}</p>
                    </div> -->
                </div>
                <div>
                    <p class="Gilroy-SemiBold text-white text-sm text-end mb-1 opacity-75">{{'Subscription Date:' | translate }} {{currentSubscription.date | date}}</p>
                    <p *ngIf="currentSubscription.planName != 'Starter'" class="Gilroy-SemiBold text-white text-sm text-end opacity-75">{{'Subscription Validity:' | translate }} {{currentSubscription.expiryDate | date}}</p>
                    <p class="Gilroy-SemiBold text-white text-sm text-end opacity-75" >{{'Remaining Usage' | translate }}: {{remainingLimit >= 0 ? remainingLimit : "Unlimited"}}</p>
                    <p class="Gilroy-SemiBold text-white text-sm text-end opacity-75" >{{'Total Storage' | translate }}: {{currentSubscription?.totalStorage ?? 0 + ' GB' }}</p>

                </div>
            </div>
            <ng-template #noSubscription>
                <p class="Gilroy-SemiBold text-white text-md">{{'No plan subscribed yet.' | translate }}</p>
            </ng-template>
        </div>
        <router-outlet></router-outlet>
    </div>
</div>