<h2 class="text-2xl Gilroy-Bold mb-4">{{title | translate }}</h2>
<div class="p-5 border flex space-x-4 rtl:space-x-reverse mb-4 rounded-xl flex-wrap gap-4">
    <div class="flex flex-col" [ngClass]="lastDate ? 'flex': 'hidden'">
        <label class="block Gilroy-Bold b">{{'From Date' | translate }}</label>
        <input type="date"  [formControl]='startDate' class="p-3 bg-input w-full border-0 rounded-xl">
        <div *ngIf='startDate.dirty && startDate.getError("invalidDate")' class="text-red-500 ms-4">{{"Invalid Date" | translate }}</div>
    </div>
    <div class="flex flex-col" [ngClass]="asOfDate ? 'flex' : 'hidden'" >
        <label class="block Gilroy-Bold b" *ngIf='!lastDate'>{{'As of Date' | translate }}</label>
        <label class="block Gilroy-Bold" *ngIf='lastDate'>{{'End Date' | translate }}</label>
        <input type="date"  [formControl]='endDate' class="p-3 bg-input w-full border-0 rounded-xl">
        <div *ngIf='endDate.dirty && endDate.getError("invalidDate")' class="text-red-500 ms-4">{{"Invalid Date" | translate }}</div>
    </div>
    <div class="flex flex-col w-1/5" [ngClass]="projectsFilter ? 'flex': 'hidden'">
        <label class="block Gilroy-Bold">{{'Projects' | translate }}</label>
        <ng-select
            class="custom-selector" 
            bindLabel="projectName" 
            bindValue="_id"
            placeholder='{{"Select Project" | translate }}'
            [items]='projects'
            [formControl]='project'>
        </ng-select>
    </div>
    <div class="flex flex-col w-1/5" [ngClass]="accountsFilter ? 'flex': 'hidden'">
        <label class="block Gilroy-Bold">{{"Accounts" | translate }}</label>
        <ng-select
            class="custom-selector" 
            bindLabel="accountName"
            groupBy='accountType'
            placeholder='{{"Select Account" | translate }}'
            [items]='accounts'
            [formControl]='account'>
        </ng-select>
    </div>
    <div class="flex flex-col w-1/5" [ngClass]="itemFilter ? 'flex': 'hidden'">
        <label class="block Gilroy-Bold">{{"Items" | translate }}</label>
        <ng-select
            class="custom-selector" 
            bindLabel="name"
            bindValue="_id"
            placeholder='{{"Select Items" | translate }}'
            [items]='products'
            [formControl]='item'>
        </ng-select>
    </div>
    <div class="flex flex-col w-1/5" [ngClass]="contactsFilter ? 'flex': 'hidden'">
        <label class="block Gilroy-Bold">{{'Contact' | translate }}</label>
        <ng-select
            class="custom-selector" 
            bindLabel="accountName"
            groupBy='accountType'
            placeholder='{{"Select Contact" | translate }}'
            [items]='contacts'
            [formControl]='contact'>
        </ng-select>
    </div>
    <div class="flex flex-col w-1/5" [ngClass]="assetsAccountFilter ? 'flex': 'hidden'">
        <label class="block Gilroy-Bold">{{'Asset Account' | translate }}</label>
        <ng-select
            class="custom-selector" 
            bindLabel="accountName"
            bindValue="_id"
            placeholder='{{"Select Account" | translate }}'
            [items]='assetAccounts'
            [formControl]='assetId'>
        </ng-select>
    </div>
    <div class="flex flex-col w-1/5" [ngClass]="cashierFilter ? 'flex': 'hidden'">
        <label class="block Gilroy-Bold">{{"Cashier" | translate }}</label>
        <ng-select
            class="custom-selector" 
            bindLabel="name"
            placeholder='{{"Select Cashier" | translate }}'
            [items]='cashiers'
            [formControl]='cashierEmail'>
        </ng-select>
    </div>
    <div class="flex flex-col w-1/5" [ngClass]="partialTrialFilter ? 'flex': 'hidden'">
        <label class="block Gilroy-Bold">{{"Account" | translate }}</label>
        <ng-select
            class="custom-selector"
            placeholder='{{"Select Account" | translate }}'
            [items]='assetCategory'
            [formControl]='assetValue'
            (change)="filterSubCategory($event)">
        </ng-select>
    </div>
    <div class="flex flex-col w-1/5" [ngClass]="partialTrialFilter ? 'flex': 'hidden'">
        <label class="block Gilroy-Bold">{{"Category" | translate }}</label>
        <ng-select
            class="custom-selector" 
            bindLabel="accountType"
            bindValue="accountType"
            placeholder='{{"Select Category" | translate }}'
            [items]='filteredSubCategory'
            [formControl]='subAssetValue'
            (change)="subCategoryChange($event)">
        </ng-select>
    </div>
    <div class="flex flex-col w-1/5" [ngClass]="salesTaxTransaction ? 'flex': 'hidden'">
        <label class="block Gilroy-Bold">{{"Tax" | translate }}</label>
        <ng-select
            class="custom-selector" 
            bindLabel="taxName"
            bindValue="accountDetails.accountId"
            placeholder='{{"Select Tax" | translate }}'
            [items]='allTaxes'
            [formControl]='selectedTax'>
        </ng-select>
    </div>
    <div class="flex space-x-2 self-end pb-2 items-center" [ngClass]="depreciationFilter ? 'flex': 'hidden'">
        <input type="checkbox"  [formControl]='withDepreciation' class="p-3 bg-input">
        <label class="block Gilroy-Bold b">{{'With Depreciation' | translate }}</label>
    </div>
    <div class="flex flex-col justify-end" [ngClass]="{'!ml-0 mb-2' : partialTrialFilter}">
        <!-- <label class="block opacity-0 mb-1">button</label> -->
        <app-button (handleClick)="emitFormData()" type="primary" buttonText="{{'Generate Report' | translate }}"></app-button>
    </div>
    <div class="flex flex-col justify-end" [ngClass]="{'!ml-0 mb-2' : partialTrialFilter}">
        <!-- <label class="block opacity-0 mb-1">button</label> -->
        <app-button (handleClick)="downloadPdf()" type="primary" buttonText="{{'Download' | translate }}"></app-button>
    </div>
</div>