import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { RootReducerState } from '../store/reducers';
import { selectBusiness } from '../store/selectors/business.selector';
import { selectUser } from '../store/selectors/user.selectors';

@Directive({
  selector: '[appAccessControl]'
})
export class AccessControlDirective implements OnInit {


  constructor(private elementRef: ElementRef,
              private store: Store<RootReducerState>) {
                this.business$ = this.store.pipe(select(selectBusiness));
              }

  @Input() moduleType;
  @Input() accessType: string;
  business$: Observable<any>;

  userData = [
    {
      moduleType: 'Report',
      permission: {
        edit: false,
        view: true,
      }
    },
    {
      moduleType: 'Accounting',
      permission: {
        edit: false,
        view: true,
      }
    }
  ];

  permissions = [];

  ngOnInit(): void {
    this.elementRef.nativeElement.style.display = 'none';
    this.business$.subscribe((business) => {
      this.permissions.length = 0;
      if (business?.businessId?._id) {
        if (typeof business.permission !== 'string') {
          this.loadPermission(business?.permission);
          this.checkAccess();
        }
      }
    });
  }

  loadPermission(permissions): void {
    if(permissions){
      Object.keys(permissions).forEach((key) => {
        if (permissions[key] === 'edit') {
          this.permissions.push({
            moduleType: key,
            permission: {
              edit: true,
              view: true
            }
          });
        } else if (permissions[key] === 'view') {
          this.permissions.push({
            moduleType: key,
            permission: {
              edit: false,
              view: true
            }
          });
        } else {
          this.permissions.push({
            moduleType: key,
            permission: {
              edit: false,
              view: false
            }
          });
        }
      });
    }
  }

  checkAccess(): void {
    if (this.permissions.length > 0) {
      const module: any = this.permissions.find(
        (access) => access.moduleType === this.moduleType
      );
      if(module){
        this.elementRef.nativeElement.style.display = module?.permission[this.accessType] ? '' : 'none';
      }
      else
      this.elementRef.nativeElement.style.display = '';
    }
  }

}

@Directive({
  selector: '[permissionCheck]'
})
export class PermissionCheckDirective implements OnInit {


  constructor(private elementRef: ElementRef,
              private store: Store<RootReducerState>) {
                this.business$ = this.store.pipe(select(selectBusiness));
              }

  @Input() moduleType;
  @Input() accessType: string = 'view';
  business$: Observable<any>;

  userData = [
    {
      moduleType: 'Report',
      permission: {
        edit: false,
        view: true,
      }
    },
    {
      moduleType: 'Accounting',
      permission: {
        edit: false,
        view: true,
      }
    }
  ];

  permissions = [];

  ngOnInit(): void {
    this.elementRef.nativeElement.style.display = 'none';
    this.business$.subscribe((business) => {
      this.permissions.length = 0;
      if (business?.businessId?._id) {
        if (typeof business.permission !== 'string') {
          this.loadPermission(business?.permission);
          console.log(this.permissions, 'permissions');
          
          this.checkAccess();
        }
      }
    });
  }

  loadPermission(permissions): void {
    if(permissions){
      Object.keys(permissions).forEach((key) => {
        if (permissions[key] === 'edit') {
          this.permissions.push({
            moduleType: key,
            permission: {
              edit: true,
              view: true
            }
          });
        } else if (permissions[key] === 'view') {
          this.permissions.push({
            moduleType: key,
            permission: {
              edit: false,
              view: true
            }
          });
        } else {
          this.permissions.push({
            moduleType: key,
            permission: {
              edit: false,
              view: false
            }
          });
        }
      });
    }
  }

  checkAccess(): void {
    if (this.permissions.length > 0) {
      const module: any = this.permissions.find(
        (access) => access.moduleType === this.moduleType
      );
      console.log(module, 'module');
      
      if(module?.permission[this.accessType]){
        this.elementRef.nativeElement.setAttribute('style','filter: blur(0px); pointer-events: auto')
      }
      else{
        this.elementRef.nativeElement.setAttribute('style','filter: blur(7px); pointer-events: none')
      }
    }
  }

}
