<div class="space-y-4 p-4">
    <div class="flex items-center justify-between">
        <h1 class="text-2xl Gilroy-Bold inline-flex items-center">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 me-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z" />
              </svg>
            {{'Projects' | translate}}
        </h1>
        <app-button appAccessControl moduleType='projects' accessType='edit' type='primary' buttonText='{{"Create a project" | translate}}' size='large' (click)='showHideProject()'></app-button>
    </div>
    <app-table-container 
        [tableHeadings]='tableHeadings'
        [tableData]='projects'
        [keys]='tableKeys'
        [actionTemplate]='actionTemplate'>
        <ng-template let-item #actionTemplate>
            <td>{{(item?.isActive ? 'Open' : 'Close') | translate}}</td>
            <td  align="right" class="p-2 last:pe-10 text-center">
                <app-dropdown #dropdown>
                    <div button (click)='dropdown.open = true' (clickOutside)='dropdown.open = false'>
                        <div class="h-8 w-8 rounded-full flex justify-center items-center bg-gray-50 border border-gray-200">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 m-auto leading-8 text-center" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                            </svg>
                        </div>
                    </div>
                    <div options>
                        <span (click)='showDetails(item._id)'  class="cursor-pointer block px-4 py-2 text-sm Gilroy-Bold hover:bg-gray-50 hover:rounded-xl text-center">{{'View' | translate}}</span>
                        <span appAccessControl moduleType='projects' accessType='edit' (click)='showHideProject(item)' disabled='!projectForm.valid' class="cursor-pointer block px-4 py-2 text-sm Gilroy-Bold hover:bg-gray-50 hover:rounded-xl text-center">{{'Edit' | translate}}</span>
                        <span appAccessControl moduleType='projects' accessType='edit' (click)='showCloseModal(item)' class="cursor-pointer block px-4 py-2 text-sm Gilroy-Bold hover:bg-gray-50 hover:rounded-xl text-center">{{(item.isActive?'Close':'Open') | translate}}</span>
                    </div>
                </app-dropdown>             
            </td>
        </ng-template>
    </app-table-container>
</div>

<app-modal-container [isOpen]='deleteOpenModal'>
    <div header class="border-b pb-2 Gilroy-Bold">{{(projectSelected?.isActive ? 'Close' : 'Open') | translate}} {{'Project' | translate}}</div>
    <div content>{{'Are you sure?' | translate}}</div>
    <div footer class="flex items-center space-x-2 rtl:space-x-reverse">
        <app-button buttonText='{{(projectSelected?.isActive ? "Close" : "Open") | translate}}' type='danger' rounded=true (handleClick)='changeProjectStatus()' ></app-button>
        <app-button buttonText='{{"Cancel" | translate}}' type='third' rounded=true (handleClick)='deleteOpenModal = false'></app-button>
    </div>
</app-modal-container>
<app-modal-container [isOpen]='createUpdateModal'>
    <div header class="border-b pb-2 Gilroy-Bold">{{(newProject?'Create':'Update') | translate}} {{'Project' | translate}}</div>
    <div content>
        <my-tabs>
            <my-tab [tabTitle]="'Project Details' | translate">
                <div [formGroup]='projectForm'>
                    <div >
                        <label for="" class="block mb-1 Gilroy-Bold text-md text-black">{{'Project Name' | translate}}</label>
                        <input type="text" class="p-3 bg-input w-full border-0 rounded-xl" formControlName='projectName' required>
                        <span class="text-red-600 text-sm" *ngIf="formErrors?.projectName">{{formErrors?.projectName }}</span>
                    </div>
                    <div >
                        <label for="" class="block mb-1 Gilroy-Bold text-md text-black">{{'Project Description' | translate}}</label>
                        <textarea class="p-3 bg-input w-full border-0 rounded-xl" formControlName='description'></textarea>
                    </div>
                </div>
            </my-tab>
            <!-- <my-tab appAccessControl moduleType="storage" accessType="edit" [tabTitle]="'Files' | translate">
                <app-file-upload #fileUploadComponent [addedFiles]="addedFiles" (emitter)="saveFiles($event)" ></app-file-upload>
            </my-tab> -->
        </my-tabs>
    </div>
    <div footer class="flex items-center space-x-2 rtl:space-x-reverse">
        <app-button buttonText="{{(newProject?'Create':'Update') | translate}}" rounded="true" type="primary" (handleClick)="handleProject()"></app-button>
        <app-button buttonText='{{"Cancel" | translate}}' type='third' rounded=true (handleClick)='closeModal()'></app-button>
    </div>
</app-modal-container>

