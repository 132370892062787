<div class="p-7 rounded-3xl bg-light-gray-1 mt-2 mb-2">
    <h3 class="Gilroy-Bold text-xl mb-4">{{'Reminder settings' | translate }}</h3>
    <p class="Gilroy-SemiBold mb-4">{{'Reminder message' | translate }}</p>
    <label class="inline-flex items-center">
        <input [formControl]='reminder' type="checkbox" class="me-2">
        <span class="block Gilroy-SemiBold">{{'Remind customers 3, 7, and 14 days after due date' | translate}}</span>
    </label>
    <p class="mb-4">{{'You can edit the reminders on individual invoices anytime.' | translate }}</p>
    <div class="flex items-center space-x-2 rtl:space-x-reverse">
        <app-button buttonText='{{"Save changes" | translate }}' type='primary' rounded='true' (handleClick)='saveChanges()'></app-button>
        <span *ngIf='response' class="text-green-500">{{response}}</span>
        <span *ngIf='error' class="text-red-500">{{error}}</span>
    </div>
</div>