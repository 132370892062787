<div class="expert-enquiry-container">
  <div class="enquiry-header flex justify-between items-center">
    <h3 class="text-xl Gilroy-SemiBold inline align-middle">
      {{ "Enquiry History" | translate }}
    </h3>
    <button
      (click)="openFilter()"
      class="page-filter border-[#EAEAEA] border rounded-[10px] p-3 flex gap-[6px] text-xs Gilroy-SemiBold items-center opacity-100"
    >
      <img src="/assets/images/filter-icon.svg" alt="" />
      {{"Filter" | translate}}
    </button>
  </div>
  <div class="enquiry-list">
    <div class="list-header flex text-xs opacity-50 p-6">
      <span class="w-[20%]">{{ "Expert Name" | translate }}</span>
      <span class="w-[20%]">{{ "Enquiry Date" | translate }}</span>
      <span class="w-[20%]">{{ "Status" | translate }}</span>
      <span class="w-[38%] ltr:text-right rtl:text-left">{{
        "Action" | translate
      }}</span>
    </div>
    <div class="list-content">
      <accordion [closeOthers]="false" [showArrows]="true">
        <ng-container *ngIf="enquiriesList?.length > 0; else noEnquiries">
          <ng-container *ngFor="let item of enquiriesList">
            <accordion-group (onToggle)="openRow()">
              <accordion-heading>
                <div
                  class="flex Gilroy-Medium heading-border cursor-pointer bg-[#f7f7f7] p-6 text-sm"
                >
                  <div class="w-[20%]">
                    <span
                      routerLink="/experts/hire-expert/expert-details/{{
                        item?._id
                      }}"
                      class="border-b-[1px] border-black h-6"
                      >{{ item?.expertDetails?.name | translate }}</span
                    >
                  </div>
                  <span class="w-[20%]">{{
                    item?.createdAt | date : "MMM d, y"
                  }}</span>
                  <span class="w-[20%]">{{
                    item?.status === "Select status"
                      ? "Not Acknowledged"
                      : "Acknowledged"
                  }}</span>
                  <div class="w-[38%] flex justify-end">
                    <span class="icon-circle">
                      <img
                        class="arrow-icon"
                        src="/assets/images/arrow-up.svg"
                        alt=""
                      />
                    </span>
                  </div>
                </div>
              </accordion-heading>
              <div
                class="accordion-body border-t-2 bg-[#f7f7f7] p-6 border-[#EAEAEA]"
              >
                <div class="flex w-[39%]">
                  <div class="rounded-xl py-2 w-[48%]">
                    <p
                      for="firstName"
                      class="block text-xs mb-2 opacity-50 font-color-05"
                    >
                      {{ "Contact Number" | translate }}
                    </p>
                    <span class="Gilroy-Medium text-sm">{{
                      item?.expertDetails?.contactNumber | translate
                    }}</span>
                  </div>
                  <div class="rounded-xl ml-4 py-2 w-[48%]">
                    <p
                      for="lastName"
                      class="block text-xs mb-2 opacity-50 font-color-05"
                    >
                      {{ "Email Address" | translate }}
                    </p>
                    <span class="Gilroy-Medium text-sm">{{
                      item?.expertDetails?.email | translate
                    }}</span>
                  </div>
                </div>
                <div class="mt-4">
                  <p
                    for="description"
                    class="block text-xs mb-2 opacity-50 font-color-05"
                  >
                    {{ "Description" | translate }}
                  </p>
                  <span class="Gilroy-Medium text-sm">{{
                    item?.expertDetails?.description | translate
                  }}</span>
                </div>
              </div>
            </accordion-group>
          </ng-container>
        </ng-container>
        <!-- <ng-template #noEnquiries> No Data Available </ng-template> -->
        <ng-template #noEnquiries>
          <div class="mt-8 ltr:ml-6 rtl:mr-6">No data available</div>
        </ng-template>
      </accordion>
    </div>
  </div>
</div>

<app-modal-container
  (clickOutside)="closeFilterForm()"
  [isOpen]="openFilterModal"
>
  <div header class="border-b pb-2 Gilroy-ExtraBold text-xl">
    {{ "Filter" | translate }}
  </div>
  <div content>
    <div class="flex justify-between">
      <div>
        <h3 class="text-sm Gilroy-SemiBold">Filter by Specialisations</h3>
        <ul class="flex flex-col gap-3 mt-4">
          <li class="text-xs items-center">
            <input
              type="checkbox"
              [(ngModel)]="values.accounting"
              class="me-2 filter-checkbox focus:ring-0"
              (change)="selectOption('Accounting', 'specialisation')"
              id="isArchive"
            />Accounting
          </li>
          <li class="text-xs items-center">
            <input
              type="checkbox"
              [(ngModel)]="values.bookkeeping"
              class="me-2 filter-checkbox focus:ring-0"
              (change)="selectOption('Bookkeeping', 'specialisation')"
              id="isArchive"
            />Bookkeeping
          </li>
          <li class="text-xs items-center">
            <input
              type="checkbox"
              [(ngModel)]="values.payroll"
              class="me-2 filter-checkbox focus:ring-0"
              (change)="selectOption('Payroll', 'specialisation')"
              id="isArchive"
            />Payroll
          </li>
          <li class="text-xs items-center">
            <input
              type="checkbox"
              [(ngModel)]="values.internal"
              class="me-2 filter-checkbox focus:ring-0"
              (change)="selectOption('Internal Audit', 'specialisation')"
              id="isArchive"
            />Internal Audit
          </li>
          <li class="text-xs items-center">
            <input
              type="checkbox"
              [(ngModel)]="values.external"
              class="me-2 filter-checkbox focus:ring-0"
              (change)="selectOption('External Audit', 'specialisation')"
              id="isArchive"
            />External Audit
          </li>
          <li class="text-xs items-center">
            <input
              type="checkbox"
              [(ngModel)]="values.sales"
              class="me-2 filter-checkbox focus:ring-0"
              (change)="selectOption('Sales Tax', 'specialisation')"
              id="isArchive"
            />Sales Tax
          </li>
          <li class="text-xs items-center">
            <input
              type="checkbox"
              [(ngModel)]="values.income"
              class="me-2 filter-checkbox focus:ring-0"
              (change)="selectOption('Income Tax', 'specialisation')"
              id="isArchive"
            />Income Tax
          </li>
          <li class="text-xs items-center">
            <input
              type="checkbox"
              [(ngModel)]="values.financial"
              class="me-2 filter-checkbox focus:ring-0"
              (change)="selectOption('Financial Advisor', 'specialisation')"
              id="isArchive"
            />Financial Advisor
          </li>
        </ul>
      </div>
      <div>
        <h3 class="text-sm Gilroy-SemiBold">Filter by Qualifications</h3>
        <ul class="flex flex-col gap-3 mt-4">
          <li class="text-xs items-center">
            <input
              type="checkbox"
              [(ngModel)]="values.under"
              class="me-2 filter-checkbox focus:ring-0"
              (change)="selectOption('Under Graduate', 'qualification')"
              id="isArchive"
            />Under Graduate
          </li>
          <li class="text-xs items-center">
            <input
              type="checkbox"
              [(ngModel)]="values.graduate"
              class="me-2 filter-checkbox focus:ring-0"
              (change)="selectOption('Graduate', 'qualification')"
              id="isArchive"
            />Graduate
          </li>
          <li class="text-xs items-center">
            <input
              type="checkbox"
              [(ngModel)]="values.post"
              class="me-2 filter-checkbox focus:ring-0"
              (change)="selectOption('Post Graduate', 'qualification')"
              id="isArchive"
            />Post Graduate
          </li>
        </ul>
      </div>
      <div>
        <h3 class="text-sm Gilroy-SemiBold">Filter by Country</h3>
        <ul class="flex flex-col gap-3 mt-4">
          <li class="flex text-xs items-center">
            <input
              type="checkbox"
              [(ngModel)]="values.global"
              class="me-2 filter-checkbox focus:ring-0"
              (change)="selectOption('Global', 'country')"
              id="isArchive"
            />Global
            <!-- <img
              src="/assets/images/india-flag.png"
              class="w-5 h-5 ltr:mr-2 rtl:ml-2"
              alt=""
            /> -->
          </li>
          <li class="flex text-xs items-center">
            <input
              type="checkbox"
              [(ngModel)]="values.jo"
              class="me-2 filter-checkbox focus:ring-0"
              (change)="selectOption('JO', 'country')"
              id="isArchive"
            />Jordon
            <!-- <img
              src="/assets/images/united-kingdom.png"
              class="w-5 h-5 ltr:mr-2 rtl:ml-2"
              alt=""
            /> -->
          </li>
          <li class="flex text-xs items-center">
            <input
              type="checkbox"
              [(ngModel)]="values.sa"
              class="me-2 filter-checkbox focus:ring-0"
              (change)="selectOption('SA', 'country')"
              id="isArchive"
            />Saudi Arabia
            <!-- <img
              src="/assets/images/united-kingdom.png"
              class="w-5 h-5 ltr:mr-2 rtl:ml-2"
              alt=""
            /> -->
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div footer class="flex items-center space-x-2 rtl:space-x-reverse">
    <app-button
      buttonText="{{ 'Filter' | translate }}"
      type="primary"
      rounded="true"
      (handleClick)="submitFilterForm()"
    ></app-button>
    <app-button
      buttonText="{{ 'Cancel' | translate }}"
      type="third"
      rounded="true"
      (handleClick)="closeFilterForm()"
    ></app-button>
  </div>
</app-modal-container>
