import { Directive, ElementRef, Input, OnInit } from "@angular/core";
import { select, Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { RootReducerState } from "../store/reducers";
import { selectUserSubscription } from "../store/selectors/subscription.selectors";
import { selectUsage } from "../store/selectors/usage.selector";
import { selectUser } from "../store/selectors/user.selectors";

@Directive({
    selector: '[appSubscriptionCheck]'
  })
  export class SubscriptionDirective implements OnInit {

    @Input() featureToCheck;
    usage$: Observable<any>;

    constructor(
                private elementRef: ElementRef,
                private store:Store<RootReducerState>
                ){
                    this.usage$ = store.pipe(select(selectUsage));
                }

    ngOnInit(): void {
        this.usage$.subscribe(({subscriptionLimit, currentUsage}) => {
            console.log(subscriptionLimit[this.featureToCheck], currentUsage[this.featureToCheck]);
            if(subscriptionLimit[this.featureToCheck] <= currentUsage[this.featureToCheck]) {
                this.elementRef.nativeElement.style.display = '';
            }
            else{
                this.elementRef.nativeElement.style.display = '';
            }

        })
    }
  }


@Directive({
    selector: '[appFeatureCheck]'
})
export class FeatureCheckDirective implements OnInit {

    @Input() feature;
    subscription$: Observable<any>

    constructor(
        private elementRef: ElementRef,
        private store: Store<RootReducerState>
    ){
        this.subscription$ = store.pipe(select(selectUserSubscription))
    }
    invoicing = ['sales', 'settings']
    others = [ 'settings', 'sales', 'dashboard', 'purchase', 'projects', 'reports', 'accounting', 'plus' ]

    ngOnInit(): void {
        this.subscription$.subscribe(sub => {
            if(sub?.planName === "Invoicing") {
                if(!this.invoicing.includes(this.feature)){
                    this.elementRef.nativeElement.setAttribute('style','filter: blur(0px); pointer-events: auto')
                    // style.filter = 'blur(7px)'
                    // this.elementRef.nativeElement.style.pointerEvents = 'none'
                }
                else{
                    this.elementRef.nativeElement.setAttribute('style','filter: blur(0px); pointer-events: auto')
                }
            }
            else{
                this.elementRef.nativeElement.setAttribute('style','filter: blur(0px); pointer-events: auto')
            }
        })
    }
}

@Directive ({
    selector: '[routeToSubscription]'
})
export class RouteToSubDirective implements OnInit {
    @Input() fromComponent;
    subscription$: Observable<any>

    constructor(
        private elementRef: ElementRef,
        private store: Store<RootReducerState>
    ){
        this.subscription$ = store.pipe(select(selectUserSubscription))
    }

    invoicing = ['sales', 'settings']
    others = [ 'settings', 'sales', 'dashboard', 'purchase', 'projects', 'reports', 'accounting', 'plus' ];
    options = []

    ngOnInit(): void {
        // this.elementRef.nativeElement.setAttribute('style','display: block')
        // this.subscription$.subscribe(sub => {
            

        //     if(sub?.planName === "Invoicing") {
        //         if(!this.invoicing.includes(this.fromComponent)){
        //             this.elementRef.nativeElement.setAttribute('style','display: block')
        //         }
        //         else{
        //             this.elementRef.nativeElement.setAttribute('style','display: none')
        //         }
        //     }
        //     else{
        //         this.elementRef.nativeElement.setAttribute('style','display: none')
        //     }
        // })
        console.log(this.fromComponent, 'fromComponent');
        
        this.elementRef.nativeElement.setAttribute('style','display: block')
        this.subscription$.subscribe(sub => {
            this.options = [];
            sub?.subscriptions?.filter(plan => !plan?.subHost?.startsWith('Transaction'))
            ?.forEach(plan => this.options?.push((plan?.subHost?.toLowerCase())));
            console.log(this.options, this.fromComponent, 'sub comp');
            
            if(!this.options.includes(this.fromComponent?.toLowerCase())){
                this.elementRef.nativeElement.setAttribute('style','display: block')
            }
            else{
                this.elementRef.nativeElement.setAttribute('style','display: block')
            }
        })
    }

}

@Directive ({
    selector: '[inventoryCheck]'
})
export class InventoryCheckDirective implements OnInit {
    // @Input() fromComponent;
    subscription$: Observable<any>

    constructor(
        private elementRef: ElementRef,
        private store: Store<RootReducerState>
    ){
        this.subscription$ = store.pipe(select(selectUserSubscription))
    }

    ngOnInit(): void {
        this.subscription$.subscribe(sub => {
            this.elementRef.nativeElement.setAttribute('style','filter: blur(0px); pointer-events: auto');
            
            if(sub?.subscriptions?.find(plan => plan?.subHost === 'Inventory')){
                this.elementRef.nativeElement.setAttribute('style','filter: blur(0px); pointer-events: auto');
            }
        })
    }

}


@Directive ({
    selector: '[subscriptionCheck]'
})

export class SubscriptionCheckDirective implements OnInit {
    @Input() featureToCheck: string;
    subscription$: Observable<any>;
    options = [];

    constructor(
        private elementRef: ElementRef,
        private store: Store<RootReducerState>
    ){
        this.subscription$ = store.pipe(select(selectUserSubscription))
    }

    ngOnInit(): void {
        this.elementRef.nativeElement.setAttribute('style','filter: blur(0px); pointer-events: auto');
        this.subscription$.subscribe(sub => {
            this.options = [];
            sub?.subscriptions?.filter(plan => !plan?.subHost?.startsWith('Transaction'))
            ?.forEach(plan => this.options?.push((plan?.subHost?.toLowerCase())));
            if(this.options.includes(this.featureToCheck?.toLowerCase())){
                this.elementRef.nativeElement.setAttribute('style','filter: blur(0px); pointer-events: auto');
            }
        })
    }
}
