<div class="py-6 px-8 rounded-3xl bg-light-gray-1 mt-2 mb-2 relative">
    <h3 class="Gilroy-Bold text-lg mb-4">{{'Personal Information' | translate}}</h3>
    <form [formGroup]='personalInformationForm' class="space-y-6">
        <div class="flex space-x-8 rtl:space-x-reverse">
            <div class="w-1/2">
                <label for='profileImage' class="block text-md pb-1 Gilroy-Bold font-color-05">{{'Profile Picture' | translate }}</label>
                <div class="flex items-center space-x-4 rtl:space-x-reverse mb-4">
                    <div class="border w-[120px] bg-white rounded-full">
                        <img [src]="profilePic" width="120" class="rounded-full" alt="Logo">
                    </div>
                    <div class="flex flex-col space-y-4">
                        <label class="bg-gradient px-4 py-2 text-sm rounded-full text-white text-center cursor-pointer" for="file">
                            <input type="file" id='file' accept="image/png, image/jpeg, image/jpg" (change)='fileEvent($event)' hidden>
                            {{profilePic === "assets/images/avatar.jpg" ? 'Add Picture' : 'Update' | translate }}
                        </label>
                        <app-button *ngIf='profilePic !== "assets/images/avatar.jpg"' (handleClick)='openDeleteModal= true' buttonText='{{"Remove" | translate}}' rounded='true' type='danger'></app-button>
                    </div>
                </div>
            </div>
         
        </div>
        <div class="flex space-x-8 rtl:space-x-reverse">
            <div class="custom-input-box-shadow rounded-xl bg-white px-3 py-2 w-1/2">
                <label for='firstName' class="block text-sm Gilroy-Bold font-color-05">{{'First Name' | translate }}</label>
                <input id='firstName' formControlName='firstName' type="text" class="w-full p-0 border-0 focus:ring-0 placeholder-gray-200" placeholder="{{'Enter First Name' | translate}}" />
            </div>
            <div class="custom-input-box-shadow rounded-xl bg-white px-3 py-2 w-1/2">
                <label for='lastName' class="block text-sm Gilroy-Bold font-color-05">{{'Last Name' | translate }}</label>
                <input id='lastName' formControlName='lastName' type="text" class="w-full p-0 border-0 focus:ring-0 placeholder-gray-200" placeholder="{{'Enter Last Name' | translate}}" />
            </div>
        </div>
        <div class="flex space-x-8 rtl:space-x-reverse">
            <div class="custom-input-box-shadow rounded-xl bg-white px-3 py-2 w-1/2">
                <label for='Country' class="block text-sm Gilroy-Bold font-color-05">{{'Country' | translate}}</label>
                <ng-select (change)='changeEventContry($event)' id='country-select1' [items]="countries" class="settings-select border-0 p-0 w-full" bindLabel="text"
                bindValue="text" formControlName="country" placeholder='{{"Select one" | translate }}'>
                <ng-option *ngFor="let country of countries" [value]="country.id">{{country.name}}</ng-option>
              </ng-select>
            </div>
            <div class="custom-input-box-shadow rounded-xl bg-white px-3 py-2 w-1/2">
                <label class="text-sm Gilroy-Bold font-color-05">{{'State' | translate}}</label>
                <ng-select id='state-select' [items]="states" class="settings-select border-0 p-0 w-full" bindLabel="text"
                bindValue="text" formControlName="state" placeholder='{{"Select one" | translate }}'>
                <ng-option *ngFor="let state of states" [value]="state.id">{{state.name}}</ng-option>
              </ng-select>
            </div>
        </div>
        <div class="flex space-x-8 rtl:space-x-reverse mb-6">
            <div class="custom-input-box-shadow rounded-xl bg-white px-3 py-2 w-1/2">
                <label for='city' class="text-sm block Gilroy-Bold font-color-05">{{'City' | translate}}</label>
                <input formControlName='city' id='city' type="text" class="w-full p-0 border-0 focus:ring-0 placeholder-gray-200" placeholder="{{'Enter city name' | translate }}" />
            </div>
            <div class="flex space-x-8 rtl:space-x-reverse w-1/2">
                <div class="custom-input-box-shadow rounded-xl bg-white px-3 py-2 w-1/2">
                    <label for='zipcode' class="block text-sm Gilroy-Bold font-color-05">{{'Zipcode' | translate}}</label>
                    <input formControlName='zipcode' id='zipcode' type="number" class="w-full p-0 border-0 focus:ring-0 placeholder-gray-200" placeholder="{{'Enter Zipcode' | translate }}" />
                </div>
                <div class="custom-input-box-shadow rounded-xl bg-white px-3 py-2 w-1/2">
                    <label for='dob' class="block text-sm Gilroy-Bold font-color-05">{{'Date of Birth' | translate }}</label>
                    <input formControlName='DOB' id='dob' type="date" min="1000-01-01" class="w-full p-0 border-0 focus:ring-0 placeholder-gray-200" placeholder="{{'Enter Date of Birth' | translate }}" />
                </div>
            </div>
        </div>
        <div class="flex flex-row items-center">
            <app-button buttonText='{{"Save" | translate }}' [rounded]='true' size='medium' type='primary' (handleClick)='savePersonalInfo()'></app-button>
            <span *ngIf='respMessage' class="text-green-500 text-sm ms-2">{{respMessage}}</span>
            <span *ngIf='error' class="text-red-500 text-sm ms-2">{{error}}</span>
        </div>
        <div>
            <h3 class="Gilroy-Bold text-lg">{{'Close Your Account' | translate }}</h3>
            <p class="mt-2">{{'Close account message' | translate }}</p>
        </div>
        <div><app-button buttonText='{{"Close Account" | translate }}' [rounded]='true' size='large' type='danger' (handleClick)="openDeleteAccountModal = true"></app-button></div>

    </form>

</div>

<app-image-cropper 
    [isOpen]='openModal'
    [imageCropperConfig]='imageCropperConfig'
    (closeImage)='openModal=false'
    (base64Image)='saveImage($event)'>
</app-image-cropper>


<app-modal-container [isOpen]='openDeleteModal'>
    <div header class="border-b pb-2 Gilroy-Bold">{{'Remove Profile Image' | translate }} </div>
    <div content>Are you sure?</div>
    <div footer class="flex items-center space-x-2 rtl:space-x-reverse">
        <app-button buttonText='{{"Delete" | translate}}' type='danger' rounded=true (handleClick)='removeLogo()'></app-button>
        <app-button buttonText='{{"Cancel" | translate}}' type='third' rounded=true (handleClick)='openDeleteModal = false'></app-button>
    </div>
</app-modal-container>


<app-modal-container [isOpen]='openDeleteAccountModal'>
    <div header class="border-b pb-2 Gilroy-Bold">{{'Close my Account' | translate }} </div>
    <div content>
        <p>Are you sure? You want to delete your account permanently, this action is irreversible.</p>
        <p class="text-gray-600">Note - Your subscription will be cancelled immediately.</p>
    </div>
    <div footer class="flex items-center space-x-2 rtl:space-x-reverse">
        <app-button buttonText='{{"Delete Account" | translate}}' type='danger' rounded=true (handleClick)='deleteAccount()'></app-button>
        <app-button buttonText='{{"Cancel" | translate}}' type='third' rounded=true (handleClick)='openDeleteAccountModal = false'></app-button>
    </div>
</app-modal-container>
