<div class="mx-auto w-5/6" [ngClass]="{'!w-full' : transactionReport }">
    <ng-container [ngTemplateOutlet]="this[config.templateName]" [ngTemplateOutletContext]= "{$implicit: config}"></ng-container>
</div>

<ng-template #normal let-config>
    <div *ngIf="config?.cashierEmail" class="pb-2">
        <div class="flex justify-between">
            <div class="space-y-2 ">
                <p><span class="Gilroy-Bold">{{'Cashier Email' | translate}} : </span> <span class="Gilroy-Medium">{{config?.cashierEmail}}</span></p>
                <p><span class="Gilroy-Bold">{{'Cashier Name' | translate}} : </span> <span class="Gilroy-Medium">{{config?.cashierName}}</span></p>
                <p><span class="Gilroy-Bold">{{'Date' | translate}} : </span> <span class="Gilroy-Medium">{{config.startDate}} {{'to' | translate}} {{config.endDate}}</span></p>
            </div>
            <div *ngIf="config.countTotalAmount" class="space-y-2 ">
                <p class="text-end"><span class="Gilroy-Bold">{{'Overall Sub Total' | translate}} : </span> <span class="Gilroy-Medium">{{config.currency}}{{config.countSubTotal}}</span></p>
                <p class="text-end"><span class="Gilroy-Bold">{{'Overall Tax' | translate}} : </span> <span class="Gilroy-Medium">{{config.currency}}{{config?.countTotalTax}}</span></p>
                <p class="text-end"><span class="Gilroy-Bold">{{'Overall Discount' | translate}} : </span> <span class="Gilroy-Medium">{{config.currency}}{{config?.countDiscount}}</span></p>
                <p class="text-end"><span class="Gilroy-Bold">{{'Overall Total Amount' | translate}} : </span> <span class="Gilroy-Medium">{{config.currency}}{{config?.countTotalAmount}}</span></p>
            </div>
        </div>
    </div>
    <table class="w-full">
        <tr class="bg-input">
            <td [ngClass]="last ? 'text-end': ''" class="Gilroy-Bold p-3 whitespace-nowrap" *ngFor='let heading of config.tableHeadings; let last=last;'>{{heading | translate}}</td>
        </tr>
        <tr ngClass="{{section.class}}" *ngFor='let section of config.tableData'>
            <td class="px-3 py-2" [ngClass]="lastKey ? 'text-end': ''" *ngFor='let key of config.tableKeys; let lastKey=last;'>{{section[key]}}</td>
        </tr>
        <tr *ngIf="config.paymentTypeObj" class="bg-gray-200 font-bold">
            <td class="px-3 py-2 text-start" colspan="3">{{'On Delivery' | translate }}</td>
            <td class="px-3 py-2 text-end" colspan="4">{{config.paymentTypeObj['On Delivery'] - 1 ?? 0}}</td>
        </tr>
        <tr *ngIf="config.paymentTypeObj" class="bg-gray-200 font-bold">
            <td class="px-3 py-2 text-start" colspan="3">{{'Wallet' | translate }}</td>
            <td class="px-3 py-2 text-end" colspan="4">{{config.paymentTypeObj['Wallet']- 1 ?? 0}}</td>
        </tr >
        <tr *ngIf="config.paymentTypeObj" class="bg-gray-200 font-bold">
            <td class="px-3 py-2 text-start" colspan="3">{{'Card' | translate }}</td>
            <td class="px-3 py-2 text-end" colspan="4">{{config.paymentTypeObj['Card']- 1 ?? 0}}</td>
        </tr>
        <tr *ngIf="config.paymentTypeObj" class="bg-gray-200 font-bold">
            <td class="px-3 py-2 text-start" colspan="3">{{'Cash' | translate }}</td>
            <td class="px-3 py-2 text-end" colspan="4">{{config.paymentTypeObj['Cash']- 1 ?? 0}}</td>
        </tr>
        <tr *ngIf="config.paymentTypeObj" class="bg-gray-200 font-bold">
            <td class="px-3 py-2 text-start" colspan="3">{{'Credit Balance' | translate }}</td>
            <td class="px-3 py-2 text-end" colspan="4">{{config.paymentTypeObj['Credit Balance']- 1 ?? 0}}</td>
        </tr>
        <!-- <tr *ngIf="config.totalTax" class="bg-gray-200 font-bold">
            <td class="px-3 py-2 text-start" colspan="3">{{'Total Tax' | translate }}</td>
            <td class="px-3 py-2 text-end" colspan="4">{{config.totalTax}}</td>
        </tr> -->
        <tr *ngIf="config.totalSales > 0" class="bg-gray-200 font-bold">
            <td class="px-3 py-2 text-start" colspan="3">{{'Total Sales' | translate }}</td>
            <td class="px-3 py-2 text-end" colspan="4">{{config.totalSales}}</td>
        </tr>
    </table>
</ng-template>

<ng-template #accountTransactions let-config>
    <table class="w-full">
        
        <tr *ngFor = 'let type of config.tableData'>
            <td colspan="6">
                <table class="w-full" >
                    <tr>
                        <td class="px-3 py-2 bg-gray-300 font-bold" colspan="6">{{type.title}}</td>
                    </tr>
                    <tr class="bg-input">
                        <td class="p-3 whitespace-nowrap Gilroy-Bold w-2/12">{{'Date' | translate}}</td>
                        <td class="p-3 whitespace-nowrap Gilroy-Bold w-2/12">{{'Transaction ID' | translate }}</td>
                        <td class="p-3 whitespace-nowrap Gilroy-Bold w-2/12">{{'Description' | translate }}</td>
                        <td class="p-3 whitespace-nowrap Gilroy-Bold w-2/12 text-end">{{'Debit' | translate }}</td>
                        <td class="p-3 whitespace-nowrap Gilroy-Bold w-2/12 text-end">{{'Credit' | translate }}</td>
                        <td class="p-3 whitespace-nowrap Gilroy-Bold w-2/12 text-end">{{'Balance' | translate }}</td>
                    </tr>
                    <tr *ngFor='let section of type.value'>
                        <td class="w-full" colspan="6">
                            <table class="w-full">
                                <tr>
                                    <td class="px-3 py-2 bg-gray-200 font-bold" colspan="6">{{section.accountName}}</td>
                                </tr>
                                <tr class="border-b">
                                    <td class="px-3 py-2 font-bold" colspan="5">Starting Balance</td>
                                    <td class="text-end px-3 py-2 font-bold" colspan="1">{{section.balance}}</td>
                                </tr>
                                <tr ngClass="{{transaction.class}}" *ngFor='let transaction of section.transactions'>
                                    <td class="text-start px-3 py-2 w-2/12" colspan="1">{{transaction.date | date}}</td>
                                    <td class="px-3 py-2 w-2/12" colspan="1">{{transaction.transactionNumber}}</td>
                                    <td class="px-3 py-2 w-2/12" colspan="1">{{transaction.journal ? transaction.journal.description : transaction.description}}</td>
                                    <td class="px-3 py-2 w-2/12 text-end" colspan="1">{{transaction.debit || ''}}</td>
                                    <td class="px-3 py-2 w-2/12 text-end" colspan="1">{{transaction.credit || ''}}</td>
                                    <td class="text-end px-3 py-2 w-2/12" colspan="1">{{transaction?.balance}}</td>
                                </tr>
                                <tr ngClass="bg-gray-200 font-bold">
                                    <td colspan="3" class="px-3 py-2 w-6/12">Totals and Ending Balance</td>
                                    <td class="px-3 py-2 w-2/12 text-end">{{section.total.debit}}</td>
                                    <td class="px-3 py-2 w-2/12 text-end">{{section.total.credit}}</td>
                                    <td class="px-3 py-2 w-2/12 text-end">{{section.total.net}}</td>
                                </tr>
                                <!-- <tr>
                                    <td class="p-2"></td>
                                </tr> -->
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td class="p-2"></td>
                    </tr>
                </table>
            </td>
        </tr>
        
    </table>
</ng-template>

<ng-template #salesTaxes let-config>
    <table class="w-full mb-8">
        <tr class="bg-gray-200">
            <td class="p-3 font-bold text-start" colspan="3">{{'Sales and Purchases' | translate }}</td>
            <td class="p-3 font-bold text-end" colspan="3">{{config.startDate | date}} {{'to' | translate}} {{config.endDate | date}}</td>
        </tr>
        <tr class="border-b font-bold">
            <td class="px-3 py-2 text-start">{{'Tax' | translate }}</td>
            <td class="px-3 py-2 text-end">{{'Sales Subject to Tax' | translate }}</td>
            <td class="px-3 py-2 text-end">{{'Tax Amount on Sales' | translate }}</td>
            <td class="px-3 py-2 text-end">{{'Purchases Subject to Tax' | translate }}</td>
            <td class="px-3 py-2 text-end">{{'Tax Amount on Purchases' | translate }}</td>
            <td class="px-3 py-2 text-end">{{'Net Owing' | translate }}</td>
        </tr>
        <tr class="border-b" *ngFor='let tax of config.tableData[0].salesAndPurchase.tableData'>
            <td class="px-3 py-2 text-start">{{tax.accountName}}</td>
            <td class="px-3 py-2 text-end">{{tax.salesSubjectToTax}}</td>
            <td class="px-3 py-2 text-end">{{tax.taxAmountOnSales}}</td>
            <td class="px-3 py-2 text-end">{{tax.purchaseSubjectToTax}}</td>
            <td class="px-3 py-2 text-end">{{tax.taxAmountOnPurchases}}</td>
            <td class="px-3 py-2 text-end">{{tax.netOwing}}</td>
        </tr>
        <tr class="bg-gray-200 font-bold">
            <td class="px-3 py-2 text-start" colspan="1">{{'Total' | translate }}</td>
            <td class="px-3 py-2 text-end" colspan="1"></td>
            <td class="px-3 py-2 text-end" colspan="1">{{config.tableData[0].salesAndPurchase.totals.sales}}</td>
            <td class="px-3 py-2 text-end" colspan="1"></td>
            <td class="px-3 py-2 text-end" colspan="1">{{config.tableData[0].salesAndPurchase.totals.purchase}}</td>
            <td class="px-3 py-2 text-end" colspan="1">{{config.tableData[0].salesAndPurchase.totals.owing}}</td>
        </tr>
    </table>

    <table class="w-full">
        <tr class="font-bold">
            <td class="text-start p-3" colspan="1">{{'Balances and Owing' | translate }}</td>
            <td class="text-end p-3" colspan="1">As of {{config.startDate | date}}</td>
            <td class="" colspan="1"></td>
            <td class="text-end p-3" colspan="1">As of {{config.endDate | date}}</td>
        </tr>
        <tr class="font-bold bg-gray-200">
            <td class="text-start px-3 py-2">{{'Tax' | translate }}</td>
            <td class="text-end px-3 py-2">{{'Starting Balance' | translate }}</td>
            <td class="text-end px-3 py-2">{{'Net Tax Owing' | translate }}</td>
            <td class="text-end px-3 py-2">{{'Ending Balance' | translate }}</td>
        </tr>
        <tr class="border-b" *ngFor='let tax of config.tableData[0].balances.tableData'>
            <td class="text-start px-3 py-2">{{tax.accountName}}</td>
            <td class="text-end px-3 py-2">{{tax.balance}}</td>
            <td class="text-end px-3 py-2">{{tax.netOwing}}</td>
            <td class="text-end px-3 py-2">{{tax.endBalance}}</td>
        </tr>
        <tr class="font-bold bg-gray-200">
            <td class="text-start px-3 py-2">{{'Total' | translate }}</td>
            <td class="text-end px-3 py-2">{{config.tableData[0].balances.totals.balance}}</td>
            <td class="text-end px-3 py-2">{{config.tableData[0].balances.totals.netOwing}}</td>
            <td class="text-end px-3 py-2">{{config.tableData[0].balances.totals.endBalance}}</td>
        </tr>
    </table>
</ng-template>

<ng-template #assets let-config>
    <table class="w-full">
        <tr class="font-bold bg-gray-200">
            <td class="text-start px-3 py-2">{{'Asset Name' | translate }}</td>
            <td class="text-start px-3 py-2">{{'Asset Type' | translate }}</td>
            <td class="text-start px-3 py-2">{{'Purchase Date' | translate }}</td>
            <td class="text-end px-3 py-2">{{'Purchase Value' | translate }}</td>
            <td class="text-end px-3 py-2">{{'Depreciation %' | translate}}</td>
            <td class="text-end px-3 py-2">{{'Current Depreciation Value' | translate }}</td>
            <td *ngIf="config.data.withDepreciation" class="text-end px-3 py-2">{{'Depreciation Value' | translate }}</td>
            <td *ngIf="config.data.withDepreciation" class="text-end px-3 py-2">{{'Asset Value' | translate }}</td>
        </tr>
        <tr class="border-b last:border-0" *ngFor='let asset of config.tableData'>
            <td class="text-start px-3 py-2">{{asset.assetsName}}</td>
            <td class="text-start px-3 py-2">{{asset.accountName}}</td>
            <td class="text-start px-3 py-2">{{asset?.purchaseDate }}</td>
            <td class="text-end px-3 py-2">{{asset.amount}}</td>
            <td class="text-end px-3 py-2">{{asset.depreciation | number: number}}</td>
            <td class="text-end px-3 py-2">{{asset.currentDepreciationValue}}</td>
            <td *ngIf="config.data.withDepreciation" class="text-end px-3 py-2">{{asset.depreciationValue}}</td>
            <td *ngIf="config.data.withDepreciation" class="text-end px-3 py-2">{{asset.amountValue}}</td>
        </tr>
        <tr>
            <td class="text-start px-3 py-2">{{'Total' | translate}}</td>
            <td class="text-start px-3 py-2"></td>
            <td class="text-start px-3 py-2"></td>
            <td class="text-end px-3 py-2"></td>
            <td class="text-end px-3 py-2"></td>
            <td class="text-end px-3 py-2">{{config.data.totalCurrentDepreciationValue}}</td>
            <td *ngIf="config.data.withDepreciation" class="text-end px-3 py-2">{{config.data.totalDepreciationValue}}</td>
            <td *ngIf="config.data.withDepreciation" class="text-end px-3 py-2">{{config.data.totalAssetValue}}</td>
        </tr>
    </table>
</ng-template>

<ng-template #cash let-config>
    <table class="w-full">
        <tr class="font-bold">
            <td class="text-start p-3" colspan="1">{{'Cash inflow and outflow' | translate }}</td>
            <td class="text-end p-3" colspan="1">{{config.startDate }} {{'to' | translate }} {{config.endDate }}</td>
        </tr>
        <tr class="bg-gray-200 font-bold">
            <td class="text-start px-3 py-2">Operating Activities</td>
            <td></td>
        </tr>
        <tr class="bg-gray-100 font-bold">
            <td class="text-start px-3 py-2">Sales</td>
            <td></td>
        </tr>
        <tr class="border-b" *ngFor='let account of config.tableData[0].activities.operating.sales.tableData'>
            <td class="text-start px-3 py-2">{{account.accountName}}</td>
            <td class="text-end px-3 py-2">{{account.finalBalance}}</td>
        </tr>
        <tr *ngIf='config.tableData[0].activities.operating.sales.tableData.length > 0' class="bg-gray-100 font-bold">
            <td class="text-start px-3 py-2">Sales Total</td>
            <td class="text-end px-3 py-2">{{config.tableData[0].activities.operating.sales.total}}</td>
        </tr>
        <tr class="bg-gray-100 font-bold">
            <td class="text-start px-3 py-2">Purchases</td>
            <td></td>
        </tr>
        <tr class="border-b" *ngFor='let account of config.tableData[0].activities.operating.purchase.tableData'>
            <td class="text-start px-3 py-2">{{account.accountName}}</td>
            <td class="text-end px-3 py-2">{{account.finalBalance}}</td>
        </tr>
        <tr *ngIf='config.tableData[0].activities.operating.purchase.tableData.length > 0' class="bg-gray-100 font-bold">
            <td class="text-start px-3 py-2">Purchases Total</td>
            <td class="text-end px-3 py-2">{{config.tableData[0].activities.operating.purchase.total}}</td>
        </tr>
        <tr class="bg-gray-100 font-bold">
            <td class="text-start px-3 py-2">Sales Taxes</td>
            <td></td>
        </tr>
        <tr class="border-b" *ngFor='let account of config.tableData[0].activities.operating.salesTax.tableData'>
            <td class="text-start px-3 py-2">{{account.accountName}}</td>
            <td class="text-end px-3 py-2">{{account.finalBalance}}</td>
        </tr>
        <tr *ngIf='config.tableData[0].activities.operating.salesTax.tableData.length > 0' class="bg-gray-100 font-bold">
            <td class="text-start px-3 py-2">Sales Taxes Total</td>
            <td class="text-end px-3 py-2">{{config.tableData[0].activities.operating.salesTax.total}}</td>
        </tr>
        <tr class="bg-gray-100 font-bold">
            <td class="text-start px-3 py-2">Others</td>
            <td></td>
        </tr>
        <tr class="border-b" *ngFor='let account of config.tableData[0].activities.operating.others.tableData'>
            <td class="text-start px-3 py-2">{{account.accountName}}</td>
            <td class="text-end px-3 py-2">{{account.finalBalance}}</td>
        </tr>
        <tr *ngIf='config.tableData[0].activities.operating.others.tableData.length > 0' class="bg-gray-100 font-bold">
            <td class="text-start px-3 py-2">Others Total</td>
            <td class="text-end px-3 py-2">{{config.tableData[0].activities.operating.others.total}}</td>
        </tr>
        <tr class="bg-gray-200 font-bold">
            <td class="text-start px-3 py-2">Net Operating Activities</td>
            <td class="text-end px-3 py-2">{{config.tableData[0].activities.operating.total}}</td>
        </tr>

        <!-- Investing Activities -->
        <tr class="bg-gray-200 font-bold">
            <td class="text-start px-3 py-2">Investing Activites</td>
            <td></td>
        </tr>
        <tr class="bg-gray-100 font-bold">
            <td class="text-start px-3 py-2">Property, Plants, and Equipments</td>
            <td></td>
        </tr>
        <tr class="border-b" *ngFor='let account of config.tableData[0].activities.investing.property.tableData'>
            <td class="text-start px-3 py-2">{{account.accountName}}</td>
            <td class="text-end px-3 py-2">{{account.finalBalance}}</td>
        </tr>
        <tr *ngIf='config.tableData[0].activities.investing.property.tableData.length > 0' class="bg-gray-100 font-bold">
            <td class="text-start px-3 py-2">Property Total</td>
            <td class="text-end px-3 py-2">{{config.tableData[0].activities.investing.property.total}}</td>
        </tr>
        <tr class="bg-gray-100 font-bold">
            <td class="text-start px-3 py-2">Others</td>
            <td></td>
        </tr>
        <tr class="border-b" *ngFor='let account of config.tableData[0].activities.investing.others.tableData'>
            <td class="text-start px-3 py-2">{{account.accountName}}</td>
            <td class="text-end px-3 py-2">{{account.finalBalance}}</td>
        </tr>
        <tr *ngIf='config.tableData[0].activities.investing.others.tableData.length > 0' class="bg-gray-100 font-bold">
            <td class="text-start px-3 py-2">Others Total</td>
            <td class="text-end px-3 py-2">{{config.tableData[0].activities.investing.others.total}}</td>
        </tr>
        <tr class="bg-gray-200 font-bold">
            <td class="text-start px-3 py-2">Net Investing Activities</td>
            <td class="text-end px-3 py-2">{{config.tableData[0].activities.investing.total}}</td>
        </tr>

        <!-- Financial Activities -->
        <tr class="bg-gray-200 font-bold">
            <td class="text-start px-3 py-2">Financial Activites</td>
            <td></td>
        </tr>
        <tr class="bg-gray-100 font-bold">
            <td class="text-start px-3 py-2">Loans and Lines of Credit</td>
            <td></td>
        </tr>
        <tr class="border-b" *ngFor='let account of config.tableData[0].activities.financial.loans.tableData'>
            <td class="text-start px-3 py-2">{{account.accountName}}</td>
            <td class="text-end px-3 py-2">{{account.finalBalance}}</td>
        </tr>
        <tr *ngIf='config.tableData[0].activities.financial.loans.tableData.length > 0' class="bg-gray-100 font-bold">
            <td class="text-start px-3 py-2">Loans and Lines of Credit Total</td>
            <td class="text-end px-3 py-2">{{config.tableData[0].activities.financial.loans.total}}</td>
        </tr>
        <tr class="bg-gray-100 font-bold">
            <td class="text-start px-3 py-2">Owners</td>
            <td></td>
        </tr>
        <tr class="border-b" *ngFor='let account of config.tableData[0].activities.financial.owners.tableData'>
            <td class="text-start px-3 py-2">{{account.accountName}}</td>
            <td class="text-end px-3 py-2">{{account.finalBalance}}</td>
        </tr>
        <tr *ngIf='config.tableData[0].activities.financial.owners.tableData.length > 0' class="bg-gray-100 font-bold">
            <td class="text-start px-3 py-2">Owners Total</td>
            <td class="text-end px-3 py-2">{{config.tableData[0].activities.financial.owners.total}}</td>
        </tr>
        <tr class="bg-gray-100 font-bold">
            <td class="text-start px-3 py-2">Others</td>
            <td></td>
        </tr>
        <tr  class="border-b" *ngFor='let account of config.tableData[0].activities.financial.others.tableData'>
            <td class="text-start px-3 py-2">{{account.accountName}}</td>
            <td class="text-end px-3 py-2">{{account.finalBalance}}</td>
        </tr>
        <tr *ngIf='config.tableData[0].activities.financial.others.tableData.length > 0' class="bg-gray-100 font-bold">
            <td class="text-start px-3 py-2">Others Total</td>
            <td class="text-end px-3 py-2">{{config.tableData[0].activities.financial.others.total}}</td>
        </tr>
        <tr class="bg-gray-200 font-bold">
            <td class="text-start px-3 py-2">Net Financial Activities</td>
            <td class="text-end px-3 py-2">{{config.tableData[0].activities.financial.total}}</td>
        </tr>

        <!-- Overview -->
        <tr>
            <td class="p-4"></td>
        </tr>
        <tr class="font-bold">
            <td class="text-start p-3" colspan="1">Overview</td>
            <td class="text-end p-3" colspan="1"></td>
        </tr>
        <tr class="bg-gray-200 font-bold">
            <td class="text-start px-3 py-2">Starting Balance</td>
            <td></td>
        </tr>
        <tr class="border-b" *ngFor='let account of config.tableData[0].activities.balances.tableData'>
            <td class="text-start px-3 py-2">{{account.accountName}}</td>
            <td class="text-end px-3 py-2">{{account.balance}}</td>
        </tr>
        <tr class="border-b">
            <td class="text-start px-3 py-2">Total Starting Balance</td>
            <td class="text-end px-3 py-2">{{config.tableData[0].activities.balances.startTotal}}</td>
        </tr>
        <tr class="border-b">
            <td class="text-start px-3 py-2">Gross Inflow</td>
            <td class="text-end px-3 py-2">{{config.tableData[0].activities.balances.inflow}}</td>
        </tr>
        <tr class="border-b">
            <td class="text-start px-3 py-2">Gross Outflow</td>
            <td class="text-end px-3 py-2">{{config.tableData[0].activities.balances.outflow}}</td>
        </tr>
        <tr class="font-bold bg-gray-100">
            <td class="text-start px-3 py-2">Net Change</td>
            <td class="text-end px-3 py-2">{{config.tableData[0].activities.balances.net}}</td>
        </tr>
        <tr class="bg-gray-200 font-bold">
            <td class="text-start px-3 py-2">Ending Balance</td>
            <td></td>
        </tr>
        <tr class="border-b" *ngFor='let account of config.tableData[0].activities.balances.tableData'>
            <td class="text-start px-3 py-2">{{account.accountName}}</td>
            <td class="text-end px-3 py-2">{{account.finalBalance}}</td>
        </tr>
        <tr class="bg-gray-200 font-bold">
            <td class="text-start px-3 py-2">Total Ending Balance</td>
            <td class="text-end px-3 py-2">{{config.tableData[0].activities.balances.endTotal}}</td>
        </tr>
    </table>
</ng-template>

<ng-template #cashierSales let-config>
    <div *ngIf="config?.cashierEmail" class="pb-2">
        <div class="flex justify-between">
            <div class="space-y-2 ">
                <p><span class="Gilroy-Bold">Cashier Email : </span> <span class="Gilroy-Medium">{{config?.cashierEmail}}</span></p>
                <p><span class="Gilroy-Bold">Cashier Name : </span> <span class="Gilroy-Medium">{{config?.cashierName}}</span></p>
                <p><span class="Gilroy-Bold">Date : </span> <span class="Gilroy-Medium">{{config.startDate}} {{'to' | translate}} {{config.endDate}}</span></p>
            </div>
            <div class="space-y-2 ">
                <p class="text-end"><span class="Gilroy-Bold">Overall Sub Total : </span> <span class="Gilroy-Medium">{{config.countSubtotal}}</span></p>
                <p class="text-end"><span class="Gilroy-Bold">Overall Tax : </span> <span class="Gilroy-Medium">{{config?.countTaxAmount}}</span></p>
                <p class="text-end"><span class="Gilroy-Bold">Overall Total Amount : </span> <span class="Gilroy-Medium">{{config?.countTotalAmount}}</span></p>
            </div>
        </div>
    </div>
    <table class="w-full mb-8" *ngFor="let data of config.tableData">
        <tr class="bg-gray-200">
            <td class="p-3 font-bold text-start" colspan="2">{{ data.invoiceNumber }}</td>
            <td class="p-3 font-bold text-center" colspan="2">Order Type : {{ data.orderType }}</td>
            <td class="p-3 font-bold text-end" colspan="2">Payment Type : {{ data.posPaymentType }}</td>
        </tr>
        <tr class="border-b font-bold">
            <td class="px-3 py-2 text-start">{{'Item' | translate }}</td>
            <td class="px-3 py-2 text-end">{{'Quantity' | translate }}</td>
            <td class="px-3 py-2 text-end">{{'Unit Price' | translate }}</td>
            <td class="px-3 py-2 text-end">{{'Tax' | translate }}</td>
            <td class="px-3 py-2 text-end">{{'Total' | translate }}</td>
        </tr>
        <tr class="border-b" *ngFor='let item of data.items'>
            <td class="px-3 py-2 text-start">{{item.item}}</td>
            <td class="px-3 py-2 text-end">{{item.unit}}</td>
            <td class="px-3 py-2 text-end">{{config.currency}} {{item.price}}</td>
            <td class="px-3 py-2 text-end">{{item.tax.length ? item.tax[0].taxName + '(' + item.tax[0].tax +'%)' : 'No Tax'}}</td>
            <td class="px-3 py-2 text-end">{{config.currency}} {{item.unit*item.price}}</td>
        </tr>
        <tr class="bg-gray-200 font-bold">
            <td class="px-3 py-2 text-start" colspan="2">{{'Sub Total' | translate }} - {{config.currency}} {{data.subtotal}}</td>
            <td class="px-3 py-2 text-center" colspan="2">{{'Tax' | translate }} - {{config.currency}} {{data.tax ?? 0}}</td>
            <td class="px-3 py-2 text-end" colspan="2">{{'Total' | translate }} - {{config.currency}} {{data.totalAmount}}</td>
        </tr>
    </table>
</ng-template>