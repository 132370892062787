import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { valueChanges } from 'src/app/shared/utils/formValidator';
import { updateCurrentSupportLimit } from 'src/app/store/actions/usage.action';
import { UpdateSupportEmailLimit } from 'src/app/store/actions/user.actions';
import { RootReducerState } from 'src/app/store/reducers';
import { selectBusiness } from 'src/app/store/selectors/business.selector';
import { selectUsage } from 'src/app/store/selectors/usage.selector';
import { selectUser } from 'src/app/store/selectors/user.selectors';
import { UserManagementService } from '../../user-management.service';

@Component({
  selector: 'app-invite-user',
  templateUrl: './invite-user.component.html',
  styleUrls: ['./invite-user.component.scss']
})
export class InviteUserComponent implements OnInit {


  constructor(private route: ActivatedRoute,
              private fb: FormBuilder,
              private userManagementService: UserManagementService,
              private store: Store<RootReducerState>,
              private spinner: NgxSpinnerService,
              private toastr: ToastrService,
              private router: Router) {
                this.business$ = this.store.pipe(select(selectBusiness));
                this.usage$ = this.store.pipe(select(selectUsage));
              }

  role = null;
  inviteForm: FormGroup;
  permissionsForm: FormGroup;
  business$: Observable<any>;
  usage$: Observable<any>;
  supportEmail;
  companyName: string = '';
  selectedBusiness = null;
  response = '';
  error = '';
  update = false;
  inviteId = null;

  formErrors = {
    email: '',
    firstName: '',
    lastName: ''
  };

  formErrorMessages = {
    firstName: {
      required: 'Firstname is required'
    },
    lastName: {
      required: 'Lastname is required'
    },
    email: {
      required: 'Email is required',
      pattern: 'Email must be in correct format'
    }
  };

  invitedUsers = [];

  ngOnInit(): void {
    this.getSupportEmailLimit();
    this.getCompanyName();
    this.loadPermissionsForm();
    this.loadInviteForm();
    this.loadInvitedUsers();
    this.route.params.subscribe(({role}) => {
      this.role = role;
      this.loadInviteForm();
      this.loadPermissionsForm(role);
    });
    this.route.queryParams.subscribe(({inviteId}) => {
      if (inviteId) {
        this.spinner.show();
        this.userManagementService.findInvite(inviteId).subscribe((resp) => {
          this.spinner.hide();
          console.log(resp);
          this.inviteId = inviteId;
          this.update = true;
          this.loadInviteForm(resp.data);
          this.loadPermissionsForm(null, resp.data.businesses.permission);
        }, (error) => {
          this.spinner.hide();
          this.router.navigate(['/settings/users-management']);
        });
      }
    });
    this.business$.subscribe((business) => {
      this.selectedBusiness = business;
    });
  }

  getSupportEmailLimit(): void {
    this.usage$.subscribe(({currentUsage})=>{
      if(currentUsage){
        const { supportEmail } = currentUsage;
        this.supportEmail = supportEmail;
      }
    })
  }

  getCompanyName(): void {
    this.userManagementService.companyName.subscribe(name => {
      this.companyName = name;
    })
  }

  loadInviteForm(info?): void {
    this.inviteForm = this.fb.group({
      firstName: [info?.firstName ? info?.firstName : '', [Validators.required]],
      lastName: [info?.lastName ? info?.lastName : '', [Validators.required]],
      email: [info?.email ? info?.email : '', [Validators.required, Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]],
      position: [this.role ? this.role : '']
    });

    this.inviteForm.valueChanges.subscribe(({position}) => {
        if (position === 'admin') {
          this.loadPermissionsForm('admin');
        } else if (position === 'viewer') {
          this.loadPermissionsForm('viewer');
        }
        this.formErrors = valueChanges(this.inviteForm, {...this.formErrors}, this.formErrorMessages);
    });
    this.formErrors = valueChanges(this.inviteForm, {...this.formErrors}, this.formErrorMessages);
  }

  loadPermissionsForm(role?, permission?): void {
    this.permissionsForm = this.fb.group({
      sales: [permission ? permission.sales : role === 'admin' ? 'edit' : 'view'],
      purchase: [permission ? permission.purchase : role === 'admin' ? 'edit' : 'view'],
      accounting: [permission ? permission.accounting : role === 'admin' ? 'edit' : 'view'],
      reports: [permission ? permission.reports : role === 'admin' ? 'edit' : 'view'],
      userManagement: [permission ? permission.userManagement : role === 'admin' ? 'edit' : 'view'],
      dataExport: [permission ? permission.dataExport : role === 'admin' ? 'edit' : 'view'],
      projects: [permission ? permission.projects : role === 'admin' ? 'edit' : 'view'],
      dashboard: [permission ? permission.dashboard : role === 'admin' ? 'edit' : 'view'],
      inventory: [permission ? permission.inventory : role === 'admin' ? 'edit' : 'view'],
      budget: [permission ? permission.budget : role === 'admin' ? 'edit' : 'view'],
      payroll: [permission ? permission.payroll : role === 'admin' ? 'edit' : 'view'],
      setting: [permission ? permission.setting : role === 'admin' ? 'edit' : 'view'],
      files: [permission ? permission.files : role === 'admin' ? 'edit' : 'view'],
      markAsReviewed: [permission ? permission.markAsReviewed : role === 'admin' ? 'edit' : 'view'],
      mangeChannel: [permission ? permission.mangeChannel : role === 'admin' ? 'edit' : 'view']
    });

    this.permissionsForm.valueChanges.subscribe((permissionsFormValue) => {
      console.log(permissionsFormValue);
      
      const values = Object.values(permissionsFormValue);
      if (values.every((el) => el === 'view')) {
        this.inviteForm.controls.position.patchValue('viewer');
      }
      else if (values.every((el) => el === 'edit')) {
        this.inviteForm.controls.position.patchValue('admin');
      }
      else {
        this.inviteForm.controls.position.patchValue('custom');
      }
    });
  }

  handleMarkAsReviewed(event): void {
    const accountingPermission = this.permissionsForm.get('accounting').value
    const markAsReviewPermission = this.permissionsForm.get('markAsReviewed').value
    
    if(markAsReviewPermission !== 'none'){
      if(markAsReviewPermission === 'edit'){
        this.permissionsForm.get('accounting').patchValue('edit')
      }
      if(markAsReviewPermission === 'view'){
        if(this.permissionsForm.get('accounting').value === 'none'){
          this.permissionsForm.get('accounting').patchValue('view')
        }
      }
    }
  }

  loadInvitedUsers(): void {
    this.userManagementService.invitedUsers.subscribe((users) => {
      this.invitedUsers = users;
    });
  }

  inviteUser(): void {
    if (this.inviteForm.invalid) {
      this.inviteForm.markAllAsTouched();
      this.formErrors = valueChanges(this.inviteForm, {...this.formErrors}, this.formErrorMessages);
      return;
    }
    console.log(this.invitedUsers);
    const alreadyExist = this.invitedUsers.find(el => el.email === this.inviteForm.value.email);
    console.log(alreadyExist);
    if (alreadyExist) {
      this.toastr.error('Already Invited');
      return;
    }
    const body = {
      ...this.inviteForm.value,
      permission: { ...this.permissionsForm.value },
      businessId: this.selectedBusiness.businessId._id,
      businessName: this.selectedBusiness.businessId.companyName
    };
    this.response = '';
    this.error = '';
    this.spinner.show();
    this.userManagementService.inviteNewUser(body).subscribe((resp) => {
      this.spinner.hide();
      if(resp?.success){
        this.store.dispatch(updateCurrentSupportLimit({supportEmail: this.supportEmail + 1 }))
        this.toastr.success(resp?.message || 'Invitation Sent')
      }
    }, (error) => {  this.toastr.error(error?.error?.message || 'Interval server error');
                     this.spinner.hide();
                  });
  }

  updateUser(): void {
    const body = {
      position: this.inviteForm.value.position,
      permission: { ...this.permissionsForm.value },
      inviteId: this.inviteId,
      businessId: this.selectedBusiness.businessId._id,
      businessName: this.selectedBusiness.businessId.companyName
    };

    this.spinner.show();
    this.userManagementService.updateInvitedUser(body).subscribe((resp) => {
      this.spinner.hide();
      console.log(resp);
      this.response = 'Permission updated';
      setTimeout(() => { this.response = ''; this.router.navigate(['/settings/users-management']); }, 2000);
    }, (error) => {
      this.spinner.hide();
      this.error = error?.error?.message || 'Interval server error';
      console.error(error);
    });
  }

}
