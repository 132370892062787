import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { InvoiceCustomizationService } from '../../invoice-customization.service';

@Component({
  selector: 'app-reminder-settings',
  templateUrl: './reminder-settings.component.html',
  styleUrls: ['./reminder-settings.component.scss']
})
export class ReminderSettingsComponent implements OnInit {

  constructor(private invoiceCustomizationService: InvoiceCustomizationService,
              private spinner: NgxSpinnerService) { }

  reminder = new FormControl(false);
  response = null;
  error = null;
  ngOnInit(): void {
    this.invoiceCustomizationService.invoiceCustomizationSettings$.subscribe((settings) => {
      if (settings?.reminder) {
        this.reminder.patchValue(settings?.reminder);
      }
    });
  }

  saveChanges(): void {
    this.spinner.show();
    this.invoiceCustomizationService.saveInvoiceCustomizationSettings({reminder: this.reminder.value}).subscribe((resp) => {
      this.response = 'Changes Saved';
      setTimeout(() => this.response = '', 2000);
      this.spinner.hide();
    }, (error) => {
      this.spinner.hide();
      this.error = error?.error?.message || 'Internal server error';
    });
  }

}
