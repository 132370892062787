<div class="flex justify-center p-4">
    <div class="w-full flex flex-col space-y-4">
      <div>
        <div >
          <div class="">
            <h1 class="text-2xl Gilroy-Bold inline-flex items-center">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 me-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z" />
              </svg>
             {{viewProjectDetails?.projectDetails?.projectName}}
            </h1>
          <!-- </div>
          <div class="">
              <app-button (click)="editCustomer(viewCustomerDetail._id)" buttonText='{{"Edit Customer" | translate}}' type='primary'>{{'Edit Customer' | translate}}</app-button>
          </div>
        </div> -->
        <div class="flex mt-5">
          <div class="w-full">
            <div class="grid grid-cols-3 gap-4 mt-5">
              <div class="bg-input rounded-xl p-5">
                <p class="flex Gilroy-Bold text-xl">{{totalInvoiceAmount - totalBillAmount >= 0? 'Profit':'Loss'}}% 
                    <svg *ngIf='totalInvoiceAmount - totalBillAmount > 0' xmlns="http://www.w3.org/2000/svg" class="ms-2 h-6 w-6 text-green-400 " fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6" />
                    </svg>
                    <svg *ngIf='totalInvoiceAmount - totalBillAmount < 0' xmlns="http://www.w3.org/2000/svg" class="ms-2 h-6 w-6 text-red-700" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 17h8m0 0V9m0 8l-8-8-4 4-6-6" />
                    </svg>   
                </p>
                <h3 [ngClass]="totalInvoiceAmount - totalBillAmount > 0 ? 'text-green-400' : totalInvoiceAmount - totalBillAmount < 0? 'text-red-700' : ''" class="text-2xl font-bold"> {{((totalInvoiceAmount - totalBillAmount)*100/totalInvoiceAmount) | number : '1.2-2' }} </h3>
              </div>
              <div class="bg-input rounded-xl p-5">
                <p class="Gilroy-bold text-xl">Total Earnings</p>
                <h3 class="text-2xl font-bold">{{totalInvoiceAmount | currency: currencyDetails?.currency :'symbol-narrow':number}}</h3>
              </div>
              <div class="bg-input rounded-xl p-5">
                <p class="Gilroy-bold text-xl">Total Expenses</p>
                <h3 class="text-2xl font-bold">{{totalBillAmount | currency:currencyDetails?.currency:'symbol-narrow':number }}</h3>
              </div>
            </div>
            <h3 class="text-xl Gilroy-Bold inline-flex items-center mt-6">{{'Active Filters' | translate}}</h3>
            <div class="flex space-x-2 rtl:space-x-reverse mt-5" [formGroup]='filterForm'>
                <div class="self-end">
                    <label class="Gilroy-Bold" for="startDate">{{'From' | translate}}</label>
                    <input formControlName='fromDate' type="date"  class="w-full bg-input rounded-lg border-0 h-11"/>
                    <span *ngIf='filterFormErrors.fromDate' class="text-red-600">{{filterFormErrors.fromDate}}</span>
                </div>
                <div class="self-end">
                    <label class="Gilroy-Bold" for="endDate">{{'To' | translate}}</label>
                    <input formControlName='endDate' type="date"  class="w-full bg-input rounded-lg border-0 h-11"/>
                    <span *ngIf='filterFormErrors.endDate' class="text-red-600">{{filterFormErrors.endDate}}</span>
                </div>
                <div class="justify-self-start self-end" >
                    <label class="opacity-0" for="Filter Project" >{{'Filter' | translate }}</label>
                    <app-button buttonText='{{"Filter Project Data" | translate}}' size='large' type='primary' (handleClick)='filterData()'></app-button>
                </div>
            </div>
            <div class="mt-5">
            <my-tabs >
                <my-tab tabTitle="{{'Invoices' | translate}}">
                    <app-table-container [tableHeadings]='invoiceTableHeadings' [tableData]='invoices' [keys]='invoiceTableKeys' >
                        </app-table-container>
                </my-tab>
                <my-tab tabTitle="{{'Bills' | translate}}">
                    <app-table-container [tableHeadings]='billTableHeadings' [tableData]='bills' [keys]='billTableKeys' >
                    </app-table-container>
                </my-tab>
                <!-- <my-tab tabTitle="{{'Files' | translate}}" >
                   <div class="flex flex-col space-y-4 " >
                      <app-button appAccessControl moduleType="storage" accessType="edit" [buttonText]="'Upload Files' | translate" type="primary" (handleClick)="uploadFiles()" size="large" ></app-button>
                      <app-file-upload appAccessControl moduleType="storage" accessType="view" [addedFiles]="addedFiles" (emitter)="saveFiles($event)" ></app-file-upload>
                   </div>
                </my-tab> -->
            </my-tabs>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>