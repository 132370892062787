<div class="main" [dir]="direction">
  <div class="flex min-h-screen">
    <ng-container *ngIf="showSidebar">
      <div [ngClass]="!isMobileDevice ? 'w-1/6' : 'w-0'">
        <app-sidebar
          [primary]="showSettingsSidebar ? true : false"
          [plus]="showPlusSidebar? true: false"
        ></app-sidebar>
      </div>
    </ng-container>
    <div [ngClass]="showSidebar && !isMobileDevice ? 'w-5/6' : 'w-full'">
      <router-outlet></router-outlet>
    </div>
  </div>
  <ngx-spinner
    bdColor="rgba(138,170,172,0.49)"
    size="medium"
    color="#0C4D71"
    type="ball-clip-rotate"
    [fullScreen]="true"
  ></ngx-spinner>
</div>

<div *ngIf="showModal" class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex">
  <div class="relative w-auto my-6 mx-auto max-w-6xl">
    <!--content-->
    <div class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
      <!--header-->
      <div class="flex items-start justify-center p-5 rounded-t">
        <h3 class="text-3xl font-semibold text-center">
          Welcome To Numetric
        </h3>
      </div>
      <!--body-->
      <div class="relative p-6 flex-auto">
        <p class="my-4 text-slate-500 text-lg leading-relaxed">
          To Checkout out Product specifically your country, please visit the {{userCountry == 'Jordan' ? "Jordan site" : "Saudi Arabia site"}}
        </p>
      </div>
      <!--footer-->
      <div class="flex items-center justify-center p-6 rounded-b">
        <button class="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button" (click)="toggleModal()">
          Close
        </button>
        <button class="bg-green-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button">
          <a href="{{redirectLink}}">Take me there</a>
        </button>
      </div>
    </div>
  </div>
</div>
<div *ngIf="showModal" class="opacity-25 fixed inset-0 z-40 bg-black"></div>
