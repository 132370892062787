<div class="flex justify-center p-4">
    <div class="w-full flex flex-col space- y-4">
      <div class="flex items-center justify-between">
        <h1 class="text-2xl Gilroy-Bold inline-flex items-center">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 me-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z" />
          </svg>
          <span class="text-2xl Gilroy-Bold inline-flex items-center" >{{'New Recurring Invoice' | translate}}</span>
        </h1>
        <div class="space-x-2 rtl:space-x-reverse">
          <app-button type='primary' buttonText='{{"Create Recurring Invoice" | translate}}' (handleClick)='createInvoice()'></app-button>
          <app-button buttonText='{{"Cancel" | translate}}' type='third' routerLink='../'></app-button>
        </div>
      </div>
      <div class="w-5/6 m-auto mt-5">
        <form class="space-y-4 mb-4" [formGroup]='invoiceForm'>
          <div class="rounded-3xl px-8 py-5 box-shadow-sales bg-white">
            <h3 class="Gilroy-Bold text-lg mb-4">{{'Business & Contact Details' | translate}}</h3>
            <div class="space-y-6 w-full">
              <div class="flex space-x-8 rtl:space-x-reverse w-full">
                <div class="flex-1 mb-4">
                  <label for='mobileNumber' class="block text-sm Gilroy-Bold font-color-05 mb-2">{{'Company Logo' | translate}}</label>
                  <div class="border flex items-center justify-center w-[320px] h-[180px] bg-white">
                    <svg *ngIf='!customizationSettings?.companyLogo' xmlns="http://www.w3.org/2000/svg" class="h-16 w-16" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4" />
                    </svg>
                    <img *ngIf='customizationSettings?.companyLogo' [src]="customizationSettings?.companyLogo" class="w-full" alt="Logo">
                  </div>
                </div>
                <div class="flex-1 mb-4">
                  <div>
                    <label for='title' class="block text-sm Gilroy-Bold font-color-05  mb-2">{{'Invoice Title' | translate }}</label>
                    <fieldset [disabled]='!isFieldEditable'>
                      <input id="title" fieldS formControlName="title" type="text" class="bg-input h-12 mb-3 rounded-xl w-full ps-5 border-0" placeholder="{{'Invoice Title' | translate}}" />
                      <div class="text-red-500" *ngIf='formErrors.title'>{{formErrors.title}}</div>
                    </fieldset>
                  </div>
                  <div>
                    <label class="block text-sm Gilroy-Bold font-color-05  mb-2">{{'Invoice Summary' | translate}}</label>
                    <fieldset [disabled]='!isFieldEditable'>
                      <textarea formControlName='subHeading' placeholder="{{'Invoice Summary' | translate}}" class="bg-input h-24 rounded-xl w-full ps-5 border-0 "></textarea>
                    </fieldset>
                  </div>
                  <!-- <div>
                    <label class="inline-flex mt-3 items-center">
                      <input formControlName='reminder' type="checkbox" class="me-2">
                      <span class="block Gilroy-SemiBold">{{'Remind customers 3, 7, and 14 days after due date' | translate}}</span>
                  </label>
                  <p class="mb-4">{{'You can edit the reminders on individual invoices anytime.' | translate }}</p>
                  </div> -->
                </div>
              </div>
              <div class="flex flex-col w-full">
                <label class="block text-sm Gilroy-Bold font-color-05 mb-1">{{'Contact Details' | translate}}</label>
                <ng-container *ngIf='business'>
                  <div class="Gilroy-Bold">{{business?.companyName}}</div>
                  <div>{{business?.address?.addressLineFirst}}</div>
                  <div>{{business?.address?.addressLine2}}</div>
                  <div>{{business?.address?.city ? business?.address?.city + ',' : ''}} {{business?.address?.state}}</div>
                  <div>{{business?.country}}</div>
                </ng-container>
              </div>
            </div>
          </div>
          <div class="rounded-3xl px-8 py-5 box-shadow-sales bg-white">
            <div class="w-full space-y-8">
              <div class="flex">
              <div class="w-1/3 pe-1">
                <label class="block text-sm Gilroy-Bold font-color-05 mb-2">{{'Add a Customer' | translate}}</label>
                <fieldset [disabled]='!isFieldEditable'>
                  <ng-select [items]="customers"
                  class="custom-selector" 
                  bindLabel="customerName" 
                  bindValue="_id"
                  formControlName="customerId"
                  [readonly]='!isFieldEditable'
                  placeholder='{{"Type Customer Name" | translate}}'>
                </ng-select>
              </fieldset>
              </div>
              <div class="w-1/3 px-1">
                <label class="block text-sm Gilroy-Bold font-color-05 mb-2">{{'Add a Project' | translate}}</label>
                <fieldset [disabled]='!isFieldEditable'>
                  <ng-select [items]="projects"
                  class="custom-selector" 
                  bindLabel="projectName" 
                  bindValue="_id"
                  formControlName="projectId"
                  [readonly]='!isFieldEditable'
                  placeholder='{{"Select Project Name" | translate}}'>
                </ng-select>
              </fieldset>
              </div>
              <div class="w-1/3 pe-1">
                <label class="block text-sm Gilroy-Bold font-color-05 mb-2">{{'Purchase Order' | translate}}</label>
                <fieldset [disabled]='!isFieldEditable'>
                  <input formControlName='purchaseOrder' type="text" class="bg-input w-full rounded-lg border-0 h-11 " />
                  <div class="text-red-500" *ngIf='formErrors.purchaseOrder'>{{formErrors.purchaseOrder}}</div>
              </fieldset>
              </div>
              </div>
              <div class="flex space-x-2 rtl:space-x-reverse">
                <!-- <div class="flex flex-col w-1/4">
                  <label class="Gilroy-Bold">{{"Invoice #" | translate}}</label>
                  <fieldset [disabled]='!isFieldEditable' >  
                    <input formControlName='invoiceNumber' type="text" class="bg-input w-full rounded-lg border-0 h-11 " />
                    <div class="text-red-500" *ngIf='formErrors.invoiceNumber'>{{formErrors.invoiceNumber}}</div>
                  </fieldset>
                </div> -->
                <div class="flex flex-col w-1/4">
                  <label class="Gilroy-Bold">{{'Frequency' | translate}}</label>
                  <fieldset [disabled]='!isFieldEditable' >
                    <ng-select [items]="frequency"
                        class="custom-selector"
                        formControlName="frequency"
                        [readonly]='!isFieldEditable'
                        [clearable]="false"
                        placeholder='{{"Select Frequency" | translate}}'>
                    </ng-select>
                    <div class="text-red-500" *ngIf='formErrors.frequency'>{{formErrors.frequency}}</div> 
                    <!-- <input formControlName='purchaseOrder' type="text" class="bg-input w-full rounded-lg border-0 h-11 " /> -->
                  </fieldset>
                </div>
                <div class="flex flex-col w-1/4">
                  <label class="Gilroy-Bold">{{'Payment Terms' | translate}}</label>
                  <fieldset [disabled]='!isFieldEditable' >
                    <ng-select #select [items]="paymentTerms"
                        class="custom-selector"
                        formControlName="paymentTerms"
                        bindLabel="paymentTerm"
                        bindValue="_id"
                        [readonly]='!isFieldEditable'
                        [clearable]="false"
                        placeholder='{{"Select Payment Terms" | translate}}'>
                        <ng-template ng-footer-tmp>
                          <p class="create-new cursor-pointer" (click)="showCustomPaymentTermsModal(select)">Create New </p>
                       </ng-template>
                    </ng-select>
                    <div class="text-red-500" *ngIf='formErrors.paymentTerms'>{{formErrors.paymentTerms}}</div> 
                    <!-- <input formControlName='purchaseOrder' type="text" class="bg-input w-full rounded-lg border-0 h-11 " /> -->
                  </fieldset>
                </div>
                <div class="flex flex-col w-1/4">
                  <label class="Gilroy-Bold">{{'Start Date' | translate}}</label>
                  <fieldset [disabled]='!isFieldEditable' >
                    <input formControlName='startDate' [min]="today" type="date" class="bg-input w-full rounded-lg border-0 h-11 " />
                    <div class="text-red-500" *ngIf='formErrors.startDate'>{{formErrors.startDate}}</div>
                  </fieldset>
                </div>
                <div class="flex flex-col w-1/4">
                  <label class="Gilroy-Bold">{{'End Date' | translate}}</label>
                  <input formControlName='endDate' [min]="invoiceForm.get('startDate').value" type="date" class="bg-input rounded-lg border-0 h-11" />
                  <div class="text-red-500" *ngIf='formErrors.endDate'>{{formErrors.endDate}}</div>
                </div>
              </div>
              <table class="w-full">
                <tr>
                  <td class="p-2 bg-input Gilroy-Bold rounded-s-xl w-3/14">{{tableFields.items | translate}}</td>
                  <td class="p-2 bg-input Gilroy-Bold w-3/12">{{tableFields.sku | translate}}</td>
                  <td class="p-2 bg-input Gilroy-Bold w-3/12">{{tableFields.hsn_sac | translate}}</td>
                  <td class="p-2 bg-input Gilroy-Bold w-1/12">{{tableFields.units | translate}}</td>
                  <td class="p-2 bg-input Gilroy-Bold w-2/12">{{tableFields.price | translate}}</td>
                  <td class="p-2 bg-input Gilroy-Bold w-3/12">{{'Tax:' | translate}}</td>
                  <td class="p-2 bg-input Gilroy-Bold">{{tableFields.amount | translate}}</td>
                  <td class="p-2 bg-input Gilroy-Bold rounded-e-xl"></td>
                </tr>
                <ng-container formArrayName='items'>
                  <ng-container *ngFor='let control of items?.controls; let i = index;'>
                      <tr [formGroup]='control' class="">
                          <td class="w-3/12 p-2">
                              <ng-select [items]="avaialbleProducts"
                              class="custom-selector" 
                              bindLabel="name" 
                              bindValue="name"
                              formControlName="item"
                              [readonly]='!isFieldEditable'
                              [hideSelected]="true"
                              [clearable]="false"
                              placeholder='{{"Select one" | translate}}'
                              (change) = "changeEvent($event, i)">
                            </ng-select>
                          </td>
                          <td class="p-2 w-3/12" >
                            <fieldset disabled='true'>
                              <input formControlName='sku' type="text" class="w-full p-3 rounded-xl bg-input border-0"/>
                            </fieldset>
                          </td>
                          <td class="p-2 w-3/12" >
                            <fieldset disabled='true'>
                              <input formControlName='hsn_sac' type="text" class="w-full p-3 rounded-xl bg-input border-0"/>
                            </fieldset>
                          </td>
                          <td class="p-2 w-1/12">
                            <fieldset [disabled]='!isFieldEditable'>
                              <input id='quantity' formControlName='unit' type="number" min="0" class="w-full p-3 rounded-xl bg-input border-0"/>
                            </fieldset>
                          </td>
                          <td class="p-2 w-2/12">
                            <fieldset [disabled]='!isFieldEditable'>  
                              <input id='price' formControlName='price' type="number" min="0" class="w-full p-3 rounded-xl bg-input border-0"/>
                              <div class="text-red-500" *ngIf='formErrors.price'>{{formErrors.price}}</div>
                            </fieldset>
                          </td>
                          <td class="p-2 w-3/12">
                              <ng-select class="custom-selector border-0 p-0" 
                              [readonly]='!isFieldEditable'
                              [items]="taxes" 
                              bindLabel="name" 
                              formControlName="tax"
                              multiple="true"
                              (change)= 'changeTax($event, i)'
                              placeholder='{{"Select Tax" | translate}}'>
                              <ng-template ng-label-tmp let-item="item" let-clear="clear">
                                <span class="ng-value-label">{{item.taxName}} {{item.tax | number: number}}%</span>
                                <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                              </ng-template>
                            </ng-select>
                          </td>
                          <td class="p-2 ">
                              <p>{{items.controls[i].get('unit').value * items.controls[i].get('price').value | currency: currencySymbol : 'symbol-narrow' : number}}</p>
                          </td>
                          <td *ngIf='isFieldEditable' class="w-1/12">
                              <svg (click)='removeItem(i)' xmlns="http://www.w3.org/2000/svg" name="delete" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="mx-auto h-5 w-5 text-red-500"><path _ngcontent-hsm-c58="" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"></path></svg>
                          </td>
                      </tr>
                      <tr>
                        <td colspan="3"></td>
                        <td>
                          <p class="text-red-500" *ngIf='control.get("unit").hasError("min")'>{{'Atleast 1 unit is required'}}</p>
                        </td>
                        <td colspan="4" ></td>
                      </tr>
  
                  </ng-container>
                  <ng-container *ngIf='items.controls.length === 0'>
                    <tr>
                      <td colspan="8" class="text-center p-2">{{'No items added' | translate}}</td>
                    </tr>
                  </ng-container>
                </ng-container>
                <tr>
                  <td  vAlign='top' colspan="3"> 
                      <div *ngIf='isFieldEditable' class="ps-2">
                          <button  (click)='addNewItem()' id="add" class="flex items-center Gilroy-Bold space-x-4">
                              <span  class="rounded-full bg-gradient text-white p-1 me-2">
                                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="h-4 w-4">
                                      <path  stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path>
                                  </svg>
                              </span> {{'Add a Line' | translate}}
                          </button>
                      </div>
                  </td>
                  <td colspan="4">
                    <div class="Gilroy-Bold flex justify-between py-2">
                      <p>{{'Subtotal:' | translate}}</p>
                      <p>{{subTotal | currency: currencySymbol : 'symbol-narrow' : number}}</p>
                    </div>
                    <div class="Gilroy-Bold flex items-center justify-between py-2">
                      <p>{{'Discount (%):' | translate}}</p>
                      <fieldset class="w-1/9" [disabled]='!isFieldEditable'>
                        <input type="number" class="p-2 rounded-xl bg-input border-0" formControlName='discount' min="0">
                        <div class='text-red-400' *ngIf='formErrors.discount'>{{formErrors.discount}}</div>
                      </fieldset>
                      <p>{{discount | currency: currencySymbol : 'symbol-narrow' : number}}</p>
                    </div>
                    <div class="Gilroy-Bold flex justify-between py-2">
                      <p>{{'Discount Subtotal:' | translate}} </p>
                      <p>{{discountValue | currency: currencySymbol : 'symbol-narrow' : number}}</p>
                    </div>
                    <div class="Gilroy-Bold flex justify-between py-2">
                      <p>{{'Tax:' | translate}}</p>
                      <p>{{tax | currency: currencySymbol : 'symbol-narrow' : number}}</p>
                    </div>
                    <div class="Gilroy-Bold flex justify-between py-2 border-t">
                      <p>{{'Total Amount:' | translate}}</p>
                      <p>{{totalAmount | currency: currencySymbol : 'symbol-narrow' : number}}</p>
                    </div>
                  </td>
              </tr> 
              </table>
              <div class="flex">
                 <div class="flex-1">
                   <h3 class="block text-sm Gilroy-Bold font-color-05 mb-2">{{'E-Sign' | translate}}</h3>
                   <div class="px-8 py-5 box-shadow-sales bg-white  rounded-xl flex items-center justify-center p-4 h-40">
                    <label (click)="openEsignModal()" *ngIf='!invoiceData.eSign' class='text-center flex-col p-4' >
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 mx-auto" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                        </svg>
                        <span class="text-center block mt-2" (handleClick)='openCreateEsign = true'>{{'Add E-Sign' | translate}} </span>
                    </label>
                    <img class="esign" [src]='invoiceData.eSign' [ngClass]='invoiceData.eSign?"":"hidden"' class='w-[420px] h-[236px]' alt="" srcset="">
                  </div>
                  <div [ngClass]='invoiceData.eSign?"":"hidden"' class='flex justify-center my-4'>
                    <app-button *ngIf='isFieldEditable' class="me-2" type='primary' buttonText='{{"Change" | translate}}' [rounded]='true' size='small' (click)='openCreateEsign = true'></app-button>
                    <app-button *ngIf='isFieldEditable' type='third' buttonText='{{"Remove" | translate}}' [rounded]='true' size='small' (click)='removeEsign()'></app-button>
                  </div>
                 </div>
                 <div class="flex-1 ms-3">
                   <h3 class="block text-sm Gilroy-Bold font-color-05 mb-2">
                    {{'QR Code' | translate}}
                  </h3>
                  <div class="px-8 py-5 box-shadow-sales bg-white rounded-xl items-center justify-center">
                    <div>{{'Heading' | translate}}</div>
                    <fieldset [disabled]='!isFieldEditable'>
                      <input formControlName='qrHeading' type="text" class="mb-2 p-2 rounded-xl bg-input border-0 w-full">
                    </fieldset>
                    <div>{{'Image' | translate}}</div>
                    <div class=" bg-white flex rounded-xl items-center justify-center mt-2 mb-4 p-4 h-40">
                      <label [ngClass]='invoiceData.qrCode?"hidden":""' for='qrCode' class='text-center flex-col p-4'>
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 mx-auto" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                        </svg>
                        <span>{{'Add QR Code' | translate}}</span>
                      </label>
                      <fieldset [disabled]='!isFieldEditable'>
                        <input type="file" (change)="fileEventForQR($event)" hidden id='qrCode'>
                      </fieldset>
                      <img class="qrCode" [src]='invoiceData.qrCode' [ngClass]='invoiceData.qrCode?"":"hidden"' width='120' alt="" srcset="">
                    </div>
                    <div [ngClass]='invoiceData.qrCode?"":"hidden"' class='flex justify-center mb-4'>
                      <label for='qrCode' class='px-4 py-1 text-sm rounded-full me-2 text-white bg-gradient cursor-pointer'>
                        {{'Change' | translate}}
                      </label>
                      <fieldset [disabled]='!isFieldEditable'>
                        <input id='qrCode' type="file" (change)='fileEventForQR($event)' hidden>
                      </fieldset>
                        <app-button type='third' buttonText='{{"Remove" | translate}}' [rounded]='true' size='small' (click)='removeQRCode()'></app-button>
                    </div>
                  </div>
                
                 </div>
              </div>
              <div class="pt-4 border-t border-gray-100 space-y-4">
                  <div class="flex flex-col">
                    <label class="Gilroy-Bold">{{'Notes/Terms' | translate}}</label>
                    <fieldset [disabled]='!isFieldEditable'>
                      <textarea formControlName='notesTerms' class="h-40 w-full rounded-xl bg-input border-0"></textarea>
                    </fieldset>
                  </div>
                  <div class="flex flex-col">
                    <label class="Gilroy-Bold">{{'Footer' | translate}}</label>
                    <fieldset [disabled]='!isFieldEditable'>
                      <textarea formControlName='Footer' class="h-40 w-full rounded-xl bg-input border-0"></textarea>
                    </fieldset>
                  </div>
              </div>
              <!-- File Upload Component -->
              <!-- <div appAccessControl moduleType="storage" accessType="edit" class="mt-4">
                <app-file-upload (emitter)="saveFiles($event)" ></app-file-upload>
              </div> -->
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  
  
  <app-modal-container [isOpen]='openCreateEsign'>
    <div header class="border-b pb-2 Gilroy-Bold"> {{'E-Sign Settings' | translate}} </div>
    <div content>
      <div class="">
        <div class="bg-white">
          <div class="sm:flex sm:items-start">
            <div class="mt-3 w-full text-center sm:mt-0 sm:mx-4 sm:ltr:text-left sm:rtl:text-right">     
              <div *ngIf='previewSignatureForDoc'>
                <div class="mt-2 w-full">
                  <div class="my-5 py-5 flex items-center justify-center border-b">
                    <div class=''>
                      <input id='eSignFile' type="file" (change)='fileEventForESign($event)' hidden>
                      <label for='eSignFile' class='px-6 py-1 h-10 bg-gradient text-white rounded-full hover:dark-blue-2 focus:outline-none'>{{'Add Image' | translate}}</label>
                    </div>
                  </div>
                  <div class="space-y-1">
                    <label for="eSignInput">{{'Type your signature' | translate}}</label>
                    <input type="text" [(ngModel)]='eSignValue'  class="rounded-full w-full  border-none bg-input px-5">
                  </div>
                </div>
              </div>
              <div *ngIf='!previewSignatureForDoc'>
                <div class="flex space-y-4 flex-col mt-4">
                  <div class="flex space-x-4 rtl:space-x-reverse items-center">
                    <input [(ngModel)]='eSignSelected' type="radio" id ='eSign1' name="esignRadio" value="eSignType1">
                    <label for="eSign1" id='eSignType1' class="flex-1 whitespace-nowrap h-[110px] px-6 Allison-Regular text-6xl text-center leading-[110px]">
                      {{eSignValue}}
                    </label>
                  </div>
                  <div class="flex space-x-4 rtl:space-x-reverse items-center">
                    <input [(ngModel)]='eSignSelected' type="radio" id='eSign2' name="esignRadio" value="eSignType2">
                    <label for="eSign2" id='eSignType2' class="flex-1 whitespace-nowrap h-[110px] px-6 Dancing-Regular text-6xl text-center leading-[110px]">
                      {{eSignValue}}
                    </label>
                  </div>
                  <div class="flex space-x-4 rtl:space-x-reverse items-center">
                    <input [(ngModel)]='eSignSelected' type="radio" id='eSign3' name="esignRadio" value="eSignType3">
                    <label for="eSign3" id='eSignType3' class="flex-1 whitespace-nowrap h-[110px] px-6 Saint-Regular text-6xl text-center leading-[110px]">
                      {{eSignValue}}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div footer>
      <div *ngIf='previewSignatureForDoc'>
        <button (click)='previewSignatureForDoc = false' type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-gradient text-base font-medium text-white hover:dark-blue-2 focus:outline-none sm:ms-3 sm:w-auto sm:text-sm">
          {{'Preview' | translate}}
        </button>
        <button (click)='openCreateEsign = false' type="button" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ms-3 sm:w-auto sm:text-sm">
          {{'Cancel' | translate}}
        </button>
      </div>
      <div *ngIf='!previewSignatureForDoc'>
        <button (click)='createSignature()' type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-gradient text-base font-medium text-white hover:dark-blue-2 focus:outline-none sm:ms-3 sm:w-auto sm:text-sm">
          {{'Save' | translate}}
        </button>
        <button (click)='previewSignatureForDoc = true' type="button" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ms-3 sm:w-auto sm:text-sm">
          {{'Back' | translate}}
        </button>
      </div>
    </div>
  
  </app-modal-container>
  
  <app-modal-container [isOpen]='showCustomPaymentTerms'>
    <div header class="text-start border-b pb-2 Gilroy-Bold">{{'Add New Payment Term' | translate}}</div>
    <div class="text-start" [formGroup]="paymentTermForm" content>
      <div class="flex flex-col w-1/2">
        <label class="Gilroy-Bold">{{'New Payment Term' | translate}}</label>
        <fieldset >
          <input id="paymentTerm" formControlName='paymentTerm' type="text" class="bg-input w-full rounded-lg border-0 h-11 " />
          <div class="text-red-500" *ngIf='paymentTermFormErrors.paymentTerm'>{{paymentTermFormErrors.paymentTerm}}</div>
        </fieldset>
      </div>
      <div class="flex flex-col w-1/2">
        <label class="Gilroy-Bold">{{'New Payment Term Days' | translate}}</label>
        <fieldset >
          <input formControlName='days' type="number" class="bg-input w-full rounded-lg border-0 h-11 " />
          <div class="text-red-500" *ngIf='paymentTermFormErrors.days'>{{paymentTermFormErrors.days}}</div>
        </fieldset>
      </div>
    </div>
    <div footer class="flex items-center ms-auto space-x-2 rtl:space-x-reverse">
        <app-button buttonText='{{"Create" | translate}}' type='primary' rounded=true (handleClick)='createPaymentTerms()' ></app-button>
        <app-button buttonText='{{"Close" | translate}}' type='third' rounded=true (handleClick)='showCustomPaymentTerms = false'></app-button>
    </div>
  </app-modal-container>
  
  <app-image-cropper [isOpen]='openModalForEsign' [imageCropperConfig]='imageCropperConfig' (base64Image)='loadEsign($event)' (closeImage)='openModalForEsign=false'></app-image-cropper>
  
  <app-image-cropper [isOpen]='openModal' [imageCropperConfig]='imageDataQR' (base64Image)='loadQR($event)' (closeImage)='openModal=false'></app-image-cropper>
  
  <!-- <app-image-cropper [imageData]='imageDataEsign' (base64Image)='loadEsign($event)'></app-image-cropper> -->
  