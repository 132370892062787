<div class="hire-expert-container">
  <div class="page-list">
    <div class="page-header flex justify-between items-center">
      <h3 class="Gilroy-SemiBold text-xl">
        {{ "Hire an Expert" | translate }}
      </h3>
      <button
        (click)="openFilter()"
        class="page-filter border-[#EAEAEA] border rounded-[10px] p-3 flex gap-[6px] text-xs Gilroy-SemiBold items-center opacity-100"
      >
        <img src="/assets/images/filter-icon.svg" alt="" />
        {{ "Filter" | translate }}
      </button>
    </div>
    <div class="list-items">
      <ng-container *ngIf="expertsData?.length > 0; else noExpertAvailable">
        <ng-container *ngFor="let expert of expertsData">
          <div
            routerLink="/experts/hire-expert/expert-details/{{
              expert?.numetricUserId
            }}"
            class="single-item justify-between cursor-pointer flex w-full pt-4 mt-4 border-t-2 border-[#EAEAEA]"
          >
            <div class="w-full flex gap-2">
              <div class="profile w-[17%] flex justify-center">
                <img
                  src="{{ environmentPath }}{{ expert?.profileImage }}"
                  class="h-24 w-24 rounded-[50%] max-w-[110px]"
                  alt=""
                />
              </div>
              <div class="content w-[70%] flex flex-col gap-[6px]">
                <h3 class="flex gap-2 items-center Gilroy-SemiBold text-lg">
                  {{ expert?.firstName + " " + expert?.lastName }}
                  <!-- <img
                    class="w-5 h-5"
                    src="/assets/images/india-flag.png"
                    alt=""
                  /> -->
                </h3>
                <h4 class="flex gap-2 items-center Gilroy-SemiBold text-base">
                  {{ expert?.companyName }}
                </h4>
                <div class="flex flex-wrap items-center gap-1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-6 h-6 opacity-40"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z"
                    />
                  </svg>
                  <p class="opacity-40 text-xs">
                    {{ "Specializations" | translate }}:
                  </p>
                  <span
                    *ngFor="let item of expert?.specialization; index as i"
                    class="text-xs Gilroy-SemiBold"
                    >{{ item.name }} <span *ngIf="i+1 !== expert?.specialization.length">|</span></span
                  >
                </div>
                <p class="text-xs opacity-40 h-[35px] relative overflow-hidden">
                  {{ expert?.description }}<span *ngIf="expert?.description.length >160" class="absolute top-[16px] right-[0px]">...</span>
                </p>
              </div>              
            </div>
            <div class="badge flex items-center justify-end w-[20%]">
              <img
                class="badge-image min-h-[4.5rem] w-36 max-w-[8rem]"
                [src]="
                  '/assets/images/' + badgeType(expert?.expertType) + '.jpeg'
                "
                alt=""
              />
            </div>
          </div>
        </ng-container>
      </ng-container>
      <ng-template #noExpertAvailable>
        <div class="mt-8">{{ "No Expert Available" | translate }}</div>
      </ng-template>
    </div>
  </div>
  <div class="direct-options px-4">
    <div class="rounded-xl opacity-100 bg-[#F7F7F7] h-full px-4 pt-8 pb-16">
      <h2 class="text-lg text-center Gilroy-SemiBold">
        {{"Partner with our network of experts" | translate}}
      </h2>
      <img class="mt-8 px-[10px]" src="/assets/images/hireExpertImage.png" alt="" />
      <div class="hire-points text-center mt-10 mb-8">
        <h3 class="text-base Gilroy-SemiBold mb-4">{{"Hire a NuMetric Expert now!?" | translate}}</h3>
        <p class="text-sm leading-6">{{"Connect with skilled accounting professionals" | translate}}</p>
        <p class="text-sm leading-6">{{"Your dedicated team of accounting experts" | translate}}</p>
        <p class="text-sm leading-6">{{"Tailored support for the success of your business" | translate}}</p>
      </div>
      <div class="flex flex-col items-center">
        <!-- <button
          class="button-gradient mb-4 text-sm text-white rounded-xl px-[10px] py-3 Gilroy-Medium"
        >
          {{"Hire an Expert Now" | translate}}
        </button> -->
        <p class="text-sm text-center leading-6">{{"Elevate your expertise - Join the ranks of NuMetric Experts today!" | translate}}</p>
        <button
          (click)="becomeAnExpert()"
          class="text-sm rounded-xl border mt-1 border-black px-[10px] py-3 Gilroy-SemiBold"
        >
          {{"Become an Expert" | translate}}
        </button>
      </div>
    </div>
  </div>
</div>

<app-modal-container
  (clickOutside)="closeFilterForm()"
  [isOpen]="openFilterModal"
>
  <div header class="border-b pb-2 Gilroy-ExtraBold text-xl">
    {{ "Filter" | translate }}
  </div>
  <div content>
    <div class="flex justify-between">
      <div>
        <h3 class="text-sm Gilroy-SemiBold">
          {{ "Filter by Specialisations" | translate }}
        </h3>
        <ul class="flex flex-col gap-3 mt-4">
          <li class="text-xs items-center">
            <input
              type="checkbox"
              [(ngModel)]="values.accounting"
              class="me-2 filter-checkbox focus:ring-0"
              (change)="selectOption('Accounting', 'specialisation')"
              id="isArchive"
            />{{ "Accounting" | translate }}
          </li>
          <li class="text-xs items-center">
            <input
              type="checkbox"
              [(ngModel)]="values.bookkeeping"
              class="me-2 filter-checkbox focus:ring-0"
              (change)="selectOption('Bookkeeping', 'specialisation')"
              id="isArchive"
            />{{ "Bookkeeping" | translate }}
          </li>
          <li class="text-xs items-center">
            <input
              type="checkbox"
              [(ngModel)]="values.payroll"
              class="me-2 filter-checkbox focus:ring-0"
              (change)="selectOption('Payroll', 'specialisation')"
              id="isArchive"
            />{{ "Payroll" | translate }}
          </li>
          <li class="text-xs items-center">
            <input
              type="checkbox"
              [(ngModel)]="values.internal"
              class="me-2 filter-checkbox focus:ring-0"
              (change)="selectOption('Internal Audit', 'specialisation')"
              id="isArchive"
            />{{ "Internal Audit" | translate }}
          </li>
          <li class="text-xs items-center">
            <input
              type="checkbox"
              [(ngModel)]="values.external"
              class="me-2 filter-checkbox focus:ring-0"
              (change)="selectOption('External Audit', 'specialisation')"
              id="isArchive"
            />{{ "External Audit" | translate }}
          </li>
          <li class="text-xs items-center">
            <input
              type="checkbox"
              [(ngModel)]="values.sales"
              class="me-2 filter-checkbox focus:ring-0"
              (change)="selectOption('Sales Tax', 'specialisation')"
              id="isArchive"
            />{{ "Sales Tax" | translate }}
          </li>
          <li class="text-xs items-center">
            <input
              type="checkbox"
              [(ngModel)]="values.income"
              class="me-2 filter-checkbox focus:ring-0"
              (change)="selectOption('Income Tax', 'specialisation')"
              id="isArchive"
            />{{ "Income Tax" | translate }}
          </li>
          <li class="text-xs items-center">
            <input
              type="checkbox"
              [(ngModel)]="values.financial"
              class="me-2 filter-checkbox focus:ring-0"
              (change)="selectOption('Financial Advisor', 'specialisation')"
              id="isArchive"
            />{{ "Financial Advisor" | translate }}
          </li>
        </ul>
      </div>
      <div>
        <h3 class="text-sm Gilroy-SemiBold">
          {{ "Filter by Qualifications" | translate }}
        </h3>
        <ul class="flex flex-col gap-3 mt-4">
          <li class="text-xs items-center">
            <input
              type="checkbox"
              [(ngModel)]="values.under"
              class="me-2 filter-checkbox focus:ring-0"
              (change)="selectOption('Under Graduate', 'qualification')"
              id="isArchive"
            />{{ "Under Graduate" | translate }}
          </li>
          <li class="text-xs items-center">
            <input
              type="checkbox"
              [(ngModel)]="values.graduate"
              class="me-2 filter-checkbox focus:ring-0"
              (change)="selectOption('Graduate', 'qualification')"
              id="isArchive"
            />{{ "Graduate" | translate }}
          </li>
          <li class="text-xs items-center">
            <input
              type="checkbox"
              [(ngModel)]="values.post"
              class="me-2 filter-checkbox focus:ring-0"
              (change)="selectOption('Post Graduate', 'qualification')"
              id="isArchive"
            />{{ "Post Graduate" | translate }}
          </li>
        </ul>
      </div>
      <div>
        <h3 class="text-sm Gilroy-SemiBold">
          {{ "Filter by Country" | translate }}
        </h3>
        <ul class="flex flex-col gap-3 mt-4">
          <li class="flex text-xs items-center">
            <input
              type="checkbox"
              [(ngModel)]="values.global"
              class="me-2 filter-checkbox focus:ring-0"
              (change)="selectOption('Global', 'country')"
              id="isArchive"
            />{{ "Global" | translate }}
            <!-- <img
              src="/assets/images/india-flag.png"
              class="w-5 h-5 ltr:mr-2 rtl:ml-2"
              alt=""
            /> -->
          </li>
          <li class="flex text-xs items-center">
            <input
              type="checkbox"
              [(ngModel)]="values.jo"
              class="me-2 filter-checkbox focus:ring-0"
              (change)="selectOption('JO', 'country')"
              id="isArchive"
            />{{ "Jordon" | translate }}
            <!-- <img
              src="/assets/images/united-kingdom.png"
              class="w-5 h-5 ltr:mr-2 rtl:ml-2"
              alt=""
            /> -->
          </li>
          <li class="flex text-xs items-center">
            <input
              type="checkbox"
              [(ngModel)]="values.sa"
              class="me-2 filter-checkbox focus:ring-0"
              (change)="selectOption('SA', 'country')"
              id="isArchive"
            />{{ "Saudi Arabia" | translate }}
            <!-- <img
              src="/assets/images/united-kingdom.png"
              class="w-5 h-5 ltr:mr-2 rtl:ml-2"
              alt=""
            /> -->
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div footer class="flex items-center space-x-2 rtl:space-x-reverse">
    <app-button
      buttonText="{{ 'Filter' | translate }}"
      type="primary"
      rounded="true"
      (handleClick)="submitFilterForm()"
    ></app-button>
    <app-button
      buttonText="{{ 'Cancel' | translate }}"
      type="third"
      rounded="true"
      (handleClick)="closeFilterForm()"
    ></app-button>
  </div>
</app-modal-container>
