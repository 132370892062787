<div class="min-h-screen">
    <div class="p-4 space-y-6">
        <div class="flex justify-between items-center">
            <h1 class="Gilroy-Bold text-2xl">{{'Task Manager' | translate}}</h1>
            <!-- <div class="flex space-x-2 items-center">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
                </svg>                  
                <input type="search" class="outline-none border-none" placeholder="search anything"/>
            </div> -->
            <app-dropdown #notificationList (clickOutside)='notificationList.open = false' class="flex notification-task-tray" [ngClass]="{'notification-tray-right' : lang === 'ar' }">
                <span button (click)='notificationList.open = true' class="relative inline-block">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 cursor-pointer">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M14.857 17.082a23.848 23.848 0 0 0 5.454-1.31A8.967 8.967 0 0 1 18 9.75V9A6 6 0 0 0 6 9v.75a8.967 8.967 0 0 1-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 0 1-5.714 0m5.714 0a3 3 0 1 1-5.714 0" />
                    </svg>
                    <span class="absolute -top-2 ltr:-right-1 rtl:-left-1 text-[8px] text-white bg-red-500 w-4 h-4 flex items-center justify-center rounded-full">{{notificationsCount}}</span>
                </span>
                <div options class="py-4">
                    <h3 class="text-sm px-4 Gilroy-SemiBold">
                        {{ "Notifications" | translate }}
                    </h3>
                    <ng-container *ngIf="allNotifications?.length > 0; else noNotification">
                        <div class="notification-height">
                            <ng-container *ngFor="let item of allNotifications">
                                <div (click)="goToNotification(item)" [ngClass]="{'bg-blue-200': !item.seen}" class="px-4 cursor-pointer border-t">
                                    <p id="menu-item-0" class="text-dark-700 py-1 text-sm text-start rounded-xl">{{item.message}}</p>
                                    <p class="text-dark-700 text-xs text-start py-1 rounded-xl">{{item.createdAt | date : 'MMM d, y, h:mm a'}}</p>
                                </div>
                            </ng-container>
                        </div>
                    </ng-container>
                    <ng-template #noNotification><span class="p-4">{{'No data available' | translate}}</span></ng-template>
                </div>
            </app-dropdown>
            <app-dropdown class="absolute ltr:right-4 rtl:left-4 right-options" #dropdownOptions>
                <svg button (click)='dropdownOptions.open=true' (clickOutside)='dropdownOptions.open=false' xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 grip-icon-mobile">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M8.625 12a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H8.25m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H12m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0h-.375M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                </svg>                          
                <div options>
                    <span routerLink="/chats" class="flex items-center cursor-pointer px-4 py-2 text-sm Gilroy-Bold hover:bg-gray-50 text-center rounded-t-xl"><svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 me-2" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M8.625 12a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H8.25m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H12m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0h-.375M21 12c0 4.556-4.03 8.25-9 8.25a9.764 9.764 0 0 1-2.555-.337A5.972 5.972 0 0 1 5.41 20.97a5.969 5.969 0 0 1-.474-.065 4.48 4.48 0 0 0 .978-2.025c.09-.457-.133-.901-.467-1.226C3.93 16.178 3 14.189 3 12c0-4.556 4.03-8.25 9-8.25s9 3.694 9 8.25Z" />
                    </svg>{{'Chats' | translate}}</span>
                    <span routerLink="/task" class="flex items-center cursor-pointer px-4 py-2 text-sm Gilroy-Bold hover:bg-gray-50 text-center rounded-b-xl">                                <svg class="h-6 w-6 me-2" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" color="currentColor"><path d="M9 6L20 6" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M3.80002 5.79999L4.60002 6.59998L6.60001 4.59999" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M3.80002 11.8L4.60002 12.6L6.60001 10.6" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M3.80002 17.8L4.60002 18.6L6.60001 16.6" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M9 12L20 12" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M9 18L20 18" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                        {{'Task-Manager' | translate}}</span>
                    <span routerLink="/files" class="flex items-center cursor-pointer px-4 py-2 text-sm Gilroy-Bold hover:bg-gray-50 text-center rounded-b-xl"><svg class="h-6 w-6 me-2" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M10.6776 2.30337C11.5101 1.89888 12.4899 1.89888 13.3223 2.30337L20.6379 5.85815C22.454 6.74064 22.454 9.25936 20.6379 10.1419L13.3223 13.6966C12.4899 14.1011 11.5101 14.1011 10.6776 13.6966L3.36208 10.1419C1.54595 9.25936 1.54595 6.74064 3.36208 5.85815L10.6776 2.30337ZM12.4408 4.01685C12.1633 3.88202 11.8367 3.88202 11.5592 4.01685L4.24364 7.57163C3.88042 7.74813 3.88042 8.25187 4.24364 8.42837L11.5592 11.9831C11.8367 12.118 12.1633 12.118 12.4408 11.9831L19.7563 8.42837C20.1196 8.25187 20.1195 7.74813 19.7563 7.57163L12.4408 4.01685Z" fill="#000"/>
                        <path d="M2.10555 16.8172C2.35254 16.3232 2.95321 16.123 3.44719 16.37L11.5528 20.4227C11.8343 20.5635 12.1657 20.5635 12.4472 20.4227L20.5528 16.37C21.0467 16.123 21.6474 16.3232 21.8944 16.8172C22.1414 17.3111 21.9412 17.9118 21.4472 18.1588L13.3416 22.2116C12.497 22.6339 11.5029 22.6339 10.6583 22.2116L2.55276 18.1588C2.05878 17.9118 1.85856 17.3111 2.10555 16.8172Z" fill="#000"/>
                        <path d="M3.44742 12.1058C2.95344 11.8588 2.35277 12.059 2.10578 12.553C1.85879 13.047 2.05901 13.6477 2.55299 13.8946L10.6586 17.9474C11.5031 18.3697 12.4973 18.3697 13.3418 17.9474L21.4474 13.8946C21.9414 13.6477 22.1416 13.047 21.8946 12.553C21.6476 12.059 21.047 11.8588 20.553 12.1058L12.4474 16.1586C12.1659 16.2993 11.8345 16.2993 11.553 16.1586L3.44742 12.1058Z" fill="#000"/>
                    </svg>{{'Files' | translate}}</span>
                    <span (click)='logout()'  class="flex items-center cursor-pointer px-4 py-2 text-sm Gilroy-Bold hover:bg-gray-50 text-center rounded-b-xl">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-6 me-2" viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd" d="M3 3a1 1 0 00-1 1v12a1 1 0 102 0V4a1 1 0 00-1-1zm10.293 9.293a1 1 0 001.414 1.414l3-3a1 1 0 000-1.414l-3-3a1 1 0 10-1.414 1.414L14.586 9H7a1 1 0 100 2h7.586l-1.293 1.293z" clip-rule="evenodd" />
                        </svg>
                        {{'Logout' | translate}}
                    </span>
                </div>
            </app-dropdown>
        </div>
        <div class="flex justify-between items-baseline task-type-options">
            <div class="w-2/3 flex flex-wrap gap-4 task-sub-option">
                <ng-container *ngFor="let quickFilter of quickFilters">
                    <div (click)="applyQuickFilter(quickFilter.filterBy)" [ngClass]="quickFilter.active ? 'bg-gradient text-white' : 'text-black bg-white'" class="border rounded-2xl Gilroy-SemiBold flex space-x-2 px-4 py-3 cursor-pointer justify-center items-center w-fit filter-mobile-view">
                        <svg *ngIf="quickFilter.status === 'allTasks'" width="24px" stroke-width="1.5" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" color="currentColor"><path d="M9 6L20 6" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M3.80002 5.79999L4.60002 6.59998L6.60001 4.59999" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M3.80002 11.8L4.60002 12.6L6.60001 10.6" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M3.80002 17.8L4.60002 18.6L6.60001 16.6" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M9 12L20 12" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M9 18L20 18" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                        <svg *ngIf="quickFilter.status === 'inProgress'" width="24px" height="24px" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M11.25 4.5l7.5 7.5-7.5 7.5m-6-15l7.5 7.5-7.5 7.5" />
                        </svg>
                        <svg *ngIf="quickFilter.status === 'completed'" width="24px" height="24px" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>                          
                        <svg *ngIf="quickFilter.status === 'upcoming'" width="24px" height="24px" stroke-width="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" color="currentColor"><path d="M1.5 12.5L5.57574 16.5757C5.81005 16.8101 6.18995 16.8101 6.42426 16.5757L9 14" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"></path><path d="M16 7L12 11" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"></path><path d="M7 12L11.5757 16.5757C11.8101 16.8101 12.1899 16.8101 12.4243 16.5757L22 7" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"></path></svg>
                        <svg *ngIf="quickFilter.status === 'overdue'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M3 8.25V18a2.25 2.25 0 002.25 2.25h13.5A2.25 2.25 0 0021 18V8.25m-18 0V6a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 6v2.25m-18 0h18M5.25 6h.008v.008H5.25V6zM7.5 6h.008v.008H7.5V6zm2.25 0h.008v.008H9.75V6z" />
                        </svg>                       
                        <svg *ngIf="quickFilter.status === 'assignedToMe'" width="24px" height="24px" stroke-width="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" color="currentColor"><path d="M5 20V19C5 15.134 8.13401 12 12 12V12C15.866 12 19 15.134 19 19V20" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M12 12C14.2091 12 16 10.2091 16 8C16 5.79086 14.2091 4 12 4C9.79086 4 8 5.79086 8 8C8 10.2091 9.79086 12 12 12Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                        <h2 class="Gilory-SemiBold text-sm">{{quickFilter.filterBy | translate}}</h2>
                    </div>
                </ng-container>
            </div>
            <div class="1/3 flex items-end flex-col gap-3 full-width">
                <div class="filter-create-task flex items-center gap-3">
                    <app-dropdown #dropdown>
                        <div button (click)="dropdown.open = true" (clickOutside)="dropdown.open = false" class="relative"
                          class="border rounded-2xl flex space-x-2 px-4 py-3 cursor-pointer max-h-[48px] filter-mobile-view">
                        <svg width="24px" height="24px" viewBox="0 0 24 24" stroke-width="1.5" fill="none" xmlns="http://www.w3.org/2000/svg"
                            color="#000000">
                            <path d="M3 6H21" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                            <path d="M7 12L17 12" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                            <path d="M11 18L13 18" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                        </svg>
                          <h2 class="Gilroy-Medium">{{'Filter' | translate}}</h2>
                        </div>
                        <div class="ltr:ml-6 rtl:mr-6 py-4" options>
                          <h3 class="text-sm Gilroy-SemiBold">
                            {{ "Filter" | translate }}
                          </h3>
                          <ul class="flex flex-col gap-3 mt-4">
                            <li class="text-xs items-center">
                              <input
                                type="checkbox"
                                (change)="filterByStatus($event,'All')"
                                class="me-2 filter-checkbox focus:ring-0"
                                id="isSelected"
                              />{{ "All Tasks" | translate }}
                            </li>
                            <li class="text-xs items-center">
                              <input
                                type="checkbox"
                                (change)="filterByStatus($event,'Today(s) task')"
                                class="me-2 filter-checkbox focus:ring-0"
                                id="isSelected"
                              />{{ "Today's task" | translate }}
                            </li>
                          </ul>
                        </div>
                    </app-dropdown>
                    <button (click)="openCreateTask()" class="bg-gradient text-white Gilroy-Bold px-6 py-3 rounded-2xl filter-mobile-view">{{'Create Task' | translate}}</button>
                </div>
                <app-dropdown #dropdownEmail>
                    <div button (click)='dropdownEmail.open = true' (clickOutside)='dropdownEmail.open = false' class="bg-gray-100 px-6 py-3 flex space-x-2 rounded-2xl cursor-pointer">
                        <p class="Gilroy-Medium">{{"Email Digest:" | translate}} {{isEmailSettings ? "On" : "Off"}} <span *ngIf="isEmailSettings">({{mailFrequency | titlecase}})</span></p>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                        </svg>                  
                    </div>
                    <div options class="p-4 space-y-4">
                        <div class="flex space-x-2 items-center cursor-pointer" (click)="dropdownEmail.open = false; emailSettingsModal = true; isEmailSettings = true;">
                            <span class="w-6">
                                <svg *ngIf="isEmailSettings" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="m4.5 12.75 6 6 9-13.5" />
                                </svg>
                            </span>                          
                            <p class="Gilroy-SemiBold">{{"On" | translate}}<span *ngIf="isEmailSettings">({{"Edit" | titlecase}})</span> </p>
                        </div>
                        <div class="flex space-x-2 items-center cursor-pointer" (click)="dropdownEmail.open = false; emailSettingsModal = true; isEmailSettings = false">
                            <span class="w-6">
                                <svg *ngIf="!isEmailSettings" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="m4.5 12.75 6 6 9-13.5" />
                                </svg>
                            </span>
                            <p class="Gilroy-SemiBold">{{"Off" | translate}}</p>
                        </div>
                    </div>
                </app-dropdown>
            </div>
        </div>
        <div class="mt-4">
            <app-table-container
                [tableHeadings]='tableHeadings'
                [tableData]='filteredTask'
                [actionTemplate]='actionTemplate'
                class="task-grid-table">
                <ng-template let-item #actionTemplate>
                   <ng-container>
                    <td [routerLink]="item.taskTitle ? ['/task/view-task', item._id] : ''" class="p-2 px-10 cursor-pointer"><p class="">{{item.taskTitle || '-'}}</p></td>
                    <td [routerLink]="item.taskTitle ? ['/task/view-task', item._id] : ''" class="p-2 cursor-pointer"><p class="">{{item.assignToName || '-'}}</p></td>
                    <td [routerLink]="item.taskTitle ? ['/task/view-task', item._id] : ''" class="p-2 cursor-pointer"><p class="">{{item.taskType || '-'}}</p></td>
                    <td [routerLink]="item.taskTitle ? ['/task/view-task', item._id] : ''" class="p-2 cursor-pointer"><p class="">{{item.dueDate | date}}</p></td>
                    <td [routerLink]="item.taskTitle ? ['/task/view-task', item._id] : ''" class="p-2 cursor-pointer last:pe-10 text-center"><p [ngClass]="{'text-green-500': item.status === 'Completed', 'text-black': item.status === 'Upcoming', 'text-blue-500': item.status === 'In Progress', 'text-red-500': item.status === 'Overdue'}" class="bg-gray-50 px-6 py-2 rounded-2xl Gilroy-Medium w-fit">{{item.status | translate}}</p></td>
                    <td class="p-2 last:pe-10 text-center">
                        <app-dropdown #dropdown>
                            <div button (click)='dropdown.open = true' (clickOutside)='dropdown.open = false' class="cursor-pointer">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM12.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM18.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0z" />
                                </svg>
                            </div>
                            <div options class="p-4 space-y-4">
                                <div (click)="viewTask(item._id)" class="flex space-x-2 items-center cursor-pointer" (click)="dropdown.open = false">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                    </svg>
                                    <p class="Gilroy-SemiBold">{{"View Task" | translate}}</p>
                                </div>
                                <div *ngIf="isCreatedByMe(item)" (click)="openEditModel(item)" class="flex space-x-2 items-center cursor-pointer" (click)="dropdown.open = false">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125" />
                                    </svg>               
                                    <p class="Gilroy-SemiBold">{{"Edit Task" | translate}}</p>
                                </div>
                                <div *ngIf="isCreatedByMe(item)" (click)="openDeleteModal(item)" class="flex items-center cursor-pointer">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" data-slot="icon" class="w-6 h-6 cursor-pointer">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                                    </svg>         
                                    <p class="Gilroy-SemiBold mx-[5px]">{{"Delete Task" | translate}}</p>
                                </div>
                            </div>
                        </app-dropdown>                         
                    </td>
                   </ng-container>
                </ng-template>
            </app-table-container>
        </div>
    </div>
</div>
<app-modal-container [isOpen]="isOpen">
    <div header>
        <div class="flex justify-between items-center">
            <h1 class="Gilroy-Bold text-2xl">{{edit ? 'Update Task' : 'Create Task' | translate}}</h1>
            <svg (click)="isOpen=false; isRecurringTaskUpdate = false" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 cursor-pointer">
                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>              
        </div>
    </div>
    <div content>
        <form [formGroup]="createTaskForm" class="space-y-6">
            <div class="space-y-3 border-b pb-8">
                <div class="flex flex-col space-y-1">
                    <label class="Gilroy-SemiBold text-gray-600">{{'Task Title' | translate}}</label>
                    <input type="text" formControlName="taskTitle" class="bg-transparent w-full outline-none border rounded-2xl px-4 py-3" placeholder="e.g. Work Meeting"/>
                    <span
                      class="text-red-500 text-sm"
                      *ngIf="createTaskFormErrors?.taskTitle"
                      >{{ createTaskFormErrors.taskTitle }}</span
                    >
                </div>
                <div class="flex flex-col space-y-1">
                    <label class="Gilroy-SemiBold text-gray-600">{{'Description' | translate}}</label>
                    <textarea rows="5" formControlName="description" class="bg-transparent w-full outline-none border rounded-2xl px-4 py-3"></textarea>
                    <span
                      class="text-red-500 text-sm"
                      *ngIf="createTaskFormErrors?.description"
                      >{{ createTaskFormErrors.description }}</span
                    >
                </div>
                <div class="flex flex-col space-y-2 bg-gray-100 rounded-xl p-4">
                    <div class="flex flex-col space-y-1">
                        <label class="Gilroy-SemiBold">{{'Task Type' | translate}}</label>
                        <ng-select class="custom" (ngModelChange)="changeTaskType($event)" [clearable]="false" [items]="taskTypes" formControlName="taskType" placeholder="Select task type"></ng-select>
                        <span
                          class="text-red-500 text-sm"
                          *ngIf="createTaskFormErrors?.taskType"
                          >{{ createTaskFormErrors.taskType }}</span
                        >
                    </div>
                    <div *ngIf="createTaskForm?.value?.taskType === 'Recurring'" class="flex flex-col space-y-1">
                        <label class="Gilroy-SemiBold">{{'Frequency' | translate}}</label>
                        <div class="flex space-x-4 items-center">
                            <div class="flex space-x-2 items-center">
                                <input type="radio" value="Daily" formControlName="frequency" name="frequency"/>
                                <h2 class="Gilroy-SemiBold">{{"Daily" | translate}}</h2>
                            </div>
                            <div class="flex space-x-2 items-center">
                                <input type="radio" value="Weekly" formControlName="frequency" name="frequency"/>
                                <h2 class="Gilroy-SemiBold">{{"Weekly" | translate}}</h2>
                            </div>
                            <div class="flex space-x-2 items-center">
                                <input type="radio" value="Monthly" formControlName="frequency" name="frequency"/>
                                <h2 class="Gilroy-SemiBold">{{"Monthly" | translate}}</h2>
                            </div>
                        </div>
                        <span
                            class="text-red-500 text-sm"
                            *ngIf="createTaskFormErrors?.frequency"
                            >{{ createTaskFormErrors.frequency }}</span
                        >
                    </div>
                </div>
                <div class="flex justify-between gap-8 task-dates">
                    <div class="flex flex-col w-full gap-2">
                        <label class="Gilroy-SemiBold text-gray-600">{{'Start Date' | translate}}</label>
                        <div class="flex space-x-2 items-center border rounded-2xl px-4 py-1">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                                stroke="currentColor" class="w-6 h-6">
                                <path stroke-linecap="round" stroke-linejoin="round"
                                d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5" />
                            </svg>                                              
                            <input type="date" formControlName="startDate" [min]="today" value="today" class="bg-transparent border-none w-full outline-none"/>
                            <!-- <input placeholder="DD/MM/YYYY"
                                class="bg-transparent border-none w-full outline-none" formControlName="startDate"
                                [owlDateTimeTrigger]="dt1" [owlDateTime]="dt1">
                            <owl-date-time [pickerMode]="'popup'" [min]="today" [pickerType]="'calendar'" #dt1></owl-date-time> -->
                        </div>
                        <span
                            class="text-red-500 text-sm"
                            *ngIf="createTaskFormErrors?.startDate"
                            >{{ createTaskFormErrors.startDate }}</span
                        >
                    </div>
                    <div class="flex flex-col w-full gap-2">
                        <label class="Gilroy-SemiBold text-gray-600">{{'Due Date' | translate}}</label>
                        <div class="flex space-x-2 items-center border rounded-2xl px-4 py-1">
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                            stroke="currentColor" class="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round"
                              d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5" />
                          </svg>
                          <input type="date" formControlName="dueDate"
                            class="bg-transparent border-none w-full outline-none" [min]="createTaskForm.get['startDate']?.value" placeholder="DD/MM/YYYY" />
                        </div>
                        <span class="text-red-500 text-sm"
                          *ngIf="createTaskFormErrors?.dueDate">{{ createTaskFormErrors.dueDate }}</span>
                    </div>
                </div>
                <div *ngIf="createTaskForm?.value?.taskType === 'Recurring'" class="flex flex-col space-y-1">
                    <div class="flex flex-col space-y-1">
                      <label class="Gilroy-SemiBold text-gray-600">{{'End Date' | translate}}</label>
                      <div class="flex space-x-2 items-center border rounded-2xl px-4 py-1">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                          stroke="currentColor" class="w-6 h-6">
                          <path stroke-linecap="round" stroke-linejoin="round"
                            d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5" />
                        </svg>
                        <input type="date" (change)="dateChange($event)" [min]="createTaskForm.get('dueDate').value" formControlName="endDate"
                          class="bg-transparent border-none w-full outline-none" placeholder="DD/MM/YYYY" />
                      </div>
                      <span class="text-red-500 text-sm"
                        *ngIf="createTaskFormErrors?.endDate">{{ createTaskFormErrors.endDate }}</span>
                    </div>
                    <!-- <div class="flex flex-col space-y-1">
                        <label class="Gilroy-SemiBold text-gray-600">{{'Duration' | translate}} <span class="Gilroy-Medium">{{'(Optional)'|translate}}</span></label>
                        <div class="flex space-x-2 items-center border rounded-2xl px-4 py-1">
                            <svg width="24px" height="24px" stroke-width="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" color="#000000"><path d="M9 2L15 2" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M12 10L12 14" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M12 22C16.4183 22 20 18.4183 20 14C20 9.58172 16.4183 6 12 6C7.58172 6 4 9.58172 4 14C4 18.4183 7.58172 22 12 22Z" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path></svg>                                               
                            <input type="number" formControlName="duration" class="bg-transparent border-none w-full outline-none focus:ring-white" placeholder="e.g. 5 hours"/>
                        </div>
                    </div>
                    <div class="flex flex-col space-y-1">
                        <label class="Gilroy-SemiBold text-gray-600">{{'Due Time' | translate}} <span class="Gilroy-Medium">{{'(Optional)'|translate}}</span></label>
                        <div class="flex space-x-2 items-center border rounded-2xl px-4 py-1">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>                                               
                            <input type="time" formControlName="dueTime" class="bg-transparent border-none w-full outline-none"/>
                        </div>
                    </div> -->
                </div>
                <div class="flex flex-col space-y-1 bg-gray-100 rounded-xl p-4">
                    <label class="Gilroy-SemiBold">{{'Assign Task' | translate}}</label>
                    <ng-select class="custom task-assignee-field" [clearable]="false" bindLabel="name" bindValue="_id" [items]="allBusinessUsers" formControlName="assignToUserId" placeholder="Assign task"></ng-select>
                    <span
                      class="text-red-500 text-sm"
                      *ngIf="createTaskFormErrors?.assignToUserId"
                      >{{ createTaskFormErrors.assignToUserId }}</span
                    >
                </div>
                <!-- <div class="flex flex-col space-y-1 bg-gray-100 rounded-xl p-4">
                    <label class="Gilroy-SemiBold">{{'Reminder' | translate}}</label>
                    <ng-select class="custom" [clearable]="false" [items]="['10 Minutes','1 Hour', '24 Hours']" formControlName="reminder" placeholder="Select Reminder"></ng-select>
                    <span
                        class="text-red-500 text-sm"
                        *ngIf="createTaskFormErrors?.reminder"
                        >{{ createTaskFormErrors.reminder }}</span
                    >
                </div> -->
            </div>
            <div>
                <label for="file" (click)="clearFileInput()">
                    <div  class="fileDropZone border-2 border-dashed rounded-2xl cursor-pointer h-[150px] flex flex-col items-center justify-center space-y-1"  ngFileDragDrop (uploadedFiles)="getUploadedFiles($event)" (fileDropped)="getUploadedFiles($event)">
                        <div class="flex space-x-2 items-center">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M18.375 12.739l-7.693 7.693a4.5 4.5 0 01-6.364-6.364l10.94-10.94A3 3 0 1119.5 7.372L8.552 18.32m.009-.01l-.01.01m5.699-9.941l-7.81 7.81a1.5 1.5 0 002.112 2.13" />
                            </svg>
                            <h1 class="Gilroy-Medium text-xl">{{"Upload Documents" | translate}}</h1>                           
                        </div>
                        <p class="Gilroy-Medium upload-text text-gray-500">{{"Click or drag file to this area to upload from local drive." | translate}}</p>
                        <input #fileInput id="file" class="hidden" (change)="onFileSelect($event)"  type="file"  multiple>
                    </div>
                </label>
                <div *ngFor="let file of files" class="space-y-1 pt-1">
                    <div class="flex justify-between bg-gray-100 py-2 px-6 rounded-2xl w-full">
                        <p class="Gilroy-Medium w-11/12 overflow-hidden">{{file.name ?? file.displayFileName}}</p>
                        <div class="w-1/12 flex justify-end">
                            <svg (click)="removeFile(file)" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 text-red-500 cursor-pointer">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                            </svg>                          
                        </div>
                    </div>
                </div>
                <!-- <ng-file-drag-drop (uploadedFiles)="getUploadedFiles($event)" [fileConfig]="dragDropConfig"> </ng-file-drag-drop> -->
            </div>
            <div *ngIf="isRecurringTaskUpdate && edit" class="flex items-start flex-col gap-2 justify-between mt-8 pt-4 border-t border-[#e6e6e6]">
                <label class="flex items-center gap-1"><input type="radio" value="single" formControlName="recurringUpdateType" name="recurringUpdateType"/><h2 class="Gilroy-SemiBold">Update single task</h2></label>
                <label class="flex items-center gap-1"><input type="radio" value="all" formControlName="recurringUpdateType" name="recurringUpdateType"/><h2 class="Gilroy-SemiBold">Update this task and upcoming recurring tasks</h2></label>
                <span
                    class="text-red-500 text-sm"
                    *ngIf="createTaskFormErrors?.recurringUpdateType"
                    >{{ createTaskFormErrors.recurringUpdateType }}</span
                >
            </div>
        </form>
    </div>
    <div footer>
        <button (click)="createTaskOrUpdate()" class="bg-gradient text-white Gilroy-Bold px-6 py-3 rounded-2xl">{{edit ? 'Update' : 'Next' | translate}}</button>
    </div>
</app-modal-container>

<app-modal-container [isOpen]="isDeleteModalOpen">
    <div header class="flex justify-between">
        <h1 class="Gilroy-Bold text-2xl">
            {{"Delete Task" | translate}}
        </h1>
        <svg (click)="closeDeleteModal()" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 cursor-pointer">
            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
        </svg>
    </div>
    <div content>
        <h4 class="text-lg">{{"Are you sure you want to delete this task" | translate}} <span class="Gilroy-SemiBold">{{taskToDelete?.taskTitle}}</span>?</h4>
    </div>
    <div footer>
        <button (click)="confirmDelete()" class="bg-gradient text-white Gilroy-Bold px-6 py-3 rounded-2xl">{{"Delete" | translate}}</button>
    </div>
</app-modal-container>

<app-modal-container [isOpen]="isRecurringTaskModal">
    <div header class="flex justify-between">
        <h1 class="Gilroy-Bold text-2xl">
            {{"Delete Recurring Task" | translate}}
        </h1>
        <svg (click)="closeDeleteModal()" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 cursor-pointer">
            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
        </svg>
    </div>
    <div content>
        <div class="flex items-start flex-col gap-2 justify-between">
            <label class="flex items-center gap-1"><input type="radio" value="single" [(ngModel)]="recurringDeleteType" name="recurringDeleteType"/><h2 class="Gilroy-SemiBold">{{"Delete single task" | translate}}</h2></label>
            <label class="flex items-center gap-1"><input type="radio" value="all" [(ngModel)]="recurringDeleteType" name="recurringDeleteType"/><h2 class="Gilroy-SemiBold">{{"Delete this task and upcoming recurring tasks" | translate}}</h2></label>
        </div>
    </div>
    <div footer>
        <button (click)="confirmRecurringDelete()" class="bg-gradient text-white Gilroy-Bold px-6 py-3 rounded-2xl">{{"Delete" | translate}}</button>
    </div>
</app-modal-container>

<app-modal-container [isOpen]="emailSettingsModal">
    <div header class="flex justify-between">
        <h1 class="Gilroy-Bold text-2xl">
            {{"Task Email Settings" | translate}}
        </h1>
        <svg (click)="emailSettingsModal = false; openCloseEmailSettingsModal()" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 cursor-pointer">
            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
        </svg>
    </div>
    <div content>
        <ng-container *ngIf="isEmailSettings; else emailSettingsOff">
            <div class="flex items-start flex-col gap-2 justify-between">
                <label class="flex items-center gap-1"><input type="radio" value="daily" [(ngModel)]="mailFrequency" name="mailFrequency"/><h2 class="Gilroy-SemiBold">{{"Receive daily updates of Task on email" | translate}}</h2></label>
                <label class="flex items-center gap-1"><input type="radio" value="weekly" [(ngModel)]="mailFrequency" name="mailFrequency"/><h2 class="Gilroy-SemiBold">{{"Receive weekly updates of Task on email" | translate}}</h2></label>
            </div>
        </ng-container>
        <ng-template #emailSettingsOff>
            {{"Are you sure you want to turn off email digest?" | translate}}
        </ng-template>
    </div>
    <div footer>
        <button (click)="confirmEmailSettings()" class="bg-gradient text-white Gilroy-Bold px-6 py-3 rounded-2xl">{{"Confirm" | translate}}</button>
    </div>
</app-modal-container>