import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { select, Store } from '@ngrx/store';
import { RootReducerState } from 'src/app/store/reducers';
import { NumplusService } from '../../numplus.service';
import { Observable, Subject } from 'rxjs';
import { selectBusiness } from 'src/app/store/selectors/business.selector';
import { selectAccounts } from 'src/app/store/selectors/account.selector';
import { takeUntil } from 'rxjs/operators';
import { loadAccountsOf } from 'src/app/shared/utils/accountType';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { NumberService } from 'src/app/shared/services/number.service';

@Component({
  selector: 'app-financedashboard',
  templateUrl: './financedashboard.component.html',
  styleUrls: ['./financedashboard.component.scss']
})
export class FinancedashboardComponent implements OnInit {

  public years: Array<any>= [
    {
      name:moment().format('YYYY'),
      value: moment().format('YYYY')
    },
    {
      name:(Number(moment().format('YYYY')) + 1).toString(),
      value:(Number(moment().format('YYYY')) +1).toString()
    },
    {
      name:(Number(moment().format('YYYY')) + 2).toString(),
      value:(Number(moment().format('YYYY')) +2).toString()
    }
  ]

  public monthsName:Array<any> = [
    {
      name:'January',
      value:'January'
    },
    {
      name:'February',
      value:'February'
    },
    {
      name:'March',
      value:'March'
    },
    {
      name:'April',
      value:'April'
    },
    {
      name:'May',
      value:'May'
    },
    {
      name:'June',
      value:'June'
    },
    {
      name:'July',
      value:'July'
    },
    {
      name:'August',
      value:'August'
    },
    {
      name:'September',
      value:'September'
    }, {
      name:'October',
      value:'October'
    }, {
      name:'November',
      value:'November'
    }, {
      name:'December',
      value:'December'
    }]

  public typeOfData: Array<any> =[
    {
      name:"Month",
      value:"month"
    },
    {
      name:"Year" + " " + "(" + moment().format('YYYY') + ")",
      value:"monthright"
    },
    {
      name:"Detailed Year",
      value:"year"
    }

  ]

  public preference: Array<any>= [
    {
      name:"Horizontal",
      value:"year"
    },
    {
      name:"Vertical",
      value:"month"
    },
  ]

  public monthsNameData = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August',
  'September', 'October', 'November', 'December']


  public tableColLeftIncome: Array<string> = ["Income","Actual", "Actual", "Actual", "Budget", "Historic", "YoY% Growth", "Budget Variance"];
  public tableColLeftExpenses: Array<string> = ["Expenses","Actual", "Actual", "Actual", "Budget", "Historic", "YoY% Growth", "Budget Variance"];
  public tableColRightIncome: Array<string> = ["Income","Actual", "Budget", "Historic", "YoY% Growth", "Budget Variance"]
  public tableColRightExpense: Array<string> = ["Expenses","Actual", "Budget", "Historic", "YoY% Growth", "Budget Variance"]

  public tableColYearIncome: Array<string> = ["Actual", "Budget", "BudgetVariance"]


  accounts$:Observable<any>;
  business$: Observable<any>;
  businessId = null;
  unsubscribe$ = new Subject();
  accountTypesBySection;
  accountData;
  incomeExpenseData;
  fiscalMonth;
  public selectedMonth
  public selectedYear
  public selectedType= 'month'
  public preferenceType="year"
  monthData
  yearData
  createdDate
  monthsData:boolean = true
  profitMonth;
  targetMonth;
  totalIncomeMonth
  growthMonth
  profitYear;
  salesYear;
  targetYear;
  growthYear;
  preferenceData;
  monthrightData:boolean = false
  yearlyData:boolean = false

  constructor(
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private store: Store<RootReducerState>,
    private rootStore:Store<RootReducerState>,
    private numplusService: NumplusService,
    private toastr: ToastrService,
    private translateService: TranslateService,
    private numberService: NumberService) {
      this.business$ = this.rootStore.pipe(select(selectBusiness));
      this.accounts$ = this.store.pipe(select(selectAccounts));
     }


  ngOnInit(): void {
    this.business$.pipe(takeUntil(this.unsubscribe$)).subscribe((business) =>{
      console.log("Business Details", business?.businessId)
      if(business?.businessId?._id){
        this.businessId = business.businessId._id
      }
      if(business?.businessId?.accounting?.month){
        this.fiscalMonth = business?.businessId?.accounting?.month
      }
    })
    this.selectedYear = moment().format('YYYY');
    this.selectedMonth = moment().format('MMMM');
    this.business$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((business) => {
      if (business?.businessId?._id) {
        this.dataChange();
        this.preferenceChange()
      }
    });
  }

  // ngOnChanges(){
  //   this.typeOfData[1]['name'] = "Year" + " " + "(" + this.selectedYear + ")"
  // }

  dataChange(): void {

    // this.typeOfData[1]['name'] = "Year" + " " + "(" + this.selectedYear + ")"
    // console.log(this.typeOfData[1]['name'])
    if(this.selectedType === 'month'){
      this.monthsData = true
      this.yearlyData = false
      this.monthrightData = false
    } else if(this.selectedType ==='year'){
      this.monthsData = false
      this.yearlyData = true
      this.monthrightData = false
    } else if(this.selectedType === 'monthright'){
      this.monthsData =false
      this.yearlyData = false
      this.monthrightData = true
    }
    this.tableColLeftIncome[1] = this.tableColLeftIncome[2] = this.tableColLeftIncome[3] ="Actual"
    this.tableColLeftExpenses[1] = this.tableColLeftExpenses[2] = this.tableColLeftExpenses[3] ="Actual"
    this.createdDate = "01" + "-" + this.selectedMonth + "-" + this.selectedYear;

    const filterDataForMonth = {
      "financialMonth": this.fiscalMonth,
      "financialYear": this.selectedYear,
      "monthArray":[
        {
          "month": moment(this.createdDate).subtract(2, 'months').format('MMMM'),
          "year":moment(this.createdDate).subtract(2, 'months').format('YYYY')
        },
        {
          "month": moment(this.createdDate).subtract(1, 'months').format('MMMM'),
          "year":moment(this.createdDate).subtract(1, 'months').format('YYYY')
        },
        {
          "month":this.selectedMonth,
          "year":this.selectedYear
        }
      ]
    }

    const filterForYear = {
      "financialMonth": this.fiscalMonth,
      "year": this.selectedYear,
      // "month": this.selectedMonth
    }
    console.log("Finance Month Payload",filterDataForMonth)
    console.log("Finance Year Payload",filterForYear)
    if(this.selectedType === 'month' || this.selectedType === 'monthright'){
      this.financialMonthApi(filterDataForMonth);
    }
    else{
      this.financialYearApi(filterForYear)
    }
  }

  preferenceChange(){
    if(this.preferenceType === 'year'){
      this.preferenceData = true
    } else if( this.preferenceType === 'month'){
      this.preferenceData = false
    }
  }

  financialMonthApi(filterData){

    this.spinner.show()
    this.numplusService.getFinanceMonth(filterData).subscribe(resp => {

        this.monthData = resp.data;
        console.table("MonthData",this.monthData)
        let {month0, month1, month2} = this.monthData?.incomeFinalArray[0]
        this.tableColLeftIncome[1] = month0 + " " + moment(this.createdDate).subtract(2, 'months').format('YYYY') + " " + this.tableColLeftIncome[1]
        this.tableColLeftIncome[2] = month1 + " " + moment(this.createdDate).subtract(1, 'months').format('YYYY') + " " + this.tableColLeftIncome[2]
        this.tableColLeftIncome[3] = month2 + " " + this.selectedYear + " "  + this.tableColLeftIncome[3]
        console.log("Table Left Col", this.tableColLeftIncome)

        this.tableColLeftExpenses[1] = month0 + " " + moment(this.createdDate).subtract(2, 'months').format('YYYY') + " " + this.tableColLeftExpenses[1]
        this.tableColLeftExpenses[2] = month1 + " " + moment(this.createdDate).subtract(1, 'months').format('YYYY') + " " + this.tableColLeftExpenses[2]
        this.tableColLeftExpenses[3] = month2 + " " + this.selectedYear + " "  + this.tableColLeftExpenses[3]
        console.log("Table Left Col", this.tableColLeftExpenses)
        let {income1,income2, budget } = this.monthData?.incomeFinalArray.find(item => item.account === 'Total')
        let {expense1, expense2} = this.monthData?.expenseFinalArray.find(item => item.account === 'Total')
        let actualProfit = this.profitMonth = this.numberService.toFixed(Number(income2)-Number(expense2))
        let preProfit = this.numberService.toFixed(Number(income1)-Number(expense1))
        this.targetMonth = this.numberService.toFixed(Number(budget))
        this.totalIncomeMonth = this.numberService.toFixed(Number(income2))
        console.log("Calculate Growth in month", actualProfit, preProfit)
        this.growthMonth = this.numberService.toFixed(this.calculateGrowth(actualProfit, preProfit))

        this.spinner.hide();
    }, (error) => {
      this.spinner.hide();
      this.toastr.error(this.translateService.instant('Something went wrong!'));
    });
  }

  financialYearApi(filterData){
    let totalIncomeYear = 0
    let totalbudgetYear = 0
    let totalPreviousYearIncome = 0
    let totalExpenseYear = 0
    let totalPreExpenseYear =0
    this.spinner.show()
    this.numplusService.getFinanceYear(filterData).subscribe(resp => {
        this.yearData= resp.data;
        console.table("yearData",this.yearData)
        let yearIncomeObj = this.yearData?.incomeFinalArray.find(item => item.account === 'Total')
        let yearExpenseObj = this.yearData?.expenseFinalArray.find(item => item.account === 'Total')
        console.table("Year Income total object", yearIncomeObj)
        console.table("Year Expenses total object", yearExpenseObj)
        for(let i=0; i<12; i++){
          totalIncomeYear += Number(yearIncomeObj['income' + i])
          totalbudgetYear += Number(yearIncomeObj['budget' + i])
          totalPreviousYearIncome += Number(yearIncomeObj['previousIncome' + i])
          totalExpenseYear += Number(yearExpenseObj['expense' + i])
          totalPreExpenseYear += Number(yearExpenseObj['previousExpense' + i])
        }
        console.log("total Year Income", totalIncomeYear)
        let actualProfitYear= this.profitYear = this.numberService.toFixed(totalIncomeYear - totalExpenseYear)
        let preActualProfitYear = this.numberService.toFixed(totalPreviousYearIncome-totalPreExpenseYear)
        console.log("Previous year expense", totalPreviousYearIncome, totalPreExpenseYear)
        console.log("Calculate Growth year", actualProfitYear, preActualProfitYear)
        this.growthYear = this.numberService.toFixed(this.calculateGrowth(actualProfitYear, preActualProfitYear))
        this.targetYear = this.numberService.toFixed(totalbudgetYear)
        this.salesYear = this.numberService.toFixed(totalIncomeYear)
        this.spinner.hide();
    }, (error) => {
      this.spinner.hide();
      this.toastr.error(this.translateService.instant('Something went wrong!'));
    });
  }

  counter(i:number){
    return new Array(i);
  }

  calculateGrowth(actualProfit: number, preProfit: number): number{
    console.log("Calculate Growth ", actualProfit, preProfit)

    if(actualProfit === 0 && preProfit === 0){
      return 0
    } else  if(actualProfit !== 0 && preProfit === 0){
      let preProfit2 = 1
      let growth = ((actualProfit/preProfit2)-1)*100
      return growth
    } else {
      let growth = ((actualProfit/preProfit)-1)*100
      return growth
    }
  }

  excelReport(){
  let body={
      "financialYear":this.selectedYear,
      "type":'excel',
      "monthArray":[
        {
          "month": moment(this.createdDate).subtract(2, 'months').format('MMMM'),
          "year":moment(this.createdDate).subtract(2, 'months').format('YYYY')
        },
        {
          "month": moment(this.createdDate).subtract(1, 'months').format('MMMM'),
          "year":moment(this.createdDate).subtract(1, 'months').format('YYYY')
        },
        {
          "month":this.selectedMonth,
          "year":this.selectedYear
        }
      ]
    }
    this.spinner.show();
    this.numplusService.reportOfBudgetMonth(body).subscribe((resp) => {
      const a = document.createElement('a');
      const newResp = resp.data.fileBuffer.data
      const blob = new Blob([new Uint8Array(newResp)], {type: 'application/vnd.openxmlformats-ficedocument.spreadsheetml.sheet'});
      const url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = `Budget ${this.selectedMonth} ${this.fiscalMonth}.xlsx`;
      a.click();
      window.URL.revokeObjectURL(url);
      this.toastr.success(this.translateService.instant('Excel downloaded'));
      this.spinner.hide();
    }, (error) => {
      this.spinner.hide();
      this.toastr.error(this.translateService.instant('Something went wrong!'));
    });
  }

  pdfReport(){
    let body={
      "financialYear":this.selectedYear,
      "type":'pdf',
      "monthArray":[
        {
          "month": moment(this.createdDate).subtract(2, 'months').format('MMMM'),
          "year":moment(this.createdDate).subtract(2, 'months').format('YYYY')
        },
        {
          "month": moment(this.createdDate).subtract(1, 'months').format('MMMM'),
          "year":moment(this.createdDate).subtract(1, 'months').format('YYYY')
        },
        {
          "month":this.selectedMonth,
          "year":this.selectedYear
        }
      ]
    }
    this.spinner.show();
    this.numplusService.reportOfBudgetMonth(body).subscribe((resp) => {
      const a = document.createElement('a');
      console.log("Pdf rewsponse", resp)
      const newResp = resp.data.fileBuffer.data
      const blob = new Blob([new Uint8Array(newResp)], {type: 'application/pdf'});
      const url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = `Budget ${this.selectedMonth}${this.fiscalMonth}.pdf`;
      a.click();
      window.URL.revokeObjectURL(url);
      this.toastr.success(this.translateService.instant('PDF downloaded'));
      this.spinner.hide();
    }, error => {
      console.log(error);
      this.spinner.hide();
      this.toastr.error(error?.error?.message || 'Something went wrong');
    })
  }


  excelReportYear(){
    let body ={
      "financialMonth": this.fiscalMonth,
      "year": this.selectedYear,
      "type": 'excel'
    }
    this.spinner.show();
    this.numplusService.excelReportOfBudget(body).subscribe((resp) => {
      const a = document.createElement('a');
      const newResp = resp.data.fileBuffer.data
      const blob = new Blob([new Uint8Array(newResp)], {type: 'application/vnd.openxmlformats-ficedocument.spreadsheetml.sheet'});
      const url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = `Budget ${this.selectedYear} ${this.fiscalMonth}.xlsx`;
      a.click();
      window.URL.revokeObjectURL(url);
      this.toastr.success(this.translateService.instant('Excel downloaded'));
      this.spinner.hide();
    }, (error) => {
      this.spinner.hide();
      this.toastr.error(this.translateService.instant('Something went wrong!'));
    });
  }

  pdfReportYear(){
    let body ={
      "financialMonth": this.fiscalMonth,
      "year": this.selectedYear,
      "type": 'pdf'
    }
    this.spinner.show();
    this.numplusService.excelReportOfBudget(body).subscribe((resp) => {
      const a = document.createElement('a');
      console.log("Pdf rewsponse", resp)
      const newResp = resp.data.fileBuffer.data
      const blob = new Blob([new Uint8Array(newResp)], {type: 'application/pdf'});
      const url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = `Budget ${this.selectedYear}${this.fiscalMonth}.pdf`;
      a.click();
      window.URL.revokeObjectURL(url);
      this.toastr.success(this.translateService.instant('PDF downloaded'));
      this.spinner.hide();
    }, error => {
      console.log(error);
      this.spinner.hide();
      this.toastr.error(error?.error?.message || 'Something went wrong');
    })
  }

  pdfReportYearMonthly(){
    let body ={
      "financialMonth": this.fiscalMonth,
      "year": this.selectedYear
    }
    this.spinner.show();
    this.numplusService.monthlyPdfForBudget(body).subscribe((resp) => {
      const a = document.createElement('a');
      console.log("Pdf rewsponse", resp)
      const newResp = resp.data.fileBuffer.data
      const blob = new Blob([new Uint8Array(newResp)], {type: 'application/pdf'});
      const url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = `Budget ${this.selectedYear} ${this.fiscalMonth} Month Wise.pdf`;
      a.click();
      window.URL.revokeObjectURL(url);
      this.toastr.success(this.translateService.instant('PDF downloaded'));
      this.spinner.hide();
    }, error => {
      console.log(error);
      this.spinner.hide();
      this.toastr.error(error?.error?.message || 'Something went wrong');
    })

  }

  pdfReportMonthRight(){
    let body ={
      "financialYear": moment().format('YYYY'),
      "financialMonth": this.fiscalMonth,
      "lastYear": this.findYear(this.monthsNameData, this.fiscalMonth, this.selectedMonth),
      "lastMonth": this.selectedMonth,
      "type": 'pdf',
    }
    this.spinner.show();
    this.numplusService.reportMonthRight(body).subscribe((resp) => {
      const a = document.createElement('a');
      const newResp = resp.data.fileBuffer.data
      const blob = new Blob([new Uint8Array(newResp)], {type: 'application/pdf'});
      const url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = `Yearly Budget${this.fiscalMonth}.pdf`;
      a.click();
      window.URL.revokeObjectURL(url);
      this.toastr.success(this.translateService.instant('PDF downloaded'));
      this.spinner.hide();
    }, error => {
      console.log(error);
      this.spinner.hide();
      this.toastr.error(error?.error?.message || 'Something went wrong');
    })
  }

  excelReportMonthRight(){
    let body ={
      "financialYear": moment().format('YYYY'),
      "financialMonth": this.fiscalMonth,
      "lastYear": this.findYear(this.monthsNameData, this.fiscalMonth, this.selectedMonth),
      "lastMonth": this.selectedMonth,
      "type": 'excel',
    }
    this.spinner.show();
    this.numplusService.reportMonthRight(body).subscribe((resp) => {
      const a = document.createElement('a');
      const newResp = resp.data.fileBuffer.data
      const blob = new Blob([new Uint8Array(newResp)], {type: 'application/vnd.openxmlformats-ficedocument.spreadsheetml.sheet'});
      const url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = `Yearly Budget${this.fiscalMonth}.xlsx`;
      a.click();
      window.URL.revokeObjectURL(url);
      this.toastr.success(this.translateService.instant('Excel downloaded'));
      this.spinner.hide();
    }, error => {
      console.log(error);
      this.spinner.hide();
      this.toastr.error(error?.error?.message || 'Something went wrong');
    })
  }

  findYear(monthsData:any, fiscalMonth:string, selectedMonth:string){
    const index1 = monthsData.findIndex((month) => month === fiscalMonth)
    const index2= monthsData.findIndex((month) =>month === selectedMonth)
    if(index1 > index2){
     return (Number(moment().format('YYYY')) + 1).toString()
    } else if(index1 < index2){
      return moment().format('YYYY')
    }
  }

}
