import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router, Routes } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Route } from 'aws-sdk/clients/eventbridge';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BusinessService } from 'src/app/shared/services/business.service';
import { RootReducerState } from 'src/app/store/reducers';
import { selectBusiness } from 'src/app/store/selectors/business.selector';
import { selectUserBusinesses } from 'src/app/store/selectors/user.selectors';

@Component({
  selector: 'app-pos-settings',
  templateUrl: './pos-settings.component.html',
  styleUrls: ['./pos-settings.component.scss']
})
export class PosSettingsComponent implements OnInit {
  business$: Observable<any>;
  businesses$: Observable<any>;
  constructor(
    private store: Store<RootReducerState>,
    private router : Router,
    private businessService : BusinessService,
    private spinner : NgxSpinnerService
  ) { 
    this.businesses$ = this.store.pipe(select(selectUserBusinesses));
    this.business$ = store.pipe(select(selectBusiness));
  }
  deliveryEmailAddress = new FormControl(null)
  sendSms = new FormControl(true)
  isLogo = new FormControl(true)
  footerNote = new FormControl(null)
  unsubscribe$ = new Subject();
  businessId:any;
  response:any;
  error:any;
  ngOnInit(): void {
    this.loadBusinessDetails()
  }
  loadBusinessDetails(): void {
    this.business$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(business => {
      this.businessId = business?.businessId?._id;
      this.updateForm(business)
    })
  }
  updateForm(business): void {
    this.deliveryEmailAddress.patchValue(business.businessId?.deliveryEmailAddress || '')
    this.isLogo.patchValue(business.businessId?.isLogo)
    this.footerNote.patchValue(business.businessId?.footerNote || '')
    this.sendSms.patchValue(business.businessId?.sendSms)
  }
 
  updateBusiness(): void {
    this.spinner.show();
    const body = {
      businessId: this.businessId,
      deliveryEmailAddress : this.deliveryEmailAddress.value || '',
      footerNote : this.footerNote.value || '',
      isLogo : this.isLogo.value,
      sendSms : this.sendSms.value,
    }
    this.businessService.updateBusiness(body).subscribe((updatedBusiness) => {
      this.spinner.hide();
      this.businessService.updateBusinessInStore(updatedBusiness.data);
      setTimeout(() => { this.response = ''; this.router.navigate(['/settings/sales/invoice-customization']); }, 2000);
    }, (error) => {
      this.error = error?.error?.message || 'Internal Server Error';
      this.spinner.hide();
    });
  }

  cancelUpdate() {
    this.router.navigate(['/settings/sales/invoice-customization'])
  }
}
