<app-modal-container [isOpen]='isOpen'>
    <div header class="Gilroy-Bold">{{imageCropperConfig.config.title}}</div>
    <div content class="flex flex-col">
        <div class="border flex justify-center mb-2">
            <ng-container *ngIf='!previewImage'>
                <image-cropper
                  [imageChangedEvent]="imageCropperConfig.event"
                  [maintainAspectRatio]="imageCropperConfig.config.maintainAspectRatio"
                  [aspectRatio]="imageCropperConfig.config.aspectRatio"
                  [roundCropper]="imageCropperConfig.config.roundCropper"
                  format="png"
                  (imageCropped)="imageCropped($event)"
                  (imageLoaded)="imageLoaded()"
                  ></image-cropper>
              </ng-container>
              <ng-container *ngIf='previewImage'>
                <div [ngClass]='imageCropperConfig.config.roundCropper ? "rounded-full":""' class='border'>
                  <img [src]="croppedImage" [ngClass]='imageCropperConfig.config.roundCropper ? "rounded-full":""' width='300'>
                </div>
              </ng-container>
        </div>
        <div class="text-gray-400">{{'Choose an image with 16:9 ratio for best results.' | translate }}</div>
    </div>
    <div footer class="space-x-2 rtl:space-x-reverse">
        <ng-container *ngIf='!previewImage'>
            <app-button buttonText='{{"Preview" | translate }}' rounded='true' type='primary' (handleClick)='previewImage = true'></app-button>
            <app-button buttonText='{{"Cancel" | translate }}' rounded='true' type='danger' (handleClick)='close()'></app-button>
        </ng-container>
        <ng-container *ngIf='previewImage'>
            <app-button buttonText='{{"Save Changes" | translate }}' rounded='true' type='primary' (handleClick)='saveImage()'></app-button>
            <app-button buttonText='{{"Back" | translate }}' rounded='true' type='danger' (handleClick)='previewImage = false'></app-button>
        </ng-container>
    </div>
</app-modal-container>