<div class="m-auto flex flex-col space-y-4 p-4">
    <div class="flex items-center justify-between">
      <h1 class="text-2xl Gilroy-Bold inline-flex items-center">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="h-6 w-6 me-2">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
            d="M16 8v8m-4-5v5m-4-2v2m-2 4h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z">
          </path>
        </svg>
        {{'Charts of Accounts' | translate}}
      </h1>
      <app-button appAccessControl moduleType='accounting' accessType='edit' type='primary' buttonText="{{'Add new account' | translate}}" size='large' (handleClick)='addNewAcount()'></app-button>
    </div>
  
    <app-accounts-header></app-accounts-header>
    
    <div class="w-full">
      <div *ngFor='let accountType of accountTypesBySection'>
        <div class="rounded-full bg-input p-3 mx-5">
          <p class="Gilroy-Bold text-lg ps-3">{{accountType.accountType | translate}}</p>
        </div>
        <div class="ps-5 pe-5 pb-5 mx-5 pt-0">
          <div class="rounded-xl bg-white pb-5">
            <div *ngFor='let account of accountType.accounts' class="flex items-center border-b-2 py-2">
              <div class="w-1/6 break-words">
                <p>{{account.accountId | translate }}</p>
              </div>
              <div class="w-2/6 break-words">
                <h3 class="Gilroy-Bold text-lg">{{account.accountName | translate}}</h3>
              </div>
              <div class="w-2/6 break-words">
                <div class="flex">
                  <p class="text-black Gilroy-Medium">{{account.description | translate}}</p>
                </div>
              </div>
              <div class="w-1/6 inline-flex items-center justify-end">
                <span class="bg-gray-500 text-sm text-white rounded p-1 me-2" *ngIf='account.isArchive'>{{'Archived' | translate}}</span>
                <app-tooltip appAccessControl moduleType='accounting' accessType='edit' customClass='mb-8' class="" tooltipName='{{"Edit" | translate}}'>
                  <div (click)="editAccount(account)" class="h-8 w-8 rounded-full flex justify-center items-center bg-gray-50 border border-gray-200 cursor-pointer">
                    <svg  xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
                    </svg>
                  </div>
                </app-tooltip>
              </div>
            </div>
          </div>
          <div class="">
            <button appAccessControl moduleType='accounting' accessType='edit' (click)="addNewAcount(accountType)" class="flex items-center Gilroy-Bold space-x-4">
              <span class="rounded-full bg-gradient text-white p-1 me-2">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                  stroke="currentColor" class="h-5 w-5">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                    d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path>
                </svg>
              </span>
              {{'Add a new account' | translate}}
            </button>
          </div>
        </div>
     
      </div>
    </div>
</div>
  
<app-account-form [data]='data' [update]="update" [accountData]='accountEditData' [isOpen]='openAccountForm' (accountFormValue)='handleAccountFormValue($event)' (closeAccountForm)="openAccountForm=false"></app-account-form>