<div permissionCheck moduleType="budget" class="space-y-4 p-4">
  <div class="flex items-center justify-between">
    <h1 class="text-2xl Gilroy-Bold inline-flex items-center">
      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 me-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z" />
      </svg>
      {{'P/L Budget' | translate}}
    </h1>
    <div class="">
      <button appAccessControl moduleType="budget" accessType="edit" routerLink="/plus/budget/create-budget" class="bg-gradient text-white py-2 px-6 Gilroy-Bold rounded-lg">{{'Create Budget'| translate}}</button>
    </div>
  </div>
  <app-table-container
    [tableHeadings]='tableHeadings'
    [tableData]='tableData'
    [keys]='tableKeys'
    [actionTemplate]='actionTemplate'>
    <ng-template let-item #actionTemplate>
      <td align="right" class="p-2 last:pe-10 text-center">
        <app-dropdown #dropdown>
          <div button (click)='dropdown.open = true' (clickOutside)='dropdown.open = false'>
            <div class="h-8 w-8 rounded-full flex justify-center items-center bg-gray-50 border border-gray-200">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 m-auto leading-8 text-center" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
              </svg>
            </div>
          </div>
          <div options>
            <span class="cursor-pointer block px-4 py-2 text-sm Gilroy-Bold hover:bg-gray-50 text-center" (click)="viewBudget(item._id)">{{'View' | translate}}</span>
            <span appAccessControl moduleType="budget" accessType="edit" class="cursor-pointer block px-4 py-2 text-sm Gilroy-Bold hover:bg-gray-50 text-center" (click)="editBudget(item._id)">{{'Edit' | translate}}</span>
            <span appAccessControl moduleType="budget" accessType="edit" class="cursor-pointer block px-4 py-2 text-sm Gilroy-Bold hover:bg-gray-50 text-center" (click)="deleteBudget(item._id)">{{'Delete' | translate}}</span>
          </div>
        </app-dropdown>
      </td>
    </ng-template>
  </app-table-container>
</div>

<app-modal-container [isOpen]='deleteOpenModal'>
  <div header class="text-start border-b pb-2 Gilroy-Bold">{{'Delete Budget' | translate}}</div>
  <div class="text-start" content>{{'Are you sure?' | translate}}</div>
  <div footer class="flex items-center ms-auto space-x-2 rtl:space-x-reverse">
      <app-button buttonText='{{"Delete" | translate}}' type='danger' rounded=true (handleClick)='deleteBudgetlist()' ></app-button>
      <app-button buttonText='{{"Cancel" | translate}}' type='third' rounded=true (handleClick)='deleteOpenModal = false'></app-button>
  </div>
</app-modal-container>

