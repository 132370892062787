<div class="w-full flex flex-col space-y-4">
    <div class="flex items-center ps-5 pt-4 pe-7 justify-between">
        <div class="">
            <h1 class="text-2xl Gilroy-Bold inline-flex items-center">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="h-6 w-6 me-2">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="M16 8v8m-4-5v5m-4-2v2m-2 4h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z">
                    </path>
                </svg>
                {{'Transactions' | translate}}
            </h1>
        </div>
        <div appSubscriptionCheck featureToCheck='transactionLimit' appAccessControl moduleType='accounting' accessType='edit' class="">
            <app-dropdown #dropdown>
                <app-button button type='primary' buttonText='{{"Add New Transaction" | translate}}' size='large' (click)='dropdown.open=true' (clickOutside)='dropdown.open=false'></app-button>
                <div options>
                    <span (click)="addTransaction('income')" class="cursor-pointer block px-4 py-2 text-sm Gilroy-Bold hover:bg-gray-50 text-center rounded-t-xl">{{'Add Credit Note' | translate}}</span>
                    <span (click)="addTransaction('expense')" class="cursor-pointer block px-4 py-2 text-sm Gilroy-Bold hover:bg-gray-50 text-center rounded-b-xl">{{'Add Debit Note' | translate}}</span>
                    <span routerLink="/accounting/journal-entry" class="cursor-pointer block px-4 py-2 text-sm Gilroy-Bold hover:bg-gray-50 text-center rounded-b-xl">{{'Add Journal Voucher' | translate}}</span>
                </div>
            </app-dropdown>    
        </div>
    </div>

    <div class="flex justify-between me-6 ms-5 mt-0 mb-0">
        <!-- <div class="">
            <ng-select [items]="transaction" class="w-96" bindLabel="name"
            placeholder="Select one" bindValue="name">
          </ng-select>
        </div> -->
        <div class="">
            <ul class="flex">
                <li class=" me-4">
                    <app-tooltip customClass='mb-10' class="me-2" tooltipName='{{"Filter" | translate}}'>
                        <img (click)="openFilter()" src="/assets/images/Group 770.svg" class="w-10 cursor-pointer" />
                    </app-tooltip>
                </li>
                <li class=" me-4">
                    <app-tooltip customClass='mb-10' class="me-2" tooltipName='{{"Sort by Serial ID" | translate}}'>
                        <img (click)='revereseList()' src="/assets/images/Group 769.svg" class="w-10 cursor-pointer" />
                    </app-tooltip>
                </li>
            </ul>
        </div>
    </div>

    <div class="ps-5 pe-7 space-y-0 mt-0-one">
        <app-table-container [tableHeadings]='tableHeadings' [tableData]='tableData' [keys]='tableKeys' [actionTemplate]='actionTemplate'>
            <ng-template let-item #actionTemplate>
                <td appAccessControl moduleType='accounting' accessType='edit' align="right" class="p-2 last:pe-6 flex items-center justify-start">
                    <app-tooltip appAccessControl moduleType="markAsReviewed" accessType="edit" customClass='mb-8' class="me-2" tooltipName='{{"Mark as reviewed" | translate}}' *ngIf='!item?.isReviewed'>
                        <svg xmlns="http://www.w3.org/2000/svg" (click)='reviewTransaction(item)' class="h-8 w-8 text-gray-500 cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                    </app-tooltip>
                    <app-tooltip appAccessControl moduleType="markAsReviewed" accessType="edit" customClass='mb-8' class="me-2" tooltipName='{{"Reviewed"| translate}}' *ngIf='item?.isReviewed'>
                        <svg xmlns="http://www.w3.org/2000/svg" (click)='reviewTransaction(item)' class="h-8 w-8 text-green-500 cursor-pointer" viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
                        </svg>
                    </app-tooltip>
                    <app-tooltip customClass='mb-8' class="me-2" tooltipName='{{"Edit" | translate}}'>
                        <div (click)="editTransaction(item)" class="h-8 w-8 rounded-full flex justify-center items-center bg-gray-50 border border-gray-200 cursor-pointer">
                            <svg  xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
                            </svg>
                        </div>
                    </app-tooltip>
                    <app-tooltip customClass='mb-8' tooltipName='{{"Download" | translate}}'>
                        <svg (click)='downloadTransactionPdf(item)' xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 10v6m0 0l-3-3m3 3l3-3m2 8H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                        </svg>
                      </app-tooltip>
                </td>
            </ng-template>
        </app-table-container>
    </div>
</div>

<app-modal-container [isOpen]='openFilterModal'>
    <div header class="border-b pb-2 Gilroy-ExtraBold text-xl">{{'Filter' | translate}}</div>
    <div content>
        <app-filter-form [form]='filterForm' [formErrors]='filterFormErrors'></app-filter-form>
    </div>
    <div footer class="flex items-center space-x-2 rtl:space-x-reverse">
        <app-button buttonText='{{"Save" | translate}}' type='primary' rounded=true (handleClick)="submitFilterForm()"></app-button>
        <app-button buttonText='{{"Cancel" | translate}}' type='third' rounded=true (handleClick)="closeFilterForm()"></app-button>
    </div>
</app-modal-container>

<app-modal-container [isOpen]='addTransactionModal.isOpen'>
    <div header class="border-b pb-2 Gilroy-ExtraBold text-xl">{{addTransactionModal.heading | translate}}</div>
    <div content>
        <my-tabs [formGroup]='form'>
            <my-tab tabTitle="{{'Details' | translate}}">
                <app-add-income-form [invalidAmount]='invalidAmount' [formErrors]='formErrors' [formEditable]='addTransactionModal.formEditable' [form]='form'></app-add-income-form>
            </my-tab>
            <my-tab tabTitle="{{'Notes' | translate}}">
                <div class="flex mb-3">
                    <div class="bg-proile rounded-xl bg-white px-3 py-2 w-full">
                        <textarea formControlName='notes' name="notes" class="w-full p-0 h-20 border-none bg-proile focus:ring-0" placeholder="{{'Add some notes' | translate}}" ></textarea>
                    </div>
                </div>
            </my-tab>
            <!-- <my-tab appAccessControl moduleType="storage" accessType="view" tabTitle="{{'Files' | translate}}">
                <app-file-upload #fileUploadComponent [addedFiles]="addedFiles" (emitter)="saveFiles($event)" ></app-file-upload>
            </my-tab> -->
        </my-tabs>
    </div>
    <div footer class="flex items-center space-x-2 rtl:space-x-reverse">
        <app-button buttonText='{{"Save" | translate}}' type='primary' rounded=true (handleClick)='saveTransaction(addTransactionModal.type, addTransactionModal.to)'></app-button>
        <app-button buttonText='{{"Cancel" | translate}}' type='third' rounded=true (handleClick)="closeModal()"></app-button>
    </div>
  </app-modal-container>

<app-modal-container [isOpen]='confirmDeleteTransactionModal'>
    <div header class="Gilroy-Bold">
        {{'Delete Transaction' | translate}}
    </div>
    <div content>
        <p>{{'Delete message' | translate}}</p>
    </div>
    <div footer class="space-x-2 rtl:space-x-reverse">
        <app-button class="me-2" type='danger' buttonText='{{"Delete" | translate}}' (handleClick)='confirmDeleteTransaction()'></app-button>
        <app-button type='third' buttonText='{{"Cancel" | translate}}' (handleClick)='confirmDeleteTransactionModal=false'></app-button>
    </div>
</app-modal-container>
  