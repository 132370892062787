import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import moment = require('moment');
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subject, Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { RecurringInvoicesService } from 'src/app/modules/sales/components/recurring-invoices/recurring-invoices.service';
import { SalesService } from 'src/app/modules/sales/sales.service';
import { selectCustomers } from 'src/app/modules/sales/state/selectors/customers.selector';
import { NumberService } from 'src/app/shared/services/number.service';
import { RootReducerState } from 'src/app/store/reducers';
import { selectBusiness } from 'src/app/store/selectors/business.selector';
import { selectProjects } from 'src/app/store/selectors/project.selector';
import { InventoryService } from '../../inventory.service';

@Component({
  selector: 'app-production-run',
  templateUrl: './production-run.component.html',
  styleUrls: ['./production-run.component.scss']
})
export class ProductionRunComponent implements OnInit {

  tableData = [];
  productsList = [];
  tableKeys = [
    'item',
    'productionType',
    'productionNumber',
    'quantity',
    'createdAt',
  ];
  tableHeadings = [
    'Product Name',
    'Production Type',
    'Production Number',
    'Quantity',
    'Created At',
  ];
  customers = [];
  number = '1.2-2';
  currencyDetails = {
    currency: '',
    currencySymbol: ''
  };
  projects = [];

  unsubscribe$ = new Subject();
  business$: Observable<any>;
  customers$: Observable<any>;
  projects$: Observable<any>;
  openProductionRun = false;
  selectedItemForRun = null;
  quantity = null;
  businessId;
  selectedRunType = null;

  constructor(
    private router: Router,
    private store: Store<RootReducerState>,
    private invoiceService: RecurringInvoicesService,
    private numberService: NumberService,
    private translateService: TranslateService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private salesService: SalesService,
    private inventoryService: InventoryService
  ) {
    this.customers$ = store.pipe(select(selectCustomers));
    this.projects$ = this.store.pipe(select(selectProjects));
    this.business$ = store.pipe(select(selectBusiness));
   }

  ngOnInit(): void {
    this.business$.subscribe((business) => {
      if (business?.businessId?._id) {
        this.businessId = business?.businessId?._id;
        this.loadProductionRunList(business?.businessId?._id);
        this.loadComplexProduct(business?.businessId?._id);
      }
    });
  }

  loadProductionRunList(businessId): void {
    this.spinner.show();
    this.inventoryService.getProductionRun(businessId)
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(resp => {
      if(resp?.success){
        console.log('businessId', resp);
        
        this.tableData = this.mapDataForTable(resp?.data);
      }
      else{
        this.toastr.error(resp?.message)
      }
      this.spinner.hide();
    }, (err) => {
      this.toastr.error(err?.error?.message);
      this.spinner.hide();
    })
  }
  loadComplexProduct(businessId): void {
    this.spinner.show();
    this.inventoryService.getComplexProduct(businessId)
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(resp => {
      if(resp?.success){
        console.log('businessId', resp);
        
        this.productsList = resp?.data;
        this.spinner.hide();
      }
      else{
        this.toastr.error(resp?.message)
        this.spinner.hide();
      }
    }, (err) => {
      this.toastr.error(err?.error?.message);
      this.spinner.hide();
    })
  }

  mapDataForTable(itemsList) {
    return itemsList?.map(item => {
      return {
        productionNumber: item?.productionNumber,
        productionType: item?.productionType,
        quantity: item?.quantity,
        createdAt: moment(item?.createdAt).format('DD-MM-YYYY'),
        item: item?.itemId.name ?? ''
      }
    })
  }

  viewInvoice(id: string): void {
    console.log(id);
    
    this.router.navigate(['/sales/recurring-invoices/view-template'], { queryParams: { id }})
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  submitRun(): void {
    const data = {
      itemId: this.selectedItemForRun,
      quantity: this.quantity
    };
    this.spinner.show();
    if(this.selectedRunType === 'create'){
      this.inventoryService.runProduction(this.businessId, data).subscribe((result)=>{
        if(result.success) {
          this.spinner.hide();
          console.log("result", result);
          this.openProductionRun = false;
          this.quantity = null;
          this.selectedItemForRun = null;
          this.toastr.success("Production run successfully completed");
          this.loadProductionRunList(this.businessId);
        } else{
          this.spinner.hide();
          this.toastr.error(result?.message)
        }
      }, (err) => {
        this.toastr.error(err?.error?.message);
        this.spinner.hide();
      })
    } else {
      this.inventoryService.reverseProduction(this.businessId, data).subscribe((result)=>{
        if(result.success) {
          this.spinner.hide();
          console.log("result", result);
          this.openProductionRun = false;
          this.quantity = null;
          this.selectedItemForRun = null;
          this.toastr.success("Production reverse run successfully completed");
          this.loadProductionRunList(this.businessId);
        } else{
          this.spinner.hide();
          this.toastr.error(result?.message)
        }
      }, (err) => {
        this.toastr.error(err?.error?.message);
        this.spinner.hide();
      })
    }
  }

  itemSelectionChange(event): void {
    console.log("itemSelectionChange", event.target.value);
  }

}

