import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subject } from 'rxjs';
import { getAllAccountType } from 'src/app/shared/utils/accountType';
import { RootReducerState } from 'src/app/store/reducers';
import { selectBusiness } from 'src/app/store/selectors/business.selector';
import { AccountingService } from '../../accounting.service';
import { selectAccounts } from 'src/app/store/selectors/account.selector';
import { ToastrService } from 'ngx-toastr';
import { selectUser } from 'src/app/store/selectors/user.selectors';
import { UpdateTransactionLimit } from 'src/app/store/actions/user.actions';
import { selectUsage } from 'src/app/store/selectors/usage.selector';
import { updateCurrentTransactionLimit } from 'src/app/store/actions/usage.action';
import { DateValidator, valueChanges } from 'src/app/shared/utils/formValidator';
import { NumberService } from 'src/app/shared/services/number.service';
import { takeUntil } from 'rxjs/operators';
import { FileUploadService } from 'src/app/shared/services/file-upload.service';

@Component({
  selector: 'app-journal-entry',
  templateUrl: './journal-entry.component.html',
  styleUrls: ['./journal-entry.component.scss']
})
export class JournalEntryComponent implements OnInit, OnDestroy {

  constructor(private fb: FormBuilder,
    private store: Store<RootReducerState>,
    private spinner: NgxSpinnerService,
    private accoutingService: AccountingService,
    private router: Router,
    private toastr: ToastrService,
    private numberService: NumberService,
    private fileUploadService: FileUploadService,
    private route: ActivatedRoute) {
    this.accounts$ = this.store.pipe(select(selectAccounts));
    this.business$ = this.store.pipe(select(selectBusiness));
    this.usage$ = this.store.pipe(select(selectUsage));
    this.user$ = this.store.pipe(select(selectUser));

  }
  accounts$: Observable<any>;
  business$: Observable<any>;
  usage$: Observable<any>;
  user$: Observable<any>;
  unsubscribe$ = new Subject();
  transactionLimit;
  businessId = null;
  accounts = [];
  addedFiles = [];
  files: File[];
  number = '1.2-2';
  user = null;
  issuedBy = null;
  currencyDetails;
  journalEntryForm: FormGroup;
  creditTotal = 0;
  debitTotal = 0;
  debitIndex = null;
  difference = 0;
  enableSave = false;
  buttonType = 'third';
  update = false;
  showDialog: boolean = false;
  confirmChange: boolean = false;
  showConfirmDialog: boolean = false;
  payload = null;
  transactionId = null;
  transaction = {
    type: 'journal voucher',
    isReviewed: false
  };
  formErrors = {
    date: ''
  }
  formErrorMessages = {
    date: {
      invalidDate: 'Invalid date'
    }
  }
  ngOnInit(): void {
    this.route.queryParams.subscribe(({ id }) => {
      this.loadJournalEntry(id);
    });
    this.getTransactionLimit();
    this.loadAccounts();
    this.loadForm();
    this.loadUser();
    this.loadNumberConfig();
  }

  loadNumberConfig(): void {
    this.numberService.number
    .pipe((takeUntil(this.unsubscribe$)))
    .subscribe((number) => {
      this.number = number
    })
  }

  getTransactionLimit(): void {
    this.usage$.subscribe(({currentUsage}) => {
      if (currentUsage) {
        const { transactionLimit } = currentUsage;
        this.transactionLimit = transactionLimit;
      }
    });
  }

  loadJournalEntry(id): void {
    if (id) {
      this.spinner.show();
      this.accoutingService.getTransactionDetails(id).subscribe((resp) => {
        this.spinner.hide();
        this.update = true;
        this.transactionId = id;
        this.transaction = resp.data
        const data = {
          notes: resp?.data?.notes ?? null,
          description: resp?.data?.description ?? null,
          date: resp?.data?.date?.split('T')[0] ?? null,
          journal: resp?.data?.journal.map((entry) => {
            return {
              type: entry?.type,
              description: entry?.description,
              debit: entry?.debit ? this.numberService.toFixed(entry?.debit) : 0,
              credit: entry?.credit ? this.numberService.toFixed(entry?.credit) : 0,
              account: { accountName: entry?.accountName, accountType: entry?.account, _id: entry?.accountId }
            }
          }) ?? [],
          amount: this.numberService.toFixed(resp?.data?.amount) ?? null
        };
        this.addedFiles = resp?.data?.files ?? [];
        this.issuedBy = `${resp.data.userId.firstName} ${resp.data.userId.lastName}`;
        const noOfLinesToAdd = data?.journal?.length - 2;
        for (let i = 0; i < noOfLinesToAdd; i++) {
          this.addLine();
        }
        this.journalEntryForm.setValue(data);
      }, (error) => {
        this.spinner.hide();
      })
    }
  }

  loadUser(): void {
    this.user$.subscribe((user) => {
      if (user) {
        this.issuedBy = `${user.firstName} ${user.lastName}`;
      }
    });
  }

  loadAccounts(): void {
    this.accounts$.subscribe((accounts) => {
      this.accounts = accounts;
    })
    this.business$.subscribe((business) => {
      if (business?.businessId._id) {
        this.businessId = business?.businessId?._id;
        this.currencyDetails = (({ currency, currencySymbol }) => ({ currency, currencySymbol }))(business?.businessId);
      }
    })
  }

  loadForm(): void {
    this.journalEntryForm = this.fb.group({
       date: [null, [Validators.required, DateValidator()]],
      description: [null],
      amount: [null],
      notes: [null],
      journal: this.fb.array([])
    });
    this.addLine('Debit');
    this.addLine('Credit');
    this.journalEntryForm.valueChanges.subscribe(({ journal }) => {
      this.formErrors = valueChanges(this.journalEntryForm, this.formErrors, this.formErrorMessages);
      let creditTotal = 0;
      let debitTotal = 0;
      journal.forEach((entry) => {
        console.log(entry)
        creditTotal += entry?.credit,
          debitTotal += entry?.debit
      })
      this.creditTotal = this.numberService.toFixed(creditTotal);
      this.debitTotal = this.numberService.toFixed(debitTotal);
      this.difference = this.numberService.toFixed(Math.abs(creditTotal - debitTotal));
      if (this.creditTotal === this.debitTotal && this.journalEntryForm.valid) {
        this.enableSave = true;
        this.buttonType = 'primary';
      } else {
        this.enableSave = false;
        this.buttonType = 'third';
      }
    });
  }

  addLine(type?): void {
    (<FormArray>this.journalEntryForm.get('journal')).push(
      this.fb.group({
        description: [null],
        account: [null, [Validators.required]],
        debit: [0, [Validators.min(0)]],
        credit: [0, [Validators.min(0)]],
        type: [type ?? 'Debit']
      }))
  }

  removeLine(index): void {
    (<FormArray>this.journalEntryForm.get('journal')).removeAt(index)
  }

  get journal() {
    return this.journalEntryForm.get('journal') as FormArray
  }

  changeType(value, i): void {
    if (value === 'Credit') {
      if (this.journal.controls[i].get('debit').value !== null) {
        console.log(this.journal.controls[i].get('debit').value);
        this.journal.controls[i].get('credit').setValue(this.journal.controls[i].get('debit').value);
        this.journal.controls[i].get('debit').setValue(null);
      }
    } else {
      if (this.journal.controls[i].get('credit').value !== null) {
        this.journal.controls[i].get('debit').setValue(this.journal.controls[i].get('credit').value);
        this.journal.controls[i].get('credit').setValue(null);
      }
    }
  }

  saveJournal(): void {
    this.showDialog = false;
    this.spinner.show();
    if (((this.journalEntryForm.get('journal')) as FormArray).length === 0) {
      this.toastr.error('No items to save, try adding some');
      this.spinner.hide();
      return;
    }
    let body = {
      businessId: this.businessId,
      type: "journal",
      isConfirm: this.confirmChange,
      notes: this.journalEntryForm.value.notes,
      description: this.journalEntryForm.value.description,
      amount: this.numberService.toFixed(this.creditTotal),
      date: this.journalEntryForm.value.date,
      category: 'Journal Entry',
      journal: this.journalEntryForm.value.journal.map((entry) => {
        return {
          ...entry,
          account: entry?.account?.accountType,
          accountName: entry?.account?.accountName,
          accountId: entry?.account?._id
        };
      })
    }
    let type = 'new';
    if (this.update) {
      body['id'] = this.transactionId;
      type = 'old'
    }
    const formData = new FormData();
    this.fileUploadService.emitFiles.next(true);
    this.fileUploadService.emitFiles.next(false);
    // this.files.forEach((file, i) => {
    //   formData.append(`file${i}`, file);
    // });
    formData.append('payload', JSON.stringify(body));

    this.accoutingService.saveJournalEntry({formData, businessId:this.businessId, transactionId: this.transactionId}, type).subscribe((resp) => {
      if (resp.success) { 
        this.spinner.hide();
        this.toastr.success(resp?.message);
        if (type === 'new') {
          this.store.dispatch(updateCurrentTransactionLimit({ transactionLimit: this.transactionLimit + 1 }))
        }
        if(body?.isConfirm ){
          this.confirmChange = false;
          this.showConfirmDialog = false;
        }
        this.files = [];
        this.router.navigate(['/accounting/transaction']);
      }
      else{
        this.spinner.hide();
        this.toastr.error('Something went wrong!');
      }
    }, error => {
      this.toastr.error('Something went wrong!');
      this.spinner.hide();
    });
  }

  reviewTransaction(): void {
    const data = {
      transactionId: this.transactionId,
      isReviewed: !this.transaction.isReviewed
    }
    this.spinner.show();
    this.accoutingService.markAsReviewTransaction(data).subscribe((resp) => {
      if (resp.success) {
        this.spinner.hide();
        this.transaction = resp.data;
      }
    }, error => {
      this.spinner.hide();
    })
  }

  saveFiles(files: File[]){
    this.files = files;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
