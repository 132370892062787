<div class="p-8 rounded-3xl bg-light-gray-1 mt-2 mb-2">
    <h3 class="Gilroy-Bold text-lg">{{'Emails' | translate }}</h3>
    <p class="mt-2 Gilory-Medium">{{'Email listing message' | translate }}</p>
    <table *ngIf="emails$ | async as emails" class="mt-6 min-w-full divide-y divide-gray-300">
        <thead class="text-start">
            <tr class="h-12">
                <th class="Gilroy-Bold">{{'Email Address' | translate }}</th>
                <th class="Gilroy-Bold">{{'Status' | translate }}</th>
                <th *ngIf='emails.length > 1' class="Gilroy-Bold">{{'Actions' | translate }}</th>
            </tr>
        </thead>
        <tbody  class="divide-y divide-gray-300">
            <tr class="h-12" *ngFor='let email of emails; trackBy:trackByFn'>
                <td>{{email.email}}</td>
                <td>{{email.type | titlecase}}</td>
                <td>
                    <div *ngIf='email.type !== "primary"' class="flex">
                        <button (click)='confirmEmailDelete(email.email)'>
                            <svg xmlns="http://www.w3.org/2000/svg" name='delete' class="h-5 w-5 text-red-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                    d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                            </svg>
                        </button>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
    <div class="mt-6">
        <button appSubscriptionCheck featureToCheck='adminLimit' *ngIf='!addNewEmail' (click)='addNewEmail = true' id='add' class="flex items-center Gilroy-Bold space-x-4 rtl:space-x-reverse">
            <span class="rounded-full bg-gradient text-white p-1 me-2">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                </svg>
            </span>
            {{'Add new email' | translate }}
        </button>
        <div *ngIf="response" class="text-green-500 py-1">{{response}}</div>
        <div *ngIf='addNewEmail' class="flex flex-col border-t border-gray-300 space-y-4 pt-4 w-full lg:w-1/2">
            <h2 class="Gilroy-Bold">{{'Add New email' | translate }}</h2>
            <div class="custom-input-box-shadow rounded-xl bg-white px-3 py-2">
                <label for='email' class="block text-sm Gilroy-Bold font-color-05">{{'Email Address' | translate }}</label>
                <input [formControl]='newEmail' id='email' type="text" class="w-full p-0 border-0 focus:ring-0 placeholder-gray-200" placeholder="{{'Enter email' | translate }}" />
            </div>
            <div class="flex space-x-2">
                <app-button buttonText='{{"Verify" | translate }}' type='primary' (handleClick)='verifyEmail()'></app-button>
                <app-button buttonText='{{"Cancel" | translate }}' type='secondary' (handleClick)="addNewEmail=false"></app-button>
            </div>
        </div>
    </div>
</div>

<app-modal-container [isOpen]='deleteEmailModal'>
    <div header class="Gilroy-Bold border-b pb-2">{{'Remove email' | translate }}</div>
    <div content>
        <p class="mb-2">
            {{'Delete email confirmation message' | translate }}
        </p>
        <p *ngIf='emailToDelete'>{{'Email -' | translate }} {{emailToDelete}}</p>
    </div>
    <div footer class="flex space-x-4">
        <app-button type='danger' buttonText='{{"Remove" | translate }}' rounded='true' (handleClick)='deleteEmail()'></app-button>
        <app-button type='third' buttonText='{{"Cancel" | translate }}' rounded='true' (handleClick)='deleteEmailModal = false'></app-button>
    </div>
</app-modal-container>