import { Component, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { RootReducerState } from 'src/app/store/reducers';
import { selectUser } from 'src/app/store/selectors/user.selectors';
import { FileManagerService } from '../../file-manager.service';
import { selectBusiness } from 'src/app/store/selectors/business.selector';
import { takeUntil } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { StorageService } from 'src/app/modules/storage/storage.service';
import { TaskServiceService } from 'src/app/modules/task-manager/task-service.service';
import { ToastrService } from 'ngx-toastr';
import { ChatsService } from 'src/app/modules/chats/chats.service';

@Component({
  selector: 'app-files-list',
  templateUrl: './files-list.component.html',
  styleUrls: ['./files-list.component.scss'],
})
export class FilesListComponent implements OnInit {
  quickFilters = [
    {
      filterBy: 'All Files',
      status: 'allTasks',
      active: true,
    },
    {
      filterBy: 'In Progress',
      status: 'inProgress',
      active: false,
    },
    {
      filterBy: 'Upcoming',
      status: 'upcoming',
      active: false,
    },
    {
      filterBy: 'Completed',
      status: 'completed',
      active: false,
    },
    {
      filterBy: 'Overdue',
      status: 'overdue',
      active: false,
    },
    {
      filterBy: 'Assigned to Me',
      status: 'assignedToMe',
      active: false,
    },
  ];
  tableHeadings = ['File Name', 'File Type', 'Uploaded Date', 'Download', 'Share', 'View', 'Delete'];
  tasks = [];
  files = [];
  filteredTask: any = this.tasks;
  user$: Observable<any>;
  isFileModalOpen = false;
  userData: any;
  fileUploaded: boolean;
  businessId: string;
  businessId$: Observable<any>;
  unsubscribe = new Subject();
  fileError = false;
  tableKeys = ['fileName', 'fileType'];
  allFiles: any;
  isShareModalOpen = false;
  fileSharedWithUsers; any;
  fileToShareId: string;
  selectedUser: string;
  isRemoveFileAccess = false;
  removeUserDetails: any;
  originalBusinessUsers: any;
  allBusinessUsers: any;
  selectUserError = false;
  isDeleteModalOpen = false;
  fileToDelete: any;

  constructor(
    private store: Store<RootReducerState>,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private rootStore: Store<RootReducerState>,
    private filesService: FileManagerService,
    private storageService: StorageService,
    private taskService : TaskServiceService,
    private chatService: ChatsService,
  ) {
    this.user$ = this.store.pipe(select(selectUser));
    this.businessId$ = rootStore.pipe(select(selectBusiness));
  }

  ngOnInit(): void {
    this.spinner.show();
    this.businessId$.pipe(takeUntil(this.unsubscribe)).subscribe((business) => {
      if (business?.businessId?._id) {
        this.businessId = business?.businessId?._id;
        if(this.businessId) {
          this.getAllBusinessUsers();
          this.loadUserDetails();
        }
      }
      console.log('business?', this.businessId);
    });
    this.getMyFiles();
  }


  getAllBusinessUsers(): void {
    this.taskService.getAllBusinessUsers(this.businessId).subscribe((resp)=>{
      console.log('user', resp);
      resp.data = resp.data.filter(item => item._id !== this.userData._id);
      this.originalBusinessUsers = resp.data.map(({ _id, firstName, lastName })=>({_id, name: firstName + ' ' + lastName}));
    })
  }

  getMyFiles(): void {
    this.spinner.show();
    console.log("allFiles");
    this.filesService.getAllFiles().subscribe((resp)=>{
      console.log("resp", resp);
      this.allFiles = resp.data;
      this.spinner.hide();
    }, (err)=>{
      this.spinner.hide();
    })
  };

  loadUserDetails(): void {
    this.user$.subscribe((user) => {
      if (user) {
        this.userData = user;
        console.log('this.userData', this.userData);
      }
    });
  }

  applyQuickFilter(status: any) {
    this.quickFilters = this.quickFilters.map((filter: any) => {
      if (filter.filterBy === status) {
        filter.active = true;
      } else {
        filter.active = false;
      }
      return filter;
    });
    // this.filteredTask = this.tasks
    this.filteredTask = this.tasks.filter((task: any) => {
      if (status === 'All Tasks') {
        return this.tasks;
      }
      if (task.status === status) {
        return task;
      }
      if (status === 'Assigned to Me') {
        return task.assignToUserId._id === this.userData._id;
      }
    });
  }

  onFileSelect(event: any) {
    if (event.target.files.length > 0) {
      this.fileError = false;
      // this.fileUploaded = event.target.files;
    }
    console.log('fileevent', event.target.files);
    
    this.files.push(...event.target.files);
    console.log('fileevent1', this.files[0].name.split('.').at(-1));
  }

  // formData() {
  //   this.createTaskForm.get('createdByUserId').setValue(this.userData._id);
  //   const payload = this.createTaskForm.value

  //   payload['businessId'] = this.businessId
  //   const formData = new FormData()
  //   Object.keys(this.createTaskForm.value).forEach((key)=>{

  //     formData.append(key,this.createTaskForm.get(key)?.value)
  //   })
  //   formData.append('payload',JSON.stringify(this.createTaskForm.value))
  //   if(this.files.length) {
  //     for(let i=0; i<this.files.length; i++) {
  //       formData.append(`file${i}`,this.files[i])
  //     }
  //   }
  //   return formData
  // }

  uploadFiles(): void {
    if (!this.fileUploaded) {
      this.fileError = true;
      return;
    }
    this.spinner.show();
    console.log('files', this.allFiles);
    const formValues = {
      fileName: this.files[0].name,
      formattedName: 'Files' + '-' + new Date().getFullYear() + '-' + (this.allFiles.length + 1),
      fileType:  this.files[0].name.split('.').at(-1).toUpperCase(),
    };

    const formData = new FormData();

    Object.keys(formValues).forEach((key) => {
      formData.append(key, formValues[key]);
    });
    formData.append('payload', JSON.stringify(formValues));
    // if (this.files.length) {
    //   for (let i = 0; i < this.files.length; i++) {
    //     formData.append(`file${i}`, this.files[i]);
    //   }
    // }

    this.filesService.uploadFile(this.businessId, formData).subscribe(
      (resp) => {
        console.log('resp', resp);
        this.closeFileModal();
        this.spinner.hide();
        this.getMyFiles();
      },
      (err) => {
        console.log("error", err.error.message);
        
        this.toastr.error(err.error.message);
        this.spinner.hide();
      }
    );
  }

  closeFileModal(): void {
    this.isFileModalOpen = false;
    this.fileUploaded = null;
    this.files = [];
  }

  openFileModal(): void {
    this.isFileModalOpen = true;
    this.fileUploaded = null;
    this.fileError = false;
  }

  viewOrDownloadFile(fileId): void {
    this.spinner.show();
      this.storageService.getFile(fileId)
      .subscribe(resp => {
        if(resp?.success){
          console.log(resp?.data);
          const a = document.createElement('a');
          const bufferArray = resp?.data?.fileBuffer?.data; 
          const uint8Array = new Uint8Array(bufferArray);
          const blob = new Blob([uint8Array], {type: resp?.data?.docData?.fileType});
          const url = window.URL.createObjectURL(blob);
          a.href = url;
          a.download = resp?.data?.docData?.displayFileName;
          a.click();
          window.URL.revokeObjectURL(url);
          this.spinner.hide();
        }
      }, (err)=>{
        this.spinner.hide();
      })
  }

  viewFileInNewTab(fileId): void {
    this.spinner.show();
      this.storageService.getFile(fileId)
      .subscribe(resp => {
        if(resp?.success){
          const a = document.createElement('a');
          const bufferArray = resp?.data?.fileBuffer?.data; 
          const uint8Array = new Uint8Array(bufferArray);
          const blob = new Blob([uint8Array], {type: resp?.data?.docData?.fileType});
          const url = window.URL.createObjectURL(blob);
          const newTab = window.open(url, '_blank');
          if (newTab) {
            newTab.onbeforeunload = () => {
              window.URL.revokeObjectURL(url);
            };
          }
          this.spinner.hide();
        }
      }, (err)=>{
        this.spinner.hide();
      })
  }

  shareFile(id): void {
    this.isRemoveFileAccess = false;
    this.fileSharedWithUsers = this.allFiles.filter(item=>item._id === id)[0].sharedWithUsers;
    this.allBusinessUsers = this.originalBusinessUsers.filter((item)=>{
      const userId = item._id;
      return !this.fileSharedWithUsers.some(sharedUser => sharedUser.userId._id === userId)
    });
    this.isShareModalOpen = true;    
    this.fileToShareId = id;
  }

  closeShareModal(): void {
    this.selectUserError = false;
    this.isShareModalOpen = false;
    this.selectedUser = null;
    this.isRemoveFileAccess = false;
  }

  goBackToList(): void {
    this.isRemoveFileAccess = false;
  }

  userSelected(event): void {
    console.log("event", event);
    
    if(this.selectedUser){
      this.selectUserError = false;
    }
  }

  confirmShare(): void {
    console.log("shareFile", this.selectedUser);
    if(!this.selectedUser){
      this.selectUserError = true;
      return;
    }
    this.isShareModalOpen = false;
    const payload = {
      userId: this.selectedUser,
      sharedDate: new Date()
    }
    this.spinner.show();
    this.filesService.addShareInFile(this.fileToShareId, payload).subscribe((resp)=>{
      console.log("resp", resp);
      this.selectUserError = false;
      this.isShareModalOpen = false;
      this.getMyFiles();
      console.log("shared", this.userData, this.selectedUser);
      
      this.chatService.emit('notification', {
        entityId: resp.data._id,
        entityType: 'sharedFile',
        message: `${this.userData.firstName + ' ' + this.userData.lastName} has shared a file ${resp.data.fileName} with you`,
        fromUser: this.userData._id,
        toUser: this.selectedUser,
      });
      this.toastr.success('File has been shared');
      this.selectedUser = null;
    }, (err)=>{
      this.spinner.hide();
    })
  }

  removeAccess(item): void {
    this.removeUserDetails = item;
    this.isRemoveFileAccess = true;
  }

  confirmRemoveAccess(): void {
    const payload = {
      userId: this.removeUserDetails.userId._id
    }
    this.spinner.show();
    this.filesService.removeShareInFile(this.fileToShareId, payload).subscribe((resp)=>{
      console.log("resp", resp);
      this.selectUserError = false;
      this.isShareModalOpen = false;
      this.selectedUser = null;
      this.isRemoveFileAccess = false;
      this.getMyFiles();
      this.toastr.success('Access has been removed');
    }, (err)=>{
      this.spinner.hide();
    })
  }

  closeDeleteModal(): void {
    this.isDeleteModalOpen = false;
  }

  deleteModal(file): void {
    this.fileToDelete = file;
    this.isDeleteModalOpen = true;
  }

  confirmDelete(): void {
    this.spinner.show();
    this.filesService.deleteFile(this.fileToDelete._id).subscribe((resp)=>{
      console.log("resp", resp);
      this.filesService.deleteStorageFile(this.fileToDelete.files[0]?._id).subscribe((resp)=>{
        this.spinner.hide();
        this.isDeleteModalOpen = false;
        this.toastr.success("File deleted successfully")
        this.getMyFiles();
      })
    }, (err)=>{
      this.spinner.hide();
    })
  }

  
}
