<div class="p-8 space-y-5">
    <div class="w-full flex justify-between">
        <h1 class="text-2xl Gilroy-Bold" >{{'Cloud Storage' | translate}}</h1>
    </div>
    <div class="">
        <p class="Gilroy-Bold text-lg" >{{'Quick Access'}}</p>
        <div class="flex space-x-4 rtl:space-x-reverse rtl:px-2 w-full" >
            <div class="mt-4 rounded px-4 py-2 box-shadow w-1/5" >
                <p class="Gilroy-Bold text-xl" >{{'Storage' | translate}}</p>
                <progress [value]="statisticsData?.usedPercentage" max="100" class="h-1 w-5/6" ></progress>
                <p class="Gilroy">{{statisticsData?.usedStorage + ' ' + 'GB used out of' + ' ' + statisticsData?.totalStorage + ' ' + 'GB' | translate}}</p> <!-- yha se continue krna h, dynamic statistics data integrate krke -->
            </div>
            <div class="mt-4 rounded px-4 py-3 box-shadow w-[10%] space-y-4" >
                <p class="Gilroy-Bold text-center text-xl" >{{'Total Files' | translate}}</p>
                <p class="Gilroy-Bold text-center text-xl">{{statisticsData?.totalFiles | translate}}</p>
            </div>
        </div>
    </div>
    <hr class="h-1 opacity-10 w-full border-black" >
    <div class="flex space-x-4 rtl:space-x-reverse">
        <div class="w-full">
            <div *ngIf="!listView">
                <div class="my-6" *ngFor="let sectionType of folderData; let i = index" >
                    <p class="Gilroy-Bold text-lg">{{(sectionType?.parentType + ' ' + 'Documents') | translate}} </p>
                    <div class="mt-3 flex space-x-3 rtl:space-x-reverse">
                        <div (click)="showFilesForSection(docType?.docType)" class="card relative rounded-xl w-[12%] h-[12%] flex flex-col items-center justify-center p-2 space-y-3 " *ngFor="let docType of sectionType?.child; let j = index" >
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"  class="w-4/5 h-3/4" [ngStyle]="{'fill': j > 4 ? folderColorMap[j%4] : folderColorMap[j]}" >
                                <path d="M19.5 21a3 3 0 003-3v-4.5a3 3 0 00-3-3h-15a3 3 0 00-3 3V18a3 3 0 003 3h15zM1.5 10.146V6a3 3 0 013-3h5.379a2.25 2.25 0 011.59.659l2.122 2.121c.14.141.331.22.53.22H19.5a3 3 0 013 3v1.146A4.483 4.483 0 0019.5 9h-15a4.483 4.483 0 00-3 1.146z" />
                            </svg>
                            <span class="text-xs bg-white opacity-60 p-1 ml-[40%] mt-[10%] absolute rounded-lg">{{docType?.totalFile}}</span>
                            <p class="Gilroy-Bold text-lg">{{docType?.docType}}</p>                          
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="listView"></div>
        </div>
        <!-- <div class="w-1/4">
            <div class="rounded-lg bg-gray-100 p-2" >
                <img class="w-full h-full" src="../../../../../assets/images/storage.png" alt="storage image">
                <div>
                    <p class="text-xl my-5" >{{'Upgrade Storage!' | translate}}</p>
                    <p class="my-3">{{'Get more space to keep your documents safe'}}</p>
                    <app-button [buttonText]="'Upgrade'" ></app-button>
                </div>
            </div>
        </div> -->

    </div>
</div>