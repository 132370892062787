<div class="flex min-h-screen">
  <div class="w-full h-screen overflow-y-auto no-scrollbar">
    <div class="w-full flex flex-col space-y-4">

      <!-- header start  -->
      <div class="flex items-center ps-5 pt-4 pe-7 justify-between w-10/12 bg-white fixed top-0">
        <div class="">
          <h1 class="text-2xl Gilroy-Bold inline-flex items-center">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 me-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z" />
            </svg>
            {{'Cash Flow Dashboard' | translate}}
          </h1>
        </div>
        <div class="">
          <div class=" flex gap-4">
            <!-- <div class="w-36">
              <ng-select
              [items]='monthsName'
              bindLabel='name'
              [searchable]=false
              bindValue="value"
              placeholder="Months"
              [clearable]="false"
              [(ngModel)]='selectedMonth'
              (change)=" dataChange()"
              ></ng-select>
            </div> -->
            <div class="w-64">
              <ng-select
              [items]='years'
              bindLabel='name'
              [searchable]=false
              [clearable]="false"
              placeholder="Years"
              [(ngModel)]="selectedYear"
              bindValue="value"
              (change)="dataChange()"></ng-select>
            </div>
          </div>
        </div>
      </div>
      <!-- header End -->

      <!-- charts starts here -->
      <div class="flex">
        <div class="w-11/12 px-5 mt-14">

      <!-- Net Operating cash flow start here -->
      <div class="flex items-center justify-between mb-3">
        <h1 class="text-xl Gilroy-Bold">{{'Net Operating In Flow'| translate}}</h1>
      </div>

      <div class="bg-proile p-5 mb-3 rounded-lg" style="display: block">
        <canvas baseChart
          [datasets]="netOperatingCashFlowData"
          [labels]="netOperatingCashFlowLabels"
          [options]="netOperatingCashFlowOptions"
          [plugins]="netOperatingCashFlowPlugins"
          [legend]="netOperatingCashFlowLegend"
          [chartType]="netOperatingCashFlowType">
        </canvas>
      </div>

      <!-- Net Operating cash flow ends here -->


      <!-- charts ends here  -->
    </div>
  </div>

    </div>
  </div>
</div>
