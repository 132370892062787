<div class="flex justify-center p-4">
  <div class="w-full flex flex-col space-y-4">
    <div>
      <div class="flex items-center justify-between">
        <div class="">

          <h1 *ngIf='!update' class="text-2xl Gilroy-Bold inline-flex items-center">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 me-2" fill="none" viewBox="0 0 24 24"
              stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z" />
            </svg>
            {{'Add New Bill' | translate}}
          </h1>

          <h1 *ngIf='update' class="text-2xl Gilroy-Bold inline-flex items-center">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 me-2" fill="none" viewBox="0 0 24 24"
              stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z" />
            </svg>
            {{'Update a Bill' | translate}}
          </h1>
        </div>
        <div appAccessControl moduleType='purchase' accessType='edit' class="space-x-2 rtl:space-x-reverse">
          <app-button *ngIf='!update' buttonText="{{'Save' | translate}}" (handleClick)="createBill()" type="primary"></app-button>
          <app-button *ngIf='update' buttonText="{{'Update' | translate}}" (handleClick)="updatebill()" type="primary"></app-button>
          <app-button buttonText="{{'Discard' | translate}}" routerLink='../' type="border"></app-button>
      </div>
    </div>
    <form [formGroup]='billForm'>
      <div class="flex justify-between mt-5">
        <div class="flex mb-2">
          <div class="me-5">
            <label class="block text-md Gilroy-Bold">{{'Vendor' | translate}}</label>
            <ng-select [items]="vendors" class="custom-selector border-0 p-0 w-44 h-11" bindLabel="vendorName" formControlName="vendor" placeholder='{{"Select one" | translate}}' (change)='changeEventVendor($event)'>
              <ng-option *ngFor="let vendor of vendor" [value]="vendor.id">{{vendor.name}}</ng-option>
            </ng-select>
            <div class="text-sm text-red-500 ms-2 mt-1" *ngIf='formErrors.vendor'>
              {{formErrors.vendor}}</div>
          </div>
          <div>
            <label class="block text-md Gilroy-Bold">{{'Project' | translate}}</label>
            <ng-select [items]="projects"
                          class="custom-selector" 
                          bindLabel="projectName" 
                          bindValue="_id"
                          formControlName="projectId"
                          placeholder='{{"Select Project Name" | translate}}'>
                </ng-select>
          </div>
        </div>
      </div>
      <div class="flex mt-2">
        <div class="me-5">

          <label class="block text-sm Gilroy-Bold">{{'Date' | translate}}</label>
          <input (change)='reloadBillNumber()' id='date' formControlName="date" type="date" 
          class="w-full h-11 pl-3 rounded-xl mt-2 bg-input-sales p-0 border-0 focus:ring-0" />
            <div class="text-sm text-red-500 ms-2 mt-1" *ngIf='formErrors.date'>
              {{formErrors.date}}</div>
        </div>
        <div class="me-5">
          
          <label class="block text-sm Gilroy-Bold">{{'Due Date' | translate}}</label>
          <input id='dueDate' formControlName='dueDate' type="date" 
          class="w-full h-11 pl-3 rounded-xl mt-2 bg-input-sales p-0 border-0 focus:ring-0" />
            <div class="text-sm text-red-500 ms-2 mt-1" *ngIf='formErrors.dueDate'>
            {{formErrors.dueDate}}</div>
        </div>
        <div class="me-5">
          
          <label class="block text-sm Gilroy-Bold">{{'Vendor Invoice' | translate}}</label>
          <input id='purchaseOrder' formControlName='purchaseOrder' type="text"
          class="w-full h-11 pl-3 rounded-xl mt-2 bg-input-sales p-0 border-0 focus:ring-0"
          placeholder="{{'Ex. 1234567' | translate}}" />
          <div class="text-sm text-red-500 ms-2 mt-1" *ngIf='formErrors.purchaseOrder'>
            {{formErrors.purchaseOrder}}</div>
          </div>
          <div class="me-5">
            
          <label class="block text-sm Gilroy-Bold">{{'Bill Number #' | translate}}</label>
          <fieldset disabled="disabled">
            <input id='billNumber' formControlName='billNumber' type="text"
              class="w-full h-11 pl-3 rounded-xl mt-2 bg-input-sales p-0 border-0 focus:ring-0" />
          </fieldset>
          <div class="text-sm text-red-500 ms-2 mt-1" *ngIf='formErrors.billNumber'>
            {{formErrors.purchaseOrder}}</div>
        </div>

      </div>
      <div class="flex mt-4">
        <div class="w-8/12">
          <label class="block text-sm Gilroy-Bold">{{'Notes' | translate}}</label>
          <textarea formControlName="notes"
            class="w-full h-28 ps-3 rounded-xl mt-2 bg-input-sales p-4 border-0 focus:ring-0"
            placeholder="{{'Add some notes' | translate}}"></textarea>
          <div class="text-sm text-red-500 ms-2 mt-1" *ngIf='formErrors.notes'>
            {{formErrors.notes}}</div>
        </div>
      </div>

      <h3 class="text-lg mt-6 Gilroy-Bold">{{'Items' | translate}}</h3>

      <table class="min-w-full divide-y divide-gray-300 border-b"> 
        <thead class="bg-gray-100 h-12">
                <tr class="text-left">
                    <th class="ps-5 rounded-ts-xl Gilroy-Bold">{{'Item' | translate}}</th>
                    <th class="px-5 Gilroy-Bold">{{'SKU' | translate}}</th>
                    <th class="px-5 Gilroy-Bold">{{'HSN/SKU' | translate}}</th>
                    <th class="px-2 Gilroy-Bold">{{'Quantity' | translate}}</th>
                    <th class="px-2 Gilroy-Bold">{{'Unit Price' | translate}}</th>
                    <th class="px-2 Gilroy-Bold">{{'Select Tax' | translate }}</th>
                    <th class="px-2 Gilroy-Bold">{{'Total' | translate}}</th>
                    <th class="rounded-te-xl"></th>
                </tr>
        </thead>
        <tbody class="border">
            <ng-container formArrayName='items'>
                <ng-container *ngFor='let itemList of items.controls; let i = index;'>
                    <tr [formGroup]='itemList' class="">
                        <td class="w-2/12 p-2">
                            <ng-select [items]="availableProducts"
                                groupBy = 'type'
                                class="custom-selector" 
                                bindLabel="name" 
                                bindValue="name"
                                formControlName="item"
                                placeholder='{{"Select one" | translate}}'
                                [clearable]="false"
                                (change) = "changeEvent($event, i)">
                            </ng-select>
                        </td>                    
                        <td class="p-2">
                          <fieldset disabled="disabled">
                            <input formControlName='sku' type="text" class="w-full p-3 rounded-xl bg-input bg-gray-100 border-0 focus:ring-0"  />
                          </fieldset>
                        </td>
                        <td class="p-2">
                          <fieldset disabled="disabled">
                            <input formControlName='hsn_sac' type="text" class="w-full p-3 rounded-xl bg-input bg-gray-100 border-0 focus:ring-0"  />
                          </fieldset>
                        </td>
                        <td class="p-2">
                          <input id='unit' formControlName='unit' type="number" class="w-full p-3 rounded-xl bg-input bg-gray-100 border-0 focus:ring-0" placeholder="0" />
                        </td>
                        <td class="p-2">
                            <input id='price' formControlName='price' type="number" class="w-full p-3 rounded-xl bg-input bg-gray-100 border-0 focus:ring-0" placeholder="0" />
                        </td>
                        <td class="p-2 w-2/12">
                            <ng-select class="custom-selector border-0 p-0" [items]="salesTaxes" 
                                bindLabel="name" 
                                formControlName="tax"
                                multiple="true"
                                placeholder='{{"Select Tax" | translate}}'>
                                <ng-template ng-label-tmp let-item="item" let-clear="clear">
                                    <span class="ng-value-label">{{item.taxName}} {{item.tax}}%</span>
                                    <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                                </ng-template>
                            </ng-select>
                        </td>
                        <td class="p-2">
                            <p>{{items.controls[i].get('unit').value * items.controls[i].get('price').value | currency: currencyDetails?.currency : 'symbol-narrow' :number}}</p>
                        </td>
                        <td class="w-1/12">
                            <svg (click)='removeItem(i)' xmlns="http://www.w3.org/2000/svg" name="delete" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="mx-auto h-5 w-5 text-red-500"><path _ngcontent-hsm-c58="" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"></path></svg>
                        </td>
                    </tr>
                    <tr>
                      <td colspan="3"></td>
                      <td>
                        <p class="text-red-500" *ngIf='itemList.get("unit").hasError("min")'>{{'Atleast 1 unit is required'}}</p>
                      </td>
                      <td colspan="4" ></td>
                    </tr>
                </ng-container>
                <ng-container *ngIf='items.controls.length === 0'>
                  <tr>
                    <td colspan="7" class="text-center p-2">
                      {{'No item added' | translate}}
                    </td>
                  </tr>
                </ng-container>
            </ng-container>
            <tr>
                <td vAlign='top' colspan="4"> 
                    <div class="ps-2">
                        <button (click)='addNewItem()' id="add" class="flex items-center Gilroy-Bold space-x-4">
                            <span  class="rounded-full bg-gradient text-white p-1 me-2">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="h-4 w-4">
                                    <path  stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path>
                                </svg>
                            </span> {{'Add a Line' | translate}}
                        </button>
                    </div>
                </td>
                <td colspan="4" class="pe-2">
                    <div class="Gilroy-Bold flex justify-between py-2">
                        <p>{{'Subtotal:' | translate}}</p>
                        <p>{{subTotal | currency: currencyDetails?.currency : 'symbol-narrow' :number}}</p>
                    </div>
                    <div class="Gilroy-Bold flex justify-between py-2">
                        <p>{{'Tax:' | translate}}</p>
                        <p>{{totalTax | currency: currencyDetails?.currency : 'symbol-narrow' :number}}</p>
                    </div>
                    <div class="Gilroy-Bold flex justify-between py-2 border-t">
                        <p>{{'Total Amount:' | translate}}</p>
                        <p>{{totalAmount | currency: currencyDetails?.currency : 'symbol-narrow' :number}}</p>
                    </div>
                </td>
            </tr> 
        </tbody>
      </table>
      <!-- <div class="mt-4">
        <app-file-upload appAccessControl moduleType="storage" accessType="edit" [addedFiles]="addedFiles"   (emitter)="saveFiles($event)" ></app-file-upload>
      </div> -->
    </form>


  </div>
</div>

<app-modal-container [isOpen]='outOfStock'>
  <div header class="text-start border-b pb-2 Gilroy-Bold">{{'Check Items' | translate}}</div>
  <div class="text-start" content>
      <p>Following items have insufficient qunatity in inventory:</p>
      <ul>
          <li class="Gilroy list-disc" *ngFor="let item of checkQuantityInStock">
              <div>{{item?.item}} added with {{item?.quantityAdded}} units has {{item?.openingStock}} units only</div>
          </li>
      </ul>

  </div>
  <div footer class="flex items-center ms-auto space-x-2 rtl:space-x-reverse">
      <!-- <app-button buttonText='{{"Delete" | translate}}' type='danger' rounded=true (handleClick)='deleteEstimateslist()' ></app-button> -->
      <app-button buttonText='{{"Ok" | translate}}' type='third' rounded=true (handleClick)='outOfStock = false'></app-button>
  </div>
</app-modal-container>
