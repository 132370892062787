
<div class="flex justify-center p-4">
    <div class="w-full flex flex-col space-y-4">
        <div>
            <div class="flex items-center justify-between">
                <div class="">
                    <h1 class="text-2xl Gilroy-Bold inline-flex items-center">
                        <svg _ngcontent-hbd-c18="" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                            stroke="currentColor" class="h-6 w-6 me-2">
                            <path _ngcontent-hbd-c18="" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                d="M16 8v8m-4-5v5m-4-2v2m-2 4h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z">
                            </path>
                        </svg>
                        {{'Assets' | translate}}
                    </h1>
                </div>
                <div class="">
                    <app-button appAccessControl moduleType='purchase' accessType='edit' (click)='this.openAddAssetsModal = true' buttonText="{{'Add an asset' | translate}}" type="primary" size='large'></app-button>
                </div>
            </div>
            <app-table-container [tableHeadings]='tableHeadings' [tableData]='tableData' [keys]='tableKeys' [actionTemplate]='actionTemplate'>
                <ng-template let-item #actionTemplate>
                    <td align="right" class="p-2 last:pr-10 text-center">
                        <div appAccessControl moduleType='purchase' accessType='edit' class="flex justify-center mt-2">
                            <svg (click)='modalAssetUpdate(item._id)' xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-1 cursor-pointer" fill="none"
                                viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                    d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
                            </svg>
                            <svg (click)='deleteConfirmation(item._id)'  xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 cursor-pointer" fill="none"
                                viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                    d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                            </svg>
                        </div>
                    </td>
                </ng-template>
            </app-table-container>
        </div>
    </div>
</div>
<app-modal-container [isOpen]='openAddAssetsModal'>
    <div *ngIf='!update' header class="border-b pb-2 Gilroy-Bold">{{'Add an asset' | translate}}</div>
    <div *ngIf='update'  header class="border-b pb-2 Gilroy-Bold">{{'Update an Asset' | translate}}</div>
    <div content>
      <my-tabs>
        <my-tab tabTitle='{{"Details" | translate}}' >
          <p class="text-sm text-start Gilroy-Bold">{{'Asset Info' | translate}}
          </p>
          <form [formGroup]="createAssetsForm" class="mt-5 text-start">
            <div class="flex mb-3 items-center">
              <div class="w-1/4">
                <p>{{'Asset Name:' | translate}}</p>
              </div>
              <div class="w-3/4">
                <input type="text" formControlName="assetsName" class="bg-input pl-2 h-10 w-9/12 border-0 rounded-xl" placeholder="" />
                <div class="text-sm text-red-500 ms-2 mt-1" *ngIf='formErrors.assetsName'>
                  {{formErrors.assetsName}}</div>
              </div>
            </div>
            <div class="flex items-center mb-3">
                <div class="w-1/4">
                  <p>{{'Asset Type:' | translate}}</p>
                </div>
                <div class="w-3/4">
                  <input type="text" formControlName="assetsType" class="bg-input pl-2 h-10 w-9/12 border-0 rounded-xl" placeholder="" />
                  <div class="text-sm text-red-500 ms-2 mt-1" *ngIf='formErrors.assetsType'>
                    {{formErrors.assetsType}}</div>
                </div>
              </div>
            <div class="flex mb-3 items-center w-full">
              <div class="w-1/4">
                <p>{{'Description:' | translate}}</p>
              </div>
              <div class="w-full">
                <textarea formControlName="description" class="bg-input pl-2 h-44 border-0 w-9/12 rounded-xl ml-5 mr-5"></textarea>
                <div class="text-sm text-red-500 ms-2 mt-1" *ngIf='formErrors.description'>
                  {{formErrors.description}}</div>
              </div> 
            </div>
            
            <div  class=" flex mb-3 items-center">
              <div class="w-1/4">
                <p>Select Account</p>
              </div>
              <fieldset class="w-2/4" [disabled]="update">
                  <ng-select [readonly]="update" [items]= "accounts"
                      class="custom-selector1 bg-input" 
                      bindLabel = "accountName"
                      formControlName="accountDetails"
                      placeholder='Select one category'
                      [searchable]='false'
                      [clearable]='false'
                      [multiple]='false'
                      >
                  </ng-select>
                  <div *ngIf='formErrors.accountDetails' class="text-sm text-red-500 ms-2 mt-1">{{formErrors.accountDetails}}</div>
              </fieldset>
            </div>
            <div class="flex mb-3 items-center">
              <div class="w-1/4">
                <p>{{'Depreciation %:' | translate}}</p>
              </div>
              <div class="w-3/4">
                <input type="number" formControlName="depreciation" class="pl-2 border-0 bg-input w-9/12 h-10 rounded-xl" placeholder="" />
                <div class="text-sm text-red-500 ms-2 mt-1" *ngIf='formErrors.depreciation'>
                  {{formErrors.depreciation}}</div>
              </div>
            </div>
            <div  class=" flex mb-3 items-center">
              <div class="w-1/4">
                <p>Calculate Depreciation</p>
              </div>
              <input type="checkbox" formControlName="calDepreciation" name="calDepreciation" id="calDepreciation">
            </div>
    
            <div  class=" flex mb-3 items-center">
              <div class="w-1/4">
                <p>Select Depreciation Account</p>
              </div>
              <fieldset class="w-2/4" [disabled]="update">
                  <ng-select [readonly]="update" [items]= "depreciationAccounts"
                      class="custom-selector1 bg-input" 
                      bindLabel = "accountName"
                      formControlName="depreciationAccount"
                      placeholder='Select one category'
                      [searchable]='false'
                      [clearable]='false'
                      [multiple]='false'
                      >
                  </ng-select>
                  <div *ngIf='formErrors.depreciationAccount' class="text-sm text-red-500 ms-2 mt-1">{{formErrors.depreciationAccount}}</div>
              </fieldset>
            </div>
          </form>
        </my-tab>
        <!-- <my-tab tabTitle='{{"Files" | translate}}' >
          <div >
            <app-file-upload #fileUploadComponent [addedFiles]="addedFiles"   (emitter)="saveFiles($event)" ></app-file-upload>
          </div>
        </my-tab> -->
      </my-tabs>
    </div>
    <div footer class="flex w-full">
      <div class="w-1/4">
      </div>
      <div class="">
        <app-button *ngIf='!update' buttonText="{{'Save' | translate}}" (handleClick)="createAsset()" type="primary" rounded="full"></app-button>
        <app-button *ngIf='update'  buttonText="{{'Update' | translate}}"(handleClick)="updateAsset()" type="primary" rounded="full"></app-button>
        <app-button buttonText="{{'Cancel' | translate}}" type="third"  class="ms-2" (click)='cancelModal()' rounded="full"></app-button>
      </div>
    </div>
  </app-modal-container>

<app-modal-container [isOpen]='deleteConfirmationModal'>
    <div header class="border-b pb-2 Gilroy-Bold">{{'Delete Asset' | translate}}</div>
    <div content>{{'Are you sure?' | translate}}</div>
    <div footer class="flex items-center space-x-2 rtl:space-x-reverse">
        <app-button buttonText='{{"Delete" | translate}}' type='danger' rounded=true (handleClick)='deleteAsset()' ></app-button>
        <app-button buttonText='{{"Cancel" | translate}}' type='third' rounded=true (handleClick)='deleteConfirmationModal = false'></app-button>
    </div>
</app-modal-container>
