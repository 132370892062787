<div class="flex h-screen">
  <div class="p-8 md:p-14 lg:p-20 w-full lg:w-5/12 max-h-full overflow-y-auto no-scrollbar">
    <div class="mb-12"><a routerLink="/"><img src="assets/images/logo.png" width="150" alt="logo"></a></div>
    <div class="w-full lg:w-5/6">
      <div class="Gilroy-Bold text-3xl mb-3">Sign Up.</div>
      <p class="Gilroy-SemiBold font-color-black-03 py-2">Please create your password.</p>
      <form [formGroup]='signupForm' novalidate class="py-2 px-0">
        <div class="mb-4">
          <label class="Gilroy-Bold block font-color-black-05 mb-2">Your e-mail</label>
          <input formControlName='email'
            class="bg-input rounded-xl border border-gray-200 w-full py-3" 
            type="email" name="email" id="">
        </div>
        <div class="mb-4">
          <label class="Gilroy-Bold block font-color-black-05 mb-2">Password</label>
          <div class='relative'>
            <input formControlName='password'
              class="bg-input rounded-xl border border-gray-200 py-3 w-full" placeholder=""
              [type]='showPassword ? "text": "password"' name="password" id="">
            <div class="absolute inset-y-0 right-0 rtl:left-0 flex items-center me-4">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 cursor-pointer" [ngClass]="showPassword ? 'hidden' : 'block'" (click)="showPassword = !showPassword" viewBox="0 0 20 20" fill="currentColor">
                  <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
                  <path fill-rule="evenodd" d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z" clip-rule="evenodd" />
              </svg>
              <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 cursor-pointer" (click)="showPassword = !showPassword" [ngClass]="showPassword ? 'block' : 'hidden'"  viewBox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd" d="M3.707 2.293a1 1 0 00-1.414 1.414l14 14a1 1 0 001.414-1.414l-1.473-1.473A10.014 10.014 0 0019.542 10C18.268 5.943 14.478 3 10 3a9.958 9.958 0 00-4.512 1.074l-1.78-1.781zm4.261 4.26l1.514 1.515a2.003 2.003 0 012.45 2.45l1.514 1.514a4 4 0 00-5.478-5.478z" clip-rule="evenodd" />
                  <path d="M12.454 16.697L9.75 13.992a4 4 0 01-3.742-3.741L2.335 6.578A9.98 9.98 0 00.458 10c1.274 4.057 5.065 7 9.542 7 .847 0 1.669-.105 2.454-.303z" />
              </svg>
            </div>
          </div>
          <div *ngIf='formErrors.password' class="text-sm text-red-400 ms-2">{{formErrors.password}}</div>
        </div>
        <!-- <div class="mb-8">
          <label class="Gilroy-Bold block font-color-black-05 mb-2">Referral Code (Optional)</label>
          <input formControlName='referralCode'
            class="bg-input rounded-xl border border-gray-200 w-full py-3" type="text">
        </div> -->
        <app-button buttonText='Sign up' type='primary' size='large' [fullWidth]='true' (handleClick)='signup()'></app-button>
      </form>
    </div>
  </div>
  <div class="w-0 lg:w-7/12 flex items-center justify-center bg-light-gray-1 min-h-screen">
    <img src="assets/images/login.png" width="600" alt="" srcset="">
  </div>
</div>