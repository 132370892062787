<div class="flex justify-center p-4 overflow-scroll max-h-full">
    <div class="w-full flex flex-col space-y-4">
      <div class="relative w-max">
        <div class="flex items-center justify-between w-max sticky left-0">
          <div class="sticky left-0">
            <h1 class="text-2xl Gilroy-Bold inline-flex items-center">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 me-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z" />
              </svg>
              {{'P/L Budget' | translate}}
            </h1>
          </div>
        </div>

        <ng-container *ngIf="view ;else addDataTable">
          <div class="flex space-x-2 rtl:space-x-reverse">
            <div class="sticky left-0">
              <form [formGroup]="firstForm">
                <div class="flex">
                <div class="flex flex-col">
                  <label class="Gilroy-Bold">{{'Name'| translate}}</label>
                  <input name="name" formControlName="budgetName" type="text" Placeholder="Enter name" class="w-full bg-input rounded-lg border-0 h-11"/>
                  <div class="text-red-500" *ngIf='firstFormError.budgetName'>{{firstFormError.budgetName}}</div>
                </div>
                <div class="ml-4">
                  <label class="Gilroy-Bold">{{'Financial Year' | translate}}</label>
                  <ng-select [items]="newPeriod"
                  class="custom-selector w-64"
                  bindLabel="name"
                  bindValue="value"
                  formControlName='period'
                  [clearable]="false"
                  placeholder="Select Financial Period">
                  </ng-select>
                  <div class="text-red-500" *ngIf='firstFormError.period'>{{firstFormError.period}}</div>
                </div>
                <div class="">
                  <button class="Gilroy-Bold bg-gradient pt-2 mx-6 my-6 rounded-lg text-white py-2 px-6" (click)="getNameAndYearOfBudget()">{{'Next'}}</button>
                </div>
              </div>
              </form>
           </div>
        </div>

        <div class="flex my-4">
          <div class="w-max">

            <div class="shadow-lg">
              <table class="w-max bg-slate-5 border-separate bg-gray-300">
                <thead>
                  <tr class="text-center bg-gradient shadow-lg">
                    <th class="Gilroy-Bold text-white w-48" *ngFor="let tabcol of tableIncomeCol">{{tabcol}}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="shadow-lg-light border border-blue-100" *ngFor="let income of incomeData;index as i">
                    <th class="text-center border border-blue-100 Gilroy-Bold w-64">{{income?.accountName}}</th>
                    <td class="text-right border border-blue-100" *ngFor="let in of counter(13);let j=index">{{""}}</td>
                  </tr>
                  <tr class="shadow-sm border border-blue-50">
                    <th class="text-center border border-blue-50 Gilroy-Bold">{{'Total Net'}}</th>
                    <td class="text-right Gilroy-Bold border border-blue-50" *ngFor="let in of counter(13);let j=index">{{"0.00"| currency: currencyDetails?.currency:'symbol-narrow':number}}</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="shadow-lg">
              <table class="w-max mt-4 bg-slate-5 border-separate bg-gray-300">
                <thead>
                  <tr class="text-center bg-gradient shadow-lg">
                    <th class="Gilroy-Bold text-white w-48" *ngFor="let tabcol of tableExpensesCol">{{tabcol}}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="shadow-lg-light border border-blue-100" *ngFor="let expense of expensesData;index as exp">
                    <th class="text-center border border-blue-100 Gilroy-Bold w-64">{{expense?.accountName}}</th>
                    <td class="text-right border border-blue-100" *ngFor="let in of counter(13);let j=index">{{""}}</td>
                  </tr>
                  <tr class="shadow-sm border border-blue-50">
                    <th class="text-center border border-blue-50 Gilroy-Bold">{{'Total Net'}}</th>
                    <td class="text-right Gilroy-Bold border border-blue-50" *ngFor="let in of counter(13);let j=index">{{"0.00" | currency: currencyDetails?.currency:'symbol-narrow':number}}</td>
                  </tr>
                </tbody>
              </table>
            </div>

          </div>
        </div>

      </ng-container>

      <ng-template #addDataTable>

        <div class="flex space-x-2 rtl:space-x-reverse">
          <div class="sticky left-0">
            <form [formGroup]="secondForm">
              <div class="flex">
                <div class="">
                  <label class="Gilroy-Bold">{{'Name'| translate}}</label>
                  <input name="name" type="text" formControlName="name" class="w-lg bg-input rounded-lg border-0 h-11" disabled/>
                </div>
                <div class="">
                  <label class="Gilroy-Bold">{{'Year'| translate}}</label>
                  <input name="name" type="text" formControlName="year" class="w-lg bg-input rounded-lg border-0 h-11" disabled/>
                </div>
              </div>
            </form>
          </div>
        </div>

        <ng-template [ngIf]="createBudgetModal">
          <div  #createModal [style]="ycod" class="bg-white w-[79vw] overflow-x-scroll shadow-lg sticky z-10 h-12" id="createModal">
            <form [formGroup]="yearBudget">
              <table class="w-max">
                <tr>
                  <td class="p-2 Gilroy-Bold w-64 sticky left-0 z-20 bg-white">
                    {{accountText | translate}}
                  </td>
                  <td *ngFor ='let data of newMonthsName'>
                    <input class="h-8 w-48 border border-blue-500 shadow-sm text-left" name="{{data}}" type="number" placeholder="{{data}}" formControlName="{{data}}"/>
                  </td>
                  <td>
                    <button *ngIf="!update" class="Gilroy-Bold bg-gradient pt-2 mx-2 rounded-lg text-white py-2 px-4" (click)="addBudget()">Add</button>
                    <button *ngIf="update" class="Gilroy-Bold bg-gradient pt-2 mx-2 rounded-lg text-white py-2 px-4" (click)="updateBudget()">Update</button>
                  </td>
                  <td>
                    <button class="Gilroy-Bold bg-gradient pt-2 mx-2 rounded-lg text-white py-2 px-4" (click)="closeCreateBudget()">Cancel</button>
                  </td>
                </tr>
              </table>
            </form>
          </div>
        </ng-template>

        <div class="flex my-4" [style]="tablemargin" >
          <div class="w-max">
            <div class="w-[79vw] overflow-x-scroll">


            <div class="">
              <table class="w-max bg-slate-50 border-separate shadow-lg">
                <thead>
                  <tr class="text-center bg-gradient shadow-lg">
                    <th class="Gilroy-Bold text-white w-48" *ngFor="let tabcol of tableIncomeCol" [ngClass]="{'incomeAccount':tabcol === 'INCOME'}">{{tabcol}}</th>
                  </tr>
                </thead>
                <tbody class="">
                  <tr class="hover:bg-gray-100 shadow-lg-light border border-blue-100" *ngFor="let income of incomeData;index as incomeIndex">
                    <td class="text-center border border-blue-100 Gilroy-Bold w-64 bg-white sticky left-0">{{income?.accountName}}</td>
                    <td  class="text-right border border-blue-100 Gilroy-Medium" *ngFor="let data of getDataArray(income?.accountName)" (click)="onEdit(income,$event)">
                      {{data?.amount| currency: currencyDetails?.currency:'symbol-narrow':number}}
                    </td>
                  </tr>
                  <tr class="shadow-sm border border-blue-50">
                    <td class="text-center border border-blue-50 Gilroy-Bold bg-white sticky left-0">{{'Total Net'}}</td>
                    <td class="text-right border border-blue-50 Gilroy-Bold" *ngFor="let data of netotalIncome">{{data | currency: currencyDetails?.currency:'symbol-narrow':number}}</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="">
              <table class="w-max mt-4 bg-slate-50 border-separate shadow-lg">
                <thead>
                  <tr class="text-center bg-gradient shadow-lg">
                    <th class="Gilroy-Bold text-white w-48" *ngFor="let tabcol of tableExpensesCol" [ngClass]="{'incomeAccount':tabcol === 'EXPENSES'}">{{tabcol}}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="hover:bg-gray-100 shadow-lg-light border border-blue-100" *ngFor="let expense of expensesData;index as exp">
                    <td class="text-center border border-blue-100 Gilroy-Bold w-64 bg-white sticky left-0">{{expense?.accountName}}</td>
                    <td  class="text-right border border-blue-100 Gilroy-Medium" *ngFor="let data of getDataArray2(expense?.accountName)" (click)="onEdit(expense, $event)">
                      {{data?.amount | currency: currencyDetails?.currency:'symbol-narrow':number}}</td>
                  </tr>
                  <tr class="shadow-sm border border-blue-50">
                    <td class="text-center border border-blue-50 Gilroy-Bold sticky left-0 bg-white">{{'Total Net'}}</td>
                    <td class="text-right border border-blue-50 Gilroy-Bold" *ngFor="let data of netotalExpense">{{data | currency: currencyDetails?.currency:'symbol-narrow':number}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            </div>
          </div>
        </div>
      </ng-template>

      </div>
    </div>
</div>




