<div class="flex justify-center p-4 min-h-screen">
  <div class="w-full flex flex-col">
    <div class="relative w-max">
      <div class="flex items-center justify-between w-max sticky left-0">
        <div class="sticky left-0">
          <h1 class="text-2xl Gilroy-Bold inline-flex items-center">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 me-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z" />
            </svg>
            {{' P/L Budget View' | translate}}
          </h1>
        </div>
      </div>

      <div class="flex space-x-2 rtl:space-x-reverse">
        <div class="sticky left-0">
          <form [formGroup]="secondForm">
            <div class="flex">
              <div class="">
                <label class="Gilroy-Bold">{{'Name'| translate}}</label>
                <input name="name" type="text" formControlName="name" class="w-lg bg-input rounded-lg border-0 h-11" disabled/>
              </div>
              <div class="">
                <label class="Gilroy-Bold">{{'Year'| translate}}</label>
                <input name="name" type="text" formControlName="year" class="w-lg bg-input rounded-lg border-0 h-11" disabled/>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div class="flex my-4">
        <div class="w-max">
          <div class="w-[79vw] overflow-x-scroll">

          <div class="">
            <table class="w-max bg-slate-50 border-separate shadow-lg">
              <thead>
                <tr class="text-center bg-gradient shadow-lg">
                  <th class="Gilroy-Bold text-white w-48" *ngFor="let tabcol of tableIncomeCol" [ngClass]="{'incomeAccount':tabcol === 'INCOME'}">{{tabcol}}</th>
                </tr>
              </thead>
              <tbody class="">
                <tr class="shadow-lg-light border border-blue-100" *ngFor="let income of incomeData;index as incomeIndex">
                  <td class="text-center border border-blue-100 Gilroy-Bold w-64 bg-white sticky left-0">{{income?.accountName}}</td>
                  <td  class="text-right border border-blue-100 Gilroy-Medium" *ngFor="let data of getDataArray(income?.accountName)" >
                    {{data?.amount| currency: currencyDetails?.currency:'symbol-narrow':number}}
                  </td>
                </tr>
                <tr class="shadow-sm border border-blue-50">
                  <td class="text-center border border-blue-50 Gilroy-Bold bg-white sticky left-0">{{'Total Net'}}</td>
                  <td class="text-right border border-blue-50 Gilroy-Bold" *ngFor="let data of netotalIncome">{{data | currency: currencyDetails?.currency:'symbol-narrow':number}}</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="">
            <table class="w-max mt-4 bg-slate-50 border-separate shadow-lg">
              <thead>
                <tr class="text-center bg-gradient shadow-lg">
                  <th class="Gilroy-Bold text-white w-48" *ngFor="let tabcol of tableExpensesCol" [ngClass]="{'incomeAccount':tabcol === 'EXPENSES'}">{{tabcol}}</th>
                </tr>
              </thead>
              <tbody>
                <tr class="shadow-lg-light border border-blue-100" *ngFor="let expense of expensesData;index as exp">
                  <td class="text-center border border-blue-100 Gilroy-Bold w-64 bg-white sticky left-0">{{expense?.accountName}}</td>
                  <td  class="text-right border border-blue-100 Gilroy-Medium" *ngFor="let data of getDataArray2(expense?.accountName)">
                    {{data?.amount | currency: currencyDetails?.currency:'symbol-narrow':number}}</td>
                </tr>
                <tr class="shadow-sm border border-blue-50">
                  <td class="text-center border border-blue-50 Gilroy-Bold sticky left-0 bg-white">{{'Total Net'}}</td>
                  <td class="text-right border border-blue-50 Gilroy-Bold" *ngFor="let data of netotalExpense">{{data | currency: currencyDetails?.currency:'symbol-narrow':number}}</td>
                </tr>
              </tbody>
            </table>
          </div>

          </div>
        </div>
      </div>

    </div>
  </div>
</div>




